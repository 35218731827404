import React from 'react';

export function SidebarMenu() {
  return (
    <svg
      data-testid='sidebar-menu_svg_icon'
      fill='none'
      height='20'
      viewBox='0 0 20 20'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M0 2.85715C0 2.46266 0.319797 2.14286 0.714286 2.14286H19.2857C19.6802 2.14286 20 2.46266 20 2.85715C20 3.25164 19.6802 3.57143 19.2857 3.57143H0.714286C0.319797 3.57143 0 3.25164 0 2.85715Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-10)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M0 10C0 9.60551 0.319797 9.28572 0.714286 9.28572H19.2857C19.6802 9.28572 20 9.60551 20 10C20 10.3945 19.6802 10.7143 19.2857 10.7143H0.714286C0.319797 10.7143 0 10.3945 0 10Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-10)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M0 17.1429C0 16.7484 0.319797 16.4286 0.714286 16.4286H19.2857C19.6802 16.4286 20 16.7484 20 17.1429C20 17.5374 19.6802 17.8571 19.2857 17.8571H0.714286C0.319797 17.8571 0 17.5374 0 17.1429Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-10)'
        fillRule='evenodd'
      />
    </svg>
  );
}
