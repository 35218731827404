import { useEffect, useState } from 'react';

import { HardwareService } from '../HardwareService';
import { usePeripheral } from '../shared/usePeripherals';

import type { Scale } from './Scale';
import type { ScaleMeasurement } from './types';
import type { DeviceScaleMeasurement } from './useScales';

export type ScaleHook = {
  scale: Scale;
  refresh: () => void;
  measurement: ScaleMeasurement | undefined;
}

export type ScaleHookProps = {
  onMeasurement?: (measurement: ScaleMeasurement) => void
}

export const useScale = (device: Scale, props?: ScaleHookProps): ScaleHook => {
  const [measurement, setMeasurement] = useState<ScaleMeasurement | undefined>(undefined);

  const handleMeasurement = (evt: Event) => {
    const detail = (evt as CustomEvent<DeviceScaleMeasurement>).detail;
    if(detail.device.id !== device.id) return;

    setMeasurement(detail.measurement);
    props?.onMeasurement?.(detail.measurement);
  };

  useEffect(() => {
    HardwareService.scale.addEventListener('measurement', handleMeasurement);
    return () => {
      HardwareService.scale.removeEventListener('measurement', handleMeasurement);
    }
  });
  
  const result = usePeripheral<Scale>(device, HardwareService.scale);
  return {
    scale: result.item.device,
    refresh: result.refresh,
    measurement,
  }
}
