import {
  Dialog as MUIModal,
  DialogTitle as MUIModalTitle,
  DialogContent as MUIModalContent,
  DialogContentText as MUIModalContentText,
  DialogActions as MUIModalActions,
  Divider as MUIDivider,
} from '@mui/material';
import styled, { css } from 'styled-components';

import { IconButton } from 'src/app/components/lib/icon-button';

import { FormContainer } from '../form';

import type { ModalActionsProps } from 'src/app/components/lib/modal/modal';

type ModalActionsPropsWithDollar = Omit<ModalActionsProps, 'footerPadding' | 'justifyFooterContent'> & {
  $footerPadding?: string;
  $justifyFooterContent?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly';
};

export const StyledModal = styled(MUIModal)<{
  $height?: string;
  $hideHeader?: boolean;
  $minWidth?: string;
  $width?: string;
}>`
  .MuiPaper-root {
    width: ${({ $width }) => $width ?? '576px'};
    height: ${({ $height }) => $height ?? 'auto'};
    min-width: ${({ $minWidth }) => $minWidth ?? '320px'};
    min-height: 120px;
    max-height: ${({ $height }) => ($height ? '100%' : 'calc(100vh - 100px)')};
    padding: var(--sizes-0);
    border-radius: 20px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    max-width: unset;
  }

  .expand-modal {
    &-enter {
      height: ${({ $height }) => $height ?? '50%'};
      width: ${({ $width }) => $width ?? '576px'};
    }
    &-enter-done {
      width: 85%;
      height: 90%;
      transition: all 300ms ease;

      .data-grid-container {
        height: 100vh;
      }
    }
    &-exit-active {
      width: 85%;
      height: 90%;

      .data-grid-container {
        height: 100vh;
      }
    }
    &-exit-done {
      width: ${({ $width }) => $width ?? '576px'};
      height: ${({ $height }) => $height ?? '50%'};
      transition: all 300ms ease;
    }

    .MuiBackdrop-root {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* Using rgba to have the correct opacity - this is var(--color-opal-90) at 50% opacity */
      background-color: rgba(11, 32, 51, 0.5);
    }
  }

  ${({ $hideHeader }) => {
    if ($hideHeader) {
      return css`
        .MuiTypography-root {
          opacity: 0;
          height: 24px;
        }
        hr {
          display: none;
        }
      `;
    }
    return '';
  }}
`;

export const OnCloseBackIconButton = styled(IconButton)`
  position: absolute;
  left: var(--sizes-50);

  svg {
    height: var(--sizes-60);
    width: var(--sizes-60);
  }
`;

export const OnExpandIconButton = styled(IconButton)`
  position: absolute;
  right: var(--sizes-50);
`;

export const RebrandModalTitle = styled(MUIModalTitle)`
  padding: var(--sizes-30) var(--sizes-30) var(--sizes-0);
  color: var(--color-grayscale-black);
  font: var(--font-large-16pt-semibold);
  text-align: center;
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: var(--sizes-40) var(--sizes-50) var(--sizes-0);
  justify-content: center;

  h2 {
    padding: var(--sizes-0);
    text-align: center;
  }

  button {
    padding: var(--sizes-0);
    z-index: 2;
  }

  svg {
    > path {
      fill: var(--color-gray-70);
    }
  }
`;

export const getRebrandModalContentStyles = (contentPadding?: string) => css`
  display: flex;
  flex-direction: column;
  padding: ${contentPadding ?? 'var(--sizes-60) var(--sizes-70)'};

  .form-control {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const RebrandModalContent = styled(MUIModalContent)<{ contentPadding?: string }>`
  ${({ contentPadding }) => getRebrandModalContentStyles(contentPadding)}
`;

export const RebrandModalContentText = styled(MUIModalContentText)`
  color: var(--color-grayscale-black);
  font: var(--font-small-13pt-normal);
`;

export const RebrandModalActions = styled(MUIModalActions)<ModalActionsPropsWithDollar>`
  padding: ${(props) => props?.$footerPadding || 'var(--sizes-0) var(--sizes-60) var(--sizes-40)'};
  display: flex;
  justify-content: ${(props) => props?.$justifyFooterContent || 'space-between'};
  gap: ${(props) => (props?.$justifyFooterContent === 'flex-end' ? '12px' : '0')};

  .secondary-actions {
    display: flex;
    gap: 12px;
  }

  .primary-actions {
    display: flex;
    margin-left: 0;
    gap: 12px;
  }
`;

export const RebrandModalDivider = styled(MUIDivider)<{ $variant: string }>`
  ${({ $variant }) => css`
    ${$variant === 'actions' && actionsDivider}
    ${$variant === 'actions-no-content' && actionsNoContentDivider}
    ${$variant === 'title' && titleDivider}
    ${$variant === 'section' && sectionDivider}
  `}
`;

const actionsDivider = css`
  margin: var(--sizes-0) var(--sizes-0) var(--sizes-40);
`;

const actionsNoContentDivider = css`
  margin: var(--sizes-0) var(--sizes-0) var(--sizes-40);
  border-bottom-width: 0; // when there is no content above the actions, hide the divider line
`;

const titleDivider = css`
  margin: var(--sizes-40) var(--sizes-0) var(--sizes-0);
`;

const sectionDivider = css`
  margin: var(--sizes-70) var(--sizes-0);
`;

export const ModalFormContainer = styled(FormContainer)`
  width: 100%;
  max-width: 51rem;
`;
