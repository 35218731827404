import keyMirror from 'keymirror';

export const endPoints = {
  GET_ROOMS: 'api/v2/room/get-rooms',
  GET_LABELS: '',
  GET_USERS: 'api/posv3/maintenance/Get-users',
  SET_USERS: 'api/posv3/maintenance/update-user',
  SET_USERS_WITHIN_ORG: 'api/users/update-user-within-org',
  GET_CUSTOMERS: 'api/posv3/maintenance/get-customers',
  SET_CUSTOMERS: 'api/posv3/maintenance/update-customer',
  SET_CUSTOMER_STATUS: 'api/posv3/maintenance/set-customer-status',

  SET_USER_PASSWORD: 'api/posv3/maintenance/set-user-password',
  FORCE_PASSWORD_CHANGE: 'api/posv3/maintenance/force-password-change',
  GENERATE_PIN: 'api/posv3/maintenance/generate-pin',
  GENERATE_PIN_V2: 'api/posv3/maintenance/generate-pin-v2',

  GET_CUSTOMER_JOURNAL: 'api/v2/guest/View_Patient_Notes',
  SET_CUSTOMER_JOURNAL: 'api/v2/guest/Update_Patient_Notes',

  GET_QUALIFYING_CONDITIONS_GUEST: 'api/v2/guest/get-qualifying-conditions',

  GET_CUSTOMER_PRODUCT_HISTORY: 'api/v2/product/product_History',
  GET_CUSTOMER_TRANSACTION_HISTORY: 'api/v2/product/Transaction_History',
  GET_CUSTOMER_TRANSACTION_HISTORY_V2: 'api/v2/transaction_history/Transaction_History',
  GET_CUSTOMER_TRANSACTION_DETAILS: 'api/v2/product/product_History_Detail',

  GET_CUSTOMER_FILE_LIST: 'api/v2/guest/get-file-list',
  UPLOAD_CUSTOMER_FILE: 'api/v2/guest/upload-file',
  DOWNLOAD_CUSTOMER_FILE: 'api/v2/guest/download-file',
  DELETE_CUSTOMER_FILE: 'api/v2/guest/delete-file',
};

export const actions = keyMirror({
  ROOMS_LOADED: null,
  LABELS_LOADED: null,
  STRAINS_LOADED: null,
  USERS_LOADED: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
