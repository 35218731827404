import { Select as MUISelect } from '@mui/material';
import styled from 'styled-components';

export const BohSelect = styled(MUISelect)`
  width: 100%;

  input {
    height: 100%;
  }

  .MuiSelect-select {
    font-size: 16px;
    padding: 0;
    color: #464a4c;
  }

  &.Mui-disabled {
    background-color: #eceeef;
    :hover {
      cursor: not-allowed;
    }
  }

  .MuiSelect-icon {
    top: calc(50% - 0.1875rem);
    right: 12px;

    svg {
      display: block;
    }
  }

  .MuiSelect-outlined.Mui-disabled {
    -webkit-text-fill-color: #464a4c;
  }

  legend {
    width: auto;
  }
`;
