import type { BufferConfiguration } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import type { PeripheralServiceConfig } from "../../shared/index";
import { PeripheralService, PeripheralType } from "../../shared/index";

import { KeyboardScanner } from "./KeyboardScanner";
import { isKeyboardScanner } from "./utils";

export type KeyboardScannerServiceConfiguration = {
  buffer?: Partial<BufferConfiguration>;
  service?: Partial<PeripheralServiceConfig>;
}

export class KeyboardScannerService extends PeripheralService<KeyboardScanner> {
  private bufferConfig?: Partial<BufferConfiguration>;
  
  constructor(config?: Partial<KeyboardScannerServiceConfiguration>) {
    super(PeripheralType.scanner, { autoConnect: true, name: 'KeyboardScannerService', autoEnable: true }, config?.service);
    this.bufferConfig = config?.buffer;
  }

  get isSupported(): boolean { return true; }

  protected async availableDevices(): Promise<KeyboardScanner[]> {
    const isAlreadyConnected = HardwareService.scanner.devices.find(it => isKeyboardScanner(it))?.isConnected === true;
    return [ new KeyboardScanner(isAlreadyConnected, this.bufferConfig) ];
  }
}
