import React from 'react';
/* eslint-disable max-len */
export function RebrandIndeterminate(): JSX.Element {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13 0.5H3C1.61929 0.5 0.5 1.61929 0.5 3V13C0.5 14.3807 1.61929 15.5 3 15.5H13C14.3807 15.5 15.5 14.3807 15.5 13V3C15.5 1.61929 14.3807 0.5 13 0.5Z'
        fill='var(--color-brand-secondary-sapphire)'
        stroke='var(--color-brand-secondary-sapphire)'
      />
      <path
        d='M11 7H5C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7Z'
        fill='white'
      />
    </svg>
  );
}
/* eslint-enable max-len */
