import { useQuery } from '@tanstack/react-query';

import UserStore from 'src/app_deprecated/stores/UserStore';

import { postData } from '..';

export const GET_ORG = 'api/org/get';

export const orgQueryKey = ['org'];

export type OrgRequest = {
  OrgId: number;
};

export type OrgResponse = {
  Id: number;
  Name: string;
  Settings: {
    PinLength: number;
  };
};

export function useGetOrgQuery() {
  const apiData = UserStore.getApiData();
  return useQuery({
    queryKey: ['org', apiData?.LspId, apiData?.LocId],
    queryFn: async () => postData<OrgRequest, OrgResponse>({ endpoint: GET_ORG, payload: { OrgId: apiData?.orgId } }),
    staleTime: Infinity,
  });
}
