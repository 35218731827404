import { IconButton as MUIIconButton } from '@mui/material';
import styled from 'styled-components';

export const StyledIconButton = styled(MUIIconButton)`
  :hover {
    background: none;
  }

  :focus {
    outline: 0;
  }
`;
