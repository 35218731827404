import { useMemo } from 'react';

import type { LocsByLspsResponse } from 'src/app/queries/settings/get-locations-for-lsp';

export type LspObject = Record<
  number,
  {
    locations: LocsByLspsResponse[];
    LspName: string;
  }
>;

/**
 * Custom React hook to transform an array of LSP data into an object.
 *
 * @param {LocsByLspsResponse[]} locsForLsps - An array of location objects with LspId, LspName, LocName, and potentially other properties.
 * @returns {LspObject} An object with LspId as keys and an object with LspName and locations as values.
 *
 * @example
 * const locsForLsps = [
 *   { LspId: 1029, LspName: 'LSP 1', LocName: 'Location A' },
 *   { LspId: 1029, LspName: 'LSP 1', LocName: 'Location B' },
 *   { LspId: 1031, LspName: 'LSP 2', LocName: 'Location C' },
 * ];
 *
 * const lspObject = useLspObject(locsForLsps);
 *
 * // lspObject will be:
 * // {
 * //   1029: {
 * //     LspName: 'LSP 1',
 * //     locations: [
 * //       { Name: 'Location A', ... },
 * //       { Name: 'Location B', ... },
 * //     ],
 * //   },
 * //   1031: {
 * //     LspName: 'LSP 2',
 * //     locations: [
 * //       { Name: 'Location C', ... },
 * //     ],
 * //   },
 * // }
 */

export const useLspObject = (locsForLsps: LocsByLspsResponse[]): LspObject =>
  useMemo(() => {
    if (!locsForLsps) {
      return {};
    }

    return locsForLsps.reduce((acc, item) => {
      if (!acc[item.LspId]) {
        acc[item.LspId] = {
          LspName: item.LspName,
          locations: [],
        };
      }

      acc[item.LspId].locations.push({
        Name: item.LocName,
        ...item,
      });

      return acc;
    }, {});
  }, [locsForLsps]);
