import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';
import PermissionStore from 'src/app_deprecated/stores/PermissionStore';

import { Features } from '../state/types/loc-profile';
import { useFeatureCheck } from '../state/user-dispensaries';

export function useViewOrgManagementEnabled() {
  const isFeatureEnabled = useFeatureCheck(Features.ViewOrgManagement);
  const flags = useFlags();
  const isLDFlagEnabled = flags['pos.iam.org-management-2024-07.rollout'];
  const userHasPermission = PermissionStore.userCan(Permissions.ViewOrgManagement);
  return useMemo(
    () => isFeatureEnabled && isLDFlagEnabled && userHasPermission,
    [isFeatureEnabled, isLDFlagEnabled, userHasPermission]
  );
}
