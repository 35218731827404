import React, { useCallback, useEffect, useState } from 'react';

import { Drawer } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { BohExitIcon } from 'src/app/components/icons/boh-exit-icon';
import { ChevronRight } from 'src/app/components/icons/chevron-right';
import { DutchieLogo } from 'src/app/components/icons/dutchie-logo';
import { IconButton } from 'src/app/components/lib/icon-button';
import { Menu } from 'src/app/components/lib/menu';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { useBohNav } from 'src/app/hooks/use-boh-nav';
import { SubNavigation } from 'src/app/layout/sub-navigation/index';
import { useDarkMode } from 'src/app/state/dark-mode';

import { NewItem, NewItemContainer } from '../sidebar/shared';

import { DutchieLogoColor } from './dutchie-logo-color';
import { NavigationIcon } from './nav-icon';

import type { DrawerProps } from '@mui/material';

type NavigationMenuProps = {
  isOpen: boolean;
  onClose: DrawerProps['onClose'];
};

function NavigationMenu({ isOpen, onClose }: NavigationMenuProps) {
  const darkMode = useDarkMode();
  const links = useBohNav();
  const navigate = useNavigate();

  const [isSubNavOpen, setIsSubNavOpen] = useState(false);
  const [parentNavLabel, setParentNavLabel] = useState(null);
  const [parentNavElement, setParentNavElement] = useState(null);

  function handleClickNavigation(event, link) {
    const firstAvailableRoute: string = link?.subNavItems?.filter((navItem) => navItem.visible)[0].to;
    const navigationRoute: string = firstAvailableRoute ?? link.to;
    navigate(navigationRoute);
    handleCloseNavigationMenu(event);
  }

  function handleCloseNavigationMenu(event) {
    onClose(event, 'escapeKeyDown');
    setParentNavLabel(null);
    setIsSubNavOpen(false);
  }

  const navMenuBackdropElement = document.querySelector('.MuiBackdrop-invisible');

  const handleBackdropListener = useCallback(() => {
    const timer = window.setTimeout(() => {
      setIsSubNavOpen(false);
      setParentNavLabel(null);
    }, 300);

    navMenuBackdropElement.addEventListener('mouseout', () => {
      window.clearTimeout(timer);
    });
  }, [navMenuBackdropElement]);

  useEffect(() => {
    if (isSubNavOpen && isOpen && navMenuBackdropElement) {
      navMenuBackdropElement.addEventListener('mouseover', handleBackdropListener);
    } else {
      navMenuBackdropElement?.removeEventListener('mouseover', handleBackdropListener);
    }
  }, [isSubNavOpen, isOpen, navMenuBackdropElement, handleBackdropListener]);

  const Logo = darkMode ? DutchieLogo : DutchieLogoColor;

  return (
    <NavigationMenuContainer
      $isOpen={isOpen}
      anchor='left'
      id='main-navigation-menu'
      ModalProps={{
        keepMounted: true,
      }}
      open={isOpen}
      onClose={onClose}
    >
      <MainNavContainer $darkMode={darkMode}>
        <div>
          <MenuHeader $darkMode={darkMode}>
            <Logo onClick={_.noop} />
            <IconButton onClick={handleCloseNavigationMenu}>
              <BohExitIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </MenuHeader>

          <NavigationItems role='navigation'>
            {links.map((link) => {
              const { visible, label, isNew } = link;
              const allSubNavItemsAreHidden =
                Boolean(link.subNavItems) && !link.subNavItems?.some((navItem) => navItem.visible);

              // high level navigation domain parent element to add hover listener to
              const parentNav = document.querySelector(`[data-testid='index_navigation-menu-item_${label}']`);

              // add event listener for on mouseover (hover) to set which high level route is being viewed
              if (parentNav && label !== 'Home' && !!link.subNavItems?.length) {
                parentNav.addEventListener('mouseover', () => {
                  // delay the sub nav items updating so they don't update immediately and causing a weird UX
                  const timer = window.setTimeout(() => {
                    setParentNavElement(parentNav);
                    setIsSubNavOpen(true);
                    setParentNavLabel(label);
                  }, 175);

                  // clear the timeout when a user stops hovering over a parent nav label
                  parentNav.addEventListener('mouseout', () => {
                    window.clearTimeout(timer);
                  });
                });
              }
              return (
                visible &&
                !allSubNavItemsAreHidden && (
                  <NavigationMenuItem
                    $darkMode={darkMode}
                    $isActive={parentNavLabel === label}
                    data-testid={`index_navigation-menu-item_${label}`}
                    key={label}
                    value={label}
                    onClick={(e) => handleClickNavigation(e, link)}
                  >
                    <NavigationIcon type={label} />
                    {label}
                    {isNew && (
                      <NewItemContainer width='30%'>
                        <NewItem>NEW</NewItem>
                      </NewItemContainer>
                    )}
                    {label !== 'Home' && !!link.subNavItems?.length && (
                      <NavigationMenuItemArrow>
                        <ChevronRight />
                      </NavigationMenuItemArrow>
                    )}
                  </NavigationMenuItem>
                )
              );
            })}
          </NavigationItems>
        </div>
      </MainNavContainer>
      <CSSTransition
        classNames='sub-navigation-panel'
        in={isSubNavOpen}
        key='sub-navigation-panel'
        mountOnEnter
        timeout={{ enter: 100, exit: 500 }}
        unmountOnExit
      >
        <SubNavigationPanel
          anchorEl={parentNavElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoFocus={false}
          disableAutoFocus
          disablePortal
          disableScrollLock
          id='sub-navigation-panel'
          marginThreshold={0}
          open
          transformOrigin={{
            vertical: 12,
            horizontal: 0,
          }}
          variant='menu'
        >
          <SubNavigation
            isDualPane
            parentSection={parentNavElement?.innerText}
            onSubNavClick={handleCloseNavigationMenu}
          />
        </SubNavigationPanel>
      </CSSTransition>
    </NavigationMenuContainer>
  );
}

export default NavigationMenu;

const NavigationMenuContainer = styled(Drawer)<{ $isOpen: boolean }>`
  .MuiDrawer-paperAnchorLeft {
    display: flex;
    flex-grow: 1;
    box-shadow: none;
    background-color: transparent;
    transition: transform 175ms cubic-bezier(0.02, 0.01, 0.47, 1) !important;

    ${({ $isOpen }) => css`
      ${!$isOpen && closedMenu}
      ${$isOpen && openMenu}
    `}
  }

  & .MuiDrawer-paper {
    height: 100%;
  }

  .MuiBackdrop-root {
    background-color: rgba(11, 32, 51, 0.5);
  }

  .sub-navigation-panel {
    &-enter {
      opacity: 0;
      transform: translateX(calc(-1 * var(--navigation-drawer-width)));
    }
    &-enter-done {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 75ms linear, transform 175ms ease-in;
    }
    &-exit {
      opacity: 1;
      transform: translateX(0%);
    }
    &-exit-active {
      opacity: 0;
      transform: translateX(calc(-1 * var(--navigation-drawer-width)));
      transition: opacity 300ms linear, transform 300ms ease-in;
    }
  }
`;

const closedMenu = css`
  transform: translateX(calc(-1 * var(--navigation-drawer-width)));
`;

const openMenu = css`
  transform: none;
`;

const MenuHeader = styled.div<{ $darkMode: boolean }>`
  display: flex;
  padding: 0 22px 0 32px;
  min-height: 60px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0;
    width: 36px;
    height: 36px;

    :focus {
      outline: 0;
    }

    :hover {
      svg {
        path {
          fill: var(--color-gray-90);
        }
      }
      padding: 6px;
      background: var(--color-gray-10);
      border-radius: 18px;
    }
  }
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      button {
        :hover {
          background: var(--color-gray-100);
          svg {
            path {
              fill: var(--color-brand-primary-white);
            }
          }
        }
      }
    `}
`;

const NavigationMenuItemArrow = styled.div`
  position: absolute;
  visibility: hidden;
  right: var(--sizes-50);
  width: var(--sizes-40);
  height: var(--sizes-40);
`;

const activeMenuItemStyles = css`
  color: var(--color-grayscale-black);
  font-weight: 600;
  background: none;

  svg {
    path {
      fill: var(--color-grayscale-black);
    }
  }

  ${NavigationMenuItemArrow} {
    visibility: visible;
  }
`;

const NavigationMenuItem = styled(MenuItem)<{ $darkMode: boolean; $isActive?: boolean }>`
  letter-spacing: var(--font-letter-spacing);
  color: var(--color-gray-90);
  padding: var(--sizes-40) var(--sizes-60) var(--sizes-40) var(--sizes-70);

  svg {
    path {
      fill: var(--color-gray-70);
    }
  }

  :hover {
    ${activeMenuItemStyles}
  }

  ${({ $isActive }) => $isActive && activeMenuItemStyles}

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: var(--color-gray-40);
      svg {
        path {
          fill: var(--color-gray-40);
        }
      }
      :hover {
        color: var(--color-brand-primary-white);
        svg {
          path {
            fill: var(--color-brand-primary-white);
          }
        }
      }
    `}
`;

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    margin-right: var(--sizes-40);
  }
`;

const MainNavContainer = styled.div<{ $darkMode: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  width: var(--navigation-drawer-width);
  min-width: var(--navigation-drawer-width);
  max-width: var(--navigation-drawer-width);
  background-color: var(--color-brand-primary-white);
  min-height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  /* overflow: auto; */
  z-index: 9999;

  /* hide scrollbar for most browsers */
  /* ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; */

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background-color: var(--color-grayscale-black);
    `}
`;

const SubNavigationPanel = styled(Menu)`
  .DualPane {
    padding: var(--sizes-50) var(--sizes-30);
    min-width: var(--navigation-drawer-width);
    max-width: var(--navigation-drawer-width);
  }

  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiPopover-paper {
    border-radius: 0px 9px 9px 0px;
    height: fit-content;
    max-height: 100%;

    ul {
      padding: 0;
    }
  }
`;
