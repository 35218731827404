import type { ChangeEvent } from 'react';
import React, { Children } from 'react';

import { RebrandCaret } from 'src/app/components/icons';
import { TrashCan } from 'src/app/components/icons/trash-can';
import { ErrorMessage } from 'src/app/components/lib/error-message';
import { FormControl } from 'src/app/components/lib/form-control';
import { IconButton } from 'src/app/components/lib/icon-button';
import { Label } from 'src/app/components/lib/label';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { getIDFromLabel } from 'src/app/components/lib/utils';
import { useDarkMode } from 'src/app/state/dark-mode';

import { StyledSelect, StyledPlaceholder, StyledOutlinedInput } from './select-rebrand.styles';

import type { SxProps, SelectProps as MUISelectProps } from '@mui/material';

export function SelectCaret(props) {
  return <RebrandCaret {...props} color='var(--color-gray-70)' height='7px' width='14px' />;
}

export type SelectProps = MUISelectProps & {
  automationId?: string;
  borderColor?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  errorMessage?: string;
  gridColumns?: number;
  hideBorder?: boolean;
  id?: string;
  includeNoneWhenSelected?: boolean;
  label?: React.ReactElement | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDelete?: () => void;
  paddingRightInput?: string;
  placeholder?: string;
  renderValue?: (selected: boolean | string) => React.ReactNode | boolean;
  required?: boolean;
  sx?: SxProps;
  tooltip?: React.ReactNode;
  value: boolean | number | string;
  width?: string;
};

export const Select = React.forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const {
    label,
    labelPlacement = 'start',
    displayEmpty = true,
    children,
    onChange,
    renderValue,
    value,
    width,
    required = false,
    sx,
    disabled = false,
    tooltip,
    automationId,
    gridColumns,
    className = '',
    input,
    MenuProps,
    SelectDisplayProps,
    hideBorder = false,
    id,
    includeNoneWhenSelected = false,
    placeholder,
    borderColor,
    errorMessage,
    onDelete,
    paddingRightInput,
    ...other
  } = props;

  const idFromLabel = getIDFromLabel(label);

  // This grabs the value and label off of each child so that we can use them to find the matching option in the renderValue function below
  const valuesAndLabelsOffChildren = Children.toArray(children).map((child: React.ReactElement) => {
    const { props: childProps } = child;
    if (childProps) {
      return { value: childProps?.value, label: childProps?.children };
    }
    return {};
  });

  const includeNoneOption = includeNoneWhenSelected && !!value;
  const darkMode = useDarkMode();
  return (
    <FormControl
      {...other}
      $fullWidth={!label}
      $labelPlacement={label ? labelPlacement : undefined}
      $width={width}
      className={`${className ?? ''} select-form-control`}
      disabled={disabled}
      error={!!errorMessage}
      gridColumns={gridColumns}
      ref={ref}
      sx={sx}
    >
      {label && (
        <Label
          className='select-label'
          htmlFor={`select-input_${id ?? idFromLabel}`}
          id={`select-label_${id ?? idFromLabel}`}
          required={required}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      <StyledSelect
        $borderColor={borderColor}
        $darkMode={darkMode}
        $hideBorder={hideBorder}
        className='select-select-input'
        data-testid={automationId}
        displayEmpty={displayEmpty}
        IconComponent={SelectCaret}
        id={`select-input_${id ?? idFromLabel}`}
        input={input ?? <StyledOutlinedInput $paddingRightInput={paddingRightInput} />}
        labelId={`select-label_${id ?? idFromLabel}`}
        MenuProps={
          MenuProps ?? {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              marginTop: '8px',
            },
          }
        }
        renderValue={
          renderValue ??
          (placeholder &&
            ((selected) => {
              if (!selected) {
                return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
              }
              const matchingOption = valuesAndLabelsOffChildren.find((option) => option.value === selected);
              return matchingOption?.label;
            }))
        }
        SelectDisplayProps={SelectDisplayProps}
        value={value}
        onChange={onChange}
      >
        {includeNoneOption && <MenuItem value=''>None</MenuItem>}
        {children}
      </StyledSelect>
      {errorMessage && (
        <ErrorMessage $fullWidth={!label} error>
          {errorMessage}
        </ErrorMessage>
      )}
      {onDelete && (
        <IconButton
          aria-label='delete'
          data-testid='delete-select-button'
          style={{ width: 'fit-content' }}
          onClick={onDelete}
        >
          <TrashCan />
        </IconButton>
      )}
    </FormControl>
  );
});
