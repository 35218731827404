import keyMirror from 'keymirror';

export const endPoints = {};

export const actions = keyMirror({
  ADD_NOTIFICATION: null,
  REMOVE_NOTIFICATION: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
