import { Peripheral } from "../shared/Peripheral";
import type { ConnectionType } from "../shared/index";
import { PeripheralType } from "../shared/index";

export abstract class LabelPrinter extends Peripheral {
  constructor(connectionType: ConnectionType) {
    super(PeripheralType.labelPrinter, connectionType);
  }
  
  abstract print(bytes: Uint8Array): Promise<boolean>
}
