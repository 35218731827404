import { atom, useRecoilState } from 'recoil';

export enum Modal {
  addReason = 'addReason',
  confirm = 'confirm',
  createTemplate = 'createTemplate',
  exportTable = 'exportTable',
  permissionDenied = 'permissionDenied',
  printTable = 'printTable',
}

export type ModalState = {
  state?: {
    tableName?: string;
    title?: string;
  };
};

// state
const modalsAtom = atom({
  key: 'modals',
  default: {},
});

// actions
export function useModal(name: Modal) {
  const [modalsState, setModalsState] = useRecoilState(modalsAtom);

  const state = modalsState[name];
  const openModal = (data?: any) => setModalsState((prev) => ({ ...prev, [name]: data || true }));
  const closeModal = () => setModalsState((prev) => ({ ...prev, [name]: undefined }));

  return {
    state,
    openModal,
    closeModal,
  };
}
