import React, { useMemo } from 'react';

import { LinkButton } from 'src/app/components/lib/button';
import {
  generateRowActions,
  type GridValidRowModel,
  type GridColDef as MuiGridColDef,
} from 'src/app/components/lib/table';
import { useEnvironmentDetails } from 'src/app/queries/admin/get-environment-details';
import { cleanLdKey } from 'src/app/utils/clean-ld-key';

import { getLDUrl } from './get-ld-deets';

// Overriding the GridColDef definition to tie the `field` to the keys of `LDExplorerRow`
export type GridColDef<T extends GridValidRowModel> = Omit<MuiGridColDef<T>, 'field'> & {
  field: keyof LDExplorerRow | 'Actions';
};

export type LDExplorerRow = {
  id: string;
  name: string;
  value: string;
};

export function transformToLDExplorerRow(data: Record<string, any>): LDExplorerRow[] {
  return Object.keys(data).map((key) => {
    const value = data[key];

    return {
      id: key,
      name: key,
      value: JSON.stringify(value),
    };
  });
}

export function useColumns(): GridColDef<LDExplorerRow>[] {
  const { data } = useEnvironmentDetails();

  return useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        renderCell: ({ value }) => (
          <LinkButton
            label={value}
            onClick={() => window.open(`${getLDUrl(data?.Data?.LaunchDarklyClientKey)}/${String(value)}`, '_blank')}
          />
        ),
      },
      {
        field: 'value',
        headerName: 'Value',
      },
      generateRowActions([
        {
          visible: true,
          value: 'Open in Github',
          onClick: (row: LDExplorerRow) => {
            window.open(`https://github.com/search?q=repo%3AGetDutchie%2Fback-office+${row.name}&type=code`, '_blank');
          },
        },
        {
          visible: true,
          value: 'Open in Datadog',
          onClick: (row: LDExplorerRow) => {
            window.open(
              `https://app.datadoghq.com/rum/feature-flags/${cleanLdKey(
                row.name
              )}?query=%40application.id%3A0e94699a-652a-47d8-8c5a-f86cf996f90f&from_ts=1692225843406&to_ts=1692312243406&live=true`,
              '_blank'
            );
          },
        },
        {
          visible: true,
          value: 'Open in LD',
          onClick: (row: LDExplorerRow) => {
            window.open(`${getLDUrl(data?.Data?.LaunchDarklyClientKey)}/${String(row.name)}`, '_blank');
          },
        },
        {
          visible: true,
          value: 'Copy to Clipboard',
          onClick: (row: LDExplorerRow) => {
            void navigator.clipboard.writeText(row.name);
          },
        },
        {
          visible: true,
          value: 'Create EC Ticket',
          onClick: (row: LDExplorerRow) => {
            window.open(
              `https://dutchie.atlassian.net/secure/CreateIssueDetails!init.jspa?customfield_10570=${row.name}&pid=10165&issuetype=10297&summary=Update%20LD%20Flag&components=12866&customfield_10566=Core-CAB`,
              '_blank'
            );
          },
        },
      ]),
    ],
    [data]
  );
}
