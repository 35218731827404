import { InputBuffer } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import { PeripheralService } from "../../shared/PeripheralService";
import type { PeripheralServiceConfig } from "../../shared/types";
import { PeripheralType, WebApi } from "../../shared/types";

import { HidScanner } from "./HidScanner";

export class HidScannerService extends PeripheralService<HidScanner> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.scanner, { autoConnect: true, name: 'HidScannerService', autoEnable: true}, config)
  }

  get isSupported(): boolean { return !!navigator.hid; }

  // A connection event is fired for every known hid device. If disconnecting a hub
  // this could fire multiple times. A buffer is used to prevent multiple invalidations
  // within a short period of time.
  private connectionEventBuffer = new InputBuffer<Event>({
    name: 'HIDScannerService',
    onCandidateDetected: () => HardwareService.scanner.invalidate(this.name),
  });
  private handleConnectionChangedRef = this.handleConnectionChanged.bind(this);
  private handleConnectionChanged(event: Event): void {
    this.connectionEventBuffer.push(event);
  }

  attachEventListeners() : void {
    WebApi.hid.addEventListener('connect', this.handleConnectionChangedRef);
    WebApi.hid.addEventListener('disconnect', this.handleConnectionChangedRef);
  }
  
  detachEventListeners(): void {
    WebApi.hid.removeEventListener('connect', this.handleConnectionChangedRef);
    WebApi.hid.removeEventListener('disconnect', this.handleConnectionChangedRef);
  }

  async availableDevices(): Promise<HidScanner[]> {
    try {
      const devices = await WebApi.hid.getDevices();
      return devices
        .filter(device => this.vendorIds.indexOf(device.vendorId) !== -1)
        .map((device) => new HidScanner(device))
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return [];
  }

  async requestNewDevices(): Promise<HidScanner[]> {
    try {
      const filters: HIDDeviceFilter[] = this.isFilterEnabled ? this.vendorIds.map(id => {
        return {
          vendorId: id,
        } as HIDDeviceFilter;
      }) : [];
      
      const devices = await WebApi.hid.requestDevice({ filters })
      return devices.map((device) => new HidScanner(device));
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return [];
  }
}
