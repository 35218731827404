import React from 'react';

export enum E2EOnlyTargets {
  CtxKindIsInSegment = 'e2e-only-context-kind-is-in-segment-rule',
  CtxKindUserEmail = 'e2e-ctx-email-rule',
  CtxKindUserLocId = 'e2e-ctx-loc-id-rule',
  CtxKindUserLspId = 'e2e-ctx-lsp-id-rule',
  CtxKindUserRegion = 'e2e-ctx-region-rule',
  Individual = 'e2e-only-individual-target',
}

export enum E2EOnlyPage {
  IsVisible = 'e2e-only-page-is-visible',
  Route = 'e2e-only-page-route',
}

export function E2EOnlyPageComponent() {
  return (
    <div className='page-heading'>
      <h1>{E2EOnlyPage.IsVisible}</h1>
    </div>
  );
}
