import React from 'react';

export function RegistersIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M7.04052 10.9458C6.75108 10.953 6.47557 11.0657 6.27084 11.2606C6.06611 11.4556 5.94777 11.718 5.94025 11.9937C5.93438 12.2088 5.99639 12.4207 6.11826 12.602C6.24013 12.7832 6.41626 12.9255 6.62391 13.0105C6.83157 13.0954 7.06119 13.1191 7.28312 13.0785C7.50505 13.0379 7.7091 12.9348 7.8689 12.7826C8.02871 12.6304 8.13692 12.4361 8.17957 12.2247C8.22222 12.0134 8.19734 11.7947 8.10815 11.5969C8.01896 11.3991 7.86956 11.2314 7.67924 11.1153C7.48892 10.9993 7.26644 10.9402 7.04052 10.9458Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12.0405 10.9458C11.7511 10.953 11.4756 11.0657 11.2708 11.2606C11.0661 11.4556 10.9478 11.718 10.9403 11.9937C10.9344 12.2088 10.9964 12.4207 11.1183 12.602C11.2401 12.7832 11.4163 12.9255 11.6239 13.0105C11.8316 13.0954 12.0612 13.1191 12.2831 13.0785C12.5051 13.0379 12.7091 12.9348 12.8689 12.7826C13.0287 12.6304 13.1369 12.4361 13.1796 12.2247C13.2222 12.0134 13.1973 11.7947 13.1081 11.5969C13.019 11.3991 12.8696 11.2314 12.6792 11.1153C12.4889 10.9993 12.2664 10.9402 12.0405 10.9458Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M17.0405 10.9458C16.7511 10.953 16.4756 11.0657 16.2708 11.2606C16.0661 11.4556 15.9478 11.718 15.9403 11.9937C15.9344 12.2088 15.9964 12.4207 16.1183 12.602C16.2401 12.7832 16.4163 12.9255 16.6239 13.0105C16.8316 13.0954 17.0612 13.1191 17.2831 13.0785C17.5051 13.0379 17.7091 12.9348 17.8689 12.7826C18.0287 12.6304 18.1369 12.4361 18.1796 12.2247C18.2222 12.0133 18.1973 11.7947 18.1082 11.5969C18.019 11.3991 17.8696 11.2314 17.6792 11.1153C17.4889 10.9993 17.2664 10.9402 17.0405 10.9458Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M2.25 16.7646C1.83579 16.7646 1.5 17.0844 1.5 17.4789V20.8122C1.5 21.2067 1.83579 21.5265 2.25 21.5265H21.75C22.1642 21.5265 22.5 21.2067 22.5 20.8122V17.4789C22.5 17.0844 22.1642 16.7646 21.75 16.7646H2.25ZM0 17.4789C0 16.2954 1.00736 15.336 2.25 15.336H21.75C22.9926 15.336 24 16.2954 24 17.4789V20.8122C24 21.9957 22.9926 22.9551 21.75 22.9551H2.25C1.00736 22.9551 0 21.9957 0 20.8122V17.4789Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M5 7.38365C5 6.98916 5.33579 6.66937 5.75 6.66937H12.75C13.1642 6.66937 13.5 6.98916 13.5 7.38365C13.5 7.77814 13.1642 8.09794 12.75 8.09794H5.75C5.33579 8.09794 5 7.77814 5 7.38365Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M5.15 5.00023C5.15143 5.39472 4.81681 5.71562 4.4026 5.71698C4.21194 5.71761 4.0285 5.78646 3.88865 5.90988C3.74881 6.0333 3.66278 6.20227 3.64763 6.38327L2.9976 16.1074C2.96451 16.5006 2.60297 16.7938 2.19008 16.7623C1.77719 16.7308 1.46931 16.3865 1.5024 15.9933L2.15237 6.26975C2.1975 5.73162 2.45333 5.22898 2.8691 4.86204C3.28495 4.49503 3.83046 4.29029 4.3974 4.28842C4.81162 4.28706 5.14856 4.60574 5.15 5.00023Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M13.5 5.0027C13.5 4.60821 13.8358 4.28842 14.25 4.28842H19.6026C20.1695 4.29029 20.715 4.49503 21.1309 4.86204C21.5467 5.22898 21.8025 5.73131 21.8476 6.26945L22.4976 15.9933C22.5307 16.3865 22.2228 16.7308 21.8099 16.7623C21.397 16.7938 21.0355 16.5006 21.0024 16.1074L20.3524 6.38358C20.3372 6.20258 20.2512 6.0333 20.1113 5.90988C19.9718 5.78669 19.7887 5.71787 19.5985 5.71699H14.25C13.8358 5.71699 13.5 5.39719 13.5 5.0027Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M11.729 1.11972C11.9007 1.25542 12 1.45688 12 1.66937V7.38365C12 7.77814 11.6642 8.09794 11.25 8.09794H7.25C6.83579 8.09794 6.5 7.77814 6.5 7.38365V2.38366C6.5 2.03993 6.75705 1.74495 7.11178 1.6816L11.1118 0.967318C11.3311 0.928159 11.5573 0.984017 11.729 1.11972ZM8 2.97646V6.66937H10.5V2.53003L8 2.97646Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M9 19.1456C9 18.7511 9.33579 18.4313 9.75 18.4313H14.25C14.6642 18.4313 15 18.7511 15 19.1456C15 19.54 14.6642 19.8598 14.25 19.8598H9.75C9.33579 19.8598 9 19.54 9 19.1456Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
    </svg>
  );
}
