import { useEffect } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { userAtom } from '../state/user';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    zE: {
      activate: () => void;
      (target: string, action: string, options?: Record<string, unknown> | (() => void)): void;
    };
  }
}

export default function useZendesk() {
  const user = useRecoilValue(userAtom);
  const flags = useFlags();
  const enableZendesk = flags['enable-back-office-zendesk'];
  const widgetKey = 'ffeeab13-1fe5-4896-9223-6d3b19d9b497';
  const zendeskInitScript = document.createElement('script');
  const zendeskElementId = 'ze-snippet';
  zendeskInitScript.id = zendeskElementId;
  zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`;
  zendeskInitScript.async = true;

  function removeZendeskWidget() {
    if (_.isFunction(window.zE)) {
      window.zE('webWidget', 'hide');
    }
    if (document.getElementById(zendeskElementId)) {
      document.body.removeChild(zendeskInitScript);
    }
  }

  useEffect(() => {
    const hasChild = document.getElementById(zendeskElementId);
    // if the flag is off and the widget is already loaded, remove it
    if (!enableZendesk && hasChild) {
      removeZendeskWidget();
    }

    if (enableZendesk && !hasChild) {
      document.body.appendChild(zendeskInitScript);
    }

    return removeZendeskWidget;
  }, [enableZendesk]);

  useEffect(() => {
    // auto populate the contact form
    if (_.isFunction(window.zE)) {
      window.zE('webWidget', 'prefill', {
        name: {
          value: user.FullName,
        },
        email: {
          value: user.UserName,
        },
      });
    }
  }, [user.FullName, user.UserName]);

  useEffect(() => {
    if (_.isFunction(window.zE)) {
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', () => window.zE('webWidget', 'hide'));
    }
  }, [window.zE]);
}
