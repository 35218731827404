import React, { useMemo } from 'react';

import { Modal, ModalContentText } from 'src/app/components/lib/modal';

import type { ButtonPropsVariant } from 'src/app/components/lib/button';

type ConfirmModalProps = {
  cancelButtonAutomationId?: string;
  confirmButtonAutomationId?: string;
  confirmButtonLabel?: string;
  confirmButtonVariant?: ButtonPropsVariant;
  Content?: React.ReactNode;
  helpText?: React.ReactNode;
  loadingState?: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void> | void;
  open: boolean;
  skipCloseOnConfirm?: boolean;
  title?: string;
};

function ConfirmModal({
  loadingState,
  skipCloseOnConfirm = false,
  cancelButtonAutomationId,
  confirmButtonAutomationId,
  confirmButtonLabel = 'Confirm',
  confirmButtonVariant = 'primary',
  Content,
  open,
  onConfirm,
  onClose,
  title,
  helpText,
}: ConfirmModalProps) {
  const [loading, setLoading] = React.useState(false);
  const useExternalLoader = loadingState !== undefined;

  const modalContent = useMemo(() => {
    if (Content !== undefined) {
      return Content;
    }
    if (helpText !== undefined) {
      return <ModalContentText>{helpText}</ModalContentText>;
    }
    return undefined;
  }, [Content, helpText]);

  async function handleConfirm() {
    setLoading(true);
    try {
      await onConfirm();
    } finally {
      setLoading(false);
      if (!skipCloseOnConfirm) {
        onClose();
      }
    }
  }

  return (
    <Modal
      Content={modalContent}
      open={open}
      primaryActions={[
        {
          automationId: confirmButtonAutomationId,
          label: confirmButtonLabel,
          loading: useExternalLoader ? loadingState : loading,
          disabled: useExternalLoader ? loadingState : loading,
          variant: confirmButtonVariant,
          onClick: () => void handleConfirm(),
        },
      ]}
      secondaryActions={[
        { automationId: cancelButtonAutomationId, label: 'Cancel', variant: 'secondary', onClick: onClose },
      ]}
      title={title || 'Are you sure?'}
      onClose={onClose}
    />
  );
}

export default ConfirmModal;
