import React from 'react';

import { useNavigate } from 'react-router-dom';

import PermissionDeniedModal from 'src/app/components/modals/modals/permission-denied-modal';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import type { PermissionVals } from 'src/app/state/user-permissions';

type PrivateComponentProps = {
  booleanPermissionOverride?: boolean;
  component: JSX.Element;
  permission?: PermissionVals;
};

export function PrivateComponent({ component, permission, booleanPermissionOverride }: PrivateComponentProps) {
  const navigate = useNavigate();
  const hasPerms = usePermissionCheck(permission ?? 1);
  const canViewComponent = booleanPermissionOverride ?? hasPerms;
  if (!canViewComponent) {
    return <PermissionDeniedModal open onClose={() => navigate('/', { replace: true })} />;
  }
  return component;
}
