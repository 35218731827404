import React from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { userAtom } from 'src/app/state/user';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { Text, generalBannerStyles, SMALL_BANNER_HEIGHT } from './shared';

export function useNonSandboxBannerIsVisible() {
  const { selectedLocation } = useRecoilValue(userDispensariesAtom);
  const { UserName = '' } = useRecoilValue(userAtom);
  const isSuperUser = usePermissionCheck(Permissions.SuperUser);
  const isInternalUser = UserName.includes('@dutchie.com') && isSuperUser;

  return !selectedLocation?.IsSandbox && isInternalUser;
}

export function NonSandboxBanner() {
  const isVisible = useNonSandboxBannerIsVisible();

  if (!isVisible) {
    return null;
  }

  return (
    <Banner>
      <Text>
        <span>Please note you are in production viewing a non sandbox account. Do not make unauthorized changes.</span>
      </Text>
    </Banner>
  );
}

const Banner = styled.div`
  background-color: #ff3e51;
  height: ${SMALL_BANNER_HEIGHT}px;

  ${generalBannerStyles}
`;
