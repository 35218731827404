import React from 'react';

type BohCaretDownProps = {
  className?: string;
  height?: string;
  width?: string;
};

export function BohCaretDown({ className, width, height }: BohCaretDownProps): JSX.Element {
  return (
    <div className={className}>
      <svg fill='none' height={height ?? '7'} width={width ?? '10'} xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M.293 1.854 1.707.44 5 3.733 8.293.44l1.414 1.414L5 6.561.293 1.854Z'
          fill='#000'
          fillRule='evenodd'
        />
      </svg>
    </div>
  );
}
