import React from 'react';

import { RowActionCell } from './cells';
import { ColumnsSettings } from './columns-settings';
import { ActionsFieldName } from './data-grid';

import type { GridColDef } from '@mui/x-data-grid-pro';

export type Action = {
  automationId?: string;
  onClick: ((row: any) => Promise<void>) | ((row: any) => void);
  value: string | ((row: any) => string);
  visible: boolean | ((row: any) => boolean);
};

export function generateRowActions(
  actions: Action[],
  rowLoading = { loading: false, rowId: '' },
  forceVisible = false,
  hideTableSettings = false,
  allowColumnReordering = true
) {
  const ColumnsSettingsWithProps = <ColumnsSettings allowColumnReordering={allowColumnReordering} />;

  return {
    disableColumnMenu: true,
    disableExport: true,
    disableReorder: true,
    editable: false,
    field: ActionsFieldName,
    filterable: false,
    hide: !actions.some(({ visible }) => visible) && !forceVisible,
    hideSortIcons: true,
    hideable: false,
    resizable: false,
    sortable: false,
    type: 'actions',
    minWidth: 48,
    width: 48,
    headerAlign: 'right',
    align: 'right',
    renderCell: (params) => <RowActionCell actions={actions} params={params} rowLoading={rowLoading} />,
    renderHeader: !hideTableSettings ? () => ColumnsSettingsWithProps : undefined,
  } as const;
}
export const settingsColumnWithoutActions = ({ allowColumnReordering = true } = {}): GridColDef => ({
  cellClassName: 'data-grid--empty-actions-cell',
  disableColumnMenu: true,
  disableExport: true,
  disableReorder: true,
  editable: false,
  field: ActionsFieldName,
  filterable: false,
  hide: false,
  hideSortIcons: true,
  hideable: false,
  resizable: false,
  sortable: false,
  type: 'actions',
  minWidth: 48,
  width: 48,
  headerAlign: 'right',
  align: 'right',
  renderCell: () => null,
  renderHeader: () => <ColumnsSettings allowColumnReordering={allowColumnReordering} />,
});
