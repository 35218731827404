import React, { useState, useEffect } from 'react';

import { getDaysInMonth, format, isValid, parseISO } from 'date-fns';
import _ from 'lodash';
import getUuid from 'uuid/v1';

import { ChevronLeft } from 'src/app/components/icons/chevron-left';
import { ChevronRight } from 'src/app/components/icons/chevron-right';
import { CloseSmall } from 'src/app/components/icons/close-small';
import { ExitCircle } from 'src/app/components/icons/exit-circle';
import { Input } from 'src/app/components/lib/input';
import { formatToMilitaryTime } from 'src/app/utils/formatters';

import { IconButton } from '../icon-button';

import { DatePickerMonth } from './date-picker-month';
import {
  RebrandDatePicker,
  RebrandDatePickerHeader,
  RebrandDatePickerClearDate,
  RebrandDatePickerMonths,
  RebrandContainer,
  StyledYearPicker,
} from './date-picker-rebrand.styles';

const getDaysArrayByMonth = ({ year, month }: { month: string; year: string }) => {
  const numberOfDaysInMonth = getDaysInMonth(new Date(Number(year), Number(month) - 1));
  const arrDays = [];

  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    const dateInMonth = new Date(`${month}/${i}/${year}`);
    arrDays.push({
      date: format(dateInMonth, 'MM/dd/yyyy'),
      dd: format(dateInMonth, 'EEEEEE'),
    });

    if (format(dateInMonth, 'EEEEEE') === 'Sa') {
      arrDays.push({
        date: false,
        dd: false,
      });
    }
  }
  return arrDays;
};

const getDate = (initialDate) => {
  // JavaScript date object returns the previous day if the initial date comes in as this format: 'yyyy-MM-dd'
  // This helper outputs the correct date regardless of the initialDate format
  if (initialDate && isValid(new Date(initialDate))) {
    const parsedISO = parseISO(initialDate);
    const ISOFormattedDate = isValid(parsedISO) ? parsedISO : initialDate;
    return new Date(ISOFormattedDate);
  }
  return new Date();
};
export function DatePicker(props) {
  const {
    automationId = '',
    label,
    labelPlacement,
    tooltip,
    onDateChange,
    selectMonthOnly = false,
    selectYearWithMonth = false,
    children,
    disabled = false,
    disableClearDate = false,
    allowPastDate = false,
    enableTime = false,
    required = false,
    date: initialDate,
    beforeSelectedDate: initialbeforeSelectedDate = '',
    afterSelectedDate: initialafterSelectedDate = '',
    gridColumns,
    inputSx,
    className,
    errorMessage,
    placeholder,
    hideValueWhenDisabled = false,
    disabledPlaceholder = '',
  } = props;

  const date = getDate(initialDate);
  const today = new Date();
  const initialMonth = format(date, 'MM');
  const initialYear = format(date, 'yyyy');
  const initialTime = isValid(date) ? format(date, 'hh:mm a') : '12:00 AM';
  const beforeSelectedDate = new Date(initialbeforeSelectedDate);
  const afterSelectedDate = new Date(initialafterSelectedDate);
  const [year, setYear] = useState<string>(initialYear ?? '');
  const [month, setMonth] = useState<string>(initialMonth ?? '');
  const [time, setTime] = useState(enableTime ? initialTime : '');
  const currentMonth = getDaysArrayByMonth({ year, month });
  const monthNumber = parseInt(month, 10);
  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const yearNumber = parseInt(year, 10);

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const open = Boolean(anchorEl);
  const uuid: string = getUuid();

  const [selectByMonth, setSelectByMonth] = useState(false);
  const showMonthSelection = selectByMonth || selectMonthOnly;

  const isValidDate = initialDate && isValid(date);
  const getFormattedDate = () => {
    if (!isValidDate) {
      return '';
    }
    return enableTime ? format(date, 'MM/dd/yyyy hh:mm a') : format(date, 'MM/dd/yyyy');
  };
  const formattedDate = getFormattedDate();
  const [inputValue, setInputValue] = useState<string | null>(formattedDate ?? null);
  const hasValidInputValue = Boolean(inputValue) && isValid(new Date(inputValue));
  const showClearX = !disableClearDate && !disabled && !!inputValue;

  useEffect(() => {
    if (isValidDate && formattedDate !== inputValue) {
      setInputValue(formattedDate);
    }
  }, [formattedDate]);

  useEffect(() => {
    const inputValueDate = new Date(inputValue);
    if (hasValidInputValue) {
      const inputValueTime = format(inputValueDate, 'hh:mm a');
      if (inputValueTime !== time && enableTime) {
        setTime(inputValueTime);
        setMonth(format(inputValueDate, 'MM'));
        setYear(format(inputValueDate, 'yyyy'));
      }
    }
  }, [enableTime, hasValidInputValue, inputValue, time]);

  useEffect(() => {
    if (!initialYear) {
      setYear(initialYear);
    } else if (initialYear && year !== initialYear) {
      setYear(year);
    }
  }, [initialYear, year]);

  useEffect(() => {
    if (!initialMonth) {
      setMonth(initialMonth);
    } else if (initialMonth && month !== initialMonth) {
      setMonth(month);
    }
  }, [initialMonth, month]);

  useEffect(() => {
    if (!initialTime) {
      setTime(initialTime);
    } else if (initialTime && time !== initialTime) {
      setTime(time);
    }
  }, [initialTime, time]);

  function handleClick({ currentTarget }) {
    setAnchorEl(currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleInputChange() {
    if (inputValue === formattedDate) {
      return;
    }

    if (!hasValidInputValue) {
      setInputValue(formattedDate ?? null);
      return;
    }
    if (hasValidInputValue && enableTime) {
      handleSelectNewTime(inputValue);
    }

    if (hasValidInputValue) {
      handleSelectDate(inputValue);
    }

    const inputValueDate = new Date(inputValue);

    setTime(format(inputValueDate, 'hh:mm a'));
    setMonth(format(inputValueDate, 'MM'));
    setYear(format(inputValueDate, 'yyyy'));
  }

  function handleSelectNewTime(updatedDateWithNewTime: string | null) {
    const dateWithNewTime = new Date(updatedDateWithNewTime);
    setTime(format(dateWithNewTime, 'hh:mm a'));
    if (onDateChange) {
      onDateChange(dateWithNewTime);
    }

    setInputValue(updatedDateWithNewTime);
  }

  function handleSelectDate(selectedDate: string) {
    const formattedSelectedDate = format(new Date(selectedDate), 'MM/dd/yyyy');
    if (enableTime && onDateChange) {
      const updatedDateWithTime = `${formattedSelectedDate} ${time}`;
      onDateChange(new Date(updatedDateWithTime));
      setInputValue(updatedDateWithTime);
    }

    if (onDateChange && !enableTime) {
      onDateChange(new Date(formattedSelectedDate));
      setInputValue(formattedSelectedDate);
    }

    setSelectByMonth(false);
    setAnchorEl(null);
  }

  function setByMonth(monthToUpdate: string) {
    const updatedMonth = month.toString().length === 1 ? `0${monthToUpdate}` : monthToUpdate;
    if (selectMonthOnly) {
      onDateChange(monthToUpdate, year);
      setMonth(updatedMonth);
      setAnchorEl(null);
    } else {
      setMonth(updatedMonth);
      setSelectByMonth(false);
    }
  }

  function nextMonth() {
    const updatedYear = monthNumber + 1 <= 12 ? yearNumber : yearNumber + 1;
    const updatedMonth = monthNumber + 1 <= 12 ? monthNumber + 1 : 1;
    setYear(updatedYear.toString());
    setMonth(updatedMonth.toString());
  }

  function prevMonth() {
    const updatedYear = monthNumber - 1 >= 1 ? yearNumber : yearNumber - 1;
    const updatedMonth = monthNumber - 1 >= 1 ? monthNumber - 1 : 12;
    setYear(updatedYear.toString());
    setMonth(updatedMonth.toString());
  }

  function handleClearDate() {
    onDateChange(null);
    setInputValue('');
    setMonth(format(today, 'MM'));
    setYear(format(today, 'yyyy'));
    if (enableTime) {
      setTime('12:00 AM');
    }
  }

  return (
    <>
      {children ? (
        <div onClick={handleClick}>{children}</div>
      ) : (
        <Input
          className={className}
          disabled={disabled}
          endAdornment={
            showClearX && (
              <IconButton
                aria-label='Clear Date Input'
                data-testid='clear-date-input'
                sx={{ padding: 0 }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  // Stop the event from propagating to the parent onClick event
                  e.stopPropagation();
                  handleClearDate();
                }}
              >
                <ExitCircle />
              </IconButton>
            )
          }
          errorMessage={errorMessage}
          gridColumns={gridColumns}
          inputProps={{ autoComplete: 'off' }}
          label={label}
          labelPlacement={labelPlacement}
          placeholder={placeholder}
          required={required}
          sx={inputSx}
          tooltip={tooltip}
          value={hideValueWhenDisabled && disabled ? disabledPlaceholder : inputValue}
          onBlur={handleInputChange}
          onChange={({ target }) => setInputValue(target.value)}
          onClick={disabled ? _.noop : handleClick}
        />
      )}

      <RebrandDatePicker
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        componentsProps={{
          root: {
            title: label,
          },
        }}
        data-testid={automationId}
        disableAutoFocus
        id={uuid}
        open={open}
        onClose={() => handleClose()}
      >
        {!showMonthSelection && (
          <>
            <RebrandDatePickerHeader data-testid='date-picker-header'>
              <ChevronLeft onClick={prevMonth} />
              <div>
                <span
                  onClick={() => {
                    setSelectByMonth(true);
                  }}
                >
                  {format(new Date(currentMonth[0].date), 'MMMM')}
                </span>

                <input
                  type='number'
                  value={year}
                  onBlur={({ target }) => {
                    const value = Number(target.value);
                    if (value < 0) {
                      setYear(String(Math.abs(value)));
                    }
                  }}
                  onChange={({ target }) => {
                    const { value } = target;
                    setYear(value);
                  }}
                />
              </div>
              <ChevronRight onClick={nextMonth} />
            </RebrandDatePickerHeader>

            <DatePickerMonth
              afterSelectedDate={afterSelectedDate}
              allowPastDate={allowPastDate}
              beforeSelectedDate={beforeSelectedDate}
              data={currentMonth}
              selectDate={(d) => handleSelectDate(d)}
              selected={date}
              today={today}
            />

            {!disableClearDate && (
              <RebrandDatePickerClearDate onClick={handleClearDate}>Clear date</RebrandDatePickerClearDate>
            )}

            {enableTime && (
              <RebrandContainer id={`date-picker-time_${label}`}>
                <input
                  type='time'
                  value={formatToMilitaryTime(time)}
                  onChange={(e) => {
                    const currentDate = isValid(date) ? format(date, 'MM/dd/yyyy') : format(new Date(), 'MM/dd/yyyy');
                    handleSelectNewTime(format(new Date(`${currentDate} ${e.target.value}`), 'MM/dd/yyyy h:mm a'));
                  }}
                />
              </RebrandContainer>
            )}
          </>
        )}

        {showMonthSelection && (
          <RebrandDatePickerMonths>
            <div className='year'>
              {selectYearWithMonth ? (
                <StyledYearPicker>
                  <input
                    type='number'
                    value={year}
                    onBlur={({ target }) => {
                      const value = Number(target.value);
                      if (value < 0) {
                        setYear(String(Math.abs(value)));
                      }
                    }}
                    onChange={({ target }) => {
                      const { value } = target;
                      setYear(value);
                    }}
                  />
                </StyledYearPicker>
              ) : (
                year
              )}
            </div>
            <CloseSmall
              className={`close close_${uuid}`}
              onClick={() => {
                if (selectMonthOnly) {
                  setAnchorEl(null);
                } else {
                  setSelectByMonth(false);
                }
              }}
            />

            {monthList.map((m, i) => {
              const selected = monthNumber === i + 1 ? 'selected' : '';
              const current = parseInt(format(today, 'MM'), 10) === i + 1 ? 'current' : '';
              return (
                <div
                  className={`month-option ${selected} ${current}`}
                  key={`month-${m}`}
                  onClick={() => {
                    const chosenMonth = i + 1;
                    setByMonth(chosenMonth.toString());
                  }}
                >
                  {m}
                </div>
              );
            })}
          </RebrandDatePickerMonths>
        )}
      </RebrandDatePicker>
    </>
  );
}
