import { SimpleCachedDataStore } from './SimpleCachedDataStore';

class PurchaseLimitCategoryStore extends SimpleCachedDataStore {
  sourceUrl = 'api/purchase-limits/get-categories';
  entity = 'Purchase Limit Categories';
  get categories() {
    return this.data;
  }
}
export default new PurchaseLimitCategoryStore();
