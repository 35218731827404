import React from 'react';

type AccountIconProps = {
  onClick?: (e) => void;
};

export function AccountIcon({ onClick }: AccountIconProps): JSX.Element {
  return (
    <svg
      data-testid='account-icon_svg_icon'
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        clipRule='evenodd'
        d='M12 17C10.6762 17 9.15406 17.3514 8 18C6.84594 18.6486 5.68831 19.8692 5 21C4.75387 21.4044 4.63699 21.3783 4.23262 21.1322C3.82825 20.886 3.69998 20.3587 3.94611 19.9543C4.78739 18.5722 5.97018 17.4299 7.3807 16.6372C8.79121 15.8445 10.382 15.4281 12 15.4281C13.618 15.4281 15.2088 15.8445 16.6193 16.6372C18.0298 17.4299 19.2126 18.5722 20.0539 19.9543C20.3 20.3587 20.1717 20.886 19.7674 21.1322C19.363 21.3783 18.7461 20.9044 18.5 20.5C17.8117 19.3692 17.1541 18.6486 16 18C14.8459 17.3514 13.3238 17 12 17Z'
        fill='#606060'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472ZM12 1.71429C9.27206 1.71429 6.65585 2.79796 4.7269 4.7269C2.79796 6.65585 1.71429 9.27206 1.71429 12C1.71429 14.7279 2.79796 17.3442 4.7269 19.2731C6.65585 21.202 9.27206 22.2857 12 22.2857C14.7279 22.2857 17.3442 21.202 19.2731 19.2731C21.202 17.3442 22.2857 14.7279 22.2857 12C22.2857 9.27206 21.202 6.65585 19.2731 4.7269C17.3442 2.79796 14.7279 1.71429 12 1.71429Z'
        fill='#606060'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M16.5 10C16.5 12.4853 14.4853 14.5 12 14.5C9.51472 14.5 7.5 12.4853 7.5 10C7.5 7.51472 9.51472 5.5 12 5.5C14.4853 5.5 16.5 7.51472 16.5 10ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
        fill='#606060'
        fillRule='evenodd'
      />
    </svg>
  );
}
