import React from 'react';

import $ from 'jquery';
import uuid from 'uuid/v1';

import NotificationActions from 'src/app_deprecated/actions/NotificationActions';
import ConfirmPopup from 'src/app_deprecated/components/common/ConfirmPopup.react';
import PopupHeader from 'src/app_deprecated/components/popup/PopupHeader.react';

class Note extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      uuid: uuid(),
      showAdditionalInfo: false,
      confirmClose: false,
    };

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    const self = this;

    if (!this.props.note.sticky) {
      setTimeout(() => {
        self.close(false);
      }, this.props.note.timer);
    }
  }

  close(e) {
    const self = this;

    const { uuid } = this.state;
    const { note } = this.props;

    if (e && e.target.id === `i${this.state.uuid}`) {
      this.setState({ showAdditionalInfo: true });
      return;
    }

    if (note.additionalInfo && !this.state.confirmClose) {
      (this.refs.ConfirmPopup as any).show();
      return;
    }

    $(`#${uuid} .note__content`).css({
      left: '400px',
      opacity: 0,
    });

    $(`#${uuid}`).css({
      height: parseInt($(`#${uuid}`).css('height')),
    });

    setTimeout(() => {
      $(`#${uuid}`).css({
        height: 0,
        'padding-top': 0,
      });

      setTimeout(() => {
        NotificationActions.removeNotification(self.props.note.uuid);
      }, 400);
    }, 400);
  }

  render() {
    const self = this;

    const { note } = this.props;
    const automationId = this.props.automationId || '';

    return (
      <>
        <div
          className='note'
          id={this.state.uuid}
          onClick={(e) => {
            this.close(e);
          }}
        >
          <div className={`note__content note__content--${note.type}`} data-testid={`${automationId}content`}>
            {note.body}

            {note.additionalInfo && (
              <>
                <br />
                <i className='fa fa-info-circle note__info--icon' id={`i${this.state.uuid}`} />
              </>
            )}
          </div>
        </div>
        {this.state.showAdditionalInfo && (
          <div className='smokescreen smokescreen__transparent'>
            <div className='popup' style={{ width: 640 }}>
              <PopupHeader
                header={{
                  actions: {
                    onClose: () => {
                      this.setState({ showAdditionalInfo: false, confirmClose: false });
                    },
                  },
                  title: 'Connection Error Details',
                }}
              />

              <div className='popup__content note__info'>
                {Object.keys(note.additionalInfo).map((x) => (
                  <div key={x}>
                    <div className='note__info--header'>{x}</div>
                    <div className='note__info--body'>{note.additionalInfo[x]}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <ConfirmPopup
          cancelLabel='Show Info'
          confirmLabel='Discard'
          ref='ConfirmPopup'
          title='Discard or Show Detailed Info'
          onCancel={() => {
            this.setState({ showAdditionalInfo: true });
          }}
          onConfirm={() => {
            this.setState({ confirmClose: true }, () => {
              self.close(false);
            });
          }}
        />
      </>
    );
  }
}

export default Note;
