import { useEffect } from 'react';

import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';

import { IS_PRODUCTION_APP } from 'src/app/constants/environment';
import { analyticsAtom } from 'src/app/state/analytics';

export function useTrackBrowserDimensions() {
  const analytics = useRecoilValue(analyticsAtom);

  useEffect(() => {
    const trackResize = debounce(() => {
      if (analytics && IS_PRODUCTION_APP) {
        analytics.track('Browser dimensions', { width: window.innerWidth, height: window.innerHeight });
      }
    }, 5000);

    // track initial size
    trackResize();

    // subscribe to resize
    window.addEventListener('resize', trackResize);

    // unsubscribe to resize
    return () => window.removeEventListener('resize', trackResize);
  }, [analytics]);
}
