import React from 'react';

import { TextField } from '@mui/material';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

import { SearchIcon } from 'src/app/components/icons/search-icon';
import { InputAdornment } from 'src/app/components/lib/input';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { TextFieldProps } from '@mui/material';

type SearchInputProps = TextFieldProps & { isActive: boolean; transparentBackground?: boolean };

export function SearchInput(props: SearchInputProps) {
  const { isActive, transparentBackground = false, ...rest } = props;
  const backgroundColor = transparentBackground ? 'transparent' : '#1F3244';

  return (
    <Input
      {...rest}
      $backgroundColor={backgroundColor}
      $isActive={isActive}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color={isActive ? 'var(--color-gray-70)' : 'var(--color-gray-10)'} />
          </InputAdornment>
        ),
      }}
    />
  );
}

type InputProps = TextFieldProps & {
  $backgroundColor?: string;
  $isActive: boolean;
};

export function Input({ $isActive, $backgroundColor = '#1F3244', ...rest }: InputProps) {
  const darkMode = useDarkMode();
  return <StyledInput $backgroundColor={$backgroundColor} $darkMode={darkMode} $isActive={$isActive} {...rest} />;
}

const StyledInput = styled(TextField)<{ $backgroundColor: string; $darkMode: boolean; $isActive: boolean }>`
  width: 100%;

  ${down('largeTablet')} {
    width: 310px;
  }

  padding: var(--sizes-30) var(--sizes-40);
  background-color: ${({ $isActive, $backgroundColor }) => ($isActive ? 'var(--color-gray-10)' : $backgroundColor)};
  border-radius: 6px;
  outline: 1px solid transparent;

  :hover {
    outline-color: var(--color-gray-80);
  }

  :focus {
    background-color: var(--color-brand-primary-white);
    outline-color: var(--color-brand-secondary-sapphire);
  }

  .MuiAutocomplete-input {
    font: var(--font-regular-14pt-normal);

    ::placeholder {
      opacity: 1;
      font-weight: 400;
      color: ${({ $isActive }) => ($isActive ? 'var(--color-gray-80)' : 'var(--color-gray-10)')};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiOutlinedInput-input {
    height: auto;
    padding: 0;
  }

  .MuiInputAdornment-root {
    margin-right: 12px;
  }

  .MuiInputBase-adornedStart {
    padding-left: 0;
  }
`;
