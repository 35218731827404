import { InputBuffer } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import type { Peripheral } from "../Peripheral";
import { PeripheralService } from "../PeripheralService";
import type { PeripheralServiceConfig, PeripheralType } from "../types";
import { WebApi } from "../types";

export abstract class WebSerialService<T extends Peripheral> extends PeripheralService<T> {
  constructor(peripheralType: PeripheralType, defaultConfig: PeripheralServiceConfig, config?: Partial<PeripheralServiceConfig>) {
    super(peripheralType, defaultConfig, config);
  }

  abstract createPort(port: SerialPort): T;

  abstract onInvalidate(): void;
  
  get isSupported(): boolean { return !!navigator.serial }
  
  // A connection event is fired for every known serial device. If disconnecting a hub
  // this could fire multiple times. A buffer is used to prevent multiple invalidations
  // within a short period of time.
  private connectionEventBuffer = new InputBuffer<Event>({
    name: 'WebSerialServiceHelper',
    onCandidateDetected: () => this.onInvalidate(),
  });

  private handleConnectionChangedRef = this.handleConnectionChanged.bind(this);
  private handleConnectionChanged(event: Event): void {
    this.connectionEventBuffer.push(event);
  }

  async availableDevices(): Promise<T[]> {
    try {
      const ports = await WebApi.serial.getPorts();
      return ports
        .filter(port => this.vendorIds.indexOf(port.getInfo().usbVendorId ?? 0) !== -1)
        .map(port => this.createPort(port));
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return [];
  }
  
  async requestNewDevices(): Promise<T[]> {
    try {
      const filters: SerialPortFilter[] = this.isFilterEnabled ? this.vendorIds.map(id => {
        return {
          usbVendorId: id
        } as SerialPortFilter
      }) : [];
      const port = await WebApi.serial.requestPort({ filters })
      if (port) {
        return [this.createPort(port)];
      }
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return []
  }

  detachEventListeners(): void {
    WebApi.serial.removeEventListener('connect', this.handleConnectionChangedRef);
    WebApi.serial.removeEventListener('disconnect', this.handleConnectionChangedRef);
  }
  
  attachEventListeners(): void {
    WebApi.serial.addEventListener('connect', this.handleConnectionChangedRef);
    WebApi.serial.addEventListener('disconnect', this.handleConnectionChangedRef);
  }
}
