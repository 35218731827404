import { useQuery } from '@tanstack/react-query';

import { endPoints as LocationSyncEndPoints } from 'src/app_deprecated/constants/CompanyConstants';

import { postData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

export const locsbylpsQueryKey: QueryKey = ['locsbylps'];
export type LocsByLspsResponse = {
  LocId: number;
  LocName: string;
  LspId: number;
  LspName: string;
  OrgId: number;
};

export function useLocsForLspsQuery<T = LocsByLspsResponse[]>(params?: {
  enabled?: boolean;
  payload: any;
  select?: (data: LocsByLspsResponse[]) => T;
  staleTime?: number;
}) {
  const { enabled, staleTime, select } = params ?? {};
  return useQuery({
    queryKey: locsbylpsQueryKey,
    queryFn: () =>
      postData<any, LocsByLspsResponse[]>({
        endpoint: LocationSyncEndPoints.GET_LOCATIONS_FOR_ADMIN,
        payload: {},
      }),
    enabled,
    select,
    staleTime,
  });
}
