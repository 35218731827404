import React from 'react';

import styled from 'styled-components';

type ChevronRightProps = {
  onClick?: () => void;
};

const ChevronContainer = styled.div`
  cursor: pointer;

  svg {
    display: block;
  }
`;

export function ChevronRight({ onClick }: ChevronRightProps) {
  return (
    <ChevronContainer onClick={onClick}>
      <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M5.74286 12.7287C5.54092 12.5033 5.54135 12.1385 5.74382 11.9137L9.26541 8.00474L5.75333 4.08516C5.55141 3.85981 5.55187 3.49496 5.75435 3.27025C5.95684 3.04553 6.28467 3.04604 6.48659 3.27139L10.1813 7.39477C10.2533 7.47506 10.3106 7.57064 10.3495 7.67546C10.3885 7.78043 10.4084 7.8929 10.4083 8.00646C10.4082 8.12002 10.3879 8.23244 10.3487 8.33728C10.3096 8.44199 10.2523 8.53709 10.1801 8.61716L6.4751 12.7297C6.27263 12.9545 5.9448 12.954 5.74286 12.7287Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M5.67794 12.8007C5.44025 12.5355 5.44075 12.106 5.67907 11.8415L9.13575 8.00455L5.68841 4.15721C5.45073 3.89196 5.45127 3.4625 5.68961 3.19799C5.92795 2.93348 6.31384 2.93408 6.55151 3.19933L10.2462 7.32271C10.3267 7.41248 10.3908 7.5193 10.4343 7.63651C10.4778 7.75387 10.5002 7.87963 10.5 8.0066C10.4998 8.13357 10.4772 8.25926 10.4334 8.37649C10.3896 8.49357 10.3255 8.5999 10.2448 8.68942L6.53985 12.802C6.30153 13.0665 5.91564 13.0659 5.67794 12.8007ZM5.80857 11.986C5.64195 12.1709 5.6416 12.4712 5.80778 12.6566C5.97396 12.842 6.24374 12.8424 6.41035 12.6575L10.1151 8.54522C10.1787 8.47461 10.2295 8.39041 10.2641 8.29807C10.2986 8.20561 10.3165 8.10647 10.3166 8.00632C10.3167 7.90618 10.2991 7.80699 10.2648 7.71442C10.2305 7.62197 10.1801 7.53795 10.1167 7.46715L6.42167 3.34344C6.2555 3.158 5.98572 3.15758 5.8191 3.3425C5.65247 3.52742 5.65209 3.82766 5.81825 4.01311L9.33034 7.93269C9.36609 7.97259 9.36601 8.03719 9.33016 8.07699L5.80857 11.986ZM9.38354 8.13662C9.41938 8.09682 9.47742 8.09688 9.51319 8.13675C9.54894 8.17665 9.54918 8.24159 9.51335 8.28139C9.47751 8.32119 9.41947 8.32113 9.3837 8.28126C9.34795 8.24137 9.3477 8.17642 9.38354 8.13662ZM9.38386 7.87322C9.34812 7.83331 9.34821 7.76872 9.38406 7.72893C9.41992 7.68917 9.47828 7.68893 9.51402 7.72884C9.54976 7.76874 9.54967 7.83334 9.51382 7.87312C9.47796 7.91289 9.4196 7.91312 9.38386 7.87322Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M5.49177 12.9676C5.16884 12.6072 5.16946 12.0337 5.49333 11.6742L8.79967 8.00409L5.50222 4.32404C5.17932 3.96369 5.17998 3.39011 5.50389 3.03064C5.84177 2.65566 6.40091 2.65663 6.7377 3.0325L10.4324 7.15584C10.5345 7.26975 10.6146 7.40392 10.6686 7.54952C10.7227 7.69522 10.7502 7.85058 10.75 8.0069C10.7498 8.16323 10.722 8.31851 10.6675 8.46406C10.6132 8.60944 10.533 8.74309 10.4305 8.8568L6.72559 12.9693C6.38774 13.3443 5.8286 13.3434 5.49177 12.9676ZM9.58387 8.13265C9.60038 8.15801 9.60673 8.18505 9.60677 8.20842C9.60683 8.23756 9.59708 8.27309 9.56932 8.30391C9.56473 8.30901 9.55974 8.31384 9.5544 8.31836C9.52666 8.34183 9.48933 8.35685 9.44814 8.35681C9.43753 8.3568 9.42718 8.35579 9.41719 8.3539M9.41799 7.65589L9.49701 7.74407L9.51653 7.76586C9.54651 7.79932 9.56906 7.83732 9.58415 7.87759C9.60066 7.85217 9.60708 7.82498 9.60711 7.80137C9.60715 7.77184 9.59722 7.73672 9.57009 7.70643C9.5655 7.7013 9.56053 7.69646 9.55522 7.69194M9.5876 7.8872C9.58504 7.88603 9.58247 7.88489 9.57989 7.88379C9.57683 7.88802 9.57346 7.89219 9.56979 7.89627M9.5876 7.8872C9.59505 7.90897 9.60037 7.93134 9.60355 7.95397C9.60828 7.98766 9.60828 8.02202 9.60353 8.0557C9.60031 8.07845 9.59492 8.10104 9.58739 8.1229C9.58478 8.12409 9.58216 8.12524 9.57954 8.12635M9.47061 7.807L9.45551 7.82053L9.47061 7.807ZM9.43281 8.20887L9.44832 8.19495L9.43281 8.20887ZM9.46408 8.20915L9.44857 8.22306L9.46408 8.20915Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
      </svg>
    </ChevronContainer>
  );
}
