import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

import type { PageVariant } from './page';

export const StyledPage = styled.div<{ $darkMode: boolean; variant?: PageVariant }>`
  margin: 0 auto;
  padding: var(--sizes-80) var(--sizes-100) var(--sizes-100) var(--sizes-100);
  max-width: 100%;
  overflow: auto;
  color: var(--color-grayscale-black);
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: inherit;
    `}
  height: 100%;
  display: flex;
  flex-direction: column;

  ${down('largeTablet')} {
    padding: var(--sizes-60) var(--sizes-70);
  }

  ${({ variant }) => css`
    ${variant === 'table' && tableVariant}
    ${variant === 'max-width' && maxWidthVariant}
    ${variant === 'table-max-width' && tableMaxWidthVariant}
    ${variant === 'gray-background' && grayBackgroundVariant}
  `}
`;

const tableVariant = css`
  padding-bottom: var(--sizes-30);

  .tab-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const maxWidthVariant = css`
  max-width: 1440px;
`;

const tableMaxWidthVariant = css`
  ${tableVariant};
  ${maxWidthVariant}
`;

const grayBackgroundVariant = css`
  background-color: var(--color-gray-10);
`;
