import { Menu } from '@mui/material';
import styled, { css } from 'styled-components';

export const RebrandOverflowMenu = styled(Menu)<{ $darkMode: boolean }>`
  border-radius: 9px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  color: var(--color-grayscale-black);
  font: var(--font-regular-14pt-normal);
  .MuiMenuItem-root {
    padding: var(--sizes-30) var(--sizes-70);
    font: var(--font-regular-14pt-normal);
    :hover {
      background: var(--color-gray-10);
    }
  }
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      .MuiMenuItem-root {
        :hover {
          background: var(--color-gray-100);
        }
      }
    `}
`;
