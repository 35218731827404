import styled from 'styled-components';

import type { CSSObject } from 'styled-components';

// HEADINGS
export const Heading1 = styled.h1`
  font: var(--font-headings-heading-1);
`;

export const Heading2 = styled.h2<{ $overrides?: CSSObject }>(({ $overrides }) => ({
  font: 'var(--font-headings-heading-2)',
  ...$overrides,
}));

export const Heading3 = styled.h3`
  font: var(--font-headings-heading-3);
`;

// LARGE
export const LargeText = styled.span`
  font: var(--font-large-16pt-normal);
`;

export const LargeTextSemiBold = styled.span`
  font: var(--font-large-16pt-semibold);
`;

// REGULAR
export const RegularText = styled.span`
  font: var(--font-regular-14pt-normal);
`;

export const RegularTextSemiBold = styled.span`
  font: var(--font-regular-14pt-semibold);
`;

export const RegularTextBold = styled.span`
  font: var(--font-regular-14pt-bold);
`;

// SMALL
export const SmallText = styled.span`
  font: var(--font-small-13pt-normal);
`;

export const SmallTextSemiBold = styled.span`
  font: var(--font-small-13pt-semibold);
`;

export const SmallTextBold = styled.span`
  font: var(--font-small-13pt-bold);
`;

// EXTRA SMALL
export const ExtraSmallText = styled.span`
  font: var(--font-extra-small-12pt-normal);
`;

export const ExtraSmallTextSemiBold = styled.span`
  font: var(--font-extra-small-12pt-semibold);
`;

export const ExtraSmallTextBold = styled.span`
  font: var(--font-extra-small-12pt-bold);
`;

export const ExtraSmallTextCapsBold = styled(ExtraSmallTextBold)`
  text-transform: uppercase;
`;

// MINI
export const MiniTextSemiBold = styled.span`
  font: var(--font-mini-11pt-semibold);
`;

// MICRO
export const MicroTextBold = styled.span`
  font: var(--font-micro-10pt-bold);
  text-transform: uppercase;
`;
