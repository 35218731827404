import styled, { css } from 'styled-components';

import type { LoadingSpinnerSizeProp } from '.';

export const LoaderRing = styled.div<{ size: LoadingSpinnerSizeProp }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const InnerRing = styled.div<{ $variant: string }>`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  margin: 2px;
  border: 2px solid;
  border-color: #fff transparent transparent transparent;
  ${({ $variant }) =>
    $variant === 'blue' &&
    css`
      border-color: var(--color-brand-secondary-sapphire) transparent transparent transparent;
    `}
  ${({ $variant }) =>
    $variant === 'black' &&
    css`
      border-color: var(--color-grayscale-black) transparent transparent transparent;
    `}
  ${({ $variant }) =>
    $variant === 'grey' &&
    css`
      border-color: var(--color-gray-50) transparent transparent transparent;
    `}
  border-radius: 50%;
  box-sizing: border-box;
  animation: loader-ring 1.4s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:first-child {
    animation-delay: -0.35s;
  }

  &:nth-child(2) {
    animation-delay: -0.25s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
