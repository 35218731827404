import { object, string } from 'yup';

import { getOperatorFromRule } from '../../utils';

import type { Attributes, CustomerSegmentSchemaMap } from './schema';
import type {
  Rule,
  RuleGroup,
} from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';
import type { BaseSchema } from 'yup';

type SchemaForQueryBuilderProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  rule: Rule;
};

export const generateSchemaForQueryBuilder = ({
  rule,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
}: SchemaForQueryBuilderProps) => {
  const getYup: BaseSchema =
    getOperatorFromRule({ rule, customerSegmentSchema, enableMultiSelectDropdowns })?.yup || string();

  const queryBuilderSchema = object({
    Attribute: string().required(),
    Condition: string().required(),
    Operator: string().required(),
    Value: getYup.required(),
    SecondValue: getYup.when('$requireSecondValue', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .transform((value, originalValue) => (originalValue === '' || originalValue === null ? undefined : value))
          .optional(),
    }),
    Variant: string().when('$requireVariant', (requireVariant, schema) => {
      if (requireVariant) {
        return schema.required().nullable();
      }
      return schema.nullable();
    }),
  });

  return queryBuilderSchema;
};

type IsRuleValidProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  requireSecondValue: boolean;
  requireVariant: boolean;
  rule: Rule;
};

export function isRuleValid({
  rule,
  customerSegmentSchema,
  requireVariant,
  requireSecondValue,
  enableMultiSelectDropdowns,
}: IsRuleValidProps) {
  try {
    generateSchemaForQueryBuilder({ rule, customerSegmentSchema, enableMultiSelectDropdowns }).validateSync(rule, {
      context: { requireSecondValue, requireVariant },
    });
    return true;
  } catch {
    return false;
  }
}

type ValidateRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  rule: Rule;
};

export const validateRule = ({ rule, customerSegmentSchema, enableMultiSelectDropdowns }: ValidateRuleProps) => {
  // only some rules require a second value or variant
  const getOperatorSecondValue =
    getOperatorFromRule({ rule, customerSegmentSchema, enableMultiSelectDropdowns })?.requiresSecondValue || false;
  const requireSecondValue = getOperatorSecondValue;
  const variants = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.variants
    : [];
  const requireVariant = variants?.length > 0;

  return isRuleValid({ rule, customerSegmentSchema, requireVariant, requireSecondValue, enableMultiSelectDropdowns });
};

type ValidateAllRulesProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  ruleGroup?: RuleGroup;
};

export const validateAllRules = ({
  ruleGroup,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
}: ValidateAllRulesProps) => {
  const areRulesValid = ruleGroup?.rules.every((rule) =>
    validateRule({ rule, customerSegmentSchema, enableMultiSelectDropdowns })
  );

  return areRulesValid;
};
