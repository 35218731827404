import type { ChangeEvent } from 'react';
import React from 'react';

import { useDarkMode } from 'src/app/state/dark-mode';

import { RebrandInput } from './base-input.styles';

import type { TextFieldProps as MUIInputProps } from '@mui/material';

export type BaseInputProps = MUIInputProps & {
  autoComplete?: string;
  automationId?: string;
  disabled?: boolean;
  endAdornment?: any;
  gridColumn?: string;
  id?: string;
  multiline?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  startAdornment?: any;
  tooltip?: React.ReactNode;
  type?: string;
  validator?: (event: ChangeEvent<HTMLInputElement>) => boolean;
  value?: number | string;
  width?: string;
};

export function BaseInput(props: BaseInputProps): JSX.Element {
  const { minRows } = props;
  const {
    id,
    value,
    type = 'text',
    onChange = () => undefined,
    onBlur,
    multiline = false,
    width,
    gridColumn,
    required = false,
    disabled = false,
    placeholder,
    rows = minRows ? 0 : 4,
    endAdornment,
    startAdornment,
    InputProps,
    validator = null,
    className = '',
    onClick,
    automationId,
    autoFocus = false,
    onKeyPress,
    inputProps,
    ...other
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    (validator && !validator(event)) || onChange(event);
  };
  const darkMode = useDarkMode();
  // TODO: Spike out why value is uncontrolled
  return (
    <RebrandInput
      {...other}
      $darkMode={darkMode}
      $gridColumn={gridColumn}
      $width={width}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      id={id}
      inputProps={{
        'data-testid': automationId,
        ...inputProps,
        ...(type === 'number' && {
          onWheel: ({ currentTarget }) => currentTarget.blur(),
        }),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={InputProps ?? { endAdornment, startAdornment }}
      multiline={multiline}
      placeholder={placeholder}
      required={required}
      rows={multiline ? rows : undefined}
      type={type}
      value={value}
      variant='outlined'
      onBlur={onBlur}
      onChange={handleChange}
      onClick={onClick}
      onKeyPress={onKeyPress}
    />
  );
}
