import type React from 'react';

import type { Moment } from 'moment';

export enum NotificationTypes {
  Alert = 'alert',
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

// Shared type value between both notifications and legacy notifications
type BaseNotification = {
  additionalInfo?: React.ReactNode;
  className?: string;
  isLegacy?: boolean;
  shouldRemove?: boolean;
  sticky?: boolean;
  timestamp?: Moment;
  uuid: string;
};

// Notification type in state
export type Notification = BaseNotification & {
  message: string;
  subContent?: React.ReactNode;
  variant: NotificationTypes;
};

// Legacy notification type in state
export type LegacyNotification = BaseNotification & {
  body: string;
  show: boolean;
  type: NotificationTypes;
};

// Notification component props type
export type NotificationProps = Notification & { automationId: string; onRemove: (value: string) => void };

// Notification action argument type
export type AddNotificationArgs = {
  additionalInfo?: Notification['additionalInfo'];
  message: Notification['message'];
  sticky?: Notification['sticky'];
  subContent?: Notification['subContent'];
};
