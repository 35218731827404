import React from 'react';

export function PlusIcon() {
  return (
    <svg fill='none' height='14' viewBox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z'
        fill='var(--color-gray-90)'
      />
      <path
        d='M7 3.5C7.27615 3.5 7.5 3.72386 7.5 4V10C7.5 10.2761 7.27615 10.5 7 10.5C6.72386 10.5 6.5 10.2761 6.5 10V4C6.5 3.72386 6.72386 3.5 7 3.5Z'
        fill='var(--color-gray-90)'
      />
      <path
        d='M3.5 7C3.5 6.72386 3.72386 6.5 4 6.5H10C10.2761 6.5 10.5 6.72386 10.5 7C10.5 7.27615 10.2761 7.5 10 7.5H4C3.72386 7.5 3.5 7.27615 3.5 7Z'
        fill='var(--color-gray-90)'
      />
    </svg>
  );
}
