import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import type { ProductCategory } from 'src/app_deprecated/stores/InventoryStore';
import InventoryStore from 'src/app_deprecated/stores/InventoryStore';

import { getMultiSelectedOptionsForProductCategories } from 'src/app/pages/customers/customers/segments/utils';
import { inventoryAtom } from 'src/app/state/inventory';

import { LinkButton } from '../../lib/button';
import { MultiFilterSelect } from '../../lib/multi-filter-select';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type ProductCategoryDropdownProps = {
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

export function ProductCategoryDropdown({ onChange, values, disabled = false }: ProductCategoryDropdownProps) {
  const [label, setLabel] = useState('Select a product category');
  const [loading, setLoading] = useState(false);
  const { productCategories } = useRecoilValue(inventoryAtom);
  const allProductCategoryOptions: ProductCategory[] = productCategories?.length ? productCategories : [];

  const [productCategoryDropdownSelection, setProductCategoryDropdownSelection] = useState<DropdownMenuOption[]>([]);

  useEffect(() => {
    if (productCategoryDropdownSelection.length !== 0 && productCategoryDropdownSelection.length < 4) {
      const productCategoryLabelList = productCategoryDropdownSelection.map((item) => item.label).join(', ');
      setLabel(productCategoryLabelList);
    } else {
      setLabel('Select a product category');
    }
  }, [productCategoryDropdownSelection]);

  useEffect(() => {
    // if values updates be sure to update the selection
    setProductCategoryDropdownSelection(getMultiSelectedOptionsForProductCategories(values, allProductCategoryOptions));
  }, [productCategories]);

  useEffect(() => {
    void loadCategory();
  }, []);

  async function loadCategory() {
    setLoading(true);
    void InventoryStore.refreshProductCategories();
    setLoading(false);
  }

  async function handleRefresh() {
    await loadCategory();
  }

  const handleProductCategoryFilterChange = (values: DropdownMenuOption[]) => {
    setProductCategoryDropdownSelection(values);

    const productCategoryDropdownSelection = values.map((option) => option.id);
    onChange(productCategoryDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={productCategoryDropdownSelection}
      automationId='product-category-dropdown_multi-filter-select'
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId='product-category-dropdown_multi-filter-select_refresh-button'
          buttonSize='small'
          key='category-refresh'
          label='Refresh'
          loading={loading}
          onClick={handleRefresh}
        />
      }
      label={label}
      options={allProductCategoryOptions.map((productCategory) => ({
        label: String(productCategory.ProductCategory),
        id: String(productCategory.ProductCategoryId),
      }))}
      onChange={handleProductCategoryFilterChange}
    />
  );
}
