import React from 'react';

type CheckIconProps = {
  checked?: boolean;
  checkedCheckIconColor?: string;
  checkedFillColor?: string;
  dataTestId: string;
  uncheckedCheckIconColor?: string;
  uncheckedFillColor?: string;
};

export function CheckIcon({
  checked = false,
  dataTestId,
  checkedFillColor = 'var(--color-brand-secondary-sapphire)',
  uncheckedFillColor = 'white',
  uncheckedCheckIconColor = 'var(--color-gray-70)',
  checkedCheckIconColor = 'white',
}: CheckIconProps): JSX.Element {
  return (
    <svg fill='none' height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='6.5' cy='6.48071' fill={checked ? checkedFillColor : uncheckedFillColor} r='6' />
      {!checked && (
        <svg
          data-testid={`${dataTestId}-unchecked-icon`}
          fill='none'
          height='11'
          viewBox='0 0 11 11'
          width='11'
          x='1'
          xmlns='http://www.w3.org/2000/svg'
          y='1'
        >
          <path
            d='M8.12925 3.4762C8.24659 3.59341 8.3125 3.75235 8.3125 3.91808C8.3125 4.08381 8.24659 4.24275 8.12925 4.35995L4.99991 7.48495C4.88254 7.60212 4.72338 7.66795 4.55742 7.66795C4.39146 7.66795 4.2323 7.60212 4.11493 7.48495L2.86319 6.23495C2.74918 6.11708 2.6861 5.9592 2.68752 5.79533C2.68895 5.63146 2.75477 5.4747 2.87081 5.35882C2.98685 5.24294 3.14383 5.17721 3.30793 5.17578C3.47203 5.17436 3.63013 5.23736 3.74817 5.3512L4.55742 6.15933L7.24428 3.4762C7.36164 3.35904 7.52081 3.29321 7.68676 3.29321C7.85272 3.29321 8.01189 3.35904 8.12925 3.4762Z'
            fill={uncheckedCheckIconColor}
          />
        </svg>
      )}
      {checked && (
        <svg
          data-testid={`${dataTestId}-checked-icon`}
          fill='none'
          height='6'
          viewBox='0 0 7 6'
          width='7'
          x='3'
          xmlns='http://www.w3.org/2000/svg'
          y='3'
        >
          <path
            d='M6.12925 0.956917C6.24659 1.07412 6.3125 1.23306 6.3125 1.39879C6.3125 1.56452 6.24659 1.72346 6.12925 1.84067L2.99991 4.96567C2.88254 5.08284 2.72338 5.14866 2.55742 5.14866C2.39146 5.14866 2.2323 5.08284 2.11493 4.96567L0.863189 3.71567C0.749182 3.59779 0.686098 3.43991 0.687524 3.27604C0.68895 3.11217 0.754772 2.95541 0.870813 2.83953C0.986854 2.72365 1.14383 2.65792 1.30793 2.6565C1.47203 2.65507 1.63013 2.71807 1.74817 2.83192L2.55742 3.64004L5.24428 0.956917C5.36164 0.839748 5.52081 0.773926 5.68676 0.773926C5.85272 0.773926 6.01189 0.839748 6.12925 0.956917Z'
            fill={checkedCheckIconColor}
          />
        </svg>
      )}
    </svg>
  );
}
