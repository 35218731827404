import { endPoints } from 'src/app_deprecated/constants/AppConstants';

import Request from '../../app_deprecated/utils/AjaxPromises';

import type { EnvironmentDetails } from '../state/environment';

type EnvDetailsResponse = {
  Data: EnvironmentDetails;
  Message: string | null;
  Result: boolean;
};

/**
 * @deprecated: use `src/app/queries/admin/get-environment-details` instead
 */
export const getEnvDetails = (): Promise<EnvDetailsResponse> => Request.GET(endPoints.GET_ENVIRONMENT_DETAILS);
