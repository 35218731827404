import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { TextField } from 'src/app/components/fields/text-field';
import { EyeClosedIcon } from 'src/app/components/icons/eye-closed-icon';
import { EyeOpenIcon } from 'src/app/components/icons/eye-open-icon';
import { Button } from 'src/app/components/lib/button';
import { InputAdornment } from 'src/app/components/lib/input';
import { Modal } from 'src/app/components/lib/modal';
import { useLoginMutation } from 'src/app/queries/posv3/user/employee-login';
import { useSSOEnabled } from 'src/app/queries/saml/sso-login-enabled';
import { useSSOLoginMutation } from 'src/app/queries/saml/sso-login-redirect';

import { AuthFormContainer, AuthFormSection, StyledHeading } from './shared';

import type { SubmitHandler } from 'react-hook-form';
import type { LoginResponse } from 'src/app/queries/posv3/user/employee-login';

const schema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

type LoginFormData = yup.InferType<typeof schema>;

type MaxLoginFailureModal = Partial<LoginResponse['Data']> & {
  visible: boolean;
};

export function Login({ showFullPageMigrationTakeover }: { showFullPageMigrationTakeover: (props: any) => void }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showMaxLoginFailureModal, setShowMaxLoginFailureModal] = useState<MaxLoginFailureModal>({ visible: false });
  const ssoEnabled = useSSOEnabled();
  const isSSOLoginEnabled = ssoEnabled.data?.isSSOLoginEnabled;
  const navigate = useNavigate();

  const form = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: { username: '', password: '' },
  });
  const { isDirty, isValid } = form.formState;
  const currentUserName = form.watch('username');

  const { mutate: loginMutate, isLoading: isLoginLoading } = useLoginMutation({
    showFullPageMigrationTakeover,
    showMaxLoginFailureModal: (data) => {
      setShowMaxLoginFailureModal({ ...data, visible: true });
      form.reset();
    },
  });

  const { mutate: ssoLoginMutate, isLoading: isSSOLoginLoading } = useSSOLoginMutation({
    showFullPageMigrationTakeover,
  });

  const handleLogin: SubmitHandler<LoginFormData> = async (data) => {
    loginMutate({ username: data.username, password: data.password });
  };

  const handleSSOLogin = async () => {
    ssoLoginMutate({ username: currentUserName });
  };

  return (
    <>
      <AuthFormContainer>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleLogin)}>
            <AuthFormSection>
              <StyledHeading automationId='auth_heading_login' subheader='Login to your dutchie account:'>
                Welcome back!
              </StyledHeading>

              <TextField
                automationId='auth_input_username'
                endAdornment={<div />} // this is here to match spacing with the pw field
                name='username'
                placeholder='Username'
                required
              />
              <TextField
                automationId='auth_input_password'
                endAdornment={
                  <InputAdornment position='end'>
                    {!showPassword && <EyeOpenIcon onClick={() => setShowPassword(!showPassword)} />}
                    {showPassword && <EyeClosedIcon onClick={() => setShowPassword(!showPassword)} />}
                  </InputAdornment>
                }
                name='password'
                placeholder='Password'
                required
                type={showPassword ? 'text' : 'password'}
              />
            </AuthFormSection>
            <ButtonsContainer>
              <PasswordLink onClick={() => navigate('/forgot-password')}>Forgot password</PasswordLink>
              <ButtonsGroup>
                <Button
                  automationId='auth_button_go-green'
                  disabled={!isDirty || !isValid || isLoginLoading}
                  label='Login'
                  loading={isLoginLoading}
                  type='submit'
                />
                {isSSOLoginEnabled && (
                  <Button
                    disabled={currentUserName?.length === 0 || isSSOLoginLoading}
                    label='Login with SSO'
                    loading={isSSOLoginLoading}
                    onClick={handleSSOLogin}
                  />
                )}
              </ButtonsGroup>
            </ButtonsContainer>
          </form>
        </FormProvider>
      </AuthFormContainer>

      <Modal
        Content={
          <p>
            {`Your account has been blocked after ${
              showMaxLoginFailureModal.MaxLoginFailureCount ?? 1
            } login failures. Please wait ${
              (showMaxLoginFailureModal.MaxRetryMinutes ?? 0) - (showMaxLoginFailureModal.RetryMinutes ?? 0)
            } minutes and try again.`}
          </p>
        }
        open={showMaxLoginFailureModal.visible}
        primaryActions={[
          {
            label: 'OK',
            onClick: () => setShowMaxLoginFailureModal({ ...showMaxLoginFailureModal, visible: false }),
          },
        ]}
        title='Account locked'
        onClose={() => setShowMaxLoginFailureModal({ ...showMaxLoginFailureModal, visible: false })}
      />
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const PasswordLink = styled.div`
  font-weight: var(--font-weight-semibold);
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
`;
