import React from 'react';

import styled from 'styled-components';

import { Loader } from '../loader';

import { CardContainer } from './content-card.styles';

type ContentCardSkeletonProps = {
  height?: string;
};

export function ContentCardSkeleton(props: ContentCardSkeletonProps) {
  const { height } = props;

  return (
    <CardContainer>
      <StyledWrapper $height={height}>
        <Loader size='3x' variant='grey' />
      </StyledWrapper>
    </CardContainer>
  );
}

const StyledWrapper = styled.div<{ $height?: string }>`
  background-color: var(--color-brand-primary-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sizes-70);
  width: 100%;
  height: ${({ $height }) => $height ?? '400px'};
`;
