import React from 'react';

export function ReportsIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M23.8452 23.1443C23.8452 22.6717 23.4639 22.2886 22.9936 22.2886L0.851611 22.2886C0.381277 22.2886 -1.98202e-06 22.6717 -2.02334e-06 23.1443C-2.06465e-06 23.6169 0.381277 24 0.85161 24L22.9936 24C23.4639 24 23.8452 23.6169 23.8452 23.1443Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M24 0.855721C24 0.383121 23.6187 2.06481e-06 23.1484 2.0237e-06L16.3355 1.42809e-06C15.8651 1.38697e-06 15.4839 0.38312 15.4839 0.855721L15.4839 23.1044C15.4839 23.577 15.8651 23.9601 16.3355 23.9601L23.1484 23.9601C23.6187 23.9601 24 23.577 24 23.1044L24 0.855721ZM22.2968 1.71144L22.2968 22.2487L17.1871 22.2487L17.1871 1.71144L22.2968 1.71144Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M17.1032 7.67168C17.1032 7.19908 16.722 6.81596 16.2516 6.81596L9.29678 6.81596C8.82645 6.81596 8.44517 7.19908 8.44517 7.67168L8.44517 23.1443C8.44517 23.6169 8.82645 24 9.29678 24L16.2516 24C16.722 24 17.1032 23.6169 17.1032 23.1443L17.1032 7.67168ZM15.4 8.5274L15.4 22.2886L10.1484 22.2886L10.1484 8.5274L15.4 8.5274Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M10.1484 15.1609C10.1484 14.6883 9.76711 14.3052 9.29678 14.3052L2.83871 14.3052C2.36838 14.3052 1.98709 14.6883 1.98709 15.1609L1.98709 23.1443C1.98709 23.6169 2.36837 24 2.83871 24L9.29678 24C9.76711 24 10.1484 23.6169 10.1484 23.1443L10.1484 15.1609ZM8.44517 16.0166L8.44517 22.2886L3.69032 22.2886L3.69032 16.0166L8.44517 16.0166Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
    </svg>
  );
}
