import type { ProductCategory } from 'src/app_deprecated/stores/InventoryStore';

import { getOperatorsFromOperatorGroup } from './details/segment-query-builder/operators';

import type { Attributes, CustomerSegmentSchemaMap } from './details/segment-query-builder/schema';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type {
  Rule,
  RuleGroup,
} from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';

export type SegmentPayload = {
  segmentName: string;
};

export type QueryRulesAndSchemaResponse = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  segmentDetails?: RuleGroup;
};

export const parseSelectedIds = (values: string): number[] => values.split(',').map((id) => Number(id));
export const parseSelectedValues = (values: string): string[] => values.split(',');

export const mapToDropdownOptions = (
  selectedIds: number[],
  allProductCategoryOptions: ProductCategory[]
): DropdownMenuOption[] =>
  selectedIds
    .map((id) => {
      const option = allProductCategoryOptions.find((opt) => opt.ProductCategoryId === id);
      if (option) {
        return { label: option.ProductCategory, id: option.ProductCategoryId.toString() };
      }
      return null;
    })
    .filter((option) => option !== null);

export const getMultiSelectedOptionsForProductCategories = (
  values: string,
  allProductCategoryOptions: ProductCategory[]
): DropdownMenuOption[] => {
  const selectedIds = parseSelectedIds(values);
  return mapToDropdownOptions(selectedIds, allProductCategoryOptions);
};

type GetOperatorFromRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  rule: Rule;
};

export const getOperatorFromRule = ({
  rule,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
}: GetOperatorFromRuleProps) => {
  const operatorGroup = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.operatorGroup
    : 'string';
  const ruleOperators = getOperatorsFromOperatorGroup(operatorGroup, enableMultiSelectDropdowns);
  return ruleOperators.find((operator) => operator.name === rule.Operator);
};

type getOperatorGroupFromRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  rule: Rule;
};

export const getOperatorGroupFromRule = ({
  rule,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
}: getOperatorGroupFromRuleProps) => {
  const operatorGroup = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.operatorGroup
    : 'string';

  const ruleOperators = getOperatorsFromOperatorGroup(operatorGroup, enableMultiSelectDropdowns);
  return ruleOperators;
};
