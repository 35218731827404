import type { ReactElement } from 'react';

import i18next from 'i18next';

// TODO-REFACTOR: This should be a util function used here and in select.tsx
export const getIDFromLabel = (label?: ReactElement | string): string => {
  if (!label) {
    return '';
  }
  if (typeof label === 'string') {
    return label;
  }
  if (typeof label?.props?.children === 'string') {
    return label.props.children;
  }
  if (Array.isArray(label?.props?.children)) {
    return label.props.children.filter((child) => typeof child === 'string').join('');
  }
  return label ? String(label) : '';
};

// TODO-REFACTOR: See if why 118next.t is truncating the string.
// nsSeparator: '|' might be causing some of the issues. File: src/app_deprecated/utils/lang/i18n
export const translateMessageWithPipe = (message: string) => {
  const parts = message.split('\n');

  const translatedParts = parts.map((part) => {
    const subParts = part.split('|');
    const translatedSubParts = subParts.map((subPart) => i18next.t(subPart.trim()));
    return translatedSubParts.join(' | ');
  });

  const translatedMessage = translatedParts.join('\n');
  return translatedMessage;
};
