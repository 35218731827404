import React from 'react';

import styled from 'styled-components';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { generalBannerStyles, LARGE_BANNER_HEIGHT, Text } from './shared';

export type GeneralHeaderConfig = {
  copy?: string;
  visible: boolean;
};

export function GeneralHeaderBanner() {
  const { visible, copy } = useLDFlag<GeneralHeaderConfig>(LD.GENERAL_BACKOFFICE_HEADER_BANNER, {
    visible: false,
    copy: '',
  });

  if (!visible || !copy) {
    return null;
  }

  return (
    <Banner>
      <Text>
        <span>{copy}</span>
      </Text>
    </Banner>
  );
}

const Banner = styled.div`
  height: ${LARGE_BANNER_HEIGHT}px;
  background-color: #3c96fe;
  ${generalBannerStyles}
`;
