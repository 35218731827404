import React, { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { LinkButton } from 'src/app/components/lib/button';
import { parseSelectedIds } from 'src/app/pages/customers/customers/segments/utils';
import { customerTypeQueryKey, useGetAllCustomerTypes } from 'src/app/queries/customers/get-all-customer-types';

import { MultiFilterSelect } from '../../lib/multi-filter-select';

import type { DropdownMenuOption } from '../../lib/dropdown';

type CustomerTypeDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

type CustomerType = {
  CustomerType: string;
  CustomerTypeId: number;
  Visible: boolean;
};

export function CustomerTypeDropdown(props: CustomerTypeDropdownProps) {
  const { onChange, values, disabled = false, automationId } = props;
  const queryClient = useQueryClient();
  const { data: allCustomerTypes = [], isLoading } = useGetAllCustomerTypes();
  const allCustomerTypeOptions = allCustomerTypes?.filter((customer: CustomerType) => customer.Visible);

  const mapToDropdownOptions = (selectedIds: number[], allCustomerTypeOptions: CustomerType[]): DropdownMenuOption[] =>
    selectedIds
      .map((id) => {
        const option = allCustomerTypeOptions.find((opt) => opt.CustomerTypeId === id);
        if (option) {
          return { label: option.CustomerType, id: option.CustomerTypeId.toString() };
        }
        return null;
      })
      .filter((option) => option !== null);

  const getMultiSelectedOptionsForCustomerTypes = (
    values: string,
    allCustomerTypeOptions: CustomerType[]
  ): DropdownMenuOption[] => {
    const selectedIds = parseSelectedIds(values);
    return mapToDropdownOptions(selectedIds, allCustomerTypeOptions);
  };

  const [label, setLabel] = useState('Select a customer type');
  const [customerTypeDropdownSelection, setCustomerTypeDropdownSelection] = useState<DropdownMenuOption[]>([]);

  useEffect(() => {
    if (customerTypeDropdownSelection.length !== 0 && customerTypeDropdownSelection.length < 4) {
      const customerTypeLabelList = customerTypeDropdownSelection.map((item) => item.label).join(', ');
      setLabel(customerTypeLabelList);
    } else {
      setLabel('Select a customer type');
    }
  }, [customerTypeDropdownSelection]);

  useEffect(() => {
    // if values updates be sure to update the selection
    if (allCustomerTypeOptions.length) {
      setCustomerTypeDropdownSelection(getMultiSelectedOptionsForCustomerTypes(values, allCustomerTypeOptions));
    }
  }, [allCustomerTypes]);

  async function loadCustomerTypes() {
    void queryClient.invalidateQueries({ queryKey: customerTypeQueryKey });
  }

  async function handleRefresh() {
    await loadCustomerTypes();
  }

  const handleCustomerTypeFilterChange = (values: DropdownMenuOption[]) => {
    setCustomerTypeDropdownSelection(values);

    const customerTypeDropdownSelection = values.map((option) => option.id);
    onChange(customerTypeDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={customerTypeDropdownSelection}
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId='customer-type-dropdown_multi-filter-select_refresh-button'
          buttonSize='small'
          key='category-refresh'
          label='Refresh'
          loading={isLoading}
          onClick={handleRefresh}
        />
      }
      label={label}
      options={allCustomerTypeOptions.map((customerType) => ({
        label: String(customerType.CustomerType),
        id: String(customerType.CustomerTypeId),
      }))}
      onChange={handleCustomerTypeFilterChange}
    />
  );
}
