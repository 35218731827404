import React, { forwardRef } from 'react';
import type { AriaAttributes, HTMLAttributes } from 'react';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import { CheckboxBase } from 'src/app/components/lib/checkbox';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { CheckboxBaseProps } from 'src/app/components/lib/checkbox';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown/menu/types';

export type DropdownMenuItemProps = DropdownMenuOption &
  HTMLAttributes<HTMLLIElement> & {
    ariaSelected: AriaAttributes['aria-selected'];
    automationId?: string;
    checkboxLabel?: string;
    checkboxProps?: CheckboxBaseProps;
    checked?: boolean;
    dataOptionIndex: string;
    height: number;
    top: number;
    truncateLabel?: boolean;
  };

export const DropdownMenuItem = forwardRef<HTMLLIElement, DropdownMenuItemProps>(
  (
    {
      ariaSelected,
      automationId,
      dataOptionIndex,
      danger,
      disabled,
      truncateLabel,
      onClick,
      height,
      top,
      variant,
      checkboxProps,
      checked,
      label,
      labelSecondary,
      footer,
      id,
      tabIndex,
      onMouseOver,
      onTouchStart,
    },
    ref
  ) => {
    const darkMode = useDarkMode();
    const style = {
      height: `${height}px`,
      top: `${top}px`,
    };
    const hasFooter = Boolean(footer);
    const hasCheckbox = variant === 'checkbox';

    const dropdownMenuItemClasses = classNames({
      'dropdown-menu-item--disabled': disabled,
      'dropdown-menu-item--checkbox': hasCheckbox,
    });

    const dropdownMenuItemTextContainerClasses = classNames({
      'dropdown-menu-item__text-container': true,
      'dropdown-menu-item__text-container--checkbox': hasCheckbox,
    });

    const dropdownMenuItemPrimaryLabelClasses = classNames({
      'dropdown-menu-item__primary-label': true,
      'dropdown-menu-item__primary-label--truncated': truncateLabel,
      'dropdown-menu-item__primary-label--disabled': disabled,
      'dropdown-menu-item__primary-label--danger': danger,
      'dropdown-menu-item__primary-label--bold': hasCheckbox || hasFooter,
    });

    return (
      <DropdownMenuItemStyled
        $darkMode={darkMode}
        aria-disabled={disabled}
        aria-selected={ariaSelected}
        className={dropdownMenuItemClasses}
        data-option-index={dataOptionIndex}
        data-testid={automationId}
        id={id}
        ref={ref}
        role='option'
        style={style}
        tabIndex={tabIndex}
        title={label}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onTouchStart={onTouchStart}
      >
        {hasCheckbox && <CheckboxBase {...checkboxProps} checked={checked} />}
        <div className={dropdownMenuItemTextContainerClasses}>
          <div className='dropdown-menu-item__label-container'>
            <span className={dropdownMenuItemPrimaryLabelClasses}>{label}</span>
            {Boolean(labelSecondary) && <span className='dropdown-menu-item__label-secondary'>{labelSecondary}</span>}
          </div>
          {Boolean(footer) && <div className='dropdown-menu-item__label-footer'>{footer}</div>}
        </div>
      </DropdownMenuItemStyled>
    );
  }
);

const DropdownMenuItemStyled = styled.li<{ $darkMode: boolean }>`
  --default-horizontal-padding: var(--sizes-70);
  --checkbox-horizontal-padding: var(--sizes-50);
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 var(--default-horizontal-padding);
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;

  &.dropdown-menu-item--checkbox {
    padding: 0 var(--checkbox-horizontal-padding);
  }

  .MuiCheckbox-root {
    margin-right: var(--sizes-40);
  }

  &:hover {
    background-color: var(--color-gray-10);
  }

  &.Mui-focusVisible {
    background-color: var(--color-gray-10);
  }

  .dropdown-menu-item__text-container {
    color: var(--color-grayscale-black);
    display: var(--menu-item-display-value);
    flex-direction: var(--menu-item-flex-direction);
    font: var(--menu-item-label-font);
    width: 100%;
  }

  .dropdown-menu-item__text-container--checkbox {
    width: calc(100% - 32px);
  }

  .dropdown-menu-item__label-container {
    display: var(--menu-item-label-container-display-value);
    justify-content: var(--menu-item-label-container-justify-content-value);
    gap: var(--menu-item-label-container-gap-value);
  }

  .dropdown-menu-item__primary-label {
    display: var(--menu-item-label-container-display-value);
    justify-content: var(--menu-item-label-container-justify-content-value);
    color: var(--color-grayscale-black);
    font: var(--font-regular-14pt-normal);
    white-space: var(--dropdown-menu-item-label-wrapped-white-space);
  }

  .dropdown-menu-item__primary-label--bold {
    font: var(--menu-item-label-font);
  }

  &[aria-selected='true'] {
    .dropdown-menu-item__primary-label {
      font: var(--menu-item-label-font);
    }
  }

  .dropdown-menu-item__primary-label--truncated {
    display: var(--dropdown-menu-item-label-display);
    overflow: var(--dropdown-menu-item-label-overflow);
    text-overflow: var(--dropdown-menu-item-label-text-overflow);
    white-space: var(--dropdown-menu-item-label-white-space-value);
  }

  .dropdown-menu-item__primary-label--disabled {
    color: var(--color-gray-70);
  }

  .dropdown-menu-item__label-secondary {
    margin-top: var(--menu-item-label-secondary-margin-top);
    color: var(--menu-item-label-secondary-color);
    font: var(--menu-item-label-secondary-font);
    text-transform: var(--menu-item-label-secondary-text-transform);
    align-self: var(--menu-item-label-secondary-align-self);
  }

  .dropdown-menu-item__label-footer {
    white-space: var(--menu-item-footer-white-space);
    font: var(--menu-item-footer-font);
    color: var(--color-gray-70);
  }

  &.dropdown-menu-item--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .dropdown-menu-item__primary-label--danger {
    color: var(--color-red-50);
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      &:hover {
        background-color: var(--color-gray-100);
      }

      &.Mui-focusVisible {
        background-color: var(--color-gray-100);
      }

      .dropdown-menu-item__text-container,
      .dropdown-menu-item__primary-label {
        color: var(--color-brand-primary-white);
      }
    `}
`;
