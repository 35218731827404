import LogRocket from 'logrocket';

import { getLDClient } from 'src/app/utils/ld-client-do-not-use-me';

import { VERSION } from '../constants/environment';

export function getRequestHeaders() {
  const headers: Record<string, string> = {};
  const ldClient = getLDClient();
  if (ldClient) {
    const includeAppNameAndVersion = ldClient.variation(
      'backoffice.add-appname-and-version-to-requests.rollout',
      false
    );
    if (includeAppNameAndVersion) {
      headers.AppName = 'Backoffice';
      if (VERSION) {
        headers.AppVersion = VERSION ?? '';
      }
    }
    const logRocketSessionUrl = LogRocket.sessionURL;
    if (logRocketSessionUrl) {
      headers['X-LogRocket-URL'] = logRocketSessionUrl;
    }
  }
  return headers;
}
