import styled from 'styled-components';

import { IconButton } from '../icon-button';

export const BohIconButton = styled(IconButton)`
  border: none;
  font-size: 30px;
  margin-right: 10px;
  height: 30px;
  cursor: pointer;
  align-self: center;
  display: flex;
  padding: 0;

  svg {
    height: 30px;
    width: 18px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    path {
      fill: : #000000;
    }
  }

  :focus {
    outline: none;
  }
`;
