import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import UserStore from 'src/app_deprecated/stores/UserStore';

import { Button, ButtonGroup } from 'src/app/components/lib/button';
import { Heading } from 'src/app/components/lib/heading';
import { ModalContainer, ModalContent } from 'src/app/components/lib/modal';
import { Countdown } from 'src/app/components/modals/modals/session-expiration-modal/countdown';

import { SessionExpirationIllustration } from './session-expiration-illustration';

import type { IIdleTimer } from 'react-idle-timer';

const defaultEvents = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

const stayLoggedInId = 'stay-logged-in-button';

type SessionExpirationModalProps = {
  sessionTimeoutMinutes: number;
  userIsLoggedIn: boolean;
};

const ONE_MINUTE_AS_MS = 60_000;
const PROMPT_TIME = ONE_MINUTE_AS_MS;

export function SessionExpirationModal({ sessionTimeoutMinutes = 0, userIsLoggedIn }: SessionExpirationModalProps) {
  // If the location has specified a 0 minute timeout period,
  // this actually means we should _not_ use a timeout at all.
  if (sessionTimeoutMinutes === 0) {
    return <span data-testid='should-not-use-idle-timer' />;
  }

  const timeout = sessionTimeoutMinutes * ONE_MINUTE_AS_MS - PROMPT_TIME;
  const promptTimeout = PROMPT_TIME;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let idleTimer: IIdleTimer;
  const [open, setOpen] = useState(false);

  const onPrompt = () => {
    if (userIsLoggedIn) {
      setOpen(true);
    }
  };

  const onActive = () => {
    setOpen(false);
  };

  const onAction = (event) => {
    if (open && event.target?.getAttribute?.('id') === stayLoggedInId && event.type === 'click') {
      setOpen(false);
      idleTimer.activate();
    } else if (!open && defaultEvents.includes(event.type)) {
      idleTimer.activate();
    }
  };

  const onIdle = () => {
    setOpen(false);
    if (UserStore.getState().data) {
      navigate('/');
      queryClient.clear();
      UserStore.logout('SessionTimeout');
    }
  };

  idleTimer = useIdleTimer({
    stopOnIdle: true,
    timeout,
    promptTimeout,
    crossTab: true,
    syncTimers: 1_000,
    leaderElection: true,
    onPrompt,
    onActive,
    onAction,
    onIdle,
  });

  const handleLogoutOnClick = () => {
    onIdle();
  };

  const handleStayLoggedInOnCLick = (event) => {
    onAction(event);
  };

  return (
    <span data-testid='should-use-idle-timer'>
      <ModalContainer open={open}>
        <StyledModalContent>
          <SessionExpirationIllustration />
          <StyledHeading>
            <Countdown />
          </StyledHeading>
          <Message>You will be automatically logged out. Do you want to continue with your session?</Message>
          <StyledButtonGroup>
            <Button buttonSize='large' label='Logout' variant='secondary' onClick={handleLogoutOnClick} />
            <Button buttonSize='large' id={stayLoggedInId} label='Stay logged in' onClick={handleStayLoggedInOnCLick} />
          </StyledButtonGroup>
        </StyledModalContent>
      </ModalContainer>
    </span>
  );
}

const StyledModalContent = styled(ModalContent)`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  margin-top: var(--sizes-70);
`;

const Message = styled.p`
  font: var(--font-large-16pt-normal);
  color: var(--color-gray-90);
  margin-bottom: 40px;
  text-align: center;
  max-width: 25rem;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 40px;
`;
