import React from 'react';

import { GridRow } from '@mui/x-data-grid-pro';

import type { GridRowProps } from '@mui/x-data-grid-pro';

export function CustomRow(props: GridRowProps) {
  const lastColumnIndex = props.lastColumnToRender - 1;
  const testId = `data-grid${props.visibleColumns[lastColumnIndex]?.field === 'Actions' ? '-pinned' : ''}-row`;
  return <GridRow {...props} data-testid={testId} />;
}
