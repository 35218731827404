import { useQuery } from '@tanstack/react-query';

import { fetchData } from 'src/app/queries';

export const favoritesQueryKey = ['reports-favorites'];

export function useFavoriteReportsQuery() {
  // TODO make a query key factory for all Reports queries

  type Favorite = { StaticReportId: number; UserId: number };

  return useQuery({
    queryKey: favoritesQueryKey,
    queryFn: () => fetchData<Favorite[]>('api/posv3/reports/get-favorites'),
  });
}
