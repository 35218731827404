
import { HardwareService } from "../HardwareService";

import type { Peripheral } from "./Peripheral";
import type { PeripheralService } from "./PeripheralService";
import type { IPeripheralServiceManager } from "./interfaces";
import type { DeviceStatusEvent, PeripheralSearchConfig, PeripheralType } from "./types";
import { peripheralTypeName } from "./types";

// Store data about a device enabling us to detect state changes.
// The device object may contain pointers to objects like USB devices so a
// copy of the current connected state is used for comparison when updates occur
type PeripheralData<T extends Peripheral> = {
  device: T, 
  connected: boolean,
  serviceName: string,
}

// Track whether service is currently enable and use state to add or remove devices.
// This value is NOT persisted across loads and it is expected consuming apps will
// utilize the autoEnable setting to manage which services initialize on load.
export type ServiceState<T extends Peripheral> = {
  enabled: boolean,
  service: PeripheralService<T>,
}

// Handles device across multiple services for a specific peripheral type
export class PeripheralServiceManager<T extends Peripheral> implements IPeripheralServiceManager<T> {
  private type: PeripheralType;

  filterEnabled = true;
  constructor(type: PeripheralType) {
    this.type = type;
  }

  deviceById(id: string): T | undefined { return this.grid.get(id)?.device; }

  // Keep a list of all devices in an easily accessible map
  private grid: Map<string, PeripheralData<T>> = new Map();
  private get gridValues(): PeripheralData<T>[] { return Array.from(this.grid.values()) }

  // Custom events specific to this peripheral type across all services
  events: EventTarget = new EventTarget();

  // Registered service
  private serviceState: Record<string, ServiceState<T>> = {};
  get state(): ServiceState<T>[] {
    return Object.values(this.serviceState);
  }

  private get enabledServices(): PeripheralService<T>[] {
    return this.state.filter(it => it.enabled).map(it => it.service);
  }

  private get disabledServices(): PeripheralService<T>[] {
    return this.state.filter(it => !it.enabled).map(it => it.service);
  }

  get devices(): T[] { return this.gridValues.map(it => it.device) };
  get hasDevices(): boolean { return this.grid.keys.length > 0 }
  get typeName(): string { return peripheralTypeName(this.type); }

  //#region Private Methods
  // Emit events 
  private emitDeviceStatus(device: Peripheral, source?: string): void {
    const data: DeviceStatusEvent = { connected: device.isConnected, device: device }
    HardwareService.logger.d(`Emit ${'device-status'} for ${this.typeName}`,  { from: source, ...data });
    this.events.dispatchEvent(new CustomEvent('device-status', { detail: data }));
  }
  
  private emitDevicesUpdated(): void {
    const data = { devices: this.devices };
    HardwareService.logger.debug({
      message: `Emit devices-updated`,
      details: {
        type: this.typeName,
        ...data,
      },
    })
    this.events.dispatchEvent(new CustomEvent('devices-updated', { detail: data }));
  }

  private removeDeviceAndEmitEvent(device: Peripheral, emit: boolean): boolean {
    const item = this.grid.get(device.id);
    if (!item) return false;

    item.device.dispose();

    if (emit) {
      this.emitDeviceStatus(item.device, 'removeDevice');
    }

    if (!this.grid.delete(item.device.id)) return false;

    if (emit) {
      this.emitDevicesUpdated();
    }
    return true;
  }

  private updateServiceResultsAndEmitEvent(devices: T[], service: PeripheralService<T>, remove = true) {
    let emit = false;

    for (const device of devices) {
      // add device
      if (!this.grid.has(device.id)) {
        this.grid.set(device.id, { device, connected: device.isConnected, serviceName: service.name });

        // initialize instance only once when added to map
        device.initialize();

        if (service.config.autoConnect) {
          device.connect();
        }

        emit = true;
        continue;
      }

      // update device if connection status has changed
      const current = this.grid.get(device.id);
      if (current?.connected !== device.isConnected) {
        this.grid.set(device.id, { device, connected: device.isConnected, serviceName: service.name });
        emit = true;
        this.emitDeviceStatus(device, 'updateServiceResults');
        continue;
      }
    }

    if (remove) {
      const currentDeviceIds = devices
        .map(it => it.id);

      const removeDevices = this.gridValues
        .filter(it => it.serviceName == service.name)
        .filter(it => !currentDeviceIds.includes(it.device.id));

      for (const removeDevice of removeDevices) {
        const removed = this.removeDeviceAndEmitEvent(removeDevice.device, false);
        if (removed) {
          emit = true;
        }
      }
    }

    if (emit) {
      this.emitDevicesUpdated();
    }
  }
  //#endregion

  addEventListener(type: 'devices-updated' | 'device-status' | string, callback: EventListenerOrEventListenerObject): void { this.events.addEventListener(type, callback); }
  removeEventListener(type: 'devices-updated' | 'device-status' | string, callback: EventListenerOrEventListenerObject): void { this.events.removeEventListener(type, callback); }

  dispose(): void {
    this.state.map(state => state.service).forEach(service => this.unregister(service));
  }

  async search(config?: Partial<PeripheralSearchConfig>): Promise<T[]> {
    for (const service of this.enabledServices) {
      try {
        HardwareService.logger.d(`Search devices`, { service: service.name, type: this.typeName });
        const devices = await service.search(config);
        this.updateServiceResultsAndEmitEvent(devices, service, false);
      }
      catch (e) {
        HardwareService.logger.e('Uncaught discovery', e)
      }
    }

    return this.devices.sort((a, b) => a.name.localeCompare(b.name));
  }

  // Resync devices against those currently reported as available by the service.
  // Dispose and remove devices no longer available.
  // Add and initialize new devices.
  async invalidate(serviceName: string): Promise<void> {
    // detect service
    const service = this.enabledServices.find(it => it.name == serviceName);
    if (!service) return;

    const devices = await service.search();
    this.updateServiceResultsAndEmitEvent(devices, service)
  }

  async invalidateDeviceId(id: string): Promise<void> {
    const serviceName = this.gridValues.find(it => it.device.id == id)?.serviceName;

    if(serviceName) {
      await this.invalidate(serviceName)
    }
  }

  async reconnectService(serviceName: string): Promise<T[]> {
    const service = this.enabledServices.find(it => it.name == serviceName)
    if(!service) return [];

    const devices = await service.search()
    this.updateServiceResultsAndEmitEvent(devices, service)
    return this.devices;
  }

  async register(...services: PeripheralService<T>[]): Promise<void> {

    const promises = services
      // filter out enabled services
      .filter((svc) => !this.enabledServices.some((it) => it.name === svc.name))
      .map(async (service): Promise<boolean> => {
        if (!this.serviceState[service.name]) {
          this.serviceState[service.name] = { enabled: false, service: service };

          // Allow service to be registered in a disabled state while not preventing
          // subsequent registrations
          if(service.config.autoEnable === false) { return false; }
        }

        HardwareService.logger.d(`Register ${this.typeName} service ${service.name}`);
        const initialized = await service.initialize();
        const enabled = initialized && service.isSupported;
        this.serviceState[service.name].enabled = enabled;

        await this.reconnectService(service.name);

        this.events.dispatchEvent(new CustomEvent('registered-service', { detail: { service, enabled } }));

        return enabled;
      });

    await Promise.all(promises);
  }

  // Removes device from grid and disposes
  removeDevice(device: Peripheral): boolean {
    return this.removeDeviceAndEmitEvent(device, true);
  }

  unregister(...services: PeripheralService<T>[]): void {
    for (const service of services) {
      if (!this.enabledServices.some((svc) => service.name === svc.name)) {
        continue;
      }

      // remove all devices
      this.gridValues
        .filter(it => it.serviceName == service.name)
        .forEach(it => this.removeDevice(it.device))

      // dispose service
      service.dispose();

      this.serviceState[service.name].enabled = false;

      this.events.dispatchEvent(new CustomEvent('unregistered-service', { detail: { name: service.name, enabled: false } }));
      HardwareService.logger.d(`Unregister ${this.typeName} service ${service.name}`);
    }
  }
}
