// 💀

import React from 'react';

import styled from 'styled-components';

import { Loader } from 'src/app/components/lib/loader';

export function SkeletonLoader({ height }: { height?: string }) {
  return (
    <StyledWrapper $height={height}>
      <Loader size='3x' variant='grey' />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ $height?: string }>`
  background-color: var(--color-brand-primary-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sizes-70);
  width: 100%;
  height: ${({ $height }) => $height ?? '400px'};
`;
