import keyMirror from 'keymirror';

export const endPoints = {
  GET_EXTERNAL_WEBSITES: 'api/v2/devices/get-external-websites',
  UPDATE_EXTERNAL_WEBSITES: 'api/v2/devices/update-external-websites',
  PRINT_SYNC_PRINTERS: 'api/posv3/print/sync-printers',
  PRINT_ACCOUNT_DETAILS: 'api/posv3/print/printnode-details',
  PRINT_ENTERPRISE_AVAILABLE: 'api/posv3/print/is-enterprise-account-available',
  PRINT_LIST_PRINTERS: 'api/posv3/print/list-printers',
  PRINT_UPDATE_PRINTER: 'api/posv3/print/update-printer',
  PRINT_USE_ENTERPRISE_ACCT: 'api/posv3/print/setup-enterprise-account',
  PRINT_SETUP_PRINT_ACCT: 'api/posv3/print/setup-print-node',
  PRINT_DELETE_PRINT_ACCT: 'api/posv3/print/delete-print-node',
  UPDATE_TAX_PRODUCT_CATEGORY: 'api/tax/product-category/update',
  UPDATE_TAX_RATE: 'api/tax/update',
  DELETE_TAX_RATE: 'api/tax/delete',
  DELETE_TAX_CATEGORY: 'api/tax/product-category/delete',
  GET_ALL_TAX_PRODUCT_CATEGORY: 'api/tax/product-category/get-all',
  GET_ALL_TAX: 'api/tax/get-all',
  SET_USER_PERMISSION: 'api/permissions/user/set',
  ASSIGN_USER_TO_GROUP: 'api/usergroup/user/assign',
};

export const actions = keyMirror({
  OPEN_EDITOR: null,
  CLOSE_EDITOR: null,
  SET_PRINTER: null,
  SET_ROWS_PER_PAGE: null,

  SINGLE_SETTING_SAVED: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
  EDITOR_EVENT: 'editorEvent',
  PRINTER_EVENT: 'printerEvent',
});
