import React from 'react';

import { FormGroup as MUIFormGroup, FormControlLabel as MUIFormControlLabel } from '@mui/material';

import { ErrorMessageIcon } from 'src/app/components/icons/error-message';
import { FormControl } from 'src/app/components/lib/form-control';
import { useDarkMode } from 'src/app/state/dark-mode';

import { WarnMessageIcon } from '../../icons/warning-message';

import {
  RebrandFormContainer,
  RebrandFormTitle,
  RebrandFormSection,
  StyledFormFieldContainer,
  StyledFormFieldSubContent,
  StyledFormValidationMessage,
  FormStickyFooterButtonContainer,
  FormStickyFooterContainer,
  StyledFormWarnMessage,
} from './form-rebrand.styles';

export const FormGroup = MUIFormGroup;
export const FormControlLabel = MUIFormControlLabel;

type FormContainerProps = {
  automationId?: string;
  children: React.ReactNode;
  className?: string;
};

// This should wrap the entire form (Dividers, Button, FormSections, FormTitles, etc)
export function FormContainer({ automationId, children, className = '' }: FormContainerProps): JSX.Element {
  const darkMode = useDarkMode();
  return (
    <RebrandFormContainer
      $darkMode={darkMode}
      className={`${className} form-form-container`}
      data-testid={automationId}
    >
      {children}
    </RebrandFormContainer>
  );
}

type FormSectionProps = {
  children: React.ReactNode;
  className?: string;
};

// This should wrap only groups/sections of form fields (Input, Select, Toggle, etc)
export function FormSection({ children, className = '', ...other }: FormSectionProps): JSX.Element {
  return (
    <RebrandFormSection {...other} className={`${className} form-form-section`}>
      {children}
    </RebrandFormSection>
  );
}

type FormTitleProps = {
  children: React.ReactNode;
  className?: string;
  noMargin?: boolean;
  styleOverrides?: any;
};

export function FormTitle({ children, className = '', noMargin, styleOverrides = {} }: FormTitleProps): JSX.Element {
  const darkMode = useDarkMode();
  return (
    <RebrandFormTitle $darkMode={darkMode} $overrides={styleOverrides} className={className} noMargin={noMargin}>
      {children}
    </RebrandFormTitle>
  );
}

type FormFieldContainerProps = {
  children: React.ReactNode;
  className?: string;
  subContent?: React.ReactNode | string;
};

export function FormFieldContainer({ children, className = '', subContent }: FormFieldContainerProps): JSX.Element {
  return (
    <StyledFormFieldContainer className={className}>
      {children}
      {subContent && (
        <FormControl>
          <StyledFormFieldSubContent>{subContent}</StyledFormFieldSubContent>
        </FormControl>
      )}
    </StyledFormFieldContainer>
  );
}

type FormStickyFooterProps = {
  Actions: React.ReactNode;
  FormValidation?: React.ReactNode;
  FormWarnings?: React.ReactNode;
};

export function FormStickyFooter({ FormValidation, Actions, FormWarnings }: FormStickyFooterProps): JSX.Element {
  const darkMode = useDarkMode();
  return (
    <FormStickyFooterContainer $darkMode={darkMode} data-testid='form-footer-container'>
      {FormWarnings && <FormWarnMessage>{FormWarnings}</FormWarnMessage>}
      {FormValidation && <FormValidationMessage>{FormValidation}</FormValidationMessage>}
      <FormStickyFooterButtonContainer>{Actions}</FormStickyFooterButtonContainer>
    </FormStickyFooterContainer>
  );
}

export function FormValidationMessage({ children }): JSX.Element {
  return (
    <StyledFormValidationMessage data-testid='form-validation-message-container'>
      <ErrorMessageIcon />
      {children}
    </StyledFormValidationMessage>
  );
}

export function FormWarnMessage({ children }): JSX.Element {
  return (
    <StyledFormWarnMessage data-testid='form-warn-message-container'>
      <WarnMessageIcon />
      {children}
    </StyledFormWarnMessage>
  );
}
