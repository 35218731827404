import React, { useRef } from 'react';

import { startCase } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { DragHandleIcon } from 'src/app/components/icons/drag-handle-icon';
import { Checkbox } from 'src/app/components/lib/checkbox';

export type ReorderableCheckboxRowProps = {
  disabled: boolean;
  field: string;
  handleCheckboxOnChange: (field: string) => void;
  headerName?: string;
  index: number;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  searchValue: string;
  workingColumnVisibilityModel: Record<string, boolean>;
};

type DragItem = {
  field: string;
  index: number;
  type: string;
};

const CHECKBOX_ROW_TYPE = 'checkbox-row';

export function ReorderableCheckboxRow({
  disabled,
  field,
  handleCheckboxOnChange,
  index,
  moveColumn,
  searchValue,
  workingColumnVisibilityModel,
  headerName,
}: ReorderableCheckboxRowProps) {
  const ref = useRef(null);
  const isChecked = workingColumnVisibilityModel[field];
  const isDraggable = isChecked && !searchValue;

  const [{ handlerId }, drop] = useDrop({
    accept: CHECKBOX_ROW_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveColumn(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: CHECKBOX_ROW_TYPE,
    item: () => ({ field, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isDraggable,
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <ReorderableCheckboxRowContainer
      data-handler-id={handlerId}
      data-testid='reorderable-checkbox-row'
      ref={ref}
      style={{ opacity }}
    >
      <Checkbox
        checked={isChecked}
        disabled={disabled}
        label={headerName ?? startCase(field)}
        onChange={() => handleCheckboxOnChange(field)}
      />
      {isDraggable && <DragHandleIcon />}
    </ReorderableCheckboxRowContainer>
  );
}

const ReorderableCheckboxRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sizes-20) var(--sizes-40);
  border-radius: 6px;
  border: 1px solid var(--color-gray-20, #e3e7e9);
  background: var(--color-grayscale-white, #fff);

  &:hover {
    border-radius: var(--button-border-radius-small);
    background-color: var(--color-gray-10);
  }
`;
