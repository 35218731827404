import React from 'react';

import { CaretDown } from 'src/app/components/icons';
import { Input, InputAdornment } from 'src/app/components/lib/input';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { SxProps } from '@mui/material';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown/menu/types';

type MultiSelectInputProps = {
  automationId?: string;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  gridColumns?: 4 | 6;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  helpText?: string;
  id?: number | string;
  label?: React.ReactElement | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  loading?: boolean;
  required?: boolean;
  selectionName?: string;
  selectionText?: string;
  sx?: SxProps;
  tooltip?: string;
  value: DropdownMenuOption[];
  width?: string;
};

export function DropdownInputMultiple({
  className,
  gridColumns,
  handleClick,
  helpText = '',
  id,
  label,
  labelPlacement,
  required,
  selectionName = '',
  selectionText: overrideSelectionText,
  sx,
  tooltip,
  value,
  width,
  loading,
  automationId = '',
  disabled = false,
  errorMessage,
}: MultiSelectInputProps) {
  const darkMode = useDarkMode();

  const hasValue = value && value.length > 0;
  const provisionalSelectionText = [value.length, selectionName, 'selected'].filter((_) => _).join(' ');
  const selectionText = hasValue && !overrideSelectionText ? provisionalSelectionText : overrideSelectionText ?? '';

  return (
    <Input
      autoComplete='off'
      automationId={automationId}
      className={className}
      disabled={disabled}
      endAdornment={
        <InputAdornment position='end'>
          <CaretDown fill={darkMode ? 'var(--color-brand-primary-white)' : undefined} />
        </InputAdornment>
      }
      errorMessage={errorMessage}
      gridColumns={gridColumns}
      id={String(id)}
      label={label}
      labelPlacement={labelPlacement}
      loading={loading}
      placeholder={helpText}
      required={required}
      sx={sx}
      tooltip={tooltip}
      value={selectionText}
      width={width}
      onClick={handleClick}
    />
  );
}
