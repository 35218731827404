// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { IS_DEVELOPMENT_APP } from 'src/app/constants/environment';

export function initPendo() {
  try {
    if (!IS_DEVELOPMENT_APP) {
      /* eslint-disable */
      /* @ts-ignore */
      // pendo init
      (function (apiKey) {
        (function (p, e, n, d, o) {
          let v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
          for (w = 0, x = v.length; w < x; ++w) {
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
          }
          y = e.createElement(n);
          y.async = !0;
          y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })('28a64b1a-322b-48a4-6319-f6c7ad86b901');
      /* eslint-enable */
    }
  } catch (error) {
    console.error('Failed to initialize Pendo', error);
  }
}
