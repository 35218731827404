import { createContext, useContext } from 'react';

type DataGridWrapperContextData = {
  tableName: string | undefined;
};

export const DataGridWrapperContext = createContext<DataGridWrapperContextData>({ tableName: undefined });

export function useDataGridWrapperContext() {
  return useContext(DataGridWrapperContext);
}
