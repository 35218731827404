import _ from 'lodash';
import moment from 'moment';

var TableLogic = {
  translate: (title) => {
    switch (title.toLowerCase()) {
      case 'accts_addr1':
        return 'Address';

      case 'avgpercart':
        return 'Avg. $/Item';

      case 'biotrackdt':
        return 'Biotrack Date';

      case 'productcategory':
        return 'Category';

      case 'paidincash':
        return 'Cash';

      case 'accts_city':
        return 'City';

      case 'cashpaidback':
        return 'Change';

      case 'avgcart':
        return 'Cart Average';

      case 'patientname':
        return 'Customer';

      case 'cultivationstage':
        return 'Cultivation Stage';

      case 'pcounter':
      case 'seedcnt':
        return 'Count';

      case 'posdate':
      case 'posinvoiceddate':
      case 'transactiondate':
        return 'Date';

      case 'voiddate':
        return 'Void Date';

      case 'canceldate':
        return 'Cancel Date';

      case 'cancelreason':
        return 'Cancel Reason';

      case 'patientdob':
      case 'dateofbirth':
        return 'DOB';

      case 'paidindebit':
        return 'Debit';

      case 'paidincredit':
        return 'Credit';

      case 'discountdescription':
      case 'productdesc':
      case 'whseproducts_description':
        return 'Description';

      case 'discdatefrom':
        return 'From';

      case 'groupname':
        return 'Group Name';

      case 'hiredate':
        return 'Hired';

      case 'idnumber':
        return 'ID #';

      case 'inventoryroom':
        return 'Inventory Room';

      case 'lasttransdate':
        return 'Last Transaction';

      case 'paidinloyalty':
        return 'Loyalty';

      case 'ownerlocation':
      case 'zone':
        return 'Location';

      case 'mastercategory':
        return 'Master Category';

      case 'mjstateidno':
        return 'MMJ ID';

      case 'accts_name':
      case 'ownername':
        return 'Name';

      case 'productoz':
        return 'OZ';

      case 'plantserialno':
        return 'Serial No';

      case 'packageid':
        return 'Package No';

      case 'pricepercent':
        return 'Price Percent';

      case 'pricepoint':
        return 'Price Point (from)';

      case 'pricepoint1':
        return 'Price Point (to)';

      case 'patientphone':
      case 'driverphone':
        return 'Phone No';

      case 'cnt':
        return 'Qty';

      case 'posid':
        return 'POS ID';

      case 'receiptno':
        return 'Receipt #';

      case 'remainingqty':
        return 'Remaining Qty';

      case 'accts_state':
        return 'State';

      case 'driverstateid':
        return 'State ID #';

      case 'posstatus':
      case 'plantstatus':
        return 'Status';

      case 'quarantineroom':
        return 'Quarantine Room';

      case 'soldby':
        return 'Sold By';

      case 'bench':
        return 'Table';

      case 'orderno':
        return 'Title';

      case 'totalinvoice':
        return 'Total';

      case 'postotalitems':
        return 'Total Items';

      case 'startbalance':
        return 'Start Balance';

      case 'dispensaryrestrictedroom':
        return 'Sales Floor';

      case 'discdateto':
        return 'To';

      case 'waitingroom':
        return 'Waiting Room';

      case 'loyaltydiscount':
        return 'Type';
      case 'isreceiptprinter':
        return 'Receipt?';
      case 'islabelprinter':
        return 'Label?';
      case 'printerid':
        return 'ID';
      case 'emptycontwgt':
        return 'Empty Weight';
      case 'fullcontwgt':
        return 'Full Weight';
      case 'netcontwgt':
        return 'Net Weight';
      default:
        return title;
    }
  },

  formatAsPrice: (val) => {
    switch (val.toLowerCase()) {
      case 'totalspend':
      case 'avgcart':
      case 'totalinvoice':
      case 'paidincash':
      case 'paidindebit':
      case 'paidincredit':
      case 'cashpaidback':
      case 'avgpercart':
      case 'paidinloyalty':
      case 'unitprice':
      case 'discountamt':
      case 'poscouponamt':
      case 'postax':
      case 'posproductcost':
      case 'posnetprofit':
      case 'voidamt':
      case 'cashpaid':
      case 'debitpaid':
      case 'creditpaid':
      case 'epaymentpaid':
      case 'cannabissales':
      case 'noncannabissales':
      case 'duecustomer':
      case 'loyaltyamt':
      case 'startbalance':
      case 'transactionamt':
      case 'endingbalance':
      case 'amount':
      case 'discounts':
      case 'totalsales':
      case 'netsales':
      case 'tax':
      case 'cost':
      case 'netprofit':
      case 'price':
      case 'retail':
      case 'wholesale':
      case 'soldprice':
      case 'priceperunit':
      case 'taxamount':
      case 'ending balance':
      case 'total':
      case 'totalprice':
      case 'discount':
      case 'unitdiscount':
      case 'subtotal':
      case 'totaldiscount':
      case 'totalcost':
      case 'productunitprice':
      case 'producttotalprice':
      case 'checkpaid':
        return true;

      default:
        return false;
    }
  },

  formatAsDate: (val) => {
    switch (val.toLowerCase()) {
      case 'posdate':
      case 'discdatefrom':
      case 'discdateto':
      case 'biotrackdt':
      case 'date':
      case 'endofmonth':
      case 'posinvoiceddate':
      case 'posdelivereddate':
      case 'dateofbirth':
      case 'hireddate':
      case 'harvestdate':
      case 'curedate':
      case 'batchdt':
      case 'statusdate':
      case 'batchclosed':
      case 'packagingdate':
      case 'inventorydate':
      case 'issueddate':
      case 'batchclosedate':
      case 'statedate':
      case 'scheduleddate':
      case 'thcdate':
      case 'testdate':
      case 'testeddate':
      case 'packagedate':
      case 'expirationdate':
      case 'extractiondate':
      case 'wastedate':
      case 'created':
      case 'recondate':
      case 'closeddate':
      case 'hiredate':
      case 'transdate':
      case 'enddate':
      case 'startdate':
      case 'notedate':
        return 'MM/DD/YYYY';

      default:
        return false;
    }
  },

  formatAsDateTime: (val) => {
    switch (val.toLowerCase()) {
      case 'transactiondate':
      case 'voiddate':
      case 'canceldate':
      case 'close date':
      case 'harvest date':
      case 'transactiondatetime':
      case 'createdon':
      case 'ftpdttm':
        return 'MM/DD/YYYY hh:mm:ss';
      default:
        return false;
    }
  },

  tdAlign: (val) => {
    if (TableLogic.formatAsDate(val) !== false) {
      return 'left';
    }

    if (TableLogic.formatAsDateTime(val) !== false) {
      return 'right';
    }

    if (TableLogic.formatAsPrice(val) !== false) {
      return 'right';
    }

    switch (val.toLowerCase()) {
      case 'totalcustomers':
      case 'newcustomers':
      case 'totalitemssold':
      case 'totalitemssoldcannabis':
      case 'totalitemssoldnoncannabis':
      case 'cnt':
      case 'qty':
      case 'ordernumber':
      case 'receipt':
      case 'productsku':
      case 'sku':
      case 'productid':
      case 'processyear':
      case 'processmonth':
      case 'received':
      case 'sold':
      case 'removed':
      case 'weeklyinventorycnt':
      case 'perunit':
      case 'costperunit':
      case 'available':
        return 'right';
    }

    // for center here

    // return default le
    return 'left';
  },

  hideColumn: (val, columnArr) => {
    if (columnArr) {
      for (let i = 0; i < columnArr.length; i++) {
        if (columnArr[i].toLowerCase() === val.toLowerCase()) {
          return true;
        }
      }
    }

    switch (val.toLowerCase()) {
      case 'uuid':
      // case 'posid':
      case 'match':
      case 'isselected':
        return true;

      default:
        return false;
    }
  },

  removeNull(data) {},

  getColumnTitle: (data, defined) => {
    if (defined) {
      return _.keys(defined);
    }

    if (data && data.length > 0) {
      const tmp = [];
      for (const x in data[0]) {
        tmp.push(x);
      }
      return tmp;
    }

    return [];
  },

  getFilteredData: (data, arr, str) => {
    if (str.length === 0) {
      return data;
    }

    let match = false;
    const tmp = [];

    for (let i = 0; i < data.length; i++) {
      match = false;
      for (let j = 0; j < arr.length; j++) {
        if (data[i][arr[j]] && data[i][arr[j]].toString().toLowerCase().indexOf(str) !== -1) {
          match = true;
        }
      }

      if (match) {
        tmp.push(data[i]);
      }
    }

    return tmp;
  },

  getSortedData: (data, arr, by, asc, layout) => {
    if (!by || by.length === 0) {
      return data;
    }

    for (let i = 0; i < data.length; i++) {
      for (const x in data[i]) {
        if (data[i][x] === null) {
          data[i][x] = '';
        }
      }
    }
    const column = layout.find((x) => x.name === by);

    if (!column) {
      return data;
    }

    let transform = (x) => (x || '').toString().toLowerCase();

    if (_.isFunction(column.sort.sortValue)) {
      return _.orderBy(data, [(x) => column.sort.sortValue(x) || ''], asc);
    }
    if (column.sort.type === 'int') {
      transform = parseInt;
    } else if (column.sort.type === 'flt') {
      transform = parseFloat;
    } else if (column.sort.type === 'date') {
      transform = moment;
    }

    const tmp = _.orderBy(data, [(x) => (x[by] ? transform(x[by]) : '')], asc);

    return tmp;
  },
};

export default TableLogic;
