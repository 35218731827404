import AppDispatcher from '../utils/AppDispatcher';
import { SimpleCachedDataStore } from './SimpleCachedDataStore';
import { actions as AppEvents } from '../constants/AppConstants';

class PrinterStore extends SimpleCachedDataStore {
  dispatchToken;
  _labelPrinterId = null;
  sourceUrl = 'api/posv3/print/list-printers';
  entity = 'Printers';
  private _labelId = 0;
  constructor() {
    super();
  }

  get printers() {
    return this.data;
  }

  get currentLabelPrinterId() {
    return this._labelPrinterId;
  }

  set currentLabelPrinterId(val) {
    this._labelPrinterId = val;
    this.emitChange(this.printers);
  }

  get currentLabelId() {
    return this._labelId;
  }

  set currentLabelId(val) {
    this._labelId = val;
    this.emitChange(this.printers);
  }
}

const PrintStore = new PrinterStore();

PrintStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.APP_BOOTED:
      PrintStore._labelPrinterId = action.data.printerId;
      break;
  }
});

export default new PrinterStore();
