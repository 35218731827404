
import { vendorForId } from "../../utils/index";
import { HardwareService } from "../HardwareService";

import type { PeripheralServiceManager } from "./PeripheralServiceManager";
import type { IPeripheral } from "./interfaces";
import type { ConnectionType } from "./types";
import { PeripheralType, peripheralTypeName } from "./types";

export abstract class Peripheral implements IPeripheral {
  private peripheralType: PeripheralType;
  
  constructor(peripheralType: PeripheralType, connectionType: ConnectionType) {
    this.peripheralType = peripheralType;
    this.connectionType = connectionType;
  }

  connectionType: ConnectionType;

  protected get typeName(): string { return peripheralTypeName(this.peripheralType); }

  abstract get isConnected(): boolean
  abstract get id(): string;

  get name(): string { 
    if(this.vendorId) {
      const vendor = vendorForId(this.vendorId, this.peripheralType);
      if(vendor) {
        return `${vendor.name} ${this.typeName}`;
      }
    }

    return `${this.typeName} ${this.id}`;
  }

  get productId(): number | undefined { return undefined; }
  get vendorId(): number | undefined { return undefined; }
  
  abstract doConnect(): Promise<boolean>;  
  abstract doDisconnect(): Promise<boolean>;
  doRevokePermission(): Promise<boolean> { throw new Error('Method not implemented.'); }

  // Connect to and listen for events specific to this device.
  // This method will only be called once when initially added
  // to the device grid on discovery or reconnect.
  protected attachEventListeners(): void { /* no-op */ };

  // Disconnect from and stop listening for events specific to this device.
  // This method is called when the device is removed from the 
  // device grid on disconnect or when forgotten.
  protected detachEventListeners(): void { /* no-op */ };

  async connect(): Promise<boolean> {
    const result = await this.doConnect();
    this.serviceManager.invalidateDeviceId(this.id)
    return result;
  }

  async disconnect(): Promise<boolean> {
    const result = await this.doDisconnect();
    this.serviceManager.invalidateDeviceId(this.id)
    return result;
  }

  async dispose(): Promise<void> {
    this.detachEventListeners();
  };

  async initialize(): Promise<void> {
    this.attachEventListeners();
  }

  async invalidate(): Promise<void> {
    this.serviceManager.invalidateDeviceId(this.id)
  }

  private get serviceManager(): PeripheralServiceManager<any> {
    switch(this.peripheralType) {
      case PeripheralType.labelPrinter: return HardwareService.labelPrinter;
      case PeripheralType.receiptPrinter: return HardwareService.receiptPrinter;
      case PeripheralType.scale: return HardwareService.scale;
      case PeripheralType.scanner: return HardwareService.scanner;
    }
  }
  
  // This method is only implemented when the service requires an additional step
  // to remove a known device. It is used by WebUSB to re-require device permission
  // which removes it from the results returned by search()
  async revokePermission(): Promise<boolean> {
    await this.doRevokePermission();
    this.serviceManager.removeDevice(this);
    return true;
  }
}
