import type { PeripheralServiceConfig } from "../../shared/index";
import { PeripheralType } from "../../shared/index";
import { WebUsbPrinterService } from "../../shared/web-usb/WebUsbPrinterService";

import { UsbLabelPrinter } from "./UsbLabelPrinter";

export class UsbLabelPrinterService extends WebUsbPrinterService<UsbLabelPrinter> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.labelPrinter, 'UsbLabelPrinterService', config);
  }
  
  createPrinter(device: USBDevice): UsbLabelPrinter { return new UsbLabelPrinter(device) }
}
