import { Tabs as MUITabs } from '@mui/material';
import styled, { css } from 'styled-components';

export const RebrandTabs = styled(MUITabs)<{
  $scrollButtonsVisible?: boolean;
  $variant?: string;
}>`
  align-items: flex-end;
  overflow: visible;
  width: 100%;

  .MuiTabs-flexContainer {
    gap: 16px;
    justify-content: flex-start;
    margin: auto;
    width: calc(100% + 12px);
    position: absolute;
    left: -12px;
    bottom: 0;

    ${({ $scrollButtonsVisible }) => css`
      ${$scrollButtonsVisible &&
      css`
        div:first-of-type {
          margin-left: 12px;
        }
      `}

      ${!$scrollButtonsVisible &&
      css`
        div:first-of-type {
          margin-left: 0;
        }
      `}
    `}
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .MuiTabScrollButton-root {
    height: 60px;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  .MuiTabs-scroller {
    width: 100%;
    height: 56px;
    ${({ $scrollButtonsVisible }) => css`
      ${!$scrollButtonsVisible &&
      css`
        overflow: visible;
      `}
    `}
  }
`;

export const TabSliderTrack = styled.span`
  height: 1px;
  width: 100%;
  background-color: var(--color-gray-40);
  position: relative;
  left: 0;
  bottom: 1px;
`;

export const TabsContainer = styled.div<{ $marginBottom?: string; $variant?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 'var(--sizes-70)'}; // 32px
`;

// To create 48px of margin bottom under tabs for details pages
export const TabbedDetailsContainer = styled.div`
  margin-top: var(--sizes-40);
`;
