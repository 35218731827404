import type { ReactNode } from 'react';
import React, { useState, useRef } from 'react';

import i18next from 'i18next';
import { CSSTransition } from 'react-transition-group';

import { CloseRebrand, PaginationBack, ExpandIcon, CollapseIcon } from 'src/app/components/icons';
import { Button, LinkButton } from 'src/app/components/lib/button';
import { ValidityErrorMessage } from 'src/app/components/styled-components';
import { omit } from 'src/app/utils/omit';

import {
  StyledModal,
  RebrandModalTitle,
  RebrandModalContent,
  RebrandModalContentText,
  RebrandModalActions,
  RebrandModalDivider,
  ModalHeaderContainer,
  OnCloseBackIconButton,
  OnExpandIconButton,
} from './modal-rebrand.styles';

import type {
  DialogProps,
  DialogTitleProps,
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
} from '@mui/material';
import type { ButtonProps } from 'src/app/components/lib/button';

type ModalProps = {
  className?: string;
  closeButtonAutomationId?: string;
  Content?: React.ReactNode;
  contentPadding?: string;
  expandButtonAutomationId?: string;
  footerPadding?: string;
  height?: string;
  hideHeader?: boolean;
  /**
   * when isExpandable is true, it's best to also specify the width and height prop to ensure the expand transition functions as expected
   */
  isExpandable?: boolean;
  justifyFooterContent?: ModalActionsProps['justifyFooterContent'];
  minWidth?: string;
  onBack?: () => void;
  onClose: () => void;
  open: boolean;
  primaryActions?: ButtonProps[];
  secondaryActions?: ButtonProps[];
  title: string;
  Warning?: string[];
  width?: string;
};

export function Modal(props: ModalProps) {
  const {
    className,
    closeButtonAutomationId,
    expandButtonAutomationId,
    title,
    onClose,
    onBack,
    Content,
    Warning,
    open,
    secondaryActions,
    primaryActions,
    width,
    minWidth,
    height,
    isExpandable,
    justifyFooterContent,
    contentPadding,
    footerPadding,
    hideHeader = false,
  } = props;
  const paperRef = useRef();

  const [isExpanded, setIsExpanded] = useState(false);

  const onExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <CSSTransition classNames='expand-modal' in={isExpanded} key='expand-modal' nodeRef={paperRef} timeout={100}>
      <ModalContainer
        className={className}
        height={height}
        hideHeader={hideHeader}
        minWidth={minWidth}
        open={open}
        PaperProps={{ ref: paperRef }}
        width={width}
        onClose={onClose}
      >
        <ModalHeader
          closeButtonAutomationId={closeButtonAutomationId}
          expandButtonAutomationId={expandButtonAutomationId}
          isExpanded={isExpanded}
          title={title}
          onBack={onBack}
          onClose={onClose}
          onExpand={isExpandable ? onExpand : undefined}
        />
        {Content && (
          <ModalContent contentPadding={contentPadding} style={{ padding: contentPadding }}>
            {Content}
          </ModalContent>
        )}
        {Warning?.length > 0 && (
          <ValidityErrorMessage $overrides={{ width: '100%' }}>
            {Warning.map((warning) => (
              <div key={warning}>{warning}</div>
            ))}
          </ValidityErrorMessage>
        )}
        {(secondaryActions?.length > 0 || primaryActions?.length > 0) && (
          <ModalActions
            footerPadding={footerPadding}
            justifyFooterContent={justifyFooterContent}
            modalHasContent={!!Content}
            primaryActions={primaryActions?.map((action: ButtonProps) => {
              const { label, visible = true, variant, ...restButtonProps } = action;
              if (variant === 'link' || variant === 'link-secondary') {
                return (
                  visible && (
                    <LinkButton
                      {...restButtonProps}
                      key={`${typeof label === 'string' ? label : ''} - button`}
                      label={label}
                      variant={variant === 'link-secondary' ? 'secondary' : 'primary'}
                    />
                  )
                );
              }
              return (
                visible && (
                  <Button
                    {...restButtonProps}
                    key={`${typeof label === 'string' ? label : ''} - button`}
                    label={label}
                    variant={variant ?? 'primary'}
                  />
                ) // TODO-BACKOFFICE make keys required in ButtonProps
              );
            })}
            secondaryActions={secondaryActions?.map((buttonProps) => {
              const { variant, label, visible = true, ...restButtonProps } = buttonProps;

              if (variant === 'link' || variant === 'link-secondary') {
                return (
                  visible && (
                    <LinkButton
                      key={`${typeof label === 'string' ? label : ''} - button`}
                      label={label}
                      {...restButtonProps}
                      variant={variant === 'link-secondary' ? 'secondary' : 'primary'}
                    />
                  )
                );
              }
              return visible && <Button key={`${typeof label === 'string' ? label : ''} - button`} {...buttonProps} />;
            })}
          />
        )}
      </ModalContainer>
    </CSSTransition>
  );
}

type ModalContainerProps = DialogProps & {
  height?: string;
  hideHeader?: boolean;
  minWidth?: string;
  width?: string;
};

export function ModalContainer(props: ModalContainerProps) {
  const { width, minWidth, open, height, hideHeader, ...other } = props;

  return open ? (
    <StyledModal
      {...other}
      $height={height}
      $hideHeader={hideHeader ?? false}
      $minWidth={minWidth}
      $width={width}
      open={open}
    />
  ) : null;
}

type ModalHeaderProps = {
  closeButtonAutomationId?: string;
  expandButtonAutomationId?: string;
  isExpanded?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  onExpand?: () => void;
  title: string;
};

export function ModalHeader(props: DialogTitleProps & ModalHeaderProps) {
  const { closeButtonAutomationId, expandButtonAutomationId, title, onClose, onBack, onExpand, isExpanded } = props;
  return (
    <>
      <ModalHeaderContainer>
        {(onBack || onClose) && (
          <OnCloseBackIconButton
            aria-label='Close modal'
            data-testid={closeButtonAutomationId ?? 'modal-close-button'}
            onClick={onBack || onClose}
          >
            {onBack ? <PaginationBack /> : <CloseRebrand color='var(--color-gray-70)' />}
          </OnCloseBackIconButton>
        )}
        <ModalTitle>{i18next.t(title) ?? title}</ModalTitle>
        {onExpand && (
          <OnExpandIconButton aria-label='Expand modal' data-testid={expandButtonAutomationId} onClick={onExpand}>
            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          </OnExpandIconButton>
        )}
      </ModalHeaderContainer>
      <ModalDivider variant='title' />
    </>
  );
}

export function ModalTitle(props: DialogTitleProps) {
  return <RebrandModalTitle {...props} />;
}

export function ModalContent(props: DialogContentProps & { contentPadding?: string }) {
  return <RebrandModalContent {...omit(props, ['contentPadding'])} />;
}

export function ModalContentText(props: DialogContentTextProps) {
  return <RebrandModalContentText {...props} />;
}

export type ModalActionsProps = DialogActionsProps & {
  footerPadding?: string;
  justifyFooterContent?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly';
  modalHasContent?: boolean;
  primaryActions?: ReactNode;
  secondaryActions?: ReactNode;
};

/**
 * Building block for the actions section. Pass in `modalHasContent` if the modal consists of more than just the header and actions.
 * @param modalHasContent shows a divider line, use if there's content above the actions area
 * @param primaryActions React node for actions on the right side of the modal
 * @param secondaryActions React node for actions on the left side of the modal
 */
export function ModalActions(props: ModalActionsProps) {
  const {
    footerPadding,
    justifyFooterContent,
    modalHasContent,
    primaryActions,
    secondaryActions,
    ...restModalActionProps
  } = props;

  return (
    <>
      <ModalDivider variant={modalHasContent ? 'actions' : 'actions-no-content'} />
      <RebrandModalActions
        $footerPadding={footerPadding}
        $justifyFooterContent={justifyFooterContent}
        {...restModalActionProps}
      >
        <div className='secondary-actions'>{secondaryActions}</div>
        <div className='primary-actions'>{primaryActions}</div>
      </RebrandModalActions>
    </>
  );
}

type DividerProps = {
  variant: 'actions-no-content' | 'actions' | 'section' | 'title';
};

export function ModalDivider(props: DividerProps) {
  const { variant, ...other } = props;

  return <RebrandModalDivider {...other} $variant={variant} />;
}
