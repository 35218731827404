import React from 'react';

export function ErrorMessageIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_722_2241)'>
        <g clipPath='url(#clip1_722_2241)'>
          <path
            d='M16 -0.000908882C11.7171 0.0659165 7.63221 1.81386 4.62666 4.86576C3.13066 6.36155 1.95028 8.14253 1.15553 10.1031C0.360772 12.0636 -0.0321739 14.1638 -3.98443e-06 16.2791C-0.00176078 18.3451 0.404092 20.3911 1.19431 22.3C1.98452 24.2089 3.14358 25.9432 4.60508 27.4034C6.06658 28.8637 7.80181 30.0213 9.71138 30.8099C11.6209 31.5985 13.6673 32.0026 15.7333 31.9991H16C20.2801 31.9604 24.3697 30.2238 27.3699 27.171C30.3701 24.1183 32.0355 19.9992 32 15.7191C32.0039 13.6299 31.5907 11.561 30.7845 9.6336C29.9783 7.70623 28.7954 5.95923 27.3052 4.49505C25.8149 3.03087 24.0473 1.87898 22.106 1.10695C20.1647 0.33491 18.0888 -0.0417378 16 -0.000908882ZM14 22.0524C13.9891 21.7844 14.033 21.5169 14.1289 21.2664C14.2249 21.0159 14.3709 20.7875 14.5581 20.5953C14.7453 20.4032 14.9697 20.2512 15.2176 20.1487C15.4655 20.0461 15.7317 19.9952 16 19.9991C16.5252 20.0021 17.0289 20.2076 17.4064 20.5726C17.7839 20.9377 18.0061 21.4343 18.0267 21.9591C18.0375 22.2231 17.9947 22.4866 17.9011 22.7337C17.8074 22.9809 17.6648 23.2065 17.4817 23.397C17.2986 23.5876 17.0788 23.7392 16.8357 23.8426C16.5925 23.9461 16.3309 23.9993 16.0667 23.9991C15.5368 24.0066 15.0249 23.8076 14.6391 23.4443C14.2534 23.0809 14.0242 22.5818 14 22.0524ZM14.6667 16.7191V8.71909C14.6667 8.36547 14.8071 8.02633 15.0572 7.77628C15.3072 7.52623 15.6464 7.38576 16 7.38576C16.3536 7.38576 16.6928 7.52623 16.9428 7.77628C17.1929 8.02633 17.3333 8.36547 17.3333 8.71909V16.7191C17.3333 17.0727 17.1929 17.4119 16.9428 17.6619C16.6928 17.9119 16.3536 18.0524 16 18.0524C15.6464 18.0524 15.3072 17.9119 15.0572 17.6619C14.8071 17.4119 14.6667 17.0727 14.6667 16.7191Z'
            fill='var(--color-red-60)'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_722_2241'>
          <rect fill='white' height='32' width='32' />
        </clipPath>
        <clipPath id='clip1_722_2241'>
          <rect fill='white' height='32' width='32' />
        </clipPath>
      </defs>
    </svg>
  );
}
