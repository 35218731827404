import React from 'react';

import { useDarkMode } from 'src/app/state/dark-mode';

import { RebrandMenu } from './menu-rebrand.styles';

export function Menu(props) {
  const darkMode = useDarkMode();
  return <RebrandMenu {...props} $darkMode={darkMode} />;
}
