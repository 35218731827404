import { SimpleCachedDataStore } from './SimpleCachedDataStore';

export type ProductTaxCategory = {
  LspId: number;
  ProductTaxCategory: string;
  ProductTaxCategoryId: number;
};

class ProductTaxCategoryStore extends SimpleCachedDataStore {
  sourceUrl = 'api/tax/product-category/get-all';
  entity = 'Tax Categories';

  get categories() {
    return this.data as ProductTaxCategory[];
  }

  get ilCategories() {
    return [
      { Id: 2, Name: 'Infused' },
      { Id: 1, Name: 'Low THC (< 35%)' },
      { Id: 3, Name: 'High THC (> 35%)' },
    ];
  }
}
export default new ProductTaxCategoryStore();
