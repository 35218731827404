import { Tab as MUITab } from '@mui/material';
import styled, { css } from 'styled-components';

export const TabContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  .MuiTab-root {
    min-width: max-content;
  }
`;

export const RebrandTab = styled(MUITab)`
  width: fit-content;
  min-width: 0;
  min-height: 36px;
  padding: var(--sizes-20) var(--sizes-30);
  border-bottom: 2px solid transparent;
  opacity: 1;
  color: var(--color-gray-80);
  font: var(--font-regular-14pt-normal);
  text-transform: initial;
  font-variant-ligatures: none;

  :hover {
    background: var(--color-gray-10);
    border-radius: 4px;
    color: var(--color-grayscale-black);
  }

  &.Mui-selected {
    background-color: var(--color-brand-primary-white);
    border-radius: 0;
    color: var(--color-grayscale-black);
    font-weight: 600;
    outline: none;
  }
`;

export const SelectedTabIndicator = styled.span<{ $width?: number }>`
  ${({ $width }) => css`
    height: 2px;
    width: ${$width}px;
    background-color: var(--color-brand-primary-flora);
    margin: 10px 0 0;
    display: flex;
    align-self: center;
    z-index: 1;
  `}
`;
