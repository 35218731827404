import moment from 'moment';
import { recurrence as RecurrenceTypes } from '../constants/ReportSchedulerConstants';

export default {
  formatDate(date, format = 'MM/DD/YYYY') {
    if (!date) {
      return null;
    }
    const x = moment(date);
    if (!x.isValid()) {
      return null;
    }

    return x.format(format);
  },
  formatDateTime(date, format = 'MM/DD/YYYY hh:mm:ss a') {
    if (!date) {
      return null;
    }
    const x = moment(date);
    if (!x.isValid()) {
      return null;
    }

    return x.format(format);
  },
  formatRecurrence(date, recurrence) {
    let recurrenceName = null;
    let momentName = null;
    switch (Number(recurrence)) {
      case RecurrenceTypes.DAILY:
        recurrenceName = 'daily';
        momentName = '';
        break;
      case RecurrenceTypes.WEEKLY:
        recurrenceName = 'weekly';
        momentName = `every ${date.format('dddd')}`;
        break;
      case RecurrenceTypes.MONTHLY:
        recurrenceName = 'monthly';
        momentName = `every ${date.format('Do')} day of the month`;
        break;
      case RecurrenceTypes.YEARLY:
        recurrenceName = 'yearly';
        momentName = `every ${date.format('MMMM')} ${date.format('Do')}`;
        break;
    }

    const time = date.format('h:mm a');
    const startDate = date.format('MM/DD/YYYY');

    if (!recurrenceName && !momentName) {
      return '';
    }
    return `${recurrenceName} ${momentName} at ${time} starting on ${startDate}`;
  },
};
