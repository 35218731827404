// exports

export const POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
];
export const NO_OPTIONS_TEXT_DEFAULT = 'None found';
export const POPPER_PLACEMENT_DEFAULT = 'bottom-start';
export const SEARCH_PLACEHOLDER_DEFAULT = 'Search...';
export const BASE_ROW_HEIGHT = 20; // px
export const ROW_HEIGHT_PADDING = 24; // px
