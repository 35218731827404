import React from 'react';

import styled from 'styled-components';

import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import type { GridColDef } from 'src/app/components/lib/table';

export type LinkCellColumn<T, U> = GridColDef<T> & {
  linkAction?: (arg?: { row: T; value: U }) => void;
  shouldRenderLink?: boolean;
};

const LinkCellContainerRebrand = styled.div`
  font: var(--font-regular-14pt-semibold);
  color: var(--color-brand-secondary-sapphire);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function LinkCell<T>({ onClick, value }: { onClick: () => void; value: T }) {
  return <LinkCellContainerRebrand onClick={onClick}>{value}</LinkCellContainerRebrand>;
}

function renderCell<T, U>({
  colDef,
  id,
  row,
  value,
}: Omit<GridRenderCellParams<U, T>, 'colDef'> & { colDef: LinkCellColumn<T, U> }) {
  return <LinkCell key={id} value={value} onClick={() => colDef.linkAction?.({ row, value })} />;
}

export function getLinkTextCellColumn<T, U = unknown>({
  linkAction,
  shouldRenderLink = true,
  field,
  ...other
}: LinkCellColumn<T, U>): LinkCellColumn<T, U> {
  return shouldRenderLink
    ? {
        ...other,
        field,
        headerAlign: 'left',
        linkAction,
        renderCell,
        type: 'string',
        shouldRenderLink,
      }
    : {
        ...other,
        field,
        headerAlign: 'left',
        linkAction,
        type: 'string',
        shouldRenderLink,
      };
}

export function getLinkNumberCellColumn<T, U = unknown>({
  field,
  linkAction,
  ...other
}: LinkCellColumn<T, U>): LinkCellColumn<T, U> {
  return {
    ...other,
    field,
    headerAlign: 'right',
    linkAction,
    renderCell,
    type: 'number',
  };
}

type GetLinkCellColumnArg<T> = {
  column: T;
  options?: {
    linkAction?: (arg: { row: unknown; value: unknown }) => void;
    shouldRenderLink?: boolean;
  };
};

export function getLinkCellColumn<T>({
  column,
  options,
}: GetLinkCellColumnArg<T>): T & { headerAlign: 'left'; type: 'string' } {
  if (options?.shouldRenderLink) {
    return {
      ...column,
      headerAlign: 'left',
      renderCell: ({ id, row, value }: GridRenderCellParams) => (
        <LinkCell key={id} value={value} onClick={() => options?.linkAction?.({ row, value })} />
      ),
      type: 'string',
    };
  }
  return {
    ...column,
    headerAlign: 'left',
    type: 'string',
  };
}
