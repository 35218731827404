import { EventEmitter } from 'events';

import { actions as AppEvents } from '../constants/AppConstants';
import { actions as GeneralActions, events as GeneralEvents } from '../constants/GeneralConstants';
import AppDispatcher from '../utils/AppDispatcher';

// import { events as UserEvents } from '../constants/UserConstants';

const _state = {
  rooms: false,
  labels: false,
  strains: false,
  users: false,
};

class GeneralStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  getRooms() {
    return _state.rooms;
  }

  emitChange(callback) {
    this.emit(GeneralEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(GeneralEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(GeneralEvents.CHANGE_EVENT, callback);
  }
}

const GeneralStore = new GeneralStoreClass();

GeneralStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.LOCATION_LOADED:
      _state.rooms = false;
      _state.labels = false;
      _state.strains = false;
      _state.users = false;
      GeneralStore.emitChange();
      break;

    case GeneralActions.ROOMS_LOADED:
      _state.rooms = action.data;
      GeneralStore.emitChange();
      break;

    case GeneralActions.LABELS_LOADED:
      _state.labels = action.data;
      GeneralStore.emitChange();
      break;

    case GeneralActions.STRAINS_LOADED:
      _state.strains = action.data;
      GeneralStore.emitChange();
      break;

    case GeneralActions.USERS_LOADED:
      _state.users = action.data;
      GeneralStore.emitChange();
      break;
  }
});

export default GeneralStore;
