import styled from 'styled-components';

import { DataGrid } from 'src/app/components/lib/table';

import type { DataGridStyledProps } from 'src/app/components/lib/table';

export const FixedHeightDataGrid = styled(DataGrid)<DataGridStyledProps>`
  &.MuiDataGrid-root > div:first-child {
    padding-top: 0;
  }

  .MuiDataGrid-toolbarContainer {
    max-height: 40px;
    min-height: 40px;
    line-height: 40px;
  }
`;
