import keyMirror from 'keymirror';

export const recurrence = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4,
};

export const endPoints = {
  GET_SCHEDULES: 'api/v2/reports/scheduler/GetSchedules',
  UPDATE_SCHEDULE: 'api/v2/reports/scheduler/UpdateSchedule',
  UPDATE_REPORT: 'api/v2/reports/scheduler/UpdateReport',
  UPDATE_RECIPIENT: 'api/v2/reports/scheduler/UpdateRecipient',
  REMOVE_SCHEDULE: 'api/v2/reports/scheduler/DeleteSchedule',
  REMOVE_REPORT: 'api/v2/reports/scheduler/DeleteReport',
  REMOVE_RECIPIENT: 'api/v2/reports/scheduler/DeleteRecipient',
};

export const actions = keyMirror({
  SCHEDULE_LIST_LOADED: null,
  SCHEDULE_SELECTED: null,
  EDIT_SCHEDULE: null,
  EDIT_SCHEDULE_REPORT: null,
  EDIT_SCHEDULE_RECIPIENT: null,
  EDITED_SCHEDULE: null,
  EDITED_SCHEDULE_REPORT: null,
  EDITED_SCHEDULE_RECIPIENT: null,
  CANCEL_EDIT: null,
  REMOVED_SCHEDULE: null,
  REMOVED_REPORT: null,
  REMOVED_RECIPIENT: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
