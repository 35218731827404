import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';
import { actions as UserActions, events as UserEvents } from '../constants/UserConstants';
import { actions as AppEvents } from '../constants/AppConstants';
import ReportsApi from '../api/ReportsApi';

class ReportingStoreClass extends EventEmitter {
  constructor() {
    super();
    this._state = {
      reportList: [],
    };
  }

  boot() {
    ReportsApi.loadReportsForModule('MDSales').then((resp) => {
      this._state.reportList = resp.Data;
      this.emitChange(this._state);
    });
  }

  getState() {
    return this._state;
  }

  getReport(reportId) {
    return this._state.reportList.filter((x) => x.ReportId === reportId)[0];
  }
  emitChange(callback) {
    this.emit(UserEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(UserEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(AppEvents.CHANGE_EVENT, callback);
  }
}

const ReportingStore = new ReportingStoreClass();

ReportingStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case UserActions.SET_USER:
      ReportingStore.boot(action.data);
      break;
  }
});

export default ReportingStore;
