import { ActionsFieldName } from 'src/app/components/lib/table';

import type { GridColumnVisibilityModel, GridStateColDef } from '@mui/x-data-grid';

export const CheckboxFieldName = '__check__';

export const hasDifferentColumnSortOrder = (
  initialColumns: GridStateColDef[],
  updatedColumns: GridStateColDef[]
): boolean => initialColumns.some((column, index) => column?.field !== updatedColumns[index]?.field);

export const initializeColumnOrder = (
  columns: GridStateColDef[],
  visibilityModel: GridColumnVisibilityModel
): (GridStateColDef & { hideFromColumnConfig?: boolean })[] => {
  const dataColumns = filterOutActionsColumns(columns);
  const selectedColumns = filterBySelectedColumns(dataColumns, visibilityModel);
  const unselectedColumns = filterByUnselectedColumns(dataColumns, visibilityModel);
  const unselectedSorted = sortColumnsByHeaderName(unselectedColumns);
  const response = [...selectedColumns, ...unselectedSorted];
  return response;
};

export const filterColumnsBySearch = (search: string, columns: GridStateColDef[]): GridStateColDef[] =>
  columns.filter(({ headerName }) => headerName?.toLowerCase().includes(search.toLowerCase()));

const filterOutActionsColumns = (columns: GridStateColDef[]) =>
  columns.filter(({ field }) => ![ActionsFieldName, CheckboxFieldName].includes(field));

const filterBySelectedColumns = (columns: GridStateColDef[], visibilityModel: GridColumnVisibilityModel) =>
  columns.filter(({ field }) => visibilityModel[field]);

const filterByUnselectedColumns = (columns: GridStateColDef[], visibilityModel: GridColumnVisibilityModel) =>
  columns.filter(({ field }) => !visibilityModel[field]);

const sortColumnsByHeaderName = (columns: GridStateColDef[]): GridStateColDef[] =>
  columns.sort((a, b) => (a?.headerName ?? '').localeCompare(b?.headerName ?? ''));
