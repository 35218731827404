import React from 'react';

export function ManufacturingIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M3.25 18.0801C2.78587 18.0801 2.34075 18.2645 2.01256 18.5926C1.68437 18.9208 1.5 19.3659 1.5 19.8301C1.5 20.2942 1.68437 20.7393 2.01256 21.0675C2.34075 21.3957 2.78587 21.5801 3.25 21.5801H20.75C20.9798 21.5801 21.2074 21.5348 21.4197 21.4469C21.632 21.3589 21.8249 21.23 21.9874 21.0675C22.1499 20.905 22.2788 20.7121 22.3668 20.4998C22.4547 20.2875 22.5 20.0599 22.5 19.8301C22.5 19.3659 22.3156 18.9208 21.9874 18.5926C21.6592 18.2645 21.2141 18.0801 20.75 18.0801H3.25ZM0.951903 17.532C1.5614 16.9225 2.38805 16.5801 3.25 16.5801H20.75C21.612 16.5801 22.4386 16.9225 23.0481 17.532C23.6576 18.1415 24 18.9681 24 19.8301C24 20.2569 23.9159 20.6795 23.7526 21.0738C23.5893 21.4681 23.3499 21.8264 23.0481 22.1282C22.7463 22.43 22.388 22.6694 21.9937 22.8327C21.5994 22.996 21.1768 23.0801 20.75 23.0801H3.25C2.38805 23.0801 1.5614 22.7377 0.951903 22.1282C0.34241 21.5187 0 20.692 0 19.8301C0 18.9681 0.34241 18.1415 0.951903 17.532Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M3.5 19.8301C3.5 19.4159 3.83579 19.0801 4.25 19.0801H4.75C5.16421 19.0801 5.5 19.4159 5.5 19.8301C5.5 20.2443 5.16421 20.5801 4.75 20.5801H4.25C3.83579 20.5801 3.5 20.2443 3.5 19.8301Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M8.5 19.8301C8.5 19.4159 8.83579 19.0801 9.25 19.0801H9.75C10.1642 19.0801 10.5 19.4159 10.5 19.8301C10.5 20.2443 10.1642 20.5801 9.75 20.5801H9.25C8.83579 20.5801 8.5 20.2443 8.5 19.8301Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M13.5 19.8301C13.5 19.4159 13.8358 19.0801 14.25 19.0801H14.75C15.1642 19.0801 15.5 19.4159 15.5 19.8301C15.5 20.2443 15.1642 20.5801 14.75 20.5801H14.25C13.8358 20.5801 13.5 20.2443 13.5 19.8301Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M18.5 19.8301C18.5 19.4159 18.8358 19.0801 19.25 19.0801H19.75C20.1642 19.0801 20.5 19.4159 20.5 19.8301C20.5 20.2443 20.1642 20.5801 19.75 20.5801H19.25C18.8358 20.5801 18.5 20.2443 18.5 19.8301Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12 11.9189C11.9337 11.9189 11.8701 11.9453 11.8232 11.9922C11.7763 12.0391 11.75 12.1026 11.75 12.1689V16.5799H18.25V12.1689C18.25 12.1026 18.2237 12.0391 18.1768 11.9922C18.1299 11.9453 18.0663 11.9189 18 11.9189H12ZM10.7626 10.9315C11.0908 10.6033 11.5359 10.4189 12 10.4189H18C18.4641 10.4189 18.9092 10.6033 19.2374 10.9315C19.5656 11.2597 19.75 11.7048 19.75 12.1689V17.3299C19.75 17.7442 19.4142 18.0799 19 18.0799H11C10.5858 18.0799 10.25 17.7442 10.25 17.3299V12.1689C10.25 11.7048 10.4344 11.2597 10.7626 10.9315Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M15 10.4189C15.4142 10.4189 15.75 10.7547 15.75 11.1689V13.8689C15.75 14.2832 15.4142 14.6189 15 14.6189C14.5858 14.6189 14.25 14.2832 14.25 13.8689V11.1689C14.25 10.7547 14.5858 10.4189 15 10.4189Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M4.272 6.83668C4.34849 6.49383 4.65271 6.25 5.004 6.25H7.004C7.35576 6.25 7.66026 6.49447 7.73628 6.83792L8.73228 11.3379C8.74406 11.3911 8.75 11.4455 8.75 11.5V17C8.75 17.4142 8.41421 17.75 8 17.75H4C3.58579 17.75 3.25 17.4142 3.25 17V11.5C3.25 11.4451 3.25604 11.3903 3.268 11.3367L4.272 6.83668ZM5.60511 7.75L4.75 11.5827V16.25H7.25V11.582L6.40185 7.75H5.60511Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M6 3.25C6.41421 3.25 6.75 3.58579 6.75 4V5C6.75 5.41421 6.41421 5.75 6 5.75C5.58579 5.75 5.25 5.41421 5.25 5V4C5.25 3.58579 5.58579 3.25 6 3.25Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M6 0.526855C6.41421 0.526855 6.75 0.862642 6.75 1.27686V1.77686C6.75 2.19107 6.41421 2.52686 6 2.52686C5.58579 2.52686 5.25 2.19107 5.25 1.77686V1.27686C5.25 0.862642 5.58579 0.526855 6 0.526855Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
    </svg>
  );
}
