import styled from 'styled-components';

export const Frame = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.875rem;
  align-items: center;
  line-height: 1.43;
  width: 100%;
  height: fit-content;
`;

export const Card = styled.div`
  gap: var(--sizes-20);
  width: 488px;
  padding: 32px;
`;

export const Header = styled.div`
  margin-bottom: 8px;
  font: var(--font-headings-heading-3);
`;

export const Content = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--sizes-20);
  padding-bottom: var(--sizes-60);
  width: 100%;
`;
