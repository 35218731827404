import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ControlBar, ControlGroup, ControlSearchBox, RefreshButton } from 'src/app/components/lib/control-bar';
import { DataGrid, type GridColDef } from 'src/app/components/lib/table';
import { Type } from 'src/app/components/lib/type';
import { CustomerSegmentAudience } from 'src/app/constants/table-names';
import { useCheckSegmentStatusQuery } from 'src/app/queries/segment/check-segment-status';
import { useGetSegmentAudienceQuery } from 'src/app/queries/segment/get-segment-audience';
import { segmentsQueryKey } from 'src/app/queries/segment/get-segments';

import { SegmentInProgress } from './segment-update-in-progress';

export function SegmentAudience() {
  const { segmentId = '' } = useParams<{ segmentId: string }>();
  const queryClient = useQueryClient();
  const {
    data: customerList = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetSegmentAudienceQuery({ segmentId: Number(segmentId) });
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Customer',
      renderCell: ({ value }) => <Type px={14}>{value}</Type>,
    },
    { field: 'Email', headerName: 'Email' },
    { field: 'Phone', headerName: 'Phone number' },
    {
      field: 'DateAddedUtc',
      headerName: 'Date added',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  const filteredAudience = useMemo(() => {
    if (!searchTerm) {
      return customerList;
    }

    return customerList.filter((customer) => {
      let currentCustomer;
      ['Name', 'Email', 'Phone'].forEach((field) => {
        if (customer[field]?.toLowerCase().includes(searchTerm.toLowerCase())) {
          currentCustomer = customer;
        }
        return currentCustomer;
      });
      return currentCustomer;
    });
  }, [searchTerm, customerList]);

  // when a segment update finishes, refetch audience data
  const { data: isSegmentUpdateInProgress, isLoading: checkForSegmentUpdatingIsLoading } = useCheckSegmentStatusQuery({
    segmentId: Number(segmentId),
  });
  const segmentUpdateInProgress = !checkForSegmentUpdatingIsLoading && isSegmentUpdateInProgress;
  // ref to track previous segmentUpdateInProgress state
  const prevSegmentUpdateInProgressRef = useRef(segmentUpdateInProgress);

  useEffect(() => {
    const prevSegmentUpdateInProgress = prevSegmentUpdateInProgressRef.current;
    // check if segmentUpdateInProgress transitioned from true to false
    if (prevSegmentUpdateInProgress && !segmentUpdateInProgress && segmentId) {
      void queryClient.invalidateQueries({ queryKey: segmentsQueryKey });
      void refetch();
    }
    // Update the ref value for the next render
    prevSegmentUpdateInProgressRef.current = segmentUpdateInProgress;
  }, [segmentUpdateInProgress, refetch, segmentId, queryClient]);

  return (
    <>
      {segmentUpdateInProgress && <SegmentInProgress />}
      <ControlBar>
        <ControlGroup grow>
          <ControlSearchBox
            placeholder='Search by customer name, email or phone...'
            onChange={(value) => setSearchTerm(value)}
          />
          <RefreshButton isFetching={isFetching} onClick={refetch} />
        </ControlGroup>
      </ControlBar>
      <DataGrid
        columns={columns}
        getRowId={(row) => row.CustomerId}
        height='100%'
        loading={isLoading}
        name={CustomerSegmentAudience}
        rows={filteredAudience}
      />
    </>
  );
}
