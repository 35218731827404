import React from 'react';

import styled from 'styled-components';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { Button, LinkButton } from 'src/app/components/lib/button';
import { Heading } from 'src/app/components/lib/heading';
import { useNotificationActions } from 'src/app/state/notifications';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { MigrationIcon } from './migration-icon';

type FullPageMigrationTakeoverProps = {
  backofficeURL: string;
  setEscapeHatch: () => void;
  useUpdatedMessaging: boolean;
};
export function FullPageMigrationTakeover({
  backofficeURL,
  setEscapeHatch,
  useUpdatedMessaging,
}: FullPageMigrationTakeoverProps) {
  const notifications = useNotificationActions();
  const isSuperUser = usePermissionCheck(Permissions.SuperUser);

  const handleCopyURLToClipboard = async () => {
    await navigator.clipboard.writeText(backofficeURL);
    notifications.success({ message: 'URL copied to clipboard' });
  };
  return (
    <>
      <Container>
        <Box>
          <MigrationIcon />
          <TextSection>
            <StyledHeading>This URL has expired</StyledHeading>
            <Text>
              This URL has been disabled. Please click below to access and log into your new URL.{' '}
              {!useUpdatedMessaging && 'Visit the help center to learn more.'}
            </Text>
          </TextSection>
          <CopyableURLContainer>
            <DisplayURL>{backofficeURL}</DisplayURL>
            <Button buttonSize='small' label='Copy' variant='outlineBlue' onClick={handleCopyURLToClipboard} />
          </CopyableURLContainer>
          <LinksSection>
            <Button label='Go to new URL' onClick={() => (window.location.href = backofficeURL)} />
            {!useUpdatedMessaging && (
              <LinkButton
                label='Visit the help center'
                onClick={() => window.open('https://support.dutchie.com/hc/en-us/articles/28929066112147')}
              />
            )}
          </LinksSection>
        </Box>
      </Container>
      {isSuperUser && <EscapeButton label='Dismiss' variant='danger' onClick={setEscapeHatch} />}
    </>
  );
}

const EscapeButton = styled(Button)`
  position: fixed;
  top: 10px;
  left: 10px;
`;

const Container = styled.div`
  background-color: #f5f6f7;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  width: 522px;
  height: 434px;
  padding: 32px;
  background-color: #fff;
  border: 1px #d2d7da solid;
  border-radius: 20px;
  margin-top: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #121516;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: var(--sizes-30);
  font-weight: 600;
`;

const Text = styled.p`
  font: var(--font-regular-14pt-normal);
  color: #485055;
`;

const CopyableURLContainer = styled.div`
  background-color: #edf1fa;
  border-radius: 8px;
  height: 48px;
  width: 458px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DisplayURL = styled.span`
  font-weight: 600;
  font-size: 13px;
`;

const LinksSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
