import type { BufferConfiguration } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import type { PeripheralServiceConfig } from "../../shared/index";
import { PeripheralService, PeripheralType } from "../../shared/index";

import { KeyboardScale } from "./KeyboardScale";
import { isKeyboardScale } from "./utils";

export type KeyboardScaleServiceConfiguration = {
  buffer?: Partial<BufferConfiguration>;
  service?: Partial<PeripheralServiceConfig>;
}

export class KeyboardScaleService extends PeripheralService<KeyboardScale> {
  private bufferConfig?: Partial<BufferConfiguration>;
  constructor(config?: Partial<KeyboardScaleServiceConfiguration>) {
    super(PeripheralType.scale, { name: 'KeyboardScaleService', autoConnect: true, autoEnable: true }, config?.service);
    this.bufferConfig = config?.buffer;
  }

  get isSupported(): boolean { return true; }
  async availableDevices(): Promise<KeyboardScale[]> {
    const isAlreadyConnected = HardwareService.scale.devices.find(it => isKeyboardScale(it))?.isConnected === true;
    return [ new KeyboardScale(isAlreadyConnected, this.bufferConfig) ];
  }
}
