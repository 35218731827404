import { HardwareService } from '../HardwareService';
import type { PeripheralFilter, PeripheralSearchConfig } from '../shared/types';
import { usePeripherals } from '../shared/usePeripherals';

import type { ReceiptPrinter } from './ReceiptPrinter';

export type ReceiptPrintersHook = {
  receiptPrinters: ReceiptPrinter[];
  refresh: () => void;
  search: (props?: Partial<PeripheralSearchConfig>) => void;
}

export const useReceiptPrinters = (props?: { filter?: PeripheralFilter<ReceiptPrinter> }): ReceiptPrintersHook => {
  const result = usePeripherals<ReceiptPrinter>({
    service: HardwareService.receiptPrinter,
    filter: props?.filter
  });

  return {
    receiptPrinters: result.devices,
    refresh: result.refresh,
    search: result.search
  }
}
