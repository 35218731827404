import React from 'react';

import { Stack } from '@mui/material';
import {
  GridFooterContainer,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  gridVisibleRowCountSelector,
} from '@mui/x-data-grid-pro';
import styled from 'styled-components';

import { CaretDown } from 'src/app/components/icons';
import { MenuItem } from 'src/app/components/lib/menu-item';

import {
  FooterSelect,
  PaginationControlContainer,
  PaginationRange,
  StatusContainer,
  PageUnits,
} from './footer-rebrand.styles';
import { RebrandPagination } from './rebrand-pagination';
import { getPageStartEndRows } from './utils';

export function Footer({ rowsPerPageOptions, paginationMode, ...props }): JSX.Element {
  const apiRef = useGridApiContext();
  const page: number = useGridSelector(apiRef, gridPageSelector);
  const pageCount: number = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize: number = useGridSelector(apiRef, gridPageSizeSelector);
  const totalRowCount: number = useGridSelector(apiRef, gridRowCountSelector);
  const totalVisibleCount: number = useGridSelector(apiRef, gridVisibleRowCountSelector);

  function handlePaginationChange(event: React.ChangeEvent<unknown>, value: number) {
    apiRef.current.setPage(value - 1);
  }

  function handlePageSizeChange(event) {
    apiRef.current.setPageSize(event.target.value);
  }

  const { currentPage, pageStart, pageEnd } = getPageStartEndRows(page, totalRowCount, pageSize);
  const displayedPageStart = totalRowCount > 0 ? pageStart : '0';
  const displayedPageEnd = totalVisibleCount < pageEnd ? totalVisibleCount : pageEnd;
  const pageStartEnd = `${displayedPageStart} - ${paginationMode === 'server' ? pageEnd : displayedPageEnd}`;

  return (
    <StyledGridFooterContainer {...props}>
      <Stack spacing={2}>
        <RebrandPagination count={pageCount} page={currentPage} onChange={handlePaginationChange} />
      </Stack>
      <PaginationControlContainer className='data-table-footer-control-container'>
        <StatusContainer>
          Displaying <PaginationRange>{pageStartEnd}</PaginationRange> of{' '}
          <span data-testid='data-grid-total-count'>{totalRowCount}</span>
        </StatusContainer>
        <FooterSelect
          data-testid='page-size-select'
          IconComponent={CaretDown}
          value={String(pageSize)}
          onChange={handlePageSizeChange}
        >
          {rowsPerPageOptions?.map((size) => (
            <MenuItem key={String(size)} value={size}>
              {size}
            </MenuItem>
          ))}
        </FooterSelect>
        <PageUnits>Per page</PageUnits>
      </PaginationControlContainer>
    </StyledGridFooterContainer>
  );
}

const StyledGridFooterContainer = styled(GridFooterContainer)`
  min-height: unset;
  padding: 8px 0;
  border-top: 1px solid rgba(224, 224, 224, 1);
`;
