import moment from 'moment';
import { atom, useSetRecoilState } from 'recoil';
import uuid from 'uuid/v1';

import NotificationStore from 'src/app_deprecated/stores/NotificationStore';

import { NotificationTypes } from 'src/app/components/lib/notification/types';

import type { Notification, LegacyNotification, AddNotificationArgs } from 'src/app/components/lib/notification/types';

type NotificationState = (LegacyNotification | Notification)[];

export const notificationsAtom = atom({
  key: 'notifications',
  default: [] as NotificationState,
  effects_UNSTABLE: [useSyncStateEffect],
});

export type NotificationActions = {
  alert: (args: AddNotificationArgs) => void;
  apiError: (args: AddNotificationArgs) => void;
  error: (args: AddNotificationArgs) => void;
  success: (args: AddNotificationArgs) => void;
};

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListener() {
    const { notifications: notificationsFromStore } = NotificationStore.getState();

    setSelf((prevState) => {
      // since we're resyncing all notifications from the store itself
      // we need to filter out the legacy store notifications from previous state
      // to avoid duplicate notifications being rendered/stored

      const cleanedPrevState = prevState.filter(({ isLegacy }) => !isLegacy);
      const visibleNotificationFromStore = notificationsFromStore.filter(({ show }) => show);

      return [...cleanedPrevState, ...visibleNotificationFromStore];
    });
  }

  if (trigger === 'get') {
    changeListener(); // initial call
  }

  NotificationStore.addChangeListener(changeListener);

  return () => NotificationStore.removeChangeListener(changeListener);
}

export function useNotificationActions(): NotificationActions {
  const setNotificationsState = useSetRecoilState(notificationsAtom);

  function success({ message, subContent }: AddNotificationArgs) {
    const notificationToAdd: Notification = {
      message,
      subContent,
      variant: NotificationTypes.Success,
      uuid: uuid(),
      timestamp: moment(),
      sticky: false,
    };

    setNotificationsState((prevState) => [...prevState, notificationToAdd]);
  }

  /**
   * @param sticky We condensed the use of warn and alert notifications into a single alert action.
   * This means that when you are replacing the old warn action, you will instead use alert and pass it the sticky prop.
   */
  function alert({ message, subContent, sticky = false }: AddNotificationArgs) {
    const notificationToAdd: Notification = {
      message,
      subContent,
      variant: NotificationTypes.Alert,
      uuid: uuid(),
      timestamp: moment(),
      sticky,
    };

    setNotificationsState((prevState) => [...prevState, notificationToAdd]);
  }

  function error({ message, subContent, sticky = false }: AddNotificationArgs) {
    const notificationToAdd: Notification = {
      message,
      subContent,
      variant: NotificationTypes.Error,
      uuid: uuid(),
      timestamp: moment(),
      sticky,
    };

    setNotificationsState((prevState) => [...prevState, notificationToAdd]);
  }

  function apiError({ message, subContent, additionalInfo }: AddNotificationArgs) {
    const notificationToAdd: Notification = {
      message: `Connection error. ${message || ''}`,
      subContent,
      additionalInfo,
      variant: NotificationTypes.Error,
      uuid: uuid(),
      timestamp: moment(),
      sticky: false,
    };

    setNotificationsState((prevState) => [...prevState, notificationToAdd]);
  }

  return { success, alert, error, apiError };
}
