import { EventEmitter } from 'events';

import { actions as NotificationActions, events as NotificationEvents } from '../constants/NotificationConstants';
import AppDispatcher from '../utils/AppDispatcher';

const _state = {
  notifications: [],
};

class NotificationStoreClass extends EventEmitter {
  dispatchToken;

  getState() {
    return _state;
  }

  emitChange(callback?) {
    this.emit(NotificationEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(NotificationEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(NotificationEvents.CHANGE_EVENT, callback);
  }
}

const NotificationStore = new NotificationStoreClass();

NotificationStore.dispatchToken = AppDispatcher.register((action) => {
  const notificationData = [..._state.notifications];

  const getUpdatedNotificationData = () =>
    notificationData.map((notification) => {
      if (notification.uuid === action?.data) {
        return { ...notification, show: false };
      }
      return notification;
    });

  switch (action.actionType) {
    case NotificationActions.ADD_NOTIFICATION:
      notificationData.push(action.data);
      _state.notifications = notificationData;
      NotificationStore.emitChange();
      break;

    case NotificationActions.REMOVE_NOTIFICATION:
      _state.notifications = getUpdatedNotificationData();
      NotificationStore.emitChange();
      break;
  }
});

export default NotificationStore;
