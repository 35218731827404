/* eslint-disable prefer-destructuring */
// Parcel uses the NODE_ENV variable to differentiate production and development builds.
// It's important to note that this value is NOT indicative of the deployment environment.
// All deployments will use the 'production' build, and environment is indicated by the APP_ENV.

// env vars
export const APP_ENV = process.env.APP_ENV;
export const VERSION = process.env.VERSION;
// app variants
export const IS_DEVELOPMENT_APP = APP_ENV === 'development';
export const IS_PRODUCTION_APP = APP_ENV === 'production';
