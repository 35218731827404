type ILicenseTypeIds = {
  isCultivator: boolean;
  isDelivery: boolean;
  isDistribution: boolean;
  isEnhancedDelivery: boolean;
  isManufacturer: boolean;
  isRetail: boolean;
};

export default function useSmbPackage({ LicenseTypeIds }): ILicenseTypeIds {
  let smbPackages = {
    // By default every package is a Retail package
    isRetail: true,
    isDelivery: false,
    isCultivator: false,
    isDistribution: false,
    isManufacturer: false,
    isEnhancedDelivery: false,
  };

  const result = LicenseTypeIds?.map(({ LicenseTypeId }) => LicenseTypeId);

  if (result?.length) {
    if (result.includes(6)) {
      smbPackages = { ...smbPackages, isEnhancedDelivery: true };
    }
    if (result.includes(5)) {
      smbPackages = { ...smbPackages, isDelivery: true };
    }
    if (result.includes(1)) {
      smbPackages = { ...smbPackages, isCultivator: true };
    }
    if (result.includes(2)) {
      smbPackages = { ...smbPackages, isDistribution: true };
    }
    if (result.includes(3)) {
      smbPackages = { ...smbPackages, isManufacturer: true };
    }
  }

  return smbPackages;
}
