import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

export const GET_BUILD_NUMBER = 'api/admin/get-build-number';

const buildNumberQueryKey: QueryKey = ['build'];

type GetBuildNumberResponse = {
  BuildNumber: string;
  ForceRefresh: boolean;
  VersionNumber: string;
};

export function useBuildNumber() {
  const flags = useFlags();
  const interval = flags['pos.backoffice.new-version-available-notification-interval.entitlement'];

  return useQuery({
    queryKey: buildNumberQueryKey,
    queryFn: () => getData<GetBuildNumberResponse>(GET_BUILD_NUMBER),
    refetchInterval: interval,
  });
}
