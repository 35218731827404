import { createContext, useContext } from 'react';

import type { LDFlagSet } from 'launchdarkly-js-client-sdk';

type IdentifiedFlagsContextObject = {
  identifiedFlags: LDFlagSet | null;
};

export const IdentifiedFlagsContext = createContext<IdentifiedFlagsContextObject>({ identifiedFlags: null });

export function useIdentifiedFlags() {
  const { identifiedFlags } = useContext(IdentifiedFlagsContext);
  return { identifiedFlags };
}
