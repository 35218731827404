type SessionCookie = {
  Administrator: boolean;
  Cultivation: boolean;
  Dispensary: boolean;
  ExportReports: boolean;
  FullName: string;
  Id: number;
  Inspector: boolean;
  Inventory: boolean;
  InventoryReadOnly: boolean;
  LocId: number;
  LoginType: string;
  LspId: number;
  ManagerFlag: boolean;
  OnlineStatus: string;
  PatientAcct: number;
  SessionGId: string;
  SessionId: string;
  TraceId: unknown;
  UserName: string;
};

type TokenSessionInfo = {
  LocId: number;
  LspId: number;
  RegisterId: number;
  SessionId: string;
};

type EmployeeLoginDetailsResponse = {
  AcctType: string;
  Administrator: boolean;
  Cultivation: boolean;
  Dispensary: boolean;
  ExportReports: boolean;
  ForcePasswordChange: boolean;
  FullName: string;
  Inspector: boolean;
  Inventory: boolean;
  InventoryReadOnly: boolean;
  IsAssignedLocationActive: boolean;
  locations: any[];
  // TODO-BACKOFFICE: type this properly
  LocId: number;
  LspId: number;
  ManagerFlag: boolean;
  Region: string;
  UserId: number;
  userId: number;
  UserIsInactive: boolean;
  UserName: string;
};

// create a full SessionCookie using a combination of fields from token endpoint and employeeLoginInfo endpoint
// NOTE: LoginType, OnlineStatus, PatientAcct, and TraceId are the only 4 cookie fields that we can't get from tokenSessionInfo or employeeLoginInfo.
// We've determined via investigation, discussions with backend devs, and click testing that these fields are no longer in use and can be safely set to null here.
export const makeSessionCookie = (
  tokenSessionInfo: TokenSessionInfo,
  employeeLoginInfo: EmployeeLoginDetailsResponse
): SessionCookie => {
  return {
    Administrator: employeeLoginInfo.Administrator,
    Cultivation: employeeLoginInfo.Cultivation,
    Dispensary: employeeLoginInfo.Dispensary,
    ExportReports: employeeLoginInfo.ExportReports,
    FullName: employeeLoginInfo.FullName,
    Id: employeeLoginInfo.UserId,
    Inspector: employeeLoginInfo.Inspector,
    Inventory: employeeLoginInfo.Inventory,
    InventoryReadOnly: employeeLoginInfo.InventoryReadOnly,
    LocId: employeeLoginInfo.LocId,
    LoginType: null, // field not available from either source
    LspId: employeeLoginInfo.LspId,
    ManagerFlag: employeeLoginInfo.ManagerFlag,
    OnlineStatus: null, // field not available from either source
    PatientAcct: null, // field not available from either source
    SessionGId: tokenSessionInfo.SessionId,
    SessionId: tokenSessionInfo.SessionId,
    TraceId: null, // field not available from either source,
    UserName: employeeLoginInfo.UserName,
  };
};
