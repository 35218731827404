import type { ChangeEvent } from 'react';
import React from 'react';

import { MenuItem } from 'src/app/components/lib/menu-item';

import { StyledSelectAdornment, StyledAdornmentWrapper } from './select-adornment.styles';

import type { SelectProps } from '../../select';
import type { SxProps } from '@mui/material';

export type Option = {
  key?: string;
  label: string;
  value: string;
};

type SelectAdornmentProps = SelectProps & {
  className?: string;
  disableNoneOption?: boolean;
  label?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: Option[];
  position?: 'end' | 'start';
  sx?: SxProps;
  useDefaultSelectRenderValue?: boolean;
  value: string;
};

export const SelectAdornment = React.forwardRef<HTMLDivElement, SelectAdornmentProps>((props, ref) => {
  const {
    position = 'end',
    disableNoneOption = false,
    options,
    value,
    onChange,
    label,
    sx,
    className,
    renderValue,
    useDefaultSelectRenderValue,
    ...other
  } = props;

  function defaultRenderValue(option) {
    if (option === '') {
      return label;
    }
    return option;
  }

  return (
    <StyledAdornmentWrapper position={position}>
      <StyledSelectAdornment
        {...other}
        className={className}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        ref={ref}
        renderValue={useDefaultSelectRenderValue ? undefined : renderValue ?? defaultRenderValue}
        SelectDisplayProps={{ style: { paddingRight: 'var(--sizes-70)', paddingLeft: 'var(--sizes-30)' } }}
        sx={sx}
        value={options.find((option) => option.value === value)?.value ?? ''}
        onChange={onChange}
      >
        {!disableNoneOption && (
          <MenuItem key='no-selection' value=''>
            None
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.key ?? option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelectAdornment>
    </StyledAdornmentWrapper>
  );
});
