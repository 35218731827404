import { useQuery } from '@tanstack/react-query';

import { endPoints as customerEndpoints } from 'src/app_deprecated/constants/CustomerConstants';

import { fetchData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

export const customerTypeQueryKey: QueryKey = ['getAllCustomerTypes'];

type CustomerTypeProps = {
  CustomerType: string;
  CustomerTypeId: number;
  Visible: boolean;
};

export function useGetAllCustomerTypes() {
  return useQuery({
    queryKey: customerTypeQueryKey,
    queryFn: () => fetchData<CustomerTypeProps[]>(customerEndpoints.GET_ALL_CUSTOMER_TYPES),
  });
}
