import { EventEmitter } from 'events';
import _ from 'lodash';
import AppDispatcher from '../utils/AppDispatcher';
import { events as PosEvents, endPoints as PosEndPoints } from '../constants/PosConstants';
import { actions as AppEvents } from '../constants/AppConstants';
import UserStore from './UserStore';
import AjaxPromises from '../utils/AjaxPromises';

const _state = {
  chargeCodes: [],
  lspChargeCodes: [],
};

const _status = {
  chargeCodes: false,
  lspChargeCodes: false,
};

class PricingStoreClass extends EventEmitter {
  constructor() {
    super();
    this.refreshChargeCodes = this.refreshChargeCodes.bind(this);
    this.clear = this.clear.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
  }

  get chargeCodes() {
    if (!_status.chargeCodes) {
      _status.chargeCodes = true; // prevent multiple calls
      this.refreshChargeCodes();
    }
    return _state.chargeCodes;
  }

  get lspChargeCodes() {
    if (!_status.lspChargeCodes) {
      _status.lspChargeCodes = true;
      this.refreshLspChargeCodes();
    }

    return _state.lspChargeCodes;
  }

  public async refreshChargeCodes() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(PosEndPoints.GET_LIST_OF_PRICING, data);
      if (resp.Result) {
        _status.chargeCodes = true;
        _state.chargeCodes = resp.Data;
      }
      this.emitChange(_state);
    } catch {
      console.warn('Charge Codes failed to load');
      _status.chargeCodes = false;
    }
  }

  public refreshLspChargeCodes = async () => {
    try {
      const resp = await AjaxPromises.POST(PosEndPoints.GET_PRODUCT_PRICING_OPTIONS, UserStore.getApiData());
      if (resp.Result) {
        _status.lspChargeCodes = true;
        _state.lspChargeCodes = resp.Data;
      }
      this.emitChange(_state);
    } catch {
      console.warn('Charge Codes failed to load');
      _status.lspChargeCodes = false;
    }
  };

  emitChange(callback) {
    this.emit(PosEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(PosEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(PosEvents.CHANGE_EVENT, callback);
  }
  private clear() {
    _state.chargeCodes = [];
    _status.chargeCodes = false;
    _state.lspChargeCodes = [];
    _status.lspChargeCodes = false;
  }

  actionHandler(action) {
    switch (action.actionType) {
      case AppEvents.APP_BOOTED:
        this.refreshChargeCodes();
        break;
      case AppEvents.LOCATION_LOADED:
        this.clear();
        this.emitChange(_state);
    }
  }
}

const PricingStore = new PricingStoreClass();
AppDispatcher.register(PricingStore.actionHandler);
export default PricingStore;
