import React from 'react';

export function CustomersIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M6.76735 1.35425C7.33931 1.12038 7.95234 1 8.57143 1C9.19052 1 9.80354 1.12038 10.3755 1.35425C10.9475 1.58813 11.4672 1.93093 11.9049 2.36308C12.3427 2.79523 12.6899 3.30827 12.9269 3.8729C13.1638 4.43753 13.2857 5.04269 13.2857 5.65385C13.2857 6.265 13.1638 6.87017 12.9269 7.4348C12.6899 7.99943 12.3427 8.51246 11.9049 8.94461C11.4672 9.37676 10.9475 9.71956 10.3755 9.95344C9.80354 10.1873 9.19052 10.3077 8.57143 10.3077C7.95234 10.3077 7.33931 10.1873 6.76735 9.95344C6.19539 9.71956 5.67569 9.37676 5.23793 8.94461C4.80016 8.51246 4.45291 7.99943 4.216 7.4348C3.97908 6.87017 3.85714 6.265 3.85714 5.65385C3.85714 5.04269 3.97908 4.43753 4.216 3.8729C4.45291 3.30827 4.80016 2.79523 5.23793 2.36308C5.67569 1.93093 6.19539 1.58813 6.76735 1.35425ZM8.57143 2.69231C8.17746 2.69231 7.78736 2.76891 7.42338 2.91774C7.0594 3.06657 6.72868 3.28472 6.45011 3.55972C6.17153 3.83473 5.95056 4.1612 5.79979 4.52051C5.64903 4.87982 5.57143 5.26493 5.57143 5.65385C5.57143 6.04276 5.64903 6.42787 5.79979 6.78718C5.95056 7.14649 6.17153 7.47297 6.45011 7.74797C6.72869 8.02297 7.0594 8.24112 7.42338 8.38995C7.78736 8.53878 8.17746 8.61538 8.57143 8.61538C8.9654 8.61538 9.3555 8.53878 9.71948 8.38995C10.0835 8.24112 10.4142 8.02297 10.6928 7.74797C10.9713 7.47296 11.1923 7.14649 11.3431 6.78718C11.4938 6.42787 11.5714 6.04276 11.5714 5.65385C11.5714 5.26493 11.4938 4.87982 11.3431 4.52051C11.1923 4.1612 10.9713 3.83473 10.6928 3.55972C10.4142 3.28472 10.0835 3.06657 9.71948 2.91774C9.3555 2.76891 8.9654 2.69231 8.57143 2.69231Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M8.57143 12.8462C6.7528 12.8462 5.00866 13.5593 3.7227 14.8288C2.43673 16.0983 1.71429 17.8201 1.71429 19.6154V21.3077H15.4286V19.6154C15.4286 17.8201 14.7061 16.0983 13.4202 14.8288C12.1342 13.5593 10.3901 12.8462 8.57143 12.8462ZM2.51051 13.6322C4.11797 12.0453 6.29814 11.1538 8.57143 11.1538C10.8447 11.1538 13.0249 12.0453 14.6323 13.6322C16.2398 15.219 17.1429 17.3712 17.1429 19.6154V22.1538C17.1429 22.6212 16.7591 23 16.2857 23H0.857143C0.383756 23 0 22.6212 0 22.1538V19.6154C0 17.3712 0.903059 15.219 2.51051 13.6322Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M15.4286 2.69231C15.1942 2.69232 14.9606 2.71893 14.7323 2.77162C14.2714 2.87803 13.8103 2.59541 13.7025 2.14037C13.5947 1.68532 13.881 1.23018 14.342 1.12377C14.6982 1.04154 15.0627 1.00001 15.4285 1C16.6789 1 17.878 1.49031 18.7621 2.36308C19.6462 3.23585 20.1429 4.41957 20.1429 5.65385C20.1429 6.88812 19.6462 8.07185 18.7621 8.94461C17.878 9.81738 16.6789 10.3077 15.4286 10.3077C15.0916 10.3076 14.7553 10.2723 14.4259 10.2026C13.9631 10.1045 13.6683 9.65464 13.7676 9.19772C13.867 8.74081 14.3227 8.44987 14.7855 8.5479C14.9969 8.59267 15.2124 8.61529 15.4286 8.61538M15.4286 2.69231C16.2242 2.69232 16.9873 3.00433 17.5499 3.55972C18.1125 4.11512 18.4286 4.8684 18.4286 5.65385C18.4286 6.43929 18.1125 7.19257 17.5499 7.74797C16.9874 8.3033 16.2241 8.6153 15.4286 8.61538'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M16.265 11.9959C16.3676 11.5397 16.8255 11.252 17.2876 11.3534C19.1918 11.771 20.8948 12.8174 22.1152 14.3196C23.3356 15.8217 24.0004 17.6898 24 19.6154C24 19.6155 24 19.6153 24 19.6154V22.1538C24 22.6212 23.6162 23 23.1429 23H19.7143C19.2409 23 18.8571 22.6212 18.8571 22.1538C18.8571 21.6865 19.2409 21.3077 19.7143 21.3077H22.2857V19.6154C22.2861 18.0749 21.7542 16.5801 20.7779 15.3783C19.8015 14.1766 18.4392 13.3395 16.9158 13.0054C16.4537 12.904 16.1623 12.4521 16.265 11.9959Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
    </svg>
  );
}
