class Storage {
  private keyPrefix = 'dutchie.hardware';

  getBoolean(key: string): boolean | null {
    const item = this.getItem(key);
    if(item === null) return null;
    return item === 'true';
  }

  setBoolean(key: string, value: boolean): void {
    return this.setItem(key, value ? 'true' : 'false');
  }

  getItem(key: string): string | null {
    return localStorage.getItem(`${this.keyPrefix}.${key}`);
  }

  setItem(key: string, value: string): void {
    return localStorage.setItem(`${this.keyPrefix}.${key}`, value);
  }

  getObject<T>(key: string): T | null {
    const item = this.getItem(key);
    if(item === null) return null;
    return JSON.parse(item) as T;
  }

  setObject<T>(key: string, value: T): void {
    return this.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    return localStorage.removeItem(`${this.keyPrefix}.${key}`);
  }
}

export const storage = new Storage();
