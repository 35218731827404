import React from 'react';

export function MigrationIconWarning(): JSX.Element {
  return (
    <svg fill='none' height='129' viewBox='0 0 136 129' width='136' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='63.4945' cy='62.8356' fill='#F5F6F8' r='62.8356' />
      <path
        d='M8.51001 23.6053C8.51001 19.1871 12.0917 15.6053 16.51 15.6053H110.673C115.092 15.6053 118.673 19.1871 118.673 23.6053V38.6053H8.51001V23.6053Z'
        fill='#F3F4F5'
      />
      <path
        d='M8.51001 39.6053H118.673V101.605C118.673 106.024 115.092 109.605 110.673 109.605H16.51C12.0917 109.605 8.51001 106.024 8.51001 101.605V39.6053Z'
        fill='white'
      />
      <path
        clipRule='evenodd'
        d='M79.3862 79.9647C79.3875 79.9628 79.3887 79.9609 79.3899 79.959C79.4049 79.9357 79.4198 79.9123 79.4346 79.8889C79.4434 79.875 79.4523 79.8611 79.461 79.8471C79.5668 79.6792 79.6694 79.5091 79.7687 79.3371C79.9422 79.0517 79.9314 78.7255 79.7579 78.4502C79.4975 78.0221 78.9227 77.859 78.4671 78.1037C78.316 78.1808 78.1638 78.2563 78.0106 78.3301C77.9941 78.338 77.9776 78.346 77.961 78.3539C77.9353 78.3662 77.9095 78.3785 77.8836 78.3908C77.8815 78.3918 77.8793 78.3928 77.8772 78.3938C76.2875 79.1466 74.5978 79.7176 72.9681 80.071C70.8747 80.5195 68.7272 80.6724 66.5904 80.5195C63.0003 80.2341 59.5186 78.6847 57.1216 76.1363C57.0621 76.0742 57.0049 76.0092 56.9476 75.9441C56.7229 75.6887 56.4976 75.4326 56.1346 75.3514C55.4079 75.1985 54.8981 75.6674 54.7246 76.2892C52.9566 82.4563 58.9655 88.3379 65.5492 87.8283C71.2148 87.5064 76.4635 84.5163 79.3862 79.9647ZM56.5379 78.4816C56.5351 80.1894 57.2755 81.8274 58.555 83.1516C60.2539 84.9098 62.7845 85.9732 65.3802 85.7723L65.4026 85.7706L65.425 85.7693C69.1665 85.5568 72.6905 84.0217 75.2946 81.6003C74.6714 81.7884 74.0482 81.9501 73.4315 82.0839L73.4261 82.085C71.1333 82.5763 68.7788 82.7442 66.4342 82.5764L66.417 82.5752L66.417 82.5751C62.7978 82.2874 59.2423 80.8703 56.5379 78.4816Z'
        fill='#E3E7E9'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M64.906 55.0029C64.897 55.0028 64.888 55.0026 64.8789 55.0025C64.8142 55.0014 64.7494 55.0007 64.6846 55.0003C64.6666 55.0002 64.6485 55.0001 64.6305 55.0001C64.4705 54.9996 64.3104 55.0013 64.1501 55.0052C63.8031 55.0154 63.4994 55.1785 63.3367 55.4537C63.0655 55.8716 63.2065 56.4323 63.6512 56.6769C63.836 56.7842 64.0195 56.8942 64.2012 57.0066C64.2051 57.009 64.2089 57.0114 64.2128 57.0137C64.2384 57.0296 64.2641 57.0456 64.2897 57.0616C64.2915 57.0628 64.2934 57.0639 64.2953 57.0651C65.7523 57.9758 67.1005 59.0435 68.2175 60.1733C69.6818 61.6513 70.8965 63.3231 71.8293 65.1375C73.3587 68.2058 73.6624 71.8041 72.5235 75.0354C72.4956 75.1141 72.4643 75.1919 72.4332 75.2694C72.3068 75.5838 72.1826 75.8928 72.2957 76.2281C72.5235 76.8906 73.196 77.0741 73.8684 76.911C80.4413 75.2597 82.8492 67.4413 79.0855 62.3343C75.9493 57.8741 70.5659 55.1052 64.906 55.0029ZM74.9488 74.294C78.7249 72.2775 79.9928 67.1106 77.3572 63.5342L77.3435 63.5157L77.3303 63.497C75.2619 60.5553 72.0534 58.4392 68.4571 57.5411C68.9109 57.931 69.3434 58.3332 69.75 58.7445C71.3622 60.372 72.7016 62.2148 73.7308 64.2167L73.7366 64.228L73.7422 64.2393C75.284 67.3326 75.7542 70.9128 74.9488 74.294Z'
        fill='#E3E7E9'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M64.9279 68.7497L64.9256 68.7494C64.1134 68.6377 63.3222 68.8261 62.6977 69.2604L62.6875 69.2676L62.6875 69.2675C62.3638 69.4898 62.1045 69.764 61.9126 70.0796L61.9075 70.088L61.9075 70.088C61.3435 71.0056 61.4109 72.1368 62.0813 72.9826L62.0855 72.9879L62.0855 72.9879C62.5467 73.5737 63.2279 73.9656 64.0142 74.0738C64.8264 74.1855 65.6175 73.997 66.242 73.5627L66.2523 73.5556L66.2523 73.5556C66.5759 73.3333 66.8352 73.0591 67.0271 72.7435L67.0323 72.7351C67.5962 71.8175 67.5289 70.6863 66.8585 69.8405L66.8521 69.8325L66.8459 69.8245C66.4009 69.2516 65.7277 68.8588 64.9279 68.7497ZM65.2237 66.7073C63.8896 66.5238 62.5447 66.8296 61.46 67.5839C60.896 67.9713 60.4296 68.4605 60.0825 69.0314C59.0738 70.6725 59.1931 72.7214 60.3971 74.2403C61.1997 75.2596 62.382 75.9324 63.7161 76.1159C65.0502 76.2993 66.3951 75.9935 67.4797 75.2392C68.0437 74.8519 68.5101 74.3626 68.8572 73.7917C69.8659 72.1506 69.7466 70.1017 68.5427 68.5829C67.7509 67.5635 66.5686 66.8907 65.2237 66.7073Z'
        fill='#E3E7E9'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M61.8331 61.4157C60.2469 60.5915 58.3702 60.3912 56.5282 60.7681C54.0267 61.28 51.8101 62.8169 50.7236 64.9809L50.7135 65.0009L50.7031 65.0206C49.0134 68.2097 48.6858 71.9034 49.6953 75.2792C49.8399 74.6273 50.0135 73.989 50.2151 73.3725M49.1875 79.3489C49.1868 79.3478 49.1862 79.3466 49.1855 79.3455C46.4115 74.6746 46.2681 68.881 48.8133 64.0774C51.6334 58.4608 60.0501 56.5036 64.855 61.0295C65.3539 61.4882 65.5166 62.1304 65.0177 62.6503C64.7624 62.9068 64.407 62.9618 64.0502 63.017C63.9675 63.0298 63.8846 63.0427 63.8029 63.058C60.2562 63.741 57.0891 65.7899 55.0174 68.5727C53.8026 70.2444 52.8698 72.0691 52.2408 73.9956C51.6876 75.6878 51.3622 77.5838 51.308 79.4594C51.308 79.9487 50.8741 80.336 50.3535 80.336C50.0064 80.336 49.7136 80.1831 49.5292 79.8977C49.4347 79.7528 49.3429 79.6066 49.2538 79.4594C49.2423 79.4404 49.2309 79.4215 49.2195 79.4025C49.2088 79.3846 49.1981 79.3668 49.1875 79.3489ZM61.8331 61.4157C58.3816 62.4432 55.3626 64.5874 53.295 67.3648L53.2879 67.3743L53.2809 67.384C51.9413 69.2275 50.9107 71.2425 50.2153 73.3719M51.3251 78.7952C51.3252 78.7953 51.3252 78.7954 51.3253 78.7955L51.3251 78.7952Z'
        fill='#E3E7E9'
        fillRule='evenodd'
      />
      <path
        d='M108.9 14.9385H17.8953C12.6048 14.9385 8.31592 19.2274 8.31592 24.518V101.153C8.31592 106.444 12.6048 110.733 17.8953 110.733H108.9C114.191 110.733 118.479 106.444 118.479 101.153V24.518C118.479 19.2274 114.191 14.9385 108.9 14.9385Z'
        stroke='#ADB4B7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.2'
      />
      <path
        d='M8.31592 38.8871H118.479'
        stroke='#ADB4B7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.2'
      />
      <path
        d='M21.08 28.1102C20.4187 28.1102 19.8826 27.5741 19.8826 26.9128C19.8826 26.2515 20.4187 25.7154 21.08 25.7154'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M21.0798 28.1102C21.7411 28.1102 22.2773 27.5741 22.2773 26.9128C22.2773 26.2515 21.7411 25.7154 21.0798 25.7154'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M32.4491 28.1102C31.7878 28.1102 31.2517 27.5741 31.2517 26.9128C31.2517 26.2515 31.7878 25.7154 32.4491 25.7154'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M32.4492 28.1102C33.1105 28.1102 33.6466 27.5741 33.6466 26.9128C33.6466 26.2515 33.1105 25.7154 32.4492 25.7154'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M43.818 28.1102C43.1567 28.1102 42.6206 27.5741 42.6206 26.9128C42.6206 26.2515 43.1567 25.7154 43.818 25.7154'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M43.8181 28.1102C44.4796 28.1102 45.0155 27.5741 45.0155 26.9128C45.0155 26.2515 44.4796 25.7154 43.8181 25.7154'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <g filter='url(#filter0_d_3_9420)'>
        <circle cx='112.495' cy='101.518' fill='white' r='22.8466' />
        <circle cx='112.495' cy='101.518' r='22.2466' stroke='#ADB4B7' strokeWidth='1.2' />
      </g>
      <path
        d='M114.758 90.5586C114.609 90.2812 114.389 90.0492 114.119 89.8875C113.849 89.7256 113.541 89.6403 113.226 89.6403C112.912 89.6403 112.603 89.7256 112.333 89.8875C112.064 90.0492 111.843 90.2812 111.695 90.5586L102.455 108.166C102.318 108.426 102.252 108.716 102.262 109.009C102.272 109.302 102.358 109.587 102.512 109.837C102.667 110.087 102.885 110.294 103.143 110.438C103.401 110.581 103.691 110.656 103.986 110.655H122.466C122.761 110.656 123.052 110.581 123.31 110.438C123.568 110.294 123.785 110.087 123.941 109.837C124.094 109.587 124.18 109.302 124.19 109.009C124.2 108.716 124.134 108.426 123.997 108.166L114.758 90.5586Z'
        fill='#FCEFE2'
        stroke='#FF9E3E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M113.226 102.113V96.631'
        stroke='#FF9E3E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M113.226 105.502C112.974 105.502 112.769 105.298 112.769 105.045C112.769 104.793 112.974 104.589 113.226 104.589'
        stroke='#FF9E3E'
        strokeWidth='1.5'
      />
      <path
        d='M113.226 105.502C113.479 105.502 113.683 105.298 113.683 105.045C113.683 104.793 113.479 104.589 113.226 104.589'
        stroke='#FF9E3E'
        strokeWidth='1.5'
      />
      <defs>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='49.6932'
          id='filter0_d_3_9420'
          width='50.6931'
          x='84.6479'
          y='78.6712'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dx='-5' dy='4' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.917639 0 0 0 0 0.92547 0 0 0 0 0.933301 0 0 0 0.7 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3_9420' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_3_9420' mode='normal' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
