import RSVP from 'rsvp';

import { userSettingsQueryKey } from 'src/app/queries/settings/get-user-settings';

import CompanyApi from '../api/CompanyApi';
import UserApi from '../api/UserApi';
import { actions as ActionTypes } from '../constants/UserConstants';
import UserStore from '../stores/UserStore';
import AppDispatcher from '../utils/AppDispatcher';

import NotificationActions from './NotificationActions';

const UserActions = {
  selectLocation: (location) => {
    if (!location.hasOwnProperty('LocId')) {
      return;
    }
    const formData = UserStore.getApiData();
    formData.LocId = location.LocId;

    if (location.hasOwnProperty('LspId')) {
      formData.LspId = location.LspId;
    }

    RSVP.all([
      UserApi.setDefaultLoc(UserStore.getState().data.SessionId, UserStore.getState().data.Id, location.LocId),
      CompanyApi.getLocationLogo(formData),
      UserApi.getLocationSettings(formData),
      UserApi.getValidatedForms(formData),
      UserApi.loadCustomerTypes(formData),
    ]).then((res) => {
      if (res[1].Result) {
        location.Logo = res[1].Data[0].Logo;
      }

      let locProfileConverted = { LocId: formData.LocId };
      res[2].Data.Features.map((feature) => {
        locProfileConverted[feature.FeatureName] = feature.IsEnabled;
      });
      locProfileConverted = Object.assign(locProfileConverted, res[2].Data.Integrations, res[2].Data.Settings);

      AppDispatcher.dispatch({
        actionType: ActionTypes.SELECT_LOCATION,
        data: location,
        logo: res[1].Data ? res[1].Data[0].Logo : '',
        locProfile: locProfileConverted,
        features: res[2].Data.Features,
        integrations: res[2].Data.Integrations,
        lspSettings: res[2].Data.Settings,
        validatedForms: res[3].Data,
        customerTypes: res[4].Data || [],
      });
    });
  },

  setTableSettings: (table, layout, skipNotification = false, queryClient = undefined) =>
    UserApi.setSingleSetting({
      Setting: table,
      SettingType: 'table',
      value: JSON.stringify(layout),
      UserId: UserStore.getState().data.Id,
    }).then(async (res) => {
      if (!res.Result) {
        if (!skipNotification) {
          NotificationActions.error('Could not save table layout');
        }
      } else {
        if (!skipNotification) {
          NotificationActions.success('New table layout saved');
        }
        if (queryClient !== undefined) {
          await queryClient.invalidateQueries({ queryKey: userSettingsQueryKey });
        }
        AppDispatcher.dispatch({
          actionType: ActionTypes.SET_TABLE_SETTINGS,
          data: { table, layout },
        });
      }
    }),
};

export default UserActions;
