import { useQuery } from '@tanstack/react-query';

import { fetchData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

export enum ReportCategory {
  EMPLOYEE = 'Employee',
  INVENTORY = 'Inventory',
  MARKETING = 'Marketing',
  OPERATIONS = 'Operations',
  SALES = 'Sales',
}

export type ReportResponse = {
  availableForSchedule: boolean;
  ExportEndpoint: string;
  hasDeliveryFilter: boolean;
  isAsync?: boolean;
  ReportCategory: ReportCategory;
  ReportDesc: string;
  ReportEndpoint: string;
  ReportId: number;
  ReportName: string;
  ReportUseCase: string;
  requireFromDate: boolean;
  requireToDate: boolean;
  showAllLocations: boolean;
  showDropdown: boolean;
  superUser: boolean;
};

type UseReportsListQueryParams = {
  isEnabled?: false;
};
export function useReportsListQuery(params?: UseReportsListQueryParams) {
  const { isEnabled } = params ?? {};
  const reportsQueryKey: QueryKey = ['reports-list'];
  return useQuery({
    queryKey: reportsQueryKey,
    queryFn: () => fetchData<ReportResponse[]>('api/posv3/reports/list-reports-v2'),
    refetchOnWindowFocus: false,
    staleTime: 1 * 1000 * 60 * 60 * 24, // this list is quite unlikely to change; setting to 24 hr for now
    enabled: isEnabled,
  });
}
