import React from 'react';

export function CultivationIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M5.04376 1.92003C4.92534 1.88196 4.79987 1.87203 4.67706 1.89099C4.55425 1.90996 4.43738 1.95732 4.33548 2.02942C4.23358 2.10151 4.14938 2.19641 4.08938 2.30678C4.02938 2.41715 3.9952 2.54004 3.98947 2.66596C3.98906 2.67485 3.98853 2.68373 3.98788 2.6926C3.8501 4.56463 3.74765 7.44349 4.05644 10.3965C4.36762 13.3723 5.08514 16.3032 6.502 18.3704C7.97568 20.5178 10.0431 21.4982 12.0737 21.8929C14.1194 22.2905 16.0822 22.0816 17.2223 21.8799L17.2295 21.8787C17.428 21.8452 17.6169 21.7676 17.7824 21.6514C17.9479 21.5351 18.086 21.3832 18.1869 21.2064L18.1891 21.2026C18.7734 20.1896 19.6517 18.3947 19.9981 16.31C20.3419 14.2407 20.1567 11.9333 18.686 9.78664C17.2678 7.71634 14.8092 6.01118 12.1673 4.67758C9.5457 3.35422 6.84867 2.4483 5.06697 1.92716C5.05921 1.92488 5.05147 1.92251 5.04376 1.92003ZM5.59112 0.122212C5.20544 0.000387608 4.79746 -0.030902 4.39802 0.0307858C3.99417 0.0931536 3.60986 0.24889 3.27478 0.485963C2.9397 0.723035 2.66282 1.0351 2.46553 1.39804C2.27058 1.75668 2.1585 2.15552 2.13769 2.56443C1.99588 4.49748 1.88803 7.49368 2.21233 10.595C2.5349 13.6797 3.29624 16.9891 4.97985 19.4452C6.80154 22.0998 9.35758 23.2808 11.7248 23.7409C14.0748 24.1976 16.2827 23.9558 17.5373 23.7342C18.0043 23.6549 18.4483 23.472 18.8377 23.1986C19.2274 22.9249 19.5528 22.5674 19.7905 22.1514C20.4339 21.0358 21.4287 19.0184 21.8267 16.6228C22.2275 14.2105 22.0279 11.3679 20.2087 8.71264C18.5244 6.25386 15.7322 4.37545 12.9933 2.99292C10.2394 1.60278 7.43137 0.660938 5.59112 0.122212Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M7.7367 6.94065C8.13114 6.60928 8.71565 6.66509 9.04223 7.06531C12.843 11.7229 16.1746 16.755 18.9871 22.0862C19.2288 22.5443 19.0587 23.1144 18.6073 23.3597C18.1558 23.6049 17.5939 23.4323 17.3522 22.9742C14.598 17.7537 11.3357 12.8262 7.61385 8.26531C7.28726 7.8651 7.34226 7.27203 7.7367 6.94065Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
    </svg>
  );
}
