type LogLevel = 'debug' | 'error' | 'info' | 'warn';
export type HardwareLogEvent = HardwareLog & {
  level: LogLevel;
  error?: Error;
}

export type HardwareLog = {
  message: string;
  details?: any;
  key?: string;
}

export class HardwareLogger {
  private events: EventTarget = new EventTarget();
  private eventName = 'log';

  constructor() {
    // Register default listener
    this.events.addEventListener('log', this.handleLogEventRef);
  }

  // Default listener will output to console
  private handleLogEventRef = this.handleLogEvent.bind(this);
  private handleLogEvent(event: Event): void {
    const evt = event as CustomEvent<HardwareLogEvent>;
    const { detail } = evt;

    switch(detail.level) {
      case 'debug':
        console.debug(detail.message, detail)
        break;
      case 'error':
        console.error(detail.error, detail)
        break;
      case 'info':
        console.info(detail.message, detail)
        break;
      case 'warn':
        console.warn(detail.message, detail)
        break;
    }
  }

  private log(level: LogLevel, log: HardwareLog | any): void {
    const detail: HardwareLogEvent = {
      level,
      ...log
    }

    this.events.dispatchEvent(new CustomEvent<HardwareLogEvent>('log', { detail }));
  }

  d(message: string, details?: any): void { this.debug({ message, details }); }
  debug(event: HardwareLog): void { this.log('debug', event); }
  e(message: string, error: any): void { this.error({ message, error }); }
  error(error: any, event?: HardwareLog): void { this.log('error', {...event, error }); }
  info(event: HardwareLog): void { this.log('info', event); }
  warn(event: HardwareLog): void { this.log('warn', event); }

  // Add a custom listener. This will replace the default console logger.
  addEventListener(callback: EventListenerOrEventListenerObject): void {
    this.events.addEventListener(this.eventName, callback);

    // remove the default listener
    this.events.removeEventListener(this.eventName, this.handleLogEventRef);
  }

  removeEventListener(callback: EventListenerOrEventListenerObject): void {
    this.events.removeEventListener(this.eventName, callback);
  }
}
