import styled from 'styled-components';

import { InputAdornment } from 'src/app/components/lib/input';
import { Select } from 'src/app/components/lib/select';

export const StyledAdornmentWrapper = styled(InputAdornment)`
  height: 100%;
  margin-right: -16px;

  .select-form-control {
    width: 100%;
    border-radius: var(--sizes-10);
  }

  .MuiSelect-outlined {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledSelectAdornment = styled(Select)`
  margin-bottom: var(--sizes-0);

  .MuiOutlinedInput-notchedOutline {
    border-width: var(--sizes-0) !important;
  }

  .MuiSelect-select {
    color: var(--color-gray-100);
    font: var(--font-extra-small-12pt-semibold);

    &.Mui-disabled {
      background-color: var(--color-gray-20);
    }
  }

  .MuiInputBase-input {
    padding-right: 32px !important;
  }

  .select-select-input {
    background-color: var(--color-gray-20);
    display: flex;
    padding: 0;
    align-items: center;
    height: var(--sizes-70);

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      outline: var(--sizes-0);
      border-width: var(--sizes-0);
    }
  }

  .MuiSelect-icon {
    right: var(--sizes-30);
    height: 5px;

    path {
      fill: var(--color-gray-100);
    }

    &.Mui-disabled {
      background-color: var(--color-gray-20);
    }
  }
`;
