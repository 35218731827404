import { HardwareService } from "../HardwareService";
import { Peripheral } from "../shared/Peripheral";
import type { ConnectionType } from "../shared/index";
import { PeripheralType } from "../shared/index";

import type { BarcodeData } from "./types";

export abstract class Scanner extends Peripheral {
  constructor(connectionType: ConnectionType) {
    super(PeripheralType.scanner, connectionType);
  }
  
  emitBarcodeEvent(barcode: BarcodeData): void {
    HardwareService.logger.d('Emit barcode', { on: this.id, barcode });

    // Emit event to service
    HardwareService.scanner.events.dispatchEvent(new CustomEvent('barcode', {
      detail: {
        device: this,
        barcode
      }
    }));
  }
}
