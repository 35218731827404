import styled, { css } from 'styled-components';

import { ErrorIconV2 } from 'src/app/components/icons/error-icon-v2';
import { GreenFilledCheckIcon } from 'src/app/components/icons/green-filled-check-icon';
import { ProgressFillIcon } from 'src/app/components/icons/progress-fill-icon';
import { StarsIcon } from 'src/app/components/icons/stars-icon';

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const unsetFontSize = () => css`
  // Badge breaks the label text styling if we don't unset font size
  font-size: 'unset';
`;

export const StyledGreenFilledCheckIcon = styled(GreenFilledCheckIcon).attrs(({ size }) => ({
  size: size ?? 'var(--sizes-30)',
}))`
  ${unsetFontSize()};
`;

export const StyledRedErrorIcon = styled(ErrorIconV2).attrs(({ size }) => ({
  size: size ?? 'var(--sizes-30)',
}))`
  ${unsetFontSize()};
`;

export const StyledBlueProgressIcon = styled(ProgressFillIcon).attrs(({ size }) => ({
  size: size ?? 'var(--sizes-30)',
}))`
  ${unsetFontSize()};
`;

export const StyledOrangeStarsIcon = styled(StarsIcon).attrs(({ size }) => ({
  size: size ?? 'var(--sizes-30)',
}))`
  ${unsetFontSize()};
`;
