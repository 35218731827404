import React, { useState } from 'react';

import { DropdownInputSingle } from 'src/app/components/lib/dropdown/input';
import { DropdownMenuSingle } from 'src/app/components/lib/dropdown/menu';

import type { PopperProps } from '@mui/material/Popper';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown/menu';
import type { DropdownBaseProps } from 'src/app/components/lib/dropdown/types';

export type DropdownSingleProps = DropdownBaseProps & {
  disableDefaultFooterContent?: boolean;
  hideDropdown?: boolean;
  infiniteScroll?: boolean;
  menuPlacement?: PopperProps['placement'];
  menuWidth?: string;
  resetButtonAutomationId?: string;
  searchAutomationId?: string;
  setValue: (selection: DropdownMenuOption) => void;
  value: number | string;
};

export function DropdownSingle({
  automationId = '',
  className,
  disableDefaultFooterContent = false,
  disabled = false,
  footerContent,
  gridColumns = 6,
  helpText,
  hideDropdown = false,
  infiniteScroll = false,
  menuPlacement,
  inputId,
  label,
  labelPlacement,
  menuWidth = '396px',
  options,
  required,
  resetButtonAutomationId,
  searchAutomationId,
  setValue,
  sortOptions = true,
  sx,
  tooltip,
  truncateLabel = true,
  value,
  width,
  onSearchInputChange,
  paginationProps,
  errorMessage = '',
}: DropdownSingleProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const actualValue = options.find((option) => String(option.id) === String(value));

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (newValue) => {
    if (newValue) {
      setValue(newValue);
    }

    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  const sortByLabel = (array) =>
    array.sort((a, b) => {
      if (!a.label || !b.label) {
        return 0;
      }
      return a.label.localeCompare(b.label);
    });

  const open = Boolean(anchorEl);
  const id = String(inputId ?? typeof label === 'string' ? label : 'dropdown-single-noLabel');

  if (sortOptions) {
    sortByLabel(options);
  }

  return (
    <>
      <DropdownInputSingle
        automationId={automationId}
        className={className}
        disabled={disabled}
        errorMessage={errorMessage}
        gridColumns={gridColumns}
        handleClick={handleInputClick}
        helpText={helpText}
        id={id}
        label={label}
        labelPlacement={labelPlacement}
        required={required}
        sx={sx}
        tooltip={tooltip}
        value={actualValue}
        width={width}
      />
      {!hideDropdown && (
        <DropdownMenuSingle
          anchorEl={anchorEl}
          disableDefaultFooterContent={disableDefaultFooterContent}
          footerContent={footerContent}
          handleClose={handleMenuClose}
          id={id}
          infiniteScroll={infiniteScroll}
          open={open}
          options={options}
          paginationProps={paginationProps}
          placement={menuPlacement}
          resetButtonAutomationId={resetButtonAutomationId}
          searchAutomationId={searchAutomationId}
          truncateLabel={truncateLabel}
          value={actualValue}
          width={menuWidth}
          onSearchInputChange={onSearchInputChange}
        />
      )}
    </>
  );
}
