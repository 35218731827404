import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';

import { SearchStatus } from '.';

export function SearchInfo({ status }: { status: SearchStatus }) {
  const flags = useFlags();
  const showReportsItem = flags['pos.backoffice.global-search-refactor.rollout'];
  const isMac = navigator.userAgent.toLowerCase().includes('macintosh');

  return (
    <Container>
      {status === SearchStatus.WAITING && (
        <>
          <TopSection>
            <SearchByHeader>Search by...</SearchByHeader>
            <List>
              <li>Inventory item names</li>
              <li>Package IDs</li>
              <li>Product names</li>
              <li>Page names in Dutchie POS</li>
              {showReportsItem && <li>Names of reports</li>}
            </List>
          </TopSection>
          <BottomSection>
            <BoldedText>Tip:</BoldedText> use <BoldedText>{isMac ? '⌘+K' : 'CTRL+K'}</BoldedText> to search from
            anywhere.
          </BottomSection>
        </>
      )}
      {status === SearchStatus.LOADING && (
        <TopSection>
          <Header>Loading...</Header>
        </TopSection>
      )}
      {status === SearchStatus.NO_RESULTS && (
        <TopSection>
          <Header>No results found</Header>
          <p>
            You can search inventory items, products,{showReportsItem ? ' reports,' : ''} and pages. Please try again!
          </p>
        </TopSection>
      )}
    </Container>
  );
}

const Container = styled.div`
  color: var(--color-gray-70);
  font-size: var(--typography-small-13pt-normal-font-size);
  height: 100%;
`;
const TopSection = styled.div`
  padding: var(--sizes-40) var(--sizes-60);
`;
const BottomSection = styled.div`
  border-top: 1px solid var(--color-gray-20);
  padding: var(--sizes-30) var(--sizes-60);
`;
const BoldedText = styled.span`
  font-weight: var(--typography-headings-heading-1-font-weight);
`;
const Header = styled.div`
  color: var(--color-grayscale-black);
  font-weight: var(--typography-headings-heading-2-font-weight);
`;
const SearchByHeader = styled(Header)`
  margin-bottom: var(--sizes-20);
`;
const List = styled.ul`
  margin-left: var(--sizes-10);
  line-height: 28px;
  list-style-type: disc;
  list-style-position: inside;
`;
