import styled from 'styled-components';

import { FormSection } from '../lib/form';
import { Heading } from '../lib/heading';

export const AuthFormSection = styled(FormSection)`
  gap: var(--sizes-50);
  margin-bottom: var(--sizes-50);
`;

export const AuthFormContainer = styled.div`
  width: 457px;

  @media only screen and (max-width: 1350px) {
    width: 350px;
  }
`;

export const StyledHeading = styled(Heading)`
  color: var(--color-grayscale-black);
  margin-top: 8px;
  margin-bottom: 12px;

  h1 {
    margin-bottom: 8px;
  }

  span {
    color: var(--color-gray-90);
    font-size: 13px;
  }
`;
