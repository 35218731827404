import styled from 'styled-components';

import { Badge } from 'src/app/components/lib/badge-v2';
import { ContentCard } from 'src/app/components/lib/content-card';
import { IconButton } from 'src/app/components/lib/icon-button';
import { Input } from 'src/app/components/lib/input';
import { Label } from 'src/app/components/lib/label';
import { Select } from 'src/app/components/lib/select';
import { NoResultsOverlay } from 'src/app/components/lib/table/no-results-overlay';

export const StyledContentCard = styled(ContentCard)`
  margin-top: var(--sizes-30);
  margin-bottom: var(--sizes-70);
  background-color: var(--color-brand-primary-white);

  article {
    padding: var(--sizes-70);
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--sizes-110);
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: var(--sizes-10);
  }
`;

export const StyledHeading = styled.div`
  width: 50%;
`;
export const StyledSearchBar = styled.div`
  width: 50%;
`;

export const QueryBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const RuleGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
  z-index: 1;
`;

export const VerticalLine = styled.div`
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
  z-index: -1;
`;

export const CombinatorBadge = styled(Badge)`
  width: 49px;
  background-color: #eadaf2;
  font: #3c0059;
`;

export const Condition = styled.div`
  max-width: 300px;
`;

export const Attribute = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: var(--color-gray-10);
  padding: 12px;
  border-radius: 6px;
  height: 67px;
`;

export const StyledSelect = styled(Select)`
  background-color: white;
  width: fit-content;
`;

export const StyledInput = styled(Input)`
  max-width: 132px;
`;

export const StyledIconButton = styled(IconButton)`
  padding-left: 0;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
`;

export const QueryActions = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 200px;
  gap: 8px;
`;

type LabelContainerProps = {
  minWidth?: string;
};

export const LabelContainer = styled.div<LabelContainerProps>`
  display: inline-block;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '57px')};
  height: 28px;
`;
export const BottomAlignedLabel = styled(Label)`
  vertical-align: bottom;
  display: inline-block;
`;

export const StyledNoResultsOverlay = styled(NoResultsOverlay)`
  position: static;
`;

export const StyledDrawerContent = styled.div`
  flex-grow: 1;
  padding: var(--sizes-80) var(--sizes-110);
  background-color: var(--color-gray-10);
  overflow-y: scroll;
`;

export const FullWidthContainerWithStickyFooterQueryBuilder = styled.div`
  margin-bottom: var(--sizes-110);
  width: 100%;
`;
