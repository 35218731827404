import AjaxPromises from '../utils/AjaxPromises';

import { endPoints as EndPoints } from '../constants/CompanyConstants';

export default {
  getLocations: (formData) => AjaxPromises.POST(EndPoints.GET_LOCATIONS, formData),

  getLogo: (formData) => AjaxPromises.POST(EndPoints.GET_LOGO, formData),

  getLocationLogo: (formData) => AjaxPromises.POST(EndPoints.GET_LOCATION_LOGO, formData),
};
