import React, { forwardRef, memo, useMemo, useState } from 'react';

import { createFilterOptions } from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { constant } from 'lodash';
import { areEqual } from 'react-window';

import { LinkButton } from 'src/app/components/lib/button';
import {
  POPPER_MODIFIERS,
  NO_OPTIONS_TEXT_DEFAULT,
  POPPER_PLACEMENT_DEFAULT,
  SEARCH_PLACEHOLDER_DEFAULT,
} from 'src/app/components/lib/dropdown/menu/constants';
import { DropdownMenuContext, useDropdownMenuContext } from 'src/app/components/lib/dropdown/menu/context';
import {
  DropdownMenuPopper,
  PopperLayout,
  SearchInput,
  DropdownMenuFooter,
  AutocompleteContainer,
} from 'src/app/components/lib/dropdown/menu/dropdown-menu.styles';
import { DropdownMenuItem } from 'src/app/components/lib/dropdown/menu/item';
import NoOptions from 'src/app/components/lib/dropdown/menu/no-results';
import { PopperComponent } from 'src/app/components/lib/dropdown/menu/popper-component';
import { VirtualizedListbox } from 'src/app/components/lib/dropdown/menu/virtualization';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import type { ListChildComponentProps } from 'react-window';
import type { DropdownMenuOption, DropdownMenuBaseProps } from 'src/app/components/lib/dropdown/menu/types';
import type { VirtualizedListboxProps } from 'src/app/components/lib/dropdown/menu/virtualization';

const DropdownMenuSingleRow = memo(({ data, index, style }: ListChildComponentProps) => {
  const [menuItemProps, option, selected] = data[index];
  const dropdownMenuContext = useDropdownMenuContext();
  const { truncateLabel, triggerNodeRef } = dropdownMenuContext;

  const isSeventhFromEnd = data && index === data.length - 7;

  return (
    <DropdownMenuItem
      ariaSelected={menuItemProps['aria-selected']}
      automationId={option.automationId}
      checked={selected}
      danger={option.danger}
      dataOptionIndex={menuItemProps['data-option-index']}
      disabled={option.disabled}
      footer={option.footer}
      height={Number(style.height)}
      id={option.id}
      key={option.key ?? menuItemProps.id}
      label={option.label}
      labelSecondary={option.labelSecondary}
      ref={isSeventhFromEnd ? triggerNodeRef : undefined}
      tabIndex={menuItemProps.tabIndex}
      title={option.label}
      top={Number(style.top)}
      truncateLabel={truncateLabel}
      variant='label'
      onClick={menuItemProps.onClick}
      onMouseOver={menuItemProps.onMouseOver}
      onTouchStart={menuItemProps.onTouchStart}
    />
  );
}, areEqual);

const MenuSingleListbox = forwardRef<HTMLDivElement, VirtualizedListboxProps>(({ children, ...props }, ref) => {
  const { truncateLabel } = useDropdownMenuContext();

  return (
    <VirtualizedListbox {...props} ref={ref} RowComponent={DropdownMenuSingleRow} truncateLabel={truncateLabel}>
      {children}
    </VirtualizedListbox>
  );
});

export type DropdownMenuSingleProps = DropdownMenuBaseProps & {
  disableDefaultFooterContent?: boolean;
  handleClose: (newValue?: DropdownMenuOption) => void;
  infiniteScroll?: boolean;
  maxWidth?: string;
  resetButtonAutomationId?: string;
  resetLabel?: string;
  searchAutomationId?: string;
  value: DropdownMenuOption;
  width?: string;
};

export function DropdownMenuSingle({
  anchorEl,
  disableDefaultFooterContent = false,
  disableSearchAllFields = false,
  footerContent,
  handleClose,
  id,
  infiniteScroll = false,
  maxWidth,
  noOptionsText = NO_OPTIONS_TEXT_DEFAULT,
  open,
  options,
  placement = POPPER_PLACEMENT_DEFAULT,
  resetButtonAutomationId,
  resetLabel = 'Clear',
  searchPlaceholder = SEARCH_PLACEHOLDER_DEFAULT,
  searchAutomationId,
  truncateLabel = true,
  value,
  width = '396px',
  onSearchInputChange,
  paginationProps,
}: DropdownMenuSingleProps) {
  const darkMode = useDarkMode();
  const [searchString, setSearchString] = useState('');

  const handleOnChange = (event: React.KeyboardEvent, newValue, reason) => {
    if (!(event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') && newValue) {
      if (newValue.disabled) {
        handleClose(value);
      } else {
        handleClose(newValue);
      }
    }

    setSearchString('');
  };

  const handleAutocompleteOnClose = (event: React.BaseSyntheticEvent, reason: AutocompleteCloseReason) => {
    if (reason === 'escape') {
      handleClose(value);
    }
  };

  const handleOnClickAway = () => {
    if (!value) {
      handleClose({ id: '', label: '' });
    }

    handleClose(value);
    setSearchString('');
    if (onSearchInputChange) {
      onSearchInputChange('');
    }
  };

  const handleOnInputChange = (event: React.SyntheticEvent, inputChangeValue: string, reason: string) => {
    if (reason === 'input') {
      setSearchString(inputChangeValue);
      onSearchInputChange?.(inputChangeValue);
    }
  };

  const defaultFooterContent = (
    <LinkButton
      automationId={resetButtonAutomationId}
      disabled={!value}
      key='footer-content'
      label={resetLabel}
      onClick={() => handleClose({ id: '', label: '' })}
    />
  );

  const filterOptions = createFilterOptions({
    stringify: ({ label, footer, labelSecondary }: DropdownMenuOption) => {
      if (disableSearchAllFields) {
        return label;
      }
      const footerText = typeof footer === 'string' ? footer : '';
      return `${label}${footerText}${labelSecondary ?? ''}`;
    },
  });

  const { isFetching, triggerNodeRef } = paginationProps ?? {};
  const dropdownMenuContext = useMemo(
    () => ({ truncateLabel, width, isFetching, triggerNodeRef }),
    [truncateLabel, width, isFetching, triggerNodeRef]
  );

  const FooterContent = disableDefaultFooterContent ? footerContent : [defaultFooterContent, footerContent];

  const zeroProvidedOptions = options.length === 0 && searchString === '';

  return (
    <DropdownMenuPopper
      $darkMode={darkMode}
      $maxWidth={maxWidth}
      $singleSelectionEnabled
      $width={width}
      anchorEl={anchorEl}
      id={id}
      modifiers={POPPER_MODIFIERS}
      open={open}
      placement={placement}
      role='menu'
    >
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <PopperLayout>
          {zeroProvidedOptions && <NoOptions />}
          {!zeroProvidedOptions && (
            <DropdownMenuContext.Provider value={dropdownMenuContext}>
              <AutocompleteContainer
                filterOptions={filterOptions}
                inputValue={searchString}
                isOptionEqualToValue={(option: DropdownMenuOption, selectedValue: DropdownMenuOption) =>
                  option?.id === selectedValue?.id
                }
                ListboxComponent={MenuSingleListbox}
                ListboxProps={{ role: infiniteScroll ? 'list-box' : 'listbox' }}
                noOptionsText={noOptionsText}
                open
                options={options}
                PopperComponent={PopperComponent}
                renderInput={(params) => (
                  <SearchInput
                    autoFocus
                    automationId={searchAutomationId}
                    inputProps={params.inputProps}
                    inputRef={params.InputProps.ref}
                    placeholder={searchPlaceholder}
                  />
                )}
                renderOption={(props, option, { selected }) => [props, option, selected]}
                renderTags={constant(null)}
                value={value}
                onChange={handleOnChange}
                onClose={handleAutocompleteOnClose}
                onInputChange={handleOnInputChange}
              />
            </DropdownMenuContext.Provider>
          )}

          {FooterContent && <DropdownMenuFooter>{FooterContent}</DropdownMenuFooter>}
        </PopperLayout>
      </ClickAwayListener>
    </DropdownMenuPopper>
  );
}
