import React from 'react';

import ErrorBoundary from 'src/app/layout/error/error-boundary';
import { useDarkMode } from 'src/app/state/dark-mode';

import { StyledPage } from './page.styles';

export enum PageVariant {
  GrayBackground = 'gray-background',
  MaxWidth = 'max-width',
  Table = 'table',
  TableMaxWidth = 'table-max-width',
}

type PageProps = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  variant?: PageVariant;
};

export function Page({ children, dataTestId, className, variant }: PageProps): JSX.Element {
  const darkMode = useDarkMode();
  return (
    <ErrorBoundary>
      <StyledPage $darkMode={darkMode} className={className} data-testid={dataTestId} variant={variant}>
        {children}
      </StyledPage>
    </ErrorBoundary>
  );
}
