import React from 'react';

import ErrorMessage from './error-message';

class SpecifiedErrorBoundary extends React.Component<
  { additionalContent?: React.ReactElement; children: React.ReactElement },
  { errorMessage?: string; hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  static getDerivedStateFromError(error) {
    if (error.cause === 'client-error') {
      return { hasError: true, errorMessage: error.message };
    }
    return { hasError: true };
  }

  componentDidUpdate(previousProps) {
    const { children } = this.props;
    if (previousProps.children !== children) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children, additionalContent } = this.props;
    if (hasError) {
      return (
        <ErrorMessage additionalContent={errorMessage ? additionalContent : undefined} errorMessage={errorMessage} />
      );
    }

    return children;
  }
}

export default SpecifiedErrorBoundary;
