import React, { useMemo } from 'react';

import i18next from 'i18next';
import { startCase, upperCase } from 'lodash';
import { v1 } from 'uuid';

import { ActionsFieldName, settingsColumnWithoutActions } from 'src/app/components/lib/table';
import { formatDate } from 'src/app/utils/formatters';
import { isCanadaLoc } from 'src/app/utils/locale';
import { isNotNullish } from 'src/app/utils/type-utils';

import { TooltipHeader } from './cells';
import { getAutoColumnHeaderName } from './column-auto-header-name';
import { getAutoTypesForColumn } from './column-auto-types';

import type { GridColumnVisibilityModel, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import type { GridColDef, GridRowsDef, Tooltip } from 'src/app/components/lib/table';
import type { TableColumnSetting, TableSetting, UserDispensariesAtom } from 'src/app/state/user-dispensaries';

// Column Utilities:
export function computeGridColumnDefinitions(
  apiRef,
  columns: GridColDef[],
  rows: GridRowsDef,
  tableName: string,
  currentTableSettings: TableSetting | undefined,
  hideActionsColumn: boolean,
  gridColumnWidths: Map<string, number>,
  user: UserDispensariesAtom,
  ignoreColumnOrder = false
): GridColDef[] {
  const stateColumns: string[] =
    apiRef?.current?.state?.columns?.all.filter(
      (columnField) => !['__check__', ActionsFieldName].includes(columnField)
    ) ?? null;
  const actionsColumn = columns.find(({ field }) => field === ActionsFieldName);
  const shouldHideActionsColumn = hideActionsColumn || columns.length < 3;
  let processedColumns = columns
    .filter(({ field }) => field !== ActionsFieldName)
    .map((column) => computeGridColumnDefinition(column, rows));

  const getOrder = (index: number) => (ignoreColumnOrder ? index : Number.MAX_SAFE_INTEGER);

  if (currentTableSettings) {
    const mergedColumns = processedColumns
      .map((column, index) => {
        const matchingSetting = currentTableSettings.layout.find((settings) => settings.name === column.field);
        const isColumnVisible = matchingSetting?.visible ?? !column.hide;
        const newColumn = { ...column, order: getOrder(index), hide: !isColumnVisible };

        if (isNotNullish(matchingSetting?.order) && !ignoreColumnOrder) {
          newColumn.order = matchingSetting.order;
        }

        if (isNotNullish(matchingSetting?.width)) {
          newColumn.width = matchingSetting.width;
        }

        return newColumn;
      })
      .sort((columnA, columnB) => columnA.order - columnB.order) as GridColDef[];

    processedColumns = mergedColumns;
  } else if (stateColumns) {
    // sort based on the column order in grid state when persisted table settings are not present
    processedColumns = processedColumns.sort(
      (columnA, columnB) => stateColumns.indexOf(columnA.field) - stateColumns.indexOf(columnB.field)
    );
  }

  if (gridColumnWidths.size > 0) {
    gridColumnWidths.forEach((width, columnField) => {
      const columnDefinition = processedColumns.find(({ field }) => field === columnField);
      /* TODO: This null-check is needed due to a bug in our DataGrid when there are multiple DataGrids
           on the page that are switched between, for example in different tabs.
           In that case, this function is incorrectly called with the non-active DataGrid
           such as after switching tabs, causing the page to crash here without the null check,
           due to the set of columns in each table being different.
       */
      if (isNotNullish(columnDefinition)) {
        columnDefinition.width = width;
      }
    });
  }

  if (actionsColumn) {
    processedColumns.push(actionsColumn);
  }

  processedColumns.forEach((col) => {
    if (col.headerName) {
      col.headerName = i18next.t(col.headerName);
    }

    // if the user is canadian, format dates differently across the board
    if ((col.type === 'date' || col.type === 'dateTime') && isCanadaLoc({ user }) && !col.valueFormatter) {
      col.valueFormatter = ({ value }) => formatDate(value, col.type === 'dateTime', user);
    }
  });

  return actionsColumn || shouldHideActionsColumn
    ? processedColumns
    : [...processedColumns, settingsColumnWithoutActions()];
}

export function getDefaultTableSetting(name: string, title: string): TableColumnSetting {
  return {
    name,
    title,
    hidden: false,
    th: {
      style: {},
      format: false,
    },
    td: {
      style: {},
      format: false,
      formatType: false,
      wrapNewline: false,
    },
    exportRender: false,
    sort: {
      field: name,
      sort: null,
    },
    visible: false,
    exportColumn: true,
    order: Number.MAX_SAFE_INTEGER,
    align: 'left',
  };
}

export function computeGridColumnDefinition(column: GridColDef, rows: GridRowsDef): GridColDef {
  if (column.field === ActionsFieldName) {
    return column;
  }
  return {
    editable: false,
    ...column,
    width: column.width ?? getColumnWidth(rows, column),
  };
}

/**
 * Maps the column names from the returned table data and performs translations for any columns returning outdated names
 * The translations are very specific to the reports tables and should be broken out into a separate function if there are conflicts in the future.
 * @param rows rows of table data
 * @param overrideColumnTranslations allows for passing a translations object that takes precedence over the universal object
 * @returns aliased column definitions for a table
 */
export function getColumnDefsFromTableData(rows: GridRowsDef, overrideColumnTranslations?: any): GridColDef[] {
  const columnNames = new Set<string>();

  rows.forEach((row) => {
    const foundColumnNames = Object.keys(row);
    foundColumnNames.forEach((key) => columnNames.add(key));
  });

  return [...columnNames].map((columnName) => ({
    field: columnName,
    headerName: getAutoColumnHeaderName({ columnName }, overrideColumnTranslations),
    width: getColumnWidthFromRowsAndColumnName(rows, columnName),
    editable: false,
    ...getAutoTypesForColumn({ columnName }),
  }));
}

/**
 * This is utilized for the reports team right now when the tooltips flag ('platform.reports.tooltips.rollout') is proven to work then you can replace the function above with this one.
 *
 * Maps the column names from the returned table data and performs translations for any columns returning outdated names
 * The translations are very specific to the reports tables and should be broken out into a separate function if there are conflicts in the future.
 * @param rows rows of table data
 * @param overrideColumnTranslations allows for passing a translations object that takes precedence over the universal object
 * @param tooltips allows for adding a tooltip to the table header
 * @returns aliased column definitions for a table
 */
export function getColumnDefsFromTableDataReports(
  rows: GridRowsDef,
  overrideColumnTranslations?: Record<string, string>,
  tooltips?: Tooltip[]
): GridColDef[] {
  const columnNames = new Set<string>();

  rows.forEach((row) => {
    const foundColumnNames = Object.keys(row);
    foundColumnNames.forEach((key) => columnNames.add(key));
  });

  return [...columnNames].map((columnName) => {
    let columnDef = {
      field: columnName,
      headerName: getAutoColumnHeaderName({ columnName }, overrideColumnTranslations),
      width: getColumnWidthFromRowsAndColumnName(rows, columnName),
      editable: false,
      ...getAutoTypesForColumn({ columnName }),
    };

    if (tooltips) {
      const tooltip = findTooltipByColName(tooltips, columnName);

      if (tooltip) {
        columnDef = {
          ...columnDef,
          renderHeader: () => (
            <TooltipHeader
              header={getAutoColumnHeaderName({ columnName }, overrideColumnTranslations)}
              tooltip={tooltip.tooltipDesc}
            />
          ),
        };
      }
    }

    return columnDef;
  });
}

/**
 * @deprecated //TODO-BACKOFFICE: Deprecated function - remove from call sites since computeGridColumnDefinitions takes care of this need
 */
export function getColumnWidths(columns: GridColDef[], rows: GridRowsDef) {
  return columns.map((column) => ({
    ...column,
    width: column.field === ActionsFieldName ? column.width : getColumnWidth(rows, column),
  }));
}

function getCellValue(row: GridRowsDef[number], column: GridColDef) {
  // Conditionals follow the order of precedence described in https://mui.com/components/data-grid/columns/#render-cell

  if (typeof column.valueFormatter === 'function') {
    return String(column.valueFormatter({ value: row[column.field] } as GridValueFormatterParams));
  }

  if (typeof column.valueGetter === 'function') {
    // TODO-GROOT: figure out how this is even working (it seems like manually invoking column.valueGetter should require all of the parameters, hence the type casting required)
    return String(column.valueGetter({ row } as unknown as GridValueGetterParams<any, any>));
  }

  return String(row[column.field]);
}

export function getColumnWidth(
  rows: GridRowsDef,
  column: GridColDef,
  fontShorthandProperty = '600 14px Matter, -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSizePx = 14,
  minimumWidth = 0,
  maximumWidth = 500
) {
  const maxContent = rows.reduce((previousValue, row) => {
    const cellValue = getCellValue(row, column);
    return previousValue.length > cellValue.length ? previousValue : cellValue;
  }, '');

  const headerCellContentWidth = getTextWidth(
    upperCase(startCase(column.headerName ?? column.field)),
    fontShorthandProperty,
    fontSizePx
  );
  const contentCellContentWidth = getTextWidth(maxContent, fontShorthandProperty, fontSizePx);

  const calculatedWidth = Math.max(headerCellContentWidth, contentCellContentWidth);

  if (calculatedWidth > maximumWidth) {
    return maximumWidth;
  }

  if (calculatedWidth < minimumWidth) {
    return minimumWidth;
  }

  return calculatedWidth;
}

function getColumnWidthFromRowsAndColumnName(
  rows: GridRowsDef,
  columnName: string,
  fontShorthandProperty = '600 14px Matter, -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSizePx = 14,
  minimumWidth = 0,
  maximumWidth = 500
) {
  const maxContent = rows.reduce((previousValue, row) => {
    const cellValue = String(row[columnName]);
    return previousValue.length > cellValue.length ? previousValue : cellValue;
  }, '');

  const headerCellContentWidth = getTextWidth(upperCase(startCase(columnName)), fontShorthandProperty, fontSizePx);
  const contentCellContentWidth = getTextWidth(maxContent, fontShorthandProperty, fontSizePx);

  const calculatedWidth = Math.max(headerCellContentWidth, contentCellContentWidth);

  if (calculatedWidth > maximumWidth) {
    return maximumWidth;
  }

  if (calculatedWidth < minimumWidth) {
    return minimumWidth;
  }

  return calculatedWidth;
}

function getTextWidth(text: string, font: string, fontSizePx: number): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 200;
  }
  context.font = font;
  const { width } = context.measureText(text);

  return Math.ceil(width) + fontSizePx * 2;
}

export function getTextHeight(
  text: string,
  containerWidth: number,
  padding = 10,
  font = '400 13px "Open sans", sans-serif'
) {
  const temporaryElement = document.createElement('div');
  temporaryElement.style.width = `${containerWidth}px`;
  temporaryElement.style.font = font;
  temporaryElement.style.padding = `${padding}px`;
  temporaryElement.innerText = text;

  document.body.appendChild(temporaryElement);
  const { height } = temporaryElement.getBoundingClientRect();
  document.body.removeChild(temporaryElement);

  const calculatedHeight = height + padding;

  return Math.max(calculatedHeight, 40);
}

export function visibilityModelChanged(next: GridColumnVisibilityModel, current: GridColumnVisibilityModel) {
  const nextEntries = Object.entries(next);

  return nextEntries.some(([columnName, visible]) => visible !== current[columnName]);
}

// Row Utilities:
export function applyUuidToDataGridRows(rows: GridRowsDef) {
  return (rows || []).map((row) => ({ ...row, id: v1() }));
}

export function useApplyUuidToDataGridRows(rows: GridRowsDef) {
  return useMemo(() => applyUuidToDataGridRows(rows), [rows]);
}

export function findTooltipByColName(tooltips: Tooltip[], colName: string): Tooltip | undefined {
  return tooltips.find((tooltip) => tooltip.colName === colName);
}
