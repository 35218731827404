import { InputBuffer } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import { ConnectionType } from "../../shared/types";
import { Scanner } from "../Scanner";

export class HidScanner extends Scanner {
  private device: HIDDevice;
  private inputBuffer: InputBuffer<HIDInputReportEvent>;

  constructor(device: HIDDevice) {
    super(ConnectionType.hid);
    this.device = device;

    this.inputBuffer = new InputBuffer<HIDInputReportEvent>({
      name: 'HidScanner',
      onCandidateDetected: this.onCandidateDetected.bind(this)
    });
  }

  get id(): string { return `${this.device.vendorId}-${this.device.productId}-${this.device.productName}`; }
  get isConnected(): boolean { return this.device.opened; }

  get productId(): number { return this.device.productId; }
  get vendorId(): number { return this.device.vendorId; }

  //#region Private Methods
  private handleInputReportRef = this.handleInputReport.bind(this);
  private async handleInputReport(event: HIDInputReportEvent): Promise<void> {
    // filter out events from other devices
    if (!this.isThisDevice(event.device)) return;

    if(event.data.byteLength > 4) {
      // if last byte is a 0 then this should be the last report in the sequence
      const forceCheck = event.data.getInt8(event.data.byteLength - 1) === 0;
      this.inputBuffer.push(event, forceCheck);
    }
  }

  private isThisDevice(device: HIDDevice): boolean {
    return this.device.vendorId === device.vendorId && this.device.productId === device.productId;
  }

  // Zebra sends HID reports in chunks of 64 bytes. Large barcodes such as pdf417 are sent across multiple reports.
  // The first 4 characters seem to be a control with the first char being the command length and the 4th always being 0 (NUL).
  // There will always be 64 characters, but if the barcode is less than 64 characters then the remaining characters will 
  // also be NUL. We only care about the non-NUL characters.
  // Occassionally the valid bytes will also contain character codes for a newline. By checking String.fromCharCode
  // we can filter thoes non-printable characters out as well.
  private onCandidateDetected(props: { items: HIDInputReportEvent[] }): void {
    const accBytes: number[] = [];

    for(const report of props.items) {
      let start = false;
      
      const reportByteLength = report.data.byteLength;
      for(let i = 0; i < reportByteLength; i++) {
        const charCode = report.data.getUint8(i)
        
        // begin capturing bytes on next iteration
        if(!start && charCode === 0) {
          start = true;
          continue;
        }

        // end of this report was detected
        if(start && charCode === 0) {
          break;
        }

        if(start) {
          accBytes.push(charCode);
        }
      }
    }

    const bytes = Uint8Array.from(accBytes);
    this.emitBarcodeEvent({ bytes, text: new TextDecoder().decode(bytes), })
  }
  //#endregion

  attachEventListeners(): void {
    this.device.addEventListener('inputreport', this.handleInputReportRef);
  }

  detachEventListeners(): void {
    this.device.removeEventListener('inputreport', this.handleInputReportRef);
  }

  async doConnect(): Promise<boolean> {
    try {
      if (this.device.opened) return true;

      await this.device.open();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async doDisconnect(): Promise<boolean> {
    try {
      if (!this.device.opened) return true;

      await this.device.close();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async revokePermission(): Promise<boolean> {
    await this.device.forget();
    HardwareService.scanner.removeDevice(this);
    return true;
  }
}
