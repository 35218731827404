import { useQuery } from '@tanstack/react-query';

import { getData } from 'src/app/queries';

type RegionResponse = {
  Data: string;
  Message: string | null;
  Result: boolean;
};

export function useRegionQuery() {
  return useQuery({
    queryKey: ['region'],
    queryFn: () => getData<RegionResponse>('api/admin/get-region'),
    staleTime: Infinity,
  });
}
