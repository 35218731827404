import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { segmentsQueryKey } from './get-segments';
import { audienceDetailKeys, segmentDetailKeys } from './query-key-factory';

type UpdateSegmentStatusPayload = {
  IsDeleted: boolean;
  SegmentId: number;
};

export const GET_SEGMENT_STATUS_ENDPOINT = 'api/segment/set-segment-status';

export const useUpdateSegmentStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: UpdateSegmentStatusPayload) =>
      postData({
        endpoint: GET_SEGMENT_STATUS_ENDPOINT,
        payload,
      }),
    onMutate: async (payload: UpdateSegmentStatusPayload) => {
      // force a refetch when a user goes to the segment detail tab and audience tab
      // since the segment has changed
      await queryClient.invalidateQueries({ queryKey: segmentDetailKeys.one(payload.SegmentId) });
      await queryClient.invalidateQueries({ queryKey: audienceDetailKeys.one(payload.SegmentId) });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: segmentsQueryKey });
    },
  });
};
