import keyMirror from 'keymirror';

export const endPoints = {
  GET_LOCATIONS: 'api/posv3/maintenance/GetLspLocationsBackend',
  GET_LOGO: 'api/posv3/maintenance/GetLspLogo',
  GET_LOCATION_LOGO: 'api/posv3/maintenance/GetLocationLogo',
  GET_LOCATIONS_FOR_LSP: 'api/posv3/lsplocation/get-locations-for-lsp',
  GET_LOCATIONS_FOR_LIST_OF_LSPS: 'api/posv3/lsplocation/get-all-locations-for-lsp-list',
  GET_LOCATIONS_FOR_ADMIN: 'api/posv3/lsplocation/get-all-locations-for-admin-user',
};

export const actions = keyMirror({});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
