import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { useReportsList, type StaticReport } from 'src/app/pages/reports-v2/use-reports-list';

import { searchTermAtom } from './search-term';

type FilteredReports = {
  filteredReports: StaticReport[];
  isLoading: boolean;
};

export function useFilteredReports(): FilteredReports {
  const flags = useFlags();
  const globalSearchRefactorIsEnabled = flags['pos.backoffice.global-search-refactor.rollout'];

  const filter = useRecoilValue(searchTermAtom);

  const { reportsList = [], isLoading } = useReportsList({
    isEnabled: globalSearchRefactorIsEnabled,
  });

  const filteredReportsList = useMemo(
    () =>
      reportsList.filter((report) => {
        const hasNameMatch = report.ReportName.toLowerCase().includes(filter.toLowerCase());
        return hasNameMatch;
      }),
    [filter, reportsList]
  );

  // we do not want to include reports in results if Global Search Refactor is not enabled
  if (!globalSearchRefactorIsEnabled) {
    return { isLoading: false, filteredReports: [] };
  }

  return { isLoading, filteredReports: filteredReportsList };
}
