import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

export const formatFilterDropdownOptions = (options: any[], labelKey: string, valueKey: string): DropdownMenuOption[] =>
  options.reduce((accum, currentOption) => {
    if (currentOption[labelKey] && currentOption[valueKey]) {
      const addedOption = {
        label: currentOption[labelKey],
        id: currentOption[valueKey],
        key: currentOption[valueKey],
      };
      return [...accum, addedOption];
    }
    return accum;
  }, []);

export const createFilterDropdownOptionsFromStrings = (options: string[]): { label: string; value: string }[] =>
  options.map((option) => ({
    label: option,
    value: option,
  }));
