import React from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';

import { Modal } from 'src/app/components/lib/modal';

import { ControlBar, ControlGroup, ControlSearchBox } from '../../control-bar';

import { CheckboxRow } from './checkbox-row';
import { ReorderableCheckboxRow } from './reorderable-checkbox-row';
import { useColumnsModal } from './use-columns-modal';

export function ColumnsModal({
  allowColumnReordering = true,
  onClose,
}: {
  allowColumnReordering?: boolean;
  onClose: () => void;
}) {
  const {
    searchValue,
    filteredColumns,
    workingColumnVisibilityModel,
    saveBtnIsDisabled,
    saveBtnTooltipTxt,
    closeModal,
    handleSaveOnClick,
    handleCheckboxOnChange,
    handleMoveColumn,
    setSearchValue,
  } = useColumnsModal({ onClose });

  return (
    <Modal
      Content={
        <>
          <ColumnsSubheader>Columns</ColumnsSubheader>
          {allowColumnReordering && (
            <ColumnsInstructions>Drag and drop the columns below to reorder them.</ColumnsInstructions>
          )}
          <ControlBar>
            <ControlGroup grow isResponsive>
              <ControlSearchBox
                initialValue={searchValue}
                placeholder='Search columns by name...'
                onChange={setSearchValue}
              />
            </ControlGroup>
          </ControlBar>
          <StyledColumnList>
            {allowColumnReordering ? (
              <DndProvider backend={HTML5Backend}>
                {filteredColumns.map(({ field, headerName, hideable }, index) => (
                  <ReorderableCheckboxRow
                    disabled={!hideable}
                    field={field}
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    headerName={headerName}
                    index={index}
                    key={field}
                    moveColumn={handleMoveColumn}
                    searchValue={searchValue}
                    workingColumnVisibilityModel={workingColumnVisibilityModel}
                  />
                ))}
              </DndProvider>
            ) : (
              <>
                {filteredColumns.map(({ field, headerName, hideable }) => (
                  <CheckboxRow
                    disabled={!hideable}
                    field={field}
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    headerName={headerName}
                    key={field}
                    workingColumnVisibilityModel={workingColumnVisibilityModel}
                  />
                ))}
              </>
            )}
          </StyledColumnList>
        </>
      }
      height='800px'
      open
      primaryActions={[
        { label: 'Cancel', variant: 'secondary', onClick: () => closeModal(true) },
        {
          automationId: 'table-settings-modal-save-button',
          label: 'Save',
          onClick: handleSaveOnClick,
          disabled: saveBtnIsDisabled,
          tooltipText: saveBtnTooltipTxt,
        },
      ]}
      title='Column configuration'
      onClose={closeModal}
    />
  );
}

const StyledColumnList = styled.div`
  gap: var(--sizes-20);
  display: flex;
  flex-direction: column;
`;

const ColumnsSubheader = styled.div`
  font: var(--font-large-16pt-semibold);
  margin: 0 0 var(--sizes-50) 0;
`;

const ColumnsInstructions = styled.div`
  font: var(--font-small-13pt-normal);
  margin: 0 0 var(--sizes-50) 0;
`;
