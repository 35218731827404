import React from 'react';

export function BellNotificationIcon(): JSX.Element {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <g id='alert-bell-notification-2 1'>
        <g id='Group'>
          <path
            d='M8.63862 13.7501H5.76996C5.73183 13.7514 5.69542 13.7663 5.6673 13.7921C5.63919 13.8179 5.62121 13.8529 5.61663 13.8908C5.61347 13.9503 5.61347 14.0099 5.61663 14.0694C5.61663 14.4931 5.78491 14.8994 6.08447 15.1989C6.38403 15.4985 6.79032 15.6668 7.21396 15.6668C7.6376 15.6668 8.04389 15.4985 8.34344 15.1989C8.643 14.8994 8.81129 14.4931 8.81129 14.0694C8.81445 14.0099 8.81445 13.9503 8.81129 13.8908C8.80642 13.8495 8.78568 13.8117 8.75345 13.7855C8.72122 13.7592 8.68004 13.7465 8.63862 13.7501Z'
            fill='#594F00'
            id='Vector'
          />
          <path
            d='M9.11791 3.52803C9.11604 3.1482 9.19205 2.77201 9.34124 2.4227C8.97046 2.22809 8.57171 2.09225 8.15924 2.02003V1.29203C8.15265 1.04224 8.04879 0.804895 7.86979 0.63055C7.69078 0.456206 7.45078 0.358643 7.20091 0.358643C6.95103 0.358643 6.71103 0.456206 6.53202 0.63055C6.35302 0.804895 6.24916 1.04224 6.24257 1.29203V2.0267C5.57976 2.15189 4.95592 2.43195 4.42191 2.84403C3.7397 3.40536 3.19296 4.11341 2.82243 4.91541C2.4519 5.71741 2.26714 6.59269 2.28191 7.47603V7.96136C2.28139 9.59043 1.89845 11.1966 1.16391 12.6507C1.12821 12.7272 1.12355 12.8145 1.15091 12.8943C1.17826 12.9741 1.23549 13.0402 1.31057 13.0787C1.35469 13.0991 1.40262 13.1101 1.45124 13.1107H12.9512C13.0359 13.1107 13.1172 13.0771 13.177 13.0172C13.2369 12.9573 13.2706 12.8761 13.2706 12.7914C13.2705 12.7427 13.2596 12.6946 13.2386 12.6507C12.504 11.1966 12.1211 9.59043 12.1206 7.96136V7.47603C12.1229 7.11409 12.093 6.75267 12.0312 6.39603H11.9926C11.6154 6.39682 11.2418 6.32322 10.8931 6.17945C10.5444 6.03569 10.2275 5.82457 9.96047 5.55818C9.69347 5.29179 9.48161 4.97536 9.33703 4.62701C9.19246 4.27865 9.11799 3.9052 9.11791 3.52803Z'
            fill='#594F00'
            id='Vector_2'
          />
          <path
            d='M10.0767 3.52799C10.0767 4.03633 10.2786 4.52384 10.638 4.88328C10.9975 5.24273 11.485 5.44466 11.9933 5.44466C12.5017 5.44466 12.9892 5.24273 13.3486 4.88328C13.7081 4.52384 13.91 4.03633 13.91 3.52799C13.91 3.01966 13.7081 2.53215 13.3486 2.17271C12.9892 1.81326 12.5017 1.61133 11.9933 1.61133C11.485 1.61133 10.9975 1.81326 10.638 2.17271C10.2786 2.53215 10.0767 3.01966 10.0767 3.52799Z'
            fill='#594F00'
            id='Vector_3'
          />
        </g>
      </g>
    </svg>
  );
}
