const LDKeyMap = {
  Development: {
    key: '5cade',
    name: 'development',
  },
  Production: {
    key: '5cadf',
    name: 'production',
  },
  Stage: {
    key: '460e6',
    name: 'stage',
  },
  Dev: {
    key: '28b46',
    name: 'dev',
  },
  Partners: {
    key: '28b5e',
    name: 'partners',
  },
  Demo: {
    key: '24678',
    name: 'demo',
  },
  E2E: {
    key: '217b8',
    name: 'e2e',
  },
  POSE2E: {
    key: 'f390e',
    name: 'pos-e2e',
  },
  ReviewApps: {
    key: 'dcf04',
    name: 'review-apps',
  },
  Next: {
    key: '2fd8f',
    name: 'next',
  },
  E2EFlagTesting: {
    key: '91329',
    name: 'e2e-flag-testing',
  },
  Elkhorn: {
    key: '72184',
    name: 'elkhorn',
  },
  Rainier: {
    key: '48b0c',
    name: 'rainier',
  },
  Wollowa: {
    key: '1d0e0',
    name: 'wollowa',
  },
};

export function getLDEnv(targetString?: string): keyof typeof LDKeyMap | null {
  for (const key in LDKeyMap) {
    if (targetString?.includes(LDKeyMap[key as keyof typeof LDKeyMap].key)) {
      return key as keyof typeof LDKeyMap;
    }
  }
  return null;
}

export function getLDUrl(targetString?: string): string {
  const env = getLDEnv(targetString);
  if (env) {
    return `https://app.launchdarkly.com/default/${LDKeyMap[env].name}/features`;
  }
  return '';
}
