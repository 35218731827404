import { HardwareService } from "../../HardwareService";
import type { PeripheralServiceConfig } from "../../shared/index";
import { PeripheralType } from "../../shared/index";
import { WebSerialService } from "../../shared/web-serial/index";

import { SerialScale } from "./SerialScale";

export class SerialScaleService extends WebSerialService<SerialScale> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.scale, { autoConnect: true, autoEnable: true, name: 'SerialScaleService' }, config)
  }

  createPort(port: SerialPort): SerialScale {
    return new SerialScale(port);
  }
  
  onInvalidate(): void {
    HardwareService.scale.invalidate(this.name);
  }
}
