import React from 'react';

import { Chip } from '@mui/material';

import { ExtraSmallTextSemiBold } from 'src/app/components/lib/typography';

import {
  BadgeContainer,
  StyledBlueProgressIcon,
  StyledGreenFilledCheckIcon,
  StyledRedErrorIcon,
  StyledOrangeStarsIcon,
} from './badge.styles';

import type { ChipProps } from '@mui/material';

export type BadgeProps = ChipProps & {
  backgroundColor?: string;
  icon?: JSX.Element;
  label: string;
  textColor?: string;
};

export function Badge(props: BadgeProps) {
  const { backgroundColor, icon, label, textColor, ...rest } = props;
  const labelContent = (
    <BadgeContainer>
      {icon || ''}
      <ExtraSmallTextSemiBold>{label}</ExtraSmallTextSemiBold>
    </BadgeContainer>
  );
  // .MuiChip-label
  return <Chip {...rest} label={labelContent} sx={{ background: backgroundColor, color: textColor }} />;
}

export function GreenFilledCheckBadge({ backgroundColor, icon, label, textColor, ...rest }: BadgeProps) {
  return (
    <Badge
      {...rest}
      backgroundColor={backgroundColor ?? 'var(--color-green-10)'}
      icon={icon ?? <StyledGreenFilledCheckIcon />}
      label={label}
      textColor={textColor ?? 'var(--color-green-80)'}
    />
  );
}

export function RedErrorBadge({ backgroundColor, icon, label, textColor, ...rest }: BadgeProps) {
  return (
    <Badge
      {...rest}
      backgroundColor={backgroundColor ?? 'var(--color-red-10)'}
      icon={icon ?? <StyledRedErrorIcon />}
      label={label}
      textColor={textColor ?? 'var(--color-red-60)'}
    />
  );
}

export function BlueProgressBadge({ backgroundColor, icon, label, textColor, ...rest }: BadgeProps) {
  return (
    <Badge
      {...rest}
      backgroundColor={backgroundColor ?? 'var(--color-blue-10)'}
      icon={icon ?? <StyledBlueProgressIcon />}
      label={label}
      textColor={textColor ?? 'var(--color-blue-80)'}
    />
  );
}

export function OrangeStarsBadge({ backgroundColor, icon, label, textColor, ...rest }: BadgeProps) {
  return (
    <Badge
      {...rest}
      backgroundColor={backgroundColor ?? 'var(--color-orange-10)'}
      icon={icon ?? <StyledOrangeStarsIcon />}
      label={label}
      textColor={textColor ?? 'var(--color-orange-100)'}
    />
  );
}
