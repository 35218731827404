import AjaxPromises from '../utils/AjaxPromises';

import { SimpleCachedDataStore } from './SimpleCachedDataStore';
import UserStore from './UserStore';

class LabelStore extends SimpleCachedDataStore {
  constructor() {
    super();
    this.updateLabel = this.updateLabel.bind(this);
  }
  sourceUrl = 'api/posv3/maintenance/get-labels';
  updateUrl = 'api/posv3/maintenance/update-label';
  entity = 'Labels';

  get labels() {
    return this.data;
  }

  async updateLabel(label) {
    const data = { ...UserStore.getApiData(), ...label };
    const resp = await AjaxPromises.POST(this.updateUrl, data);
    // the only key in the response data is "", IDK man
    if (resp.Result && label.Fields.some((field) => field.LabelId === resp.Data[0][''])) {
      this.emitChange({});
    } else {
      throw 'Update Label Failed';
    }
  }

  emitChange(change) {
    super.emitChange(change);
  }
}

export default new LabelStore();
