import { datadogRum } from '@datadog/browser-rum';

import { VERSION, IS_PRODUCTION_APP } from 'src/app/constants/environment';

export function extractOperationName(content: string | undefined): string {
  if (content) {
    const regex = /query ([^(]+)/gm;
    const matches = regex.exec(content);
    if (matches && matches.length > 1) {
      return matches[1];
    }
  }
  return 'unknown';
}

// intentionally not typing these params because the datadog type supplied is incorrect
export function beforeSend(event, context) {
  // stop reporting resources for logrocket in datadog
  if (context?.xhr?.responseURL?.includes('r.lr-in-prod.com/i')) {
    return false;
  }
  if (context?.response?.url?.includes('api/graphql') && event.context) {
    event.context = { ...event.context, operationName: extractOperationName(context?.requestInit?.body) };
  }

  return true;
}

export function initDatadogRum() {
  if (IS_PRODUCTION_APP) {
    datadogRum.init({
      applicationId: '0e94699a-652a-47d8-8c5a-f86cf996f90f',
      clientToken: 'pubc0c2ce75635e2b5e4ac83d490f92baea',
      site: 'datadoghq.com',
      service: 'dutchie-back-office',
      version: VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [/https:\/\/.*\.backoffice.dutchie\.com/],
      enableExperimentalFeatures: ['feature_flags'],

      beforeSend,
    });

    datadogRum.startSessionReplayRecording();
  }
  return datadogRum;
}
