import React from 'react';

class Price extends React.Component<any, any> {
  render() {
    let price = parseFloat(this.props.price).toFixed(2);
    const { dollar } = this.props;
    const type = this.props.type || 'div';

    const tmp = Math.abs(this.props.price);

    if (tmp > 999.99) {
      const priceParts = price.toString().split('.');
      switch (priceParts[0].length) {
        case 4:
          price = `${priceParts[0][0]},${priceParts[0][1]}${priceParts[0][2]}${priceParts[0][3]}.${priceParts[1]}`;
          break;

        case 5:
          price = `${priceParts[0][0]}${priceParts[0][1]},${priceParts[0][2]}${priceParts[0][3]}${priceParts[0][4]}.${priceParts[1]}`;
          break;

        case 6:
          price = `${priceParts[0][0]}${priceParts[0][1]}${priceParts[0][2]},${priceParts[0][3]}${priceParts[0][4]}${priceParts[0][5]}.${priceParts[1]}`;
          break;
      }
    }

    if (type === 'table') {
      return (
        <td className={`text-right ${this.props.className}`}>
          {price === 'NaN' ? (
            <span>&nbsp;</span>
          ) : (
            <span>
              {dollar ? '$' : ''}
              {price}
            </span>
          )}
        </td>
      );
    }

    return (
      <div className={`price ${this.props.className}`} style={this.props.style ? this.props.style : {}}>
        {price === 'NaN' ? (
          <span>&nbsp;</span>
        ) : (
          <span>
            {dollar ? '$' : ''}
            {price}
          </span>
        )}
      </div>
    );
  }
}

export default Price;
