import zxcvbn from 'zxcvbn';

import type { ZXCVBNScore } from 'zxcvbn';

export function checkComplexity(password, passwordSettings) {
  const checker = zxcvbn(password);
  const strength = checker.score;
  const { warning } = checker.feedback;
  const suggestion = checker.feedback.suggestions;
  const passwordLength = passwordSettings.PasswordLengthEnabled ? passwordSettings.PasswordLength : 8;

  if (password.length < passwordLength) {
    const lengthError = {
      idStatus: 'auth__error',
      notification: `Password does not meet ${passwordLength} character length requirement`,
    };

    return lengthError;
  }

  if (strength < 3 && warning == '') {
    const strengthErrorSuggestion = {
      idStatus: 'auth__error',
      notification: `${suggestion} Password is invalid.`,
    };

    return strengthErrorSuggestion;
  }

  if (strength < 3 && warning != '') {
    const strengthErrorWarning = {
      idStatus: 'auth__error',
      notification: `${warning}. Password is invalid.`,
    };

    return strengthErrorWarning;
  }

  return null;
}

export function checkMatching(password, confirmPassword) {
  if (password != confirmPassword) {
    const matchError = {
      idStatus: 'auth__error',
      notification: 'Passwords do not match',
    };

    return matchError;
  }

  return null;
}

export function getPasswordStrength(password: string) {
  if (password) {
    const checker = zxcvbn(password);
    return checker.score;
  }
  return 0;
}

export function getPasswordStrengthLabel(score: ZXCVBNScore) {
  switch (score) {
    case 0:
      return 'Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Somewhat strong';
    case 3:
      return 'Strong';
    case 4:
      return 'Strong';
    default:
      return 'Weak';
  }
}
