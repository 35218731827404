import React from 'react';

import styled from 'styled-components';

const CloseContainer = styled.div`
  height: 16px;

  svg {
    display: block;
  }
`;

type CloseSmallProps = {
  className?: string;
  onClick?: () => void;
};

export function CloseSmall({ onClick, className }: CloseSmallProps) {
  return (
    <CloseContainer className={className} onClick={onClick}>
      <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M3.41554 12.3492C3.02816 11.9619 3.02816 11.3338 3.41554 10.9464L11.4316 2.9303C11.819 2.54293 12.4471 2.54293 12.8345 2.9303C13.2218 3.31768 13.2218 3.94575 12.8345 4.33312L4.81836 12.3492C4.43098 12.7366 3.80291 12.7366 3.41554 12.3492Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M3.41553 2.93031C3.80291 2.54293 4.43097 2.54293 4.81835 2.93031L12.8345 10.9464C13.2218 11.3338 13.2218 11.9619 12.8345 12.3492C12.4471 12.7366 11.819 12.7366 11.4316 12.3492L3.41553 4.33313C3.02816 3.94575 3.02816 3.31768 3.41553 2.93031Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
      </svg>
    </CloseContainer>
  );
}
