import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextField } from 'src/app/components/fields/text-field';
import { Button } from 'src/app/components/lib/button';
import { useSSOLoginMutation } from 'src/app/queries/saml/sso-login-redirect';

import { AuthFormContainer, AuthFormSection, StyledHeading } from './shared';

import type { SubmitHandler } from 'react-hook-form';

const schema = yup.object({
  username: yup.string().required('Username is required'),
});

type SSOLoginFormData = yup.InferType<typeof schema>;

export function SSOLogin({ showFullPageMigrationTakeover }: { showFullPageMigrationTakeover: (props: any) => void }) {
  const form = useForm<SSOLoginFormData>({
    resolver: yupResolver(schema),
  });
  const { isDirty, isValid } = form.formState;

  const { mutate, isLoading } = useSSOLoginMutation({
    showFullPageMigrationTakeover,
  });

  const handleSSOLogin: SubmitHandler<SSOLoginFormData> = async (data) => {
    mutate({ username: data.username });
  };

  return (
    <AuthFormContainer>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSSOLogin)}>
          <AuthFormSection>
            <StyledHeading automationId='auth_heading_login' subheader='Login to your dutchie account:'>
              Welcome back!
            </StyledHeading>

            <TextField automationId='auth_input_username' name='username' placeholder='Username' required />
          </AuthFormSection>
          <Button disabled={!isDirty || !isValid || isLoading} label='Login' loading={isLoading} type='submit' />
        </form>
      </FormProvider>
    </AuthFormContainer>
  );
}
