import type { ChangeEvent } from 'react';
import React from 'react';

import { RebrandCheck } from 'src/app/components/icons/rebrand-check';
import { RebrandUncheck } from 'src/app/components/icons/rebrand-uncheck';

import { RebrandCheckbox } from './checkbox.styles';

import type { SxProps } from '@mui/material';

export type CheckboxBaseProps = {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  value?: any;
};

export function CheckboxBase(props: CheckboxBaseProps) {
  const { checked, indeterminate, name, onChange, disabled, sx, className, value } = props;

  return (
    <RebrandCheckbox
      checked={checked}
      checkedIcon={<RebrandCheck disabled={disabled} />}
      className={className}
      disabled={disabled}
      icon={<RebrandUncheck disabled={disabled} />}
      indeterminate={indeterminate}
      name={name}
      sx={sx}
      value={value}
      onChange={onChange}
    />
  );
}
