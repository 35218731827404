import { Popper, Autocomplete } from '@mui/material';
import styled, { css } from 'styled-components';

import { Search } from 'src/app/components/lib/search';

import type { PopperProps } from '@mui/material';

export const DropdownMenuFooter = styled.div`
  border-top: 1px solid var(--color-gray-20);
  height: 42px;
  display: flex;
  gap: var(--sizes-70);
  padding: 0 var(--sizes-50);
  align-items: center;
  justify-content: flex-start;

  > button {
    padding: 0;
    min-width: auto;
  }
`;

type DropdownMenuPopperProps = {
  $darkMode: boolean;
  $emptyMultiselect?: boolean;
  $maxWidth?: string;
  $singleSelectionEnabled?: boolean;
  $width?: string;
  $withinModal?: boolean;
};

export const DropdownMenuPopper = styled(Popper)<DropdownMenuPopperProps>`
  --scroll-area-height: 330px;
  background-color: var(--color-brand-primary-white);
  border-radius: 9px;
  border: 1px solid #e1e4e8;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  color: var(--color-grayscale-black);
  font: var(--font-regular-14pt-normal);

  ${({ $withinModal, $singleSelectionEnabled, $emptyMultiselect }) =>
    ($withinModal &&
      css`
        @media screen and (max-height: 950px) {
          --scroll-area-height: 280px;
          height: ${$singleSelectionEnabled || $emptyMultiselect ? 'auto' : '400px'};
        }
        @media screen and (max-height: 840px) {
          --scroll-area-height: 230px;
          height: ${$singleSelectionEnabled || $emptyMultiselect ? 'auto' : '350px'};
        }
        @media screen and (max-height: 745px) {
          --scroll-area-height: 180px;
          height: ${$singleSelectionEnabled || $emptyMultiselect ? 'auto' : '300px'};
        }
      `) ||
    css`
      height: ${$singleSelectionEnabled || $emptyMultiselect ? 'auto' : '450px'};
    `};

  width: ${({ $width }) => $width ?? '396px'};
  z-index: 1400;

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}

  .MuiAutocomplete-paper {
    width: 100%;
    color: unset;
    border-radius: unset;
    box-shadow: unset;
    background-color: unset;
    transition: unset;
    box-sizing: border-box;
    overflow: hidden;
  }

  .MuiAutocomplete-popper {
    min-height: var(--scroll-area-height);
  }

  .MuiAutocomplete-listbox {
    padding: 0;
    max-height: var(--scroll-area-height);
    & > ul {
      width: unset !important;
      list-style-type: unset;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
    }
  }

  .MuiMenuItem-root {
    padding: 0;
    min-height: 44px;
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      border-color: var(--color-gray-100);
      background-color: var(--color-grayscale-black);
      color: var(--color-brand-primary-white);
    `}
` as (props: DropdownMenuPopperProps & PopperProps) => JSX.Element;

export const PopperLayout = styled.div<{ $disableFooter?: boolean }>`
  ${({ $disableFooter }) => css`
    height: ${$disableFooter ? 'auto' : '100%'}%;
  `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .MuiAutocomplete-noOptions {
    font: var(--font-regular-14pt-semibold);
    text-align: center;
  }
`;

export const SearchInput = styled(Search)`
  margin: var(--sizes-40) var(--sizes-50);
  padding: 10px var(--sizes-40);
  .MuiInputBase-formControl,
  .MuiAutocomplete-input {
    padding: 0 !important;
  }
`;

export const AutocompleteContainer = styled(Autocomplete)`
  display: flex;
  box-sizing: border-box;
` as typeof Autocomplete;
