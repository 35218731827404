import React from 'react';
import i18next from 'i18next';

class WidgetPopupHeader extends React.Component<any, any> {
  render() {
    return (
      <div className='widget-popup__header' style={this.props.style}>
        {this.props.onClose && (
          <div
            className='widget-popup__header--close'
            onClick={() => {
              this.props.onClose();
            }}
          >
            <i aria-hidden='true' className='fa fa-times' />
          </div>
        )}
        {this.props.onAdd && (
          <div
            className='widget-popup__header--add'
            onClick={() => {
              this.props.onAdd();
            }}
          >
            <i aria-hidden='true' className='fa fa-plus' />
          </div>
        )}
        {this.props.onExport && !this.props.disableExport && (
          <i
            className='fa fa-file-excel-o pull-right'
            data-testid='widget-header_i_export-excel'
            onClick={() => {
              this.props.onExport(this.props.title);
            }}
          />
        )}
        {i18next.t(this.props.title)}
      </div>
    );
  }
}

export default WidgetPopupHeader;
