import React from 'react';

export function FiltersIcon(): JSX.Element {
  return (
    <svg fill='none' height='14' viewBox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M3.33333 4.92669C4.25381 4.92669 5 4.19144 5 3.28446C5 2.37748 4.25381 1.64223 3.33333 1.64223C2.41286 1.64223 1.66667 2.37748 1.66667 3.28446C1.66667 4.19144 2.41286 4.92669 3.33333 4.92669ZM3.33333 6.56891C5.17428 6.56891 6.66667 5.09841 6.66667 3.28446C6.66667 1.4705 5.17428 0 3.33333 0C1.49238 0 0 1.4705 0 3.28446C0 5.09841 1.49238 6.56891 3.33333 6.56891Z'
        // eslint-disable-next-line max-len
        fill='#4D4D4D'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M10.6667 9.07331C9.74619 9.07331 9 9.80856 9 10.7155C9 11.6225 9.74619 12.3578 10.6667 12.3578C11.5871 12.3578 12.3333 11.6225 12.3333 10.7155C12.3333 9.80856 11.5871 9.07331 10.6667 9.07331ZM10.6667 7.43108C8.82572 7.43108 7.33333 8.90159 7.33333 10.7155C7.33333 12.5295 8.82572 14 10.6667 14C12.5076 14 14 12.5295 14 10.7155C14 8.90159 12.5076 7.43108 10.6667 7.43108Z'
        // eslint-disable-next-line max-len
        fill='#4D4D4D'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M5 3.28446C5 2.74027 5.44772 2.29912 6 2.29912H12.6667C13.219 2.29912 13.6667 2.74027 13.6667 3.28446C13.6667 3.82864 13.219 4.26979 12.6667 4.26979H6C5.44772 4.26979 5 3.82864 5 3.28446Z'
        // eslint-disable-next-line max-len
        fill='#4D4D4D'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M9 10.7155C9 11.2597 8.55229 11.7009 8 11.7009L1.33333 11.7009C0.781048 11.7009 0.333333 11.2597 0.333333 10.7155C0.333333 10.1714 0.781049 9.7302 1.33333 9.7302H8C8.55229 9.7302 9 10.1714 9 10.7155Z'
        // eslint-disable-next-line max-len
        fill='#4D4D4D'
        fillRule='evenodd'
      />
    </svg>
  );
}
