import { Button as MUIButton, Menu as MUIMenu } from '@mui/material';
import styled from 'styled-components';

export const RebrandFilterButton = styled(MUIButton)`
  background: var(--color-brand-primary-white);
  outline: 1px solid var(--color-gray-60);
  box-sizing: border-box;
  border-radius: 6px;
  color: var(--color-gray-90);
  padding: var(--sizes-30) var(--sizes-50);
  font: var(--font-regular-14pt-semibold);
  text-transform: none;
  max-height: 44px;
  white-space: nowrap;

  .MuiButton-endIcon {
    div {
      font-size: 10px;
    }
  }

  :hover {
    background: var(--color-gray-20);
  }
`;

export const RebrandFilterMenu = styled(MUIMenu)`
  margin-top: 12px;

  .MuiPaper-root {
    background: var(--color-brand-primary-white);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    border-radius: 9px;
  }

  .MuiMenuItem-root {
    padding: var(--sizes-30) var(--sizes-70);
    font: var(--font-regular-14pt-normal);
  }

  .MuiMenuItem-root:hover {
    background: var(--color-gray-10);
  }
`;
