import { useRef, useCallback } from 'react';

type UsePaginateOnVisibleNodeParams = {
  fetchNextPage: () => void;
  isFetching: boolean;
  morePagesExist: boolean;
};
export function usePaginateOnVisibleNode({
  fetchNextPage,
  isFetching,
  morePagesExist,
}: UsePaginateOnVisibleNodeParams) {
  const observer = useRef<IntersectionObserver>();

  const triggerElementRef = useCallback(
    (node) => {
      if (isFetching) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        const observedCarIsVisible = entries[0].isIntersecting;
        if (observedCarIsVisible && morePagesExist) {
          void fetchNextPage();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchNextPage, isFetching, morePagesExist]
  );

  return triggerElementRef;
}
