import React from 'react';

import styled from 'styled-components';

import { Type } from 'src/app/components/lib/type';

import type { getPasswordStrengthLabel } from 'src/app/utils/password-check';

export type Label = ReturnType<typeof getPasswordStrengthLabel> | '';
type PasswordStrengthIndicatorProps = {
  label: Label;
};

const DEFAULT_BAR_COLOR = '--color-gray-20';
const WEAK_BAR_COLOR = '--color-red-50';
const SOMEWHAT_STRONG_COLOR = '--color-brand-secondary-terra';
const STRONG_COLOR = '--color-green-50';

function getFirstBarColorToken(label: Label) {
  switch (label) {
    case 'Weak':
      return WEAK_BAR_COLOR;
    case 'Somewhat strong':
      return SOMEWHAT_STRONG_COLOR;
    case 'Strong':
      return STRONG_COLOR;
    case '':
    default:
      return DEFAULT_BAR_COLOR;
  }
}

function getSecondBarColorToken(label: Label) {
  switch (label) {
    case 'Somewhat strong':
      return SOMEWHAT_STRONG_COLOR;
    case 'Strong':
      return STRONG_COLOR;
    case 'Weak':
    default:
      return DEFAULT_BAR_COLOR;
  }
}

function getThirdBarColorToken(label: Label) {
  switch (label) {
    case 'Strong':
      return STRONG_COLOR;
    case 'Weak':
    case 'Somewhat strong':
    default:
      return DEFAULT_BAR_COLOR;
  }
}

// The label empty state is why label is used to drive this component instead of the actual score
export function PasswordStrengthIndicator({ label }: PasswordStrengthIndicatorProps) {
  return (
    <Container>
      <IndicatorBarGroup>
        <IndicatorBar $colorToken={getFirstBarColorToken(label)} />
        <IndicatorBar $colorToken={getSecondBarColorToken(label)} />
        <IndicatorBar $colorToken={getThirdBarColorToken(label)} />
      </IndicatorBarGroup>
      {label !== '' && (
        <Type px={12} weight='semibold'>
          {label}
        </Type>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--sizes-30);
  grid-column: 2 / span 3; // probably would be better to have this controlled by the parent, but this is okay for now
  min-height: var(--sizes-40);
`;

const IndicatorBarGroup = styled.div`
  display: flex;
  gap: var(--sizes-10);
`;

const IndicatorBar = styled.div<{ $colorToken: string }>`
  border-radius: 100px;
  height: 4px;
  width: 55px;
  background-color: ${({ $colorToken }) => `var(${$colorToken})`}; // 😏
`;
