import React from 'react';

import styled from 'styled-components';

import { WarningIcon } from 'src/app/components/icons/warning-icon';
import { Input } from 'src/app/components/lib/input';
import { Modal } from 'src/app/components/lib/modal';

type OrgManagementModalProps = {
  navigateToOrgManagement: (password: string) => void;
  onClose: () => void;
};

export function OrgManagementModal({ onClose, navigateToOrgManagement }: OrgManagementModalProps) {
  const [password, setPassword] = React.useState<string>('');

  return (
    <Modal
      Content={
        <div>
          <StyledWarning>
            <WarningIcon />
            <StyledWarningText>
              <span>Warning.</span> Making changes here should be carefully considered and reviewed to avoid any
              unintended consequences.
            </StyledWarningText>
          </StyledWarning>
          <div>
            <StyledInput
              autoFocus
              automationId='org-management-modal-password-input'
              label='Enter your password to continue'
              labelPlacement='top'
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      }
      open
      primaryActions={[
        {
          label: 'Continue',
          automationId: 'org-management-modal-continue-button',
          onClick: () => navigateToOrgManagement(password),
          disabled: !password.trim(),
        },
      ]}
      title='Enter password to continue'
      onClose={onClose}
    />
  );
}

const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: var(--color-yellow-10);
  border-radius: 8px;
  padding: var(--sizes-30);
`;

const StyledWarningText = styled.p`
  margin-left: 10px;
  color: var(--color-yellow-100);

  span {
    font-weight: 600;
  }
`;

const StyledInput = styled(Input)`
  margin-top: var(--sizes-70);
  margin-bottom: var(--sizes-40);

  label {
    font-size: var(--fontSizes-14);
    font-weight: 600;
    margin-bottom: var(--sizes-10);
    color: var(--color-grayscale-black);
    margin-bottom: 12px;
  }
`;
