import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { mediumButtonStyles, secondary } from 'src/app/components/lib/button';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { QueryKey } from '@tanstack/react-query';

type RefreshButtonProps = {
  isFetching?: boolean;
  onClick?: () => void;
  queryKey?: QueryKey;
};

export function RefreshButton({ onClick, isFetching, queryKey }: RefreshButtonProps) {
  const darkMode = useDarkMode();
  const queryClient = useQueryClient();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }

    if (queryKey) {
      void queryClient.invalidateQueries({ queryKey });
    }
  };

  const fill = darkMode ? '#ffffff' : 'var(--color-gray-100)';
  const stroke = darkMode ? 'white' : 'black';

  return (
    <StyledRefreshButton
      $darkMode={darkMode}
      data-testid='table-refresh-button'
      disabled={isFetching}
      onClick={handleOnClick}
    >
      <CSSTransition classNames='rotating' in={isFetching} timeout={1000}>
        <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
          <path
            clipRule='evenodd'
            d='M15.1513 9.22439C15.2812 9.30237 15.3749 9.42877 15.4116 9.5758L15.9831 11.8615C16.0596 12.1677 15.8735 12.4779 15.5673 12.5545C15.2611 12.631 14.9509 12.4449 14.8743 12.1387L14.4415 10.4073L12.7102 10.8402C12.404 10.9167 12.0937 10.7306 12.0172 10.4244C11.9407 10.1182 12.1268 9.80799 12.433 9.73145L14.7187 9.16002C14.8657 9.12327 15.0213 9.14642 15.1513 9.22439Z'
            fill={fill}
            fillRule='evenodd'
          />
          <path
            clipRule='evenodd'
            d='M15.0474 9.17553C15.345 9.28055 15.5012 9.60695 15.3961 9.90455C14.851 11.4494 13.8607 12.7982 12.5502 13.7811C11.2396 14.764 9.66747 15.337 8.03181 15.4278C8.0213 15.4284 8.01077 15.4287 8.00024 15.4287C6.47505 15.4289 4.98673 14.9598 3.73746 14.0848C2.48818 13.2099 1.53849 11.9716 1.01738 10.5382C0.909553 10.2416 1.06258 9.91375 1.35918 9.80592C1.65578 9.69809 1.98363 9.85112 2.09146 10.1477C2.5324 11.3606 3.33598 12.4084 4.39306 13.1487C5.44584 13.886 6.69924 14.2828 7.98428 14.2858C9.38896 14.2047 10.7387 13.7112 11.8645 12.8668C12.9945 12.0193 13.8484 10.8563 14.3184 9.52423C14.4234 9.22662 14.7498 9.07051 15.0474 9.17553Z'
            fill={fill}
            fillRule='evenodd'
          />
          <path
            clipRule='evenodd'
            d='M0.432972 3.44574C0.739141 3.3692 1.04939 3.55534 1.12593 3.86151L1.55877 5.59286L3.29011 5.16002C3.59628 5.08348 3.90653 5.26963 3.98307 5.5758C4.05962 5.88197 3.87347 6.19222 3.5673 6.26876L1.28158 6.84019C1.13456 6.87694 0.97895 6.85379 0.848995 6.77582C0.71904 6.69784 0.625382 6.57144 0.588625 6.42441L0.0171966 4.1387C-0.0593455 3.83253 0.126803 3.52228 0.432972 3.44574Z'
            fill={fill}
            fillRule='evenodd'
          />
          <path
            clipRule='evenodd'
            d='M4.17754 3.17906C3.02438 4.07955 2.11828 5.28109 1.67974 6.48185C1.57148 6.77829 1.2434 6.93084 0.946961 6.82257C0.650522 6.71431 0.497976 6.38623 0.606241 6.08979C1.1277 4.66197 2.17589 3.29209 3.47415 2.2783C4.77195 1.26487 6.36655 0.571533 8.00014 0.571533H8.00174C9.53437 0.575843 11.0281 1.05409 12.2783 1.94072C13.5284 2.82734 14.4737 4.07894 14.9846 5.52392C15.0898 5.82146 14.9339 6.14794 14.6363 6.25314C14.3388 6.35834 14.0123 6.20241 13.9071 5.90486C13.4748 4.68219 12.675 3.62315 11.6171 2.87293C10.5596 2.12286 9.29589 1.7182 7.99934 1.71439C6.6846 1.71462 5.33095 2.27838 4.17754 3.17906Z'
            fill={fill}
            fillRule='evenodd'
          />
          <path
            clipRule='evenodd'
            d='M15.1513 9.22439C15.2812 9.30237 15.3749 9.42877 15.4116 9.5758L15.9831 11.8615C16.0596 12.1677 15.8735 12.4779 15.5673 12.5545C15.2611 12.631 14.9509 12.4449 14.8743 12.1387L14.4415 10.4073L12.7102 10.8402C12.404 10.9167 12.0937 10.7306 12.0172 10.4244C11.9407 10.1182 12.1268 9.80799 12.433 9.73145L14.7187 9.16002C14.8657 9.12327 15.0213 9.14642 15.1513 9.22439Z'
            fillRule='evenodd'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.5'
          />
          <path
            clipRule='evenodd'
            d='M15.0474 9.17553C15.345 9.28055 15.5012 9.60695 15.3961 9.90455C14.851 11.4494 13.8607 12.7982 12.5502 13.7811C11.2396 14.764 9.66747 15.337 8.03181 15.4278C8.0213 15.4284 8.01077 15.4287 8.00024 15.4287C6.47505 15.4289 4.98673 14.9598 3.73746 14.0848C2.48818 13.2099 1.53849 11.9716 1.01738 10.5382C0.909553 10.2416 1.06258 9.91375 1.35918 9.80592C1.65578 9.69809 1.98363 9.85112 2.09146 10.1477C2.5324 11.3606 3.33598 12.4084 4.39306 13.1487C5.44584 13.886 6.69924 14.2828 7.98428 14.2858C9.38896 14.2047 10.7387 13.7112 11.8645 12.8668C12.9945 12.0193 13.8484 10.8563 14.3184 9.52423C14.4234 9.22662 14.7498 9.07051 15.0474 9.17553Z'
            fillRule='evenodd'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.5'
          />
          <path
            clipRule='evenodd'
            d='M0.432972 3.44574C0.739141 3.3692 1.04939 3.55534 1.12593 3.86151L1.55877 5.59286L3.29011 5.16002C3.59628 5.08348 3.90653 5.26963 3.98307 5.5758C4.05962 5.88197 3.87347 6.19222 3.5673 6.26876L1.28158 6.84019C1.13456 6.87694 0.97895 6.85379 0.848995 6.77582C0.71904 6.69784 0.625382 6.57144 0.588625 6.42441L0.0171966 4.1387C-0.0593455 3.83253 0.126803 3.52228 0.432972 3.44574Z'
            fillRule='evenodd'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.5'
          />
          <path
            clipRule='evenodd'
            d='M4.17754 3.17906C3.02438 4.07955 2.11828 5.28109 1.67974 6.48185C1.57148 6.77829 1.2434 6.93084 0.946961 6.82257C0.650522 6.71431 0.497976 6.38623 0.606241 6.08979C1.1277 4.66197 2.17589 3.29209 3.47415 2.2783C4.77195 1.26487 6.36655 0.571533 8.00014 0.571533H8.00174C9.53437 0.575843 11.0281 1.05409 12.2783 1.94072C13.5284 2.82734 14.4737 4.07894 14.9846 5.52392C15.0898 5.82146 14.9339 6.14794 14.6363 6.25314C14.3388 6.35834 14.0123 6.20241 13.9071 5.90486C13.4748 4.68219 12.675 3.62315 11.6171 2.87293C10.5596 2.12286 9.29589 1.7182 7.99934 1.71439C6.6846 1.71462 5.33095 2.27838 4.17754 3.17906Z'
            fillRule='evenodd'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.5'
          />
        </svg>
      </CSSTransition>
    </StyledRefreshButton>
  );
}

const StyledRefreshButton = styled.button<{ $darkMode: boolean }>`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  ${mediumButtonStyles}
  ${({ $darkMode }) => secondary($darkMode)}

  .rotating-enter {
    transform: rotate(0deg);
  }
  .rotating-enter-active {
    transform: rotate(360deg);
    transition-property: transform;
    transition-duration: 1000ms;
  }
  .rotating-exit-active {
    transform: rotate(360deg);
    transition-property: transform;
    transition-duration: 1000ms;
  }
`;
