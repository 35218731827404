import React from 'react';

import { Button, Menu, ClickAwayListener, Box } from '@mui/material';
import i18next from 'i18next';
import styled from 'styled-components';

import { CogIcon } from 'assets/icons/cog-icon';
import { CaretDown } from 'src/app/components/icons/caret-down';
import { Search } from 'src/app/components/lib/search';
import { useViewOrgManagementEnabled } from 'src/app/hooks/use-view-org-management';

export type LabelData = {
  locName?: string;
  lspName?: string;
  orgName?: string;
};

export type LspLocDropdownProps = {
  children: React.ReactNode;
  filteredSearchTerm: string | null;
  isOpen: boolean;
  isOrgManagementRoute: boolean;
  labelData: LabelData;
  openOrgManagementModal: () => void;
  setFilteredSearchTerm: (value: string) => void;
  setIsOpen: (value: boolean) => void;
};

export function LspLocDropdown({
  children,
  isOrgManagementRoute,
  labelData,
  filteredSearchTerm,
  setFilteredSearchTerm,
  openOrgManagementModal,
  isOpen,
  setIsOpen,
}: LspLocDropdownProps) {
  const canUseViewOrgManagement = useViewOrgManagementEnabled();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleOrgManagementClick = () => {
    handleClose();
    openOrgManagementModal();
  };

  React.useEffect(() => {
    if (!isOpen) {
      setAnchorEl(null);
    }
  }, [isOpen]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <StyledButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          data-testid='location_dropdown_button'
          endIcon={<CaretDown fill='var(--color-brand-primary-white)' />}
          variant='text'
          onClick={handleClick}
        >
          {labelData?.orgName && labelData.orgName}
          {!labelData?.orgName && (
            <div data-testid='location_dropdown_label'>
              {labelData.lspName}
              <span style={{ margin: '0 8px' }}>|</span>
              {labelData.locName}
            </div>
          )}
        </StyledButton>
        <Menu
          anchorEl={anchorEl}
          MenuListProps={{
            sx: {
              padding: 0,
              width: '316px',
              maxHeight: '420px',
              overflowY: 'auto',
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <SearchBox>
            <Search
              automationId='location_dropdown_search'
              placeholder={i18next.t('Search LSPs and locations...')}
              value={filteredSearchTerm ?? ''}
              onChange={(e) => setFilteredSearchTerm(e.target.value)}
            />
          </SearchBox>
          {children}
          {!isOrgManagementRoute && canUseViewOrgManagement && (
            <FooterBox>
              <DropdownMenuFooter>
                <OrgManagementButton data-testid='manage-org-btn' onClick={handleOrgManagementClick}>
                  <CogIcon />
                  Manage organization
                </OrgManagementButton>
              </DropdownMenuFooter>
            </FooterBox>
          )}
        </Menu>
      </div>
    </ClickAwayListener>
  );
}

const StyledButton = styled(Button)`
  color: var(--color-brand-primary-white);
  font: var(--font-small-13pt-normal);
  text-transform: none;

  &:focus {
    outline: none;
  }
`;

const DropdownMenuFooter = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > button {
    padding: 0;
    min-width: auto;
  }
`;

const OrgManagementButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--color-gray-70);
  color: var(--color-grayscale-black);
  cursor: pointer;
  font: var(--font-extra-small-12pt-semibold);
  padding: 12px !important;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
  }
`;

const SearchBox = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--color-brand-primary-white);
  padding: var(--sizes-30) var(--sizes-30) 0 var(--sizes-30);
`;

const FooterBox = styled(Box)`
  position: sticky;
  bottom: 0;
  background-color: var(--color-brand-primary-white);
  padding: var(--sizes-30);
`;
