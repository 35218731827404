import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { TextField } from 'src/app/components/fields/text-field';
import { Button } from 'src/app/components/lib/button';
import { BackIconButton } from 'src/app/components/lib/heading';
import { BackButtonIcon } from 'src/app/components/lib/heading/back-icon';
import { useForgotPasswordMutation } from 'src/app/queries/posv3/user/forgot-password';

import { AuthFormContainer, StyledHeading, AuthFormSection } from './shared';

import type { SubmitHandler } from 'react-hook-form';

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
});

type ForgotPasswordFormData = yup.InferType<typeof schema>;

export function ForgotPassword() {
  const navigate = useNavigate();
  const form = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(schema),
  });
  const { isDirty, isValid } = form.formState;
  const { mutateAsync, isLoading } = useForgotPasswordMutation();

  const handleFormSubmit: SubmitHandler<ForgotPasswordFormData> = async (data) => {
    await mutateAsync({ UserName: data.username });
    form.reset();
    navigate('/login');
  };

  return (
    <AuthFormContainer>
      <BackIconButton onClick={() => navigate('/login')}>
        <BackButtonIcon />
      </BackIconButton>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleFormSubmit)}>
          <AuthFormSection>
            <StyledHeading
              automationId='auth_heading_login'
              subheader='Enter the username associated with your account and we’ll send an email with instructions on how to reset your password.'
            >
              Forgot password
            </StyledHeading>

            <TextField name='username' placeholder='Username' required />
          </AuthFormSection>
          <Button disabled={!isDirty || !isValid || isLoading} label='Send' loading={isLoading} type='submit' />
        </form>
      </FormProvider>
    </AuthFormContainer>
  );
}
