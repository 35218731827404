import React, { useState } from 'react';

import { gridSelectionStateSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

import { CaretDown } from 'src/app/components/icons';
import { StyledActionsMenu } from 'src/app/components/lib/actions';
import { Button } from 'src/app/components/lib/button';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { ExportTableModal } from 'src/app/components/modals/modals/export-table-modal';
import { PrintTableModal } from 'src/app/components/modals/modals/print-table-modal';
import { getDataGridValuesFromRef } from 'src/app/components/modals/utils';
import { useDarkMode } from 'src/app/state/dark-mode';
import { Modal, useModal } from 'src/app/state/modals';

import { useDataGridWrapperContext } from '../wrapper-context';

import { BulkActionsContainer } from './bulk-actions-header-rebrand.styles';

import type { ModalState } from 'src/app/state/modals';

export type BulkAction = {
  action: (selectedRows: []) => void;
  automationId?: string;
  disabled?: boolean;
  id: string;
  label: string;
  tooltip?: string;
  visible?: boolean;
};

export type BulkActionsAndModalsContainerProps = {
  bulkActions: BulkAction[];
  buttonAutomationId?: string;
};

export function BulkActionsAndModalsContainer({ buttonAutomationId, bulkActions }: BulkActionsAndModalsContainerProps) {
  const darkMode = useDarkMode();
  // Grid hooks:
  const apiRef = useGridApiContext();
  const { tableName = '' } = useDataGridWrapperContext();
  const selection = useGridSelector(apiRef, gridSelectionStateSelector);

  // Menu state:
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Event handlers:
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuItemClick = (bulkAction) => {
    const selectedRows = apiRef.current.state.selection.map(
      (selectedId) => apiRef.current.state.rows.idRowsLookup[selectedId]
    );
    bulkAction.action(selectedRows);
    handleMenuClose();
  };

  // Computed state:
  const isMenuOpen = Boolean(anchorEl);
  const hasSelection = selection.length > 0;
  const buttonSelectionCount = hasSelection ? ` (${selection.length})` : '';
  const bulkActionsButtonLabel = `Bulk actions${buttonSelectionCount}`;
  const hasBulkActions = bulkActions?.length > 0;

  const exportTableModal = useModal(Modal.exportTable);
  const printTableModal = useModal(Modal.printTable);

  const renderModal = (modalState: ModalState, tableName: string): boolean => {
    const modalIsOpen = !!modalState.state;
    const modalStateTableName = modalState.state?.tableName;
    // If the modal is open and there is no tableName, render the modal
    const shouldRenderNoTableName = modalIsOpen && !modalStateTableName;
    // If the modal is open and the tableName matches the current tableName, render the modal
    // Since this hook is being consumed by multiple tables, we need to ensure the modal is only rendered once
    const shouldRenderMatchingTableName = modalIsOpen && modalStateTableName === tableName;
    return shouldRenderNoTableName || shouldRenderMatchingTableName;
  };

  const renderPrintModal = renderModal(printTableModal, tableName);
  const renderExportModal = renderModal(exportTableModal, tableName);

  return (
    <>
      {hasBulkActions && hasSelection && (
        <BulkActionsContainer>
          <Button
            automationId={buttonAutomationId}
            buttonSize='small'
            endIcon={<CaretDown fill='var(--color-brand-primary-white)' />}
            label={bulkActionsButtonLabel}
            onClick={handleMenuClick}
          />
          <StyledActionsMenu $darkMode={darkMode} anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
            {bulkActions.map((bulkAction) =>
              bulkAction.visible ? (
                <MenuItem
                  automationId={bulkAction.automationId}
                  disabled={bulkAction.disabled}
                  key={bulkAction.id}
                  tooltip={bulkAction.tooltip}
                  value={bulkAction.id}
                  onClick={() => handleMenuItemClick(bulkAction)}
                >
                  {bulkAction.label}
                </MenuItem>
              ) : null
            )}
          </StyledActionsMenu>
        </BulkActionsContainer>
      )}

      {renderExportModal && (
        <ExportTableModal
          {...exportTableModal.state}
          gridValues={getDataGridValuesFromRef(apiRef)}
          open
          onClose={exportTableModal.closeModal}
        />
      )}

      {renderPrintModal && (
        <PrintTableModal
          {...printTableModal.state}
          gridValues={getDataGridValuesFromRef(apiRef)}
          open
          onClose={printTableModal.closeModal}
        />
      )}
    </>
  );
}
