import { useMutation, useQueryClient } from '@tanstack/react-query';

import { endPoints as Endpoints } from 'src/app_deprecated/constants/UserConstants';

import { userSettingsQueryKey } from 'src/app/queries/settings/get-user-settings';

import { postData } from '..';

export const UPDATE_ORG_PIN_LENGTH = 'api/org/pinlength/update';

export type UserTableSettingsPayload = {
  Setting: string; // table name
  SettingType: string; // ex: 'table
  value: string; // stringified JSON
};

export type UserTableSettingsResponse = {
  Data: null;
  Message: string | null;
  Result: boolean;
};

export function useUserTableSettings() {
  const queryClient = useQueryClient();
  const endpoint = Endpoints.SET_SINGLE_USER_SETTING;
  return useMutation<UserTableSettingsResponse, unknown, UserTableSettingsPayload>({
    mutationKey: ['update-user-table-settings'],
    mutationFn: (payload) => postData({ endpoint, payload }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: userSettingsQueryKey });
    },
  });
}
