import styled, { css } from 'styled-components';

import { BaseInput } from '../base-input';

export const FormInput = styled(BaseInput)<{
  $fullWidth?: boolean;
  $showCharacterCount?: boolean;
  $tableInput?: boolean;
  $width?: string;
}>`
  grid-column: ${({ $fullWidth }) => ($fullWidth ? 'span 6' : '2 / span 3')};
  height: auto;
  width: ${({ $width }) => $width ?? '100%'};

  .MuiFormHelperText-root {
    color: var(--color-red-60);
  }

  .MuiInputBase-input {
    padding: var(--sizes-30) var(--sizes-50);
    text-overflow: ellipsis;
  }

  .MuiInputBase-root {
    ${({ $showCharacterCount }) => $showCharacterCount && 'padding-bottom: var(--sizes-80)'};
  }

  & .MuiInputAdornment-positionStart .MuiTypography-root {
    font: var(--font-regular-14pt-semibold);
  }

  ${({ $tableInput }) =>
    $tableInput &&
    css`
      background-color: white;
      height: var(--sizes-70);

      .MuiInputBase-input {
        padding: var(--sizes-20) var(--sizes-40);
        font: var(--font-extra-small-12pt-normal);
      }

      .MuiInputBase-adornedEnd {
        .MuiInputBase-input {
          padding-left: 0;
        }
      }
    `}
`;
