import { atom } from 'recoil';

export type Analytics = {
  dangerousAllowMutability: boolean;
  key: 'analytics';
  track: (event: string, data?: Record<string, unknown>, retryCount?: number) => void;
};
// state
export const analyticsAtom = atom<Analytics | undefined>({
  key: 'analytics',
  default: undefined,
  dangerouslyAllowMutability: true,
});
