/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useContext, useEffect, useRef } from 'react';

import i18next from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FilterSelect } from 'src/app/components/lib/filter-select';
import { MultiFilterSelect } from 'src/app/components/lib/multi-filter-select';
import { Search } from 'src/app/components/lib/search';

import { ControlBarRow } from './control-bar.styles';

import type { SxProps, ButtonProps as MUIButtonProps } from '@mui/material';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown/menu/index';
import type { Option } from 'src/app/components/lib/filter-select';

const ControlBarContext = React.createContext<ControlBarContextType>(null);

type ResetFunction = {
  functionDefinition: () => void;
  id: string;
};

type ControlBarContextType = {
  addResetFunction: (resetFunction: ResetFunction) => void;
  resetFunctions: ResetFunction[];
};

type ControlBarType = {
  children: React.ReactNode;
  isResponsive?: boolean;
  margin?: string;
  styleOverrides?: any;
};

type ControlSearchBarType = {
  automationId?: string;
  disabled?: boolean;
  forcedValue?: string;
  initialValue?: string | null;
  onChange: (searchString: string) => void;
  placeholder?: string;
  sx?: SxProps;
};

type ControlFilterDropdownProps<TOptionValue extends string> = {
  automationId?: string;
  ButtonProps?: MUIButtonProps;
  disabled?: boolean;
  disableDefaultFooterContent?: boolean;
  filterId?: string;
  label: string;
  onChange: (updatedFilter: Option<TOptionValue>) => void;
  onReset?: () => void;
  options: Option<TOptionValue>[];
  resetButtonAutomationId?: string;
  resetLabel?: string;
  selected?: string;
};

type ControlMultiFilterDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  filterId?: string;
  label: string;
  onChange: (updatedFilter: DropdownMenuOption[]) => void;
  onReset?: () => void;
  options: DropdownMenuOption[];
  selected: DropdownMenuOption[];
  sortOptions?: boolean;
};

export function ControlBar({ children, margin, styleOverrides = {}, ...props }: ControlBarType) {
  const [resetFunctions, setResetFunctions] = useState<ResetFunction[]>([]);

  function addResetFunction(resetFunction: ResetFunction) {
    setResetFunctions([...resetFunctions, resetFunction]);
  }

  return (
    <ControlBarContext.Provider
      value={{
        // chips,
        // addChip,
        // removeChip,
        resetFunctions,
        addResetFunction,
      }}
    >
      <ControlBarRow $margin={margin} $overrides={styleOverrides} {...props}>
        {children}
      </ControlBarRow>
    </ControlBarContext.Provider>
  );
}

export function ControlSearchBox({
  automationId,
  forcedValue,
  onChange,
  placeholder,
  initialValue,
  sx,
  disabled,
}: ControlSearchBarType) {
  const [searchString, setSearchString] = useState(initialValue);
  const [overrideForced, setOverrideForced] = useState(false);
  const flags = useFlags();
  const rollBackScannerRegistration = flags['pos.backoffice.register_scanner.rollback'];

  const forcedValueRef = useRef(forcedValue);
  useEffect(() => {
    if (rollBackScannerRegistration) {
      return;
    }
    if (forcedValue && forcedValue !== searchString && !overrideForced && forcedValue !== forcedValueRef.current) {
      setSearchString(forcedValue);
      forcedValueRef.current = forcedValue;
    }
    if ((forcedValue === undefined || forcedValue === '') && forcedValueRef.current !== undefined) {
      setSearchString('');
      forcedValueRef.current = undefined;
    }
    setOverrideForced(false);
  }, [forcedValue, searchString, rollBackScannerRegistration, overrideForced]);

  function handleChange({ target }) {
    const { value } = target;
    if (!rollBackScannerRegistration) {
      setOverrideForced(true);
    }
    setSearchString(value);
    onChange(value);
  }

  return (
    <Search
      automationId={automationId}
      disabled={disabled}
      placeholder={i18next.t(placeholder)}
      sx={sx}
      value={searchString ?? ''}
      onChange={handleChange}
    />
  );
}

export function ControlFilterDropdown<TOptionValue extends string>({
  automationId,
  onChange,
  options,
  label,
  filterId,
  onReset,
  selected,
  disabled,
  ButtonProps,
  resetButtonAutomationId,
  resetLabel,
  disableDefaultFooterContent,
}: ControlFilterDropdownProps<TOptionValue>) {
  const { resetFunctions, addResetFunction } = useContext(ControlBarContext);

  const sortedOptions = options.sort((a, b) => a.label?.localeCompare(b.label));
  const selectedOption = options.find((option) => option.value === selected);
  const [activeFilter, setActiveFilter] = useState(selected ? [selectedOption?.label] : []);

  function handleMenuItemClick(option) {
    if (!option.id) {
      setActiveFilter([]);
      onReset();
      onChange(option);
      return;
    }

    setActiveFilter([option.label]);
    onChange(option);
  }

  useEffect(() => {
    if (!resetFunctions.find((f) => f.id === filterId)) {
      addResetFunction({ functionDefinition: onReset, id: filterId });
    }
  }, []);

  useEffect(() => {
    if (!selected) {
      setActiveFilter([]);
    }
  }, [selected]);

  useEffect(() => {
    if (selected) {
      const opt = options.find((option) => option.value === selected);
      setActiveFilter([opt?.label]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <FilterSelect
      activeFilter={activeFilter}
      automationId={automationId}
      ButtonProps={ButtonProps}
      disabled={disabled}
      disableDefaultFooterContent={disableDefaultFooterContent}
      handleOnChange={handleMenuItemClick}
      label={label}
      options={sortedOptions}
      resetButtonAutomationId={resetButtonAutomationId}
      resetLabel={resetLabel ?? `Clear ${label.toLowerCase()} filter`}
    />
  );
}

export function ControlMultiFilterDropdown({
  automationId,
  onChange,
  options,
  label,
  filterId,
  onReset,
  selected,
  sortOptions = true,
  disabled,
}: ControlMultiFilterDropdownProps) {
  const { resetFunctions, addResetFunction } = useContext(ControlBarContext);

  const sortedOptions = sortOptions ? options.sort((a, b) => a.label.localeCompare(b.label)) : options;

  useEffect(() => {
    if (!resetFunctions.find((f) => f.id === filterId)) {
      addResetFunction({ functionDefinition: onReset, id: filterId });
    }
  }, []);

  return (
    <MultiFilterSelect
      activeFilter={selected}
      automationId={automationId}
      disabled={disabled}
      label={label}
      options={sortedOptions}
      onChange={onChange}
    />
  );
}
