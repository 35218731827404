import React from 'react';

import { useRecoilValue } from 'recoil';

import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { labelsAtom } from 'src/app/state/labels';

type LabelDropdownProps = {
  automationId?: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange: (value: number | string) => void;
  placeHolder?: string;
  required?: boolean;
  type?: string;
  value?: number | string;
  width?: string;
};

export function LabelDropdown(props: LabelDropdownProps) {
  const {
    onChange,
    value,
    type,
    required = false,
    disabled = false,
    automationId = '',
    width,
    label = 'Label:',
    placeHolder = 'Select label',
    className,
  } = props;
  const { labels } = useRecoilValue(labelsAtom);

  const checkType = (labelType) => {
    if (!type) {
      return true;
    }

    return !!type && labelType === type;
  };

  const options = labels
    .filter((x) => checkType(x.Type))
    .map((x) => ({
      label: x.Name,
      id: String(x.LabelId),
    }));

  return (
    <DropdownSingle
      automationId={automationId}
      className={className}
      disabled={disabled}
      helpText={placeHolder}
      inputId='select-label'
      label={label}
      options={options.map(({ label: optionLabel, id }) => ({
        label: optionLabel,
        id,
      }))}
      required={required}
      setValue={(newVal) => onChange(newVal.id)}
      value={value}
      width={width}
    />
  );
}
