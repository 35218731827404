import _ from 'lodash';

/**
 * Used as the main translations object for all screens. Be careful about adding to these as they will affect all tables in various domains.
 * Some of these likely apply to reports instead of the whole app and can be removed if causing problems.
 */
const universalColumnTranslationsObj = {
  accts_addr1: 'Address',
  accts_city: 'City',
  accts_name: 'Name',
  accts_state: 'State',
  avgcart: 'Cart average',
  avgpercart: 'Avg. $/item',
  bench: 'Table',
  biotrackdt: 'Biotrack date',
  canceldate: 'Cancel date',
  cancelreason: 'Cancel reason',
  cashpaidback: 'Change',
  cnt: 'Qty',
  cultivationstage: 'Cultivation stage',
  dateofbirth: 'DOB',
  discdatefrom: 'From',
  discdateto: 'To',
  discountdescription: 'Description',
  dispensaryrestrictedroom: 'Sales floor',
  driverphone: 'Phone no',
  driverstateid: 'State ID #',
  emptycontwgt: 'Empty weight',
  fullcontwgt: 'Full weight',
  groupname: 'Group name',
  hiredate: 'Hired',
  idnumber: 'ID #',
  inventoryroom: 'Inventory room',
  islabelprinter: 'Label?',
  isreceiptprinter: 'Receipt?',
  lasttransdate: 'Last transaction',
  loyaltydiscount: 'Type',
  mastercategory: 'Master category',
  mjstateidno: 'MMJ ID',
  netcontwgt: 'Net weight',
  orderno: 'Title',
  ownerlocation: 'Location',
  ownername: 'Name',
  packageid: 'Package no',
  paidincash: 'Cash',
  paidincredit: 'Credit',
  paidindebit: 'Debit',
  paidinloyalty: 'Loyalty',
  patientdob: 'DOB',
  patientname: 'Customer',
  patientphone: 'Phone no',
  pcounter: 'Count',
  plantserialno: 'Serial no',
  plantstatus: 'Status',
  posdate: 'Date',
  posid: 'POS ID',
  posinvoiceddate: 'Date',
  posstatus: 'Status',
  postotalitems: 'Total items',
  pricepercent: 'Price percent',
  pricepoint: 'Price point (from)',
  pricepoint1: 'Price point (to)',
  printerid: 'ID',
  productcategory: 'Category',
  productdesc: 'Description',
  productoz: 'OZ',
  quarantineroom: 'Quarantine room',
  receiptno: 'Receipt #',
  remainingqty: 'Remaining qty',
  seedcnt: 'Count',
  soldby: 'Sold by',
  startbalance: 'Start balance',
  totalinvoice: 'Total',
  transactiondate: 'Date',
  voiddate: 'Void date',
  waitingroom: 'Waiting room',
  whseproducts_description: 'Description',
  zone: 'Location',
};

/**
 * Transforms a column's name into a formatted string and replaces the column name if a translation exists.
 * @param columnName The current column name to transform
 * @param overrideColumnTranslations The base column translations object will be used for the transformation unless this object is provided. If provided preference will be given to the override.
 * @returns a formatted column name
 */
export function getAutoColumnHeaderName(
  { columnName }: { columnName: string },
  overrideColumnTranslations?: object
): string {
  const lowerCaseColumnName = columnName.toLowerCase().replace(/\s+/g, '');
  let columnHeader: string;
  if (overrideColumnTranslations) {
    columnHeader = overrideColumnTranslations[lowerCaseColumnName];
    if (columnHeader) {
      return columnHeader;
    }
  }

  return universalColumnTranslationsObj[lowerCaseColumnName] ?? _.upperFirst(_.lowerCase(columnName));
}
