import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

/**
 * In conjuction with ControlGroup, margin should never need to be passed in
 */
export const ControlSpacer = styled.div<{ margin?: string }>`
  min-width: 1px;
  max-width: 1px;
  height: 44px;
  background-color: var(--color-gray-20);
  margin: ${({ margin }) => margin || '0 8px'};
`;

/**
 * In conjunction with ControlSpacer, all items within a group should have a gap of 8px
 * with the exception of Spacers, which should have 16px total.
 * @param grow: can be used to force a group to take up all available space
 */
export const ControlGroup = styled.div<{ gap?: string; grow?: boolean; isResponsive?: boolean }>`
  display: flex;
  gap: ${({ gap }) => gap || '8px'};
  ${({ grow }) => grow && 'flex-grow: 1;'}
  ${({ isResponsive }) => isResponsive && isResponsiveCSS}
  ${({ isResponsive }) => isResponsive && `.multi-filter-select-button, .filter-select-button { flex-grow: 1; }`}
`;

export const ControlBarRow = styled.div<{ $margin?: string; $overrides?: any }>`
  ${({ $margin, $overrides }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${$margin ?? '0 0 20px'};
    width: 100%;
    overflow: visible;

    ${{ ...$overrides }}
  `}
`;

const isResponsiveCSS = css`
  ${down('smallDesktop')} {
    flex-wrap: wrap;
    gap: var(--sizes-20);
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;
