import styled from 'styled-components';

import type { CSSObject } from 'styled-components';

export const ValidityErrorMessage = styled.div<{ $overrides?: CSSObject }>(({ $overrides }) => ({
  font: 'var(--font-small-13pt-semibold)',
  borderRadius: '6px',
  backgroundColor: 'var(--color-red-10)',
  color: 'var(--color-red-60)',
  padding: 'var(--sizes-40) var(--sizes-60)',
  marginTop: 'var(--sizes-50)',
  width: '536px',
  ul: {
    listStylePosition: 'inside',
  },
  ...$overrides,
}));

// TODO-BACKOFFICE: Consolidate below and above in a Message with props for error, info, warn and success variants
export const InfoMessage = styled.div<{ $overrides?: CSSObject }>(({ $overrides }) => ({
  font: 'var(--font-regular-14pt-normal)',
  borderRadius: '10px',
  backgroundColor: 'var(--color-blue-10)',
  color: 'var(--color-opal-60)',
  padding: 'var(--sizes-50) var(--sizes-60)',
  ul: {
    listStylePosition: 'inside',
  },
  ...$overrides,
}));

export const WarnMessage = styled.div<{ $overrides?: CSSObject }>(({ $overrides }) => ({
  font: 'var(--font-small-13pt-semibold)',
  borderRadius: '6px',
  backgroundColor: 'var(--color-yellow-10)',
  color: 'var(--color-yellow-100)',
  padding: 'var(--sizes-40) var(--sizes-60)',
  marginTop: 'var(--sizes-50)',
  width: '536px',
  ul: {
    listStylePosition: 'inside',
  },
  ...$overrides,
}));
