import type React from 'react';

import i18next from 'i18next';
import uuid from 'uuid/v1';

import TableLogic from 'src/app_deprecated/components/table/TableLogic';
import UserStore from 'src/app_deprecated/stores/UserStore';

import { gridPaginatedVisibleSortedGridRowIdsSelector, gridRowsLookupSelector } from 'src/app/components/lib/table';
import { formatDate } from 'src/app/utils/formatters';

import { ActionsFieldName } from '../lib/table/data-grid';

import type { GridColDef } from '../lib/table/data-grid';
import type { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import type { GridRowId, GridRowModel, GridStateColDef } from 'src/app/components/lib/table';

export function getLayout(
  data: any,
  columns: any,
  name?: string | undefined,
  hideColumnList?: Array<string> | undefined
) {
  const items = data.Data || data;
  const updatedItems = items.map((item) => ({ ...item, uuid: uuid() }));

  let layout = [];
  if (columns) {
    // eslint-disable-next-line guard-for-in
    for (const x in columns) {
      const c = columns[x];

      const tmp = {
        name: c.hasOwnProperty('title') ? x : c,
        title: i18next.t(c.headerName) || i18next.t(x),
        disableExport: c.disableExport || false,
        th: {
          style: (c.th && c.th.style) || {},
          format: (c.th && c.th.format) || false,
        },
        td: {
          style: (c.td && c.td.style) || {},
          format: (c.td && c.td.format) || false,
          formatType: (c.td && c.td.formatType) || false,
          wrapNewline: c.hasOwnProperty('wrapNewline') ? c.wrapNewline : false,
        },
        valueGetter: c.valueGetter || false,
        valueFormatter: c.valueFormatter || false,
        type: c.type || false,
        exportRender: c.exportRender || false,
        sort: {
          type: (c.sort && c.sort.type) || 'str',
          format: (c.sort && c.sort.format) || false,
          sortValue: c.sort && c.sort.sortValue,
        },
        visible: c.hasOwnProperty('hide') ? !c.hide : true,
        exportColumn: c.hasOwnProperty('exportColumn') ? c.exportColumn : true,
      };

      if (c.hasOwnProperty('maxWidth')) {
        tmp.th.style = Object.assign(tmp.th.style, { maxWidth: c.maxWidth, overflow: 'hidden' });
        tmp.td.style = Object.assign(tmp.td.style, { maxWidth: c.maxWidth, overflow: 'hidden' });
      }

      if (c.field !== ActionsFieldName) {
        layout.push(tmp);
      }
    }
  } else if (updatedItems.length > 0) {
    layout = Object.keys(updatedItems[0]);

    const hideColumn = hideColumnList ? hideColumnList.map((x) => x.toLowerCase()) : [];

    hideColumn.push('uuid');
    hideColumn.push('isselected');

    layout = layout
      .filter((x) => hideColumn.indexOf(x.toLowerCase()) === -1)
      .map((x) => ({
        name: x,
        title: TableLogic.translate(x),
        disableExport: false,
        th: {
          style: {},
          format: false,
        },
        td: {
          style: {},
          format: false,
        },
        valueGetter: false,
        valueFormatter: false,
        type: false,
        exportRender: false,
        sort: {
          type: 'str',
          format: false,
        },
        visible: true,
      }));
  }

  const preLayout = UserStore.getTableSettings(name) || false;
  for (let i = 0; i < layout.length; i++) {
    let order = i;
    let { visible } = layout[i];

    if (preLayout) {
      for (let j = 0; j < preLayout.layout.length; j++) {
        if (preLayout.layout[j].name === layout[i].name) {
          order = preLayout.layout[j].order;
          visible = preLayout.layout[j].visible;
        }
      }
    }

    layout[i].order = order;
    layout[i].align = TableLogic.tdAlign(layout[i].name.field);
    layout[i].visible = visible;
  }

  return layout;
}

export function doDownload(bytes, type, name) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([bytes], { type }));
  link.download = name;
  link.target = '_blank';
  link.click();
}

export function exportCSV(columns, data, excel = false, title = 'Report') {
  let colHeader = '';
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].print) {
      if (colHeader.length > 0) {
        colHeader += ',';
      }
      colHeader += `"${columns[i].title}"`;
    }
  }
  colHeader += '\n';

  let body = '';
  let printColDetected = false;
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < columns.length; j++) {
      let isCommaString = false;
      const renderCol = columns[j];
      const { type, valueFormatter, valueGetter } = renderCol;
      const cellValue = data[i][renderCol.name];

      const value = valueGetter ? valueGetter({ row: data[i], value: cellValue }) : cellValue;
      const hasValue = (cellValue && cellValue !== '' && cellValue !== 'null' && cellValue !== 'undefined') || value;

      // if we only have valueGetter, the result of calling that function should be the output
      let output: string = value ?? '';

      if (renderCol.print) {
        if (printColDetected && j > 0) {
          body += ',';
        }
        printColDetected = true;

        if (hasValue && (type === 'dateTime' || type === 'date' || TableLogic.formatAsDate(renderCol.name))) {
          output = formatDate(new Date(value), type === 'dateTime', UserStore.getState());
        }

        if (hasValue && valueFormatter) {
          output = valueFormatter({ value });
        }

        if (typeof output === 'string' && output) {
          output = output.replace(/"/g, '""');
          output = output.replace(/(?:\r\n|\r|\n)/g, ' ');

          if (output.includes(',') || output.includes('"')) {
            output = `"${output}"`;
            isCommaString = true;
          }
        }

        if (excel && !isCommaString) {
          output = `="${output}"`;
        }

        body += output;
      }
    }
    body += '\n';
    printColDetected = false;
  }

  const csv = colHeader + body;

  doDownload(csv, 'text/csv', title);
}

export function getComputedColumns(columns: GridColDef[], visibleColumns: GridColDef[]): GridColDef[] {
  const visibleColumnsInSortOrder = visibleColumns
    .map((vis) => columns.find((col) => vis.field === col.field))
    .filter(Boolean);

  return columns.map((column) => ({
    ...column,
    hide: !visibleColumnsInSortOrder.some((col) => col.field === column.field),
  }));
}

export const filterColumnsByList = (sortedCol: GridStateColDef[], selectiveColumnsList: string[]): GridColDef[] =>
  sortedCol.filter((col) => selectiveColumnsList.includes(col.field));

export type columnLayout = {
  align: string;
  exportRender: string;
  name: string;
  order: string;
  print: boolean;
  title: string;
  type: string;
  valueFormatter: string;
  valueGetter: string;
};

export type GridValues = {
  currentPageRows: any;
  selectedRows: Map<GridRowId, GridRowModel>;
  sortedColumns: GridStateColDef[];
  sortedFullData: GridRowModel[];
  visibleColumns: GridStateColDef[];
};

export const filterColumnsWithoutHeaders = (cols: GridStateColDef[]) => cols.filter((col) => !!col.headerName);

export function getDataGridValuesFromRef(gridApiRef: React.MutableRefObject<GridApiPro>): GridValues {
  const selectedRows = gridApiRef.current.getSelectedRows();
  const sortedColumns = gridApiRef.current.getAllColumns();
  const sortedFullData = gridApiRef.current.getSortedRows();
  const visibleColumns = gridApiRef.current.getVisibleColumns();

  const lookup = gridRowsLookupSelector(gridApiRef.current.state);
  const currentPageRowIds = gridPaginatedVisibleSortedGridRowIdsSelector(gridApiRef.current.state);
  const currentPageRows = currentPageRowIds.map((rowId) => lookup[rowId]);

  return {
    selectedRows,
    sortedColumns,
    sortedFullData,
    visibleColumns,
    currentPageRows,
  };
}
