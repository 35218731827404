/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import structuredClone from '@ungap/structured-clone';
import { difference, noop } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import UserActions from 'src/app_deprecated/actions/UserActions';

import { ArrowDown, ArrowUp, RebrandCheck, RebrandUncheck } from 'src/app/components/icons';
import { RowReorderingIcon } from 'src/app/components/icons/row-reordering-icon';
import { doDisableVirtualization } from 'src/app/components/lib/table/modal-table/overrides';
import {
  computeGridColumnDefinitions,
  getDefaultTableSetting,
  visibilityModelChanged,
} from 'src/app/components/lib/table/utils';
import { useDarkMode } from 'src/app/state/dark-mode';
import { settingsAtom } from 'src/app/state/settings';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { BulkActionsAndModalsContainer } from './bulk-actions-header-and-modals';
import { ColumnResizeIcon } from './column-separator-icon';
import { CustomCell } from './custom-cell';
import { CustomCheckbox } from './custom-checkbox';
import { CustomLoader } from './custom-loader';
import { CustomRow } from './custom-row';
import { Footer } from './footer';
import { NoResultsOverlay } from './no-results-overlay';
import { RebrandDataGrid, RebrandDataGridContainer } from './table-rebrand.styles';
import { DataGridWrapperContext } from './wrapper-context';

import type { DataGridContainerProps, DataGridContainerStyledProps, DataGridProps, DataGridStyledProps } from './types';
import type {
  DataGridProProps as MUIDataGridProps,
  GridCallbackDetails,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
  GridSelectionModel,
  GridSlotsComponentsProps,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

export type { GridColDef } from '@mui/x-data-grid-pro';

export * from './generate-row-actions';

export type DataGridComponents = MUIDataGridProps<GridValidRowModel>['components'];

export type BohDataGridProps = DataGridContainerProps &
  DataGridContainerStyledProps &
  DataGridProps<GridValidRowModel> &
  DataGridStyledProps & {
    bulkActionButtonAutomationId?: string;
    containerAutomationId?: string;
    disablePagination?: boolean;
    disableSorting?: boolean;
    // if using server side pagination, this is the callback to handle the selection model change
    onPaginatedSelectionModelChange?: (selectedRows: GridValidRowModel[]) => void;
  };

export type GridRowsDef = BohDataGridProps['rows'];

export const defaultRowsPerPageOptions = [10, 25, 50, 75, 100, 150, 250, 500, 1000];
export const ActionsFieldName = 'Actions';

export function DataGrid(props: BohDataGridProps) {
  const {
    apiRef: propsApiRef,
    autoHeight,
    bulkActions,
    bulkActionButtonAutomationId,
    columnVisibilityModel,
    containerAutomationId,
    disablePagination = false,
    disableSorting = false,
    displayBorder = false,
    experimentalFeatures,
    getRowHeight,
    grow = false,
    height,
    hideActionsColumn = false,
    hideColumnHeaders = false,
    highlightSelectedRow = false,
    initialState,
    minHeight,
    minWidth,
    onCellClick,
    onColumnVisibilityModelChange,
    initialPageSize,
    ignoreColumnOrder = false,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    rows = [],
    rowHeight: propsRowHeight,
    rowReordering,
    hideFooter,
    onRowClick,
    columns,
    checkboxSelection,
    disableSelectionOnClick,
    disableRowHoverHighlight = false,
    onSelectionModelChange = noop,
    onSortModelChange = noop,
    onRowOrderChange,
    name,
    noResultsHeading,
    noResultsSubheading,
    loading,
    paginationMode,
    selectionModel: selectionModelProp,
    onPageSizeChange,
    onPaginatedSelectionModelChange = noop,
    // keepNonExistentRowsSelected,
    usingServerTableControls = false,
    pinnedColumns,
  } = props;
  // This state variable is to persist the selected rows when using server side pagination
  const [bulkSelectedRows, setBulkSelectedRows] = useState<Map<number, { id: number }>>(
    new Map((rows as unknown as { id: number }[]).map((row) => [row.id ?? 0, row]))
  );
  const internalApiRef = useGridApiRef();
  // use a ref to the vendor DataGrid in this context if the consumer has not passed one to our wrapper
  const apiRef = propsApiRef || internalApiRef;

  const [searchParams] = useSearchParams();

  const { hasScrollX } = apiRef?.current?.getRootDimensions?.() ?? { hasScrollX: false };

  const [{ RowsPerTable }] = useRecoilState(settingsAtom);
  const user = useRecoilValue(userDispensariesAtom);
  const { tableSettings } = user;
  const currentTableSettings = tableSettings?.find((setting) => setting.table === name);
  const [gridColumnWidths, setGridColumnWidths] = useState(new Map<string, number>());
  const [hasHorizontalScrollbar, setHasHorizontalScrollbar] = useState(!loading && hasScrollX);
  const darkMode = useDarkMode();
  useEffect(() => {
    setHasHorizontalScrollbar(!loading && hasScrollX);
  }, [loading, hasScrollX]);

  // We have to check to see if the value is a string and coerce it to an integer if it is
  // TODO-BACKOFFICE: Find root cause in recoil
  const rowsPerTable: number = typeof RowsPerTable === 'string' ? parseInt(RowsPerTable, 10) : RowsPerTable;
  const userDefinedPageOption = rowsPerTable ? [rowsPerTable] : [];
  const adjustedRowsPerPageOptions = [...new Set([...rowsPerPageOptions, ...userDefinedPageOption])];

  const computedColumns = useMemo(
    () =>
      computeGridColumnDefinitions(
        apiRef,
        columns,
        rows,
        name,
        currentTableSettings,
        hideActionsColumn,
        gridColumnWidths,
        user,
        ignoreColumnOrder
      ),
    [
      columns,
      rows.length,
      name,
      currentTableSettings,
      hideActionsColumn,
      gridColumnWidths,
      user,
      name,
      ignoreColumnOrder,
    ]
  );

  // Check if `columnVisibilityModel` is set, if not derive model from GridColDef[]
  const initialColumnVisibilityModel: GridColumnVisibilityModel =
    columnVisibilityModel ?? Object.fromEntries(computedColumns.map((column) => [column.field, !column.hide]));
  const [workingColumnVisibilityModel, setWorkingColumnVisibilityModel] = useState(initialColumnVisibilityModel);

  useEffect(() => {
    if (visibilityModelChanged(initialColumnVisibilityModel, workingColumnVisibilityModel)) {
      setWorkingColumnVisibilityModel(initialColumnVisibilityModel);
    }
    // Here we reference the `props` that may change, but we actually read from initialColumnVisibilityModel since it
    // derives its value from these props in the correct format namely, `GridColumnVisibilityModel`
  }, [columnVisibilityModel, computedColumns]);

  const searchParamsPageSize = searchParams.get('pageSize');
  const [pageSize, setPageSize] = useState(searchParamsPageSize ?? initialPageSize ?? rowsPerTable);
  const [internalSelectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  // If the grid is server paginated, there is a discrepancy between the query params
  // and the current pageSize, and the pageSize has changed, update the pageSize value.
  // This happens when we update the query params outside this component,
  // such as when we change locations and remove all query params.
  useEffect(() => {
    const serverPaginated = paginationMode === 'server';
    const queryParamDiscrepancy = searchParamsPageSize !== pageSize.toString();
    const pageSizeToSet = searchParamsPageSize ?? initialPageSize ?? rowsPerTable;
    const pageSizeChanged = pageSize.toString() !== pageSizeToSet.toString();

    if (serverPaginated && queryParamDiscrepancy && pageSizeChanged) {
      setPageSize(pageSizeToSet);
    }
  }, [searchParamsPageSize, initialPageSize, rowsPerTable, pageSize, paginationMode]);

  const selectionModel = useMemo(
    () => selectionModelProp ?? internalSelectionModel,
    [selectionModelProp, internalSelectionModel]
  );

  const internalSortModelInitialState =
    currentTableSettings?.layout.map(({ sort: { field, sort }, name }) => ({
      // not all TableSettings have these keys, so we initialize them as inactive defaults if they don't yet exist
      field: field ?? name,
      sort: sort ?? null,
    })) ??
    initialState?.sorting?.sortModel ??
    [];

  const [workingSortModel, setWorkingSortModel] = useState<GridSortModel>(internalSortModelInitialState);

  useEffect(() => {
    setWorkingSortModel(internalSortModelInitialState.filter(({ sort }) => Boolean(sort)));
  }, [currentTableSettings, columns.length, name]);

  const handleOnRowClick = (params, event, details) => {
    // eslint-disable-next-line no-prototype-builtins
    if (event.target.hasOwnProperty('checked')) {
      return;
    }
    onRowClick?.(params, event, details);
  };

  const handleOnCellClick = (params, event, details) => {
    if (params.field === ActionsFieldName) {
      event.stopPropagation();
    }
    onCellClick?.(params, event, details);
  };
  const handleOnSelectionModelChange = (newSelectionModel: GridSelectionModel, details: GridCallbackDetails) => {
    const allRowIdsCount = details.api.getRowsCount();
    const visibleRowsIds = Array.from(details.api.getVisibleRowModels().keys() || '');

    if (paginationMode !== 'server') {
      // if the visible amount of rows is less than the total amount of rows, set the selection model to only the visible rows
      if (visibleRowsIds.length < allRowIdsCount) {
        const selectedVisibleRows = newSelectionModel.filter((rowId) => visibleRowsIds.includes(rowId));
        const existingSelectionModelWithoutVisibleRows = difference(selectionModel, visibleRowsIds);
        setSelectionModel(selectedVisibleRows.concat(existingSelectionModelWithoutVisibleRows as Array<GridRowId>));
        onSelectionModelChange(
          selectedVisibleRows.concat(existingSelectionModelWithoutVisibleRows as Array<GridRowId>),
          details
        );
      } else {
        setSelectionModel(newSelectionModel);
        onSelectionModelChange(newSelectionModel, details);
      }
    } else {
      const newSelectedItems = structuredClone(bulkSelectedRows);

      // if the newSelectionModel no longer includes an item in selectedItems, delete it.
      bulkSelectedRows.forEach((_value, key) => {
        if (!newSelectionModel.includes(key)) {
          newSelectedItems.delete(key);
        }
      });
      newSelectionModel.forEach((value) => {
        if (value && !newSelectedItems.has(Number(value))) {
          const clonedRow = structuredClone(
            (rows as unknown as { id: number }[]).find((row) => row.id === Number(value))
          );
          if (clonedRow) {
            newSelectedItems.set(Number(value), clonedRow);
          }
        }
      });

      setBulkSelectedRows(newSelectedItems);
      setSelectionModel([...newSelectedItems.keys()]);
      onSelectionModelChange(newSelectionModel, details);
      onPaginatedSelectionModelChange([...newSelectedItems.values()], details);
    }
  };

  const handleSetColumnVisibilityModel: MUIDataGridProps['onColumnVisibilityModelChange'] = (model, details) => {
    if (typeof onColumnVisibilityModelChange === 'function') {
      onColumnVisibilityModelChange(model, details);
    } else {
      setWorkingColumnVisibilityModel(model);
    }
  };

  const handleGetRowHeight = (params: GridRowHeightParams): GridRowHeightReturnValue => {
    if (getRowHeight) {
      const textWrapCell = columns.find(({ cellClassName }) => cellClassName === 'cell--text-wrap');

      if (textWrapCell) {
        const { field } = textWrapCell;

        if (workingColumnVisibilityModel[field]) {
          return getRowHeight(params);
        }
      }
    }

    return null;
  };

  const handleOnColumnWidthChange = async ({ colDef, width }: GridColumnResizeParams) => {
    setGridColumnWidths((previousGridColumWidths) => {
      const nextGridColumnWidths = new Map(previousGridColumWidths);
      nextGridColumnWidths.set(colDef.field, width);

      return nextGridColumnWidths;
    });

    if (name) {
      const updatedTableLayout = computedColumns
        .map((column, index) => {
          const columnLayoutSettings =
            currentTableSettings?.layout.find(({ name }) => name === column.field) ??
            getDefaultTableSetting(column.field, column.headerName ?? column.field);

          if (!currentTableSettings && initialState?.sorting?.sortModel?.length) {
            initialState.sorting.sortModel.forEach((columSortItem) => {
              if (columSortItem.field === columnLayoutSettings.name) {
                columnLayoutSettings.sort = columSortItem;
              }
            });
          }

          return {
            ...columnLayoutSettings,
            order: index,
            visible: workingColumnVisibilityModel[column.field],
            width: column.field === colDef.field ? width : column.width,
          };
        })
        .filter(({ name }) => name !== ActionsFieldName);

      await UserActions.setTableSettings(name, updatedTableLayout, true);
    }
  };

  const handleOnSortModelChange = async (model: GridSortModel) => {
    setWorkingSortModel(model);
    onSortModelChange(model);

    // checking columns length here because in the case of reports,
    // the datagrid is mounted before we have column definitions. When that happens
    // user settings are reset for the `name` passed in, unintentionally.
    if (name && columns.length) {
      const updatedTableLayout = computedColumns
        .map((column, index) => {
          const columnLayoutSettings =
            currentTableSettings?.layout.find(({ name }) => name === column.field) ??
            getDefaultTableSetting(column.field, column.headerName ?? column.field);
          const sort = model.find(({ field }) => field === column.field) ?? { field: column.field, sort: null };
          return {
            ...columnLayoutSettings,
            order: index,
            sort,
            visible: workingColumnVisibilityModel[column.field],
            width: column.width,
          };
        })
        .filter(({ name }) => name !== ActionsFieldName);

      await UserActions.setTableSettings(name, updatedTableLayout, true);
    }
  };

  const hasBulkActions = (bulkActions ? bulkActions.length : 0) > 0 && bulkActions?.some((ba) => ba.visible);
  const hasSelection = selectionModel.length > 0;
  // TODO-REFACTOR: this is part of WIP work for handling persisted selections through server pages. Keeping it here in a comment for forthcoming work
  // const hasSelection = selectionModel.length > 0 || apiRef?.current?.getSelectedRows?.()?.size;
  const bulkActionsVisible = hasSelection && hasBulkActions;
  const hasRowActions = computedColumns.find(
    ({ field, cellClassName }) => field === ActionsFieldName && cellClassName !== 'data-grid--empty-actions-cell'
  );

  const components: DataGridComponents = {
    BaseCheckbox: CustomCheckbox,
    Footer,
    Toolbar: BulkActionsAndModalsContainer,
    LoadingOverlay: CustomLoader,
    NoResultsOverlay,
    NoRowsOverlay: NoResultsOverlay,
    Row: CustomRow,
    Cell: CustomCell,
    ColumnResizeIcon,
    RowReorderIcon: RowReorderingIcon,
  };

  components.ColumnSortedDescendingIcon = ArrowDown;
  components.ColumnSortedAscendingIcon = ArrowUp;

  const componentProps: GridSlotsComponentsProps = {
    baseCheckbox: {
      isBulkActionVisible: bulkActionsVisible,
    },
    toolbar: {
      bulkActions,
      buttonAutomationId: bulkActionButtonAutomationId,
    },
    footer: {
      rowsPerPageOptions: adjustedRowsPerPageOptions,
      paginationMode,
    },
    noResultsOverlay: {
      noResultsHeading,
      noResultsSubheading,
    },
    noRowsOverlay: {
      noResultsHeading,
      noResultsSubheading,
    },
  };

  componentProps.baseCheckbox = {
    icon: <RebrandUncheck />,
    indeterminateIcon: <RebrandCheck />,
    checkedIcon: <RebrandCheck />,
    paginationMode,
  };

  const computedInitialState = {
    ...initialState,
    pinnedColumns: {
      ...(initialState?.pinnedColumns ?? {}),
      right: [ActionsFieldName],
    },
  };

  const headerHeight = hideColumnHeaders || loading || rows.length === 0 ? 0 : 40;
  const rowHeight = propsRowHeight ?? 44;

  const shouldHideFooter = useMemo(
    () => loading || rows.length === 0 || hideFooter || disablePagination,
    [loading, rows.length, hideFooter, disablePagination]
  );

  // computedHideFooter is held in state to prevent the footer from disappearing when the user would select a page size
  // greater than the number of rows
  const [computedHideFooter, setComputedHideFooter] = useState(shouldHideFooter);

  useEffect(() => {
    let newHideFooter = shouldHideFooter;
    if (usingServerTableControls) {
      newHideFooter = false;
    }
    // computedHideFooter is updated if the row length changes--this handles cases where we show no footer on initial render,
    // but post-render the row array size becomes long enough to necessitate the footer reactively appearing
    setComputedHideFooter(newHideFooter);
  }, [shouldHideFooter, usingServerTableControls]);

  const onViewportInnerSizeChange = useCallback(() => {
    const { hasScrollX: currentHasScrollX } = apiRef.current?.getRootDimensions() ?? { hasScrollX: false };
    setHasHorizontalScrollbar(!loading && currentHasScrollX);
  }, [apiRef, loading]);

  useEffect(() => {
    const unsubscribeEvent = apiRef.current?.subscribeEvent('viewportInnerSizeChange', onViewportInnerSizeChange);
    return () => (unsubscribeEvent ? unsubscribeEvent() : noop());
  }, [apiRef, onViewportInnerSizeChange]);

  const tableName = useMemo(() => ({ tableName: name }), [name]);

  return (
    <DataGridWrapperContext.Provider value={tableName}>
      <RebrandDataGridContainer
        $darkMode={darkMode}
        autoHeight={autoHeight}
        bulkActionsVisible={bulkActionsVisible}
        className='data-grid-container'
        data-testid={containerAutomationId}
        grow={grow}
        hasRowClickHandler={onRowClick !== undefined}
        height={height}
        highlightSelectedRow={highlightSelectedRow}
        minHeight={minHeight}
        minWidth={minWidth}
      >
        <RebrandDataGrid
          columnBuffer={computedColumns.length}
          columnThreshold={5}
          columnVisibilityModel={workingColumnVisibilityModel}
          pageSize={Number(pageSize)}
          pagination={!disablePagination}
          onColumnVisibilityModelChange={handleSetColumnVisibilityModel}
          {...props}
          $darkMode={darkMode}
          $hasHorizontalScrollbar={hasHorizontalScrollbar}
          $hasRowActions={Boolean(hasRowActions)}
          apiRef={apiRef}
          checkboxSelection={checkboxSelection}
          columns={computedColumns}
          components={components}
          componentsProps={componentProps}
          disableColumnFilter
          disableColumnMenu
          disableColumnReorder
          disableRowHoverHighlight={disableRowHoverHighlight}
          disableSelectionOnClick={disableSelectionOnClick}
          disableVirtualization={doDisableVirtualization()}
          displayBorder={displayBorder}
          experimentalFeatures={experimentalFeatures}
          getRowHeight={handleGetRowHeight}
          headerHeight={headerHeight}
          hideFooter={computedHideFooter}
          initialState={computedInitialState}
          loading={loading}
          pinnedColumns={pinnedColumns}
          rowHeight={rowHeight}
          rowReordering={rowReordering}
          rows={rows}
          rowsPerPageOptions={adjustedRowsPerPageOptions}
          sortModel={disableSorting ? [] : workingSortModel}
          onCellClick={handleOnCellClick}
          onColumnWidthChange={handleOnColumnWidthChange}
          onPageSizeChange={(newPage, details) => {
            if (onPageSizeChange) {
              onPageSizeChange(newPage, details);
            }
            setPageSize(newPage);
          }}
          onRowClick={handleOnRowClick}
          onRowOrderChange={onRowOrderChange}
          // TODO-REFACTOR: this is part of WIP work for handling persisted selections through server pages. Keeping it here in a comment for forthcoming work
          // onSelectionModelChange={keepNonExistentRowsSelected ? onSelectionModelChange : handleOnSelectionModelChange}
          onSelectionModelChange={handleOnSelectionModelChange}
          onSortModelChange={handleOnSortModelChange}
        />
      </RebrandDataGridContainer>
    </DataGridWrapperContext.Provider>
  );
}
