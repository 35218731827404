import moment from 'moment';
import TableLogic from '../components/table/TableLogic';

export default {
  capFirstLetter(str) {
    if (str.length === 0) {
      return str;
    }

    if (str.length === 1) {
      return str.charAt(0).toUpperCase();
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  noNull(arr) {
    if (!arr) {
      return [];
    }

    return arr.map((x) => {
      for (const y in x) {
        if (x[y] === null) {
          x[y] = '';
        }

        if ((typeof x[y] === 'string' || x[y] instanceof String) && x[y].toLowerCase() === 'na') {
          x[y] = 'n/a';
        }
      }

      return x;
    });
  },

  formatAsPrice(val) {
    val = parseFloat(val).toFixed(2);
    if (isNaN(val)) {
      return '';
    }

    const tmp = Math.abs(val);

    if (tmp > 999.99) {
      const priceParts = val.toString().split('.');
      switch (priceParts[0].length) {
        case 4:
          return `${priceParts[0][0]},${priceParts[0][1]}${priceParts[0][2]}${priceParts[0][3]}.${priceParts[1]}`;

        case 5:
          return `${priceParts[0][0]}${priceParts[0][1]},${priceParts[0][2]}${priceParts[0][3]}${priceParts[0][4]}.${priceParts[1]}`;

        case 6:
          return `${priceParts[0][0]}${priceParts[0][1]}${priceParts[0][2]},${priceParts[0][3]}${priceParts[0][4]}${priceParts[0][5]}.${priceParts[1]}`;
      }
    }

    return val;
  },

  toggleSelection(allItems, selectedItems, idField) {
    const select = (list, i) =>
      list.map((d) => {
        if (d[idField] === selectedItems[i][idField]) {
          const x = { ...d, isSelected: !d.isSelected };
          return x;
        }
        return d;
      });

    for (let i = 0; i < selectedItems.length; i++) {
      selectedItems = select(selectedItems, i);
    }

    return selectedItems;
  },

  openPDFInWindow(data, fileName) {
    this.openFileInWindow(data, fileName, 'application/pdf');
  },

  doDownload(bytes, type, name) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([bytes], { type }));
    link.download = name;
    link.target = '_blank';
    link.click();
  },

  downloadJsonFile(jsonObject, fileName) {
    const contentType = 'application/json;charset=utf-8;';
    const bytes = decodeURIComponent(encodeURI(JSON.stringify(jsonObject)));
    this.doDownload(bytes, contentType, fileName);
  },

  downloadTxt(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },

  openFileInWindow(data, name, type) {
    if (type == 'text') {
      this.downloadTxt(name, data);
      return;
    }

    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    this.doDownload(bytes, type, name);
  },

  useSkuAsPackageId(product) {
    let useSku = false;

    if (
      !!product &&
      !!product.Sku &&
      product.IsCannabisProduct.toLowerCase() === 'no' &&
      product?.UseSkuNumberAsSerialNo?.toLowerCase() === 'yes'
    ) {
      useSku = true;
    }

    return useSku;
  },

  exportCSV(columns, data, excel = false, headers = [], title = 'Report', fixedWidthCommas = false) {
    let pageHeader = '';
    headers.forEach((x) => {
      if (fixedWidthCommas) {
        pageHeader += x;
        columns.slice(0, columns.length - 2).forEach((x) => (pageHeader += ','));
      }
      return (pageHeader += '\n');
    });

    let colHeader = '';
    for (var i = 0; i < columns.length; i++) {
      if (columns[i].print) {
        if (colHeader.length > 0) {
          colHeader += ',';
        }

        colHeader += `"${columns[i].title}"`;
      }
    }
    colHeader += '\n';

    let body = '';
    let printColDetected = false;
    for (var i = 0; i < data.length; i++) {
      for (let j = 0; j < columns.length; j++) {
        let isCommaString = false;
        if (columns[j].print) {
          if (printColDetected && j > 0) {
            body += ',';
          }
          printColDetected = true;

          var renderVal;
          if (columns[j].exportRender) {
            renderVal = columns[j].exportRender(data[i]);
          } else if (columns[j].render) {
            renderVal = columns[j].render(data[i]);
          } else if (data[i][columns[j].name] === null || data[i][columns[j].name] === undefined) {
            renderVal = '';
          } else if (TableLogic.formatAsDate(columns[j].name)) {
            renderVal = moment(data[i][columns[j].name]).format('YYYY-MM-DD');
          } else {
            renderVal = data[i][columns[j].name];
          }

          if (typeof renderVal === 'string' && renderVal) {
            renderVal = renderVal.replace(/"/g, '""');
            renderVal = renderVal.replace(/(?:\r\n|\r|\n)/g, ' ');

            if (renderVal.includes(',') || renderVal.includes('"')) {
              renderVal = `"${renderVal}"`;
              isCommaString = true;
            }
          }

          if (excel && !isCommaString) {
            renderVal = `="${renderVal}"`;
          }

          body += renderVal;
        }
      }
      body += '\n';
      printColDetected = false;
    }
    const csv = pageHeader + colHeader + body;

    this.doDownload(csv, 'text/csv', title);
  },
};
