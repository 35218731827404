import styled from 'styled-components';

import { Input } from 'src/app/components/lib/input';

export const UnstyledInput = styled(Input)`
  pointer-events: none;
  height: var(--sizes-50);

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-input {
    padding-left: 0;
  }
`;
