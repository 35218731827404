import { EventEmitter } from 'events';

import { datadogRum } from '@datadog/browser-rum';
import i18next from 'i18next';
import _ from 'lodash';
import LogRocket from 'logrocket';
import Cookies from 'universal-cookie';

import { isCanadaLocByState } from 'src/app/utils/locale';

import { actions as AppEvents } from '../constants/AppConstants';
import { endPoints as EndPoints, actions as AuthActions } from '../constants/AuthConstants';
import { events as UserEvents, actions as UserActions } from '../constants/UserConstants';
import AjaxPromises from '../utils/AjaxPromises';
import AppDispatcher from '../utils/AppDispatcher';

import ConfigStore from './ConfigStore';

import type { TableSetting } from 'src/app/state/user-dispensaries';

export const _state = {
  data: false as any,

  companies: false as any,
  selectedCompany: false as any,

  locations: [] as any,
  selectedLocation: false as any,

  locProfile: false as any,

  tableSettings: [] as any,
  settings: [] as any,

  features: [] as any,
  validatedForms: [] as any,
  integrations: false as any,
  lspSettings: false as any,

  customerTypes: [] as any,

  isSSOLoginEnabled: false as any,
};

enum FieldOption {
  Show = 1,
  Required = 2,
  Hide = 3,
  RequiredIfCannabis = 4,
}

class UserStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  mergeApiData(formData?: any) {
    if (!formData) {
      formData = {};
    }

    formData.SessionId = _state.data ? _state.data.SessionId.toString() : '';

    if (!formData.hasOwnProperty('LspId')) {
      formData.LspId = _state.selectedCompany ? _state.selectedCompany.LspId : '';
    }

    if (!formData.hasOwnProperty('LocId')) {
      formData.LocId = _state.selectedLocation ? _state.selectedLocation.LocId : '';
    }

    if (!formData.hasOwnProperty('UserId')) {
      formData.UserId = _state.data ? _state.data.Id : '';
    }

    return formData;
  }

  getApiData(formData?: any) {
    if (!formData) {
      formData = {};
    }

    formData.SessionId = _state.data ? _state.data.SessionId.toString() : '';
    formData.LspId = _state.selectedCompany ? _state.selectedCompany.LspId : '';
    formData.LocId = _state.selectedLocation ? _state.selectedLocation.LocId : '';

    if (_state.companies) {
      const org = _state.companies.find((l: { LspId: number }) => l.LspId === _state.selectedCompany.LspId) as {
        OrgId: number;
      };
      formData.OrgId = org.OrgId;
    }

    if (!formData.hasOwnProperty('UserId')) {
      formData.UserId = _state.data ? _state.data.Id : '';

      if (_.isNil(formData.UserId)) {
        const error = new Error('Invalid User Id');
        datadogRum.addError(error, formData);
      }
    }

    return formData;
  }

  logout(logoutRequestOrigin = 'UserInitiatedLogout') {
    const payload = {
      Origin: logoutRequestOrigin,
    };
    sessionStorage.removeItem('llxBackOfficeBannerIsOpen');
    _state.data = false;
    _state.companies = false;
    _state.selectedCompany = false;
    _state.locations = false;
    _state.selectedLocation = false;
    const cookies = new Cookies();
    cookies.remove('LeafLogixSessionInfo', { path: '/' });
    AjaxPromises.POST(EndPoints.LOGOUT, payload).then((res) => {
      if (res && res.Result && res.Data) {
        window.location.href = res.Data;
      }
    });
    this.emitChange({ lspChange: true, locChange: true, logout: true });
  }

  getTableSettings(table) {
    if (!table) {
      return false;
    }

    return _state.tableSettings.filter((x) => x.table === table)[0];
  }

  addLocation(location) {
    _state.locations = [..._state.locations, location];
  }

  emitChange(callback) {
    this.emit(UserEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(UserEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(UserEvents.CHANGE_EVENT, callback);
  }

  getFeatures() {
    return _state.features;
  }

  getValidatedForms() {
    if (
      _state.locProfile.UseBioTrack &&
      _state.locProfile.IntegratedState &&
      _state.locProfile.IntegratedState.toLowerCase() === 'ny'
    ) {
      return _state.validatedForms;
    }

    return _state.validatedForms.filter(
      (validatedForm) => validatedForm.ReactName.toLowerCase() !== 'potencyindicator'
    );
  }

  isFieldNeverRequired(fieldReactName, unitId = null as number | null) {
    const {
      locProfile: { RecFlowerEquiv, UseManufacturers },
    } = _state;

    switch (fieldReactName) {
      case 'recflowerequivalent': // rec flower equivalent is never required if the location is not using it
        return !RecFlowerEquiv;
      case 'grossweight': // gross weight is never required for non-quantity unit types
        return unitId !== 1;
      case 'producer': // manufacturer (aka producer) is never required if the location is not using it
        return !UseManufacturers;

      default:
        return false;
    }
  }

  /**
   * @param FormName - The database form name
   * @param FieldName - The database react name
   * @param isCannabisProduct - Optional parameter denoting that a product contains cannabis (default false). Useful for evaluating the RequiredIfCannabis option.
   * @param unitId - Optional unit id parameter (default null). Useful evaluate a field that does not have its own unit id and relies on the general unit id of form (e.g. product/inventory default unit id).
   * @returns true | false
   */
  isFieldRequired(FormName, FieldName, isCannabisProduct = false, unitId = null as number | null) {
    const field = _state.validatedForms.filter(
      (x) => x.FormName.toUpperCase() == FormName.toUpperCase() && x.ReactName.toUpperCase() == FieldName.toUpperCase()
    );

    if (field[0]) {
      if (this.isFieldNeverRequired(field[0].ReactName.toLowerCase(), unitId)) {
        return false;
      }

      return (
        field[0].CustomerOption == FieldOption.Required ||
        (field[0].CustomerOption == FieldOption.RequiredIfCannabis && isCannabisProduct) ||
        field[0].SystemRequired
      );
    }
    return false;
  }

  /**
   *
   * @param FormName - The database form name
   * @param FieldName - The database react name
   * @returns true | false
   */
  isFieldHidden(FormName, FieldName) {
    const field = _state.validatedForms.filter(
      (x) => x.FormName.toUpperCase() == FormName.toUpperCase() && x.ReactName.toUpperCase() == FieldName.toUpperCase()
    );

    if (field[0]) {
      return field[0].CustomerOption == FieldOption.Hide;
    }
    return false;
  }

  /**
   *
   * @param FormName - The database form name
   * @param Form - The form object that is being validated. Most commonly the state of the component being validated.
   * @param allowZeroFieldNames - Optional parameter that allows certain fields to be 0. Commonly an array of strings.
   * @param fieldsWithUnits - Optional parameter that allows certain fields to be validated with a unit field. Commonly a mapping that takes the field name as the key and the unit field name as the value.
   * @param generalUnitId - Optional parameter that is used to evaluate fields that do not have a unique unit id of their own and rely on the general unit id of the product/inventory.
   * @returns true | false
   */
  isFormValid(
    FormName,
    Form,
    allowZeroFieldNames: string[] = [],
    fieldsWithUnits = {},
    generalUnitId = null as number | null
  ) {
    const isCannabisProduct = Form.IsCannabisProduct ? Form.IsCannabisProduct.toLowerCase() == 'yes' : false;

    for (const Field in Form) {
      const value = Form[Field];
      const unitField = fieldsWithUnits[Field];

      if (this.isFieldRequired(FormName, Field, isCannabisProduct, generalUnitId)) {
        if (!value || value == 0) {
          if (value != 0 || allowZeroFieldNames.indexOf(Field) === -1) {
            return false;
          }
        }

        if (typeof value === 'string' && !value.trim()) {
          return false;
        }

        if (!!value && !!unitField && !Form[unitField]) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   *
   * @param FormName - The database form name
   * @param Form - The form object that is being validated. Most commonly the state of the component being validated.
   * @param allowZeroFieldNames - Optional parameter that allows certain fields to be 0. Commonly an array of strings.
   * @param fieldsWithUnits - Optional parameter that allows certain fields to be validated with a unit field. Commonly a mapping that takes the field name as the key and the unit field name as the value.
   * @param generalUnitId - Optional parameter that is used to evaluate fields that do not have a unique unit id of their own and rely on the general unit id of the product/inventory.
   * @returns string array of invalid form fields
   */
  isFormValidDetailed(
    FormName,
    Form,
    allowZeroFieldNames: string[] = [],
    fieldsWithUnits = {},
    generalUnitId = null as number | null
  ) {
    const invalidFields = [];
    const isCannabisProduct = Form.IsCannabisProduct ? Form.IsCannabisProduct.toLowerCase() == 'yes' : false;

    for (const Field in Form) {
      const value = Form[Field];
      const unitField = fieldsWithUnits[Field];

      if (this.isFieldRequired(FormName, Field, isCannabisProduct, generalUnitId)) {
        if (!value || value == 0) {
          if (value != 0 || allowZeroFieldNames.indexOf(Field) === -1) {
            if (invalidFields.indexOf(Field) === -1) {
              invalidFields.push(Field);
            }
          }
        }

        if (typeof value === 'string' && !value.trim()) {
          if (invalidFields.indexOf(Field) === -1) {
            invalidFields.push(Field);
          }
        }

        if (!!value && !!unitField && !Form[unitField]) {
          if (invalidFields.indexOf(Field) === -1) {
            invalidFields.push(Field);
          }
        }
      }
    }

    return invalidFields;
  }

  getIntegrations() {
    return _state.integrations;
  }

  getLspSettings() {
    return _state.lspSettings;
  }

  isLocationInState(stateAbbreviation) {
    const locationState = (_state.selectedLocation.State || '').toLowerCase();
    return stateAbbreviation.toLowerCase() === locationState;
  }

  dispatchToken;
}

const UserStore = new UserStoreClass();

UserStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.APP_BOOT:
      _state.locations = action.data.locations;
      _state.selectedLocation = action.data.selectedLocation;
      _state.companies = action.data.companies;
      _state.selectedCompany = action.data.selectedCompany;
      _state.data = action.data.user;
      _state.locProfile = action.data.locProfile;
      _state.tableSettings = action.data.tableSettings;
      _state.features = action.data.features;
      _state.validatedForms = action.data.validatedForms;
      _state.integrations = action.data.integrations;
      _state.lspSettings = action.data.lspSettings;
      _state.customerTypes = action.data.customerTypes;

      try {
        LogRocket.identify(action.data.user.Id, {
          ...action.data.user,
          displayName: action.data.user.FullName,
          email: action.data.user.UserName,
        });
      } catch (ex) {
        console.warn(ex);
      }

      try {
        const pendo = (window as any).pendo ?? { initialize: () => null };
        pendo.initialize({
          visitor: {
            id: action.data.user.Id,
            email: action.data.user.UserName,
            full_name: action.data.user.FullName,
            LocId: action.data.user.LocId,
            LspId: action.data.user.LspId,
            LocName: action.data.selectedLocation?.Name,
            LspName: action.data.selectedCompany?.LspName,
            Deployment: ConfigStore.getRegion(),
            location_id_and_region: `${Number(action.data.user.LocId)}${ConfigStore.getRegion() || ''}`,
            State: action.data.selectedLocation.State,
          },
          account: {
            id: action.data.user.LocId,
            ServerAndLSP: `${ConfigStore.getRegion() || ''}${Number(action.data.user.LspId)}`,
            ServerAndLOC: `${ConfigStore.getRegion() || ''}${Number(action.data.user.LocId)}`,
          },
        });
      } catch (error) {
        console.warn(error);
      }

      try {
        (window as any).appInsights?.setAuthenticatedUserContext(action.data.user.Id);
      } catch (ex) {
        console.warn(ex);
      }

      setTimeout(() => {
        AppDispatcher.dispatch({
          ...action,
          actionType: AppEvents.APP_BOOTED,
        });
      }, 0);

      try {
        if (i18next.language.includes('en')) {
          const newLocale = isCanadaLocByState({ user: _state }) ? 'en-CA' : 'en-US';
          if (i18next.language !== newLocale) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18next.changeLanguage(newLocale);
          }
        }
      } catch (error) {
        console.warn(error);
      }

      UserStore.emitChange({ lspChange: true, locChange: true });
      break;

    case UserActions.SELECT_COMPANY:
      _state.locations = action.data.locations;
      _state.selectedCompany = action.data.currentCompany;
      _state.selectedLocation = action.data.currentLocation;
      _state.data.LspId = action.data.currentCompany.LspId;
      _state.data.LocId = action.data.currentLocation.LocId;

      try {
        const pendo = (window as any).pendo ?? {};
        pendo.updateOptions({
          visitor: {
            id: _state.data.Id,
            email: _state.data.UserName,
            full_name: _state.data.FullName,
            LocId: _state.data.LocId,
            LspId: _state.data.LspId,
            LocName: action.data.currentLocation?.Name,
            LspName: action.data.currentCompany?.LspName,
            Deployment: ConfigStore.getRegion(),
            location_id_and_region: `${Number(action.data.user.LocId)}${ConfigStore.getRegion() as unknown as string}`,
            State: action.data.currentLocation.State,
          },
          account: {
            id: _state.data.LocId,
          },
        });
      } catch (error) {
        console.warn(error);
      }

      UserStore.emitChange({ lspChange: true, locChange: true });
      break;

    case UserActions.SELECT_LOCATION:
      _state.selectedLocation = action.data;
      _state.data = { ..._state.data, LocId: action.data.LocId };
      _state.locProfile = action.locProfile;
      _state.features = action.features;
      _state.validatedForms = action.validatedForms;
      _state.integrations = action.integrations;

      _state.lspSettings = action.lspSettings;
      _state.customerTypes = action.customerTypes;

      setTimeout(() => {
        AppDispatcher.dispatch({
          actionType: AppEvents.LOCATION_LOADED,
        });
      }, 0);

      try {
        const pendo = (window as any).pendo ?? {};
        pendo.updateOptions({
          visitor: {
            id: _state.data.Id,
            email: _state.data.UserName,
            full_name: _state.data.FullName,
            LocId: _state.data.LocId,
            LspId: _state.data.LspId,
            LocName: action.data.Name,
            LspName: _state.selectedCompany?.LspName,
            Deployment: ConfigStore.getRegion(),
            location_id_and_region: `${Number(action.data.user.LocId)}${ConfigStore.getRegion() as unknown as string}`,
            State: action.data.State,
          },
          account: {
            id: _state.data.LocId,
          },
        });
      } catch (error) {
        console.warn(error);
      }
      try {
        if (i18next.language.includes('en')) {
          const newLocale = isCanadaLocByState({ user: _state }) ? 'en-CA' : 'en-US';
          if (i18next.language !== newLocale) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18next.changeLanguage(newLocale);
          }
        }
      } catch (error) {
        console.warn(error);
      }
      UserStore.emitChange({ lspChange: false, locChange: true, featuresChange: true });
      break;

    case UserActions.SET_TABLE_SETTINGS:
      let nextTableSettings = _.cloneDeep(_state.tableSettings);
      const table = action.data.table || '';
      const layout = action.data.layout || {};
      const match = _state.tableSettings.find((setting: TableSetting) => setting.table === table);

      if (match) {
        nextTableSettings = [
          ..._state.tableSettings.filter((setting) => setting.table !== table),
          {
            ...match,
            layout,
          },
        ];
      } else {
        nextTableSettings.push({ table, layout });
      }

      _state.tableSettings = nextTableSettings;

      UserStore.emitChange({ tableSettingsChange: true });
      break;

    case UserActions.SET_DELIVERY_HOURS: {
      const newLocProfile = _.cloneDeep(_state.locProfile);
      newLocProfile.DefaultDeliveryWindowStart = action.data.DeliveryWindowStart;
      newLocProfile.DefaultDeliveryWindowEnd = action.data.DeliveryWindowEnd;
      _state.locProfile = newLocProfile;

      UserStore.emitChange({});
      break;
    }

    case UserActions.SET_FEATURES: {
      _state.features = action.data.features;
      _state.integrations = action.data.integrations;
      _state.lspSettings = action.data.lspSettings;

      const newLocProfile = _.cloneDeep(_state.locProfile);

      action.data.features.forEach((feature) => {
        newLocProfile[feature.FeatureName] = feature.IsEnabled;
      });

      const locProfileWithIntegrations = Object.assign(newLocProfile, action.data.integrations);
      _state.locProfile = locProfileWithIntegrations;

      UserStore.emitChange({ featuresChange: true });

      break;
    }
    case UserActions.SET_VALIDATED_FORMS:
      _state.validatedForms = action.data;
      UserStore.emitChange({});
      break;

    case AuthActions.SSO_LOGIN_ENABLED:
      _state.isSSOLoginEnabled = action.data;
      UserStore.emitChange({ ssoLoginEnabled: true });
      break;

    case UserActions.SET_RESTRICTED_HOURS: {
      const newLocProfile = _.cloneDeep(_state.locProfile);
      newLocProfile.RestrictWindowStart = action.data.RestrictWindowStart;
      newLocProfile.RestrictWindowEnd = action.data.RestrictWindowEnd;
      _state.locProfile = newLocProfile;

      UserStore.emitChange({});
      break;
    }

    case AuthActions.SSO_LOGIN:
      if (!action.data) {
        UserStore.emitChange({ ssoLogin: true, error: true });
      }
      break;
  }
});

export default UserStore;
