import React from 'react';

import { useDarkMode } from 'src/app/state/dark-mode';

import { Tooltip, TooltipIconContainer, TooltipIcon } from '../tooltip';

import { RebrandLabel } from './label-rebrand.styles';

export function Label(props) {
  const { children, required = false, tooltip, useSpanLabel = false } = props;
  const darkMode = useDarkMode();
  return (
    <RebrandLabel {...props} $darkMode={darkMode} as={useSpanLabel ? 'span' : 'label'}>
      {children} {required && <span className='required-asterisk'>*</span>}
      {tooltip && (
        <Tooltip placement='top' title={tooltip}>
          <TooltipIconContainer>
            <TooltipIcon />
          </TooltipIconContainer>
        </Tooltip>
      )}
    </RebrandLabel>
  );
}
