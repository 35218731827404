import { HardwareService } from "../HardwareService";
import { usePeripheral } from "../shared/usePeripherals";

import type { LabelPrinter } from "./LabelPrinter";

export type LabelPrinterHook = {
  labelPrinter: LabelPrinter;
  refresh: () => void;
}

export const useLabelPrinter = (device: LabelPrinter): LabelPrinterHook => {
  const result = usePeripheral<LabelPrinter>(device, HardwareService.labelPrinter);
  return {
    labelPrinter: result.item.device,
    refresh: result.refresh,
  }
}
