import { useQuery } from '@tanstack/react-query';

import { endPoints } from 'src/app_deprecated/constants/AppConstants';

import { getData } from 'src/app/queries';

import type { QueryKey } from '@tanstack/react-query';

const environmentDetailsQueryKey: QueryKey = ['environment-details'];

type GetEnvironmentDetailsResponse = {
  Data: {
    BackOfficeUrl: string;
    CurbsideUrl: string;
    DutchieBohUrl: string;
    DutchieRegisterUrl: string;
    LaunchDarklyClientKey: string;
    MobileCheckoutUrl: string;
    Pos3Url: string;
    Pos4Url: string;
    Region: string;
  };
};

export function useEnvironmentDetails() {
  return useQuery({
    queryKey: environmentDetailsQueryKey,
    queryFn: () => getData<GetEnvironmentDetailsResponse>(endPoints.GET_ENVIRONMENT_DETAILS),
  });
}
