import { InputBuffer } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import type { Peripheral } from "../Peripheral";
import { PeripheralService } from "../PeripheralService";
import type { PeripheralServiceConfig, PeripheralType } from "../types";
import { WebApi } from "../types";

export abstract class WebUsbPrinterService<T extends Peripheral> extends PeripheralService<T> {
  constructor(type: PeripheralType, name: string, userConfig?: Partial<PeripheralServiceConfig>) {
    super(type, { autoConnect: false, name, autoEnable: true }, userConfig);
  }
  
  abstract createPrinter(device: USBDevice): T;

  get isSupported(): boolean { return !!navigator.usb; }

  attachEventListeners(): void {
    WebApi.usb.addEventListener('connect', this.handleConnectionChangedRef);
    WebApi.usb.addEventListener('disconnect', this.handleConnectionChangedRef);
  }
  
  detachEventListeners(): void {
    WebApi.usb.removeEventListener('connect', this.handleConnectionChangedRef);
    WebApi.usb.removeEventListener('disconnect', this.handleConnectionChangedRef);
  }

  // A connection event is fired for every known usb device. If disconnecting a hub
  // this could fire multiple times. A buffer is used to prevent multiple invalidations
  // within a short period of time.
  private connectionEventBuffer = new InputBuffer<USBConnectionEvent>({
    name: 'WebUsbPrinterServiceHelper',
    onCandidateDetected: () => this.invalidate(),
  });
  private handleConnectionChangedRef = this.handleConnectionChanged.bind(this);
  private handleConnectionChanged(event: USBConnectionEvent): void {
    this.connectionEventBuffer.push(event);
  }

  async availableDevices(): Promise<T[]> {
    try {
      const devices = await WebApi.usb.getDevices();
      return devices
        .filter(device => this.vendorIds.indexOf(device.vendorId) !== -1)
        .map((it) => this.createPrinter(it));
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return [];
  }

  async requestNewDevices(): Promise<T[]> {
    try {
      const filters = this.isFilterEnabled ? this.vendorIds.map((id) => {
        return { vendorId: id } as USBDeviceFilter;
      }) : [];

      const device = await WebApi.usb.requestDevice({ filters })
      if (device) {
        return [this.createPrinter(device)];
      }
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return [];
  }
}
