import { endPoints as InventoryEndPoints } from '../constants/InventoryConstants';

import { SimpleCachedDataStore } from './SimpleCachedDataStore';

class MMURFormsStore extends SimpleCachedDataStore {
  sourceUrl = InventoryEndPoints.GET_MMUR_FORMS;
  entity = 'MMUR Forms';

  constructor() {
    super();
  }

  get forms() {
    return this.data;
  }

  get orderTypes() {
    return [
      { Name: 'Low-THC Cannabis', value: 1 },
      { Name: 'Medical Cannabis', value: 2 },
      { Name: 'Smoking', value: 3 },
      { Name: 'Low-THC and Medical Cannabis', value: 99 },
    ];
  }

  emitChange(change) {
    super.emitChange(change);
  }
}
export default new MMURFormsStore();
