import { HardwareService } from "../HardwareService";
import type { ConnectionType } from "../shared/index";
import { Peripheral, PeripheralType } from "../shared/index";

import type { ScaleMeasurement } from "./types";

export abstract class Scale extends Peripheral {
  constructor(connectionType: ConnectionType) {
    super(PeripheralType.scale, connectionType);
  }

  emitMeasurementEvent(measurement: ScaleMeasurement): void {    
    HardwareService.logger.d('Emit measurement', { on: this.id, measurement });

    // Emit event to service
    HardwareService.scale.events.dispatchEvent(new CustomEvent('measurement', {
      detail: {
        device: this,
        measurement
      }
    }));
  }
}

