import _ from 'lodash';
import { atom, selectorFamily, useRecoilValue } from 'recoil';

import type { Permissions, SuperUserPermissions } from 'src/app_deprecated/constants/PermissionsConstants';
import PermissionsStore from 'src/app_deprecated/stores/PermissionStore';

export * from 'src/app_deprecated/constants/PermissionsConstants';

export type PermissionVals = (typeof Permissions)[keyof typeof Permissions];

type SuperPermissionVals = (typeof SuperUserPermissions)[keyof typeof SuperUserPermissions];

type userPermissions = {
  perms: Record<PermissionVals, PermissionVals>[];
  superuserPerms: Record<SuperPermissionVals, SuperPermissionVals>[];
};

export const userPermissionsAtom = atom<userPermissions>({
  key: 'userPermissions',
  default: {
    perms: [],
    superuserPerms: [],
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

const permissionCheck = selectorFamily({
  key: 'userPermissionsCheck',
  get:
    (key: { keys: PermissionVals | PermissionVals[]; operator: 'and' | 'or' }) =>
    ({ get }) => {
      const { keys, operator } = key;
      const { perms } = get(userPermissionsAtom);
      if (typeof keys === 'number') {
        return !!perms[keys];
      }

      if (operator === 'or') {
        return _.some(keys, (param) => !!perms[param]);
      }

      return _.every(keys, (param) => !!perms[param]);
    },
});

const superUserPermissionCheck = selectorFamily({
  key: 'superUserPermissionsCheck',
  get:
    (key: { keys: SuperPermissionVals | SuperPermissionVals[]; operator: 'and' | 'or' }) =>
    ({ get }) => {
      const { keys, operator } = key;
      const { superuserPerms } = get(userPermissionsAtom);
      if (typeof keys === 'number') {
        return !!superuserPerms[keys];
      }

      if (operator === 'or') {
        return _.some(keys, (param) => !!superuserPerms[param]);
      }

      return _.every(keys, (param) => !!superuserPerms[param]);
    },
});

/**
 * Verify that a user has permission(s)
 * @param keys single or array of permissions
 * @param operator determine if the check should be for all or any permissions passed in
 * @returns true | false
 */
export function usePermissionCheck(keys: PermissionVals | PermissionVals[], operator: 'and' | 'or' = 'and') {
  return useRecoilValue(permissionCheck({ keys, operator }));
}

export function useSuperUserPermissionCheck(
  keys: SuperPermissionVals | SuperPermissionVals[],
  operator: 'and' | 'or' = 'and'
) {
  return useRecoilValue(superUserPermissionCheck({ keys, operator }));
}

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    const { permissions, superUserPermissions } = PermissionsStore;
    const permissionsObj = _.keyBy(permissions, (key) => key);
    const superuserPermissionsObj = _.keyBy(superUserPermissions, (key) => key);
    setSelf({
      perms: permissionsObj,
      superuserPerms: superuserPermissionsObj,
    });
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  PermissionsStore.addChangeListener(changeListenerForUserState);

  return () => PermissionsStore.removeChangeListener(changeListenerForUserState);
}
