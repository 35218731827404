import React, { useState } from 'react';

import styled from 'styled-components';

import RebrandGear from 'src/app/components/icons/rebrand-gear';
import { IconButton } from 'src/app/components/lib/icon-button';

import { ColumnsModal } from './columns-modal';

export function ColumnsSettings({ allowColumnReordering = true }: { allowColumnReordering?: boolean }): JSX.Element {
  const [columnsSettingsModalOpen, setColumnsSettingsModalOpen] = useState(false);

  const openModal = () => setColumnsSettingsModalOpen(true);
  const closeModal = () => setColumnsSettingsModalOpen(false);

  return (
    <>
      <StyledGearIconButton aria-label='Column configuration' data-testid='table-settings-button' onClick={openModal}>
        <RebrandGear />
      </StyledGearIconButton>
      {/* Conditionally Rendering this so the component unmounts on close */}
      {columnsSettingsModalOpen && <ColumnsModal allowColumnReordering={allowColumnReordering} onClose={closeModal} />}
    </>
  );
}

const StyledGearIconButton = styled(IconButton)`
  padding: 0;
  width: 28px;
  height: 28px;

  svg {
    path {
      fill: var(--color-gray-80);
    }
  }

  :hover {
    background-color: var(--color-gray-40);
  }

  button {
    :focus {
      outline: none;
    }
  }
`;
