// commenting to resolve jest error
// see: https://github.com/istanbuljs/v8-to-istanbul/issues/198

export const segmentDetailKeys = {
  all: ['segmentDetail'] as const,
  many: (queryProps?: any) => [...segmentDetailKeys.all, 'list'].concat(queryProps ?? []),
  one: (id: number | null) => [...segmentDetailKeys.all, 'detail', id] as const,
};

export const audienceDetailKeys = {
  all: ['segmentAudience'] as const,
  many: (queryProps?: any) => [...audienceDetailKeys.all, 'list'].concat(queryProps ?? []),
  one: (id: number) => [...audienceDetailKeys.all, 'detail', id] as const,
};
