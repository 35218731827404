{
  "- Abilit to archive customers": "- Abilit to archive customers",
  "- Ability to create a waste manager entry upon Plant retirement": "- Ability to create a waste manager entry upon Plant retirement",
  "- Ability to track immature plants individually": "- Ability to track immature plants individually",
  " add discounts to group": " add discounts to group",
  "- AZDHS Integration": "- AZDHS Integration",
  "- BioTrack Integration upgrades": "- BioTrack Integration upgrades",
  "- Brand support for products & menus": "- Brand support for products & menus",
  "- Caregiver selection": "- Caregiver selection",
  "- Completion of the Purchase Order module": "- Completion of the Purchase Order module",
  "- Coupons can now be assigned a product category in product master": "- Coupons can now be assigned a product category in product master",
  "- Coupons can now be set to \"Taxable\"": "- Coupons can now be set to \"Taxable\"",
  " discount groups": " discount groups",
  " edit discounts in group": " edit discounts in group",
  "- Effecive permissions viewer": "- Effecive permissions viewer",
  "- Electronic signatures can now be required upon checkout.": "- Electronic signatures can now be required upon checkout.",
  "- Emailed receipt options": "- Emailed receipt options",
  "- Flight Discounts": "- Flight Discounts",
  "- METRC client can now self-service the maintenance of individual employee API keys": "- METRC client can now self-service the maintenance of individual employee API keys",
  "- METRC Integration upgrades": "- METRC Integration upgrades",
  "- New configuration options": "- New configuration options",
  "- New lab result fields": "- New lab result fields",
  "- Package History viewer": "- Package History viewer",
  "- Plant manager performance improvements": "- Plant manager performance improvements",
  "- PMP Enhancements": "- PMP Enhancements",
  "-- Select Label --": "-- Select Label --",
  "-- Select Loc --": "-- Select Loc --",
  "-- Select Lsp --": "-- Select Lsp --",
  "- Users can now remove a product image from product master using the \"remove product image\" button at the bottom": "- Users can now remove a product image from product master using the \"remove product image\" button at the bottom",
  "-$ = Reduce Register": "-$ = Reduce Register",
  "(Canada Locations) Use OCS SKU/UPC": "(Canada Locations) Use OCS SKU/UPC",
  "(Drivers) License Expiration": "(Drivers) License Expiration",
  "(Drivers) License Number": "(Drivers) License Number",
  "(The new closing report is most easily identified by having a start and end date with time as opposed to just a single date input).": "(The new closing report is most easily identified by having a start and end date with time as opposed to just a single date input).",
  "+$ = Increase Register": "+$ = Increase Register",
  "24 Hour Period Starting:": "24 Hour Period Starting:",
  "6/11/19 Release - Highlights Include:": "6/11/19 Release - Highlights Include:",
  "A brand new permissions system has been released.  Managers and administrators can now assign very granular permissions to users and user groups using the maintenance menus. ": "A brand new permissions system has been released.  Managers and administrators can now assign very granular permissions to users and user groups using the maintenance menus. ",
  "A new Register Close Out UI has been released.  Users will now see a link saying \"Try the new version\" at the top of Register Transactions Screen under POS > Daily Activities.  ": "A new Register Close Out UI has been released.  Users will now see a link saying \"Try the new version\" at the top of Register Transactions Screen under POS > Daily Activities.  ",
  "A new window will open where you will be prompted to login. When it closes, return to this window to complete setup.": "A new window will open where you will be prompted to login. When it closes, return to this window to complete setup.",
  "Abbreviation": "Abbreviation",
  "Absolute": "Absolute",
  "Accept Can Pay": "Accept Can Pay",
  "Accept Hypur": "Accept Hypur",
  "Accept Linx": "Accept Linx",
  "Accessory": "Accessory",
  "Account Type": "Account Type",
  "Accounts Payable": "Accounts Payable",
  "Accounts Receivable": "Accounts Receivable",
  "Accrual Rate": "Accrual Rate",
  "Acct Status": "Acct Status",
  "Action": "Action",
  "Actions": "Actions",
  "Active": "Active",
  "Active Plants": "Active Plants",
  "Actual": "Actual",
  "Actual Arrival Date": "Actual Arrival Date",
  "Add": "Add",
  "ADD": "ADD",
  "Add Delivery Options to Ecom": "Add Delivery Options to Ecom",
  "Add Discounts": "Add Discounts",
  "Add Doctor": "Add Doctor",
  "Add Inventory To Order": "Add Inventory To Order",
  "Add item to manifest": "Add item to manifest",
  "Add Key": "Add Key",
  "Add new": "Add new",
  "Add New Coupon": "Add New Coupon",
  "Add new discount": "Add new discount",
  "Add new label": "Add new label",
  "Add new note": "Add new note",
  "Add new Reconciliation": "Add new Reconciliation",
  "Add new status": "Add new status",
  "Add NewTransaction to POS - allows dispensaries to add items to a users cart, without checking them in and polluting the checkin list. Useful for pre-orders.": "Add NewTransaction to POS - allows dispensaries to add items to a users cart, without checking them in and polluting the checkin list. Useful for pre-orders.",
  "Add Package": "Add Package",
  "Add Plant": "Add Plant",
  "Add Plant/Clones to Inventory": "Add Plant/Clones to Inventory",
  "Add Plants to Inventory": "Add Plants to Inventory",
  "Add Recipe:": "Add Recipe:",
  "Add Selected": "Add Selected",
  "Add Table": "Add Table",
  "Add Tags": "Add Tags",
  "Add to Order": "Add to Order",
  "Add/Edit Step": "Add/Edit Step",
  "Added from": "Added from",
  "Additional Fields": "Additional Fields",
  "Address": "Address",
  "Address 1": "Address 1",
  "Address 2": "Address 2",
  "Address 3": "Address 3",
  "Address added successfully": "Address added successfully",
  "Address Line 1": "Address Line 1",
  "Address Line 2": "Address Line 2",
  "Adds an option in POS settings to display the cart in a 2nd window.": "Adds an option in POS settings to display the cart in a 2nd window.",
  "Adds support on POS to allow adjust caregiver loyalty points.": "Adds support on POS to allow adjust caregiver loyalty points.",
  "Adjust": "Adjust",
  "Adjust and Close": "Adjust and Close",
  "Adjust Available": "Adjust Available",
  "Adjust Balance": "Adjust Balance",
  "Adjust Employee Clock": "Adjust Employee Clock",
  "Adjusted From": "Adjusted From",
  "Adjusted To": "Adjusted To",
  "Adjustment posted succesfully": "Adjustment posted succesfully",
  "Adjustment Reasons": "Adjustment Reasons",
  "Adjustment Type": "Adjustment Type",
  "Adjustments": "Adjustments",
  "Administrative": "Administrative",
  "All": "All",
  "All actions": "All actions",
  "All Batches": "All Batches",
  "All Cannabis": "All Cannabis",
  "All Categories": "All Categories",
  "All Inventory": "All Inventory",
  "All inventory actions have been moved to the gray action wheel on the far right side of the screen.": "All inventory actions have been moved to the gray action wheel on the far right side of the screen.",
  "All Registers": "All Registers",
  "All Rooms": "All Rooms",
  "All Stages": "All Stages",
  "All Status": "All Status",
  "All Strains": "All Strains",
  "All systems will be upgraded to the new closing report with the next release. If you would like to move over sooner please let us know at support@leaflogix.com.": "All systems will be upgraded to the new closing report with the next release. If you would like to move over sooner please let us know at support@leaflogix.com.",
  "All Testing Statuses": "All Testing Statuses",
  "All Types": "All Types",
  "Allergens": "Allergens",
  "Allow Adjust Caregiver Loyalty Points": "Allow Adjust Caregiver Loyalty Points",
  "Allow Adjustments": "Allow Adjustments",
  "Allow Backdated Register Transactions": "Allow Backdated Register Transactions",
  "Allow Customer to Exceed Allotment": "Allow Customer to Exceed Allotment",
  "Allow Default Product Labels": "Allow Default Product Labels",
  "Allow Employee to Exceed Allotment": "Allow Employee to Exceed Allotment",
  "Allow Exp Dt Through": "Allow Exp Dt Through",
  "Allow Medical Only Packages": "Allow Medical Only Packages",
  "Allows clients to edit days supply for completed transactions on the transactions page in the backend": "Allows clients to edit days supply for completed transactions on the transactions page in the backend",
  "Allows LSP (CANADA ONLY) to use the OCS SKU and UPC fields on the Product Master": "Allows LSP (CANADA ONLY) to use the OCS SKU and UPC fields on the Product Master",
  "Alternate Description": "Alternate Description",
  "Alternate Name": "Alternate Name",
  "Amount": "Amount",
  "Amount Off Price": "Amount Off Price",
  "Amount to Restore": "Amount to Restore",
  "An error occurred": "An error occurred",
  "An identical discontinue action needs to be manually performed in METRC": "An identical discontinue action needs to be manually performed in METRC",
  "API Key": "API Key",
  "API Notice": "API Notice",
  "API Pre Order Noitfy": "API Pre Order Noitfy",
  "API Token": "API Token",
  "APIKey": "APIKey",
  "Application Method": "Application Method",
  "Applied By": "Applied By",
  "Applied On": "Applied On",
  "Applies to All Customers": "Applies to All Customers",
  "Applies to All Products": "Applies to All Products",
  "Applies to type": "Applies to type",
  "Apply": "Apply",
  "Apply Loyalty as a Discount": "Apply Loyalty as a Discount",
  "Apply loyalty pre-tax as a discount spread across all items in the cart": "Apply loyalty pre-tax as a discount spread across all items in the cart",
  "Apply Nutrients": "Apply Nutrients",
  "Are you sure you want to remove these items from inventory?": "Are you sure you want to remove these items from inventory?",
  "Are you sure?": "Are you sure?",
  "Arrival": "Arrival",
  "Asset Account": "Asset Account",
  "Assign Mother Status": "Assign Mother Status",
  "Assign Plant Group": "Assign Plant Group",
  "Assign Plant Stage": "Assign Plant Stage",
  "Assign POS Registers to Physical Hardware": "Assign POS Registers to Physical Hardware",
  "Assign Products to Coupon": "Assign Products to Coupon",
  "Assign Room and Table": "Assign Room and Table",
  "Assign Status": "Assign Status",
  "Assign to Batch": "Assign to Batch",
  "Assign Users": "Assign Users",
  "Assign/Remove Mother": "Assign/Remove Mother",
  "AssignCultivationStageToRooms": "AssignCultivationStageToRooms",
  "Attestation": "Attestation",
  "Auto print labels": "Auto print labels",
  "Auto Print Labels?": "Auto Print Labels?",
  "Auto print receipts": "Auto print receipts",
  "Auto Print Receipts?": "Auto Print Receipts?",
  "Auto Print?": "Auto Print?",
  "Auto-Fill Order": "Auto-Fill Order",
  "AutoFill order?": "AutoFill order?",
  "Auto-Fulfill Order": "Auto-Fulfill Order",
  "Automatic": "Automatic",
  "Automatically create and send Daily Sales Receipt Rollups IIF Export via Email for QuickBooks Desktop": "Automatically create and send Daily Sales Receipt Rollups IIF Export via Email for QuickBooks Desktop",
  "Automatically Create New Products On Transfer": "Automatically Create New Products On Transfer",
  "Automatically pushes Daily Sales Receipt Rollups to QuickBooks Online": "Automatically pushes Daily Sales Receipt Rollups to QuickBooks Online",
  "Available": "Available",
  "Average Cart": "Average Cart",
  "Average Sale per Cart": "Average Sale per Cart",
  "Avg $ / Cart": "Avg $ / Cart",
  "Avg Response Time (ms)": "Avg Response Time (ms)",
  "Balance Adjustment": "Balance Adjustment",
  "Batch": "Batch",
  "Batch #": "Batch #",
  "Batch Closed": "Batch Closed",
  "Batch Date": "Batch Date",
  "Batch deleted": "Batch deleted",
  "Batch Documentation": "Batch Documentation",
  "Batch Ingredient Details": "Batch Ingredient Details",
  "Batch Journal": "Batch Journal",
  "Batch Manager": "Batch Manager",
  "Batch Name": "Batch Name",
  "Batch Number": "Batch Number",
  "Batch Size": "Batch Size",
  "Beta Caryophyllene": "Beta Caryophyllene",
  "Beta Myrcene": "Beta Myrcene",
  "Beta Pinene": "Beta Pinene",
  "Big Online Description": "Big Online Description",
  "BioT Driver Alt Id": "BioT Driver Alt Id",
  "BioT Driver Id": "BioT Driver Id",
  "BioTrack End": "BioTrack End",
  "BioTrack Start": "BioTrack Start",
  "BioTrack Used": "BioTrack Used",
  "Blind Audit": "Blind Audit",
  "Blind Register Close Out": "Blind Register Close Out",
  "Book to Account": "Book to Account",
  "Book to Sales Account?": "Book to Sales Account?",
  "Brand": "Brand",
  "Bud Weight": "Bud Weight",
  "Budtender": "Budtender",
  "Budtenders": "Budtenders",
  "Bud-Tenders": "Bud-Tenders",
  "Buy X Get 1 - All Products": "Buy X Get 1 - All Products",
  "Buy X Get 1 - Single Product": "Buy X Get 1 - Single Product",
  "By": "By",
  "By pressing the shift key on your keyboard you toggle the quick-select options on/off.": "By pressing the shift key on your keyboard you toggle the quick-select options on/off.",
  "Bypass 2FA": "Bypass 2FA",
  "Bypass State System": "Bypass State System",
  "Calendar Days": "Calendar Days",
  "Can Access Facilities": "Can Access Facilities",
  "Can Access Harvests": "Can Access Harvests",
  "Can Access Items": "Can Access Items",
  "Can Access Packages": "Can Access Packages",
  "Can Access Patients": "Can Access Patients",
  "Can Access Plants": "Can Access Plants",
  "Can Access Receipts": "Can Access Receipts",
  "Can Access Strains": "Can Access Strains",
  "Cancel": "Cancel",
  "Cancellations": "Cancellations",
  "Cannabinoids": "Cannabinoids",
  "Cannabis (Qty)": "Cannabis (Qty)",
  "Cannabis (Wgt)": "Cannabis (Wgt)",
  "Cannabis inputs will be limited to the selected batch": "Cannabis inputs will be limited to the selected batch",
  "Cannabis Product": "Cannabis Product",
  "Cannabis Product (Y/N)": "Cannabis Product (Y/N)",
  "Cannabis Products": "Cannabis Products",
  "Cannabis Sales": "Cannabis Sales",
  "Cannot discontinue because the package was converted or sold": "Cannot discontinue because the package was converted or sold",
  "Capture ReceiptIDs": "Capture ReceiptIDs",
  "Car": "Car",
  "Caregiver": "Caregiver",
  "Caregiver DOB": "Caregiver DOB",
  "Caregiver Email": "Caregiver Email",
  "Caregiver Expiration Date": "Caregiver Expiration Date",
  "Caregiver Name": "Caregiver Name",
  "Caregiver Notes": "Caregiver Notes",
  "Caregiver Phone": "Caregiver Phone",
  "Caregiver StateId": "Caregiver StateId",
  "Caregivers and patients earn and spend the same loyalty.": "Caregivers and patients earn and spend the same loyalty.",
  "Cars": "Cars",
  "Cars updated successfully": "Cars updated successfully",
  "Cart Avg": "Cart Avg",
  "Cartridge": "Cartridge",
  "Caryophyllene Oxide": "Caryophyllene Oxide",
  "Cash": "Cash",
  "Cash Activity": "Cash Activity",
  "Cash Back from Debit": "Cash Back from Debit",
  "Cash Back From Debit (-)": "Cash Back From Debit (-)",
  "Cash Counted": "Cash Counted",
  "Cash Deposits": "Cash Deposits",
  "Cash Drop": "Cash Drop",
  "Cash Income": "Cash Income",
  "Cash Payments": "Cash Payments",
  "Cash Sales": "Cash Sales",
  "Cashflow": "Cashflow",
  "Cashier": "Cashier",
  "Cashier Title": "Cashier Title",
  "Categories": "Categories",
  "Categories updated successfully": "Categories updated successfully",
  "Categories will also need to be assigned to limit categories in order for them to count against a specific limit.  This is configurable in Maintenance > Categories.": "Categories will also need to be assigned to limit categories in order for them to count against a specific limit.  This is configurable in Maintenance > Categories.",
  "Category": "Category",
  "Category Name": "Category Name",
  "Category Summary": "Category Summary",
  "CBD Content": "CBD Content",
  "Cell Phone": "Cell Phone",
  "Change": "Change",
  "Change Permissions": "Change Permissions",
  "Change Product": "Change Product",
  "Change to passwords in the \"Users\" section.": "Change to passwords in the \"Users\" section.",
  "Changing the product on this package will update all active packages that share Package ID in this location": "Changing the product on this package will update all active packages that share Package ID in this location",
  "Check": "Check",
  "Check out check": "Check out check",
  "Check out credit card": "Check out credit card",
  "Check out debit": "Check out debit",
  "Check Patient Allotments": "Check Patient Allotments",
  "Chemical Residue": "Chemical Residue",
  "Choose a merge survivor": "Choose a merge survivor",
  "Choose File": "Choose File",
  "City": "City",
  "Clear": "Clear",
  "Clear Menu": "Clear Menu",
  "Clear Search": "Clear Search",
  "Click the checkbox on the left side of the table": "Click the checkbox on the left side of the table",
  "Click to select from list": "Click to select from list",
  "Click to upload Image": "Click to upload Image",
  "Client Download": "Client Download",
  "Client Secret": "Client Secret",
  "ClientId": "ClientId",
  "Clock In": "Clock In",
  "Clock Out": "Clock Out",
  "Clocked In": "Clocked In",
  "Clocked Out": "Clocked Out",
  "Clone": "Clone",
  "Clones": "Clones",
  "Close": "Close",
  "Close Date": "Close Date",
  "Close Out": "Close Out",
  "Close Reconciliation": "Close Reconciliation",
  "Closed Batches": "Closed Batches",
  "Closed On": "Closed On",
  "Closing Plant Count": "Closing Plant Count",
  "Closing Report": "Closing Report",
  "Closing report failed to load": "Closing report failed to load",
  "Closing report loaded successfully": "Closing report loaded successfully",
  "Code": "Code",
  "Code / License Number": "Code / License Number",
  "Collect Data": "Collect Data",
  "Color Styling": "Color Styling",
  "Combine": "Combine",
  "Combine Caregiver and Patient Loyalty": "Combine Caregiver and Patient Loyalty",
  "Comment": "Comment",
  "Comments": "Comments",
  "Company": "Company",
  "Company created successfully": "Company created successfully",
  "Company Flag": "Company Flag",
  "Company ID": "Company ID",
  "Company info": "Company info",
  "Company Name": "Company Name",
  "Compare Inventory Rooms": "Compare Inventory Rooms",
  "Complete Order": "Complete Order",
  "Completed": "Completed",
  "Computer": "Computer",
  "Concentrate": "Concentrate",
  "Concentrates": "Concentrates",
  "Cones": "Cones",
  "Confirm": "Confirm",
  "Confirm retire": "Confirm retire",
  "Confirm unretire": "Confirm unretire",
  "Connect": "Connect",
  "Connect this LeafLogix location with": "Connect this LeafLogix location with",
  "Connection Settings": "Connection Settings",
  "Contact Email": "Contact Email",
  "Contact Name": "Contact Name",
  "Contact Phone": "Contact Phone",
  "Contaminants": "Contaminants",
  "Conversion Date": "Conversion Date",
  "Conversion Error": "Conversion Error",
  "Convert": "Convert",
  "Convert Clones to Plants": "Convert Clones to Plants",
  "Convert to Plant(s)": "Convert to Plant(s)",
  "converted from": "converted from",
  "converted into": "converted into",
  "Converted packages": "Converted packages",
  "Copy": "Copy",
  "Copy could not be created": "Copy could not be created",
  "Copy Created": "Copy Created",
  "Copy Label": "Copy Label",
  "Cost": "Cost",
  "Cost of Goods": "Cost of Goods",
  "Cost of Goods Sold": "Cost of Goods Sold",
  "Cost Per Unit": "Cost Per Unit",
  "Could not add item": "Could not add item",
  "Could not autofulfill item": "Could not autofulfill item",
  "Could not create a new reconciliation": "Could not create a new reconciliation",
  "Could not create group": "Could not create group",
  "Could not create sample": "Could not create sample",
  "Could not load batch details.": "Could not load batch details.",
  "Could not load data": "Could not load data",
  "Could not load journal entries.": "Could not load journal entries.",
  "Could not load notes": "Could not load notes",
  "Could not load packages": "Could not load packages",
  "Could not load plants for batch": "Could not load plants for batch",
  "Could not load users assigned to group.": "Could not load users assigned to group.",
  "Could not move plants": "Could not move plants",
  "Could not parse file": "Could not parse file",
  "Could not save features": "Could not save features",
  "Could not save forms": "Could not save forms",
  "Could not save sprout configuration!": "Could not save sprout configuration!",
  "Could not save table layout": "Could not save table layout",
  "Could not set time clock": "Could not set time clock",
  "Could not update journal entry": "Could not update journal entry",
  "Could not update plant group.": "Could not update plant group.",
  "Could not update plant mother status.": "Could not update plant mother status.",
  "Could not update plant mother.": "Could not update plant mother.",
  "Could not update plant stage.": "Could not update plant stage.",
  "Could not update settings": "Could not update settings",
  "Count": "Count",
  "Counted By": "Counted By",
  "Coupon Code": "Coupon Code",
  "Coupon Name": "Coupon Name",
  "Coupon Orders": "Coupon Orders",
  "Coupon updated": "Coupon updated",
  "Coupon Value ($)": "Coupon Value ($)",
  "Coupons": "Coupons",
  "Create": "Create",
  "Create Batch": "Create Batch",
  "Create Company": "Create Company",
  "Create Group": "Create Group",
  "Create Lab Sample": "Create Lab Sample",
  "Create Manifest": "Create Manifest",
  "Create New": "Create New",
  "Create New Batch": "Create New Batch",
  "Create New Mother Plant": "Create New Mother Plant",
  "Create New Pricing Tier": "Create New Pricing Tier",
  "Create New Recipe Batch": "Create New Recipe Batch",
  "Create Package": "Create Package",
  "Create Packages": "Create Packages",
  "Create Plant Reconciliation": "Create Plant Reconciliation",
  "Create Product": "Create Product",
  "Create Purchase Order": "Create Purchase Order",
  "Create Quantity": "Create Quantity",
  "Create waste on plant retirement": "Create waste on plant retirement",
  "Created": "Created",
  "Created By": "Created By",
  "Created new package.": "Created new package.",
  "Created On": "Created On",
  "Created Package Id": "Created Package Id",
  "Credit": "Credit",
  "Credit Sales": "Credit Sales",
  "Ctrl key": "Ctrl key",
  "Cultivation": "Cultivation",
  "Cultivation Stage": "Cultivation Stage",
  "Cultivation System": "Cultivation System",
  "Cultivator": "Cultivator",
  "Current Balance": "Current Balance",
  "Current calendar month": "Current calendar month",
  "Current calendar week": "Current calendar week",
  "Current Phase": "Current Phase",
  "Current Room": "Current Room",
  "Current Step": "Current Step",
  "Cust Cnt": "Cust Cnt",
  "Custom Entries": "Custom Entries",
  "Custom Result Name and Value": "Custom Result Name and Value",
  "Customer": "Customer",
  "Customer Files": "Customer Files",
  "Customer ID": "Customer ID",
  "Customer List Count": "Customer List Count",
  "Customer Name": "Customer Name",
  "Customer Notes": "Customer Notes",
  "Customer on Receipt": "Customer on Receipt",
  "Customer profile (back office)": "Customer profile (back office)",
  "Customer profile (Register)": "Customer profile (Register)",
  "Customer Summary": "Customer Summary",
  "Customer Title": "Customer Title",
  "Customer To Bill": "Customer To Bill",
  "Customer Type": "Customer Type",
  "Customer Types": "Customer Types",
  "Customer Types Updated": "Customer Types Updated",
  "Customer updated": "Customer updated",
  "Customers": "Customers",
  "Customers by Budtender": "Customers by Budtender",
  "Customers Merged": "Customers Merged",
  "Cycle Days": "Cycle Days",
  "Daily Activities": "Daily Activities",
  "Daily Snapshot": "Daily Snapshot",
  "Data Entry Error": "Data Entry Error",
  "Data failed to update": "Data failed to update",
  "Data Field": "Data Field",
  "Date": "Date",
  "Date Added": "Date Added",
  "Date Completed:": "Date Completed:",
  "Date Created": "Date Created",
  "Date Entered": "Date Entered",
  "Date Exited": "Date Exited",
  "Date of Birth": "Date of Birth",
  "Date Of Birth": "Date Of Birth",
  "Date Planted": "Date Planted",
  "Date Processed": "Date Processed",
  "Date Received": "Date Received",
  "Date Started": "Date Started",
  "Date:": "Date:",
  "days": "days",
  "Days In Cycle": "Days In Cycle",
  "Days In Flowering": "Days In Flowering",
  "Days in Phase": "Days in Phase",
  "Days In Phase": "Days In Phase",
  "Days In Vegetation": "Days In Vegetation",
  "Days Supply": "Days Supply",
  "DEA Number": "DEA Number",
  "Deactivate": "Deactivate",
  "Dear Valued Client,": "Dear Valued Client,",
  "Debit": "Debit",
  "Debit Sales": "Debit Sales",
  "Default label": "Default label",
  "Default Label": "Default Label",
  "Default Label Print": "Default Label Print",
  "Default label printer": "Default label printer",
  "Default Location": "Default Location",
  "Default POS Status": "Default POS Status",
  "Default Pricing Tier": "Default Pricing Tier",
  "Default Receipt Print": "Default Receipt Print",
  "Default receipt printer": "Default receipt printer",
  "Default Status": "Default Status",
  "Default Unit": "Default Unit",
  "Default Unit (g, kg, lbs)": "Default Unit (g, kg, lbs)",
  "Defect": "Defect",
  "De-Leaf Wet Weight": "De-Leaf Wet Weight",
  "Delete": "Delete",
  "DELETE": "DELETE",
  "Delete Batch": "Delete Batch",
  "Delete item from Receive?": "Delete item from Receive?",
  "Delete Receive?": "Delete Receive?",
  "Delete This Receive?": "Delete This Receive?",
  "Deleting Batch": "Deleting Batch",
  "Delivered By": "Delivered By",
  "Delivered On": "Delivered On",
  "Delivery Options": "Delivery Options",
  "Departure": "Departure",
  "Deposit": "Deposit",
  "Deposit Account": "Deposit Account",
  "Deposited At": "Deposited At",
  "Deposits": "Deposits",
  "Description": "Description",
  "Description is required!": "Description is required!",
  "Destination Batch": "Destination Batch",
  "Destroy": "Destroy",
  "Destroy Inventory": "Destroy Inventory",
  "Destroy Seedling": "Destroy Seedling",
  "Destroyed": "Destroyed",
  "Destroyed Comment": "Destroyed Comment",
  "Destroyed Date From": "Destroyed Date From",
  "Destroyed Date To": "Destroyed Date To",
  "Detail": "Detail",
  "Detail Update": "Detail Update",
  "Details": "Details",
  "Difference": "Difference",
  "Directions": "Directions",
  "Discontinue": "Discontinue",
  "Discount": "Discount",
  "Discount Applied To": "Discount Applied To",
  "Discount Configuration": "Discount Configuration",
  "Discount Description": "Discount Description",
  "Discount Group": "Discount Group",
  "Discount Groups": "Discount Groups",
  "Discount Id": "Discount Id",
  "Discount Method": "Discount Method",
  "Discount Over Price": "Discount Over Price",
  "Discount Type": "Discount Type",
  "Discount updated successfully": "Discount updated successfully",
  "Discounted": "Discounted",
  "Discounted Products": "Discounted Products",
  "Discounts": "Discounts",
  "Display rows per table": "Display rows per table",
  "Displays the purchase order section": "Displays the purchase order section",
  "Distributor": "Distributor",
  "Distributor - Tax Exempt": "Distributor - Tax Exempt",
  "Do not allow patients to check in with an expired Driver's License.": "Do not allow patients to check in with an expired Driver's License.",
  "DOB": "DOB",
  "Doctors": "Doctors",
  "Documentation": "Documentation",
  "Doing Business As": "Doing Business As",
  "Don't Move": "Don't Move",
  "Dosage": "Dosage",
  "Dosages": "Dosages",
  "Download": "Download",
  "DPI": "DPI",
  "Drink": "Drink",
  "Driver": "Driver",
  "Drivers": "Drivers",
  "Drivers License": "Drivers License",
  "Drivers License Expiration": "Drivers License Expiration",
  "Drivers License Expiration Date": "Drivers License Expiration Date",
  "Drivers updated successfully": "Drivers updated successfully",
  "Drop": "Drop",
  "Drop Cash": "Drop Cash",
  "Dry waste": "Dry waste",
  "Dry Weights": "Dry Weights",
  "Due Customer": "Due Customer",
  "Duplicate Category": "Duplicate Category",
  "Duplicate DEA Number": "Duplicate DEA Number",
  "Duplicate Doctor Name": "Duplicate Doctor Name",
  "Duplicate Doctor State Id Number": "Duplicate Doctor State Id Number",
  "Duplicate NPI Number": "Duplicate NPI Number",
  "Duplicate Product Category": "Duplicate Product Category",
  "Ecom Delivery Notes": "Ecom Delivery Notes",
  "E-Commerce": "E-Commerce",
  "Ecommerce Styling": "Ecommerce Styling",
  "Edible": "Edible",
  "Edibles": "Edibles",
  "Edit": "Edit",
  "Edit / Close": "Edit / Close",
  "Edit Address": "Edit Address",
  "Edit Cart": "Edit Cart",
  "Edit Cart Partial": "Edit Cart Partial",
  "Edit Company": "Edit Company",
  "Edit Company Information": "Edit Company Information",
  "Edit Days Supply On Completed Transactions": "Edit Days Supply On Completed Transactions",
  "Edit Details": "Edit Details",
  "Edit Discounts": "Edit Discounts",
  "Edit Driver": "Edit Driver",
  "Edit Driver Details": "Edit Driver Details",
  "Edit Notes": "Edit Notes",
  "Edit Printer": "Edit Printer",
  "Edit Product": "Edit Product",
  "Effective Permissions": "Effective Permissions",
  "Electronic Payments": "Electronic Payments",
  "E-mail address": "E-mail address",
  "Email Address": "Email Address",
  "Email Order": "Email Order",
  "Email Receipt From": "Email Receipt From",
  "Email Sent": "Email Sent",
  "Email Settings": "Email Settings",
  "Employee Time Clocks": "Employee Time Clocks",
  "Empty manifest can not be completed": "Empty manifest can not be completed",
  "EMPTY WEIGHT": "EMPTY WEIGHT",
  "Enable +/- Buttons in Cart": "Enable +/- Buttons in Cart",
  "Enable +/- Buttons in Search": "Enable +/- Buttons in Search",
  "Enable Allotment Check": "Enable Allotment Check",
  "Enable Customer SMS": "Enable Customer SMS",
  "Enable EcomV2": "Enable EcomV2",
  "Enable integration with IDScan.net": "Enables ID Scan integration + ID Check-In option in the POS",
  "Enable integration with sprout": "Enable integration with sprout",
  "Enable METRC Tag Control": "Enable METRC Tag Control",
  "Enable Nutrient Tracking": "Enable Nutrient Tracking",
  "Enable Plant Search": "Enable Plant Search",
  "Enable Quickbooks Integration": "Enable Quickbooks Integration",
  "Enable Recreational Use": "Enable Recreational Use",
  "Enable Register Adjustments": "Enable Register Adjustments",
  "Enable Spin Debit Integration": "Enable Spin Debit Integration",
  "Enable Sprout Integration": "Enable Sprout Integration",
  "Enabled": "Enabled",
  "Enables the METRC Tag Control for the Tag Manager": "Enables the METRC Tag Control for the Tag Manager",
  "End Date": "End Date",
  "End Time": "End Time",
  "Ending Balance": "Ending Balance",
  "Ending Cash Balance": "Ending Cash Balance",
  "Enforce Coupon Value Greater Than Items": "Enforce Coupon Value Greater Than Items",
  "Enforce POS Login Permission": "Enforce POS Login Permission",
  "Enter 3 or 4 of 11": "Enter 3 or 4 of 11",
  "Enter Actual Weight": "Enter Actual Weight",
  "Enter Cash Details": "Enter Cash Details",
  "Enter Dry Weights": "Enter Dry Weights",
  "Enter Plant Weights": "Enter Plant Weights",
  "Enter Wet Weights": "Enter Wet Weights",
  "Entity": "Entity",
  "Entity Count": "Entity Count",
  "Environment": "Environment",
  "EPay Sales": "EPay Sales",
  "Equiv. External": "Equiv. External",
  "Error adding plants.": "Error adding plants.",
  "Error applying nutrients": "Error applying nutrients",
  "Error Authenticating with Quickbooks": "Error Authenticating with Quickbooks",
  "Error closing reconciliation": "Error closing reconciliation",
  "Error combining packages": "Error combining packages",
  "Error communicating with Quickbooks": "Error communicating with Quickbooks",
  "Error connecting Weedmaps location!": "Error connecting Weedmaps location!",
  "Error converting clones": "Error converting clones",
  "Error converting package": "Error converting package",
  "Error creating company": "Error creating company",
  "Error creating manifest": "Error creating manifest",
  "Error creating order": "Error creating order",
  "Error creating package": "Error creating package",
  "Error deleting batch": "Error deleting batch",
  "Error deleting item from order": "Error deleting item from order",
  "Error deleting package": "Error deleting package",
  "Error deleting plant reconciliation": "Error deleting plant reconciliation",
  "Error deleting user group": "Error deleting user group",
  "Error destroying waste": "Error destroying waste",
  "Error finishing step.": "Error finishing step.",
  "Error fulfilling order": "Error fulfilling order",
  "Error generating Package IDs": "Error generating Package IDs",
  "Error getting all effective permissions": "Error getting all effective permissions",
  "Error getting customer data": "Error getting customer data",
  "Error getting customer type data": "Error getting customer type data",
  "Error getting manifest": "Error getting manifest",
  "Error getting oauth server!": "Error getting oauth server!",
  "Error getting order info": "Error getting order info",
  "Error getting plant attributes": "Error getting plant attributes",
  "Error getting plant group identifier": "Error getting plant group identifier",
  "Error Getting Receipt Export File": "Error Getting Receipt Export File",
  "Error Getting Sales Receipt Log": "Error Getting Sales Receipt Log",
  "Error Getting Settings": "Error Getting Settings",
  "Error loading available nutrients": "Error loading available nutrients",
  "Error loading discrepancies": "Error loading discrepancies",
  "Error Loading Hypur Settings": "Error Loading Hypur Settings",
  "Error loading METRC Logs": "Error loading METRC Logs",
  "Error loading METRC Settings": "Error loading METRC Settings",
  "Error loading METRC Statistics": "Error loading METRC Statistics",
  "Error loading nutrient log": "Error loading nutrient log",
  "Error loading plant reconciliations": "Error loading plant reconciliations",
  "Error loading preview": "Error loading preview",
  "Error loading pricing tier": "Error loading pricing tier",
  "Error loading Receipt Reconciliation": "Error loading Receipt Reconciliation",
  "Error loading reconciliation details": "Error loading reconciliation details",
  "Error loading Spring Big Settings": "Error loading Spring Big Settings",
  "Error loading waste": "Error loading waste",
  "Error loading Weedmaps Location Data!": "Error loading Weedmaps Location Data!",
  "Error loading Weedmaps settings": "Error loading Weedmaps settings",
  "Error merging customers": "Error merging customers",
  "Error moving packages": "Error moving packages",
  "Error moving plants": "Error moving plants",
  "Error parsing lab result url": "Error parsing lab result url",
  "Error printing label": "Error printing label",
  "Error printing labels": "Error printing labels",
  "Error printing plant labels": "Error printing plant labels",
  "Error pulling": "Error pulling",
  "Error pushing": "Error pushing",
  "Error reconciling item": "Error reconciling item",
  "Error remediating package": "Error remediating package",
  "Error removing field": "Error removing field",
  "Error removing items": "Error removing items",
  "Error removing product tax category": "Error removing product tax category",
  "Error removing tax rate": "Error removing tax rate",
  "Error reporting": "Error reporting",
  "Error resetting! Please contact LL support": "Error resetting! Please contact LL support",
  "Error restoring": "Error restoring",
  "Error retreiving permission groups": "Error retreiving permission groups",
  "Error retrieving lab results": "Error retrieving lab results",
  "Error Saving Hypur Settings": "Error Saving Hypur Settings",
  "Error saving loyalty details": "Error saving loyalty details",
  "Error saving notes": "Error saving notes",
  "Error saving plant weights": "Error saving plant weights",
  "Error saving plany attributes": "Error saving plany attributes",
  "Error Saving QBO Settings": "Error Saving QBO Settings",
  "Error Saving QuickBooks Settings": "Error Saving QuickBooks Settings",
  "Error saving Spring Big Settings": "Error saving Spring Big Settings",
  "Error saving Weedmaps settings": "Error saving Weedmaps settings",
  "Error Sending Invoice to Quickbooks": "Error Sending Invoice to Quickbooks",
  "Error sending message(s)": "Error sending message(s)",
  "Error Sending Receipts to Quickbooks": "Error Sending Receipts to Quickbooks",
  "Error setting address": "Error setting address",
  "Error starting step.": "Error starting step.",
  "Error sublotting package": "Error sublotting package",
  "Error synchronizing members": "Error synchronizing members",
  "Error syncing inventory ids": "Error syncing inventory ids",
  "Error syncing with Quickbooks": "Error syncing with Quickbooks",
  "Error syncing!": "Error syncing!",
  "Error undoing finish step.": "Error undoing finish step.",
  "Error updating coupon": "Error updating coupon",
  "Error updating customer": "Error updating customer",
  "Error updating discount": "Error updating discount",
  "Error Updating Harvest": "Error Updating Harvest",
  "Error updating label field.": "Error updating label field.",
  "Error updating manifest item": "Error updating manifest item",
  "Error updating package": "Error updating package",
  "Error updating product": "Error updating product",
  "Error updating user password": "Error updating user password",
  "Everything attached to this batch will be moved to another batch with the same strain.": "Everything attached to this batch will be moved to another batch with the same strain.",
  "Example": "Example",
  "Example: .1 Accrual Rate on a $100 purchase will yield 10 points.": "Example: .1 Accrual Rate on a $100 purchase will yield 10 points.",
  "Existing Amount": "Existing Amount",
  "Existing permissions have been migrated to this new systems.  If you're encountering any issues with your access or need help getting set up please let your administrators know and/or send a message to us at support@leaflogix.com.": "Existing permissions have been migrated to this new systems.  If you're encountering any issues with your access or need help getting set up please let your administrators know and/or send a message to us at support@leaflogix.com.",
  "Existing seeds": "Existing seeds",
  "ExpDate": "ExpDate",
  "Expected": "Expected",
  "Expected Arrival Date": "Expected Arrival Date",
  "Expected Quantity": "Expected Quantity",
  "Expense Account": "Expense Account",
  "Expiration Date": "Expiration Date",
  "Expire": "Expire",
  "Export": "Export",
  "Export Inventory Reconciliation": "Export Inventory Reconciliation",
  "Export Table as CSV": "Export Table as CSV",
  "External": "External",
  "External Category": "External Category",
  "External ID": "External ID",
  "Extraction Date": "Extraction Date",
  "Fail": "Fail",
  "Failed to add items": "Failed to add items",
  "Failed to archive customer": "Failed to archive customer",
  "Failed to complete order": "Failed to complete order",
  "Failed to create batch package": "Failed to create batch package",
  "Failed to create location": "Failed to create location",
  "Failed to create purchase order": "Failed to create purchase order",
  "Failed to delete discount": "Failed to delete discount",
  "Failed to delete room": "Failed to delete room",
  "Failed to destroy seedling count": "Failed to destroy seedling count",
  "Failed to get plant fields": "Failed to get plant fields",
  "Failed to get plant info": "Failed to get plant info",
  "Failed to get user settings": "Failed to get user settings",
  "Failed to harvest plants": "Failed to harvest plants",
  "Failed to Load Customers": "Failed to Load Customers",
  "Failed to load looker": "Failed to load looker",
  "Failed to load master categories": "Failed to load master categories",
  "Failed to load orders": "Failed to load orders",
  "Failed to load purchase limits": "Failed to load purchase limits",
  "Failed to load Purchase Order": "Failed to load Purchase Order",
  "Failed to load terminals for room": "Failed to load terminals for room",
  "Failed to Load transactions": "Failed to Load transactions",
  "Failed to load users": "Failed to load users",
  "Failed to print Invoice": "Failed to print Invoice",
  "Failed to print label": "Failed to print label",
  "Failed to print Manifest": "Failed to print Manifest",
  "Failed to remove discount": "Failed to remove discount",
  "Failed to Re-Open order": "Failed to Re-Open order",
  "Failed to save info": "Failed to save info",
  "Failed to save plant info": "Failed to save plant info",
  "Failed to save purchase limit types": "Failed to save purchase limit types",
  "Failed to send email": "Failed to send email",
  "Failed to update cars": "Failed to update cars",
  "Failed to update categories": "Failed to update categories",
  "Failed to update drivers": "Failed to update drivers",
  "Failed to update inventory status": "Failed to update inventory status",
  "Failed to update labels": "Failed to update labels",
  "Failed to update reconciliation": "Failed to update reconciliation",
  "Failed to update rooms": "Failed to update rooms",
  "Failed to update size item": "Failed to update size item",
  "Failed to update strains": "Failed to update strains",
  "Failed to update vendors": "Failed to update vendors",
  "Failure Message": "Failure Message",
  "Fax Number": "Fax Number",
  "Feature Flags": "Feature Flags",
  "Features saved": "Features saved",
  "Feburary Release": "Feburary Release",
  "Female": "Female",
  "Fetch": "Fetch",
  "Field": "Field",
  "Field removed": "Field removed",
  "Fields": "Fields",
  "File exceeds maximum size of 2MB, please select a smaller file.": "File exceeds maximum size of 2MB, please select a smaller file.",
  "File Expiration Date (optional)": "File Expiration Date (optional)",
  "File Type": "File Type",
  "Filter": "Filter",
  "Filter Mother Plant": "Filter Mother Plant",
  "Filter Plant Stage": "Filter Plant Stage",
  "Filter Plant Type": "Filter Plant Type",
  "Filter Plants": "Filter Plants",
  "Filter Reports": "Filter Reports",
  "Filter Strain": "Filter Strain",
  "Filter Table": "Filter Table",
  "Finish": "Finish",
  "Finish Harvest": "Finish Harvest",
  "Five digits number": "Five digits number",
  "Flower": "Flower",
  "Flower Eq.": "Flower Eq.",
  "Flower Equiv": "Flower Equiv",
  "Flower Equivalent": "Flower Equivalent",
  "Flowering Ended": "Flowering Ended",
  "Flowering Started": "Flowering Started",
  "Font Size": "Font Size",
  "Footer Settings": "Footer Settings",
  "Footer Text": "Footer Text",
  "For": "For",
  "Form Validation": "Form Validation",
  "Format": "Format",
  "Forms": "Forms",
  "Forms saved": "Forms saved",
  "Four digits number": "Four digits number",
  "Four digits year": "Four digits year",
  "FreeOrders": "FreeOrders",
  "Fresh Plant": "Fresh Plant",
  "Friday": "Friday",
  "from": "from",
  "From": "From",
  "FROM": "FROM",
  "From Loc": "From Loc",
  "From Location": "From Location",
  "From Lsp": "From Lsp",
  "From Package(s)": "From Package(s)",
  "From Room": "From Room",
  "From:": "From:",
  "Fulfill": "Fulfill",
  "Fulfill Order": "Fulfill Order",
  "Fulfilled": "Fulfilled",
  "Full Container Weight": "Full Container Weight",
  "Full Name": "Full Name",
  "FULL WEIGHT": "FULL WEIGHT",
  "Fullfillment Notes": "Fullfillment Notes",
  "Gear": "Gear",
  "Gen PIN": "Gen PIN",
  "Gender": "Gender",
  "General Identifiers": "General Identifiers",
  "General Settings": "General Settings",
  "Generate": "Generate",
  "Generate Unique Id Per Item": "Generate Unique Id Per Item",
  "Generates a unique id per qty item in inventory": "Generates a unique id per qty item in inventory",
  "Generating PDF…": "Generating PDF…",
  "Get authorized!": "Get authorized!",
  "Gives stores the ability to specify what form fields are reqired / not required": "Gives stores the ability to specify what form fields are reqired / not required",
  "Go": "Go",
  "Go for it!": "Go for it!",
  "Gram": "Gram",
  "Grams / Concentration": "Grams / Concentration",
  "Grid Layout": "Grid Layout",
  "Gross Margin": "Gross Margin",
  "Gross Profit": "Gross Profit",
  "Gross Sales": "Gross Sales",
  "Gross Weight": "Gross Weight",
  "Group": "Group",
  "Group Description": "Group Description",
  "Group Name": "Group Name",
  "GroupPlantedDate": "GroupPlantedDate",
  "Growth Phase": "Growth Phase",
  "Growth Phases": "Growth Phases",
  "Guest Name 2 lines": "Guest Name 2 lines",
  "Guestlist Status": "Guestlist Status",
  "Guestlist Status Color Coding": "Guestlist Status Color Coding",
  "Guestlist Status Layout": "Guestlist Status Layout",
  "Harvest": "Harvest",
  "Harvest Date": "Harvest Date",
  "Harvest Details": "Harvest Details",
  "Harvest Package": "Harvest Package",
  "Harvest Plants": "Harvest Plants",
  "Harvest Units": "Harvest Units",
  "Harvest Updated Successfully": "Harvest Updated Successfully",
  "Harvested": "Harvested",
  "Harvested Weights": "Harvested Weights",
  "Harvesting": "Harvesting",
  "Has ECommerce": "Has ECommerce",
  "Has Enterprise Reporting": "Has Enterprise Reporting",
  "Has Looker Explorer": "Has Looker Explorer",
  "Has Purchase Orders": "Has Purchase Orders",
  "Having issues? Make sure LL is configured as your POS provider on Weedmaps.com first. After that, use the 'Start Over' button to connect again.": "Having issues? Make sure LL is configured as your POS provider on Weedmaps.com first. After that, use the 'Start Over' button to connect again.",
  "Header Settings": "Header Settings",
  "Heavy Metal": "Heavy Metal",
  "Height": "Height",
  "Help": "Help",
  "Hidden?": "Hidden?",
  "Hide": "Hide",
  "Hide From POS": "Hide From POS",
  "Hide From POS?": "Hide From POS?",
  "Hide Inactive POS Customers": "Hide Inactive POS Customers",
  "Hide Prices To anonymous users": "Hide Prices To anonymous users",
  "Highest Priced Items": "Highest Priced Items",
  "Hire Date": "Hire Date",
  "Hired": "Hired",
  "History": "History",
  "How to select multiple items in a table": "How to select multiple items in a table",
  "hree digits number": "hree digits number",
  "https://md-eastlive-..... ": "https://md-eastlive-..... ",
  "https://md-live-.....": "https://md-live-.....",
  "https://md-westlive-.....": "https://md-westlive-.....",
  "Humulene": "Humulene",
  "Hybrid": "Hybrid",
  "Hypur Integration": "Hypur Integration",
  "Hypur Settings Saved": "Hypur Settings Saved",
  "ID Expiration Date": "ID Expiration Date",
  "ID Number": "ID Number",
  "Identification Image": "Identification Image",
  "Identifiers": "Identifiers",
  "If enabled, allows loyalty points earned to be increased based on additional criteria.": "If enabled, allows loyalty points earned to be increased based on additional criteria.",
  "If enabled, any immature plants from this point on will be created as individual plants": "If enabled, any immature plants from this point on will be created as individual plants",
  "If enabled, inventory reconciliation functionality updates the inventory in one request instead of one request per product.": "If enabled, inventory reconciliation functionality updates the inventory in one request instead of one request per product.",
  "If enabled, Product Master will allow different Flower Equivilent to be set for both Med and Rec sales.": "If enabled, Product Master will allow different Flower Equivilent to be set for both Med and Rec sales.",
  "If multiple customer types are enabled on your system (Medical vs Recreational, for example), you will also see a breakdown of sales by customer type.": "If multiple customer types are enabled on your system (Medical vs Recreational, for example), you will also see a breakdown of sales by customer type.",
  "If page does not redirect after logging into weedmaps, click Continue": "If page does not redirect after logging into weedmaps, click Continue",
  "Image URL": "Image URL",
  "Images": "Images",
  "In an  effort to lead the cannabis industry with the most robust and reliable software in the market, we have completed the full migration of our seed to sale software to our latest supported technologies. ": "In an  effort to lead the cannabis industry with the most robust and reliable software in the market, we have completed the full migration of our seed to sale software to our latest supported technologies. ",
  "In Use": "In Use",
  "In-Active": "In-Active",
  "Include Detail": "Include Detail",
  "Include Labs - Include THC & CBD from Product Master - only works with milligrams and %": "Include Labs - Include THC & CBD from Product Master - only works with milligrams and %",
  "Include Non-cannabis Items": "Include Non-cannabis Items",
  "Income Account": "Income Account",
  "Income Summary": "Income Summary",
  "Incremental": "Incremental",
  "Infused Non-Edibles": "Infused Non-Edibles",
  "Ingredient Details": "Ingredient Details",
  "Ingredients": "Ingredients",
  "Initial Plant Count": "Initial Plant Count",
  "Input": "Input",
  "Inputs": "Inputs",
  "Instructions": "Instructions",
  "Instructions:": "Instructions:",
  "Integration Data": "Integration Data",
  "Integration Reconciliation": "Integration Reconciliation",
  "Integrations": "Integrations",
  "Into Room": "Into Room",
  "Inv Type": "Inv Type",
  "Invalid Plant Scan. Plant Id is not in system.": "Invalid Plant Scan. Plant Id is not in system.",
  "Invalid Scan. Table is not in system.": "Invalid Scan. Table is not in system.",
  "Inventory": "Inventory",
  "Inventory %": "Inventory %",
  "Inventory Action Wheel Change": "Inventory Action Wheel Change",
  "Inventory Adjustment Reasons": "Inventory Adjustment Reasons",
  "Inventory Adjustments": "Inventory Adjustments",
  "Inventory Assets": "Inventory Assets",
  "Inventory Date": "Inventory Date",
  "Inventory Destroyed": "Inventory Destroyed",
  "Inventory Id": "Inventory Id",
  "Inventory Ids Synced": "Inventory Ids Synced",
  "Inventory items removed": "Inventory items removed",
  "Inventory Lookup": "Inventory Lookup",
  "Inventory Recon": "Inventory Recon",
  "Inventory Reconciliation": "Inventory Reconciliation",
  "Inventory Reconciliation Update In Bulk": "Inventory Reconciliation Update In Bulk",
  "Inventory Status": "Inventory Status",
  "INVENTORY STATUS": "INVENTORY STATUS",
  "Inventory status updated successfully": "Inventory status updated successfully",
  "Inventory System": "Inventory System",
  "Invoice Accounts": "Invoice Accounts",
  "Invoice Comments": "Invoice Comments",
  "Invoice printed": "Invoice printed",
  "Invoice Successfully Uploaded": "Invoice Successfully Uploaded",
  "Is Available Online": "Is Available Online",
  "Is Blind?": "Is Blind?",
  "Is Cannabis": "Is Cannabis",
  "Is Cannabis?": "Is Cannabis?",
  "Is Coupon": "Is Coupon",
  "Is E-Commerce Room?": "Is E-Commerce Room?",
  "Is Enabled": "Is Enabled",
  "Is Enabled?": "Is Enabled?",
  "Is Finished Good": "Is Finished Good",
  "Is Finished?": "Is Finished?",
  "Is Inventory Room?": "Is Inventory Room?",
  "Is Mother": "Is Mother",
  "Is Nutrient": "Is Nutrient",
  "Is Nutrient?": "Is Nutrient?",
  "Is On Sale": "Is On Sale",
  "Is POS Available": "Is POS Available",
  "Is POS Room?": "Is POS Room?",
  "Is PreOrder Room?": "Is PreOrder Room?",
  "Is Quarantine Room?": "Is Quarantine Room?",
  "Is Retired": "Is Retired",
  "Is Taxable?": "Is Taxable?",
  "Is Waiting Room?": "Is Waiting Room?",
  "Issued By": "Issued By",
  "Item Count": "Item Count",
  "Item does not have a Batch Id": "Item does not have a Batch Id",
  "Item not found": "Item not found",
  "Item removed": "Item removed",
  "Item removed from manifest.": "Item removed from manifest.",
  "Item updated": "Item updated",
  "Items": "Items",
  "Items Added": "Items Added",
  "Items assigned to batch": "Items assigned to batch",
  "Items Per Package": "Items Per Package",
  "Items Selected": "Items Selected",
  "Items Sold": "Items Sold",
  "Joint Material Value": "Joint Material Value",
  "Journal": "Journal",
  "Journal Entries": "Journal Entries",
  "Journal entry updated": "Journal entry updated",
  "June Release #1": "June Release #1",
  "Keep header/navigation pinned top top of page while scrolling. Great for iframes.": "Keep header/navigation pinned top top of page while scrolling. Great for iframes.",
  "Key": "Key",
  "Keys": "Keys",
  "Keyword": "Keyword",
  "Kief Value": "Kief Value",
  "Lab": "Lab",
  "Lab Analysis": "Lab Analysis",
  "Lab Analysis Updated": "Lab Analysis Updated",
  "Lab Result Details": "Lab Result Details",
  "Lab Result URL (Scan a QR Code)": "Lab Result URL (Scan a QR Code)",
  "Lab results fetched": "Lab results fetched",
  "Lab results parsed": "Lab results parsed",
  "Lab Sample": "Lab Sample",
  "Lab Samples": "Lab Samples",
  "Label": "Label",
  "Label copied successfully!": "Label copied successfully!",
  "Label Copier": "Label Copier",
  "Label copy failed.": "Label copy failed.",
  "Label printed": "Label printed",
  "Label Printer": "Label Printer",
  "Label Printer?": "Label Printer?",
  "Label Settings": "Label Settings",
  "Label settings updated": "Label settings updated",
  "Label To Copy": "Label To Copy",
  "Labels": "Labels",
  "Labels printed": "Labels printed",
  "Labels updated successfully": "Labels updated successfully",
  "Landscape270": "Landscape270",
  "Landscape90": "Landscape90",
  "Last 30 days": "Last 30 days",
  "Last 4": "Last 4",
  "Last 7 days": "Last 7 days",
  "Last Sync": "Last Sync",
  "Layout": "Layout",
  "Leaf Logix E-Commerce": "Leaf Logix E-Commerce",
  "Leaf Logix is Beta Testing the New Cultivation system with expected full Release In January 2018.": "Leaf Logix is Beta Testing the New Cultivation system with expected full Release In January 2018.",
  "Leaf Logix is Beta Testing the New Inventory system with expected full release in January 2018.": "Leaf Logix is Beta Testing the New Inventory system with expected full release in January 2018.",
  "Leaf Logix is integrated with numerous third party applications. We have current integrations with CANPAY, HYPUR, LINX, LEAFLY, STICKY GUIDE, WEEDMAPS, SPRINGBIG, SPROUT & RELEAF. If you have questions on setting up an integration, please send a request to support@leaflogix.com": "Leaf Logix is integrated with numerous third party applications. We have current integrations with CANPAY, HYPUR, LINX, LEAFLY, STICKY GUIDE, WEEDMAPS, SPRINGBIG, SPROUT & RELEAF. If you have questions on setting up an integration, please send a request to support@leaflogix.com",
  "Leaf Logix is nearly ready with our E-Commerce site! Allow your customers to login, and place orders directly against your available product. Fulfill the placed orders through the POS 3.0 solution.": "Leaf Logix is nearly ready with our E-Commerce site! Allow your customers to login, and place orders directly against your available product. Fulfill the placed orders through the POS 3.0 solution.",
  "Leaf Logix is proud to announce our new Manifest system.  Linked to POS 3.0, our customers can now create a manifest for a wholesale order or customer delivery.": "Leaf Logix is proud to announce our new Manifest system.  Linked to POS 3.0, our customers can now create a manifest for a wholesale order or customer delivery.",
  "Leaf Logix is proud to announce the release of our Wholesale system. Using Leaf Logix E-Commerce or one of our 3rd party partners offering an E-Commerce site which is integrated to Leaf Logix. Allow your customers to place orders against your inventory.": "Leaf Logix is proud to announce the release of our Wholesale system. Using Leaf Logix E-Commerce or one of our 3rd party partners offering an E-Commerce site which is integrated to Leaf Logix. Allow your customers to place orders against your inventory.",
  "Leaf Logix POS 3.0 is officially released and operational.  We will post any updates of POS to POS 3.0 (Beta) so that you can get time to review the changes and train your staff prior to final release.  We will then update to permanent live release. Please note that our Beta version is looking at updating live data.": "Leaf Logix POS 3.0 is officially released and operational.  We will post any updates of POS to POS 3.0 (Beta) so that you can get time to review the changes and train your staff prior to final release.  We will then update to permanent live release. Please note that our Beta version is looking at updating live data.",
  "Leaf Logix Reporting": "Leaf Logix Reporting",
  "Leaf Logix Wholesale Solution": "Leaf Logix Wholesale Solution",
  "Leaf Waste Weight": "Leaf Waste Weight",
  "Leafly Category": "Leafly Category",
  "Leaves": "Leaves",
  "Lic Id": "Lic Id",
  "License #": "License #",
  "License No": "License No",
  "License Number": "License Number",
  "License Number (if different)": "License Number (if different)",
  "Limit Uses": "Limit Uses",
  "Limits can be configured in POS > Maintenance > Purchase Limits.  ": "Limits can be configured in POS > Maintenance > Purchase Limits.  ",
  "Limits saved": "Limits saved",
  "Limonene": "Limonene",
  "Linalool": "Linalool",
  "Line Item Discrepancy": "Line Item Discrepancy",
  "Line Item Settings": "Line Item Settings",
  "Line Item Total": "Line Item Total",
  "Line Items": "Line Items",
  "Linked Registers": "Linked Registers",
  "List Inventory IDs": "List Inventory IDs",
  "Listed Product": "Listed Product",
  "Lite Signup": "Lite Signup",
  "LL Data": "LL Data",
  "LL Item Count": "LL Item Count",
  "LL Required": "LL Required",
  "LL Sale Date": "LL Sale Date",
  "LL Transaction ID": "LL Transaction ID",
  "Loading": "Loading",
  "loading images": "loading images",
  "Loading Journal": "Loading Journal",
  "Loading Journal entries": "Loading Journal entries",
  "Loading label": "Loading label",
  "Location": "Location",
  "Location Created": "Location Created",
  "Location Info": "Location Info",
  "Location info saved": "Location info saved",
  "Location Name": "Location Name",
  "Location Options": "Location Options",
  "Location Specific Product Details": "Location Specific Product Details",
  "Location Styling": "Location Styling",
  "Location Type": "Location Type",
  "Login Id": "Login Id",
  "Login name already exists in system": "Login name already exists in system",
  "Logo Image": "Logo Image",
  "LOGOUT": "LOGOUT",
  "Logs": "Logs",
  "Looker": "Looker",
  "Lookup": "Lookup",
  "Lot": "Lot",
  "Lot #": "Lot #",
  "Lot Name / Batch ID": "Lot Name / Batch ID",
  "Lot No": "Lot No",
  "Low Inventory Threshold": "Low Inventory Threshold",
  "Lowest Priced Items": "Lowest Priced Items",
  "Loyalty": "Loyalty",
  "Loyalty Configuration": "Loyalty Configuration",
  "Loyalty details saved": "Loyalty details saved",
  "Loyalty Discount": "Loyalty Discount",
  "Loyalty Earned": "Loyalty Earned",
  "Loyalty Point Dollar Value": "Loyalty Point Dollar Value",
  "Loyalty Points": "Loyalty Points",
  "Loyalty Used": "Loyalty Used",
  "LoyaltyReturned": "LoyaltyReturned",
  "Lsp Address": "Lsp Address",
  "Lsp info saved": "Lsp info saved",
  "Main Background Color": "Main Background Color",
  "Main Background Image": "Main Background Image",
  "Maintain Items": "Maintain Items",
  "Maintain Rooms": "Maintain Rooms",
  "Maintain Strains": "Maintain Strains",
  "Maintenance": "Maintenance",
  "Make": "Make",
  "Make sure you are logged into YOUR DISPENSARIES weedmaps account before continuing!": "Make sure you are logged into YOUR DISPENSARIES weedmaps account before continuing!",
  "Male": "Male",
  "Manage Customer Types": "Manage Customer Types",
  "Manage Pin For Deposit": "Manage Pin For Deposit",
  "Management": "Management",
  "Manager Password": "Manager Password",
  "Manager Search Flag": "Manager Search Flag",
  "Manifest": "Manifest",
  "Manifest could not be removed": "Manifest could not be removed",
  "Manifest Created": "Manifest Created",
  "Manifest failed to generate": "Manifest failed to generate",
  "Manifest failed to save": "Manifest failed to save",
  "Manifest generated": "Manifest generated",
  "Manifest printed": "Manifest printed",
  "Manifest Removed": "Manifest Removed",
  "Manifest System": "Manifest System",
  "Manifest Types": "Manifest Types",
  "Manifest updated": "Manifest updated",
  "Manifest updated.": "Manifest updated.",
  "Manifests": "Manifests",
  "Manual": "Manual",
  "Manufacturer": "Manufacturer",
  "Manufacturing Module": "Manufacturing Module",
  "March Release #1": "March Release #1",
  "Massachusetts Adult Use": "Massachusetts Adult Use",
  "Master Category": "Master Category",
  "Max Purchasable Per Transaction": "Max Purchasable Per Transaction",
  "Max Purchasble Per Transaction": "Max Purchasble Per Transaction",
  "Max use": "Max use",
  "MD Label No": "MD Label No",
  "Med Flower Equiv": "Med Flower Equiv",
  "Med Per Gram": "Med Per Gram",
  "Med Total": "Med Total",
  "Medical": "Medical",
  "Medical - Tax Exempt": "Medical - Tax Exempt",
  "Medical Only": "Medical Only",
  "Medical Patients Only": "Medical Patients Only",
  "Medical Price": "Medical Price",
  "Menu Preview": "Menu Preview",
  "Merchant Id": "Merchant Id",
  "Merge Customers": "Merge Customers",
  "Message": "Message",
  "Message(s) sent": "Message(s) sent",
  "Method": "Method",
  "Method:": "Method:",
  "Metrc Customer ID": "Metrc Customer ID",
  "METRC Integration": "METRC Integration",
  "Metrc Item Count": "Metrc Item Count",
  "Metrc Label": "Metrc Label",
  "Metrc Qty": "Metrc Qty",
  "Metrc Receipt ID": "Metrc Receipt ID",
  "Metrc Sale Date": "Metrc Sale Date",
  "METRC Tag": "METRC Tag",
  "Metrc Tags": "Metrc Tags",
  "METRC tags are required to be 24 characters in length": "METRC tags are required to be 24 characters in length",
  "METRC Tools": "METRC Tools",
  "Metrc Unit": "Metrc Unit",
  "Micro Business": "Micro Business",
  "Microbiology": "Microbiology",
  "Minimum Purchase Required to Earn Loyalty": "Minimum Purchase Required to Earn Loyalty",
  "Minor": "Minor",
  "Mix and Match - All Products": "Mix and Match - All Products",
  "Mix and Match - Single Product": "Mix and Match - Single Product",
  "MJ Expiration Date": "MJ Expiration Date",
  "MN Medicine Id": "MN Medicine Id",
  "Mobile Phone": "Mobile Phone",
  "Mobile Phone Number": "Mobile Phone Number",
  "Model": "Model",
  "Moisture Gain": "Moisture Gain",
  "Moisture Loss": "Moisture Loss",
  "Monday": "Monday",
  "Money Adjustment": "Money Adjustment",
  "Monthly Snapshot": "Monthly Snapshot",
  "Most Sold Products": "Most Sold Products",
  "Mother": "Mother",
  "Mother Plant": "Mother Plant",
  "Mother?": "Mother?",
  "Move": "Move",
  "Move & Delete": "Move & Delete",
  "Move Date": "Move Date",
  "Move Plant Location": "Move Plant Location",
  "Move Plants": "Move Plants",
  "Move Table into Rooms": "Move Table into Rooms",
  "Moved": "Moved",
  "Moved On": "Moved On",
  "Moved to": "Moved to",
  "Must supply valid manager's password to return an item.": "Must supply valid manager's password to return an item.",
  "Mycotoxin": "Mycotoxin",
  "Name": "Name",
  "Name is required!": "Name is required!",
  "Nav Background Image": "Nav Background Image",
  "Nav Hover Background": "Nav Hover Background",
  "Nav Hover Text": "Nav Hover Text",
  "Navigation Background Color": "Navigation Background Color",
  "Navigation Menu Text": "Navigation Menu Text",
  "Navigation Selected Background": "Navigation Selected Background",
  "Navigation Selected Boxshadow": "Navigation Selected Boxshadow",
  "Navigation Selected Text": "Navigation Selected Text",
  "Needed": "Needed",
  "Net Bud Weight": "Net Bud Weight",
  "NET MMJ": "NET MMJ",
  "Net Product Weight": "Net Product Weight",
  "Net Sales": "Net Sales",
  "Net Unit Weight": "Net Unit Weight",
  "Net Weight": "Net Weight",
  "New": "New",
  "New Amount": "New Amount",
  "New Balance": "New Balance",
  "New Category": "New Category",
  "New Closing Report": "New Closing Report",
  "New Coupon": "New Coupon",
  "New Customer": "New Customer",
  "New Customers": "New Customers",
  "New Dosage": "New Dosage",
  "New Size": "New Size",
  "New Driver": "New Driver",
  "New Journal Entry": "New Journal Entry",
  "New Mother": "New Mother",
  "New PDF Printed Manifests (needs dev intervention)": "New PDF Printed Manifests (needs dev intervention)",
  "New Preorder": "New Preorder",
  "New Product": "New Product",
  "New Register Close Out UI": "New Register Close Out UI",
  "New Room": "New Room",
  "New Strain": "New Strain",
  "New table layout saved": "New table layout saved",
  "New Transaction": "New Transaction",
  "New User": "New User",
  "New Vendor": "New Vendor",
  "Next Batch Number": "Next Batch Number",
  "NEXT MONTH": "NEXT MONTH",
  "Nightly Manager Email": "Nightly Manager Email",
  "no": "no",
  "No": "No",
  "No data": "No data",
  "No discrepancies": "No discrepancies",
  "No Double Pre Order": "No Double Pre Order",
  "No image selected": "No image selected",
  "No Inventory": "No Inventory",
  "No Journal Entries": "No Journal Entries",
  "No label selected": "No label selected",
  "No printer selected": "No printer selected",
  "No printer selected.": "No printer selected.",
  "No products to receive": "No products to receive",
  "No results": "No results",
  "No transaction details found": "No transaction details found",
  "Non Cannabis": "Non Cannabis",
  "Non Cannabis Sales": "Non Cannabis Sales",
  "Non-cannabis": "Non-cannabis",
  "Non-Cannabis": "Non-Cannabis",
  "Non-Cannabis Products": "Non-Cannabis Products",
  "Non-Cannabis Sales": "Non-Cannabis Sales",
  "None": "None",
  "Non-Massachusetts clients are unaffected by this change and Massachusetts clients will only be affected once they enable their METRC integration.": "Non-Massachusetts clients are unaffected by this change and Massachusetts clients will only be affected once they enable their METRC integration.",
  "not hooked up": "not hooked up",
  "Not Stacking": "Not Stacking",
  "Not Synced": "Not Synced",
  "Note": "Note",
  "Notes": "Notes",
  "Notes loaded…": "Notes loaded…",
  "Notication Alert": "Notication Alert",
  "Notification Error": "Notification Error",
  "Notification Success": "Notification Success",
  "Notify Button (for Preorders)": "Notify Button (for Preorders)",
  "Now synchronizing members, this will take some time and complete in the background. Feel free to leave this page.": "Now synchronizing members, this will take some time and complete in the background. Feel free to leave this page.",
  "NPI Number": "NPI Number",
  "Number of Labels:": "Number of Labels:",
  "Number of Plants": "Number of Plants",
  "Number to Create": "Number to Create",
  "Nutrient": "Nutrient",
  "Nutrient Log": "Nutrient Log",
  "Nutrients": "Nutrients",
  "OK": "OK",
  "On Behalf of Vendor": "On Behalf of Vendor",
  "On Sale": "On Sale",
  "On Sale Ribbon": "On Sale Ribbon",
  "On Sale Text": "On Sale Text",
  "One digit day": "One digit day",
  "One digit month": "One digit month",
  "Online Available?": "Online Available?",
  "Online Description": "Online Description",
  "Online Product Details": "Online Product Details",
  "Online Title": "Online Title",
  "Only apply price breaks at specified weights": "Only apply price breaks at specified weights",
  "Only open manifests with zero items can be deleted": "Only open manifests with zero items can be deleted",
  "Only require email. pwd, first/last, drivers license, phone for ecom signup": "Only require email. pwd, first/last, drivers license, phone for ecom signup",
  "Open": "Open",
  "Open Batches": "Open Batches",
  "Operations": "Operations",
  "Optional - the background image of Ecom": "Optional - the background image of Ecom",
  "Optional - the background image of the Navigation Menu": "Optional - the background image of the Navigation Menu",
  "Options saved": "Options saved",
  "Or Select Table": "Or Select Table",
  "Order": "Order",
  "Order - these are for transfers outside of your LSP": "Order - these are for transfers outside of your LSP",
  "Order completed": "Order completed",
  "Order could not be Rejected": "Order could not be Rejected",
  "Order created": "Order created",
  "Order Date": "Order Date",
  "Order Deleted": "Order Deleted",
  "Order does not contain any items.": "Order does not contain any items.",
  "Order Id": "Order Id",
  "Order is missing information required to receive. Please fill out a room and a package id for all items.": "Order is missing information required to receive. Please fill out a room and a package id for all items.",
  "Order No": "Order No",
  "Order Ready Text Message": "Order Ready Text Message",
  "Order Rejected": "Order Rejected",
  "Order Saved": "Order Saved",
  "Order Title": "Order Title",
  "Order Total": "Order Total",
  "OrderNumber": "OrderNumber",
  "Orders": "Orders",
  "Orders By Product": "Orders By Product",
  "Orientation": "Orientation",
  "Original Receipt #": "Original Receipt #",
  "Other": "Other",
  "Other Flower": "Other Flower",
  "Other minor updates:": "Other minor updates:",
  "Our February update has been released.  Highlights include:": "Our February update has been released.  Highlights include:",
  "Our first update for March has been released. Highlights include:": "Our first update for March has been released. Highlights include:",
  "output": "output",
  "Output": "Output",
  "Outputs": "Outputs",
  "Over/Short": "Over/Short",
  "Owner": "Owner",
  "Package": "Package",
  "Package #": "Package #",
  "Package already scanned": "Package already scanned",
  "Package Count": "Package Count",
  "Package Date": "Package Date",
  "Package deleted": "Package deleted",
  "Package Detail": "Package Detail",
  "Package has no parents": "Package has no parents",
  "Package History": "Package History",
  "Package Id": "Package Id",
  "Package ID": "Package ID",
  "Package Label": "Package Label",
  "Package No": "Package No",
  "PACKAGE NO": "PACKAGE NO",
  "Package not found": "Package not found",
  "Package Status": "Package Status",
  "Package(s):": "Package(s):",
  "packaged into": "packaged into",
  "PackageId": "PackageId",
  "packages": "packages",
  "Packages": "Packages",
  "Packages combined": "Packages combined",
  "Packages created successfully": "Packages created successfully",
  "Packages moved": "Packages moved",
  "Packages remediated successfully": "Packages remediated successfully",
  "Packages sublotted": "Packages sublotted",
  "Packages To Create": "Packages To Create",
  "Packaging": "Packaging",
  "Packaging Date": "Packaging Date",
  "PACKAGING DATE": "PACKAGING DATE",
  "Packaging Error": "Packaging Error",
  "Page": "Page",
  "Page Header (leave blank for no header)": "Page Header (leave blank for no header)",
  "Paid in Cash": "Paid in Cash",
  "Paid in Check": "Paid in Check",
  "Paid in Debit": "Paid in Debit",
  "Paid in Loyalty": "Paid in Loyalty",
  "Pass": "Pass",
  "Password": "Password",
  "Password updated": "Password updated",
  "Password:": "Password:",
  "Patient": "Patient",
  "Patient DOB": "Patient DOB",
  "PatientName": "PatientName",
  "Payment Method": "Payment Method",
  "Payment Summary": "Payment Summary",
  "PaymentMethod": "PaymentMethod",
  "Payments": "Payments",
  "Pending Transfers": "Pending Transfers",
  "Per Day": "Per Day",
  "Per Gram": "Per Gram",
  "Per Month": "Per Month",
  "Per Plant:": "Per Plant:",
  "Per Week": "Per Week",
  "Per Year": "Per Year",
  "Percent Amount": "Percent Amount",
  "Percent Off Price": "Percent Off Price",
  "Performed By": "Performed By",
  "Permission group created": "Permission group created",
  "Permission Groups": "Permission Groups",
  "Permission Groups for": "Permission Groups for",
  "Permission groups loaded": "Permission groups loaded",
  "Permission list loaded": "Permission list loaded",
  "Permission Updates": "Permission Updates",
  "permissions": "permissions",
  "Permissions failed to update": "Permissions failed to update",
  "Permissions for": "Permissions for",
  "Permissions updated": "Permissions updated",
  "Pesticides": "Pesticides",
  "Phone": "Phone",
  "Phone No": "Phone No",
  "Phone Number": "Phone Number",
  "PIN Code": "PIN Code",
  "Pin required on check out": "Pin required on check out",
  "Plant": "Plant",
  "Plant already scanned": "Plant already scanned",
  "Plant count": "Plant count",
  "Plant Count": "Plant Count",
  "Plant Details": "Plant Details",
  "Plant Group": "Plant Group",
  "Plant group created successfully": "Plant group created successfully",
  "Plant Group Details": "Plant Group Details",
  "Plant Group updated successfully.": "Plant Group updated successfully.",
  "Plant info saved": "Plant info saved",
  "Plant Journal": "Plant Journal",
  "Plant labels printed": "Plant labels printed",
  "Plant Manager": "Plant Manager",
  "Plant Mother": "Plant Mother",
  "Plant mother status updated successfully.": "Plant mother status updated successfully.",
  "Plant mother updated successfully.": "Plant mother updated successfully.",
  "Plant not found": "Plant not found",
  "Plant Reconciliation": "Plant Reconciliation",
  "Plant Serial No": "Plant Serial No",
  "Plant Stage": "Plant Stage",
  "Plant stage updated successfully.": "Plant stage updated successfully.",
  "Plant Weight": "Plant Weight",
  "Plant Weight Reporting Batch Size": "Plant Weight Reporting Batch Size",
  "Plant weights": "Plant weights",
  "PlantLabel": "PlantLabel",
  "plants": "plants",
  "Plants": "Plants",
  "Plants added successfully.": "Plants added successfully.",
  "Plants could not be added": "Plants could not be added",
  "Plants could not be retired": "Plants could not be retired",
  "Plants could not be un-retired": "Plants could not be un-retired",
  "Plants do not load by default and must be searched for": "Plants do not load by default and must be searched for",
  "Plants Moved": "Plants Moved",
  "plants moved.": "plants moved.",
  "Plants retired successfully.": "Plants retired successfully.",
  "Plants Selected": "Plants Selected",
  "Plants un-retired successfully.": "Plants un-retired successfully.",
  "Please confirm inventory update and enter a reason": "Please confirm inventory update and enter a reason",
  "Please contact support@leaflogix.com for more information or to confirm the website url's you are using.": "Please contact support@leaflogix.com for more information or to confirm the website url's you are using.",
  "Please select a batch": "Please select a batch",
  "Please select a location": "Please select a location",
  "Please select inventory that is the same product as setup in Product Master": "Please select inventory that is the same product as setup in Product Master",
  "Please select lot # and date before you scan.": "Please select lot # and date before you scan.",
  "Please select room before you scan.": "Please select room before you scan.",
  "Please select the same unit type for all packages": "Please select the same unit type for all packages",
  "Please select the Weedmaps Listing you would like to sync with this LeafLogix Location": "Please select the Weedmaps Listing you would like to sync with this LeafLogix Location",
  "Please upload an image smaller than 4 MB": "Please upload an image smaller than 4 MB",
  "Point of Contact": "Point of Contact",
  "Point Value": "Point Value",
  "Portrait": "Portrait",
  "Portrait180": "Portrait180",
  "POS": "POS",
  "POS 3.0 is complete": "POS 3.0 is complete",
  "POS Available?": "POS Available?",
  "POS Daily Password": "POS Daily Password",
  "POS Dashboard": "POS Dashboard",
  "POS Edit Cart Require Manager Password": "POS Edit Cart Require Manager Password",
  "POS Grams Oz": "POS Grams Oz",
  "POS Make Payment": "POS Make Payment",
  "POS Note Under 21": "POS Note Under 21",
  "POS Scan Error": "POS Scan Error",
  "POS Show Rooms": "POS Show Rooms",
  "POS Status": "POS Status",
  "Post All Invoices": "Post All Invoices",
  "Post Invoices In Real Time": "Post Invoices In Real Time",
  "Post Trim Wet Weight": "Post Trim Wet Weight",
  "Postal Code": "Postal Code",
  "Precision Weight": "Precision Weight",
  "PreOrder Received Text Message": "PreOrder Received Text Message",
  "PreRoll": "PreRoll",
  "Prescription": "Prescription",
  "Prescription Number": "Prescription Number",
  "Present": "Present",
  "Press and hold the CTRL key.": "Press and hold the CTRL key.",
  "Pre-Tax Amount": "Pre-Tax Amount",
  "PREV MONTH": "PREV MONTH",
  "Prevent Overselling": "Prevent Overselling",
  "Preview": "Preview",
  "Previous calendar month": "Previous calendar month",
  "Previous calendar week": "Previous calendar week",
  "Price": "Price",
  "Price Per Unit": "Price Per Unit",
  "Price Tier Added": "Price Tier Added",
  "Price To Amount": "Price To Amount",
  "Price To Cost Plus Percent": "Price To Cost Plus Percent",
  "Prices ($ per Gram)": "Prices ($ per Gram)",
  "Pricing": "Pricing",
  "Pricing Details refreshed": "Pricing Details refreshed",
  "Pricing removed": "Pricing removed",
  "Pricing Tier": "Pricing Tier",
  "Only group items of the same SKU for price breaks": "Only group items of the same SKU for price breaks",
  "Pricing Tier Updated": "Pricing Tier Updated",
  "Pricing Tiers": "Pricing Tiers",
  "Pricing updated successfully": "Pricing updated successfully",
  "Primary Caregiver": "Primary Caregiver",
  "Print": "Print",
  "Print an Extra Copy": "Print an Extra Copy",
  "Print Invoice": "Print Invoice",
  "Print Label": "Print Label",
  "Print Labels": "Print Labels",
  "Print Manifest": "Print Manifest",
  "Print Node Details": "Print Node Details",
  "Print Non Cannabis Labels": "Print Non Cannabis Labels",
  "Print Plant Labels": "Print Plant Labels",
  "Print Preview": "Print Preview",
  "Print Table": "Print Table",
  "Printer": "Printer",
  "Printer ID": "Printer ID",
  "Printers": "Printers",
  "Processor": "Processor",
  "Processor - Tax Exempt": "Processor - Tax Exempt",
  "Producer/License": "Producer/License",
  "Product": "Product",
  "Product BI Dashboard": "Product BI Dashboard",
  "Product Categories": "Product Categories",
  "Product Categories will also need to be assigned to limit categories in order for them to count against a specific limit.  This is configurable in Maintenance > Product Categories.": "Product Categories will also need to be assigned to limit categories in order for them to count against a specific limit.  This is configurable in Maintenance > Product Categories.",
  "Product Category": "Product Category",
  "Product Combination": "Product Combination",
  "Product Conversion": "Product Conversion",
  "Product Details": "Product Details",
  "Product Grams": "Product Grams",
  "Product Group": "Product Group",
  "Product Group Saved!": "Product Group Saved!",
  "Product Groups": "Product Groups",
  "Product History": "Product History",
  "Product Images": "Product Images",
  "Product Journal": "Product Journal",
  "Product Master": "Product Master",
  "Product Name": "Product Name",
  "Product Size": "Product Size",
  "Product Tax Categories": "Product Tax Categories",
  "Product updated.": "Product updated.",
  "Product Values": "Product Values",
  "Product/Service (Cannabis Items)": "Product/Service (Cannabis Items)",
  "Product/Service (NON-Cannabis Items)": "Product/Service (NON-Cannabis Items)",
  "production": "production",
  "Production": "Production",
  "Production Batch": "Production Batch",
  "Products": "Products",
  "Products by Category": "Products by Category",
  "Products sold by Hour": "Products sold by Hour",
  "Profit": "Profit",
  "Propagation Type": "Propagation Type",
  "Public API": "Public API",
  "Public API Setup": "Public API Setup",
  "Pull": "Pull",
  "Pulled": "Pulled",
  "Purchase Limit (g)": "Purchase Limit (g)",
  "Purchase Limit Category": "Purchase Limit Category",
  "Purchase Limit Type": "Purchase Limit Type",
  "Purchase Limits": "Purchase Limits",
  "Purchase limits are now configurable per customer per limit category (Flower, concentrate, etc).": "Purchase limits are now configurable per customer per limit category (Flower, concentrate, etc).",
  "Purchase Order Completed": "Purchase Order Completed",
  "Purchase Order Id": "Purchase Order Id",
  "Purchase Order Re-Opened": "Purchase Order Re-Opened",
  "Purchase Order Submitted": "Purchase Order Submitted",
  "Purchase Orders": "Purchase Orders",
  "Push": "Push",
  "Pushed": "Pushed",
  "QBO Accounts Updated": "QBO Accounts Updated",
  "QBO Settings Saved.": "QBO Settings Saved.",
  "qty": "qty",
  "Qty": "Qty",
  "Qty Sold": "Qty Sold",
  "Quantity": "Quantity",
  "Quantity Added": "Quantity Added",
  "Quantity Available": "Quantity Available",
  "Quantity Received": "Quantity Received",
  "Quantity Requested": "Quantity Requested",
  "Quantity to Print": "Quantity to Print",
  "Quantity to Sublot": "Quantity to Sublot",
  "Quickbooks Integration": "Quickbooks Integration",
  "QuickBooks Inventory Settings": "QuickBooks Inventory Settings",
  "QuickBooks Online Settings": "QuickBooks Online Settings",
  "QuickBooks Settings Saved": "QuickBooks Settings Saved",
  "Rate Amount": "Rate Amount",
  "Rate Limit (ms)": "Rate Limit (ms)",
  "Rate Name": "Rate Name",
  "Rate Type": "Rate Type",
  "Rate Unit": "Rate Unit",
  "Raw Flower Eq.": "Raw Flower Eq.",
  "Reactivate": "Reactivate",
  "Ready to integrate with WeedMaps? Click the button below to get started!": "Ready to integrate with WeedMaps? Click the button below to get started!",
  "Reason": "Reason",
  "Reason Code": "Reason Code",
  "Reason Codes": "Reason Codes",
  "Reason Note": "Reason Note",
  "Rec Flower Equiv": "Rec Flower Equiv",
  "Rec Per Gram": "Rec Per Gram",
  "Rec Total": "Rec Total",
  "Receipt #": "Receipt #",
  "Receipt Id": "Receipt Id",
  "Receipt No": "Receipt No",
  "Receipt Printer?": "Receipt Printer?",
  "Receipt Reconciliation": "Receipt Reconciliation",
  "Receipt Reconciliation Details": "Receipt Reconciliation Details",
  "ReceiptNo": "ReceiptNo",
  "Receipts": "Receipts",
  "Receive": "Receive",
  "Receive Details": "Receive Details",
  "Receive History": "Receive History",
  "Receive Notes": "Receive Notes",
  "Receive Transaction": "Receive Transaction",
  "Received": "Received",
  "Received By": "Received By",
  "Recent Errors": "Recent Errors",
  "Recipe": "Recipe",
  "Recipe Batches": "Recipe Batches",
  "Recipe Batches in Progress": "Recipe Batches in Progress",
  "Recipe Name": "Recipe Name",
  "Recipes": "Recipes",
  "Recipient": "Recipient",
  "Recon Zero Balance": "Recon Zero Balance",
  "Reconcile Plant": "Reconcile Plant",
  "Reconcile Remaining Plant": "Reconcile Remaining Plant",
  "Reconciled": "Reconciled",
  "Reconciliation": "Reconciliation",
  "Reconciliation closed": "Reconciliation closed",
  "Reconciliation closed successfully": "Reconciliation closed successfully",
  "Reconciliation could not be closed": "Reconciliation could not be closed",
  "Reconciliation could not be deleted": "Reconciliation could not be deleted",
  "Reconciliation created successfully": "Reconciliation created successfully",
  "Reconciliation Date": "Reconciliation Date",
  "Reconciliation deleted": "Reconciliation deleted",
  "Reconciliation deleted successfully": "Reconciliation deleted successfully",
  "Reconnect": "Reconnect",
  "Recorded By": "Recorded By",
  "Recreational": "Recreational",
  "Recreational - Tax Exempt": "Recreational - Tax Exempt",
  "Recreational Price": "Recreational Price",
  "Recreational Tax Rate": "Recreational Tax Rate",
  "Reference #": "Reference #",
  "Reference No": "Reference No",
  "Referred By": "Referred By",
  "Refresh": "Refresh",
  "Re-fresh notes": "Re-fresh notes",
  "Refunds": "Refunds",
  "Register": "Register",
  "Register Actions": "Register Actions",
  "Register By Phone Number": "Register By Phone Number",
  "Register Name": "Register Name",
  "Register Transactions": "Register Transactions",
  "Registers": "Registers",
  "Registers at": "Registers at",
  "Regulatory Category": "Regulatory Category",
  "Reject Order?": "Reject Order?",
  "Rejected": "Rejected",
  "Release Notes": "Release Notes",
  "Remediate": "Remediate",
  "Remove": "Remove",
  "Remove all items from receive?": "Remove all items from receive?",
  "Remove Image": "Remove Image",
  "Remove pre orders from Ecom availble Inventory": "Remove pre orders from Ecom availble Inventory",
  "Remove this item from the receive?": "Remove this item from the receive?",
  "Remove?": "Remove?",
  "Removed Quantity": "Removed Quantity",
  "Removed Reason": "Removed Reason",
  "Reopen": "Reopen",
  "Re-Open Order": "Re-Open Order",
  "Report": "Report",
  "Report Type": "Report Type",
  "Report Updates": "Report Updates",
  "reported successfully": "reported successfully",
  "Reports": "Reports",
  "Request": "Request",
  "Request Date": "Request Date",
  "Require Doctor For Check In": "Require Doctor For Check In",
  "Require Individual User API Keys": "Require Individual User API Keys",
  "Require Manager Approval": "Require Manager Approval",
  "Require Manager Password For Allotment Adjust": "Require Manager Password For Allotment Adjust",
  "Require Manager Password for Loyalty Adjustment": "Require Manager Password for Loyalty Adjustment",
  "Require Manager Password for Returns": "Require Manager Password for Returns",
  "Require Multiple Items?": "Require Multiple Items?",
  "Require Policy Signature": "Require Policy Signature",
  "Require Signature to Checkout": "Require Signature to Checkout",
  "Require Valid Driver's License": "Require Valid Driver's License",
  "Require valid state system credentials to use POS": "Require valid state system credentials to use POS",
  "Required Fields": "Required Fields",
  "Requires a signature to checkout in the POS": "Requires a signature to checkout in the POS",
  "Requires Remediation": "Requires Remediation",
  "Reset": "Reset",
  "Reset Credentials": "Reset Credentials",
  "Response": "Response",
  "Restore": "Restore",
  "Restore Removed Items to Inventory": "Restore Removed Items to Inventory",
  "restored": "restored",
  "Restrict Package Products to the Harvest Strain": "Restrict Package Products to the Harvest Strain",
  "Restrict To Location": "Restrict To Location",
  "Restrict voids": "Restrict voids",
  "Restricts voids to same day / before closeout": "Restricts voids to same day / before closeout",
  "Resync Inventory Ids": "Resync Inventory Ids",
  "Retail": "Retail",
  "Retailer": "Retailer",
  "Retailer - Tax Exempt": "Retailer - Tax Exempt",
  "Retire": "Retire",
  "Retire Plant": "Retire Plant",
  "Retired": "Retired",
  "Return By": "Return By",
  "Return Reason": "Return Reason",
  "Return Reasons": "Return Reasons",
  "Return Receipt #": "Return Receipt #",
  "Returned": "Returned",
  "Returns": "Returns",
  "Room": "Room",
  "Room Change": "Room Change",
  "Room History": "Room History",
  "Room Name": "Room Name",
  "Room/Table": "Room/Table",
  "Rooms": "Rooms",
  "Rooms re-loaded": "Rooms re-loaded",
  "Rooms updated successfully": "Rooms updated successfully",
  "Rotation": "Rotation",
  "Round all transactions to the nearest dollar": "Round all transactions to the nearest dollar",
  "Round Cash Change to Nearest Nickel.": "Round Cash Change to Nearest Nickel.",
  "Round Percent Discounts": "Round Percent Discounts",
  "Round percent discounts to nearest dollar when calculated discount amount": "Round percent discounts to nearest dollar when calculated discount amount",
  "Round to the nearest dollar": "Round to the nearest dollar",
  "Round Transactions Down to Next Dollar": "Round Transactions Down to Next Dollar",
  "Round Transactions Down to Next Quarter": "Round Transactions Down to Next Quarter",
  "Rounding": "Rounding",
  "Run": "Run",
  "Run Auto Discount": "Run Auto Discount",
  "Run Auto Price": "Run Auto Price",
  "RX No": "RX No",
  "Sale Date": "Sale Date",
  "Sales": "Sales",
  "Sales Account": "Sales Account",
  "Sales by Budtender": "Sales by Budtender",
  "Sales Date": "Sales Date",
  "Sales floor": "Sales floor",
  "Sales Receipts": "Sales Receipts",
  "Sales Receipts Defaults": "Sales Receipts Defaults",
  "Sales Receipts Log (QBO)": "Sales Receipts Log (QBO)",
  "Sales Receipts Successfully Uploaded": "Sales Receipts Successfully Uploaded",
  "Sales Since Last Drop": "Sales Since Last Drop",
  "Sales Tax Code Category (Cannabis Items)": "Sales Tax Code Category (Cannabis Items)",
  "Sales Tax Code Category (NON-Cannabis Items)": "Sales Tax Code Category (NON-Cannabis Items)",
  "Sample created successfully": "Sample created successfully",
  "Sample Date": "Sample Date",
  "Sample Weight": "Sample Weight",
  "Sampled Package ID": "Sampled Package ID",
  "sandbox": "sandbox",
  "Sativa": "Sativa",
  "Saturday": "Saturday",
  "Save": "Save",
  "SAVE": "SAVE",
  "Save & Report": "Save & Report",
  "Save Customer": "Save Customer",
  "Save Details": "Save Details",
  "Save Note": "Save Note",
  "Save Transaction": "Save Transaction",
  "Save Vendor": "Save Vendor",
  "Saved Orders": "Saved Orders",
  "Saved!": "Saved!",
  "Saving": "Saving",
  "Saving group failed!": "Saving group failed!",
  "Scan Into": "Scan Into",
  "Scan Items": "Scan Items",
  "Scan Packages": "Scan Packages",
  "Scan Plant Barcode": "Scan Plant Barcode",
  "Scan Plants": "Scan Plants",
  "Scan Plants into Harvest": "Scan Plants into Harvest",
  "Scan Plants into Locations": "Scan Plants into Locations",
  "Scan Plants into Retirement": "Scan Plants into Retirement",
  "Scan Table Barcode": "Scan Table Barcode",
  "Scan Table into Room": "Scan Table into Room",
  "Scanned Plants": "Scanned Plants",
  "scans": "scans",
  "Schedule IIF Sales Receipts Export": "Schedule IIF Sales Receipts Export",
  "Schedule QBO Sales Receipts": "Schedule QBO Sales Receipts",
  "Search": "Search",
  "Search by Inventory Id": "Search by Inventory Id",
  "Search by Plant Group": "Search by Plant Group",
  "Search by Plant Status": "Search by Plant Status",
  "Search by Room": "Search by Room",
  "Search by Text": "Search by Text",
  "Search Customers": "Search Customers",
  "Search for product": "Search for product",
  "Search Full Serial No": "Search Full Serial No",
  "Search notes": "Search notes",
  "Search Plants": "Search Plants",
  "Searching manifests has been upgraded to include searching for full or partial matches on the package IDs, product names, product SKUs, customer name, manifest title and manifest order id.": "Searching manifests has been upgraded to include searching for full or partial matches on the package IDs, product names, product SKUs, customer name, manifest title and manifest order id.",
  "Second Driver": "Second Driver",
  "Secret": "Secret",
  "See the full release notes sent via email for more details": "See the full release notes sent via email for more details",
  "See the full release notes sent via email for more details.": "See the full release notes sent via email for more details.",
  "Seed": "Seed",
  "Seed(s) To Destroy": "Seed(s) To Destroy",
  "Seedling count successfully destroyed.": "Seedling count successfully destroyed.",
  "Seedlings": "Seedlings",
  "Select": "Select",
  "Select a batch to continue.": "Select a batch to continue.",
  "Select a Company": "Select a Company",
  "Select a plant to continue.": "Select a plant to continue.",
  "Select a recipe to continue.": "Select a recipe to continue.",
  "Select a single item to continue.": "Select a single item to continue.",
  "Select a single seedling to continue.": "Select a single seedling to continue.",
  "Select a summary before you print": "Select a summary before you print",
  "Select Action": "Select Action",
  "Select Address": "Select Address",
  "Select All": "Select All",
  "Select another nutrient": "Select another nutrient",
  "Select Batch": "Select Batch",
  "Select batch to continue.": "Select batch to continue.",
  "Select Batches": "Select Batches",
  "Select Car": "Select Car",
  "Select Category": "Select Category",
  "Select Date": "Select Date",
  "Select discount to continue": "Select discount to continue",
  "Select Discount Type": "Select Discount Type",
  "Select Driver": "Select Driver",
  "Select Existing": "Select Existing",
  "Select item(s) to continue.": "Select item(s) to continue.",
  "Select Location": "Select Location",
  "Select Lot": "Select Lot",
  "Select Master Category": "Select Master Category",
  "Select Mother": "Select Mother",
  "Select Mother Plant": "Select Mother Plant",
  "Select None": "Select None",
  "Select one plant to continue.": "Select one plant to continue.",
  "Select Option": "Select Option",
  "Select Package": "Select Package",
  "Select package to look up": "Select package to look up",
  "Select Page": "Select Page",
  "Select Plant Group": "Select Plant Group",
  "Select plant stage": "Select plant stage",
  "Select plant to continue.": "Select plant to continue.",
  "Select Plant Type": "Select Plant Type",
  "Select plants to continue.": "Select plants to continue.",
  "Select Qty": "Select Qty",
  "Select Quantity": "Select Quantity",
  "Select Register": "Select Register",
  "Select Report": "Select Report",
  "select report and datespan to view or export": "select report and datespan to view or export",
  "Select Report Type": "Select Report Type",
  "Select Room": "Select Room",
  "Select Status": "Select Status",
  "Select Strain": "Select Strain",
  "Select Table": "Select Table",
  "Select the rows one by one by marking the checkbox": "Select the rows one by one by marking the checkbox",
  "Select timespan": "Select timespan",
  "Select Type": "Select Type",
  "Select user continue.": "Select user continue.",
  "Selected": "Selected",
  "Selected Day Totals": "Selected Day Totals",
  "Selected Navigation Items": "Selected Navigation Items",
  "Selected Packages": "Selected Packages",
  "Selected plants": "Selected plants",
  "Selected Rows": "Selected Rows",
  "Send": "Send",
  "Send a note to the customer": "Send a note to the customer",
  "Send after visit SMS": "Send after visit SMS",
  "Send Email?": "Send Email?",
  "Send PO Email": "Send PO Email",
  "Send Sales Receipt Summary": "Send Sales Receipt Summary",
  "Send SMS Message": "Send SMS Message",
  "Send Test Invoice": "Send Test Invoice",
  "Serial #": "Serial #",
  "Serial No": "Serial No",
  "Serial Number": "Serial Number",
  "SerialNumber": "SerialNumber",
  "SerialNumberStrain": "SerialNumberStrain",
  "Set": "Set",
  "Set Displayed Language": "Set Displayed Language",
  "Set Harvest Stage": "Set Harvest Stage",
  "Set Password": "Set Password",
  "Set Total Weight": "Set Total Weight",
  "Settings": "Settings",
  "Settings updated": "Settings updated",
  "Shift key": "Shift key",
  "Shipping Information": "Shipping Information",
  "Show": "Show",
  "Show Allotment": "Show Allotment",
  "Show Allotment Information": "Show Allotment Information",
  "Show Allotment Valid Dates": "Show Allotment Valid Dates",
  "Show Batch Ingredients": "Show Batch Ingredients",
  "Show Batch Names": "Show Batch Names",
  "Show Cashier ID": "Show Cashier ID",
  "Show Cashier Name": "Show Cashier Name",
  "Show Cultivation Integration Reconcilation": "Show Cultivation Integration Reconcilation",
  "Show Cultivation Tax": "Show Cultivation Tax",
  "Show Customer Address": "Show Customer Address",
  "Show Customer Date Of Birth": "Show Customer Date Of Birth",
  "Show Customer ID": "Show Customer ID",
  "Show Customer Name": "Show Customer Name",
  "Show Days Supply Calculator": "Show Days Supply Calculator",
  "Show Delivery Information": "Show Delivery Information",
  "Show Doctor Designator Field": "Show Doctor Designator Field",
  "Show EcomV2 Features in backend (Multiple images in Product Master, Ecom Styling, Product Groups)": "Show EcomV2 Features in backend (Multiple images in Product Master, Ecom Styling, Product Groups)",
  "Show expired discount groups": "Show expired discount groups",
  "Show expired discounts": "Show expired discounts",
  "Show Header": "Show Header",
  "Show Hidden Printers": "Show Hidden Printers",
  "Show Icon On Guest  List": "Show Icon On Guest  List",
  "Show in POS": "Show in POS",
  "Show Ingredients": "Show Ingredients",
  "Show Lab Data": "Show Lab Data",
  "Show lab data on ViewProduct page. Requires configuration from Doug.": "Show lab data on ViewProduct page. Requires configuration from Doug.",
  "Show Logo": "Show Logo",
  "Show Loyalty": "Show Loyalty",
  "Show Loyalty Points": "Show Loyalty Points",
  "Show Manifest": "Show Manifest",
  "Show notes when entering POS": "Show notes when entering POS",
  "Show Notes?": "Show Notes?",
  "Show On Sale Page": "Show On Sale Page",
  "Show on sale page on navigation menu": "Show on sale page on navigation menu",
  "Show Only Low Inventory": "Show Only Low Inventory",
  "Show only products with a difference": "Show only products with a difference",
  "Show Option to Bypass State System(METRC, BioTrack, etc).": "Show Option to Bypass State System(METRC, BioTrack, etc).",
  "Show Orders by Product in Inventory": "Show Orders by Product in Inventory",
  "Show Price Online": "Show Price Online",
  "Show Product Images": "Show Product Images",
  "Show Product Instructions": "Show Product Instructions",
  "Show Product SKU": "Show Product SKU",
  "Show Quantity Online": "Show Quantity Online",
  "Show Register ID": "Show Register ID",
  "Show Registration Page": "Show Registration Page",
  "Show registration page when not logged into the storefront": "Show registration page when not logged into the storefront",
  "Show Retired Products": "Show Retired Products",
  "Show Search Bar": "Show Search Bar",
  "Show Signature Line": "Show Signature Line",
  "Show Source Package ID": "Show Source Package ID",
  "Show Stats on Store Page": "Show Stats on Store Page",
  "Show the Doctor Designator field on the POS profile": "Show the Doctor Designator field on the POS profile",
  "Show the logo row in the Storefront. Disable if you primarily use the storefront as an iFrame": "Show the logo row in the Storefront. Disable if you primarily use the storefront as an iFrame",
  "Show the notify button in POS so that stores can notify 3rd party menu providers that orders have been fulfilled": "Show the notify button in POS so that stores can notify 3rd party menu providers that orders have been fulfilled",
  "Show the waste management dialog when retiring plants": "Show the waste management dialog when retiring plants",
  "Show Total Grams Purchased": "Show Total Grams Purchased",
  "Show Transactions Tab in POS": "Show Transactions Tab in POS",
  "Show Unit Count to Add": "Show Unit Count to Add",
  "Show Vendor Online": "Show Vendor Online",
  "Show Zero Inventory": "Show Zero Inventory",
  "Show/Hide PIN": "Show/Hide PIN",
  "Showing rows": "Showing rows",
  "Size": "Size",
  "Size Item updated successfully": "Size Item updated successfully",
  "Sizes": "Sizes",
  "SKU": "SKU",
  "Software Support Notice": "Software Support Notice",
  "Sold": "Sold",
  "Sold by": "Sold by",
  "Sold By": "Sold By",
  "Sold On": "Sold On",
  "Sold To": "Sold To",
  "Solvent Residue": "Solvent Residue",
  "Something went wrong": "Something went wrong",
  "Sort By Last Name": "Sort By Last Name",
  "Source": "Source",
  "Source Batch": "Source Batch",
  "Source Package": "Source Package",
  "Source Package ID": "Source Package ID",
  "Spring Big settings saved": "Spring Big settings saved",
  "Springbig Integration": "Springbig Integration",
  "Sprout Configuration": "Sprout Configuration",
  "Stacking Order": "Stacking Order",
  "Stacks On Other Rates": "Stacks On Other Rates",
  "Stage": "Stage",
  "Stage Change Date": "Stage Change Date",
  "Start": "Start",
  "Start Balance": "Start Balance",
  "Start Date": "Start Date",
  "Start Over": "Start Over",
  "Start Time": "Start Time",
  "Started By": "Started By",
  "Started On": "Started On",
  "Starting Balance": "Starting Balance",
  "Starting Cash Balance": "Starting Cash Balance",
  "Starting Tag": "Starting Tag",
  "State": "State",
  "State Id": "State Id",
  "State ID #": "State ID #",
  "State Id Expiration": "State Id Expiration",
  "State Id No": "State Id No",
  "Static Reports": "Static Reports",
  "Statistics": "Statistics",
  "Statistics - API - Last 7 Days": "Statistics - API - Last 7 Days",
  "Statistics - Cache": "Statistics - Cache",
  "Status": "Status",
  "Status Code": "Status Code",
  "Status List updated": "Status List updated",
  "Status Removed": "Status Removed",
  "Status Settings": "Status Settings",
  "Stem Waste Weight": "Stem Waste Weight",
  "Step Cost": "Step Cost",
  "Step Name": "Step Name",
  "Steps": "Steps",
  "Steps:": "Steps:",
  "Sticky Header/Nav": "Sticky Header/Nav",
  "Stop # on Route": "Stop # on Route",
  "Stop Scanning": "Stop Scanning",
  "Store Front Show Qty": "Store Front Show Qty",
  "Storefront Account": "Storefront Account",
  "strain": "strain",
  "Strain": "Strain",
  "Strains": "Strains",
  "Strains re-loaded": "Strains re-loaded",
  "Strains updated successfully": "Strains updated successfully",
  "Street": "Street",
  "Street Address": "Street Address",
  "Sub Total": "Sub Total",
  "Subject": "Subject",
  "Sublot": "Sublot",
  "Sublot Package": "Sublot Package",
  "Sublotted Package Id": "Sublotted Package Id",
  "Submit": "Submit",
  "Submit Date": "Submit Date",
  "Submit Order": "Submit Order",
  "Submitted": "Submitted",
  "Subtotal": "Subtotal",
  "SubTotal": "SubTotal",
  "Successfully added": "Successfully added",
  "Successfully added to manifest": "Successfully added to manifest",
  "Sugar Leaf Value": "Sugar Leaf Value",
  "Summary": "Summary",
  "Sunday": "Sunday",
  "Superuser": "Superuser",
  "Superuser Tools": "Superuser Tools",
  "Supplier": "Supplier",
  "Supplier License Number": "Supplier License Number",
  "Support for adult use sales in Massachusetts is now complete with a POS update to allow the LeafLogix MassCIP integration to work in conjunction with METRC.": "Support for adult use sales in Massachusetts is now complete with a POS update to allow the LeafLogix MassCIP integration to work in conjunction with METRC.",
  "Surviving Entity": "Surviving Entity",
  "Sync every 15 minutes": "Sync every 15 minutes",
  "Sync Everything": "Sync Everything",
  "Sync Inventory": "Sync Inventory",
  "Sync Members": "Sync Members",
  "Sync Printers": "Sync Printers",
  "Sync to METRC Item?": "Sync to METRC Item?",
  "Synced All": "Synced All",
  "Synced Customer": "Synced Customer",
  "Synced Inventory": "Synced Inventory",
  "Synced Transaction": "Synced Transaction",
  "Synced Vendor": "Synced Vendor",
  "Synchronization": "Synchronization",
  "Synchronize data": "Synchronize data",
  "Synchronizing members": "Synchronizing members",
  "Systems running our beta business intelligence and reporting features will notice that the link names have been updated by replacing the word \"Looker\" with \"BI.\"": "Systems running our beta business intelligence and reporting features will notice that the link names have been updated by replacing the word \"Looker\" with \"BI.\"",
  "Table": "Table",
  "Table Name": "Table Name",
  "Table Settings": "Table Settings",
  "Tables": "Tables",
  "Tag": "Tag",
  "Tag Type": "Tag Type",
  "Tags": "Tags",
  "Take Inventory Snapshots": "Take Inventory Snapshots",
  "Tare Weight": "Tare Weight",
  "Target Batch": "Target Batch",
  "Tax": "Tax",
  "Tax Categories": "Tax Categories",
  "Tax Coupons": "Tax Coupons",
  "Tax Per Unit": "Tax Per Unit",
  "Tax Rate": "Tax Rate",
  "Tax Rate NonCannibis": "Tax Rate NonCannibis",
  "Tax Rates": "Tax Rates",
  "Taxable": "Taxable",
  "Taxable?": "Taxable?",
  "Taxes": "Taxes",
  "Terpenes": "Terpenes",
  "Test": "Test",
  "Test Product": "Test Product",
  "Tested Date": "Tested Date",
  "Testing Status": "Testing Status",
  "Testing Status Text": "Testing Status Text",
  "Text Color": "Text Color",
  "Thanks for using Leaflogix - this is a test.  Let's get some real updates up on here!": "Thanks for using Leaflogix - this is a test.  Let's get some real updates up on here!",
  "THC Content": "THC Content",
  "The background color of the Alert notification": "The background color of the Alert notification",
  "The background color of the Error notification": "The background color of the Error notification",
  "The background color of the Success notification": "The background color of the Success notification",
  "The black action wheel next to the lab results beaker icon has been removed and all actions have been moved to the gray wheel.": "The black action wheel next to the lab results beaker icon has been removed and all actions have been moved to the gray wheel.",
  "The color of navigation item text on hover": "The color of navigation item text on hover",
  "The color of navigation items on hover": "The color of navigation items on hover",
  "The color of text in the navigation bar": "The color of text in the navigation bar",
  "The color of text in the 'On Sale' ribbon": "The color of text in the 'On Sale' ribbon",
  "The color of the navigation bar": "The color of the navigation bar",
  "The color of the 'On Sale' ribbon": "The color of the 'On Sale' ribbon",
  "The following url's running on microsoft silverlight with the login screen as shown below will no longer be supported or active as of Sept 15.": "The following url's running on microsoft silverlight with the login screen as shown below will no longer be supported or active as of Sept 15.",
  "The logo for the Ecom header": "The logo for the Ecom header",
  "The new closing report now includes a cannabis/non-cannabis breakdown in the transaction summary section.": "The new closing report now includes a cannabis/non-cannabis breakdown in the transaction summary section.",
  "The new UI features cleaner look as well as an option to \"Drop Cash\", removing cash from the register as a deposit without actually closing the register out.": "The new UI features cleaner look as well as an option to \"Drop Cash\", removing cash from the register as a deposit without actually closing the register out.",
  "The password field has been removed from user details in User Maintenance.  Instead, select an existing user and click \"Set Password\" in the action wheel and you'll get a pop up to set the user's password.": "The password field has been removed from user details in User Maintenance.  Instead, select an existing user and click \"Set Password\" in the action wheel and you'll get a pop up to set the user's password.",
  "The primary background color of the Ecom page": "The primary background color of the Ecom page",
  "The primary color of text within Ecom": "The primary color of text within Ecom",
  "The transaction total is multiplied by this number.": "The transaction total is multiplied by this number.",
  "Theft": "Theft",
  "There are existing plant in this room, would you like to move them?": "There are existing plant in this room, would you like to move them?",
  "There are existing plant in this room. Would you like to move them?": "There are existing plant in this room. Would you like to move them?",
  "There was an issue saving your Accounts": "There was an issue saving your Accounts",
  "This action does not transmit to METRC.": "This action does not transmit to METRC.",
  "This action will release your credentials with weedmaps, and require you to connect again.": "This action will release your credentials with weedmaps, and require you to connect again.",
  "This allows you to select all, clear the selection or specify an amount of rows to select, starting from the first row.": "This allows you to select all, clear the selection or specify an amount of rows to select, starting from the first row.",
  "This customer information is used for transfer manifests to location": "This customer information is used for transfer manifests to location",
  "This group has no images, the default will be displayed. Upload a custom one!": "This group has no images, the default will be displayed. Upload a custom one!",
  "This harvest had been marked as finished, this will unfinish the harvest.": "This harvest had been marked as finished, this will unfinish the harvest.",
  "This option hides the option to bypass the state system, whichever that may be i.e. METRC, BioTrack, etc.": "This option hides the option to bypass the state system, whichever that may be i.e. METRC, BioTrack, etc.",
  "This product has no images so the default will be displayed. You can upload a custom image from the Upload Image button. If you have uploaded an image recently through the data import process, please note it can take a few hours for that image to display on this screen.": "This product has no images so the default will be displayed. You can upload a custom image from the Upload Image button. If you have uploaded an image recently through the data import process, please note it can take a few hours for that image to display on this screen.",
  "This shows/hides a Transaction Date field when doing a closeout to allow the user to submit the closeout in the past.": "This shows/hides a Transaction Date field when doing a closeout to allow the user to submit the closeout in the past.",
  "This will allow the users to post to METRC again.  METRC does not support updating harvest weights so this will create a discrepancy.": "This will allow the users to post to METRC again.  METRC does not support updating harvest weights so this will create a discrepancy.",
  "This will not allow coupons to be added to the cart if the cart price total is less than the value of the coupon": "This will not allow coupons to be added to the cart if the cart price total is less than the value of the coupon",
  "Thursday": "Thursday",
  "Tier Name": "Tier Name",
  "Time Clock": "Time Clock",
  "Time clock updated successfully!": "Time clock updated successfully!",
  "Time Diff": "Time Diff",
  "Time Offset": "Time Offset",
  "Time Range": "Time Range",
  "Time Zone": "Time Zone",
  "Tincture": "Tincture",
  "Tip Cut": "Tip Cut",
  "Title": "Title",
  "to": "to",
  "To": "To",
  "TO": "TO",
  "To Loc": "To Loc",
  "To Location": "To Location",
  "To Lsp": "To Lsp",
  "To Package": "To Package",
  "To Product": "To Product",
  "To Register": "To Register",
  "To Room": "To Room",
  "To:": "To:",
  "Topicals": "Topicals",
  "Total": "Total",
  "Total # of stops on Route": "Total # of stops on Route",
  "Total Cost": "Total Cost",
  "Total Credit ($)": "Total Credit ($)",
  "Total Customers": "Total Customers",
  "Total Discount": "Total Discount",
  "Total Discrepancies": "Total Discrepancies",
  "Total Errors": "Total Errors",
  "Total Income": "Total Income",
  "Total Invoice": "Total Invoice",
  "Total is greater than Wet Weight": "Total is greater than Wet Weight",
  "Total Items": "Total Items",
  "Total Items Sold": "Total Items Sold",
  "Total Items Sold (Cannabis)": "Total Items Sold (Cannabis)",
  "Total Items Sold (Non Cannabis)": "Total Items Sold (Non Cannabis)",
  "Total Payments": "Total Payments",
  "Total Plant Weight": "Total Plant Weight",
  "Total plants": "Total plants",
  "Total Price": "Total Price",
  "Total Products": "Total Products",
  "Total Quantity Package": "Total Quantity Package",
  "Total Quantity to Package": "Total Quantity to Package",
  "Total Requests": "Total Requests",
  "Total Sales": "Total Sales",
  "Total Steps": "Total Steps",
  "Total tax": "Total tax",
  "Total Transactions": "Total Transactions",
  "Total Voids": "Total Voids",
  "Total Weight": "Total Weight",
  "Total Wet Weight": "Total Wet Weight",
  "Total wet weight is greater than harvest weight": "Total wet weight is greater than harvest weight",
  "Total:": "Total:",
  "Total: $": "Total: $",
  "TotalReturned": "TotalReturned",
  "Track by Inventory IDs": "Track by Inventory IDs",
  "Track Immature Plants Individually": "Track Immature Plants Individually",
  "Transaction": "Transaction",
  "Transaction Controls": "Transaction Controls",
  "Transaction Date": "Transaction Date",
  "Transaction Details": "Transaction Details",
  "Transaction Discrepancies": "Transaction Discrepancies",
  "Transaction History": "Transaction History",
  "Transaction ID": "Transaction ID",
  "Transaction Notes": "Transaction Notes",
  "Transaction Summary": "Transaction Summary",
  "TransactionBy": "TransactionBy",
  "TransactionDate": "TransactionDate",
  "Transactions": "Transactions",
  "Transactions processed while this is off will need to have returns and voids processed manually in METRC.": "Transactions processed while this is off will need to have returns and voids processed manually in METRC.",
  "Transfer": "Transfer",
  "Transfer - these are for transfers within your LSP": "Transfer - these are for transfers within your LSP",
  "Transfer Between Registers": "Transfer Between Registers",
  "Transfer Orders": "Transfer Orders",
  "Transferred": "Transferred",
  "Treat Moisture Loss As Harvest Waste": "Treat Moisture Loss As Harvest Waste",
  "Trim Weight": "Trim Weight",
  "Tuesday": "Tuesday",
  "Turns on the new SKU generator": "Turns on the new SKU generator",
  "Tutorials": "Tutorials",
  "Two digits day": "Two digits day",
  "Two digits month": "Two digits month",
  "Two digits year": "Two digits year",
  "Txn Cnt": "Txn Cnt",
  "Type": "Type",
  "TYPE": "TYPE",
  "Type (weight/qty)": "Type (weight/qty)",
  "Under Cut": "Under Cut",
  "Undo": "Undo",
  "Unit": "Unit",
  "Unit Cost": "Unit Cost",
  "Unit Discount": "Unit Discount",
  "Unit Price": "Unit Price",
  "Unit Weight": "Unit Weight",
  "Units": "Units",
  "Unlimited": "Unlimited",
  "Unreconciled": "Unreconciled",
  "Unretire": "Unretire",
  "Un-Retire Plant": "Un-Retire Plant",
  "Update": "Update",
  "UPDATE": "UPDATE",
  "Update Defaults": "Update Defaults",
  "Update Logo": "Update Logo",
  "Update Manifest Item": "Update Manifest Item",
  "Update Package": "Update Package",
  "Update to Receive History search function": "Update to Receive History search function",
  "Update User Password": "Update User Password",
  "Updated package": "Updated package",
  "Updated Quantity": "Updated Quantity",
  "Updated Status To": "Updated Status To",
  "Upgraded Manifest Search": "Upgraded Manifest Search",
  "Upload": "Upload",
  "Upload Customer File": "Upload Customer File",
  "Upload Image": "Upload Image",
  "Upload Inventory CSV": "Upload Inventory CSV",
  "Upload Lab Results": "Upload Lab Results",
  "Uploading": "Uploading",
  "URL's starting with :": "URL's starting with :",
  "Use BioTrack": "Use BioTrack",
  "Use both Med and Rec Flower Equiv": "Use both Med and Rec Flower Equiv",
  "Use Cart Display": "Use Cart Display",
  "Use Cycle Date": "Use Cycle Date",
  "Use Detailed Wet Weights": "Use Detailed Wet Weights",
  "Use Enterprise": "Use Enterprise",
  "Use External Id": "Use External Id",
  "Use IDScan.net integration": "Use IDScan.net integration",
  "Use Leaf": "Use Leaf",
  "Use Leafly": "Use Leafly",
  "Use MassCIP": "Use MassCIP",
  "Use METRC": "Use METRC",
  "Use MN": "Use MN",
  "Use Multi Package Creator": "Use Multi Package Creator",
  "Use negative amount to remove inventory": "Use negative amount to remove inventory",
  "Use New Barcode Scanner": "Use New Barcode Scanner",
  "Use new order fulfillment": "Use new order fulfillment",
  "Use New Tax Structures": "Use New Tax Structures",
  "Use Opt-In Workflow at Checkout": "Use Opt-In Workflow at Checkout",
  "Use Package Based Recipe Batches": "Use Package Based Recipe Batches",
  "Use Print Node": "Use Print Node",
  "Use Report Builder": "Use Report Builder",
  "Use SKU as Package ID?": "Use SKU as Package ID?",
  "Use Spin Debit Integration": "Use Spin Debit Integration",
  "Use Spring Big": "Use Spring Big",
  "Use the new Sku generator": "Use the new Sku generator",
  "Use the new Weedmaps onboarding oauth flow": "Use the new Weedmaps onboarding oauth flow",
  "Use Transfer Templates": "Use Transfer Templates",
  "Use Weed Maps": "Use Weed Maps",
  "User": "User",
  "User account created successfully!": "User account created successfully!",
  "User account updated successfully!": "User account updated successfully!",
  "User group deleted": "User group deleted",
  "User Key": "User Key",
  "User Keys": "User Keys",
  "User Metrc Key": "User Metrc Key",
  "User Name": "User Name",
  "User Permission Groups": "User Permission Groups",
  "User Pin Length": "User Pin Length",
  "Username": "Username",
  "UserName": "UserName",
  "Username Must end with @leaflogix.com": "Username Must end with @leaflogix.com",
  "users": "users",
  "Users": "Users",
  "Using our POS 3.0, process the order and produce the manifest for transport.": "Using our POS 3.0, process the order and produce the manifest for transport.",
  "Valid From": "Valid From",
  "Valid To": "Valid To",
  "Valid tokens": "Valid tokens",
  "Validate Key": "Validate Key",
  "Validate Metrc Key": "Validate Metrc Key",
  "Validated Forms": "Validated Forms",
  "Value": "Value",
  "Variance": "Variance",
  "Vault Register": "Vault Register",
  "Vegetation Ended": "Vegetation Ended",
  "Vegetation Started": "Vegetation Started",
  "Vendor": "Vendor",
  "Vendor Code": "Vendor Code",
  "Vendor Email": "Vendor Email",
  "Vendor ID": "Vendor ID",
  "Vendor License #": "Vendor License #",
  "Vendor Name": "Vendor Name",
  "Vendor Type": "Vendor Type",
  "Vendors": "Vendors",
  "Vendors updated successfully": "Vendors updated successfully",
  "View Details": "View Details",
  "View Inventory": "View Inventory",
  "View Nutrient Log": "View Nutrient Log",
  "View Permissions": "View Permissions",
  "View Users": "View Users",
  "VIN": "VIN",
  "Visible?": "Visible?",
  "Void": "Void",
  "Void Date": "Void Date",
  "Voids": "Voids",
  "Volume": "Volume",
  "Waiting for weedmaps": "Waiting for weedmaps",
  "Warning": "Warning",
  "WARNING": "WARNING",
  "Waste Date": "Waste Date",
  "Waste details failed to update": "Waste details failed to update",
  "Waste details updated successfully": "Waste details updated successfully",
  "Waste Manager": "Waste Manager",
  "Waste Package Details": "Waste Package Details",
  "Waste Type": "Waste Type",
  "Waste Weight": "Waste Weight",
  "Waste/Disposal": "Waste/Disposal",
  "Water Leaf Value": "Water Leaf Value",
  "Wax": "Wax",
  "We have moved all our customers over to the new platform which began in early 2018 and have staffed our technology department with experts to support and continued development of our product offering in this newest technology.": "We have moved all our customers over to the new platform which began in early 2018 and have staffed our technology department with experts to support and continued development of our product offering in this newest technology.",
  "We have updated the search function on the Receive History so that clients can search for partial or complete package IDs. You can also search your Receiving History for title, license number, product name, and transaction ID.": "We have updated the search function on the Receive History so that clients can search for partial or complete package IDs. You can also search your Receiving History for title, license number, product name, and transaction ID.",
  "Wednesday": "Wednesday",
  "Weedmaps 2": "Weedmaps 2",
  "WeedMaps Category": "WeedMaps Category",
  "WeedMaps Integration (NEW)": "WeedMaps Integration (NEW)",
  "Weedmaps Orderable - Allow users to order products online via WeedMaps. Dispensary will recieve orders according to settings configured at WeedMaps.com, order fulfillment can be done via LeafLogix Backend/POS. Per-product Weedmaps Orderable setting available on Product Master. Product Master setting overrides this setting.": "Weedmaps Orderable - Allow users to order products online via WeedMaps. Dispensary will recieve orders according to settings configured at WeedMaps.com, order fulfillment can be done via LeafLogix Backend/POS. Per-product Weedmaps Orderable setting available on Product Master. Product Master setting overrides this setting.",
  "Weedmaps settings saved": "Weedmaps settings saved",
  "WeedMaps Sync Started!": "WeedMaps Sync Started!",
  "Week Planted": "Week Planted",
  "Weekly Recurrence": "Weekly Recurrence",
  "Weight": "Weight",
  "Weight (g)": "Weight (g)",
  "Wet Bud Weight": "Wet Bud Weight",
  "Wet Trim Weight": "Wet Trim Weight",
  "Wet waste": "Wet waste",
  "Wet Waste": "Wet Waste",
  "Wet Weight": "Wet Weight",
  "Wet Weights": "Wet Weights",
  "Wet weights exceed total wet weight": "Wet weights exceed total wet weight",
  "When browsing products, show Price, Weight, THC and CBD content when available. If disabled, just show price": "When browsing products, show Price, Weight, THC and CBD content when available. If disabled, just show price",
  "When creating a new user, you will set up their account first then set their password using the above steps.": "When creating a new user, you will set up their account first then set their password using the above steps.",
  "When the CTRL key is held down you can notice that the mouse pointer changes to a crosshair. This will allow you to select multiple rows by using your mouse. Simply click and drag to select/un-select the rows you want.": "When the CTRL key is held down you can notice that the mouse pointer changes to a crosshair. This will allow you to select multiple rows by using your mouse. Simply click and drag to select/un-select the rows you want.",
  "Which Unit Price": "Which Unit Price",
  "Wholesale": "Wholesale",
  "Width": "Width",
  "With that said, the prior version of our software (version 2.0)  will no longer be supported as of Sept 15, 2018 and access to the older version of the software will be removed.": "With that said, the prior version of our software (version 2.0)  will no longer be supported as of Sept 15, 2018 and access to the older version of the software will be removed.",
  "Would you like to start over?": "Would you like to start over?",
  "X Position": "X Position",
  "Y Position": "Y Position",
  "Year": "Year",
  "yes": "yes",
  "Yes": "Yes",
  "Yes - Remove": "Yes - Remove",
  "You are currently clocked in": "You are currently clocked in",
  "You are integrating with weedmaps!": "You are integrating with weedmaps!",
  "You are not clocked in": "You are not clocked in",
  "You do not have permission to create a plant group": "You do not have permission to create a plant group",
  "You do not have permission to view or edit lab samples": "You do not have permission to view or edit lab samples",
  "You need to be a manager to access this feature!": "You need to be a manager to access this feature!",
  "You should not set or modify this value unless instructed to by LeafLogix staff.": "You should not set or modify this value unless instructed to by LeafLogix staff.",
  "You successfully clocked in": "You successfully clocked in",
  "You successfully clocked out": "You successfully clocked out",
  "Your Quickbooks Settings have not been set up": "Your Quickbooks Settings have not been set up",
  "You've already added this product to the PO. Please edit it instead of adding another line": "You've already added this product to the PO. Please edit it instead of adding another line",
  "Zero Value": "Zero Value",
  "This user is not authorized to view this page": "This user is not authorized to view this page",
  "Home": "Home",
  "Marketing": "Marketing",
  "Delivery": "Delivery",
  "Manufacturing": "Manufacturing",
  "Traceability": "Traceability",
  "Catalog": "Catalog",
  "Brands": "Brands",
  "Configure": "Configure",
  "Overview": "Overview",
  "Point of Sale": "Point of Sale",
  "Data Audit Tools": "Data Audit Tools",
  "Data Validation": "Data Validation",
  "Data Troubleshooting": "Data Troubleshooting",
  "Ecommerce": "Ecommerce",
  "End of Day": "End of Day",
  "Financial": "Financial",
  "State Reporting": "State Reporting",
  "Customer Groups": "Customer Groups",
  "Batches": "Batches",
  "Harvests": "Harvests",
  "Waste": "Waste",
  "Infusions": "Infusions",
  "Integration Audit": "Integration Audit",
  "Receipt Audit": "Receipt Audit",
  "Cultivation Audit": "Cultivation Audit",
  "Cultivation Integration Audit": "Cultivation Integration Audit",
  "Account": "Account",
  "Devices": "Devices",
  "Taxes & Compliance": "Taxes & Compliance",
  "Fees & Donations": "Fees & Donations"
}
