import React from 'react';

import styled from 'styled-components';

import { Button, LinkButton } from 'src/app/components/lib/button';
import { Heading } from 'src/app/components/lib/heading';
import { Modal } from 'src/app/components/lib/modal';
import { useNotificationActions } from 'src/app/state/notifications';

import { MigrationIconWarning } from './migration-icon-warning';

type ServerMigrationPromptModalProps = {
  backofficeURL: string;
  dayBeforeDeadline: string;
  deadlineDateLabel: string;
  onClose: () => void;
  open: boolean;
  useUpdatedMessaging: boolean;
};

export function ServerMigrationPromptModal({
  open,
  onClose,
  deadlineDateLabel,
  dayBeforeDeadline,
  backofficeURL,
  useUpdatedMessaging,
}: ServerMigrationPromptModalProps) {
  const notifications = useNotificationActions();

  const openingText =
    deadlineDateLabel?.length && dayBeforeDeadline?.length
      ? `Your account will be migrated to an upgraded environment on ${deadlineDateLabel}. This URL will be disabled one day ahead of time on ${dayBeforeDeadline}.`
      : 'Your account will soon be migrated to an upgraded environment.';

  const handleCopyURLToClipboard = async () => {
    await navigator.clipboard.writeText(backofficeURL);
    notifications.success({ message: 'URL copied to clipboard' });
  };

  if (useUpdatedMessaging) {
    return (
      <Modal
        Content={
          <Container>
            <MigrationIconWarning />
            <TextSection>
              <StyledHeading>Your URL has changed</StyledHeading>
              <Text>
                As part of the routine maintenance of our platform, the URL you use to access Dutchie is being updated.
                This URL will be disabled {deadlineDateLabel ? `on ${deadlineDateLabel}` : 'soon'}. Please click below
                to access and log into your new URL or copy and paste the link below.
              </Text>
            </TextSection>
            <CopyableURLContainer>
              <DisplayURL>{backofficeURL}</DisplayURL>
              <Button buttonSize='small' label='Copy' variant='outlineBlue' onClick={handleCopyURLToClipboard} />
            </CopyableURLContainer>
            <LinksSection>
              <Button label='Go to new URL' onClick={() => (window.location.href = backofficeURL)} />
            </LinksSection>
          </Container>
        }
        hideHeader
        open={open}
        title=''
        onClose={onClose}
      />
    );
  }

  return (
    <Modal
      Content={
        <Container>
          <MigrationIconWarning />
          <TextSection>
            <StyledHeading>Your URL has changed</StyledHeading>
            <Text>
              {openingText} Please click below to access and log into your new URL or copy and paste the link below.
              Visit the help center to learn more.
            </Text>
          </TextSection>
          <CopyableURLContainer>
            <DisplayURL>{backofficeURL}</DisplayURL>
            <Button buttonSize='small' label='Copy' variant='outlineBlue' onClick={handleCopyURLToClipboard} />
          </CopyableURLContainer>
          <LinksSection>
            <Button label='Go to new URL' onClick={() => (window.location.href = backofficeURL)} />
            <LinkButton
              label='Visit the help center'
              onClick={() => window.open('https://support.dutchie.com/hc/en-us/articles/28929066112147')}
            />
          </LinksSection>
        </Container>
      }
      hideHeader
      open={open}
      title=''
      onClose={onClose}
    />
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #121516;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: var(--sizes-30);
  font-weight: 600;
`;

const Text = styled.p`
  font: var(--font-regular-14pt-normal);
  color: #485055;
`;

const CopyableURLContainer = styled.div`
  background-color: #edf1fa;
  border-radius: 8px;
  height: 48px;
  width: 458px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DisplayURL = styled.span`
  font-weight: 600;
  font-size: 13px;
`;

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
