import React, { useState } from 'react';

import { CaretDown } from 'src/app/components/icons/caret-down';
import { Button } from 'src/app/components/lib/button';
import { DropdownMenuMultiple } from 'src/app/components/lib/dropdown/menu';

import type { DropdownPaginationProps } from '../dropdown/types';
import type { ButtonProps as MUIButtonProps, MenuProps as MUIMenuProps } from '@mui/material';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown/menu';

export type MultiFilterSelectProps = {
  activeFilter: DropdownMenuOption[];
  automationId?: string;
  ButtonProps?: MUIButtonProps;
  disabled?: boolean;
  footerContent?: React.ReactNode;
  infiniteScroll?: boolean;
  label: string;
  MenuProps?: MUIMenuProps;
  onChange: (updatedValue: DropdownMenuOption[]) => void;
  onSearchInputChange?: (input: string) => void;
  onSelectAll?: () => Promise<DropdownMenuOption[]>;
  options: DropdownMenuOption[];
  paginationProps?: DropdownPaginationProps;
};

function isSelectionDifferentFromActiveFilter(
  selection: DropdownMenuOption[],
  activeFilter: DropdownMenuOption[]
): boolean {
  if (selection.length !== activeFilter.length) {
    return true;
  }

  const selectionIds = selection.filter(Boolean).map(({ id }) => id);
  const activeFilterIds = activeFilter.filter(Boolean).map(({ id }) => id);

  const allSelectionsInActiveFilter = selectionIds.every((id) => activeFilterIds.includes(id));
  const allActiveFiltersInSelections = activeFilterIds.every((id) => selectionIds.includes(id));

  return !(allActiveFiltersInSelections && allSelectionsInActiveFilter);
}

export function MultiFilterSelect(props: MultiFilterSelectProps) {
  const {
    ButtonProps,
    MenuProps,
    infiniteScroll = false,
    label,
    options,
    activeFilter,
    automationId,
    disabled,
    onChange,
    footerContent,
    paginationProps,
    onSearchInputChange,
    onSelectAll,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOnChange = (newValue: DropdownMenuOption[]) => {
    const selectionHasChanged = isSelectionDifferentFromActiveFilter(newValue, activeFilter);
    if (selectionHasChanged) {
      onChange(newValue);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newValue) => {
    handleOnChange(newValue);

    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleMenuOnChange = (event, newValue) => {
    handleOnChange(newValue);
  };

  const multiFilterLabel = activeFilter.length === 0 ? label : `${label} (${activeFilter.length})`;

  return (
    <>
      <Button
        {...ButtonProps}
        aria-controls='filter-menu'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        automationId={automationId}
        className='multi-filter-select-button'
        disabled={disabled}
        endIcon={<CaretDown />}
        id={`multi-filter-menu--${label}`}
        label={multiFilterLabel}
        variant='white'
        onClick={handleClick}
      />
      <DropdownMenuMultiple
        {...MenuProps}
        anchorEl={anchorEl}
        footerContent={footerContent}
        handleClose={handleClose}
        id='multi-filter-menu'
        infiniteScroll={infiniteScroll}
        open={open}
        options={options}
        paginationProps={paginationProps}
        placement='bottom-end'
        value={activeFilter}
        onChange={handleMenuOnChange}
        onSearchInputChange={onSearchInputChange}
        onSelectAll={onSelectAll}
      />
    </>
  );
}
