import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useRecoilState } from 'recoil';

import { notificationsAtom } from 'src/app/state/notifications';

import { Notification } from './notification';
import { NotificationsWrapper } from './notification.styles';
import { VersionChecker } from './version-checker';

import type { Notification as NotificationType, LegacyNotification } from './types';

export function Notifications() {
  const flags = useFlags();
  const useNewVersionAvailableNotification = flags['pos.backoffice.new-version-available-notification.rollout'];

  const [notifications, setNotificationsState] = useRecoilState(notificationsAtom);

  const removeNotification = (notificationId: string) => {
    const notification = notifications.find(({ uuid: id }) => id === notificationId) as NotificationType;

    // first set the notification to be removed
    setNotificationsState((prevState) => [
      ...prevState.filter(({ uuid: id }) => id !== notificationId),
      { ...notification, shouldRemove: true },
    ]);

    // wait 300ms for the animation to finish then completely remove the notification
    setTimeout(() => {
      setNotificationsState((prevState) => prevState.filter(({ uuid: id }) => id !== notificationId));
    }, 300);
  };

  return (
    // This className can be removed once the legacy notification store is not used
    <TransitionGroup className='notification' component={NotificationsWrapper}>
      <>
        {useNewVersionAvailableNotification && <VersionChecker />}

        {notifications.map((notification, i) => {
          const { isLegacy = false } = notification;

          if (isLegacy) {
            const { additionalInfo, body, sticky, show, timestamp, type, uuid } = notification as LegacyNotification;

            if (!show) {
              return null;
            }

            return (
              <CSSTransition classNames='toast' key={uuid} timeout={300}>
                <Notification
                  additionalInfo={additionalInfo} // only used for API errors to display connection error details
                  automationId={`notification_note_${i}`}
                  isLegacy={isLegacy}
                  key={`${i}_${uuid}`}
                  message={body}
                  sticky={sticky}
                  timestamp={timestamp}
                  uuid={uuid}
                  variant={type}
                  onRemove={removeNotification}
                />
              </CSSTransition>
            );
          }

          const { additionalInfo, message, sticky, subContent, timestamp, uuid, variant } =
            notification as NotificationType;

          return (
            <CSSTransition classNames='toast' key={uuid} timeout={300}>
              <Notification
                additionalInfo={additionalInfo} // only used for API errors to display connection error details
                automationId={`notification_note_${i}`}
                key={uuid}
                message={message}
                shouldRemove={notification.shouldRemove}
                sticky={sticky}
                subContent={subContent}
                timestamp={timestamp}
                uuid={uuid}
                variant={variant}
                onRemove={removeNotification}
              />
            </CSSTransition>
          );
        })}
      </>
    </TransitionGroup>
  );
}
