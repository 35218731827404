import React, { useState, useEffect } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Divider } from 'src/app/components/lib/divider';
import { useBohNav } from 'src/app/hooks/use-boh-nav';
import { useDarkMode } from 'src/app/state/dark-mode';

import { NewItem, NewItemContainer } from '../sidebar/shared';

import type { DrawerProps } from '@mui/material/Drawer/Drawer';

type SubNavigationProps = {
  id?: string;
  isDualPane?: boolean; // isDualPane = the slide out sub nav panel from the main navigation component
  onSubNavClick?: DrawerProps['onClose'];
  parentSection?: string;
};

export function SubNavigation({ id, parentSection, onSubNavClick, isDualPane }: SubNavigationProps) {
  const darkMode = useDarkMode();
  const { pathname } = useLocation();
  const links = useBohNav();

  const parentSectionFromPath = parentSection;
  const [activeSection, setActiveSection] = useState(parentSectionFromPath);
  const subNavItems = links?.find((navItem) => navItem.label === activeSection)?.subNavItems;
  const subNavItemsHasConfigureTab = subNavItems?.find(({ label }) => label === 'Configure');
  const subNavItemsCount =
    subNavItems?.filter(({ label }) => label !== 'Configure' && label !== 'Help center').length ?? 0;

  useEffect(() => {
    setActiveSection(parentSectionFromPath);
  }, [parentSectionFromPath]);

  return (
    <SidebarContainer $darkMode={darkMode} className={isDualPane ? 'DualPane' : undefined} id={id}>
      {activeSection && !isDualPane && <SubNavSection>{activeSection}</SubNavSection>}

      {subNavItems?.map((item) => {
        const { to, visible, label, isNew } = item;
        const isSubNavActive = pathname.includes(to);
        const addDividerToConfigure = label === 'Configure' && subNavItemsCount > 0;
        const addDividerToHelpLink = label === 'Help center' && !subNavItemsHasConfigureTab;
        const addDivider = addDividerToConfigure || addDividerToHelpLink;

        return (
          visible &&
          (addDivider ? (
            <div key={`subnav-item-${to}`}>
              <SubNavItemDivider />
              <NavLink rel='noopener noreferrer' target={label === 'Help center' ? '_blank' : undefined} to={to}>
                <SubNavItem
                  $darkMode={darkMode}
                  className={isSubNavActive ? 'sidebar-nav_active' : ''}
                  data-testid={`index_sub-nav-item_${label}`}
                  key={label}
                  onClick={(e) => onSubNavClick?.(e, 'escapeKeyDown')}
                >
                  {label}
                </SubNavItem>
              </NavLink>
            </div>
          ) : (
            <NavLink
              key={`subnav-item-${to}`}
              rel='noopener noreferrer'
              target={label === 'Help center' ? '_blank' : undefined}
              to={to}
            >
              <SubNavItem
                $darkMode={darkMode}
                className={isSubNavActive ? 'sidebar-nav_active' : ''}
                data-testid={`index_sub-nav-item_${label}`}
                key={label}
                onClick={(e) => onSubNavClick?.(e, 'escapeKeyDown')}
              >
                {label}
                {isNew && (
                  <NewItemContainer>
                    <NewItem>NEW</NewItem>
                  </NewItemContainer>
                )}
              </SubNavItem>
            </NavLink>
          ))
        );
      })}
    </SidebarContainer>
  );
}

export const SidebarContainer = styled.div<{ $darkMode: boolean }>`
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-10);
  padding: var(--sizes-70) var(--sizes-40);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .menu-nav_not_active {
    :hover {
      background-color: #f6f6f6;
    }
  }

  .sidebar-nav_active {
    background: var(--color-gray-20);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-grayscale-black);
    font-weight: 600;

    svg {
      path {
        fill: var(--color-grayscale-black);
      }
    }
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background-color: var(--color-gray-100);
      .sidebar-nav_active {
        background: var(--color-gray-90);
        color: var(--color-brand-primary-white);
      }
      .menu-nav_not_active {
        :hover {
          background-color: var(--color-gray-100);
        }
      }
    `}
`;

const SubNavSection = styled.div`
  padding: 0px var(--sizes-50) var(--sizes-30) var(--sizes-60);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-gray-60);
  font-size: var(--sizes-30);
  line-height: var(--sizes-40);
`;

const SubNavItem = styled.div<{ $darkMode: boolean }>`
  padding: var(--sizes-30) var(--sizes-50) var(--sizes-30) var(--sizes-60);
  font-size: 14px;
  line-height: var(--sizes-50);
  color: var(--color-gray-100);
  display: flex;

  :hover {
    font-weight: 600;
    color: var(--color-grayscale-black);
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: var(--color-gray-40);
      :hover {
        color: var(--color-brand-primary-white);
      }
    `}
`;

const SubNavItemDivider = styled(Divider)`
  width: 61px;
  margin: 22px var(--sizes-60);
`;
