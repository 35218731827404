/*
Example:

const originalObject = {
  a: 1,
  b: 2,
  c: 3,
  unwantedProp: 'don\'t want this'
};

const newObj = omit(originalObject, ['unwantedProp']); // { a: 1, b: 2, c: 3 }

*/

export function omit<T extends object, K extends keyof T>(object: T, keys: K[]): Omit<T, K> {
  const result = { ...object };
  keys.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete result[key];
  });
  return result as Omit<T, K>;
}
