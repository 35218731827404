/* eslint-disable no-console */
import LogRocket from 'logrocket';

import { isProduction } from './is-production';

export function initLogRocket() {
  try {
    if (isProduction()) {
      LogRocket.init('zg2tcu/leaflogix-boh', {
        network: {
          requestSanitizer: (request) => {
            const url = request.url.toLowerCase();

            if (url.includes('/posv3/user/employeelogin')) {
              request.body = undefined;
            }

            if (url.includes('/posv3/user/resetpassword')) {
              request.body = undefined;
            }

            return request;
          },
        },
      });
    }
  } catch (error) {
    console.error('Failed to initialize LogRocket', error);
  }
}
