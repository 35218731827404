// This is built to support nullable number types, where we want to
// maintain null as an empty value in the form state
export type getOnChangeValueProps = {
  isCurrency?: boolean;
  isNullable?: boolean;
  isNumber?: boolean;
  maxDecimalPlaces?: number;
  value: number | string;
};

export function formatInputDecimalValue(value: string, maxDecimalPlaces = 2) {
  const output = parseFloat(value).toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: maxDecimalPlaces,
    minimumFractionDigits: maxDecimalPlaces,
  });
  return output;
}

export const getOnChangeValue = ({ value, isNullable, isNumber }: getOnChangeValueProps) => {
  if (isNullable && value === '') {
    return null;
  }
  if (isNumber) {
    return Number(value);
  }
  return value;
};

export const getOnBlurValue = ({ value, isCurrency, maxDecimalPlaces = 2 }: getOnChangeValueProps) => {
  if (value && isCurrency) {
    return formatInputDecimalValue(`${value}`, maxDecimalPlaces);
  }
  return value;
};
