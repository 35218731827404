import { useMutation } from '@tanstack/react-query';

import { postData } from 'src/app/queries';
import { useNotificationActions } from 'src/app/state/notifications';

const FORGOT_PASSWORD = 'api/posv3/user/ForgotPassword';

type ForgotPasswordPayload = {
  UserName: string;
};

export function useForgotPasswordMutation() {
  const notifications = useNotificationActions();
  return useMutation({
    mutationKey: ['forgot-password'],
    mutationFn: (payload: ForgotPasswordPayload) =>
      postData<{ Url: string; UserName: string }, { Result: boolean }>({
        endpoint: FORGOT_PASSWORD,
        payload: {
          UserName: payload.UserName,
          Url: window.location.origin,
        },
      }),
    onSuccess: (body: { Result: boolean }) => {
      if (body.Result) {
        notifications.success({ message: 'We`ve sent an email with instructions on how to reset your password' });
      } else {
        console.error(body);
        notifications.error({ message: 'There was an error, please try again later.' });
      }
    },
  });
}
