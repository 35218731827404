import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { inventoryAtom } from 'src/app/state/inventory';
import { userPermissionsAtom } from 'src/app/state/user-permissions';

import { searchTermAtom } from './search-term';

export type Product = {
  Abbreviation: string;
  ActiveIngredients?: any;
  AdministrationMethod?: any;
  AllergenList: string;
  AllowAutomaticDiscounts: boolean;
  AlternateName: string;
  ApplicationDevice?: any;
  Available: number;
  AvailableUnitAbbreviation?: any;
  BigOnlineDescription?: any;
  BrandId?: any;
  BrandName?: any;
  Category: string;
  CategoryFlowerEquivalencies: any[];
  CBDContent?: any;
  CBDContentUnitId: number;
  ChargeCodeId?: any;
  Cost?: any;
  DefaultLabelId?: any;
  DefaultPricingTier?: any;
  DefaultUnitId: number;
  DistillationId?: any;
  DistillationName?: any;
  DosageId?: any;
  DosageLabel?: any;
  EpaRegistrationNumber?: any;
  ExpirationDays?: any;
  ExternalCategory: string;
  ExternalId: string;
  ExternalSubCategory: string;
  Flavor: string;
  FlowerEquivalent?: any;
  Grams: number;
  GrossWeight?: any;
  GTIN: string;
  ILTaxCategory?: any;
  IngredientList: string;
  IsCannabisProduct: string;
  IsCoupon: string;
  IsDeliveryDevice: boolean;
  IsFinished: boolean;
  IsNutrient: boolean;
  IsOnlineProduct: string;
  IsOnSale: boolean;
  IsPosProduct: string;
  IsRetired: boolean;
  IsTestProduct: boolean;
  ItemTaxable: string;
  LastAuditedDate?: any;
  LeafLinkBrandId?: any;
  LeafLinkCategoryId?: any;
  LeafLinkSubCategoryId?: any;
  LeaflyCategory?: any;
  LineageId?: any;
  LineageName?: any;
  LocationCost?: any;
  LocationExternalCategory?: any;
  LocationID?: any;
  LocationLowInventoryThreshold?: any;
  LocationMaxPurchasable?: any;
  LocationOnlineAvailable?: any;
  LocationPosAvailable?: any;
  LocationPrice?: any;
  LocationPricingTierName?: any;
  LocationRecPrice?: any;
  LocationSalesAccount?: any;
  LowInventoryThreshold?: any;
  MasterCategory: string;
  MaxQuantityPerTransaction?: any;
  MedicalOnly: boolean;
  MetrcBrand?: any;
  MMURAlternateForms?: any;
  MMURDevices?: any;
  MMURForm?: any;
  MMURFormId?: any;
  MMUROrderType?: any;
  MMUROrderTypeId?: any;
  MNMedicineId: string;
  Name: string;
  NDC: string;
  NetWeight?: any;
  NetWeightUnit?: any;
  NonCannabisWeight: number;
  NonCannabisWeightUnit: number;
  OCSItemNumber: string;
  OCSVariantNumber: string;
  OilVolume?: any;
  OnlineDescription?: any;
  OnlineTitle?: any;
  owner: string;
  Prescription: string;
  Price: number;
  ProducerId?: any;
  ProducerName?: any;
  ProductCategoryId: number;
  ProductId: number;
  ProductImageFileName?: any;
  ProductInstructions: string;
  ProductTags?: any;
  RecFlowerEquivalent?: any;
  RecPrice: number;
  RegulatoryCategoryId?: any;
  RegulatoryName?: any;
  SalesAccountId?: any;
  ServingSize?: any;
  ServingSizePerUnit?: any;
  Size?: any;
  SizeId?: any;
  SizeListItemId?: any;
  SizeName?: any;
  Sku: string;
  Strain?: any;
  StrainId?: any;
  SyncExternally: boolean;
  TaxCategories: any[];
  THCContent?: any;
  THCContentUnitId: number;
  TotalAmountApplied?: any;
  TotalAmountUnitOfMeasure?: any;
  Type: string;
  UnitCbdContentDose?: any;
  UnitThcContentDose?: any;
  UnitTypeId: number;
  Upc: string;
  UseSkuNumberAsSerialNo: string;
  Vendor: string;
  VendorId?: any;
  WeedMapsCategoryId?: any;
  WeedMapsOnlineOrderable?: any;
  WeedMapsSubCategoryId?: any;
  WeedMapsVerifiedBrandId?: any;
  WeedMapsVerifiedBrandName?: any;
  WeedMapsVerifiedBrandProductId?: any;
  WeedMapsVerifiedBrandVariantId?: any;
  WeedMapsVerifiedBrandVariantOptionId?: any;
  WeedMapsVerifiedBrandVariantValueId?: any;
  WeedMapsVerifiedBrandVariantValueName?: any;
  WeedMapsVerifiedProductName?: any;
};

type FilteredCatalog = {
  filteredCatalog: Product[];
  isLoading: boolean;
};

export function useFilteredCatalog(): FilteredCatalog {
  const { perms } = useRecoilValue(userPermissionsAtom);
  const filter = useRecoilValue(searchTermAtom);
  const canViewProductMaster = !!perms[Permissions.ViewProductMaster];

  const { products, productsLoading: isLoading } = useRecoilValue(inventoryAtom);

  const filteredCatalog = useMemo(() => {
    if (!canViewProductMaster) {
      return [];
    }
    if (isLoading || !products?.length) {
      return [];
    }
    return products.filter((product) => {
      const hasSKUMatch = product.Sku?.toString().includes(filter);
      const hasNameMatch = product.Name?.toLowerCase().includes(filter.toLowerCase());
      return hasSKUMatch || hasNameMatch;
    });
  }, [canViewProductMaster, products, isLoading, filter]);

  return {
    isLoading,
    filteredCatalog,
  };
}
