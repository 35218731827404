import keyMirror from 'keymirror';

export const endPoints = {
  LOGIN: 'api/posv3/user/EmployeeLogin',
  FORGOT: 'api/posv3/user/ForgotPassword',
  RESET: 'api/posv3/user/ResetPassword',
  LOGOUT: 'api/posv3/user/LogOut',
  PASSWORD_SETTINGS: 'api/security/password/settings',
};

export const actions = keyMirror({
  SSO_LOGIN_ENABLED: null,
  SSO_LOGIN: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
