import { SimpleCachedDataStore } from './SimpleCachedDataStore';

type ProductTaxCategory = {
  ProductTaxCategory: string;
  ProductTaxCategoryId: number; // int
  RateAmount: number; // decimal
  TaxRateId: number; // int
};

type CustomerType = {
  CustomerType: string;
  CustomerTypeId: number; // int
  RateAmount: number; // decimal
  TaxRateId: number; // int
};

export type TaxRate = {
  AccountId: number;
  AppliesToAllCustomers: boolean;
  AppliesToAllProducts: boolean;
  AppliesToAllZones: boolean;
  AppliesToWholesaleTransfers: boolean;
  ApplyMarkupRate: boolean;
  ApplyPreDiscount: boolean;
  ApplyToCost: boolean;
  ArmsLength: boolean;
  BookToSalesAccount: boolean;
  CustomerTypes: CustomerType[];
  IsBuiltIntoPrice: boolean;
  IsExciseTax: boolean;
  IsPotencyTax: boolean;
  MarkupRate: any;
  MetrcTaxTypeId: number;
  ProductTaxCategories: ProductTaxCategory[];
  RateAmount: number; // decimal
  RateName: string;
  RateTypeUnitId: number;
  StackingOrder: number; // int
  StacksOnOtherRates: boolean;
  TaxRateId: number; // int
  TaxRateTypeId: number; // int
  Zones: any[];
};

class TaxRateStore extends SimpleCachedDataStore {
  constructor() {
    super();
  }

  sourceUrl = 'api/tax/get-all';
  entity = 'Tax Rates';

  get taxRates() {
    return this.data as TaxRate[];
  }
}

export default new TaxRateStore();
