import React from 'react';

import styled from 'styled-components';

import { Loader } from 'src/app/components/lib/loader';

import type { LoaderProps } from 'src/app/components/lib/loader';

type BlockSpinnerProps = {
  className?: string;
  size?: LoaderProps['size'];
  variant?: LoaderProps['variant'];
  visible?: boolean;
};

function BlockSpinner(props: BlockSpinnerProps) {
  const { visible = true, className = '', variant = 'black', size = '5x' } = props;

  if (!visible) {
    return null;
  }

  return (
    <BlockSpinnerContainer className={className}>
      <Loader size={size} variant={variant} />
    </BlockSpinnerContainer>
  );
}

export default BlockSpinner;

const BlockSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
