import styled, { css } from 'styled-components';

export const CardContainer = styled.section<{ $marginBottom?: string }>`
  border-radius: var(--sizes-30);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  outline: 1px solid var(--color-gray-20);
  overflow: visible;
  width: 100%;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 0};
`;

export const CardHeader = styled.header<{ $borderBottom?: string }>`
  --border-width: ${({ $borderBottom }) => $borderBottom ?? '1px'};
  align-items: center;
  border-bottom: var(--border-width) solid var(--color-gray-20);
  display: flex;
  height: calc(var(--sizes-110) - var(--border-width));
  justify-content: space-between;
  padding: 0 var(--sizes-60);

  .content-card--label-container {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
  }
`;

export const CardFooter = styled.footer`
  --border-width: 1px;
  align-items: center;
  border-top: var(--border-width) solid var(--color-gray-20);
  display: flex;
  height: calc(var(--sizes-110) - var(--border-width));
  justify-content: space-between;
  padding: 0 var(--sizes-60);
`;

export const CardTitle = styled.span`
  color: var(--color-grayscale-black);
  font: var(--font-large-16pt-semibold);
  align-self: center;
`;

export const CardContent = styled.article<{ $addContentPadding: boolean; $contentColor: string; $marginTop?: string }>`
  ${({ $addContentPadding }) => {
    if ($addContentPadding) {
      return css`
        padding: 0 var(--sizes-70) var(--sizes-40) var(--sizes-70);
      `;
    }
    return css``;
  }};

  margin-top: ${({ $marginTop }) => $marginTop ?? 0};
  background-color: ${({ $contentColor }) => $contentColor ?? ''};

  .input-form-control {
    background-color: ${({ $contentColor }) => $contentColor ?? ''};
  }
`;

export const CardSlot = styled.div`
  display: flex;
  gap: var(--sizes-20);
`;
