import React, { useState } from 'react';

import { useInterval } from 'src/app/hooks/use-interval';

export function Countdown() {
  const [remainingTime, setRemainingTime] = useState(60);

  useInterval(() => {
    setRemainingTime((prevState) => prevState - 1);
  }, 1_000);

  return <span>Session expires in {remainingTime} seconds</span>;
}
