import { useQuery } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { audienceDetailKeys } from './query-key-factory';

export type SegmentCustomer = {
  CustomerId: number;
  DateAddedUtc: string;
  Email: string;
  Name: string;
  Phone: string;
};

type SegmentPayload = {
  segmentId?: number;
};

export const GET_SEGMENT_AUDIENCE = 'api/segment/get-segment-audience';

export function useGetSegmentAudienceQuery({ segmentId }: SegmentPayload) {
  return useQuery({
    queryKey: audienceDetailKeys.one(Number(segmentId)),
    queryFn: () =>
      postData<SegmentPayload, SegmentCustomer[]>({ endpoint: GET_SEGMENT_AUDIENCE, payload: { segmentId } }),
  });
}
