import { useQuery } from '@tanstack/react-query';

import { postData } from '..';

import type { QueryKey } from '@tanstack/react-query';

type SegmentPayload = {
  segmentId: number | null;
};

export const checkSegmentStatusQueryKey: QueryKey = ['check-segment-status'];

export const CHECK_SEGMENT_STATUS_ENDPOINT = 'api/segment/check-for-segment-status';

export const useCheckSegmentStatusQuery = (params: SegmentPayload) =>
  useQuery({
    queryKey: checkSegmentStatusQueryKey,
    queryFn: () => postData<SegmentPayload, boolean>({ endpoint: CHECK_SEGMENT_STATUS_ENDPOINT, payload: params }),
    staleTime: 0,
    refetchInterval: (isSegmentUpdating) => (isSegmentUpdating ? 3000 : false),
    enabled: !!params.segmentId,
  });
