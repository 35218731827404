import { Menu as MUIMenu } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledActionsMenu = styled(MUIMenu)<{ $darkMode: boolean }>`
  .MuiPaper-root {
    background: var(--color-brand-primary-white);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    border-radius: 9px;
    margin-top: var(--sizes-20);
  }

  .MuiMenuItem-root {
    font-size: 14px;
    line-height: 20px;
    padding: var(--sizes-30) var(--sizes-70);

    .warning {
      color: var(--color-red-50);
    }
  }

  .MuiMenuItem-root:hover {
    background: var(--color-gray-10);
    font-weight: var(--font-weight-semibold);
  }
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      .MuiPaper-root {
        background: var(--color-grayscale-black);
        box-shadow: 0px 6px 20px rgba(255, 255, 255, 0.2);
      }
      .MuiMenuItem-root:hover {
        background: var(--color-gray-100);
      }
    `}
`;
