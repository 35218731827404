import { Divider as MUIDivider } from '@mui/material';
import styled, { css } from 'styled-components';

export const RebrandDivider = styled(MUIDivider)<{ $darkMode: boolean; $variant: string; $width?: string }>`
  ${({ $darkMode, $variant, $width }) => css`
    border-color: var(--color-gray-20);
    ${$darkMode &&
    css`
      background-color: var(--color-gray-90);
    `}
    ${$variant === 'vertical-flex' &&
    css`
      border-right-width: 1px;
      margin: 0;
      flex-shrink: 0;
      border-width: 0;
      border-style: solid;
      border-bottom-width: 0;
      height: auto;
      border-right-width: 1px;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      border-right-width: 1px;
    `}
    ${$variant !== 'vertical-flex' &&
    css`
      width: ${$width || '100%'};
      margin-left: 0;
      margin-right: 0;
    `}

    ${$variant === 'below-header' && belowHeaderDivider}
    ${$variant === 'form-section-divider' && formSectionDivider}
    ${$variant === 'end-of-form' && endOfFormDivider}
    ${$variant === 'modal-form-divider' && modalFormDivider}
    ${$variant === 'compact' && compactDivider}
  `}
`;

const belowHeaderDivider = css`
  margin-top: var(--sizes-30);
  margin-bottom: var(--sizes-70);
`;

const formSectionDivider = css`
  margin-top: var(--sizes-70);
  margin-bottom: var(--sizes-70);
`;

const endOfFormDivider = css`
  margin-top: var(--sizes-70);
  margin-bottom: var(--sizes-70);
`;

const modalFormDivider = css`
  margin-top: var(--sizes-70);
  margin-bottom: var(--sizes-70);
`;

const compactDivider = css`
  margin-top: var(--sizes-0); // 0px
  margin-bottom: var(--sizes-0); // 0px
`;
