import React from 'react';

import { MenuItem } from 'src/app/components/lib/menu-item';
import { Select } from 'src/app/components/lib/select';
import { States, Provinces, GetRegionName } from 'src/app/constants/state-and-province-options';

import { StyledPlaceholder } from '../../lib/select/select-rebrand.styles';

import type { SxProps } from '@mui/material';

type StateAndProvinceDropdownProps = {
  automationId?: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelPlacement?: any;
  onChange: (value: string) => void;
  placeholder?: string;
  sx?: SxProps;
  value: string;
};

export function StateAndProvinceDropdown(props: StateAndProvinceDropdownProps) {
  const {
    automationId,
    className = 'select',
    disabled,
    label,
    placeholder = 'Select a state/province',
    labelPlacement,
    onChange,
    sx,
    value = '',
  } = props;

  return (
    <Select
      automationId={automationId}
      className={className}
      disabled={disabled}
      label={label}
      labelPlacement={labelPlacement}
      renderValue={(selected) =>
        !selected ? <StyledPlaceholder>{placeholder}</StyledPlaceholder> : GetRegionName(selected)
      }
      sx={sx}
      value={value}
      onChange={({ target }) => {
        onChange(target.value);
      }}
    >
      <MenuItem disabled value='US'>
        <b>
          <u>US</u>
        </b>
      </MenuItem>
      {Object.entries(States).map(([key, currentState]) => (
        <MenuItem data-testid={`state_provinces-menu-item_${key}`} key={key} value={key}>
          {currentState.name}
        </MenuItem>
      ))}
      <MenuItem disabled value='Canada'>
        <b className='list-selection-header'>
          <u>Canada</u>
        </b>
      </MenuItem>
      {Object.entries(Provinces).map(([key, currentProvince]) => (
        <MenuItem data-testid={`state_provinces-menu-item_${key}`} key={key} value={key}>
          {currentProvince.name}
        </MenuItem>
      ))}
    </Select>
  );
}
