import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { autocompleteRenderOption } from 'src/app/components/lib/autocomplete';
import { StyledAutocomplete, StyledPopperComponent } from 'src/app/components/lib/autocomplete/autocomplete.styles';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { Input } from '../global-search/search-input';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

type SuperUserLocationSelectProps = {
  changeCompany: (props: { target: { value: string } }) => void;
  changeLocation: (props: { target: { value: string } }) => void;
};

export function SuperUserLocationSelect(props: SuperUserLocationSelectProps) {
  const { changeCompany, changeLocation } = props;
  // this should always be true for now, pulling it in from the existing ui
  // for the off chance we use this for all users
  const isSuperUser = usePermissionCheck(Permissions.SuperUser);
  const { locations, selectedLocation, companies, selectedCompany } = useRecoilValue(userDispensariesAtom);
  const flags = useFlags();
  const showLocationPublish = flags['pos-publish-locations'] && isSuperUser;
  const companyOptions = (companies || []).map((company) => ({
    label: company.LspName as string,
    id: String(company.LspId),
    variant: 'compact' as const,
    value: company.LspName as string,
  }));
  const locationOptions = (locations || []).map((location) => ({
    label: (!location.IsLive && showLocationPublish ? `${String(location.Name)} - STAGING` : location.Name) as string,
    id: String(location.LocId),
    variant: 'compact' as const,
    value: location.Name as string,
  }));

  return (
    <>
      <StyledAutocomplete
        disableClearable
        forcePopupIcon={false}
        openOnFocus
        options={companyOptions as DropdownMenuOption[]}
        PopperComponent={StyledPopperComponent}
        renderInput={(params) => (
          <Input
            $isActive={false}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'header_select_company',
              sx: { font: 'var(--font-small-13pt-normal) !important' },
            }}
            inputRef={params.InputProps.ref}
            size='small'
          />
        )}
        renderOption={(props, option, state) =>
          autocompleteRenderOption(props, option, state, `rebrand-header_menu-item_${String(option.value)}`)
        }
        sx={{ width: '200px' }}
        value={companyOptions.find((company) => company.id === String(selectedCompany?.LspId))}
        onChange={(event, value) => changeCompany({ target: { value: String(value?.id) ?? '' } })}
      />

      <StyledAutocomplete
        disableClearable
        forcePopupIcon={false}
        openOnFocus
        options={locationOptions}
        PopperComponent={StyledPopperComponent}
        renderInput={(params) => (
          <Input
            $isActive={false}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'header_select_location',
              sx: { font: 'var(--font-small-13pt-normal) !important' },
            }}
            inputRef={params.InputProps.ref}
            size='small'
          />
        )}
        renderOption={(props, option, state) =>
          autocompleteRenderOption(props, option, state, `rebrand-header_menu-item_${String(option.value)}`)
        }
        sx={{ width: '200px' }}
        value={locationOptions.find((location) => location.id === String(selectedLocation?.LocId))}
        onChange={(event, value) => changeLocation({ target: { value: String(value?.id) ?? '' } })}
      />
    </>
  );
}
