import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import UserActions from 'src/app_deprecated/actions/UserActions';
import CompanyApi from 'src/app_deprecated/api/CompanyApi';
import UserApi from 'src/app_deprecated/api/UserApi';
import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';
import { actions as ActionTypes } from 'src/app_deprecated/constants/UserConstants';
import UserStore from 'src/app_deprecated/stores/UserStore';
import AppDispatcher from 'src/app_deprecated/utils/AppDispatcher';

import { BohExitIcon } from 'src/app/components/icons/boh-exit-icon';
import { BackButtonIcon } from 'src/app/components/lib/heading/back-icon';
import { IconButton } from 'src/app/components/lib/icon-button';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { Select } from 'src/app/components/lib/select';
import { useNotificationActions } from 'src/app/state/notifications';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { userPermissionsAtom } from 'src/app/state/user-permissions';

type LocationMenuProps = {
  onBackAction: () => void;
  onClose: (event) => void;
};

export function LocationMenu({ onClose, onBackAction }: LocationMenuProps) {
  const { perms } = useRecoilValue(userPermissionsAtom);
  const { locations, selectedLocation, companies, selectedCompany } = useRecoilValue(userDispensariesAtom);
  const queryClient = useQueryClient();

  const flags = useFlags();
  const showLocationPublish = flags['pos-publish-locations'] && perms[Permissions.SuperUser];
  const notificationActions = useNotificationActions();

  async function changeCompany({ target }) {
    const company = companies.find(({ LspId }) => LspId === parseInt(target.value, 10));
    const formData = UserStore.getApiData();

    // first get the locations associated with the selected company
    const newLocations = await CompanyApi.getLocations({
      ...formData,
      LspId: company.LspId,
    });
    const locationWithAccess = (newLocations.Data || []).find((loc) => loc.HasPermission);

    // fwiw I _believe_ this check should be done on the server. We shouldn't be sending down lsp's that
    // the user has no locs available under
    if (!locationWithAccess) {
      // notify the user that they can't access this company(lsp)
      notificationActions.alert({
        message: 'Unauthorized',
        subContent: 'It appears you do not have access to view the requested company',
      });
      return;
    }

    // set the location's lsp id to the id  of the lsp selected from the menu
    locationWithAccess.LspId = company.LspId;

    // clear the RQ cache
    queryClient.clear();

    // now grab images and set default lsp
    void UserApi.setDefaultLsp(formData.SessionId, UserStore.getState().data.Id, company.LspId);
    // this could possibly be removed. We show logo on invoices and in settings.
    const images = await CompanyApi.getLogo({ ...formData, LspId: company.LspId });

    // then set the location and notify all listening stores
    UserActions.selectLocation(locationWithAccess);
    AppDispatcher.dispatch({
      actionType: ActionTypes.SELECT_COMPANY,
      data: {
        locations: newLocations.Data || [],
        currentCompany: { ...company, Logo: images.Data[0].Logo },
        currentLocation: locationWithAccess,
      },
    });
  }

  function changeLocation({ target }) {
    const location = locations.find(({ LocId }) => LocId === parseInt(target.value, 10));
    queryClient.clear();
    UserActions.selectLocation(location);
  }

  return (
    <SidebarContainer>
      <MenuHeader>
        <BackAndTitle>
          <BackIconButton onClick={onBackAction}>
            <BackButtonIcon />
          </BackIconButton>
          <SubNavSectionTitle>Location</SubNavSectionTitle>
        </BackAndTitle>
        <IconButton onClick={onClose}>
          <BohExitIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
      </MenuHeader>
      <LocationSelectionContainer>
        <SelectHeader
          automationId='nav_select_company'
          value={selectedCompany.LspId.toString()}
          onChange={(e) => changeCompany(e)}
        >
          {companies.map((company) => (
            <MenuItem
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              data-testid={`nav-_menu-item_${company.LspName}`}
              key={company.LspId}
              sx={{ fontSize: '12px' }}
              value={company.LspId}
            >
              {company.LspName}
            </MenuItem>
          ))}
        </SelectHeader>
        <SelectHeader
          automationId='nav_select_location'
          value={selectedLocation.LocId}
          onChange={(e) => changeLocation(e)}
        >
          {locations
            .filter((location) => location.HasPermission || location.LocId === selectedLocation.LocId)
            .map((location) => (
              <MenuItem
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                data-testid={`nav-_menu-item_${location.Name}`}
                key={location.LocId}
                sx={{ fontSize: '12px' }}
                value={location.LocId}
              >
                {!location.IsLive && showLocationPublish ? `${location.Name} - STAGING` : location.Name}
              </MenuItem>
            ))}
        </SelectHeader>
      </LocationSelectionContainer>
    </SidebarContainer>
  );
}

const LocationSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--sizes-70);
  gap: var(--sizes-70);
  width: 100%;
`;

const SelectHeader = styled(Select)`
  width: 100%;
  background: none;

  .MuiSelect-select {
    min-height: var(--sizes-50);
    padding: 12px 20px;
  }
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 56px 32px 20px;

  button {
    padding: 0;
    width: 20px;
    height: 20px;
    :focus {
      outline: 0;
    }

    :hover {
      svg {
        path {
          fill: var(--color-gray-90);
        }
      }
      padding: 6px;
      background: var(--color-gray-10);
      border-radius: 18px;
    }
  }
`;

const BackAndTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    padding: 0;
    width: 40px;
    height: 40px;
    :focus {
      outline: 0;
    }
  }
`;

const BackIconButton = styled(IconButton)`
  margin-right: var(--sizes-50);
  border: 1px solid var(--color-gray-40);
`;

const SubNavSectionTitle = styled.div`
  font: var(--font-headings-heading-1);
`;
