import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MenuItem, Popper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

import InventoryStore from 'src/app_deprecated/stores/InventoryStore';

import { searchTermAtom } from 'src/app/hooks/global-search/search-term';
import { useGlobalSearch } from 'src/app/hooks/global-search/use-global-search';
import { analyticsAtom } from 'src/app/state/analytics';

import { SearchInfo } from './search-info';
import { SearchInput } from './search-input';
import { SearchResult } from './search-result';

import type { SearchResult as SearchResultType } from 'src/app/hooks/global-search/use-global-search';

export enum SearchStatus {
  LOADING = 'loading',
  NO_RESULTS = 'noResults',
  RESULTS_READY = 'resultsReady',
  WAITING = 'waiting',
}

type GlobalSearchProps = {
  transparentBackground?: boolean;
};

function GlobalSearch({ transparentBackground = false }: GlobalSearchProps) {
  const { searchResults, allSourcesDoneLoading } = useGlobalSearch();
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState<SearchStatus>(SearchStatus.WAITING);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const currentlyHighlightedOption = useRef<SearchResultType>();
  const queryClient = useQueryClient();

  const setGlobalSearchFilter = useSetRecoilState(searchTermAtom);

  // analytics setup
  const analytics = useRecoilValue(analyticsAtom);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const trackGlobalSearchQuery = useCallback(
    _.debounce((query: string) => {
      if (analytics) {
        analytics.track('Global Search Query', { query });
      }
    }, 1000),
    [analytics]
  );
  const trackResultClick = useCallback(
    (option: SearchResultType) => {
      const { primaryDisplayName, resultType, metadata } = option;
      analytics?.track('Global Search Result Click', { resultType, primaryDisplayName, metadata });
    },
    [analytics]
  );

  // hotkeys for focusing search field
  useHotkeys('command+k', (e) => {
    e.preventDefault();
    document.getElementById('global-search-input')?.focus();
  });
  useHotkeys('control+k', (e) => {
    e.preventDefault();
    document.getElementById('global-search-input')?.focus();
  });

  // handlers
  function handleSearchChange(_e, newValue) {
    const userInput = newValue;
    const debouncedFilter = _.debounce(setGlobalSearchFilter, 300);
    setSearchTerm(userInput);

    if (userInput?.length >= 3) {
      setStatus(SearchStatus.LOADING);
      debouncedFilter(userInput);
      trackGlobalSearchQuery(searchTerm);
    } else if (userInput?.length === 0) {
      setStatus(SearchStatus.WAITING);
      debouncedFilter(userInput);
    }
  }
  function handleMenuClose() {
    setSearchTerm('');
    setIsMenuOpen(false);
    document.getElementById('global-search-input')?.blur();
  }
  function handleSearchResultClick(option: SearchResultType) {
    handleMenuClose();
    trackResultClick(option);
  }
  function handleOnSearchFocus() {
    setIsMenuOpen(true);

    if (InventoryStore.inventoryOnly.length === 0) {
      void InventoryStore.refreshInventory(queryClient);
    }
    if (InventoryStore.productsOnly.length === 0) {
      void InventoryStore.refreshProducts();
    }
  }
  function handleHighlightChange(option) {
    currentlyHighlightedOption.current = option;
  }

  // updates status state depending on input and whether all sources are finished loading
  useEffect(() => {
    if (searchTerm.length < 3) {
      setStatus(SearchStatus.WAITING);
    } else if (searchTerm.length >= 3 && (searchResults?.length ?? 0) > 0) {
      setStatus(SearchStatus.RESULTS_READY);
    } else if (searchTerm.length >= 3 && allSourcesDoneLoading && searchResults?.length === 0) {
      setStatus(SearchStatus.NO_RESULTS);
    }
  }, [searchResults, allSourcesDoneLoading, searchTerm]);

  return (
    <Wrapper>
      <Autocomplete
        clearOnBlur
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-46, 20],
                },
              },
            ],
          },
        }}
        disablePortal
        filterOptions={(x) => x}
        freeSolo
        getOptionLabel={(option: SearchResultType) => option.primaryDisplayName}
        inputValue={searchTerm}
        open={isMenuOpen}
        options={searchResults || []}
        PaperComponent={(props) => <PaperComponent status={status} {...props} />}
        PopperComponent={DumbPopper}
        renderInput={(params) => (
          <SearchInput
            inputProps={{ ...params.inputProps, id: 'global-search-input' }}
            inputRef={params.InputProps.ref}
            isActive={isMenuOpen}
            placeholder='Search...'
            size='small'
            transparentBackground={transparentBackground}
            onFocus={handleOnSearchFocus}
          />
        )}
        renderOption={(props, option: SearchResultType) => (
          <StyledMenuItem
            data-option-index={props['data-option-index']}
            id={props.id}
            key={`${option.primaryDisplayName}-${props.id}`}
            role={props.role}
            tabIndex={props.tabIndex}
          >
            <SearchResult searchResult={option} onClick={() => handleSearchResultClick(option)} />
          </StyledMenuItem>
        )}
        onClose={handleMenuClose}
        onHighlightChange={(_e, option) => {
          handleHighlightChange(option);
        }}
        onInputChange={handleSearchChange}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement> & { defaultMuiPrevented?: boolean }) => {
          if (event.key === 'Enter') {
            // disable MUI's default Enter key handling: https://mui.com/material-ui/react-autocomplete/#events
            event.defaultMuiPrevented = true;
            // select highlighted option, or first option if none is highlighted
            const navTo = currentlyHighlightedOption?.current?.to ?? searchResults?.[0]?.to;
            if (navTo) {
              navigate(navTo);
              handleMenuClose();
            }
          }
        }}
      />
    </Wrapper>
  );
}

export default GlobalSearch;

function DumbPopper(props) {
  return <Popper {...props} data-testid='global-search-popper' placement='bottom-start' />;
}

function PaperComponent(props) {
  const { status, children } = props;
  return status === 'resultsReady' ? (
    <InfoContainer {..._.omit(props, ['disablePortal', 'anchorEl', 'open', 'style'])}>
      <TopBar>Search Results</TopBar>
      {children}
    </InfoContainer>
  ) : (
    <InfoContainer {..._.omit(props, ['disablePortal', 'anchorEl', 'open', 'style'])}>
      <SearchInfo status={status} />
    </InfoContainer>
  );
}

const Wrapper = styled.div`
  --container-width: 737px;
  margin: 0 var(--sizes-80);
  width: 100%;
  max-width: 340px;

  .MuiAutocomplete-paper {
    background-color: white;
    min-width: var(--container-width) !important;
  }
  .MuiAutocomplete-popper {
    min-width: var(--container-width);
  }
  .MuiAutocomplete-listbox {
    padding: 0;
  }
  .MuiFormControl-root {
    padding: 8px 16px;
  }
  .MuiInputBase-root {
    padding: 0 !important;
  }
  .MuiInputBase-input {
    font-size: 14px !important;
    padding: 0 !important;
    height: 16px !important;
  }

  ${down('largeTablet')} {
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  background-color: var(--color-brand-primary-white);
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const TopBar = styled.div`
  background-color: white;
  padding: var(--sizes-40) var(--sizes-60);
  text-transform: uppercase;
  color: var(--color-gray-50);
  font-size: var(--typography-extra-small-12pt-normal-font-size);
  font-weight: var(--typography-headings-heading-1-font-weight);
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0;
  margin: 0;
`;
