import React from 'react';

import { useDarkMode } from 'src/app/state/dark-mode';

/* eslint-disable max-len */
export function BackButtonIcon() {
  const darkMode = useDarkMode();
  const fill = darkMode ? '#fff' : '#171A1C';
  return (
    <svg fill='none' height='14' viewBox='0 0 8 14' width='8' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M6.82142 1.08918C7.07385 1.37084 7.07331 1.8269 6.82023 2.10782L2.41823 6.99407L6.80834 11.8936C7.06074 12.1752 7.06016 12.6313 6.80706 12.9122C6.55395 13.1931 6.14416 13.1925 5.89176 12.9108L1.27339 7.75654C1.18342 7.65618 1.11174 7.5367 1.06311 7.40567C1.01441 7.27447 0.98944 7.13387 0.989612 6.99192C0.989784 6.84998 1.0151 6.70946 1.06411 6.5784C1.11306 6.44751 1.18469 6.32864 1.2749 6.22855L5.90613 1.08785C6.15921 0.806932 6.569 0.807527 6.82142 1.08918Z'
        fill={fill}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M6.90257 0.999109C7.19969 1.33064 7.19906 1.86747 6.90116 2.19814L2.58031 6.99432L6.88949 11.8035C7.18659 12.1351 7.18591 12.6719 6.88799 13.0025C6.59006 13.3332 6.1077 13.3324 5.81061 13.0008L1.19224 7.84661C1.09164 7.7344 1.01155 7.60087 0.957177 7.45436C0.902731 7.30766 0.874808 7.15046 0.875001 6.99175C0.875194 6.83304 0.903499 6.67592 0.958301 6.52939C1.01303 6.38304 1.09312 6.25013 1.19398 6.13822L5.82519 0.997544C6.12309 0.666875 6.60545 0.667575 6.90257 0.999109ZM6.73929 2.01752C6.94756 1.78634 6.948 1.41104 6.74028 1.17926C6.53255 0.947478 6.19533 0.946988 5.98706 1.17816L1.35618 6.31848C1.27663 6.40674 1.21309 6.51199 1.16992 6.62741C1.1267 6.74299 1.10437 6.86691 1.10422 6.9921C1.10407 7.11728 1.12609 7.24127 1.16904 7.35698C1.21193 7.47253 1.27484 7.57756 1.35418 7.66606L5.97292 12.8207C6.18062 13.0525 6.51785 13.053 6.72613 12.8219C6.93441 12.5907 6.93489 12.2154 6.72718 11.9836L2.33708 7.08414C2.29239 7.03426 2.29248 6.95351 2.3373 6.90376L6.73929 2.01752ZM2.27058 6.82922C2.22578 6.87897 2.15323 6.8789 2.10852 6.82906C2.06383 6.77919 2.06352 6.69801 2.10832 6.64826C2.15311 6.59851 2.22566 6.59858 2.27038 6.64842C2.31506 6.69829 2.31537 6.77947 2.27058 6.82922ZM2.27018 7.15848C2.31485 7.20836 2.31474 7.2891 2.26993 7.33884C2.2251 7.38854 2.15215 7.38884 2.10748 7.33895C2.0628 7.28907 2.06291 7.20833 2.10772 7.1586C2.15256 7.10889 2.2255 7.1086 2.27018 7.15848Z'
        fill={fill}
        fillRule='evenodd'
      />
      {/* eslint-disable-next-line max-len */}
      <path
        clipRule='evenodd'
        d='M7.13529 0.790547C7.53895 1.24096 7.53818 1.95793 7.13334 2.4073L3.00041 6.99488L7.12223 11.5949C7.52585 12.0454 7.52502 12.7624 7.12014 13.2117C6.69779 13.6804 5.99886 13.6792 5.57787 13.2094L0.959553 8.05521C0.831918 7.91282 0.731754 7.7451 0.664204 7.5631C0.59661 7.38097 0.562264 7.18678 0.562501 6.99137C0.562739 6.79596 0.597557 6.60186 0.665601 6.41992C0.733563 6.2382 0.833745 6.07113 0.961861 5.929L5.59302 0.788378C6.01532 0.319618 6.71425 0.320743 7.13529 0.790547ZM2.02016 6.83419C1.99953 6.80249 1.99159 6.76869 1.99153 6.73947C1.99146 6.70305 2.00366 6.65864 2.03835 6.62011C2.04409 6.61374 2.05032 6.6077 2.057 6.60205C2.09168 6.57271 2.13834 6.55393 2.18983 6.55398C2.20309 6.554 2.21603 6.55526 2.22852 6.55763M2.22751 7.43014L2.12874 7.31991L2.10434 7.29268C2.06686 7.25085 2.03868 7.20336 2.01981 7.15301C1.99918 7.18478 1.99115 7.21878 1.99111 7.24829C1.99106 7.2852 2.00348 7.32911 2.03739 7.36696C2.04312 7.37337 2.04934 7.37943 2.05597 7.38508M2.0155 7.141C2.0187 7.14246 2.02191 7.14388 2.02513 7.14526C2.02897 7.13997 2.03317 7.13476 2.03777 7.12966M2.0155 7.141C2.00618 7.11379 1.99953 7.08582 1.99556 7.05754C1.98965 7.01543 1.98965 6.97248 1.99559 6.93037C1.99961 6.90194 2.00635 6.87371 2.01577 6.84637C2.01902 6.84489 2.02229 6.84345 2.02558 6.84206M2.16174 7.24125L2.18061 7.22434L2.16174 7.24125ZM2.20899 6.73891L2.1896 6.75631L2.20899 6.73891ZM2.1699 6.73857L2.18929 6.72117L2.1699 6.73857Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
}
/* eslint-enable max-len */
