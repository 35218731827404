import React from 'react';

type SortIconProps = {
  className?: string;
  dataTestId?: string;
  fill?: string;
  size?: string;
};

export function SortIcon({
  size = 'var(--sizes-40)',
  fill = 'black',
  className,
  dataTestId,
}: SortIconProps): JSX.Element {
  return (
    <svg
      className={className}
      data-testid={dataTestId}
      fill='none'
      height='24'
      style={{ width: size, height: size }}
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M2.10723 5.26555C2.02412 5.2812 1.97681 5.2982 1.88826 5.3442C1.78872 5.39591 1.64182 5.54486 1.58708 5.64958C1.43912 5.93266 1.48086 6.27374 1.69171 6.50444C1.7955 6.618 1.91883 6.69306 2.0663 6.73242L2.14563 6.75358H12H21.8544L21.9338 6.73242C22.0812 6.69306 22.2045 6.618 22.3083 6.50444C22.5189 6.27409 22.5607 5.93223 22.413 5.64958C22.3821 5.59061 22.3505 5.5504 22.2768 5.47634C22.171 5.37004 22.0896 5.31992 21.955 5.27821L21.8832 5.25598L12.0288 5.25444C3.90686 5.25315 2.16261 5.25511 2.10723 5.26555ZM2.1296 11.261C2.04972 11.2745 1.97445 11.3001 1.89603 11.3404C1.78872 11.3956 1.6443 11.5401 1.58708 11.6496C1.43912 11.9327 1.48086 12.2737 1.69171 12.5044C1.7955 12.618 1.91883 12.6931 2.0663 12.7324L2.14563 12.7536H12H21.8544L21.9338 12.7324C22.0812 12.6931 22.2045 12.618 22.3083 12.5044C22.5189 12.2741 22.5607 11.9322 22.413 11.6496C22.3821 11.5906 22.3505 11.5504 22.2768 11.4763C22.171 11.37 22.0896 11.3199 21.955 11.2782L21.8832 11.256L12.0288 11.2547C6.60891 11.254 2.15426 11.2568 2.1296 11.261ZM2.1296 17.261C2.04972 17.2745 1.97445 17.3001 1.89603 17.3404C1.78872 17.3956 1.6443 17.5401 1.58708 17.6496C1.43912 17.9327 1.48086 18.2737 1.69171 18.5044C1.7955 18.618 1.91883 18.6931 2.0663 18.7324L2.14563 18.7536H12H21.8544L21.9338 18.7324C22.0812 18.6931 22.2045 18.618 22.3083 18.5044C22.5189 18.2741 22.5607 17.9322 22.413 17.6496C22.3821 17.5906 22.3505 17.5504 22.2768 17.4763C22.171 17.37 22.0896 17.3199 21.955 17.2782L21.8832 17.256L12.0288 17.2547C6.60891 17.254 2.15426 17.2568 2.1296 17.261Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
}
