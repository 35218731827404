import React from 'react';

import { CaretDown } from 'src/app/components/icons/caret-down';
import { Button } from 'src/app/components/lib/button';
import { DropdownMenuSingle } from 'src/app/components/lib/dropdown';

import type { ButtonProps as MUIButtonProps, MenuProps as MUIMenuProps } from '@mui/material';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { MenuItemVariantTypes } from 'src/app/components/lib/menu-item';

export type Option<TOptionValue extends string = string> = {
  key?: string;
  label: string;
  onClick?: () => void;
  value?: TOptionValue;
  variant?: MenuItemVariantTypes;
};

export type FilterSelectProps = {
  activeFilter?: string[];
  automationId?: string;
  ButtonProps?: MUIButtonProps;
  disabled?: boolean;
  disableDefaultFooterContent?: boolean;
  handleOnChange: (option: DropdownMenuOption) => void;
  label: string;
  MenuProps?: MUIMenuProps;
  options: Option[];
  resetButtonAutomationId?: string;
  resetLabel?: string;
};

export function FilterSelect(props: FilterSelectProps) {
  const {
    automationId,
    ButtonProps,
    label,
    options,
    activeFilter = [],
    disabled,
    handleOnChange: onChange,
    resetButtonAutomationId,
    resetLabel,
    disableDefaultFooterContent,
  } = props;

  const formattedOptions: DropdownMenuOption[] = options.map((option) => ({ ...option, id: option.value }));

  const activeValue = formattedOptions.find((option) => option.label === activeFilter[0]);
  const filterSelectLabel = activeValue?.label ?? label;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOnChange = (option) => {
    // cases where we do NOT want onChange to fire:
    // - if you click on the same menu item as is already selected: activeValue?.label === option.label
    // - if something is selected, and you click out of the menu without making a new selection: activeValue?.label !== undefined && activeValue?.label === option.label
    // - if you open a fresh menu and close it again without making a selection: activeFilter.length === 0 && option.label === ''

    const selectionExistsAndIsUnchanged = activeValue?.label !== undefined && activeValue?.label === option.label;
    const freshMenuAndNoNewSelection = activeFilter.length === 0 && option.label === '';

    if (!selectionExistsAndIsUnchanged && !freshMenuAndNoNewSelection) {
      onChange(option);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    if (option) {
      handleOnChange(option);
    }
    handleClose();
  };

  return (
    <>
      <Button
        {...ButtonProps}
        aria-controls='filter-menu'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        automationId={automationId}
        className='filter-select-button'
        disabled={disabled}
        endIcon={<CaretDown />}
        id={`filter-menu-button--${label}`}
        label={filterSelectLabel}
        variant='white'
        onClick={handleClick}
      />
      <DropdownMenuSingle
        anchorEl={anchorEl}
        disableDefaultFooterContent={disableDefaultFooterContent}
        handleClose={handleMenuItemClick}
        id={`filter-menu--${label}`}
        open={open}
        options={formattedOptions}
        placement='bottom-end'
        resetButtonAutomationId={resetButtonAutomationId}
        resetLabel={resetLabel}
        value={activeValue}
      />
    </>
  );
}
