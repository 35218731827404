import React from 'react';

import styled, { css } from 'styled-components';

type Weight = 'bold' | 'normal' | 'semibold';
type PxSize = 10 | 11 | 12 | 13 | 14 | 16;
type SemanticSize = 'extra-small' | 'large' | 'micro' | 'mini' | 'regular' | 'small';

export type TypeProps = {
  children?: React.ReactNode | string;
  className?: string;
  px: PxSize;
  weight?: Weight;
};

export type TypeContainerProps = {
  className?: string;
  px: PxSize;
  size: SemanticSize;
  weight: Weight;
};

const pxToSemanticSize: Record<string, SemanticSize> = {
  '10': 'micro',
  '11': 'mini',
  '12': 'extra-small',
  '13': 'small',
  '14': 'regular',
  '16': 'large',
};

const defaultWeightBySize: Record<string, Weight> = {
  '10': 'bold',
  '11': 'semibold',
  '12': 'normal',
  '13': 'normal',
  '14': 'normal',
  '16': 'normal',
};

export function Type({ children, px, weight, className = '' }: TypeProps) {
  const semanticSize = pxToSemanticSize[px];
  const defaultFontWeight = defaultWeightBySize[px];

  return (
    <TypeContainer className={className} px={px} size={semanticSize} weight={weight ?? defaultFontWeight}>
      {children}
    </TypeContainer>
  );
}

const TypeContainer = styled.span<TypeContainerProps>`
  font: ${({ size, px, weight }) => css`var(--font-${size}-${px}pt-${weight}`});
`;
