import React from 'react';

import styled from 'styled-components';

type LDServerMigrationConfig = {
  backofficeURL: string;
  dayBeforeDeadline: string;
  deadlineDateLabel: string;
  shouldShowBlockingUI: boolean;
  useUpdatedMessaging?: boolean;
};
type ServerMigrationBannerProps = {
  migrationConfig: LDServerMigrationConfig;
};

export function ServerMigrationBanner({ migrationConfig }: ServerMigrationBannerProps) {
  // shouldn't be any reason deadlineDateLabel is empty if we're configuring the LD variants correctly, but just in case
  const deadlineText = migrationConfig.dayBeforeDeadline
    ? `As of ${migrationConfig.dayBeforeDeadline} this URL will be disabled in preparation for your account environment upgrade the following day.`
    : `This URL will soon be disabled.`;

  if (migrationConfig.useUpdatedMessaging) {
    return (
      <Banner>
        <Text>
          <span>
            {migrationConfig.deadlineDateLabel ? `As of ${migrationConfig.deadlineDateLabel}` : 'Soon'} this URL will be
            disabled. Please <Link href={migrationConfig.backofficeURL}>click here</Link> to access and log into your
            new URL.
          </span>
        </Text>
      </Banner>
    );
  }

  return (
    <Banner>
      <Text>
        <span>
          {deadlineText} Please <Link href={migrationConfig.backofficeURL}>click here</Link> to access and log into your
          new URL. Visit the{' '}
          <Link href='https://support.dutchie.com/hc/en-us/articles/28929066112147'>help center</Link> for more
          information.
        </span>
      </Text>
    </Banner>
  );
}

const Banner = styled.div`
  background-color: #3c96fe;
  color: #fff;
  width: 100%;
  height: 48px;
  padding: var(--sizes-40);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--sizes-20);
`;

const Text = styled.div`
  font: var(--font-extra-small-12pt-semibold);
  display: inline-block;
  display: flex;
  justify-content: center;
`;

const Link = styled.a`
  color: var(--color-brand-primary-white) !important;
  text-decoration: underline !important;
`;
