import { atom } from 'recoil';

export type EnvironmentDetails = {
  BackOfficeUrl?: string | null;
  CurbsideUrl?: string | null;
  DutchieBohUrl?: string | null;
  DutchieRegisterUrl?: string | null;
  LaunchDarklyClientKey?: string | null;
  MobileCheckoutUrl?: string | null;
  Pos3Url?: string | null;
  Pos4Url?: string | null;
  Region?: string | null;
};

export const environmentAtom = atom<EnvironmentDetails>({
  key: 'environment',
  default: {
    Pos4Url: '',
    Pos3Url: '',
    BackOfficeUrl: '',
    CurbsideUrl: '',
    MobileCheckoutUrl: '',
    DutchieBohUrl: '',
    DutchieRegisterUrl: '',
    LaunchDarklyClientKey: '',
    Region: '',
  },
});
