import React from 'react';
import type { ReactNode } from 'react';

import { CSSTransition } from 'react-transition-group';

import { DrawerOverlay, DrawerContainer, DRAWER_TRANSITION_DURATION, FullScreenDrawerContainer } from './drawer.styles';

type DrawerProps = {
  ariaLabel?: string;
  children: ReactNode;
  fullScreen?: boolean;
  onClose?: () => void;
  open: boolean;
};

export function Drawer(props: DrawerProps) {
  const { ariaLabel, children, fullScreen = false, open, onClose } = props;

  const Container = fullScreen ? FullScreenDrawerContainer : DrawerContainer;
  return (
    <>
      <CSSTransition classNames='drawer-overlay' in={open} timeout={DRAWER_TRANSITION_DURATION} unmountOnExit>
        <DrawerOverlay onClick={onClose} />
      </CSSTransition>
      <CSSTransition classNames='drawer-container' in={open} timeout={DRAWER_TRANSITION_DURATION} unmountOnExit>
        <Container aria-label={ariaLabel} role='dialog'>
          {children}
        </Container>
      </CSSTransition>
    </>
  );
}
