import styled from 'styled-components';

export const NewItemContainer = styled.div<{ width?: string }>`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.width && props.width.length > 0 ? props.width : '100%')};
`;

export const NewItem = styled.div`
  color: var(--color-grayscale-white);
  font-family: Matter;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 155.556% */
  letter-spacing: 0.045px;
  text-transform: uppercase;
  display: flex;
  padding: 2px 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: linear-gradient(107deg, #ff3e51 7.85%, #4862f0 88.18%);
`;
