import React from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { ModalContainer, ModalHeader } from 'src/app/components/lib/modal';
import { VERSION } from 'src/app/constants/environment';
import { appAtom } from 'src/app/state/app';

type VersionsModalProps = {
  onClose: () => void;
};

export function VersionsModal(props: VersionsModalProps) {
  const { onClose } = props;
  const { buildNumber } = useRecoilValue(appAtom);

  return (
    <ModalContainer open width='480px'>
      <ModalHeader title='Versions' onClose={onClose} />
      <VersionsContainer>
        <dl>
          <StyledTerm>UI Release</StyledTerm>
          <dd>{VERSION}</dd>
        </dl>
        <dl>
          <StyledTerm>API Build Number</StyledTerm>
          <dd>{buildNumber}</dd>
        </dl>
      </VersionsContainer>
    </ModalContainer>
  );
}

const VersionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: var(--sizes-50) 0;
  font: var(--font-regular-14pt-normal);
`;

const StyledTerm = styled.dt`
  text-transform: uppercase;
  font-size: var(--typography-micro-10pt-bold-font-size);
  font-weight: var(--typography-micro-10pt-bold-font-weight);
  color: var(--color-gray-60);
`;
