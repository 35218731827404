import React from 'react';
import moment from 'moment';

class FormatDate extends React.Component<any, any> {
  render() {
    const format = this.props.format || 'MM/DD/YYYY';
    const type = this.props.type || 'div';
    const date = moment(this.props.date).format(format);

    if (type === 'table') {
      return <td className={this.props.className}>{date}</td>;
    }

    return <div className={`price ${this.props.className}`}>{date}</div>;
  }
}

export default FormatDate;
