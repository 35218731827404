import { Menu as MUIMenu } from '@mui/material';
import styled, { css } from 'styled-components';

export const RebrandMenu = styled(MUIMenu)<{ $darkMode: boolean }>`
  .MuiPopover-paper {
    border-radius: var(--sizes-20);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);

    ul {
      padding: var(--sizes-20) 0;
    }
    ${({ $darkMode }) =>
      $darkMode &&
      css`
        box-shadow: 0px 6px 20px rgba(255, 255, 255, 0.2);
      `}
  }
`;
