import React from 'react';

import styled from 'styled-components';

import { Loader } from 'src/app/components/lib/loader';
import { Type } from 'src/app/components/lib/type';

import { StyledGridContainer, StyledGridOverlay } from '../no-results-overlay';

export function CustomLoader() {
  return (
    <StyledGridOverlay>
      <StyledGridContainer>
        <Loader size='3x' variant='grey' />
        <StyledDefaultLoadingCopy px={14}>Loading data...</StyledDefaultLoadingCopy>
      </StyledGridContainer>
    </StyledGridOverlay>
  );
}

const StyledDefaultLoadingCopy = styled(Type)`
  margin-top: var(--sizes-60);
`;
