import { storage } from "../../../utils/index";
import { HardwareService } from "../../HardwareService";

import type { SerialConnectionOptions } from "./types";
import { defaultSerialOptions } from "./types";

export type WebSerialHelperParams = {
  device: SerialPort;
  commandTerminator: string;
  onStatusChange: () => void;
  onBuffer: (buffer: string) => void;
}

export class WebSerialHelper {
  options: SerialConnectionOptions = defaultSerialOptions;
  config: WebSerialHelperParams;

  private keepReading = false;
  private reader: ReadableStreamDefaultReader | null = null;
  private writer: WritableStreamDefaultWriter<Uint8Array> | null = null;

  private get port(): SerialPort { return this.config.device }
  private get storageKey(): string { return `hardware.${this.id}` }
  private get portInfo() { return this.port.getInfo() }

  constructor(config: WebSerialHelperParams) {
    this.config = config;

    const record = storage.getItem(this.storageKey);
    if (record != null) {
      try {
        this.options = JSON.parse(record) as SerialConnectionOptions;
      }
      catch (e) {
        HardwareService.logger.error(e);
      }
    }
  }

  get connected(): boolean { return this.port.readable != null }
  get id(): string {
    return `serial-${this.portInfo.usbVendorId}-${this.portInfo.usbProductId}`
  }
  get productId(): number | undefined { return this.portInfo.usbProductId }
  get vendorId(): number | undefined { return this.portInfo.usbVendorId }

  async connect(): Promise<boolean> {
    try {
      this.keepReading = true;
      if (this.connected) return true;

      await this.port.open(this.options);
      storage.setItem(this.storageKey, JSON.stringify(this.options))

      this.listen();
      this.config.onStatusChange();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async disconnect(): Promise<boolean> {
    try {
      this.keepReading = false;
      if (!this.connected) return true;

      try {
        if (this.reader) {
          this.reader.releaseLock();
          this.reader.cancel();
        }

      }
      catch (e) {
        HardwareService.logger.e('Release reader error', e);
      }
      try {
        this.writer?.releaseLock();
        const isClosed = await (this.writer?.closed ?? Promise.resolve(true));
        if (isClosed !== true) {
          this.writer?.close();
        }
      }
      catch (e) {
        HardwareService.logger.e('Release writer error', e);
      }
      
      try {
        await this.port.close();
      }
      catch (e) {
        HardwareService.logger.e('failed to close port', e);
      }

      this.config.onStatusChange();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async revokePermission(): Promise<boolean> {
    localStorage.removeItem(this.storageKey);
    await this.port.forget();
    return true;
  }

  async write(bytes: Uint8Array | string): Promise<void> {
    if (!this.connected) return;

    if (typeof bytes === 'string') {
      bytes = new TextEncoder().encode(bytes);
    }

    if (this.writer == null || this.port?.writable?.locked != true) {
      this.writer = this.port.writable?.getWriter() ?? null;
      if (this.writer === null) throw Error('Unable to get writer')
    }

    await this.writer?.write(bytes);
    this.writer?.releaseLock();
  }

  private async listen() {
    let buffer = ''

    while (this.port.readable && this.keepReading) {
      try {
        // when releasing on disconnect, it seems it must be set to this instance
        // instead of calling getReader again
        this.reader = this.port.readable.getReader();

        while (true) {
          const { value, done } = await this.reader.read();
          if (done) {
            try {
              this.reader.releaseLock();
              this.writer?.releaseLock();
              break;
            }
            catch (e) {
              HardwareService.logger.e('error releasing reader', e);
            }
          }

          if (value) {
            try {
              // Append to buffer
              const stringValue = new TextDecoder().decode(value)
              buffer += stringValue

              this.config.onBuffer(buffer);
            }
            catch (e) {
              HardwareService.logger.e('buffer error', e);
            }

            if (buffer.endsWith(this.config.commandTerminator)) {
              buffer = ''
            }
            else {
              const lastCommand = buffer.split(this.config.commandTerminator).pop()
              if (lastCommand) {
                buffer = lastCommand
              }
            }
          }
        }
      } catch (e) {
        HardwareService.logger.e('error reading port', e);
      }
    }
  }
}
