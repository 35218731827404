import i18next from 'i18next';
import $ from 'jquery';
import React from 'react';
import { Button } from 'src/app/components/lib/button';
import WidgetPopupHeader from '../popup/WidgetPopupHeader.react';

class ConfirmPopup extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      notes: null,
    };

    this.show = this.show.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  show() {
    this.setState({
      visible: true,
    });
  }

  confirm() {
    const self = this;

    this.setState(
      {
        visible: false,
      },
      () => {
        self.props.onConfirm();
      }
    );
  }

  render() {
    const self = this;

    const type = this.props.type || 'hidden',
      buttonClass = this.props.buttonClass || 'primary',
      disabled = this.props.disabled || false;

    return (
      <>
        {type === 'button' && (
          <Button
            disabled={disabled}
            label={this.props.buttonLabel || 'Save'}
            sx={{ cursor: 'pointer' }}
            variant={buttonClass}
            onClick={() => {
              this.setState({ visible: true });
            }}
          />
        )}

        {(this.state.visible || this.props.visible) && (
          <div className='smokescreen smokescreen__transparent'>
            <div
              className='widget-popup'
              data-testid={this.props.automationId}
              style={{ width: this.props.width || 300 }}
            >
              <WidgetPopupHeader
                style={{ marginBottom: 20 }}
                title={this.props.title || 'Are you sure?'}
                onClose={() => {
                  if (this.props.onClose) {
                    this.props.onClose();
                  }
                  this.setState({ visible: false }, () => {
                    $('.dim').css({ opacity: 1 });
                  });
                }}
              />

              {this.props.helpText && (
                <>
                  <p>{this.props.helpText}</p>
                  <br />
                </>
              )}
              <button className='btn btn-primary' onClick={this.confirm}>
                {this.props.confirmLabel || i18next.t('Confirm')}
              </button>

              <button
                className='btn btn-secondary pull-right'
                onClick={() => {
                  this.setState({ visible: false }, () => {
                    if (self.props.onCancel) {
                      self.props.onCancel();
                    }
                  });
                }}
              >
                {this.props.cancelLabel || i18next.t('Cancel')}
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ConfirmPopup;
