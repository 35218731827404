import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';

import { actions as PosActions, events as PosEvents } from '../constants/PosConstants';
import { actions as UserActions } from '../constants/UserConstants';

const _state = {
  dailySummary: [],
  discountGroupList: false,
  registerList: false,
  listOfDoctors: false,
  listOfVolumeDiscounts: false,
  listOfDiscounts: false,
  pricingTier: false,
  returnReasons: [],
  loyaltyAdjustmentReasons: [],
  transactionAdjustmentReasons: [],
  calculationMethods: [],
  // listOfPricing     : false,
};

class PosStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  emitChangeEvent(event, callback) {
    this.emit(event, callback);
  }

  emitChange(callback) {
    this.emit(PosEvents.CHANGE_EVENT, callback);
  }

  addChangeEventListener(callback, event) {
    this.on(event, callback);
  }

  addChangeListener(callback) {
    this.on(PosEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(PosEvents.CHANGE_EVENT, callback);
  }
}

const PosStore = new PosStoreClass();

PosStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case UserActions.SELECT_COMPANY:
      _state.dailySummary = [];
      _state.discountGroupList = false;
      _state.registerList = false;
      _state.listOfDoctors = false;
      _state.listOfVolumeDiscounts = false;
      _state.listOfDiscounts = false;
      _state.pricingTier = false;
      _state.returnReasons = [];
      _state.calculationMethods = [];
      PosStore.emitChange();
      break;

    case PosActions.DAILY_SUMMARY_LOADED:
      _state.dailySummary[action.locId] = action.data;
      PosStore.emitChange();
      break;

    case PosActions.DISCOUNT_GROUP_LOADED:
      _state.discountGroupList = action.data;
      PosStore.emitChange();
      break;

    case PosActions.REGISTER_LIST_LOADED:
      _state.registerList = action.data;
      PosStore.emitChange();
      PosStore.emitChangeEvent(PosEvents.REGISTERS_LOADED);
      break;

    case PosActions.DOCTOR_LIST_LOADED:
      _state.listOfDoctors = action.data;
      PosStore.emitChange();
      break;

    case PosActions.DISCOUNTS_LIST_LOADED:
      _state.listOfDiscounts = action.data;
      PosStore.emitChange();
      break;

    case PosActions.PRICING_TIER_LOADED:
      _state.pricingTier = action.data;
      PosStore.emitChange();
      break;

    case PosActions.RETURN_REASONS_LOADED:
      _state.returnReasons = action.data;
      PosStore.emitChange();
      break;

    case PosActions.LOYALTY_ADJUSTMENT_REASONS_LOADED:
      _state.loyaltyAdjustmentReasons = action.data;
      PosStore.emitChange();
      break;

    case PosActions.CALCULATION_METHODS_LOADED:
      _state.calculationMethods = action.data;
      PosStore.emitChange();
      break;

    case PosActions.TXN_ADJUSTMENT_REASONS_LOADED:
      _state.transactionAdjustmentReasons = action.data;
      PosStore.emitChange();
      break;

    // case PosActions.PRICING_LIST_LOADED:
    //     _state.listOfPricing = action.data;
    //     PosStore.emitChange();
    //     break;
  }
});

export default PosStore;
