import { endPoints as AppEndPoints } from '../constants/AppConstants';
import { endPoints as EndPoints } from '../constants/UserConstants';
import { getLDClient } from 'src/app/utils/ld-client-do-not-use-me';
import AjaxPromises from '../utils/AjaxPromises';

export default {
  getCompanies: (formData) => AjaxPromises.POST(EndPoints.GET_COMPANIES, formData),

  getLocationSettings: (formData) => AjaxPromises.POST(AppEndPoints.GET_LOCATION_SETTINGS, formData),

  getValidatedForms: (formData) => AjaxPromises.POST(AppEndPoints.GET_VALIDATED_FORMS, formData),

  setDefaultLoc: (sessionId, userId, locId) => {
    var ld = getLDClient();
    const useNewEndpoint = Boolean(ld?.variation('pos.platform.track-loc-id-on-session.rollout', false) ?? false);
    if (useNewEndpoint) {
      return AjaxPromises.POST(EndPoints.SET_DEFAULT_LOC_V2, { UserId: userId, LocId: locId, SessionId: sessionId })  
    }
    return AjaxPromises.POST(EndPoints.SET_DEFAULT_LOC, { UserId: userId, LocId: locId, SessionId: sessionId })
  },

  setDefaultLsp: (sessionId, userId, lspId) =>
    AjaxPromises.POST(EndPoints.SET_DEFAULT_COMPANY, { UserId: userId, LspId: lspId, SessionId: sessionId }),

  getUserSettings: (userId) => AjaxPromises.POST(EndPoints.GET_USER_SETTINGS, { UserId: userId }),

  setSingleSetting: (formData) => AjaxPromises.POST(EndPoints.SET_SINGLE_USER_SETTING, formData), // { UserId: userid, Setting: setting, SettingType: settingType, Value: value}
  saveUserSettings: (setting, settingType, value, userid) =>
    AjaxPromises.POST(EndPoints.SET_USER_SETTINGS, {
      UserId: userid,
      Settings: [{ Setting: setting, SettingType: settingType, Value: value }],
    }),

  loadCustomerTypes: (formData) => AjaxPromises.POST(EndPoints.GET_CUSTOMER_TYPES, formData),

  validateBioTrackCredentials: (formData) => AjaxPromises.POST(EndPoints.VALIDATE_BIOTRACK_USER_CREDENTIALS, formData),

  resetBioTrackUserCredentialsRequest: (username, password) =>
    AjaxPromises.POST(EndPoints.RESET_BIOTRACK_USER_CREDENTIALS_REQUEST, { Username: username, Password: password }),

  updateBioTrackPassword: (username, password) =>
    AjaxPromises.POST(EndPoints.UPDATE_BIOTRACK_USER_CREDENTIALS, { Username: username, Password: password }),
};
