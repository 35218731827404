import AjaxPromises from '../utils/AjaxPromises';
import UserStore from './UserStore';
import { SimpleCachedDataStore } from './SimpleCachedDataStore';
import { endPoints as InventoryEndPoints } from '../constants/InventoryConstants';

class SavedOrderStore extends SimpleCachedDataStore {
  constructor() {
    super();
    this.getSavedOrderDetail.bind(this);
  }

  sourceUrl = InventoryEndPoints.GET_SAVED_ORDERS;
  entity = 'Saved Orders';

  get orders() {
    return this.data;
  }

  async getSavedOrderDetail(receiveInventoryHistoryId) {
    const req = UserStore.getApiData();
    req.ReceiveInventoryHistoryId = receiveInventoryHistoryId;

    try {
      const resp = await AjaxPromises.POST(InventoryEndPoints.GET_SAVED_ORDER_DETAIL, req);
      if (resp.Result) {
        return resp.Data;
      }
    } catch {
      console.error(`Error loading receive transaction ${receiveInventoryHistoryId}`);
    }
  }
}

export default new SavedOrderStore();
