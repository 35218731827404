import type { Peripheral } from "./Peripheral";

export type PeripheralServiceConfig = {
  autoConnect: boolean; // default true
  autoEnable: boolean; // default true
  name: string; // default based on vendor id and peripheral type
}

export type PeripheralSearchConfig = {
  requestNewDevices: boolean;
}

export type PeripheralFilter<T extends Peripheral> = (device: T) => boolean;

export enum VendorId {
  star = 1,
  zebra = 2,
  epson = 3,
  and = 4
}

export enum PeripheralType {
  scanner = 1,
  labelPrinter = 2,
  receiptPrinter = 3,
  scale = 4,
}

export enum ConnectionType {
  hid = 1,
  lan = 2,
  serial = 3,
  usb = 4,
  bluetooth = 5,
  keyboard = 6,
  other = 7
}

export const peripheralTypeName = (type: PeripheralType): string => {
  switch (type) {
    case PeripheralType.labelPrinter: return 'Label Printer';
    case PeripheralType.receiptPrinter: return 'Receipt Printer';
    case PeripheralType.scale: return 'Scale';
    case PeripheralType.scanner: return 'Scanner';
  }
}

export const WebApi = {
  bluetooth: (navigator as any).bluetooth as Bluetooth,
  hid: (navigator as any).hid as HID,
  serial: (navigator as any).serial as Serial,
  usb: (navigator as any).usb as USB,
}

export type DeviceStatusEvent = {
  connected: boolean;
  device: Peripheral;
}
