import darkScrollbar from '@mui/material/darkScrollbar';
import { createGlobalStyle, css } from 'styled-components';

export const Universals = createGlobalStyle<{ $darkMode?: boolean }>`
  * {
    font-variant-ligatures: none !important;
  }

  html, body {
    height: 100%;
  }

  html {
    /* Text rendering settings */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    /*
      Set up global font properties so they can be overwritten by either individual or shorthand properties lower
      in the cascade
    */
    font-size: var(--font-body-default-size);
    font-family: var(--font-family-text);
  }

  body {
    background-color: var(--color-brand-primary-white);
    color: var(--color-grayscale-black);
    font: var(--font-body-default);
    overflow-x: hidden;
    overflow-y: auto;

    .MuiPopover-paper {
      border-radius: var(--button-border-radius-large);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    }

    ${({ $darkMode }) =>
      $darkMode &&
      css`
        background-color: var(--color-grayscale-black);
        color: var(--color-brand-primary-white);
        ${darkScrollbar()}
        .MuiPopover-paper {
          box-shadow: 0 6px 20px rgba(255, 255, 255, 0.2);
          background-color: var(--color-grayscale-black);
        }
      `}
  }
`;
