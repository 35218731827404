import { Popper } from '@mui/material';
import styled from 'styled-components';

import { AutocompleteContainer } from 'src/app/components/lib/dropdown/menu/dropdown-menu.styles';
import { Search } from 'src/app/components/lib/search';

export const StyledAutocomplete = styled(AutocompleteContainer)`
  grid-column: 2 / span 3;
  .MuiFormControl-root {
    justify-content: center;
  }

  .MuiTextField-root {
    padding: var(--sizes-0);
  }
` as typeof AutocompleteContainer;

export const StyledSearch = styled(Search)`
  height: 44px;
  background: white;
  outline: 1px solid var(--color-gray-60);

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: unset;
    width: unset;
    border-radius: unset;
    background: unset;
  }

  .MuiOutlinedInput-root {
    padding: 0 0 0 var(--sizes-50);
  }

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: var(--sizes-0);
  }
`;

export const StyledPopperComponent = styled(Popper)`
  margin-top: var(--sizes-20);
  background-color: var(--color-brand-primary-white);
  border-radius: 9px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  color: var(--color-grayscale-black);
  font: var(--font-regular-14pt-normal);
  z-index: 1400;
  overflow: hidden;

  .MuiAutocomplete-paper {
    width: 100%;
    color: unset;
    border-radius: unset;
    box-shadow: unset;
    background-color: unset;
    transition: unset;
  }

  .MuiAutocomplete-popper {
    min-height: 240px;
  }

  .MuiAutocomplete-listbox {
    width: 100%;
  }
`;
