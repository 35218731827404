import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';

import { actions as AppActions, events as AppEvents } from '../constants/AppConstants';

const _state = {
  buildNumber: '',
  apiVersionNumber: '',
};

class AppStoreClass extends EventEmitter {
  constructor() {
    super();
    this.getBuildNumber = this.getBuildNumber.bind(this);
  }

  getState() {
    return _state;
  }

  getBuildNumber() {
    return _state.buildNumber;
  }

  emitChange(event, data) {
    this.emit(event, data);
  }

  addChangeListener(event, callback) {
    this.on(event, callback);
  }

  getForceRefresh() {
    return _state.forceRefresh;
  }

  removeChangeListener(event, callback) {
    this.removeListener(event, callback);
  }
}

const AppStore = new AppStoreClass();
AppStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppActions.BUILD_NUMBER:
      _state.buildNumber = action.data.number;
      _state.apiVersionNumber = action.data.apiVersionNumber;
      _state.forceRefresh = action.data.forceRefresh;
      AppStore.emit(AppEvents.BUILD_NUMBER, action.data.number);
      break;
  }
});

export default AppStore;
