import { atom } from 'recoil';

import { events as SettingsEvents } from 'src/app_deprecated/constants/SettingsConstants';
import SettingsStore from 'src/app_deprecated/stores/SettingsStore';

import type { PaletteMode } from '@mui/material';

export const settingsAtom = atom<{
  LabelPrinterId: number | null;
  MetrcKey: string | null;
  PaletteMode: PaletteMode;
  RowsPerTable: number;
}>({
  key: 'settings',
  default: {
    LabelPrinterId: null,
    RowsPerTable: 100,
    MetrcKey: null,
    PaletteMode: 'light',
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    const { LabelPrinterId, RowsPerTable, MetrcKey, PaletteMode } = SettingsStore.getState();
    setSelf({ LabelPrinterId, RowsPerTable, MetrcKey, PaletteMode });
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  // Subscribe to remote storage changes and update the atom value
  SettingsStore.addListener(SettingsEvents.EDITOR_EVENT, changeListenerForUserState);
  SettingsStore.addListener(SettingsEvents.CHANGE_EVENT, changeListenerForUserState);

  // Cleanup remote storage subscription
  return () => {
    SettingsStore.removeListener(SettingsEvents.EDITOR_EVENT, changeListenerForUserState);
    SettingsStore.removeListener(SettingsEvents.CHANGE_EVENT, changeListenerForUserState);
  };
}
