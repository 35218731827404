import styled, { css } from 'styled-components';

import { ValidityErrorMessage, WarnMessage } from 'src/app/components/styled-components';

import { FormContainer } from './form';

import type { CSSObject } from 'styled-components';

// wraps buttons at end of form to have sticky position when scrolling
export const FormStickyFooterContainer = styled.div<{ $darkMode: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  background: var(--color-brand-primary-white);
  border-top: 1px solid var(--color-gray-20);
  box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.03);
  padding: var(--sizes-50) var(--sizes-100);
  gap: var(--sizes-50);
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background: var(--color-grayscale-black);
      border-top: 1px solid var(--color-gray-100);
      box-shadow: 2px -2px 2px rgba(255, 255, 255, 0.03);
    `}
`;

export const StyledFormValidationMessage = styled(ValidityErrorMessage)`
  margin-top: 0 !important;
  display: flex;
  gap: var(--sizes-20);
  align-items: center;
  padding: var(--sizes-40);

  ul {
    list-style-type: none;
  }

  > svg {
    height: var(--sizes-40);
    min-width: var(--sizes-40);
    width: var(--sizes-40);
  }
`;

export const StyledFormWarnMessage = styled(WarnMessage)`
  margin-top: 0 !important;
  display: flex;
  gap: var(--sizes-20);
  align-items: center;
  padding: var(--sizes-40);

  ul {
    list-style-type: none;
  }

  > svg {
    height: var(--sizes-40);
    min-width: var(--sizes-40);
    width: var(--sizes-40);
  }
`;

export const FormStickyFooterButtonContainer = styled.div`
  display: flex;
  gap: var(--sizes-50);
`;

// This should wrap the entire form (Dividers, Button, FormSections, FormTitles, etc)
export const RebrandFormContainer = styled.div<{ $darkMode: boolean }>`
  width: 51rem;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: var(---sizes-50);
  color: var(--color-gray-90);
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: var(--color-gray-50);
    `}
  .MuiInputBase-input {
    font: var(--font-small-13pt-normal);
    line-height: var(--sizes-40);
  }
`;

export const FormContainerWithStickyFooter = styled(FormContainer)<{ $marginBottom?: string }>`
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 'var(--sizes-110)'};
`;

export const FullWidthContainerWithStickyFooter = styled(FormContainerWithStickyFooter)`
  width: 100%;
`;

// This should wrap only form fields (Input, Select, Toggle, etc)
export const RebrandFormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--color-gray-90);
  gap: var(--sizes-50);

  .MuiInputBase-input {
    font: var(--font-small-13pt-normal);
    line-height: var(--sizes-40);
  }
`;

export const RebrandFormTitle = styled.h2<{ $darkMode: boolean; $overrides?: CSSObject; noMargin?: boolean }>(
  ({ $darkMode, $overrides, noMargin }) => ({
    font: 'var(--font-large-16pt-semibold)',
    color: $darkMode ? 'var(--color-brand-primary-white)' : 'var(--color-grayscale-black)',
    width: '100%',
    margin: noMargin ? '0' : '0 0 var(--sizes-40) 0',
    textAlign: 'start',
    ...$overrides,
  })
);

export const StyledFormFieldContainer = styled.div`
  gap: var(--sizes-20);
  display: flex;
  flex-direction: column;
`;

export const InlineFormFieldContainer = styled(StyledFormFieldContainer)`
  flex-direction: row;
`;

export const StyledFormFieldSubContent = styled.div`
  color: var(--color-gray-70);
  font: var(--font-extra-small-12pt-normal);
  grid-column: 2 / span 3;
`;
