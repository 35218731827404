import { css } from 'styled-components';

const primaryBrandColors = css`
  --color-brand-primary-opal: var(--color-opal-70);
  --color-brand-primary-flora: #ff3e51;
  --color-brand-primary-white: var(--color-grayscale-white);
  --color-brand-primary-dutchie-pay: var(--color-green-60);
`;

const secondaryBrandColors = css`
  --color-brand-secondary-lavender: #e0d5f8;
  --color-brand-secondary-moss: #297c2c;
  --color-brand-secondary-terra: #f8a867;
  --color-brand-secondary-cedar: #ffff76;
  --color-brand-secondary-sapphire: var(--color-blue-60);
`;

const grayscaleShades = css`
  --color-grayscale-black: #121516;
  --color-gray-100: #2c3236;
  --color-gray-90: #485055;
  --color-gray-80: #646d72;
  --color-gray-70: #828a8f;
  --color-gray-60: #a0a7ab;
  --color-gray-50: #b0b7bb;
  --color-gray-40: #c1c7ca;
  --color-gray-30: #d2d7da;
  --color-gray-20: #e3e7e9;
  --color-gray-10: #f5f6f7;
  --color-grayscale-white: #ffffff; // also a primary brand color - white
`;

const blueShades = css`
  --color-blue-100: #051027;
  --color-blue-90: #082352;
  --color-blue-80: #083a7e;
  --color-blue-70: #0555ae;
  --color-blue-60: #0075e0; // also a secondary brand color - sapphire
  --color-blue-50: #3387e1;
  --color-blue-40: #659de5;
  --color-blue-30: #94b7ea;
  --color-blue-20: #c1d3f1;
  --color-blue-10: #edf1fa;
`;

const opalShades = css`
  --color-opal-100: #06101b;
  --color-opal-90: #0b2034;
  --color-opal-80: #10304d;
  --color-opal-70: #153f66; // also a primary brand color - opal
  --color-opal-60: #395c7e;
  --color-opal-50: #5b7791;
  --color-opal-40: #8197ac;
  --color-opal-30: #a5b5c4;
  --color-opal-20: #c8d2db;
  --color-opal-10: #ecf0f3;
`;

const redShades = css`
  --color-red-100: #190306;
  --color-red-90: #3f070c;
  --color-red-80: #670811;
  --color-red-70: #920715;
  --color-red-60: #be0517;
  --color-red-50: #ec0017;
  --color-red-40: #eb3f50;
  --color-red-30: #ed7b86;
  --color-red-20: #f2b3b9;
  --color-red-10: #fae8ea;
`;

const greenShades = css`
  --color-green-100: #002101;
  --color-green-90: #004232;
  --color-green-80: #00624a;
  --color-green-70: #008363;
  --color-green-60: #00a47c; // also a primary brand color - dutchie pay
  --color-green-50: #33b293;
  --color-green-40: #65c1ab;
  --color-green-30: #95d2c3;
  --color-green-20: #c4e5dd;
  --color-green-10: #f1f8f7;
`;

const tealShades = css`
  --color-teal-100: #032329;
  --color-teal-90: #063e4b;
  --color-teal-80: #085a6c;
  --color-teal-70: #0b768d;
  --color-teal-60: #0d91ae;
  --color-teal-50: #10adcf;
  --color-teal-40: #47c0da;
  --color-teal-30: #7ed3e5;
  --color-teal-20: #b5e6f0;
  --color-teal-10: #ecf8fb;
`;

const purpleShades = css`
  --color-purple-100: #3c0059;
  --color-purple-50: #9050ff;
  --color-purple-10: #eadaf2;
`;

const yellowShades = css`
  --color-yellow-100: #594f00;
  --color-yellow-50: #ffe83e;
  --color-yellow-10: #faf6d4;
`;

const orangeShades = css`
  --color-orange-100: #592d00;
  --color-orange-50: #ff9e3e;
  --color-orange-10: #fae1c8;
`;

export const colors = css`
  ${primaryBrandColors}
  ${secondaryBrandColors}
  ${grayscaleShades}
  ${blueShades}
  ${opalShades}
  ${redShades}
  ${greenShades}
  ${tealShades}
  ${purpleShades}
  ${yellowShades}
  ${orangeShades}
`;
