/* eslint-disable react/destructuring-assignment */
import React from 'react';

import styled, { css } from 'styled-components';

import { ExitCircleFillIcon } from 'src/app/components/icons/exit-circle-fill-icon';
import { ErrorMessage } from 'src/app/components/lib/error-message';
import { FormControl } from 'src/app/components/lib/form-control';
import { Label } from 'src/app/components/lib/label';
import { MenuItem } from 'src/app/components/lib/menu-item';

import { StyledAutocomplete, StyledSearch, StyledPopperComponent } from './autocomplete.styles';

import type { AutocompleteProps as muiAutocompleteProps } from '@mui/material';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

export type AutocompleteProps = {
  disabled?: boolean;
  errorMessage?: string;
  gridColumns?: number;
  id: string;
  key?: string;
  label?: React.ReactNode;
  labelPlacement?: 'start' | 'top';
  onChange: muiAutocompleteProps<DropdownMenuOption, false, false, false>['onChange'];
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  options: DropdownMenuOption[];
  required?: boolean;
  searchPlaceholder?: string;
  tooltip?: string;
  value?: DropdownMenuOption | null;
};

export function Autocomplete(props: AutocompleteProps) {
  const {
    options,
    searchPlaceholder = 'Search...',
    onChange,
    id,
    key,
    label,
    required = false,
    tooltip,
    labelPlacement = 'start',
    gridColumns,
    disabled = false,
    value,
    errorMessage,
    onKeyDown,
  } = props;

  return (
    <FormControl $fullWidth={!label} $labelPlacement={labelPlacement} disabled={disabled} gridColumns={gridColumns}>
      {label && (
        <Label className='select-label' htmlFor={id} required={required} tooltip={tooltip}>
          {label}
        </Label>
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <StyledAutocomplete
        clearIcon={<ExitCircleFillIcon />}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 19],
                },
              },
            ],
          },
        }}
        forcePopupIcon={false}
        id={id}
        isOptionEqualToValue={({ id: optionId }, { id: selectedValue }) =>
          optionId === '' || optionId === selectedValue
        }
        key={key}
        options={options}
        PopperComponent={StyledPopperComponent}
        renderInput={(params) => (
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          <StyledSearch {...params} placeholder={searchPlaceholder} />
        )}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        renderOption={autocompleteRenderOption}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {errorMessage && (
        <ErrorMessage $fullWidth={!label} error>
          {errorMessage}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

export const autocompleteRenderOption = (props, option: DropdownMenuOption, { selected }, dataTestId?: string) => (
  <DropdownMenuItem
    $singleSelection
    aria-disabled={props['aria-disabled']}
    aria-selected={props['aria-selected']}
    className='dropdown--option'
    danger={option.danger}
    data-option-index={props['data-option-index']}
    data-testid={dataTestId}
    disabled={option.disabled}
    footer={option.footer}
    id={props.id}
    key={option.key ?? props.id}
    labelSecondary={option.labelSecondary}
    role={props.role}
    selected={selected}
    tabIndex={props.tabIndex}
    title={option.label}
    value={option.id as string}
    variant={option.footer || option.labelSecondary ? 'label' : option.variant || 'default'}
    onClick={props.onClick}
    onMouseOver={props.onMouseOver}
    onTouchStart={props.onTouchStart}
  >
    {option.label}
  </DropdownMenuItem>
);

export const DropdownMenuItem = styled(MenuItem)<{
  $hasSecondaryLabel?: boolean;
  $singleSelection?: boolean;
  variant: string;
}>`
  &.MuiMenuItem-root {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    padding: var(--sizes-30) var(--sizes-70);

    ${({ variant }) =>
      variant === 'checkbox' &&
      css`
        padding: var(--sizes-30) var(--sizes-50);
      `}

    .menu-item--primary-label {
      white-space: normal;
      font: var(--font-regular-14pt-semibold);
      width: 100%;
      display: block;
    }

    ${({ variant }) =>
      variant === 'compact' &&
      css`
        padding: var(--sizes-20) var(--sizes-40);
        .menu-item--primary-label {
          font: var(--font-small-13pt-normal);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}
  }
  ${({ $hasSecondaryLabel }) =>
    $hasSecondaryLabel &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        margin-bottom: 0;
      }
    `}
`;
