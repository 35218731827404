import React from 'react';

export function ToggleChecked() {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <rect fill='white' height='24' rx='12' width='24' />
      <path
        // eslint-disable-next-line max-len
        d='M16.2068 8.79279C16.3945 8.98031 16.5 9.23462 16.5 9.49979C16.5 9.76495 16.3945 10.0193 16.2068 10.2068L11.1999 15.2068C11.0121 15.3943 10.7574 15.4996 10.4919 15.4996C10.2263 15.4996 9.97167 15.3943 9.78388 15.2068L7.7811 13.2068C7.59869 13.0182 7.49776 12.7656 7.50004 12.5034C7.50232 12.2412 7.60763 11.9904 7.7933 11.805C7.97897 11.6196 8.23013 11.5144 8.49269 11.5121C8.75525 11.5098 9.0082 11.6106 9.19707 11.7928L10.4919 13.0858L14.7908 8.79279C14.9786 8.60532 15.2333 8.5 15.4988 8.5C15.7644 8.5 16.019 8.60532 16.2068 8.79279Z'
        fill='var(--color-opal-60)'
      />
    </svg>
  );
}
