import React from 'react';

import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MenuItem } from 'src/app/components/lib/menu-item';
import { useBohNav } from 'src/app/hooks/use-boh-nav';
import { useDarkMode } from 'src/app/state/dark-mode';

import { NavigationIcon } from '../navigation-menu/nav-icon';
import { SidebarContainer } from '../sub-navigation';

import { MinimizableSidebar } from './minimizable-sidebar';
import { NewItem, NewItemContainer } from './shared';

import type { BohLink } from 'src/app/hooks/use-boh-nav';

export function Sidebar() {
  const darkMode = useDarkMode();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const links = useBohNav();

  const parentSectionFromPath = _.capitalize(pathname.split('/')[1]);

  function handleClickNavigation(link: BohLink) {
    const firstAvailableRoute: string | undefined = link?.subNavItems?.filter((navItem) => navItem.visible)[0].to;
    const navigationRoute: string = firstAvailableRoute ?? link.to ?? '';
    navigate(navigationRoute);
  }

  // TODO: do not like this method for making sidebar UI exceptions. If we're going to keep adding pages with the "homepage style" sidebar, we need a more elegant way for specifying which sidebar a given page uses (rather than hardcoding the exceptions here based on URL path)
  const isNotHomeOrFinancials =
    parentSectionFromPath && parentSectionFromPath !== 'Home' && parentSectionFromPath !== 'Financials';

  return isNotHomeOrFinancials ? (
    <MinimizableSidebar parentSectionFromPath={parentSectionFromPath} />
  ) : (
    <HomepageSidebarContainer $darkMode={darkMode}>
      <NavigationItems>
        {links.map((link) => {
          const { visible, label, isNew } = link;
          const allSubNavItemsAreHidden =
            Boolean(link.subNavItems) && !link.subNavItems?.some((navItem) => navItem.visible);
          const isActive = label === parentSectionFromPath || (label === 'Home' && parentSectionFromPath === '');

          return (
            visible &&
            !allSubNavItemsAreHidden && (
              <NavigationMenuItem
                // AIRBENDER-TODO: Define unique test IDs for this specific case
                // data-testid={`index_navigation-menu-item_${label}`}
                $darkMode={darkMode}
                className={isActive ? 'sidebar-nav_active' : 'menu-nav_not_active'}
                key={label}
                value={label}
                onClick={() => handleClickNavigation(link)}
              >
                <NavigationIcon type={label} />
                {label}
                {isNew && (
                  <NewItemContainer width='30%'>
                    <NewItem>NEW</NewItem>
                  </NewItemContainer>
                )}
              </NavigationMenuItem>
            )
          );
        })}
      </NavigationItems>
    </HomepageSidebarContainer>
  );
}

const HomepageSidebarContainer = styled(SidebarContainer)<{ $darkMode: boolean }>`
  background-color: #f6f6f6;
  position: relative;
  overflow: visible;
  min-width: 216px;
  max-width: 216px;
  padding-bottom: var(--sizes-50);
  justify-content: space-between;
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background-color: var(--color-gray-100);
    `}
`;

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  /* hide scrollbar for most browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  svg {
    margin-right: 16px;
  }
`;

const NavigationMenuItem = styled(MenuItem)<{ $darkMode: boolean }>`
  letter-spacing: var(--font-letter-spacing);
  color: var(--color-gray-90);
  padding: var(--sizes-40);

  svg {
    path {
      fill: var(--color-gray-70);
    }
  }

  :hover {
    color: var(--color-grayscale-black);
    font-weight: 600;

    svg {
      path {
        fill: var(--color-grayscale-black);
      }
    }
  }
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: var(--color-gray-50);
      :hover {
        color: var(--color-brand-primary-white);
        background-color: var(--color-gray-100);
        svg {
          path {
            fill: var(--color-brand-primary-white);
          }
        }
      }
    `}
`;
