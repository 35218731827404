import type { FC } from 'react';
import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

type IntegrationStatus = {
  integrationFlagName: string;
  states?: Array<string>;
};

type PosStatus = {
  affectsApps: Array<string>;
  integration?: IntegrationStatus;
  message: string;
  status: string;
};

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 2em;
`;

const BannerText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1em;
`;

const BannerContent = styled.div`
  padding: 0.5rem;
  width: 80%;
  height: auto;
  border: 1px solid lightGrey;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fefdec;
`;

const BannerIcon = styled.i`
  margin-right: 1.5rem;
  font-size: 2.5em;
`;

export const StatusBanner: FC = () => {
  let { 'alert.llx-pos-incident': statuses }: { 'alert.llx-pos-incident'?: Array<PosStatus> } = useFlags();
  const user = useRecoilValue(userDispensariesAtom);
  if (!statuses || statuses.length === 0) {
    return null;
  }

  const backofficeAlerts = statuses.filter((x) => x.affectsApps.indexOf('backoffice') >= 0);

  if (backofficeAlerts.length === 0) {
    return null;
  }

  const integrationAlerts = backofficeAlerts.filter((x) => {
    if (!x.integration) return true;

    if (!user.locProfile) return false;

    if (user.locProfile[x.integration?.integrationFlagName]) {
      if (
        !x.integration.states ||
        x.integration.states.length === 0 ||
        x.integration.states[0].toLowerCase() === 'all' ||
        x.integration.states.filter((x) => x.toLowerCase() === user.selectedLocation.State.toLowerCase()).length > 0
      ) {
        return true;
      }
    }

    return false;
  });

  if (integrationAlerts.length === 0) {
    return null;
  }

  return (
    <BannerContainer>
      <BannerContent>
        <BannerText>
          <BannerIcon className='fa fa-exclamation-triangle' />
          {integrationAlerts.length > 1
            ? 'Multiple incidents are ongoing, please check the status page for additional information'
            : integrationAlerts[0].message}
        </BannerText>
        <a href='https://status.leaflogix.net/' rel='noopener noreferrer' target='_blank'>
          View Status
        </a>
      </BannerContent>
    </BannerContainer>
  );
};
