import type { BufferConfiguration } from '../../../utils/InputBuffer';
import { InputBuffer } from '../../../utils/InputBuffer';
import { KeyboardEventRepeater } from '../../../utils/KeyboardEventRepeater';
import { HardwareService } from '../../HardwareService';
import { isKeyboardScale, ScaleMeasurementParser } from '../../scale/index';
import { ConnectionType } from '../../shared/index';
import { Scanner } from '../Scanner';
import type { BarcodeData } from '../types';

export class KeyboardScanner extends Scanner {
  private _connected = false;
  private inputBuffer: InputBuffer<KeyboardEvent>;

  constructor(initiallyConnected: boolean, config?: Partial<BufferConfiguration>) {
    super(ConnectionType.keyboard);
    this._connected = initiallyConnected;
    this.inputBuffer = new InputBuffer<KeyboardEvent>({
      name: 'KeyboardScanner',
      config: {
        maxAverageInterval: config?.maxAverageInterval,
        minItemCount: config?.minItemCount || 3, // requires at least 3 key events
        timeout: config?.timeout,
      },
      onCandidateDetected: this.handleInputCandidate.bind(this),
    });
  }

  get id(): string { return 'keyboard-scanner'; }
  get isConnected(): boolean { return this._connected; }
  get name(): string { return 'Keyboard Scanner'; }

  private handleInputCandidate(props: { items: KeyboardEvent[] }) {
    // Do not emit measurements as barcodes when keyboard scale is registered
    const hasKeyboardScale = HardwareService.scale.devices.some((it) => isKeyboardScale(it) && it.isConnected);
    if (hasKeyboardScale) {
      const measurement = new ScaleMeasurementParser(HardwareService.logger).parseMeasurementFromKeyboardEvents(props.items);
      if (measurement != null) return;
    }

    const text = props.items
      .map((input) => String.fromCharCode(input.which))
      .join('')

    const barcode: BarcodeData = {
      bytes: new TextEncoder().encode(text),
      text,
    }

    this.emitBarcodeEvent(barcode);
  }

  private handleKeyPressRef = this.handleKeyPress.bind(this);
  private handleKeyPress(e: Event): void {
    const event: KeyboardEvent = (e as CustomEvent).detail as KeyboardEvent;
    const { target } = event

    // only detect when not focused on an input field
    if (target instanceof window.HTMLElement && this.isInput(target)) { return }

    // scanners can be configured to append the Enter key when scanning a barcode
    const forceCheck = ['Enter'].includes(event.code);

    // push the event to the input buffer
    this.inputBuffer.push(event, forceCheck);
  }

  private isContentEditable(element: HTMLElement): boolean {
    if (typeof element.getAttribute !== 'function') {
      return false
    }

    return !!element.getAttribute('contenteditable')
  }

  private isInput(element: HTMLElement): boolean {
    if (!element) {
      return false
    }

    const { tagName } = element
    const editable = this.isContentEditable(element)

    return tagName === 'INPUT' || tagName === 'TEXTAREA' || editable
  }

  async doConnect(): Promise<boolean> {
    if (this._connected) return true;

    KeyboardEventRepeater.instance.addEventListener('keypress', this.handleKeyPressRef);
    this._connected = true;

    return true;
  }

  async doDisconnect(): Promise<boolean> {
    if (!this._connected) return true;

    KeyboardEventRepeater.instance.removeEventListener('keypress', this.handleKeyPressRef);
    this._connected = false;

    return true;
  }
}
