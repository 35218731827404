import React from 'react';

export function HomeIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M3.75 13.3047C4.16421 13.3047 4.5 13.673 4.5 14.1274V22.3546H9V16.5955C9 15.9409 9.23705 15.3132 9.65901 14.8503C10.081 14.3874 10.6533 14.1274 11.25 14.1274H12.75C13.3467 14.1274 13.919 14.3874 14.341 14.8503C14.7629 15.3132 15 15.9409 15 16.5955V22.3546H19.5V14.1274C19.5 13.673 19.8358 13.3047 20.25 13.3047C20.6642 13.3047 21 13.673 21 14.1274V23.1773C21 23.6317 20.6642 24 20.25 24H14.25C13.8358 24 13.5 23.6317 13.5 23.1773V16.5955C13.5 16.3773 13.421 16.1681 13.2803 16.0138C13.1397 15.8595 12.9489 15.7728 12.75 15.7728H11.25C11.0511 15.7728 10.8603 15.8595 10.7197 16.0138C10.579 16.1681 10.5 16.3773 10.5 16.5955V23.1773C10.5 23.6317 10.1642 24 9.75 24H3.75C3.33579 24 3 23.6317 3 23.1773V14.1274C3 13.673 3.33579 13.3047 3.75 13.3047Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12 1.64544C11.9015 1.64544 11.8039 1.66674 11.7129 1.70811C11.6219 1.74949 11.5392 1.81014 11.4695 1.88659L1.28033 13.0637C0.987437 13.385 0.512563 13.385 0.21967 13.0637C-0.0732233 12.7424 -0.0732233 12.2215 0.21967 11.9002L10.4085 0.723466C10.6175 0.494109 10.8656 0.312164 11.1386 0.188029C11.4117 0.0638932 11.7044 0 12 0C12.2956 0 12.5883 0.0638932 12.8614 0.188029C13.1344 0.312164 13.3825 0.494109 13.5915 0.723466L23.7803 11.9002C24.0732 12.2215 24.0732 12.7424 23.7803 13.0637C23.4874 13.385 23.0126 13.385 22.7197 13.0637L12.5307 1.88678C12.461 1.81033 12.3781 1.74949 12.2871 1.70811C12.1961 1.66674 12.0985 1.64544 12 1.64544Z'
        // eslint-disable-next-line max-len
        fill='inherit'
        fillRule='evenodd'
      />
    </svg>
  );
}
