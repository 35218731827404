import { registerPlugin } from '@capacitor/core';

import type { HardwarePlugin } from './definitions';

const Hardware = registerPlugin<HardwarePlugin>('Hardware', {
  web: () => import('./web').then(m => new m.HardwareWeb()),
});

export * from './definitions';
export * from './models/index';
export * from './utils/index';
export { Hardware };

