import { useMutation } from '@tanstack/react-query';

import { endPoints } from 'src/app_deprecated/constants/PermissionsConstants';

import { fetchData } from 'src/app/queries';
import { useNotificationActions } from 'src/app/state/notifications';

const endpoint = endPoints.ELEVATE_SUPERUSER;

type Response = {
  Message: string;
};

type Error = {
  response?: {
    data?: {
      Message: string;
    };
  };
};

export function useElevateSuperuserQuery() {
  const notifications = useNotificationActions();
  return useMutation({
    mutationFn: async () => fetchData<Response>(endpoint),
    onError: (error: Error) =>
      notifications.error({ message: error?.response?.data?.Message ?? 'Unable to elevate permissions' }),
  });
}
