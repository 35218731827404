import $ from 'jquery';

import { VERSION } from 'src/app/constants/environment';
import { getRequestHeaders } from 'src/app/utils/request-headers';

import NotificationActions from '../actions/NotificationActions';

const formatException = (resp) => {
  if (resp.hasOwnProperty('IntegrationResult') && !resp.IntegrationResult) {
    return `${resp.Message}: ${resp.IntegrationMessage}`;
  }

  return `${resp.Message}`;
};

function getAppHeaders() {
  const result = { AppName: 'Backoffice' };
  if (VERSION) {
    result.AppVersion = VERSION;
  }

  return {
    ...result,
    ...getRequestHeaders(),
  };
}

const notifyIfForbidden = (status) => {
  if (status === 403) {
    NotificationActions.addNotification({
      type: 'error',
      body: 'User is not permitted to perform some of the operations in this page.',
    });
  }
};

export default {
  GET: (url) => {
    const host = '/';
    const promise = new Promise((resolve, reject) => {
      $.ajax({
        url: host + url,
        type: 'GET',
        xhrFields: {
          withCredentials: true,
        },
        headers: getAppHeaders(),
        success(data) {
          resolve(data);
        },
        error(data) {
          notifyIfForbidden(data.status);
          reject(data);
        },
      });
    });
    return promise;
  },
  POST: (url, formData) => {
    const host = '/';
    const promise = new Promise((resolve, reject) => {
      $.ajax({
        url: host + url,
        type: 'POST',
        xhrFields: {
          withCredentials: true,
        },
        headers: getAppHeaders(),
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(formData),
        success(data) {
          if (data && data.hasOwnProperty('Result')) {
            if (data.Result === 'true') {
              data.Result = true;
            } else if (data.Result === 'false') {
              data.Result = false;
              console.warn(data.Message, data.Data);
            }

            if (!data.Result) {
              try {
                window.appInsights.trackException({
                  exception: new Error(`${formatException(data)}`),
                  properties: {
                    url,
                    environment: host,
                    responseData: JSON.stringify(data),
                    requestData: JSON.stringify(formData),
                  },
                });
              } catch (ex) {
                console.warn('error logging to AppInsights: ', ex);
              }
            }
          }
          resolve(data);
        },
        error(data) {
          if (data.responseJSON) {
            notifyIfForbidden(data.responseJSON.status);
            if (!data.responseJSON.hasOwnProperty('Result')) {
              data.responseJSON.Result = false;
            }
            resolve(data.responseJSON);
          }

          resolve({
            data: { responseJSON: { Result: false, Message: 'An error occurred while processing your request.' } },
          });
        },
      });
    });

    return promise;
  },
};
