import React from 'react';

import _ from 'lodash';

import { Loader } from 'src/app/components/lib/loader';
import { usePaginateOnVisibleNode } from 'src/app/hooks/use-paginate-on-visible-node';

import { MultiFilterSelect } from './multi-filter-select';

import type { MultiFilterSelectProps } from './multi-filter-select';
import type { DropdownMenuOption } from '../dropdown';

type ServerPaginatedMultiFilterSelectProps = Omit<MultiFilterSelectProps, 'activeFilter' | 'onChange'> & {
  fetchNextPage: () => void;
  isFetching: boolean;
  morePagesExist: boolean;
  onSelectAll?: () => Promise<DropdownMenuOption[]>;
  setFilter: (filter: string) => void;
  setValue: (selection: (number | string)[]) => void;
  value: DropdownMenuOption[];
};

export function ServerPaginatedMultiFilterSelect({
  fetchNextPage,
  isFetching,
  morePagesExist,
  setFilter,
  options,
  setValue,
  value,
  label,
  footerContent,
  onSelectAll,
  ...props
}: ServerPaginatedMultiFilterSelectProps) {
  const triggerNodeRef = usePaginateOnVisibleNode({ fetchNextPage, isFetching, morePagesExist });

  return (
    <MultiFilterSelect
      {...props}
      activeFilter={value}
      footerContent={
        <>
          {footerContent}
          {isFetching && <Loader size='1x' variant='grey' />}{' '}
        </>
      }
      infiniteScroll
      label={label}
      options={options}
      paginationProps={{ isFetching, triggerNodeRef }}
      onChange={(opts) => setValue(opts.map(({ id }) => id))}
      onSearchInputChange={_.debounce(setFilter, 500)}
      onSelectAll={onSelectAll}
    />
  );
}
