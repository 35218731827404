export type SerialConnectionOptions = {
  baudRate: number;
  dataBits: number;
  stopBits: number;
  parity: ParityType;
}

export const defaultSerialOptions: SerialConnectionOptions = {
  baudRate: 2400,
  dataBits: 7,
  stopBits: 1,
  parity: 'even',
};
