import keyMirror from 'keymirror';

export const endPoints = {
  RUN_REPORT: 'api/posv3/reports/run-report',

  RUN_CLOSING_REPORT: 'api/posv3/reports/closing-report',

  EXPORT_CLOSING_REPORT_V2: 'api/posv3/reports/export-closing-report-v2',
};

export const actions = keyMirror({
  LIST_OF_REPORTS_LOADED: null,
  SET_REPORT_DATA: null,
  CLEAR_SELECTED_REPORT: null,
  REPORT_DATA_LOADED: null,
  REPORT_FAILED: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
