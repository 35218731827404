import { PeripheralType, VendorId } from "../models/shared/types";

type SupportGrid = Record<PeripheralType, number[]>;
export class Vendor {
  id: VendorId;
  name: string;
  supported: SupportGrid;
  
  constructor(id: VendorId, name: string, supported: SupportGrid) {
    this.id = id;
    this.name = name;
    this.supported = supported;
  }
}

export const Vendors = {
  and: new Vendor(VendorId.and, 'A&D', {
    [PeripheralType.scale]: [1659, 1027, 3516]
  } as SupportGrid),
  epson: new Vendor(VendorId.epson, 'Epson', {
    [PeripheralType.receiptPrinter]: [1208],
  } as SupportGrid),
  star: new Vendor(VendorId.star, 'Star', {
    [PeripheralType.receiptPrinter]: [1305],
  } as SupportGrid),
  zebra: new Vendor(VendorId.zebra, 'Zebra', {
    [PeripheralType.labelPrinter]:[2655],
    [PeripheralType.scanner]: [1504]
  } as SupportGrid)
}

export const vendorForId = (id: number, peripheralType: PeripheralType): Vendor | undefined => {
  const vendors = Object.values(Vendors);
  for(const vendor of vendors) {
    if(vendor.supported[peripheralType] && vendor.supported[peripheralType].indexOf(id) !== -1) {
      return vendor;
    }
  }
  return undefined;
}

export const vendorIdsByType = (type: PeripheralType): number[] => {
  return Object.values(Vendors).reduce((ids: number[], vendor: Vendor) => {
    return [...ids, ...(vendor.supported[type] ?? [])]
  }, []);
}