import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useNotificationActions } from 'src/app/state/notifications';

import type { AxiosError, AxiosResponse } from 'axios';

const SSO_LOGIN = 'api/SAML/SSOLoginRedirect';

type SSOLoginPayload = {
  username: string;
};

export type LoginResponse = {
  Data: string | { shouldShowBlockingUI: boolean };
  Message: string;
  Result: boolean;
};

export type LoginMutationProps = {
  showFullPageMigrationTakeover: (props: LoginResponse['Data']) => void;
};

export function useSSOLoginMutation({ showFullPageMigrationTakeover }: LoginMutationProps) {
  const notifications = useNotificationActions();
  return useMutation({
    mutationKey: ['user-sso-login'],
    mutationFn: (payload: SSOLoginPayload) =>
      axios.post(`/${SSO_LOGIN}`, {
        username: payload.username,
      }),
    onSuccess: (body: AxiosResponse<LoginResponse>) => {
      const { Result, Data } = body.data;

      if (!Result) {
        if (typeof Data !== 'string' && Data?.shouldShowBlockingUI) {
          showFullPageMigrationTakeover(Data);
          return;
        }
        notifications.error({ message: 'SSO login failed' });
      } else if (Result && typeof Data === 'string') {
        window.location.href = Data;
      }
    },
    onError: (error: AxiosError<LoginResponse>) => {
      notifications.error({ message: error.response?.data.Message ?? 'Something went wrong' });
    },
  });
}
