import React, { useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { Heading } from 'src/app/components/lib/heading';
import { Input } from 'src/app/components/lib/input';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { SkeletonLoader } from 'src/app/pages/reports-v2/overview/overview-tabs/loading-skeletons';
import { useCheckSegmentStatusQuery } from 'src/app/queries/segment/check-segment-status';
import { useGetSegmentDetailsQuery } from 'src/app/queries/segment/get-segment-details';
import { segmentsQueryKey } from 'src/app/queries/segment/get-segments';
import { audienceDetailKeys } from 'src/app/queries/segment/query-key-factory';

import { SegmentInProgress } from '../segment-update-in-progress';

import QueryBuilder from './query-builder';
import { isDirtyAtom } from './query-builder-atom';
import {
  StyledContentCard,
  StyledContent,
  StyledHeading,
  StyledSearchBar,
  StyledNoResultsOverlay,
  FullWidthContainerWithStickyFooterQueryBuilder,
} from './query-builder.styles';

type SegmentQueryBuilderProps = {
  handleDrawerClose?: () => void;
};

export function SegmentQueryBuilder({ handleDrawerClose }: SegmentQueryBuilderProps) {
  const { segmentId = '' } = useParams<{ segmentId: string }>();
  const queryClient = useQueryClient();
  const { data: customerQueryBuilder, isLoading: getSegmentQueryIsLoading } = useGetSegmentDetailsQuery({
    segmentId: segmentId ? Number(segmentId) : null,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDirty, setIsDirty] = useRecoilState(isDirtyAtom);

  // disable query builder when segment is inactive - user can set it to active on segment list
  const disableQueryBuilder =
    !useLDFlag(LD.CUSTOMERS_QUERY_BUILDER_CHUNK_ROLLOUT) || customerQueryBuilder?.isSegmentInactive;
  const { data: isSegmentUpdateInProgress, isLoading: checkForSegmentUpdatingIsLoading } = useCheckSegmentStatusQuery({
    segmentId: segmentId ? Number(segmentId) : null,
  });
  const [segmentName, setSegmentName] = useState('');

  useEffect(() => {
    setIsDirty(false);
  }, []);

  // update segment name state when segment details are fetched
  useEffect(() => {
    if (customerQueryBuilder?.segmentName) {
      setSegmentName(customerQueryBuilder.segmentName);
    }
  }, [customerQueryBuilder]);

  const handleSegmentNameChange = (value: string) => {
    setSegmentName(value);
    setIsDirty(true);
  };

  const segmentUpdateInProgress = !checkForSegmentUpdatingIsLoading && isSegmentUpdateInProgress;
  // ref to track previous segmentUpdateInProgress state
  const prevSegmentUpdateInProgressRef = useRef(segmentUpdateInProgress);

  // when a segment update finishes, refetch the segment list and audience for the user
  useEffect(() => {
    const prevSegmentUpdateInProgress = prevSegmentUpdateInProgressRef.current;

    // check if segmentUpdateInProgress transitioned from true to false
    if (prevSegmentUpdateInProgress && !segmentUpdateInProgress && segmentId) {
      void queryClient.invalidateQueries({ queryKey: segmentsQueryKey });
      void queryClient.refetchQueries({ queryKey: audienceDetailKeys.one(Number(segmentId)) });
    }

    // Update the ref value for the next render
    prevSegmentUpdateInProgressRef.current = segmentUpdateInProgress;
  }, [segmentUpdateInProgress, segmentId, queryClient]);

  const isSegmentDisabled = segmentUpdateInProgress || disableQueryBuilder;
  const queryLoadedWithNoSchemaOrRules =
    !getSegmentQueryIsLoading && !customerQueryBuilder?.segmentDetails && !customerQueryBuilder?.customerSegmentSchema;
  const queryLoadedWithSchema = !getSegmentQueryIsLoading && customerQueryBuilder?.customerSegmentSchema;

  return (
    <FullWidthContainerWithStickyFooterQueryBuilder>
      {segmentUpdateInProgress && <SegmentInProgress />}
      {!isSegmentDisabled && !getSegmentQueryIsLoading && (
        <StyledContentCard>
          <StyledContent>
            <StyledHeading>
              <Heading
                level={4}
                marginBottom='0'
                subheader='Give a name for your segment to allow you to track and analyze people who meet certain conditions.'
              >
                Segment name
              </Heading>
            </StyledHeading>
            <StyledSearchBar>
              <Input
                disabled={disableQueryBuilder}
                label='Name:'
                labelPlacement='top'
                maxlength={100}
                placeholder='Segment'
                value={segmentName}
                onChange={({ target }) => handleSegmentNameChange(target.value)}
              />
            </StyledSearchBar>
          </StyledContent>
        </StyledContentCard>
      )}

      {getSegmentQueryIsLoading && <SkeletonLoader />}
      {queryLoadedWithNoSchemaOrRules && <StyledNoResultsOverlay />}
      {queryLoadedWithSchema && (
        <QueryBuilder
          customerSegmentSchema={customerQueryBuilder?.customerSegmentSchema}
          disabled={isSegmentDisabled}
          handleDrawerClose={handleDrawerClose}
          segmentDetails={customerQueryBuilder?.segmentDetails}
          segmentId={segmentId ? Number(segmentId) : null}
          segmentName={segmentName}
        />
      )}
    </FullWidthContainerWithStickyFooterQueryBuilder>
  );
}
