import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { useBohNav } from '../use-boh-nav';

import { searchTermAtom } from './search-term';

import type { BohLink } from '../use-boh-nav';

export function useFilteredNav(): BohLink[] {
  const flags = useFlags();

  // we are deprecating the searchability of "past names" (a holdover of Airbender change management). Once this is fully rolled out, we can delete this flag and all `pastNames` references in use-boh-nav.
  const skipPastNamesCheck = flags['pos.backoffice.global-search-refactor.rollout'];
  const links = useBohNav();

  const filter = useRecoilValue(searchTermAtom);

  const filteredLinks = useMemo(() => {
    if (!filter) {
      return [];
    }

    const clonedLinks = _.cloneDeep(links);

    clonedLinks.forEach((link) => {
      link.subNavItems = link.subNavItems?.filter((subNavItem) => {
        const hasPastNameMatch = skipPastNamesCheck
          ? false
          : subNavItem.pastNames?.find((pastName) => pastName.toLowerCase().includes(filter.toLowerCase()));
        const hasLabelMatch = subNavItem.label.toLowerCase().includes(filter.toLowerCase());

        return hasPastNameMatch || hasLabelMatch;
      });
    });

    const filteredLinks = clonedLinks.filter((clonedLink) => (clonedLink.subNavItems?.length ?? 0) > 0);

    return filteredLinks;
  }, [links, filter, skipPastNamesCheck]);

  return filteredLinks;
}
