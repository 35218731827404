import React from 'react';

export function ProgressFillIcon({ size = 'var(--sizes-30)' }: { size?: string }): JSX.Element {
  return (
    <svg fill='none' style={{ width: size, height: size }} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          clipRule='evenodd'
          d='M6 9.5C7.933 9.5 9.5 7.933 9.5 6C9.5 4.067 7.933 2.5 6 2.5C4.067 2.5 2.5 4.067 2.5 6C2.5 7.933 4.067 9.5 6 9.5ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z'
          fill='var(--color-blue-30)'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M9.5 6C9.5 7.933 7.933 9.5 6 9.5V12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0V2.5C7.933 2.5 9.5 4.067 9.5 6Z'
          fill='var(--color-blue-80)'
          fillRule='evenodd'
        />
      </g>
    </svg>
  );
}
