import React from 'react';

import { Controller } from 'react-hook-form';

import { getOnBlurValue, getOnChangeValue } from 'src/app/components/fields/utils';
import { Input } from 'src/app/components/lib/input';

import type { FieldError } from 'react-hook-form';

export type TextFieldProps = {
  autoComplete?: string;
  automationId?: string;
  customErrorMessages?: Partial<Record<FieldError['type'], string>>;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  gridColumns?: number;
  id?: string;
  isCurrency?: boolean;
  // If true, the field will be set to null when the user clears the input.
  isNullable?: boolean;
  // if true, the field will be set to a number instead of a string.
  isNumber?: boolean;
  label?: React.ReactElement | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  maxDecimalPlaces?: number;
  maxLength?: number;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  showCharacterCount?: boolean;
  startAdornment?: React.ReactNode;
  tooltip?: string;
  type?: string;
  width?: string;
};

export function TextField(props: TextFieldProps) {
  const {
    autoComplete,
    automationId,
    disabled,
    endAdornment,
    gridColumns,
    id,
    label,
    labelPlacement,
    maxLength,
    maxRows,
    minRows,
    multiline,
    name,
    placeholder,
    required,
    rows,
    showCharacterCount = false,
    startAdornment,
    tooltip,
    type,
    width,
    isNullable = false,
    isNumber = false,
    isCurrency = false,
    maxDecimalPlaces = 2,
  } = props;

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        let errorMessage: string | undefined;
        const { error } = fieldState;
        if (error) {
          errorMessage = props.customErrorMessages?.[error.type] ?? error.message;
        }
        return (
          <Input
            autoComplete={autoComplete}
            automationId={automationId}
            disabled={disabled}
            endAdornment={endAdornment}
            errorMessage={errorMessage}
            gridColumns={gridColumns}
            id={id}
            label={label}
            labelPlacement={labelPlacement}
            maxlength={maxLength}
            maxRows={maxRows}
            minRows={minRows}
            multiline={multiline}
            placeholder={placeholder}
            required={required}
            rows={rows}
            showCharacterCount={showCharacterCount}
            startAdornment={startAdornment}
            tooltip={tooltip}
            type={type}
            value={field.value ?? ''}
            width={width}
            onBlur={({ target }) => {
              const newValue = getOnBlurValue({
                value: target.value,
                isCurrency,
                maxDecimalPlaces,
              });
              if (newValue !== target.value) {
                field.onChange(newValue);
              }
              return field.onBlur;
            }}
            onChange={({ target }) => {
              const newValue = getOnChangeValue({
                isNullable,
                isNumber,
                value: target?.value ?? '',
                isCurrency,
                maxDecimalPlaces,
              });
              field.onChange(newValue);
            }}
          />
        );
      }}
    />
  );
}
