import React from 'react';

import styled from 'styled-components';

import { StyledGridContainer } from 'src/app/components/lib/table/no-results-overlay';
import { RegularText } from 'src/app/components/lib/typography';

import { Loader } from './loader';

import type { LoaderProps } from './loader';

type PageLoaderProps = {
  className?: string;
  copy?: string;
  size?: LoaderProps['size'];
  variant?: LoaderProps['variant'];
  visible?: boolean;
};

export function PageLoader(props: PageLoaderProps) {
  const { visible = true, className = '', variant = 'grey', size = '3x', copy } = props;

  if (!visible) {
    return null;
  }

  return (
    <StyledGridContainer className={className}>
      <Loader size={size} variant={variant} />
      {copy && (
        <StyledRegularText aria-busy='true' role='alert'>
          {copy}
        </StyledRegularText>
      )}
    </StyledGridContainer>
  );
}

const StyledRegularText = styled(RegularText)`
  color: var(--color-gray-80);
  margin-top: var(--sizes-60);
`;
