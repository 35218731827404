import keyMirror from 'keymirror';

export const endPoints = {
  GET_BATCH_STEPS: 'api/v2/recipe/get-batch-steps',
  GET_BATCH_STEP_DETAILS: 'api/v2/recipe/get-batch-step-details',

  START_BATCH_STEP: 'api/v2/recipe/start-batch-step',
  ADJUST_BATCH_STEP: 'api/v2/recipe/adjust-batch-step',
  UNDO_FINISH_STEP: 'api/v2/recipe/undo-finish-step',
  UNDO_START_STEP: 'api/v2/recipe/undo-start-step',
  SKIP_INPUT: 'api/v2/recipe/skip-input',

  GET_BATCH_DOCUMENTATION: 'api/v2/recipe/get-batch-documentation',

  // package based endpoints
  START_STEP: 'api/v2/recipe/batch/start-step',
  ADJUST_STEP: 'api/v2/recipe/batch/adjust-step',
  FINISH_STEP: 'api/v2/recipe/batch/finish-step',
  FINISH_BATCH: 'api/v2/recipe/batch/finish-batch',
  GET_STEP_DETAILS: 'api/v2/recipe/batch/get-step-details',

  CREATE_BATCH: 'api/v2/recipe/create-batch',
  GET_ELIGIBLE_BATCHES: 'api/v2/recipe/get-eligible-batches',
  DELETE_BATCH: 'api/v2/recipe/delete-batch',
  GET_ACTIVE_BATCHES: 'api/v2/recipe/get-active-batches',
  GET_BATCH_HISTORY: 'api/v2/recipe/get-batch-history',
  UPDATE_NOTES: 'api/v2/recipe/update-notes',
};

export const actions = keyMirror({});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
