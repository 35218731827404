import styled, { css } from 'styled-components';

export const Badge = styled.span<{ $inactive?: boolean }>`
  background: var(--color-grayscale-black);
  color: var(--color-brand-primary-white);

  ${({ $inactive }) =>
    $inactive &&
    css`
      background: var(--color-gray-40);
      color: var(--color-gray-90);
    `}

  font: var(--font-mini-11pt-semibold);
  padding: var(--sizes-10) var(--sizes-20);
  min-height: 20px;
  min-width: 22px;
  line-height: 12px;
  border-radius: 3px;
`;
