import { createGlobalStyle } from 'styled-components';

import { additionalTokens } from './additional-tokens';
import { fontFaces } from './font-faces';
import { generatedTokens } from './generated-tokens';

export const Tokens = createGlobalStyle`
${fontFaces}
${generatedTokens}
${additionalTokens}
`;
