import { HardwareService } from "../models/index";

export type TimedInput<T extends any> = {
  timestamp: number;
  item: T;
}

export type TimedInputReport = {
  average: number;
  stdDev: number;
  max: number;
  min: number;
  total: number;
  timings: number[];
}

export type BufferConfiguration = {
  timeout: number;
  maxAverageInterval: number;
  minItemCount: number;
}

export type onCandidateDetectedHandler<T> = (props: { items: T[], report: TimedInputReport }) => void;

export class InputBuffer<T extends any>{
  private items: TimedInput<T>[] = [];
  private notificationTimeout: NodeJS.Timeout | number | null = null;

  private config: BufferConfiguration;

  // A helpful name for debugging logs
  private name: string;
  private defaultConfig: BufferConfiguration = {
    maxAverageInterval: 50,
    minItemCount: 1,
    timeout: 100,
  }

  constructor(props: {
    name: string,
    onCandidateDetected: onCandidateDetectedHandler<T>,
    config?: Partial<BufferConfiguration>,
  }) {
    this.name = props.name;
    this.onCandidateDetected = props.onCandidateDetected;
    this.config = {
      maxAverageInterval: props.config?.maxAverageInterval || this.defaultConfig.maxAverageInterval,
      minItemCount: props.config?.minItemCount || this.defaultConfig.minItemCount,
      timeout: props.config?.timeout || this.defaultConfig.timeout,
    };
  }

  onCandidateDetected: onCandidateDetectedHandler<T>;

  // Add an item to the buffer. After the configured timeout emit the candidate events if they meet the criteria.
  // If forceCheck is true, the buffer will be checked immediately instead of waiting for the timeout.
  push(item: T, forceCheck = false): void {
    this.items.push({
      item: item,
      timestamp: Date.now(),
    });

    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }

    // immediately check
    if (forceCheck) {
      this.maybeEmitInputBufferAsCandidate('force check')
      this.items = [];
      return;
    }

    // wait for timeout and then check
    this.notificationTimeout = setTimeout(() => {
      if (this.items.length === 0) return;
      this.maybeEmitInputBufferAsCandidate(`timeout: ${this.config.timeout}ms`)
      this.items = []
    }, this.config.timeout);
  }

  // Check buffer and emit candidate if conditions are met
  maybeEmitInputBufferAsCandidate(source?: string): void {
    if (this.items.length < this.config.minItemCount) return;

    // Create an array of timing differences between each item in the buffer.
    const timingData = this.items.map((item, index) => {
      if (index === 0) {
        return 0; // No previous item to compare to.
      }
      return item.timestamp - this.items[index - 1].timestamp;
    });

    // Calculate the average timing difference.
    const average = timingData.reduce((a, b) => a + b, 0) / timingData.length;

    // Calculate the standard deviation of the timing differences.
    const stdDev = Math.sqrt(
      timingData.map(x => Math.pow(x - average, 2)).reduce((a, b) => a + b, 0) /
      timingData.length
    );

    // Calculate the maximum and minimum timing differences.
    const max = Math.max(...timingData);
    const min = Math.min(...timingData);

    // Calculate the total duration of the input buffer.
    const start = this.items[0].timestamp;
    const end = this.items[this.items.length - 1].timestamp;
    const total = end - start;

    // Create a TimedInputReport object with the calculated values.
    const report: TimedInputReport = { average, stdDev, max, min, total, timings: timingData };

    // Check if the average timing difference is greater than the maximum allowed interval.
    if (average > this.config.maxAverageInterval) {
      this.log('Rejected candidate', { name: this.name, source, report });
      return;
    }

    // Create a data object with the input buffer items and the TimedInputReport object.
    const data = {
      items: this.items.map(it => it.item),
      report,
    };

    // Log the candidate detection and emit the candidate event.
    this.log('Detected candidate', { name: this.name, source, ...data });
    this.onCandidateDetected(data);

    // Clear the input buffer.
    this.items = [];
  }

  private log(message: string, data: any): void {
    HardwareService.logger.d(`${message}: ${this.name}`, data);
  }
}
