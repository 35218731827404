import { useQuery } from '@tanstack/react-query';

import { getData } from 'src/app/queries';

export const GET_SSO_ENABLED = 'api/saml/SSOLoginEnabled';

type GetSSOEnabledResponse = {
  isSSOLoginEnabled: boolean;
};

export function useSSOEnabled() {
  return useQuery({
    queryKey: ['sso-enabled'],
    queryFn: () => getData<GetSSOEnabledResponse>(GET_SSO_ENABLED),
  });
}
