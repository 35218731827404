import { Popper } from '@mui/material';
import styled from 'styled-components';

export const StyledTooltip = styled(Popper)`
  .MuiTooltip-tooltip {
    color: var(--color-brand-primary-white);
    background-color: var(--color-grayscale-black);
    padding: var(--sizes-30);
    opacity: 1;
    font: var(--font-small-13pt-normal);
    max-width: 350px;

    .MuiTooltip-arrow {
      color: var(--color-grayscale-black);
    }
  }
`;

export const TooltipIconContainer = styled.span`
  margin-left: var(--sizes-10);
  display: inline-block;
  vertical-align: text-bottom;
  opacity: 1;

  svg {
    display: block;
  }

  :hover {
    > svg {
      path {
        fill: var(--color-grayscale-black);
      }
    }
  }
`;
