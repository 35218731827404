import styled from 'styled-components';

import { BohSelect as Select } from '../../select/select.styles';

export const FooterSelect = styled(Select)`
  width: var(--sizes-110);
  padding: 0;
  height: var(--sizes-70);
  position: relative;

  & > svg {
    position: absolute;
    right: var(--sizes-30);
    z-index: 1;
  }

  .MuiSelect-outlined.MuiInputBase-input {
    padding-left: var(--sizes-20);
    font: var(--font-extra-small-12pt-semibold);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
  }
`;

export const PaginationControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font: var(--font-regular-14pt-normal);
`;

export const PaginationRange = styled.span`
  font: var(--font-regular-14pt-semibold);
`;

export const PageUnits = styled.span`
  white-space: nowrap;
  width: fit-content;
`;

export const StatusContainer = styled.span`
  white-space: nowrap;
  width: fit-content;
  border-right: 1px solid var(--color-gray-20);
  padding-right: 6px;
`;
