import React, { useState } from 'react';

import i18next from 'i18next';
import styled, { css } from 'styled-components';

import { EllipsesIcon } from 'src/app/components/icons';
import { IconButton } from 'src/app/components/lib/icon-button';
import { Loader } from 'src/app/components/lib/loader';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { RebrandOverflowMenu } from 'src/app/components/lib/overflow-menu/overflow-menu-rebrand.styles';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { Action, GridRenderCellParams } from 'src/app/components/lib/table';

export type RowActionCellProps = {
  actions: Action[];
  params: GridRenderCellParams;
  rowLoading: { loading: boolean; rowId: string };
};

export function RowActionCell({ actions, params, rowLoading }: RowActionCellProps) {
  const darkMode = useDarkMode();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loading = rowLoading.loading && rowLoading.rowId === params.id;

  const actionsItems = actions.reduce<JSX.Element[]>((accumulatedActions, action) => {
    const isVisible: boolean = typeof action.visible === 'function' ? action.visible(params.row) : action.visible;
    const value: string = typeof action.value === 'function' ? action.value(params.row) : action.value;
    const handleActionOnClick = () => {
      action?.onClick(params.row);
      handleClose();
    };

    if (isVisible) {
      return accumulatedActions.concat([
        <MenuItem automationId={action.automationId} key={value} value={value} onClick={handleActionOnClick}>
          {i18next.t(value) ?? value}
        </MenuItem>,
      ]);
    }
    return accumulatedActions;
  }, []);

  return actionsItems.length === 0 ? null : (
    <>
      <StyledIconButton
        $darkMode={darkMode}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        data-testid='user-row-actions-button'
        disabled={loading}
        onClick={handleClick}
      >
        {loading ? <Loader size='1x' variant='grey' /> : <EllipsesIcon />}
      </StyledIconButton>
      <RebrandOverflowMenu
        $darkMode={darkMode}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        {actionsItems}
      </RebrandOverflowMenu>
    </>
  );
}

const StyledIconButton = styled(IconButton)<{ $darkMode: boolean }>`
  width: 28px;
  height: 28px;
  padding: 0;

  svg {
    width: var(--sizes-40);

    ellipse {
      fill: var(--color-grayscale-black);
    }
  }

  :hover {
    background-color: var(--color-gray-40);
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      :hover {
        background-color: var(--color-gray-80);
      }
      svg {
        ellipse {
          fill: var(--color-brand-primary-white);
        }
      }
    `}
`;
