import React from 'react';

export type CheckProps = {
  disabled?: boolean;
  fill?: string;
};
export function RebrandCheck(props: CheckProps): JSX.Element {
  const { fill = 'var(--color-brand-secondary-sapphire', disabled = false } = props;
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <rect
        fill={disabled ? 'var(--color-gray-50)' : fill}
        height='16'
        rx='3'
        stroke={disabled ? 'var(--color-gray-50)' : 'var(--color-gray-60)'}
        width='16'
      />
      <path
        d='M12.2047 4.79279C12.3922 4.98031 12.4975 5.23462 12.4975 5.49979C12.4975 5.76495 12.3922 6.01926 12.2047 6.20679L7.20472 11.2068C7.01719 11.3943 6.76289 11.4996 6.49772 11.4996C6.23256 11.4996 5.97825 11.3943 5.79072 11.2068L3.79072 9.20679C3.60856 9.01818 3.50777 8.76558 3.51005 8.50339C3.51233 8.24119 3.61749 7.99038 3.8029 7.80497C3.98831 7.61956 4.23912 7.51439 4.50132 7.51211C4.76352 7.50983 5.01612 7.61063 5.20472 7.79279L6.49772 9.08579L10.7907 4.79279C10.9782 4.60532 11.2326 4.5 11.4977 4.5C11.7629 4.5 12.0172 4.60532 12.2047 4.79279Z'
        fill='white'
      />
    </svg>
  );
}
