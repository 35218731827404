export function getPageStartEndRows(
  page: number,
  totalRowCount: number,
  pageSize: number
): { currentPage: number; pageEnd: number; pageStart: number } {
  const currentPage = page + 1;
  const pageStart = currentPage * pageSize - (pageSize - 1);
  const calculatedRowsOnPage = totalRowCount - (currentPage - 1) * pageSize;
  const rowsOnPage = calculatedRowsOnPage < pageSize ? calculatedRowsOnPage : pageSize;
  const pageEnd = pageStart - 1 + Number(rowsOnPage);

  return { currentPage, pageStart, pageEnd };
}
