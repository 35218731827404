

import { ConnectionType } from "../../shared/index";
import { WebUsbPrinterHelper } from "../../shared/web-usb/index";
import { ReceiptPrinter } from "../ReceiptPrinter";

export class UsbReceiptPrinter extends ReceiptPrinter {
  private helper: WebUsbPrinterHelper;

  constructor(device: USBDevice) {
    super(ConnectionType.usb);
    this.helper = new WebUsbPrinterHelper(device);
  }

  get id(): string { return this.helper.id; }
  get isConnected(): boolean { return this.helper.isConnected; }
  
  get productId(): number { return this.helper.productId; }
  get vendorId(): number { return this.helper.vendorId; }
  
  doConnect(): Promise<boolean> {
    return this.helper.connect();
  }
  
  doDisconnect(): Promise<boolean> {
    return this.helper.disconnect();
  }

  async doRevokePermission(): Promise<boolean> {
    return this.helper.revokePermission();
  }

  popCashDrawer(): Promise<boolean> {
    return this.helper.write(Uint8Array.from(this.encoder.popCashDrawer));
  }

  print(bytes: Uint8Array): Promise<boolean> {
    return this.helper.write(bytes);
  }
}
