import type { MouseEventHandler, MutableRefObject, TouchEventHandler } from 'react';
import React from 'react';

import { CheckboxBase } from 'src/app/components/lib/checkbox';
import { Tooltip } from 'src/app/components/lib/tooltip';
import { useDarkMode } from 'src/app/state/dark-mode';
import { omit } from 'src/app/utils/omit';

import { RebrandMenuItem, MenuItemSubText, LabelVariantContainer } from './menu-item-rebrand.styles';

import type { SxProps } from '@mui/material';
import type { CheckboxBaseProps } from 'src/app/components/lib/checkbox';

export type MenuItemVariantTypes = 'checkbox' | 'compact' | 'default' | 'label' | 'username';

// TODO-BACKOFFICE: different prop types based on which variant is passed
type MenuItemProps = {
  automationId?: string;
  checkboxLabel?: string;
  checkboxProps?: CheckboxBaseProps;
  checked?: boolean;
  children: React.ReactNode | string;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  footer?: React.ReactNode;
  id?: string;
  key?: number | string;
  labelSecondary?: React.ReactNode;
  onClick?: (...any) => void;
  onMouseOver?: MouseEventHandler;
  onTouchStart?: TouchEventHandler;
  role?: string;
  selected?: boolean;
  sx?: SxProps;
  tabIndex?: number;
  title?: string;
  tooltip?: string;
  value?: number | string;
  variant?: MenuItemVariantTypes;
};

export const MenuItem = React.forwardRef(
  (
    props: MenuItemProps,
    ref: MutableRefObject<HTMLLIElement | null> | ((instance: HTMLLIElement | null) => void) | null
  ) => {
    const {
      automationId,
      className = '',
      checkboxLabel,
      checkboxProps,
      checked,
      children,
      danger = false,
      disabled = false,
      labelSecondary,
      footer,
      tooltip = '',
      sx,
      variant = 'default',
    } = props;
    const darkMode = useDarkMode();
    return (
      <Wrapper tooltip={tooltip}>
        <RebrandMenuItem
          $danger={danger}
          className={className}
          data-testid={automationId}
          disabled={disabled}
          {...omit(props, ['automationId', 'labelSecondary'])}
          $darkMode={darkMode}
          $variant={variant}
          ref={ref}
          sx={sx}
        >
          {variant === 'checkbox' && <CheckboxBase {...checkboxProps} checked={checked} />}
          {variant === 'username' && <span>Logged in as</span>}
          {['default', 'username'].includes(variant) && children}
          {['checkbox', 'label', 'compact'].includes(variant) && (
            <LabelVariantContainer $hasFooter={Boolean(footer)} $variant={variant}>
              <div className='main-label'>
                {variant === 'checkbox' && <span className='menu-item--primary-label'>{checkboxLabel}</span>}
                {Boolean(children) && <span className='menu-item--primary-label'>{children}</span>}
                {Boolean(labelSecondary) && <span className='menu-item--label-text'>{labelSecondary}</span>}
              </div>
              {Boolean(footer) && <MenuItemSubText>{footer}</MenuItemSubText>}
            </LabelVariantContainer>
          )}
        </RebrandMenuItem>
      </Wrapper>
    );
  }
);

function Wrapper({ children, tooltip }: { children: JSX.Element; tooltip: string }) {
  return tooltip ? (
    <Tooltip placement='bottom' title={tooltip}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
}
