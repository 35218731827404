import React from 'react';

export function ArrowUp() {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.10756 10.0721L7.37148 4.26878C7.44466 4.18533 7.53955 4.11759 7.64864 4.07094C7.75773 4.02429 7.87803 4 7.99999 4C8.12196 4 8.24225 4.02429 8.35134 4.07094C8.46044 4.11759 8.55533 4.18533 8.62851 4.26878L12.8924 10.0721C12.9617 10.1657 12.999 10.274 13 10.3848C13.001 10.4955 12.9657 10.6043 12.8981 10.6988C12.8312 10.7912 12.7377 10.8674 12.6265 10.9201C12.5154 10.9728 12.3903 11.0003 12.2632 11L3.73679 11C3.60985 11.0005 3.48488 10.9731 3.37384 10.9205C3.26279 10.8679 3.16939 10.7918 3.10256 10.6994C3.03466 10.605 2.99913 10.4961 3.00002 10.3851C3.0009 10.2742 3.03816 10.1658 3.10756 10.0721V10.0721Z'
        fill='var(--color-gray-90)'
      />
    </svg>
  );
}
