import React, { useState } from 'react';

import { Drawer } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useRecoilValue } from 'recoil';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { BohExitIcon } from 'src/app/components/icons/boh-exit-icon';
import { ChevronRight } from 'src/app/components/icons/chevron-right';
import { IconButton } from 'src/app/components/lib/icon-button';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { useBohNav } from 'src/app/hooks/use-boh-nav';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { userPermissionsAtom } from 'src/app/state/user-permissions';

import { DutchieLogoColor } from '../dutchie-logo-color';
import { NavigationIcon } from '../nav-icon';

import { LocationMenu } from './location-navigation';
import { SubNavigation } from './sub-navigation/index';

import type { DrawerProps } from '@mui/material';

type NestedNavigationMenuProps = {
  isOpen: boolean;
  onClose: DrawerProps['onClose'];
};

function NestedNavigationMenu({ isOpen, onClose }: NestedNavigationMenuProps) {
  const flags = useFlags();
  const links = useBohNav();

  const navigate = useNavigate();
  const { selectedLocation } = useRecoilValue(userDispensariesAtom);
  const { perms } = useRecoilValue(userPermissionsAtom);

  const [isSubNavOpen, setIsSubNavOpen] = useState(false);
  const [isLocationNavOpen, setIsLocationNavOpen] = useState(false);
  const [parentNavLabel, setParentNavLabel] = useState(null);

  const showLocationPublish = flags['pos-publish-locations'] && perms[Permissions.SuperUser];

  function handleClickNavigation(event, link) {
    const firstAvailableRoute: string = link?.subNavItems?.filter((navItem) => navItem.visible)[0].to;
    const navigationRoute: string = firstAvailableRoute ?? link.to;

    handleOnClose(event);
    navigate(navigationRoute);
  }

  function handleOnClose(event) {
    setParentNavLabel(null);
    setIsSubNavOpen(false);
    setIsLocationNavOpen(false);
    onClose(event, 'escapeKeyDown');
  }

  function handleBackAction() {
    setParentNavLabel(null);
    setIsSubNavOpen(false);
    setIsLocationNavOpen(false);
  }

  function handleSubNavigation(label) {
    setParentNavLabel(label);
    setIsSubNavOpen(true);
  }

  function handleLocationNavigation() {
    setIsLocationNavOpen(true);
  }

  const isParentNav = !isSubNavOpen && !isLocationNavOpen;

  return (
    <NestedNavigationMenuContainer
      $isOpen={isOpen}
      $isSubNavOpen={isSubNavOpen}
      anchor='left'
      ModalProps={{
        keepMounted: true,
      }}
      open={isOpen}
      onClose={onClose}
    >
      <MenuHeader>
        <DutchieLogoColor sizeRatio={1.36} />
        <IconButton onClick={(event) => onClose(event, 'escapeKeyDown')}>
          <BohExitIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
      </MenuHeader>
      <NavigationItems>
        <DirectoryNavigation>
          {links.map((link) => {
            const { visible, label } = link;
            const allSubNavItemsAreInvisible =
              Boolean(link.subNavItems) && !link.subNavItems?.some((navItem) => navItem.visible);

            return (
              visible &&
              !allSubNavItemsAreInvisible && (
                <NestedNavigationMenuItem
                  data-testid={`index_navigation-menu-item_${label}`}
                  key={label}
                  labelSecondary={<StyledChevronIcon />}
                  value={label}
                  variant='label'
                  onClick={(e) =>
                    label === 'Home' || label === 'Financials'
                      ? handleClickNavigation(e, link)
                      : handleSubNavigation(label)
                  }
                >
                  <NavigationIcon type={label} /> {label}
                </NestedNavigationMenuItem>
              )
            );
          })}
        </DirectoryNavigation>

        {isParentNav && (
          <LocationNavStickyFooter
            data-testid='index_location_navigation-sticky-footer'
            key='index_location_navigation-sticky-footer'
            onClick={() => handleLocationNavigation()}
          >
            <LabelContainer>
              <LabelAndTitleContainer>
                <LocationTitleContainer>
                  <LocationTitle>Location</LocationTitle>
                </LocationTitleContainer>
                <LocationLabelContainer>
                  <span>
                    {!selectedLocation.IsLive && showLocationPublish
                      ? `${selectedLocation.Name} - STAGING`
                      : selectedLocation.Name}
                  </span>
                </LocationLabelContainer>
              </LabelAndTitleContainer>
              <span>
                <StyledChevronIcon />
              </span>
            </LabelContainer>
          </LocationNavStickyFooter>
        )}
      </NavigationItems>
      <CSSTransition
        classNames='sub-navigation-panel'
        in={isSubNavOpen}
        key='sub-navigation-panel'
        mountOnEnter
        timeout={{ enter: 100, exit: 500 }}
        unmountOnExit
      >
        <SubNavigationPanel>
          <SubNavigation
            parentSection={parentNavLabel}
            onBackAction={handleBackAction}
            onClose={(event) => onClose(event, 'escapeKeyDown')}
            onSubNavClick={handleClickNavigation}
          />
        </SubNavigationPanel>
      </CSSTransition>
      <CSSTransition
        classNames='sub-navigation-panel'
        in={isLocationNavOpen}
        key='location-navigation-panel'
        mountOnEnter
        timeout={{ enter: 100, exit: 500 }}
        unmountOnExit
      >
        <SubNavigationPanel>
          <LocationMenu onBackAction={handleBackAction} onClose={handleOnClose} />
        </SubNavigationPanel>
      </CSSTransition>
    </NestedNavigationMenuContainer>
  );
}

export default NestedNavigationMenu;

const NestedNavigationMenuContainer = styled(Drawer)<{ $isOpen: boolean; $isSubNavOpen: boolean }>`
  .MuiDrawer-paperAnchorLeft {
    ${down('largeTablet')} {
      min-width: var(--navigation-drawer-width-tablet);
    }

    min-width: var(--navigation-drawer-width);
    transition: transform 175ms cubic-bezier(0.02, 0.01, 0.47, 1) !important;

    ${({ $isOpen }) => css`
      ${!$isOpen && closedMenu}

      ${$isOpen && openMenu}
    `}
  }

  .MuiBackdrop-root {
    background-color: rgba(11, 32, 51, 0.5);
  }

  .sub-navigation-panel {
    &-enter {
      opacity: 0;
      transform: translateX(-100%);
    }
    &-enter-done {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 300ms linear, transform 300ms ease-in;
    }
    &-exit {
      opacity: 1;
      transform: translateX(0%);
    }
    &-exit-active {
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 300ms linear, transform 300ms ease-in;
    }
  }
`;

const closedMenu = css`
  transform: translateX(calc(-1 * var(--drawer-width)));
`;

const openMenu = css`
  transform: none;
`;

const MenuHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 56px 32px 20px;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0;
    width: 20px;
    height: 20px;
    :focus {
      outline: 0;
    }
  }
`;

const DirectoryNavigation = styled.div``;

const NestedNavigationMenuItem = styled(MenuItem)`
  letter-spacing: var(--font-letter-spacing);
  color: var(--color-gray-90);

  span {
    display: inline-flex;
    align-items: center;
  }

  .menu-item--primary-label {
    svg {
      margin-right: var(--sizes-40);
    }
  }

  svg {
    path {
      fill: var(--color-gray-70);
    }
  }
`;

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 70px;

  overflow-y: scroll;

  /* hide scrollbar for most browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  li {
    font-size: 16px;
    padding: var(--sizes-40) var(--sizes-70);
  }
`;

const StyledChevronIcon = styled(ChevronRight)`
  display: flex;
  align-self: flex-end;
`;

const SubNavigationPanel = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  background-color: var(--color-brand-primary-white);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LocationNavStickyFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  background: var(--color-brand-primary-white);
  border-top: 1px solid #d2d7da;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
  gap: var(--sizes-50);
  color: var(--color-gray-90);
  padding: var(--sizes-40) var(--sizes-70);
  cursor: pointer;

  span {
    display: inline-flex;
    align-items: center;
  }

  svg {
    path {
      fill: var(--color-gray-70);
    }
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LabelAndTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LocationTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const LocationLabelContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

const LocationTitle = styled.span`
  margin-top: 1px;
  color: var(--color-gray-60);
  font: var(--font-mini-11pt-semibold);
  text-transform: uppercase;
  align-self: center;
`;
