import React from 'react';

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';

import { ControlBar } from 'src/app/components/lib/control-bar';
import { Modal, ModalContentText } from 'src/app/components/lib/modal';
import { Search } from 'src/app/components/lib/search';
import { ModalDataGrid } from 'src/app/components/lib/table';
import { LDExplorer } from 'src/app/constants/table-names';
import { useEnvironmentDetails } from 'src/app/queries/admin/get-environment-details';

import { transformToLDExplorerRow, useColumns } from './columns';
import { getLDEnv } from './get-ld-deets';

export function LDExplorerModal({ onClose }) {
  const [searchString, setSearchString] = React.useState('');
  const flags = useFlags();
  const client = useLDClient();
  const ldUser = client?.getContext();
  const { data } = useEnvironmentDetails();
  const columns = useColumns();
  const rows = transformToLDExplorerRow(flags);

  return (
    <Modal
      Content={
        <>
          <Container>
            <SubContainer>
              <h6>User</h6>
              <ModalContentText>
                <b>Email:</b> {ldUser?.email}
              </ModalContentText>
              <ModalContentText>
                <b>LSP:</b> {ldUser?.custom?.lspId}
              </ModalContentText>
              <ModalContentText>
                <b>LOC:</b> {ldUser?.custom?.locId}
              </ModalContentText>
              <ModalContentText>
                <b>Region:</b> {data?.Data?.Region}
              </ModalContentText>
            </SubContainer>
            <SubContainer>
              <h6>Env</h6>
              <ModalContentText>
                <b>Key:</b> {data?.Data?.LaunchDarklyClientKey}
              </ModalContentText>
              <ModalContentText>
                <b>Name:</b> {getLDEnv(data?.Data?.LaunchDarklyClientKey)}
              </ModalContentText>
            </SubContainer>
          </Container>

          <ControlBar margin='10px 0'>
            <Search
              placeholder='Search flags...'
              value={searchString}
              onChange={({ target }) => setSearchString(target.value)}
            />
          </ControlBar>
          <ModalDataGrid
            columns={columns}
            filterModel={{
              items: [{ columnField: 'name', operatorValue: 'contains', value: searchString }],
            }}
            name={LDExplorer}
            rows={rows}
          />
        </>
      }
      height='600px'
      isExpandable
      minWidth='800px'
      open
      title='LD Explorer'
      width='800px'
      onClose={onClose}
    />
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubContainer = styled.div`
  flex: 1;
  width: 50%;
  margin-bottom: 10px;
`;
