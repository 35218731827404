import { HardwareService } from '../HardwareService';
import { usePeripheral } from '../shared/usePeripherals';

import type { ReceiptPrinter } from './ReceiptPrinter';

export type ReceiptPrinterHook = {
  receiptPrinter: ReceiptPrinter;
  refresh: () => void;
}

export const useReceiptPrinter = (device: ReceiptPrinter): ReceiptPrinterHook => {
  const result = usePeripheral<ReceiptPrinter>(device, HardwareService.receiptPrinter);
  return {
    receiptPrinter: result.item.device,
    refresh: result.refresh,
  }
}
