import React from 'react';

type TrashCanProps = {
  height?: number; // Optional height prop
};

export function TrashCan({ height = 18 }: TrashCanProps) {
  const aspectRatio = 17 / 18; // Original width:height ratio
  const width = height * aspectRatio; // Calculate width based on height

  return (
    <svg fill='none' height={height} viewBox='0 0 17 18' width={width} xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M0.741699 4.24995C0.741699 3.90017 1.02525 3.61662 1.37503 3.61662H15.625C15.9748 3.61662 16.2584 3.90017 16.2584 4.24995C16.2584 4.59973 15.9748 4.88328 15.625 4.88328H1.37503C1.02525 4.88328 0.741699 4.59973 0.741699 4.24995Z'
        fill='var(--color-gray-90)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M6.9167 1.71662C6.66474 1.71662 6.42311 1.81671 6.24495 1.99487C6.06679 2.17303 5.9667 2.41466 5.9667 2.66662V3.61662H11.0334V2.66662C11.0334 2.41466 10.9333 2.17303 10.7551 1.99487C10.577 1.81671 10.3353 1.71662 10.0834 1.71662H6.9167ZM12.3 3.61662V2.66662C12.3 2.07872 12.0665 1.5149 11.6508 1.0992C11.2351 0.683492 10.6713 0.449951 10.0834 0.449951H6.9167C6.3288 0.449951 5.76498 0.683492 5.34928 1.0992C4.93357 1.5149 4.70003 2.07872 4.70003 2.66662V3.61662H2.95837C2.60859 3.61662 2.32503 3.90017 2.32503 4.24995V15.3333C2.32503 15.9212 2.55857 16.485 2.97428 16.9007C3.38998 17.3164 3.9538 17.5499 4.5417 17.5499H12.4584C13.0463 17.5499 13.6101 17.3164 14.0258 16.9007C14.4415 16.485 14.675 15.9212 14.675 15.3333V4.24995C14.675 3.90017 14.3915 3.61662 14.0417 3.61662H12.3ZM3.5917 4.88328V15.3333C3.5917 15.5852 3.69179 15.8269 3.86995 16.005C4.04811 16.1832 4.28974 16.2833 4.5417 16.2833H12.4584C12.7103 16.2833 12.952 16.1832 13.1301 16.005C13.3083 15.8269 13.4084 15.5852 13.4084 15.3333V4.88328H3.5917Z'
        fill='var(--color-gray-90)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M6.9167 7.57495C7.26648 7.57495 7.55003 7.8585 7.55003 8.20828V12.9583C7.55003 13.3081 7.26648 13.5916 6.9167 13.5916C6.56692 13.5916 6.28337 13.3081 6.28337 12.9583V8.20828C6.28337 7.8585 6.56692 7.57495 6.9167 7.57495Z'
        fill='var(--color-gray-90)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M10.0834 7.57495C10.4331 7.57495 10.7167 7.8585 10.7167 8.20828V12.9583C10.7167 13.3081 10.4331 13.5916 10.0834 13.5916C9.73359 13.5916 9.45003 13.3081 9.45003 12.9583V8.20828C9.45003 7.8585 9.73359 7.57495 10.0834 7.57495Z'
        fill='var(--color-gray-90)'
        fillRule='evenodd'
      />
    </svg>
  );
}
