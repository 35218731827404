import { Popover } from '@mui/material';
import styled from 'styled-components';

export const RebrandDatePicker = styled(Popover)`
  width: 310px;

  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    min-width: 310px;
    min-height: 200px;
    margin-top: var(--sizes-20);
    padding: var(--sizes-20);
  }
`;

export const RebrandDatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--sizes-10) var(--sizes-30) var(--sizes-30);
  font: var(--font-large-16pt-semibold);

  div > span {
    display: inline-block;
    margin-right: var(--sizes-10);
    cursor: pointer;
  }

  div > span,
  input[type='number'] {
    line-height: var(--sizes-60);
    -moz-appearance: textfield; /* remove spin buttons on Firefox */
  }

  input[type='number'] {
    width: 56px;
    margin-right: -16px; // pull right to offset width of spinner controls
    padding: 0 0 0 var(--sizes-10);
    border: 0;
    border-radius: 4px;
    color: var(--color-grayscale-black);
    font: var(--font-large-16pt-normal);

    &:hover,
    &:focus {
      background-color: var(--color-gray-10);
    }

    &:focus {
      outline: none;
    }
  }
`;

export const RebrandDatePickerMonths = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);

  .year {
    grid-column: span 3;
    padding: var(--sizes-10) 0 var(--sizes-30);
    line-height: var(--sizes-60);
    text-align: center;
  }

  .close {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    float: right;
    width: 16px;
    line-height: 1;
    opacity: 0.5;
  }

  .month-option {
    grid-column: span 1;
    padding: var(--sizes-30) 0;
    border-radius: 8px;
    font: var(--font-small-13pt-semibold);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: var(--color-blue-10);
    }

    &.current {
      background-color: var(--color-gray-10);
    }

    &.selected {
      color: var(--color-brand-primary-white);
      background-color: var(--color-brand-secondary-sapphire);
    }
  }
`;

export const RebrandDatePickerClearDate = styled.div`
  margin: var(--sizes-10) auto var(--sizes-20);
  color: var(--color-brand-secondary-sapphire);
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  &:hover {
    color: var(--color-blue-70);
  }
`;

export const RebrandContainer = styled.div`
  display: flex;
  width: 100%;
  padding: var(--sizes-20) var(--sizes-30);
  background-color: var(--color-gray-10);
  border-radius: 4px;

  input[type='time'] {
    width: 100%;
    height: var(--sizes-70);
    margin: 0;
    padding: 0 var(--sizes-40);
    border: 1px solid var(--color-gray-60);
    border-radius: 4px;
    font: var(--font-extra-small-12pt-normal);

    &:focus {
      border-color: var(--color-grayscale-black);
      outline: none;
    }
  }
`;

export const StyledYearPicker = styled(RebrandDatePickerHeader)`
  justify-content: center;
`;
