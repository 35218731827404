import { DataGridPro as MUIDataGrid } from '@mui/x-data-grid-pro';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

import type { DataGridContainerStyledProps, DataGridStyledProps } from './types';

export const RebrandDataGridContainer = styled.div<DataGridContainerStyledProps & { $darkMode: boolean }>`
  ${({ autoHeight, height }) =>
    autoHeight
      ? ``
      : css`
          height: ${height ?? '50vh'};
        `}
  width: 100%;
  min-width: ${({ minWidth }) => minWidth ?? 0};

  ${down('largeTablet')} {
    min-width: auto;
  }

  ${({ grow }) =>
    grow &&
    css`
      flex-grow: 1;
    `}

  ${({ hasRowClickHandler }) =>
    hasRowClickHandler &&
    css`
      .MuiDataGrid-row:hover {
        cursor: pointer;
      }
    `}

  .MuiDataGrid-root {
    min-height: ${({ minHeight }) => minHeight ?? '166px'};

    ${({ bulkActionsVisible }) =>
      bulkActionsVisible &&
      css`
        // Selects the Bulk Actions header component. It does not have a class/id/data attribute, so we have to do this
        & > div:first-child {
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 7; // below top nav and above row actions column
        }
      `}

    .MuiDataGrid-row.Mui-selected {
      ${({ highlightSelectedRow }) =>
        highlightSelectedRow &&
        css`
          background-color: var(--color-blue-10);
        `}
    }

    .MuiDataGrid-overlay {
      font: var(--font-large-16pt-normal);
    }
  }

  ${({ $darkMode, bulkActionsVisible }) =>
    $darkMode &&
    css`
      .MuiDataGrid-root {
        & > div:first-child {
          ${
            bulkActionsVisible &&
            css`
              background-color: var(--color-grayscale-black);
            `
          }
        }
    `}
`;

export const RebrandDataGrid = styled(MUIDataGrid)<DataGridStyledProps & { $darkMode: boolean }>`
  border: none;
  border-radius: 0;

  ${({ rows, displayBorder }) =>
    !!rows.length &&
    displayBorder &&
    css`
      .MuiDataGrid-main {
        border: 1px solid var(--color-gray-20);
        border-radius: 6px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
        overflow: hidden;
      }
    `}

  .MuiDataGrid-row {
    background-color: var(--color-brand-primary-white);
    &:last-of-type {
      .MuiDataGrid-cell {
        border-bottom: none;
      }
    }

    ${({ minRowHeight }) =>
      minRowHeight &&
      css`
        min-height: ${minRowHeight}px !important;
      `}
  }

  .MuiDataGrid-row:hover,
  .MuiDataGrid-row.Mui-hovered {
    background-color: ${({ disableRowHoverHighlight }) =>
      disableRowHoverHighlight ? 'inherit' : 'var(--color-gray-10)'};
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--color-brand-primary-white);
    white-space: nowrap;
    border-bottom: 1px solid var(--color-gray-20);
  }

  .MuiDataGrid-columnHeader--sortable:hover {
    background: var(--color-gray-10);
  }

  .MuiDataGrid-columnHeaderCheckbox:hover {
    background: transparent;
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-pinnedColumnHeaders {
    height: 40px;
    :focus,
    :focus-within {
      outline: none;
    }
    min-height: 40px;
  }

  ${({ $hasHorizontalScrollbar, $hasRowActions }) => css`
    ${$hasHorizontalScrollbar &&
    !$hasRowActions &&
    css`
      .MuiDataGrid-pinnedColumnHeaders {
        box-shadow: unset;
        background-color: var(--color-brand-primary-white);
      }

      .MuiDataGrid-pinnedColumnHeaders:after {
        content: '';
        position: absolute;
        width: 0px;
        height: 100%;
        top: 0px;
        right: 0px;
        z-index: 999999;
        background-color: var(--color-brand-primary-white);
        box-shadow: -2px 0px 8px 2px rgba(0, 0, 0, 0.1);
      }

      .MuiDataGrid-pinnedColumns--right {
        box-shadow: unset;
        background-color: transparent;

        .MuiDataGrid-row {
          background-color: transparent;
        }
      }

      .MuiDataGrid-pinnedColumns--right:after {
        content: '';
        position: absolute;
        width: 0px;
        height: 100%;
        top: 0px;
        right: 0px;
        z-index: 999999;
        background-color: var(--color-brand-primary-white);
        box-shadow: -2px 0px 8px 2px rgba(0, 0, 0, 0.1);
      }
    `}

    ${$hasHorizontalScrollbar &&
    $hasRowActions &&
    css`
      .MuiDataGrid-pinnedColumnHeaders {
        box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1);
        background-color: var(--color-brand-primary-white);
      }

      .MuiDataGrid-pinnedColumns {
        box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1);
      }
    `}

    ${!$hasHorizontalScrollbar &&
    css`
      .MuiDataGrid-pinnedColumnHeaders {
        box-shadow: unset;
        background-color: var(--color-brand-primary-white);
      }

      .MuiDataGrid-pinnedColumns {
        box-shadow: unset;
      }
    `}
  `}

  .MuiDataGrid-columnSeparator--sideRight {
    right: calc(var(--sizes-20) * -1);
  }

  .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }

  .MuiDataGrid-columnHeader:hover .MuiDataGrid-columnSeparator--resizable {
    visibility: visible;
  }

  .MuiDataGrid-columnHeader:active .MuiDataGrid-columnSeparator--resizable {
    & > svg {
      & rect {
        fill: var(--color-gray-90);
      }
      & path {
        fill: var(--color-gray-90);
      }
    }
  }

  /* actions column and header to have 8px of space on the right */
  [data-field='Actions'] {
    padding-right: var(--sizes-20);
    :hover {
      background: transparent;
    }
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;

    .MuiDataGrid-iconButtonContainer {
      margin-left: var(--sizes-10);
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    font: var(--font-mini-11pt-semibold);
    color: var(--color-gray-70);
    text-transform: uppercase;
    letter-spacing: 0.02rem;
  }

  /* column headers should be black when it is sorted */
  .MuiDataGrid-columnHeader--sorted
    > .MuiDataGrid-columnHeaderDraggableContainer
    > .MuiDataGrid-columnHeaderTitleContainer
    > .MuiDataGrid-columnHeaderTitleContainerContent
    > .MuiDataGrid-columnHeaderTitle {
    color: var(--color-grayscale-black);
  }

  .MuiDataGrid-cell {
    font: var(--font-small-13pt-normal);
    vertical-align: top;
    white-space: nowrap;
    color: var(--color-gray-90);
    border-bottom: 1px solid var(--color-gray-20);

    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  .MuiDataGrid-checkboxInput {
    height: 100%;
    width: 100%;
    border-radius: 0;

    &:hover {
      background-color: unset;
    }
  }

  .MuiIconButton-root {
    outline: none !important;
  }

  /* hide the number badges atop each column when multi-sorting */
  .MuiBadge-anchorOriginTopRight {
    display: none;
  }
  /* Utility classes to be applied to GridColDef['cellClassName'] */
  .data-grid--cell-normal {
    font: var(--font-small-13pt-normal);
  }

  .data-grid--cell-bold {
    font: var(--font-small-13pt-bold);
  }

  .data-grid--cell-semibold {
    font: var(--font-small-13pt-semibold);
  }

  /* Utility classes to be applied to GridColDef['headerClassName'] */
  .data-grid--header-regular .MuiDataGrid-columnHeaderTitle {
    font-weight: 400;
  }

  .data-grid--header-semibold .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }

  .data-grid--header-bold .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    .columnHeaderCheckboxHidden {
      opacity: 0;
    }
  }

  .cell--text-wrap {
    overflow-wrap: anywhere;
    text-overflow: unset;
    white-space: initial !important;
  }

  ${({ $darkMode, $hasHorizontalScrollbar, $hasRowActions, disableRowHoverHighlight }) =>
    $darkMode &&
    css`
      .MuiDataGrid-row {
        background-color: var(--color-grayscale-black);
        &.hover,
        &.Mui-hovered {
          background-color: ${disableRowHoverHighlight ? 'inherit' : 'var(--color-gray-100)'};
        }
      }

      .MuiDataGrid-cell {
        color: var(--color-gray-10);
      }

      .MuiDataGrid-columnHeaders {
        background-color: var(--color-grayscale-black);
      }

      .MuiDataGrid-pinnedColumns--right {
        background-color: var(--color-grayscale-black);
      }

      ${$hasHorizontalScrollbar &&
      !$hasRowActions &&
      css`
        .MuiDataGrid-pinnedColumnHeaders {
          background-color: var(--color-grayscale-black);
        }

        .MuiDataGrid-pinnedColumnHeaders:after {
          background-color: var(--color-grayscale-black);
          box-shadow: -2px 0px 8px 2px rgba(255, 255, 255, 0.1);
        }

        .MuiDataGrid-pinnedColumns--right:after {
          background-color: var(--color-grayscale-black);
          box-shadow: -2px 0px 8px 2px rgba(255, 255, 255, 0.1);
        }
      `}

      ${$hasHorizontalScrollbar &&
      $hasRowActions &&
      css`
        .MuiDataGrid-pinnedColumnHeaders {
          box-shadow: -2px 2px 8px rgba(255, 255, 255, 0.1);
          background-color: var(--color-grayscale-black);
        }

        .MuiDataGrid-pinnedColumns {
          box-shadow: -2px 2px 8px rgba(255, 255, 255, 0.1);
        }
      `}

    ${!$hasHorizontalScrollbar &&
      css`
        .MuiDataGrid-pinnedColumnHeaders {
          box-shadow: unset;
          background-color: var(--color-grayscale-black);
        }
      `}
      .MuiDataGrid-columnHeader--sorted
    > .MuiDataGrid-columnHeaderDraggableContainer
    > .MuiDataGrid-columnHeaderTitleContainer
    > .MuiDataGrid-columnHeaderTitleContainerContent
    > .MuiDataGrid-columnHeaderTitle {
        color: var(--color-brand-primary-white);
      }

      .MuiDataGrid-cell {
        color: var(--color-gray-50);
        border-color: var(--color-gray-100);
      }
    `}
`;
