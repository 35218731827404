import React from 'react';

import styled from 'styled-components';

import { Modal, ModalContentText } from 'src/app/components/lib/modal';

type PermissionDeniedModalProps = {
  onClose: () => void;
  open: boolean;
};

function PermissionDeniedModal({ open, onClose }: PermissionDeniedModalProps) {
  return (
    <Modal
      Content={
        <CenteredText>
          We're sorry, you don't have permission to access this page!
          <br />
          Please contact an administrator on your account to gain access.
        </CenteredText>
      }
      open={open}
      title='Access denied'
      onClose={onClose}
    />
  );
}

export default PermissionDeniedModal;

const CenteredText = styled(ModalContentText)`
  text-align: center;
`;
