import React from 'react';

import { Navigate } from 'react-router-dom';

import BlockSpinner from 'src/app/components/block/block-spinner';
import { Page } from 'src/app/components/lib/page';
import { useIdentifiedFlags } from 'src/app/hooks/use-identified-ld-flags';

type DarklyLaunchedComponentProps = { component: JSX.Element; fallback?: JSX.Element; flag: string };

export function DarklyLaunchedComponent({ component, fallback, flag }: DarklyLaunchedComponentProps) {
  const { identifiedFlags } = useIdentifiedFlags();

  const canViewComponent = identifiedFlags?.[flag];

  if (!identifiedFlags) {
    return (
      <Page>
        <BlockSpinner />
      </Page>
    );
  }
  if (!canViewComponent) {
    if (fallback) {
      return fallback;
    }
    return <Navigate replace to='/' />;
  }
  return component;
}
