import styled from 'styled-components';

import { LinkButton } from 'src/app/components/lib/button/link-button';
import { Type } from 'src/app/components/lib/type';

// wraps multiple notifications stacked in top right corner of the screen
export const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sizes-40);
  position: absolute;
  top: 80px;
  right: var(--sizes-70);
  z-index: 1301;

  .toast-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  .toast-enter-done {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }

  .toast-exit {
    opacity: 1;
    transform: translateX(0%);
  }

  .toast-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }
`;

// individual notification container that handles layout
export const NotificationContainer = styled.div`
  position: relative;
  display: flex;
  background-color: var(--color-grayscale-black);
  padding: var(--sizes-30) var(--sizes-40);
  border-radius: var(--sizes-20);
  gap: var(--sizes-40);
  justify-content: flex-start;
  align-items: center;
  min-height: 68px;
  max-width: 508px;
  min-width: 396px;
  box-shadow: 0px 6px var(--sizes-50) rgba(0, 0, 0, 0.2);
`;

export const Message = styled(Type)`
  color: var(--color-brand-primary-white);
`;

export const SubContent = styled.div`
  color: var(--color-gray-40);
  font: var(--font-regular-14pt-normal);
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const MoreInfoButton = styled(LinkButton)`
  background-color: transparent;
  color: var(--color-gray-40);
  padding: 0;
  justify-content: start;
  text-decoration: underline;
`;
