import { OutlinedInput as MUIOutlinedInput, Select as MUISelect } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledPlaceholder = styled.span`
  color: var(--color-gray-70);
`;

type StyledSelectInputProps = {
  $paddingRightInput?: string;
};

export const StyledOutlinedInput = styled(MUIOutlinedInput)<StyledSelectInputProps>`
  .MuiInputBase-input {
    padding: var(--sizes-30) var(--sizes-50);
    padding-right: ${({ $paddingRightInput }) => $paddingRightInput || '36px'} !important;
  }
`;

type StyledSelectProps = {
  $borderColor?: string;
  $darkMode: boolean;
  $hideBorder?: boolean;
};

export const StyledSelect = styled(MUISelect)<StyledSelectProps>`
  grid-column: 2 / span 3;
  background-color: transparent;
  color: var(--color-grayscale-black);
  min-height: unset;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: auto;

  .MuiSelect-icon {
    right: var(--sizes-50);
    top: calc(50% - 2px);
  }

  .MuiSelect-select {
    min-height: unset;
  }

  legend {
    width: auto;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: var(--sizes-0) var(--sizes-50);
    border: 1px solid var(--color-gray-40);
    border-radius: 6px;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    outline: 0;
    border: 1px solid var(--color-grayscale-black);
  }

  &.Mui-focused .MuiSelect-icon {
    path {
      fill: var(--color-grayscale-black);
    }
  }

  &.Mui-disabled .MuiSelect-icon {
    path {
      fill: var(--color-gray-60);
    }
  }

  &.Mui-disabled {
    background-color: var(--color-gray-10);
    :hover {
      cursor: not-allowed;
    }
  }

  ${({ $darkMode, $hideBorder }) =>
    $darkMode &&
    css`
      color: var(--color-brand-primary-white);
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-gray-80);
      }
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          ${!$hideBorder &&
          css`
            border-color: var(--color-brand-primary-white);
          `}
        }
      }
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        ${!$hideBorder &&
        css`
          border-color: var(--color-brand-primary-white);
        `}
      }
      &.Mui-focused .MuiSelect-icon {
        path {
          fill: var(--color-brand-primary-white);
        }
      }
    `}
`;
