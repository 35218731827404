import React from 'react';

export function SuperuserIcon(): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M22.8608 18.1561C22.8617 18.1547 22.8626 18.1534 22.8634 18.152C22.874 18.135 22.8845 18.118 22.895 18.101C22.9012 18.0908 22.9074 18.0807 22.9136 18.0706C22.9883 17.9484 23.0607 17.8248 23.1308 17.6996C23.2533 17.4921 23.2456 17.2548 23.1231 17.0547C22.9394 16.7433 22.5336 16.6247 22.2121 16.8026C22.1054 16.8587 21.9979 16.9136 21.8898 16.9673C21.8781 16.9731 21.8665 16.9788 21.8548 16.9846C21.8366 16.9936 21.8184 17.0025 21.8002 17.0114C21.7986 17.0121 21.7971 17.0129 21.7956 17.0136C20.6735 17.5611 19.4807 17.9764 18.3304 18.2334C16.8527 18.5596 15.3368 18.6708 13.8285 18.5596C11.2943 18.352 8.83665 17.2252 7.14462 15.3718C7.10263 15.3266 7.06223 15.2793 7.02179 15.232C6.86316 15.0462 6.70415 14.8601 6.4479 14.801C5.93493 14.6898 5.5751 15.0308 5.4526 15.483C4.20463 19.9682 8.44618 24.2457 13.0935 23.875C17.0928 23.641 20.7977 21.4664 22.8608 18.1561ZM6.73261 17.0775C6.73061 18.3195 7.25322 19.5108 8.15642 20.4738C9.35566 21.7525 11.1419 22.5259 12.9742 22.3798L12.99 22.3785L13.0059 22.3776C15.6469 22.223 18.1344 21.1066 19.9726 19.3456C19.5327 19.4824 19.0928 19.6 18.6575 19.6973L18.6537 19.6982C17.0352 20.0554 15.3732 20.1776 13.7182 20.0555L13.706 20.0546L13.706 20.0546C11.1513 19.8453 8.64161 18.8147 6.73261 17.0775Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12.6395 0.00213321C12.6331 0.00201472 12.6267 0.00190116 12.6204 0.00179252C12.5747 0.00101291 12.5289 0.000487057 12.4832 0.000216032C12.4704 0.000140518 12.4577 8.47859e-05 12.445 4.88595e-05C12.3321 -0.000269267 12.219 0.000965568 12.1059 0.0037693C11.8609 0.0111828 11.6465 0.1298 11.5317 0.329963C11.3403 0.633915 11.4398 1.04165 11.7537 1.21958C11.8842 1.29763 12.0137 1.3776 12.142 1.45936C12.1447 1.46109 12.1474 1.46282 12.1501 1.46454C12.1682 1.4761 12.1863 1.4877 12.2044 1.49933C12.2057 1.50018 12.207 1.50104 12.2084 1.50189C13.2368 2.16423 14.1885 2.94073 14.977 3.76239C16.0106 4.83734 16.8681 6.05315 17.5265 7.37275C18.606 9.6042 18.8204 12.2212 18.0165 14.5712C17.9968 14.6284 17.9747 14.685 17.9527 14.7414C17.8636 14.97 17.7759 15.1948 17.8557 15.4386C18.0165 15.9205 18.4912 16.0539 18.9659 15.9353C23.6055 14.7343 25.3052 9.04819 22.6485 5.33405C20.4347 2.09022 16.6347 0.0764902 12.6395 0.00213321ZM19.7285 14.032C22.394 12.5655 23.289 8.8077 21.4285 6.20672L21.4189 6.19327L21.4096 6.17961C19.9495 4.04025 17.6847 2.50124 15.1461 1.84806C15.4664 2.13163 15.7717 2.42416 16.0588 2.72329C17.1968 3.90692 18.1423 5.24713 18.8687 6.70304L18.8728 6.71125L18.8768 6.71951C19.9651 8.96914 20.297 11.573 19.7285 14.032Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12.655 9.99969L12.6534 9.99946C12.08 9.91821 11.5216 10.0553 11.0808 10.3711L11.0736 10.3763L11.0735 10.3763C10.8451 10.538 10.662 10.7374 10.5266 10.9669L10.523 10.973L10.523 10.973C10.1249 11.6403 10.1724 12.4631 10.6457 13.0782L10.6486 13.082L10.6486 13.082C10.9742 13.5081 11.455 13.7931 12.0101 13.8717C12.5834 13.953 13.1418 13.8159 13.5826 13.5001L13.5899 13.4949L13.5899 13.4949C13.8183 13.3332 14.0014 13.1338 14.1368 12.9043L14.1405 12.8982C14.5385 12.2309 14.491 11.4081 14.0178 10.793L14.0133 10.7872L14.0089 10.7814C13.6948 10.3647 13.2196 10.079 12.655 9.99969ZM12.8638 8.51429C11.9221 8.38085 10.9727 8.60325 10.2071 9.15185C9.80899 9.43356 9.47977 9.7894 9.23477 10.2046C8.52274 11.3981 8.60696 12.8882 9.4568 13.9928C10.0234 14.7342 10.8579 15.2235 11.7996 15.3569C12.7413 15.4904 13.6907 15.2679 14.4563 14.7194C14.8544 14.4376 15.1837 14.0818 15.4287 13.6666C16.1407 12.4731 16.0565 10.983 15.2066 9.87836C14.6477 9.13702 13.8132 8.64773 12.8638 8.51429Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M10.4704 4.6659C9.35073 4.06651 8.02603 3.92083 6.7258 4.19496C4.96004 4.56724 3.39535 5.68498 2.62839 7.25879L2.62132 7.27332L2.61393 7.28769C1.42119 9.60698 1.18997 12.2933 1.90254 14.7484C2.0046 14.2743 2.12721 13.8102 2.26945 13.3618M1.54412 17.7083C1.54365 17.7074 1.54317 17.7066 1.5427 17.7058C-0.415443 14.3088 -0.516637 10.0953 1.27998 6.60169C3.2706 2.51687 9.21183 1.09349 12.6035 4.38506C12.9557 4.71867 13.0706 5.18571 12.7184 5.5638C12.5382 5.75034 12.2873 5.79036 12.0355 5.83053C11.977 5.83985 11.9185 5.84917 11.8609 5.86034C9.3573 6.35704 7.12168 7.84714 5.65934 9.87102C4.80185 11.0868 4.14341 12.4138 3.69935 13.815C3.30889 15.0456 3.0792 16.4245 3.04092 17.7886C3.04092 18.1444 2.73467 18.4261 2.36717 18.4261C2.12217 18.4261 1.91545 18.3149 1.7853 18.1074C1.71864 18.002 1.65384 17.8957 1.59089 17.7886C1.5828 17.7748 1.57473 17.761 1.5667 17.7472C1.55915 17.7342 1.55162 17.7213 1.54412 17.7083ZM10.4704 4.6659C8.03407 5.41319 5.903 6.9726 4.44351 8.99252L4.43849 8.99947L4.43355 9.00648C3.48794 10.3472 2.76046 11.8127 2.2696 13.3613M3.05302 17.3056C3.05307 17.3056 3.05312 17.3057 3.05316 17.3058L3.05302 17.3056Z'
        // eslint-disable-next-line max-len
        fill='var(--color-gray-70)'
        fillRule='evenodd'
      />
    </svg>
  );
}
