import zxcvbn from 'zxcvbn';

export default {
  checkComplexity(password, passwordSettings) {
    const checker = zxcvbn(password);
    const strength = checker.score;
    const { warning } = checker.feedback;
    const suggestion = checker.feedback.suggestions;
    const passwordLength = passwordSettings.PasswordLengthEnabled ? passwordSettings.PasswordLength : 10;

    if (password.length < passwordLength) {
      const lengthError = {
        idStatus: 'auth__error',
        notification: `Password does not meet ${passwordLength} character length requirement`,
      };

      return lengthError;
    }

    if (strength < 3 && warning == '') {
      const strengthErrorSuggestion = {
        idStatus: 'auth__error',
        notification: `${suggestion} Password is invalid.`,
      };

      return strengthErrorSuggestion;
    }

    if (strength < 3 && warning != '') {
      const strengthErrorWarning = {
        idStatus: 'auth__error',
        notification: `${warning}. Password is invalid.`,
      };

      return strengthErrorWarning;
    }

    return null;
  },

  checkMatching(password, confirmPassword) {
    if (password != confirmPassword) {
      const matchError = {
        idStatus: 'auth__error',
        notification: 'Passwords do not match',
      };

      return matchError;
    }

    return null;
  },

  getPasswordStrength(password) {
    if (password) {
      const checker = zxcvbn(password);
      return checker.score;
    }
    return 0;
  },

  getPasswordStrengthLabel(score) {
    switch (score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  },
};
