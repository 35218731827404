import { EventEmitter } from 'events';

import _ from 'lodash';

import { actions as AppEvents } from '../constants/AppConstants';
import { actions as SettingsActions, events as SettingEvents } from '../constants/SettingsConstants';
import AppDispatcher from '../utils/AppDispatcher';

class SettingsStore extends EventEmitter {
  private readonly dispatchToken = null;

  private _state = {
    LabelPrinterId: null,
    RowsPerTable: 100,
    PaletteMode: 'light',
    editorOpen: false,
    MetrcKey: null,
  };

  constructor() {
    super();

    this.handleAction = this.handleAction.bind(this);
    this.dispatchToken = AppDispatcher.register(this.handleAction);
  }

  getState() {
    return this._state;
  }

  setPrinter(id) {
    this._state.LabelPrinterId = id;
  }

  handleAction(action: any) {
    switch (action.actionType) {
      case AppEvents.APP_BOOTED:
        this._state = { ...this._state, ...action.data.settings };
        this.emit(SettingEvents.EDITOR_EVENT);
        break;
      case SettingsActions.OPEN_EDITOR:
        this._state.editorOpen = true;
        this.emit(SettingEvents.EDITOR_EVENT);
        break;
      case SettingsActions.CLOSE_EDITOR:
        this._state.editorOpen = false;
        this.emit(SettingEvents.EDITOR_EVENT);
        break;
      case SettingsActions.SET_PRINTER:
        this._state.LabelPrinterId = parseInt(action.data);
        this.emit(SettingEvents.CHANGE_EVENT);
        break;

      case SettingsActions.SET_ROWS_PER_PAGE:
        this._state.RowsPerTable = parseInt(action.data);
        this.emit(SettingEvents.CHANGE_EVENT);
        break;

      case SettingsActions.SINGLE_SETTING_SAVED:
        for (let i = 0; i < action.data.Settings.length; i++) {
          const setting = action.data.Settings[i];
          this._state[setting.Setting] = setting.Value;
        }
        this._state.MetrcKey = action.data.MetrcKey;

        this.emit(SettingEvents.CHANGE_EVENT);
        break;
    }
  }
}

const settings = new SettingsStore();
export default settings;
