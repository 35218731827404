import _ from 'lodash';
import { SimpleCachedDataStore } from './SimpleCachedDataStore';

class AccountingStore extends SimpleCachedDataStore {
  constructor() {
    super();
  }
  entity = 'Accounts';
  sourceUrl = 'api/quickbooks/get-accounts';

  get accounts() {
    return this.data;
  }

  emitChange(change) {
    super.emitChange(change);
  }
}

export default new AccountingStore();
