import React from 'react';

import { GridCell } from '@mui/x-data-grid-pro';

import type { GridCellProps } from '@mui/x-data-grid-pro';

export function CustomCell(props: GridCellProps) {
  const { value, width } = props;
  return <GridCell {...props} title={width === 500 ? value : undefined} />;
}
