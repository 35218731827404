import React, { useRef } from 'react';

import { RebrandTab, SelectedTabIndicator, TabContainer } from './tab-rebrand.styles';

import type { TabProps as MUITabProps } from '@mui/material';

type TabProps = {
  badge?: React.ReactElement;
  label?: string;
  selected?: boolean;
};

const getInnerWidth = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref.current) {
    const styles = getComputedStyle(ref.current);
    const paddingX = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
    return ref.current.offsetWidth - paddingX + 1;
  }
  return 1;
};

export function Tab<T extends React.ElementType>(props: MUITabProps<T> & TabProps) {
  const ref = useRef<HTMLDivElement>(null);

  const { label, selected, badge } = props;

  const width = getInnerWidth(ref);

  return (
    <TabContainer>
      <RebrandTab {...props} icon={badge} iconPosition='end' label={label} ref={ref} />
      {selected && <SelectedTabIndicator $width={width} />}
    </TabContainer>
  );
}
