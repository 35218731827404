import type { ReactEventHandler } from 'react';
import React, { useState } from 'react';

import {
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
  gridRowCountSelector,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { hasIn } from 'lodash';
import styled from 'styled-components';

import { RebrandCheckbox } from 'src/app/components/lib/checkbox';
import { Input } from 'src/app/components/lib/input';
import { Menu } from 'src/app/components/lib/menu';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { ModalContentText, Modal } from 'src/app/components/lib/modal';
import { omit } from 'src/app/utils/omit';

import type { CheckboxProps } from '@mui/material';
import type { GridRowId } from '@mui/x-data-grid-pro';

const columnHeaderClassName = 'MuiDataGrid-columnHeaderTitleContainer';

function isInHeader(event) {
  return event.nativeEvent
    .composedPath()
    .map((element) => element.className)
    .some((classes) => classes?.includes(columnHeaderClassName));
}

type CustomCheckboxProps = {
  checked?: boolean;
  className?: string;
  inputProps?: CheckboxProps['inputProps'];
  isBulkActionVisible?: boolean;
  onChange?: (e: ReactEventHandler) => void;
  onKeyDown?: (e: ReactEventHandler) => void;
  paginationMode?: 'client' | 'server';
};

export const CustomCheckbox = React.forwardRef<HTMLInputElement, CustomCheckboxProps>((props, ref) => {
  const { inputProps, onChange, onKeyDown, checked, isBulkActionVisible, className, paginationMode } = props;

  const apiRef = useGridApiContext();
  const clientPaginatedVisibleRowIds: GridRowId[] = useGridSelector(
    apiRef,
    gridPaginatedVisibleSortedGridRowIdsSelector
  );
  const serverPaginatedVisibleRowIds: GridRowId[] = useGridSelector(apiRef, gridFilteredSortedRowIdsSelector);
  const filteredSortedRowIds: GridRowId[] = useGridSelector(apiRef, gridFilteredSortedRowIdsSelector);
  const selectedRows = apiRef.current.getSelectedRows();
  const totalRowCount: number = useGridSelector(apiRef, gridRowCountSelector);
  const isHeaderCheckbox = hasIn(props, 'indeterminate');
  const shouldShowIndeterminate = selectedRows.size;
  const shouldShowChecked = selectedRows.size === totalRowCount && totalRowCount > 0;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isSelectQuantityModalOpen, setIisSelectQuantityModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const isMenuOpen = Boolean(anchorEl);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const openModal = () => setIisSelectQuantityModalOpen(true);
  const closeModal = () => setIisSelectQuantityModalOpen(false);

  const handleOnChange = (event) => {
    const inHeader = isInHeader(event) || isHeaderCheckbox;

    if (inHeader) {
      openMenu(event);
    } else {
      onChange(event);
    }
  };

  const handleOnKeyDown = (event) => {
    const inHeader = isInHeader(event) || isHeaderCheckbox;

    if (inHeader && (event.key === ' ' || event.code === 'Space' || event.keyCode === 32)) {
      openMenu(event);
    } else {
      onKeyDown(event);
    }
  };

  const handleQuantityChange = (event) => {
    // limiting input to the size of the row array, primarily to address cases of server-side pagination where only the current page of rows exists
    const allRowsLength = filteredSortedRowIds.length;
    const qty = event.target.value > allRowsLength ? allRowsLength : event.target.value;
    setQuantity(qty);
  };

  const handleSelectAll = () => {
    const allIds = apiRef.current.getAllRowIds();
    apiRef.current.setSelectionModel(allIds);
    closeMenu();
  };

  const handleSelectPage = () => {
    const rowIdsToSelect = paginationMode === 'server' ? serverPaginatedVisibleRowIds : clientPaginatedVisibleRowIds;
    apiRef.current.selectRows(rowIdsToSelect);
    closeMenu();
  };

  const handleSelectQuantity = () => {
    openModal();
    closeMenu();
  };

  const selectQuantity = () => {
    apiRef.current.selectRows(filteredSortedRowIds.slice(0, quantity));
    closeModal();
  };

  const clearSelection = () => {
    apiRef.current.setSelectionModel([]);
    closeMenu();
  };

  return (
    <>
      {/* https://github.com/mui/material-ui/issues/18874 */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RebrandCheckbox
        {...omit(props, ['isBulkActionVisible', 'paginationMode'])}
        checked={isHeaderCheckbox ? shouldShowChecked : checked}
        className={isBulkActionVisible ? `${className ?? ''} columnHeaderCheckboxHidden` : className}
        id='checkbox_data-grid-row'
        indeterminate={isHeaderCheckbox ? !shouldShowChecked && !!shouldShowIndeterminate : undefined}
        // https://github.com/mui/material-ui/issues/18874
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputProps={{ ...inputProps, 'data-testid': 'table-bulk-select-checkbox' }}
        ref={ref}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />

      {isMenuOpen && (
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenu}>
          {paginationMode !== 'server' && (
            <MenuItem automationId='table-bulk-select-menu-select-all' value='select-all' onClick={handleSelectAll}>
              Select all
            </MenuItem>
          )}
          <MenuItem automationId='table-bulk-select-menu-select-page' value='select-page' onClick={handleSelectPage}>
            Select page
          </MenuItem>
          <MenuItem
            automationId='table-bulk-select-menu-select-a-quantity'
            value='select-quantity'
            onClick={handleSelectQuantity}
          >
            Select a quantity
          </MenuItem>
          <MenuItem automationId='table-bulk-select-menu-select-none' value='select-none' onClick={clearSelection}>
            Select none
          </MenuItem>
        </Menu>
      )}

      <Modal
        Content={
          <>
            <StyledModalContentText>Number of rows:</StyledModalContentText>
            <Input
              autoFocus
              type='number'
              value={quantity || null}
              onChange={handleQuantityChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  selectQuantity();
                }
              }}
            />
          </>
        }
        open={isSelectQuantityModalOpen}
        primaryActions={[{ label: 'Save', onClick: selectQuantity }]}
        title='Select quantity'
        onClose={closeModal}
      />
    </>
  );
});

const StyledModalContentText = styled(ModalContentText)`
  margin-bottom: 13px;
`;
