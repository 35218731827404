import { useEffect, useState } from "react";


import { HardwareService } from "../HardwareService";
import type { PeripheralFilter, PeripheralSearchConfig } from "../shared";
import { usePeripherals } from "../shared/usePeripherals";

import type { Scanner } from "./Scanner";
import type { BarcodeData } from "./types";

export type DeviceBarcode = {
  barcode: BarcodeData;
  device: Scanner
}

export type ScannersHook = {
  scanners: Scanner[];
  refresh: () => void;
  search: (props?: Partial<PeripheralSearchConfig>) => void;
  deviceBarcode: DeviceBarcode | undefined;
}

export type ScannersHookProps = {
  filter?: PeripheralFilter<Scanner>;
  onBarcode?: (deviceBarcode: DeviceBarcode) => void
}

export const useScanners = (props?: ScannersHookProps): ScannersHook => {
  const [deviceBarcode, setDeviceBarcode] = useState<DeviceBarcode | undefined>(undefined);

  const result = usePeripherals<Scanner>({
    service: HardwareService.scanner,
    filter: props?.filter
  });

  useEffect(() => {
    const handleBarcode = (evt: Event) => {
      const event = evt as CustomEvent<DeviceBarcode>;
      setDeviceBarcode(event.detail);
      props?.onBarcode?.(event.detail);
    };

    HardwareService.scanner.addEventListener('barcode', handleBarcode);
    return () => {
      HardwareService.scanner.removeEventListener('barcode', handleBarcode);
    }
  }, [props]);
  
  return {
    scanners: result.devices,
    refresh: result.refresh,
    search: result.search,
    deviceBarcode,
  }
}
