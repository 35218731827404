import React from 'react';

import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { ForgotPassword } from './forgot-password';
import { Login } from './login';
import { Marketing } from './marketing';
import { SSOLogin } from './sso-login';

import type { LoginMutationProps } from 'src/app/queries/posv3/user/employee-login';

export function Auth({
  showFullPageMigrationTakeover,
}: {
  showFullPageMigrationTakeover: LoginMutationProps['showFullPageMigrationTakeover'];
}) {
  const LoginComponent = <Login showFullPageMigrationTakeover={showFullPageMigrationTakeover} />;
  const SSOLoginComponent = <SSOLogin showFullPageMigrationTakeover={showFullPageMigrationTakeover} />;
  const disableLoginWithPassword = useLDFlag(LD.DISABLE_USER_PASSWORD_LOGIN, false);
  const Base = disableLoginWithPassword ? SSOLoginComponent : LoginComponent;

  return (
    <PageContainer>
      <Marketing />
      <AuthHalf>
        <Routes>
          <Route element={Base} path='login' />
          <Route element={SSOLoginComponent} path='sso' />
          <Route element={SSOLoginComponent} path='ssologin' />
          <Route element={SSOLoginComponent} path='sso-login' />
          <Route element={<ForgotPassword />} path='forgot-password' />
          <Route element={Base} path='*' />
        </Routes>
      </AuthHalf>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--color-gray-10);
`;

const AuthHalf = styled.div`
  height: 100vh;
  width: 56%;
  background: var(--color-brand-primary-white);
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1500px) {
    width: 53%;
  }

  @media only screen and (max-width: 1350px) {
    width: 50%;
  }
`;
