import React, { useEffect } from 'react';

import styled from 'styled-components';

import PasswordCheck from 'src/app_deprecated/utils/PasswordCheck';

import { CheckIcon } from '../icons/check-icon';

type PasswordPolicyProps = {
  onPasswordPolicyValidation?: (valid: boolean) => void;
  password?: string;
  passwordPolicyLength?: number;
  username?: string;
};

export function PasswordPolicy({
  password,
  username,
  passwordPolicyLength,
  onPasswordPolicyValidation,
}: PasswordPolicyProps) {
  const isLongEnoughRegex = new RegExp(`^.{${passwordPolicyLength || 8},}$`);
  const isLongEnoughValid = isLongEnoughRegex.test(password || '');
  const hasUppercaseValid = /[A-Z]/.test(password || '');
  const hasLowercaseValid = /[a-z]/.test(password || '');
  const hasNumberValid = /[0-9]/.test(password || '');
  const isNotSameAsUserValid = password !== username;
  const complexityError = PasswordCheck.checkComplexity(password || '', {
    PasswordLengthEnabled: passwordPolicyLength !== undefined,
    PasswordLength: passwordPolicyLength || 8,
  });

  useEffect(() => {
    if (onPasswordPolicyValidation === undefined) {
      return;
    }
    onPasswordPolicyValidation(
      isLongEnoughValid &&
        hasUppercaseValid &&
        hasLowercaseValid &&
        hasNumberValid &&
        isNotSameAsUserValid &&
        complexityError === null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <ContainerDiv>
      <PointerDiv>
        <PointerInnerDiv>
          <svg fill='none' height='15' viewBox='0 0 8 15' width='8' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0.5 7.24048L7.5 0.240479L7.5 14.2405L0.5 7.24048Z' fill='var(--color-grayscale-black)' />
          </svg>
        </PointerInnerDiv>
      </PointerDiv>
      <Title>Password must:</Title>
      <FrameDiv>
        <CheckIcon checked={isLongEnoughValid} dataTestId='is-long-enough' />
        <OptionText>Be at least {passwordPolicyLength} characters long</OptionText>
      </FrameDiv>
      <FrameDiv>
        <CheckIcon checked={hasUppercaseValid} dataTestId='has-uppercase' />
        <OptionText>Have at least one uppercase letter</OptionText>
      </FrameDiv>
      <FrameDiv>
        <CheckIcon checked={hasLowercaseValid} dataTestId='has-lowercase' />
        <OptionText>Have at least one lowercase letter</OptionText>
      </FrameDiv>
      <FrameDiv>
        <CheckIcon checked={hasNumberValid} dataTestId='has-number' />
        <OptionText>Have at least one number</OptionText>
      </FrameDiv>
      {username && (
        <FrameDiv>
          <CheckIcon checked={isNotSameAsUserValid} dataTestId='is-not-same-as-user' />
          <OptionText>Be different from the username</OptionText>
        </FrameDiv>
      )}
      <FrameDiv>
        <CheckIcon checked={complexityError === null} dataTestId='is-complex-enough' />
        <OptionText>
          Complexity
          {complexityError === null || complexityError?.notification.includes('length requirement')
            ? ''
            : `: ${complexityError?.notification || ''}`}
        </OptionText>
      </FrameDiv>
    </ContainerDiv>
  );
}

const ContainerDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 213px;
  height: 217.48px;
  background: var(--color-grayscale-black);
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  left: 100%;
  margin-top: 80px;
  margin-left: 12px;
`;

const Title = styled.div`
  width: 187.38px;
  height: 18.48px;
  padding-top: 0px;
  position: absolute;
  top: 20px;

  font-family: 'Matter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const FrameDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 199px;
  height: 10px;

  flex-flow: row;
  order: 1;
  flex-grow: 0;
`;

const OptionText = styled.p`
  width: 171px;
  height: 14px;
  left: 18px;
  top: 0px;

  /* Tokens/font-size-20 */
  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height, or 127% */

  /* Primary/White */
  color: #ffffff;
`;

const PointerDiv = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 100%;
  top: 25%;
`;

const PointerInnerDiv = styled.div`
  width: 8px;
  height: 15px;
  padding-left: 13px;
`;
