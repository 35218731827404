/* eslint-disable max-len */
import React from 'react';

export function DutchieLogoColor({ sizeRatio = 1, className = '' }): JSX.Element {
  const height = 25 * sizeRatio;
  const width = 106 * sizeRatio;

  return (
    <svg
      className={className}
      fill='none'
      height={`${height}`}
      viewBox='0 0 106 25'
      width={`${width}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.5291 17.5776C21.271 18.2601 19.9043 18.7719 18.592 19.0744C17.0932 19.4156 15.5557 19.5319 14.0259 19.4156C11.4555 19.1985 8.96279 18.0196 7.24662 16.0808C7.03695 15.8481 6.86611 15.5611 6.53996 15.4836C6.01968 15.3673 5.6547 15.724 5.53046 16.1971C4.26469 20.8891 8.56675 25.364 13.2804 24.9763C17.5203 24.7203 21.4341 22.2464 23.4609 18.516C23.5852 18.2988 23.5774 18.0507 23.4531 17.8413C23.2668 17.5155 22.8552 17.3914 22.5291 17.5776Z'
        fill='var(--color-brand-primary-flora)'
      />
      <path
        d='M11.9214 1.27584C13.1406 2.02812 14.2744 2.95102 15.1907 3.93596C16.239 5.0605 17.1088 6.3324 17.7766 7.71287C18.8715 10.0473 19.0889 12.7849 18.2736 15.2434C18.1726 15.5459 18.0095 15.8328 18.1105 16.1508C18.2736 16.6549 18.755 16.7945 19.2365 16.6704C23.9424 15.414 25.6663 9.4656 22.9717 5.58012C20.6265 2.03587 16.5263 -0.104633 12.2786 0.00394318C12.0301 0.0116986 11.8127 0.135788 11.6962 0.345185C11.5021 0.663159 11.603 1.08971 11.9214 1.27584Z'
        fill='var(--color-brand-primary-flora)'
      />
      <path
        d='M13.0474 8.90714C12.0922 8.76755 11.1293 9.00021 10.3528 9.57411C9.94897 9.86882 9.61505 10.2411 9.36656 10.6754C8.64437 11.924 8.72979 13.4829 9.59176 14.6384C10.1664 15.414 11.0128 15.9258 11.968 16.0654C12.9231 16.205 13.8861 15.9724 14.6626 15.3985C15.0664 15.1037 15.4003 14.7315 15.6488 14.2972C16.371 13.0486 16.2856 11.4897 15.4236 10.3341C14.8567 9.5586 14.0103 9.04674 13.0474 8.90714Z'
        fill='var(--color-brand-primary-flora)'
      />
      <path
        d='M12.0301 6.13064C12.3407 6.0686 12.6747 6.06084 12.8999 5.82042C13.2571 5.42489 13.1406 4.9363 12.7834 4.5873C9.34327 1.14388 3.31727 2.63293 1.29825 6.90619C-0.596526 10.7064 -0.410152 15.3209 1.81077 18.9426C1.94278 19.1598 2.15245 19.2761 2.40094 19.2761C2.77369 19.2761 3.08431 18.9814 3.08431 18.6092C3.12313 17.1822 3.3561 15.7396 3.75214 14.4522C4.20253 12.9865 4.87036 11.5982 5.74009 10.3263C7.2233 8.2091 9.49081 6.65026 12.0301 6.13064Z'
        fill='var(--color-brand-primary-flora)'
      />
      <path
        d='M31.0788 13.4212C31.0788 9.82272 33.1599 7.34872 36.6466 7.34872C38.1221 7.34872 39.3801 7.95365 40.0634 8.84553V3.11425C40.0634 2.92812 40.211 2.78076 40.3973 2.78076H42.6649C42.8512 2.78076 42.9988 2.92812 42.9988 3.11425V18.9741C42.9988 19.1603 42.8512 19.3076 42.6649 19.3076H40.3973C40.211 19.3076 40.0634 19.1603 40.0634 18.9741V17.9582C39.481 18.8888 38.1687 19.5015 36.6932 19.5015C33.1599 19.4938 31.0788 17.0198 31.0788 13.4212ZM40.242 13.4212C40.242 11.2963 38.9996 9.89251 37.1048 9.89251C35.2333 9.89251 33.9908 11.2963 33.9908 13.4212C33.9908 15.5462 35.2333 16.95 37.1048 16.95C38.9996 16.95 40.242 15.5462 40.242 13.4212Z'
        fill='var(--color-opal-90)'
      />
      <path
        d='M55.2449 19.3071H52.9774C52.791 19.3071 52.6435 19.1597 52.6435 18.9736V17.5388C51.898 18.6866 50.5623 19.501 48.9005 19.501C46.3534 19.501 44.5984 17.818 44.5984 15.251V7.88329C44.5984 7.69716 44.746 7.5498 44.9324 7.5498H47.1999C47.3863 7.5498 47.5338 7.69716 47.5338 7.88329V14.5763C47.5338 15.8481 48.5666 16.9106 49.8401 16.8874C51.6262 16.8564 52.6357 15.3208 52.6357 12.7072V7.88329C52.6357 7.69716 52.7832 7.5498 52.9696 7.5498H55.2371C55.4235 7.5498 55.571 7.69716 55.571 7.88329V18.9814C55.5788 19.1597 55.4313 19.3071 55.2449 19.3071Z'
        fill='var(--color-opal-90)'
      />
      <path
        d='M62.7153 19.4937C60.3313 19.4937 58.553 18.2063 58.553 15.4531V10.0165H56.9067C56.7204 10.0165 56.5728 9.86918 56.5728 9.68305V7.88379C56.5728 7.69766 56.7204 7.5503 56.9067 7.5503H58.553V4.43261C58.553 4.24647 58.7005 4.09912 58.8869 4.09912H61.1544C61.3408 4.09912 61.4883 4.24647 61.4883 4.43261V7.5503H64.1209C64.3072 7.5503 64.4548 7.69766 64.4548 7.88379V9.68305C64.4548 9.86918 64.3072 10.0165 64.1209 10.0165H61.4883V15.1817C61.4883 16.3062 62.0941 16.9111 63.0104 16.9111C63.321 16.9111 63.6705 16.8801 63.9966 16.7793C64.1985 16.7172 64.4082 16.8568 64.4237 17.0662L64.579 18.9276C64.5945 19.0904 64.4936 19.2378 64.3383 19.2765C63.8335 19.4239 63.2899 19.4937 62.7153 19.4937Z'
        fill='var(--color-opal-90)'
      />
      <path
        d='M64.9595 13.4207C64.9595 9.82214 67.4134 7.34814 70.9467 7.34814C73.8277 7.34814 75.8934 8.77515 76.4214 11.0708C76.4602 11.2569 76.3438 11.443 76.1574 11.4741L73.9287 11.9084C73.7578 11.9394 73.5948 11.8386 73.5482 11.6679C73.2376 10.5977 72.3057 9.89194 71.0477 9.89194C69.2228 9.89194 68.0347 11.2724 68.0347 13.4207C68.0347 15.5689 69.2306 16.9494 71.0477 16.9494C72.3135 16.9494 73.191 16.3212 73.5404 15.2354C73.5948 15.0726 73.7578 14.964 73.9287 15.0028L76.1574 15.4526C76.3438 15.4914 76.468 15.6775 76.4214 15.8636C75.8468 18.136 73.7811 19.4932 70.9545 19.4932C67.4134 19.4932 64.9595 17.0192 64.9595 13.4207Z'
        fill='var(--color-opal-90)'
      />
      <path
        d='M77.9744 2.77246H80.2419C80.4283 2.77246 80.5758 2.91981 80.5758 3.10595V9.35684C81.3446 8.06944 82.6803 7.34818 84.3188 7.34818C86.9591 7.34818 88.7141 9.03111 88.7141 11.5982V18.9659C88.7141 19.152 88.5665 19.2993 88.3802 19.2993H86.1126C85.9263 19.2993 85.7787 19.152 85.7787 18.9659V12.3194C85.7787 11.0398 84.7537 9.96953 83.4724 9.96177C81.6319 9.94626 80.5914 11.5129 80.5914 14.1885V18.9659C80.5914 19.152 80.4438 19.2993 80.2575 19.2993H77.9899C77.8035 19.2993 77.656 19.152 77.656 18.9659V3.1137C77.6327 2.92757 77.788 2.77246 77.9744 2.77246Z'
        fill='var(--color-opal-90)'
      />
      <path
        d='M89.7935 4.18403C89.7935 3.18358 90.5623 2.38477 91.6184 2.38477C92.6978 2.38477 93.4899 3.17582 93.4899 4.18403C93.4899 5.20775 92.6978 6.00657 91.6184 6.00657C90.5701 6.00657 89.7935 5.21551 89.7935 4.18403ZM90.4846 7.53439H92.7521C92.9385 7.53439 93.0861 7.68174 93.0861 7.86787V18.9659C93.0861 19.1521 92.9385 19.2994 92.7521 19.2994H90.4846C90.2983 19.2994 90.1507 19.1521 90.1507 18.9659V7.87563C90.143 7.6895 90.2983 7.53439 90.4846 7.53439Z'
        fill='var(--color-opal-90)'
      />
      <path
        d='M105.643 14.1962H97.2095C97.4658 15.9722 98.6151 17.0192 100.292 17.0192C101.558 17.0192 102.49 16.4686 102.871 15.4991C102.933 15.344 103.104 15.2587 103.267 15.3053L105.371 15.8714C105.565 15.9257 105.674 16.1273 105.604 16.3135C104.843 18.3221 102.863 19.4932 100.246 19.4932C96.3398 19.4932 94.2587 16.4841 94.2587 13.4207C94.2587 10.3573 96.1767 7.34814 100.083 7.34814C104.059 7.34814 106 10.2875 106 13.0251C106 13.3043 105.992 13.6223 105.977 13.8782C105.969 14.0566 105.821 14.1962 105.643 14.1962ZM102.847 12.048C102.707 10.6442 101.791 9.59723 100.091 9.59723C98.6384 9.59723 97.6133 10.4581 97.2872 12.048H102.847Z'
        fill='var(--color-opal-90)'
      />
    </svg>
  );
}
