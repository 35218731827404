import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { SegmentsList } from './segment-list';
import { SegmentDetail } from './segment-tabs';

export function Segments() {
  return (
    <Routes>
      <Route element={<SegmentsList />} path='/' />
      <Route element={<SegmentDetail />} path=':segmentId/*' />
    </Routes>
  );
}
