import React from 'react';

import type { CheckProps } from './rebrand-check';

export function RebrandUncheck(props: CheckProps): JSX.Element {
  const { fill = 'white', disabled = false } = props;
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <rect
        fill={disabled ? 'var(--color-gray-50)' : fill}
        height='15'
        rx='2.5'
        stroke={disabled ? 'var(--color-gray-50)' : 'var(--color-gray-60)'}
        width='15'
        x='0.5'
        y='0.5'
      />
    </svg>
  );
}
