import { useQuery } from '@tanstack/react-query';

import { postData } from 'src/app/queries';
import { useNotificationActions } from 'src/app/state/notifications';

import { segmentDetailKeys } from './query-key-factory';

import type { CustomerSegmentSchemaMap } from 'src/app/pages/customers/customers/segments/details/segment-query-builder/schema';
import type { RuleGroup } from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';

type useGetSegmentDetailPayload = {
  segmentId: number | null;
};

const GET_SEGMENT_DETAILS = 'api/segment/get-segment-details';

export type SegmentDetailsResponse = {
  customerSegmentSchema: CustomerSegmentSchemaMap;
  isSegmentInactive: boolean;
  segmentDetails?: RuleGroup;
  segmentName?: string;
};

export function useGetSegmentDetailsQuery({ segmentId }: useGetSegmentDetailPayload) {
  const notifications = useNotificationActions();
  return useQuery({
    queryKey: segmentDetailKeys.one(segmentId),
    queryFn: () =>
      postData<useGetSegmentDetailPayload, SegmentDetailsResponse>({
        endpoint: GET_SEGMENT_DETAILS,
        payload: { segmentId },
      }),
    onError: () => {
      notifications.error({ message: 'Error fetching segment details' });
    },
    refetchOnWindowFocus: false,
  });
}
