import { TextField as MUIInput } from '@mui/material';
import styled, { css } from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 2 / span 2;
`;

export const RebrandInput = styled(MUIInput)<{
  $darkMode: boolean;
  $fullWidth?: boolean;
  $gridColumn?: string;
  $width?: string;
  multiline?: boolean;
}>`
  grid-column: ${({ $fullWidth }) => ($fullWidth ? 'span 6' : '2 / span 3')};
  background-color: transparent;
  width: ${({ $width }) => $width ?? '100%'};
  border-radius: 6px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  .MuiOutlinedInput-notchedOutline {
    padding: var(--sizes-0) var(--sizes-50);
    border: 1px solid var(--color-gray-40);
    border-radius: 6px;
  }

  .MuiInputBase-input {
    height: auto;
    color: var(--color-grayscale-black);

    ::placeholder {
      color: var(--color-gray-70);
      opacity: 1;
    }
  }

  .MuiInputBase-multiline {
    height: auto;
    padding: var(--sizes-40) var(--sizes-50);

    textarea {
      padding: 0;
    }
  }

  .MuiInputBase-adornedStart,
  .MuiInputBase-adornedEnd {
    padding-left: var(--sizes-50);
    padding-right: var(--sizes-50);

    .MuiInputBase-input {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .MuiInputAdornment-root {
    .MuiTypography-root {
      color: var(--color-gray-60);
    }

    .MuiSelect-icon {
      right: var(--sizes-30);
    }
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--color-grayscale-black);
  }

  & .MuiInputAdornment-positionEnd .MuiTypography-root {
    font: var(--font-extra-small-12pt-bold);
  }

  & .Mui-focused .MuiInputAdornment-root {
    .MuiTypography-root {
      color: var(--color-grayscale-black);
    }
  }

  .Mui-disabled {
    background-color: var(--color-gray-10);
    border-radius: 6px;
    :hover {
      cursor: not-allowed;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  legend {
    width: 0;
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-gray-80);
      }

      .MuiInputBase-input {
        color: var(--color-brand-primary-white);
      }

      &:hover {
        div:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline {
          border-color: var(--color-brand-primary-white);
        }
      }

      & .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-brand-primary-white);
      }

      .Mui-disabled {
        background-color: var(--color-gray-100);
        -webkit-text-fill-color: var(--color-gray-70);

        .MuiOutlinedInput-notchedOutline {
          border-color: var(--color-gray-80);
        }
      }
    `}
`;
