import React from 'react';

import { SvgIcon } from '@mui/material';

import type { SvgIconProps } from '@mui/material';

type ExitIconProps = SvgIconProps & {
  className?: string;
  color?: string;
  size?: string;
};

export function BohExitIcon({ className, color, size, ...other }: ExitIconProps): JSX.Element {
  return (
    <SvgIcon
      {...other}
      className={className}
      fill={color ?? 'none'}
      height={size ?? '16'}
      inheritViewBox
      viewBox='0 0 16 16'
      width={size ?? '16'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        // eslint-disable-next-line max-len
        d='M0.322408 15.557C-0.115937 15.1013 -0.106065 14.3723 0.344458 13.9289L14.0682 0.420606C14.5187 -0.0228444 15.2392 -0.0128575 15.6776 0.442913C16.1159 0.898684 16.1061 1.62765 15.6555 2.0711L1.93185 15.5794C1.48132 16.0228 0.760753 16.0128 0.322408 15.557Z'
        fill={color ?? 'var(--color-gray-70)'}
      />
      <path
        // eslint-disable-next-line max-len
        d='M15.5745 15.6628C15.1301 16.1124 14.4094 16.1124 13.965 15.6628L0.425467 1.96558C-0.0190085 1.51592 -0.0190088 0.786892 0.425467 0.33724C0.869943 -0.112413 1.59058 -0.112413 2.03506 0.33724L15.5745 14.0344C16.019 14.4841 16.019 15.2131 15.5745 15.6628Z'
        fill={color ?? 'var(--color-gray-70)'}
      />
    </SvgIcon>
  );
}
