import React from 'react';

import { useDarkMode } from 'src/app/state/dark-mode';

import { RebrandDivider } from './divider-rebrand.styles';

type DividerProps = {
  className?: string;
  variant?:
    | 'below-header'
    | 'compact'
    | 'end-of-form'
    | 'form-section-divider'
    | 'modal-form-divider'
    | 'vertical-flex';
  width?: string;
};

export function Divider(props: DividerProps) {
  const darkMode = useDarkMode();
  const { variant = 'end-of-form', className, width, ...other } = props;
  return <RebrandDivider {...other} $darkMode={darkMode} $variant={variant} $width={width} className={className} />;
}
