import React, { useEffect, useRef, useState } from 'react';

import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { ChevronLeft } from 'src/app/components/icons/chevron-left';
import { Tooltip } from 'src/app/components/lib/tooltip';
import { useDarkMode } from 'src/app/state/dark-mode';

import { useHeightOffset } from '../header/banners/use-height-offset';
import { SubNavigation } from '../sub-navigation';

type MinimizableSidebarType = {
  parentSectionFromPath: string;
};

export function MinimizableSidebar({ parentSectionFromPath }: MinimizableSidebarType) {
  const darkMode = useDarkMode();
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [isSidebarHoverOpen, setIsSidebarHoverOpen] = useState(false);
  const [showMinimizeIcon, setShowMinimizeIcon] = useState(true);
  const subNavRef = useRef(null);
  const subNavHoverRef = useRef(null);
  const heightOffset = useHeightOffset();

  const hoverExpand = isSidebarMinimized && isSidebarHoverOpen;
  const expand = !isSidebarMinimized && !hoverExpand;
  const minimized = isSidebarMinimized && !expand && !hoverExpand;

  // handles the opening overlay of he sidebar when a user hovers over the minimize icon
  if (isSidebarMinimized && !isSidebarHoverOpen) {
    const minimizedSidebarElement = document.querySelector('#minimized-sidebar');
    minimizedSidebarElement?.addEventListener('mouseover', (event) => {
      if (event.currentTarget === event.target) {
        const timer = window.setTimeout(() => {
          setIsSidebarHoverOpen(true);
        }, 100);

        minimizedSidebarElement.addEventListener('mouseout', () => {
          window.clearTimeout(timer);
        });
      }
    });
  }

  // handles the automatic closing of the hover state of the sidebar when a user hovers off of the side bar
  const pageContentElement = document.querySelector('.rebrand_container');
  if (isSidebarHoverOpen && pageContentElement && isSidebarMinimized) {
    pageContentElement.addEventListener('mouseover', () => {
      const timer = window.setTimeout(() => {
        setIsSidebarHoverOpen(false);
      }, 200);

      pageContentElement.addEventListener('mouseout', () => {
        window.clearTimeout(timer);
      });
    });
  }

  useEffect(() => {
    // the minimize icon and functionality should not be present for the homepage
    if (parentSectionFromPath === 'Home' || parentSectionFromPath === '') {
      setShowMinimizeIcon(false);
    } else {
      setShowMinimizeIcon(true);
    }

    // reset the isSidebarMinimized and isSidebarHoverOpen values when the sub route changes
    setIsSidebarMinimized(false);
    setIsSidebarHoverOpen(false);
  }, [parentSectionFromPath]);

  return (
    <div>
      <MinimizedBarSpacer />
      <SidebarWrapper
        $darkMode={darkMode}
        $heightOffset={heightOffset}
        $isExpanded={expand}
        $isMinimized={minimized}
        $isOpenOnHover={hoverExpand}
      >
        <MinimizeContainer
          $darkMode={darkMode}
          $isExpanded={expand}
          $isMinimized={minimized}
          $isOpenOnHover={hoverExpand}
          id='minimized-sidebar'
        >
          {showMinimizeIcon && (
            <Tooltip enterDelay={1000} placement='right' title={isSidebarMinimized ? 'Expand' : 'Collapse'}>
              <MinimizeIcon
                $darkMode={darkMode}
                id='minimize-sidebar-icon'
                onClick={() => {
                  setIsSidebarHoverOpen(false);
                  setIsSidebarMinimized(!isSidebarMinimized);
                }}
              >
                <ChevronLeft />
              </MinimizeIcon>
            </Tooltip>
          )}

          <CSSTransition
            classNames='sub-navigation-panel-hover'
            in={hoverExpand}
            key='sub-navigation-panel-hover'
            mountOnEnter
            nodeRef={subNavHoverRef}
            timeout={{ enter: 100, exit: 200 }}
            unmountOnExit
            onExited={() => {
              setIsSidebarHoverOpen(false);
            }}
          >
            <SubNavigation id='sub-navigation-hover' parentSection={parentSectionFromPath} />
          </CSSTransition>

          <CSSTransition
            classNames='sub-navigation-panel'
            in={expand}
            key='sub-navigation-panel'
            mountOnEnter
            nodeRef={subNavRef}
            timeout={{ enter: 200, exit: 200 }}
            unmountOnExit
            onExiting={() => {
              setIsSidebarHoverOpen(false);
              setIsSidebarMinimized(true);
            }}
          >
            <SubNavigation id='sub-navigation' parentSection={parentSectionFromPath} />
          </CSSTransition>
        </MinimizeContainer>
      </SidebarWrapper>
    </div>
  );
}

const MinimizeIcon = styled.div<{ $darkMode: boolean }>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: var(--color-brand-primary-white);
  border: 1px solid #d2d7da;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  width: 26px;
  height: 26px;
  z-index: 12;
  top: var(--sizes-50);
  right: -12px;
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.2, 0, 0, 1) 0s;

  path {
    fill: var(--color-gray-80);
  }

  :hover {
    cursor: pointer;
    border: 1px solid var(--color-gray-60);
    transition: border 100ms ease;

    path {
      fill: var(--color-grayscale-black);
    }
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background-color: var(--color-grayscale-black);
      border-color: var(--color-gray-80);
      :hover {
        border-color: var(--color-gray-50);
        path {
          fill: var(--color-gray-50);
        }
      }
    `}
`;

const MinimizedBarSpacer = styled.div`
  position: relative;
  width: var(--sizes-50);
  visibility: hidden;
`;

const minimizedStyles = css`
  width: var(--sizes-50);
  min-width: var(--sizes-50);
  transition: width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;

  ${MinimizeIcon} {
    transform: rotate(180deg);
    opacity: 1;
    transition: opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  .sub-navigation-panel,
  .sub-navigation-panel-hover {
    visibility: hidden;
  }
`;

const expandedStyles = css`
  width: 216px;
  transition: width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

const hoverOpenStyles = (darkMode: boolean) => css`
  position: fixed;
  z-index: 11;
  min-width: 216px;
  border-right: 1px solid #d2d7da;
  box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.13);
  transition: min-width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;

  ${MinimizeIcon} {
    transform: rotate(180deg);
    opacity: 1;
    transition: opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  ${darkMode &&
  css`
    border-right-color: var(--color-gray-80);
  `}
`;

const SidebarWrapper = styled.div<{
  $darkMode: boolean;
  $heightOffset: string;
  $isExpanded?: boolean;
  $isMinimized?: boolean;
  $isOpenOnHover: boolean;
}>`
  position: relative;
  height: calc(100vh - ${({ $heightOffset }) => $heightOffset});

  ${({ $darkMode, $isMinimized, $isOpenOnHover, $isExpanded }) => css`
    ${$isOpenOnHover && hoverOpenStyles($darkMode)}
    ${$isMinimized && !$isOpenOnHover && minimizedStyles};
    ${$isExpanded && expandedStyles}
  `}

  :hover {
    ${MinimizeIcon} {
      opacity: 1;
      transition: opacity 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
  }
`;

const MinimizeContainer = styled.div<{
  $darkMode: boolean;
  $isExpanded: boolean;
  $isMinimized: boolean;
  $isOpenOnHover: boolean;
}>`
  background-color: var(--color-gray-10);
  height: 100%;

  ${({ $isMinimized, $isOpenOnHover }) => css`
    ${$isOpenOnHover &&
    css`
      min-width: 216px;
      transition: min-width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
    `}
    ${$isMinimized &&
    css`
      min-width: var(--sizes-50);
      transition: min-width 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    `}
  `}

  .sub-navigation-panel-hover {
    &-enter {
      width: 0%;
      opacity: 0.5;
      visibility: visible;
    }
    &-enter-done {
      width: 100%;
      visibility: visible;
      opacity: 1;
      transition: opacity 100ms linear, width 100ms cubic-bezier(0.2, 0, 0, 1) 0s,
        visibility 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
    &-exit-active {
      width: 100%;
      opacity: 1;
      visibility: hidden;
    }
    &-exit-done {
      opacity: 0;
      width: 0%;
      visibility: hidden;
      transition: opacity 100ms linear, width 100ms cubic-bezier(0.2, 0, 0, 1) 0s,
        visibility 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
  }

  .sub-navigation-panel {
    &-enter-active {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      visibility: visible;
    }
    &-enter-done {
      position: absolute;
      top: 0;
      left: 0;
    }
    &-exit-active {
      visibility: hidden;
      width: 0;
      transition: width 100ms cubic-bezier(0.2, 0, 0, 1) 0s, visibility 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
    &-exit-done {
      visibility: hidden;
      opacity: 0;
      width: 0;
      transition: opacity 100ms linear, width 200ms cubic-bezier(0.2, 0, 0, 1) 0s,
        visibility 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
  }
  ${({ $darkMode }) => css`
    ${$darkMode &&
    css`
      background-color: var(--color-gray-100);
    `}
  `}
`;
