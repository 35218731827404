import React, { useState, useCallback } from 'react';

import { TabScrollButton, type TabsProps as MUITabsProps } from '@mui/material';

import { RebrandTabs, TabSliderTrack, TabsContainer } from './tabs-rebrand.styles';

type TabsProps = {
  children?: MUITabsProps['children'];
  hideTrack?: boolean;
  marginBottom?: '32px' | '48px';
  onChange?: MUITabsProps['onChange'];
  orientation?: MUITabsProps['orientation'];
  scrollButtons?: MUITabsProps['scrollButtons'];
  sx?: MUITabsProps['sx'];
  value?: MUITabsProps['value'];
  variant?: MUITabsProps['variant'];
};

export function Tabs(props: TabsProps) {
  const [scrollButtonsVisible, setScrollButtonsVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { variant, marginBottom, orientation, value, children, onChange, scrollButtons, sx, hideTrack } = props;

  const mountedRef = useCallback(
    (node) => {
      if (node !== null && windowWidth) {
        setScrollButtonsVisible(Boolean(document.querySelector('.MuiTabs-scrollButtons')));
      }
    },
    [windowWidth]
  );

  function watchWindowSize() {
    setWindowWidth(window.innerWidth);
  }

  window.onresize = watchWindowSize;

  const ScrollButtonComponent = useCallback((props) => <TabScrollButton {...props} ref={mountedRef} />, [mountedRef]);

  return (
    <TabsContainer $marginBottom={marginBottom} $variant={variant}>
      <RebrandTabs
        $scrollButtonsVisible={!!scrollButtonsVisible}
        $variant={variant}
        children={children}
        orientation={orientation}
        ScrollButtonComponent={ScrollButtonComponent}
        scrollButtons={scrollButtons}
        sx={sx}
        value={value ?? null}
        variant='scrollable'
        onChange={onChange}
      />
      {hideTrack ? null : <TabSliderTrack />}
    </TabsContainer>
  );
}
