import { useQuery } from '@tanstack/react-query';

import { fetchDataWithUserId } from '..';

export const GET_USER_SETTINGS = 'api/posv3/user/GetUserSettings';

export const userSettingsQueryKey = ['user-settings'];

export type UserSetting = {
  Setting: string;
  SettingType: string; // Usually `table`
  Value: string; // This is a stringified JSON object
};

export function useUserSettingsQuery() {
  return useQuery({
    queryKey: userSettingsQueryKey,
    queryFn: async () => fetchDataWithUserId<UserSetting[]>(GET_USER_SETTINGS),
    staleTime: Infinity,
  });
}
