import React, { useState } from 'react';

import { FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { Modal } from 'src/app/components/lib/modal';

type AddReasonModalProps = {
  isReason: boolean;
  onAddReason: (reason: string, isReason: boolean) => void;
  onClose: () => void;
  open: boolean;
  reason?: string;
  title: string;
};

function AddReasonModal({ open, onAddReason, onClose, title, reason, isReason }: AddReasonModalProps) {
  const [newReason, setReason] = useState(reason ?? '');

  return (
    <Modal
      Content={
        <FormSection>
          <Input
            automationId='plant-stages_dr_reason-code'
            label='Reason:'
            value={newReason}
            onChange={({ target }) => {
              setReason(target.value);
            }}
          />
        </FormSection>
      }
      open={open}
      primaryActions={[
        {
          label: reason ? 'Update' : 'Add',
          onClick: () => {
            onAddReason(newReason, isReason);
            setReason('');
            onClose();
          },
          disabled: newReason.trim().length === 0,
          automationId: 'plant-stages_button_create',
        },
      ]}
      title={title}
      onClose={onClose}
    />
  );
}

export default AddReasonModal;
