import React, { useState, useMemo } from 'react';

import { DropdownInputMultiple } from 'src/app/components/lib/dropdown/input';
import { DropdownMenuMultiple } from 'src/app/components/lib/dropdown/menu';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown/menu';
import type { DropdownBaseProps } from 'src/app/components/lib/dropdown/types';

export type DropdownMultipleProps = DropdownBaseProps & {
  automationId?: string;
  disabled?: boolean;
  disableFooter?: boolean;
  disableImmediateSelection?: boolean;
  footerContent?: React.ReactNode;
  loading?: boolean;
  menuAutomationId?: string;
  searchAutomationId?: string;
  selectAllButtonAutomationId?: string;
  /**
   * If true the selected values will be sorted to the top of the menu. Don't use for large sets of options as it can become slow for a user
   */
  selectedToTop?: boolean;
  selectionName?: string;
  selectionText?: string;
  selectNoneButtonAutomationId?: string;
  setValue: (selection: DropdownMenuOption[]) => void;
  sortOptions?: boolean;
  value: DropdownMenuOption[];
  width?: string;
  withinModal?: boolean;
};

export function DropdownMultiple({
  automationId = '',
  className,
  disableFooter = false,
  disableImmediateSelection = false,
  disabled = false,
  footerContent,
  gridColumns,
  helpText,
  inputId,
  label,
  labelPlacement = 'start',
  loading,
  menuAutomationId,
  menuWidth = '396px',
  options,
  required = false,
  searchAutomationId,
  selectAllButtonAutomationId,
  selectionName = '',
  selectNoneButtonAutomationId,
  selectionText,
  setValue,
  sortOptions = true,
  sx,
  tooltip,
  truncateLabel = true,
  value,
  width = '100%',
  paginationProps,
  onSearchInputChange,
  errorMessage,
  withinModal,
  selectedToTop = false,
}: DropdownMultipleProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const optionsToDisplay = useMemo(() => {
    const sortedAlphabetically = sortOptions ? options.sort((a, b) => a.label?.localeCompare(b.label)) : options;
    if (!selectedToTop) {
      return sortedAlphabetically;
    }
    const sortedBySelection = sortedAlphabetically.sort((a, b) => {
      const foundA = value.findIndex((val) => val.label === a.label);
      const foundB = value.findIndex((val) => val.label === b.label);
      if (foundA >= 0 && foundB < 0) {
        return -1;
      }
      return 0;
    });
    return sortedBySelection;
  }, [sortOptions, options, selectedToTop, value]);
  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (newValue) => {
    // newValue is not passed in if you open the menu
    // then click away without selecting an item
    if (newValue) {
      setValue(newValue);
    }

    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  const handleOnChange = (event, newValue: DropdownMenuOption[]) => {
    if (!disableImmediateSelection) {
      setValue(newValue);
    }
  };

  const open = Boolean(anchorEl);

  const getIdFromLabel = (originalLabel: React.ReactElement | string): string => {
    if (typeof originalLabel === 'string') {
      return originalLabel;
    }
    if (typeof originalLabel?.props?.children === 'string') {
      return originalLabel.props.children;
    }
    if (Array.isArray(originalLabel?.props?.children)) {
      return originalLabel.props.children.filter((child) => typeof child === 'string').join('');
    }
    return '';
  };

  const idFromLabel = getIdFromLabel(label);
  const id = inputId ?? idFromLabel ?? 'dropdown-multiple-noLabel';

  return (
    <>
      <DropdownInputMultiple
        automationId={automationId}
        className={className}
        disabled={disabled}
        errorMessage={errorMessage}
        gridColumns={gridColumns}
        handleClick={handleInputClick}
        helpText={helpText}
        id={id}
        label={label}
        labelPlacement={labelPlacement}
        loading={loading}
        required={required}
        selectionName={selectionName}
        selectionText={selectionText}
        sx={sx}
        tooltip={tooltip}
        value={value}
        width={width}
      />
      <DropdownMenuMultiple
        anchorEl={anchorEl}
        automationId={menuAutomationId}
        disableFooter={disableFooter}
        footerContent={footerContent}
        handleClose={handleMenuClose}
        id={id}
        open={open}
        options={optionsToDisplay}
        paginationProps={paginationProps}
        placement='bottom-start'
        searchAutomationId={searchAutomationId}
        selectAllButtonAutomationId={selectAllButtonAutomationId}
        selectNoneButtonAutomationId={selectNoneButtonAutomationId}
        truncateLabel={truncateLabel}
        value={value}
        width={menuWidth}
        withinModal={withinModal}
        onChange={handleOnChange}
        onSearchInputChange={onSearchInputChange}
      />
    </>
  );
}
