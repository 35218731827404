import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ResultType } from 'src/app/hooks/global-search/use-global-search';

import type { SearchResult as SearchResultType } from 'src/app/hooks/global-search/use-global-search';

type SearchResultProps = {
  onClick: (e: React.MouseEvent) => void;
  searchResult: SearchResultType;
};
export function SearchResult(props: SearchResultProps) {
  const { searchResult, onClick } = props;

  return (
    <StyledLink to={searchResult.to}>
      <Container onClick={onClick}>
        <ResultInfo>
          <MainTitle>{searchResult.primaryDisplayName}</MainTitle>
          <SubTitle>{getSubTitleString(searchResult)}</SubTitle>
        </ResultInfo>
        <ResultTypeDisplay>{searchResult.resultType}</ResultTypeDisplay>
      </Container>
    </StyledLink>
  );
}

const getSubTitleString = (searchResult: SearchResultType) => {
  const { resultType, metadata } = searchResult;
  const { parentNav, brandName, quantity, room, packageID, SKU, reportCategory } = metadata;
  switch (resultType) {
    case ResultType.PAGE:
      return `In ${parentNav}`;
    case ResultType.INVENTORY_ITEM:
      return [brandName, quantity && `Qty: ${quantity}${room ? ` in ${room}` : ''}`, `Package: ${packageID}`]
        .filter(Boolean)
        .join(' | ');
    case ResultType.PRODUCT:
      return [brandName, SKU && `SKU: ${SKU}`].filter(Boolean).join(' | ');
    case ResultType.REPORT:
      return `In Reports > ${reportCategory}`;
    default:
      return '';
  }
};

const StyledLink = styled(Link)`
  color: var(--color-grayscale-black);
  width: 100%;
`;
const Container = styled.div`
  padding: var(--sizes-50) var(--sizes-60);
  display: flex;
  justify-content: space-between;

  :hover {
    background-color: #f5f5f5;
  }
`;
const ResultInfo = styled.div`
  font: var(--font-regular-14pt-semibold);
  display: flex;
  flex-direction: column;
  gap: var(--sizes-10);
`;
const ResultTypeDisplay = styled.div`
  text-transform: uppercase;
  color: var(--color-gray-50);
  font-weight: var(--typography-headings-heading-1-font-weight);
  font-size: var(--typography-extra-small-12pt-normal-font-size);
  white-space: nowrap;
`;
const MainTitle = styled.p``;
const SubTitle = styled.p`
  color: var(--color-gray-70);
  font-weight: 400 !important; // TODO why won't this apply properly w/o !important ?
`;
