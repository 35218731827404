import React from 'react';

import { BannerContainer, BannerCopy } from 'src/app/components/banner/banner.styles';
import { Loader } from 'src/app/components/lib/loader';

export function SegmentInProgress() {
  return (
    <div>
      <BannerContainer>
        <Loader size='xs' variant='blue' />
        <BannerCopy>
          <strong>Audience update in progress.</strong> Editing is temporarily disabled until the update completes.{' '}
        </BannerCopy>
      </BannerContainer>
    </div>
  );
}
