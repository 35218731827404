import i18next from 'i18next';
import moment from 'moment';
import uuid from 'uuid/v1';

import { translateMessageWithPipe } from 'src/app/components/lib/utils';

import { actions as ActionTypes } from '../constants/NotificationConstants';
import AppDispatcher from '../utils/AppDispatcher';

export default {
  addNotification: (note) => {
    note.uuid = uuid();
    note.show = true;
    note.timestamp = moment();
    note.additionalInfo = false;
    note.isLegacy = true;

    if (!note.hasOwnProperty('type')) {
      note.type = 'success';
    }

    if (!note.hasOwnProperty('sticky')) {
      note.sticky = note.type === 'error';
    }

    if (!note.hasOwnProperty('timer')) {
      note.timer = 3000;
    }

    AppDispatcher.dispatch({
      actionType: ActionTypes.ADD_NOTIFICATION,
      data: note,
    });
  },

  success: (message) => {
    message = i18next.t(message);
    AppDispatcher.dispatch({
      actionType: ActionTypes.ADD_NOTIFICATION,
      data: {
        type: 'success',
        body: message,
        uuid: uuid(),
        show: true,
        timestamp: moment(),
        sticky: false,
        additionalInfo: false,
        timer: 3000,
        isLegacy: true,
      },
    });
  },

  alert: (message) => {
    message = i18next.t(message);
    AppDispatcher.dispatch({
      actionType: ActionTypes.ADD_NOTIFICATION,
      data: {
        type: 'alert',
        body: message,
        uuid: uuid(),
        show: true,
        timestamp: moment(),
        sticky: false,
        additionalInfo: false,
        timer: 3000,
        isLegacy: true,
      },
    });
  },

  warn: (message) => {
    message = i18next.t(message);
    AppDispatcher.dispatch({
      actionType: ActionTypes.ADD_NOTIFICATION,
      data: {
        type: 'alert',
        body: message,
        uuid: uuid(),
        show: true,
        timestamp: moment(),
        sticky: true,
        additionalInfo: false,
        timer: 3000,
        isLegacy: true,
      },
    });
  },

  error: (message) => {
    const translatedMessage = translateMessageWithPipe(message);
    AppDispatcher.dispatch({
      actionType: ActionTypes.ADD_NOTIFICATION,
      data: {
        type: 'error',
        body: translatedMessage,
        uuid: uuid(),
        show: true,
        timestamp: moment(),
        sticky: true,
        additionalInfo: false,
        timer: 3000,
        isLegacy: true,
      },
    });
  },

  removeNotification: (id) => {
    AppDispatcher.dispatch({
      actionType: ActionTypes.REMOVE_NOTIFICATION,
      data: id,
    });
  },

  apiError: (message, additionalInfo) => {
    const errorMessage: string = message ? i18next.t(message) : '';

    const body = `Connection Error. ${errorMessage}`;

    AppDispatcher.dispatch({
      actionType: ActionTypes.ADD_NOTIFICATION,
      data: {
        type: 'error',
        uuid: uuid(),
        show: true,
        timestamp: moment(),
        sticky: true,
        timer: 3000,
        body,
        additionalInfo,
        isLegacy: true,
      },
    });
  },
};
