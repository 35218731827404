import { MenuItem as MUIMenuItem } from '@mui/material';
import styled, { css } from 'styled-components';

export const RebrandMenuItem = styled(MUIMenuItem)<{
  $danger?: boolean;
  $darkMode: boolean;
  $variant: string;
  disabled?: boolean;
}>`
  ${({ $darkMode, $variant, $danger, disabled }) => css`
    padding: var(--sizes-30) var(--sizes-70);
    color: var(--color-grayscale-black);
    font: var(--font-regular-14pt-normal);

    .MuiCheckbox-root {
      margin-right: var(--sizes-40);
    }

    &:hover,
    &.Mui-selected:hover {
      background-color: var(--color-gray-10);
    }

    ${$darkMode &&
    css`
      color: var(--color-brand-primary-white);
      &:hover,
      &.Mui-selected:hover {
        background-color: var(--color-gray-100);
      }
    `}

    &.Mui-selected {
      background-color: transparent;
      font-weight: 600;
    }
    ${$danger && dangerStyles}
    ${disabled &&
    css`
      pointer-events: none;
    `}
    ${$variant === 'username' && usernameStyles}
    ${['checkbox', 'label'].includes($variant) && labelStyles}
  `}
`;

export const LabelVariantContainer = styled.div<{ $hasFooter?: boolean; $variant: string }>`
  --checkbox-container-total-width: var(--sizes-70);
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ $variant }) =>
    $variant === 'checkbox' &&
    css`
      width: calc(100% - var(--checkbox-container-total-width));
    `}

  .main-label {
    gap: var(--sizes-70);
    display: flex;
    justify-content: space-between;
    ${({ $variant, $hasFooter }) =>
      $variant === 'checkbox' &&
      $hasFooter &&
      css`
        font: var(--font-regular-14pt-semibold);
      `}
  }
`;

export const MenuItemSubText = styled.div`
  white-space: normal;
  display: flex;
  flex-direction: column;
  font: var(--font-regular-13pt-normal);
  color: var(--color-gray-70);
`;

const dangerStyles = css`
  color: var(--color-red-50);
`;

const usernameStyles = css`
  flex-direction: column;
  align-items: start;
  padding: var(--sizes-20) var(--sizes-110) var(--sizes-30) var(--sizes-70);
  font: var(--font-extra-small-12pt-normal);

  span {
    color: var(--color-gray-60);
    font: var(--font-micro-10pt-bold);
    text-transform: uppercase;
  }
`;

const labelStyles = css`
  .menu-item--label-text {
    margin-top: 1px; // visually looks off-center without this
    color: var(--color-gray-60);
    font: var(--font-extra-small-12pt-bold);
    text-transform: uppercase;
    align-self: center;
  }
`;
