import React from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

export function withGridApiRef(Component) {
  return function WrappedComponent(props) {
    const gridApiRef = useGridApiRef();
    return <Component {...props} gridApiRef={gridApiRef} />;
  };
}
