import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import styled from 'styled-components';

export const BulkActionsContainer = styled(GridToolbarContainer)`
  // MUI styles the height using min-height, max-height, and line-height, so we need to override these properties
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  padding: 0;
  position: absolute;
  top: 0;
  left: var(--sizes-80);

  .MuiCheckbox-root {
    margin-left: var(--sizes-30);
  }

  .MuiButtonBase-root {
    max-height: var(--sizes-70);
  }

  .MuiButton-endIcon {
    display: inline-flex;
    align-items: center;
  }
`;
