import { Button as MuiButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledLinkButton = styled(MuiButton)<{ $variant: string }>`
  text-transform: none !important;
  font: var(--font-small-13pt-semibold);
  ${({ $variant }) => css`
    ${$variant === 'primary' && primary}
    ${$variant === 'secondary' && secondary}
    ${$variant === 'danger' && danger}
  `}

  &:hover {
    background: none;
  }
  &:focus {
    outline: none;
  }

  :disabled {
    color: var(--color-gray-50);
  }
`;

const primary = css`
  color: var(--color-brand-secondary-sapphire);
  background-color: transparent;
`;

const secondary = css`
  color: var(--color-grayscale-black);
  background-color: transparent;
  text-decoration: underline;
`;

const danger = css`
  color: var(--color-red-60);
  background-color: transparent;
  text-decoration: underline;
`;
