import React from 'react';

import NotificationStore from 'src/app_deprecated/stores/NotificationStore';

import Note from './note';

class Notification extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };

    this.onNotificationStoreChange = this.onNotificationStoreChange.bind(this);
  }

  componentDidMount() {
    NotificationStore.addChangeListener(this.onNotificationStoreChange);
  }

  componentWillUnmount() {
    NotificationStore.removeChangeListener(this.onNotificationStoreChange);
  }

  onNotificationStoreChange() {
    this.setState({ notifications: NotificationStore.getState().notifications });
  }

  render() {
    return (
      // to be replaced with NotificationsWrapper
      <div className='notification'>
        {this.state.notifications.map((note, i) => {
          if (!note.show) {
            return null;
          }

          // to be replaced with Notification component
          return <Note automationId={`notification_note_${i}`} key={note.uuid} note={note} />;
        })}
      </div>
    );
  }
}

export default Notification;
