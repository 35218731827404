import React from 'react';

export function ArrowDown() {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.10756 5.92792L7.37148 11.7312C7.44466 11.8147 7.53955 11.8824 7.64864 11.9291C7.75773 11.9757 7.87803 12 7.99999 12C8.12196 12 8.24225 11.9757 8.35134 11.9291C8.46044 11.8824 8.55533 11.8147 8.62851 11.7312L12.8924 5.92792C12.9617 5.83433 12.999 5.72601 13 5.61524C13.001 5.50447 12.9657 5.39567 12.8981 5.30117C12.8312 5.20877 12.7377 5.13259 12.6265 5.07987C12.5154 5.02715 12.3903 4.99966 12.2632 5.00001L3.73679 5.00001C3.60985 4.99952 3.48488 5.0269 3.37384 5.07952C3.26279 5.13214 3.16939 5.20824 3.10256 5.30056C3.03466 5.39505 2.99913 5.50394 3.00002 5.61485C3.0009 5.72576 3.03816 5.83423 3.10756 5.92792V5.92792Z'
        fill='var(--color-gray-90)'
      />
    </svg>
  );
}
