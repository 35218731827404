import React from 'react';

import styled from 'styled-components';

type NoResultsOverlayProps = {
  className?: any;
  noResultsHeading?: string;
  noResultsSubheading?: React.ReactNode;
};

export const defaultNoResultsHeading = 'No data available';
export const defaultNoResultsSubheading = `We were unable to find data that matches what you're looking for. Please try again.`;

export function NoResultsOverlay({
  noResultsHeading = defaultNoResultsHeading,
  noResultsSubheading = defaultNoResultsSubheading,
  className,
}: NoResultsOverlayProps) {
  return (
    <StyledGridOverlay className={className}>
      <StyledGridContainer>
        <NoResultsHeading>{noResultsHeading}</NoResultsHeading>
        <NoResultsSubheading>{noResultsSubheading}</NoResultsSubheading>
      </StyledGridContainer>
    </StyledGridOverlay>
  );
}

export const StyledGridOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 7 !important;
  background: white;
`;

export const StyledGridContainer = styled.div`
  height: 166px;
  width: 100%;
  background-color: var(--color-gray-10);
  color: var(--color-gray-80);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--sizes-50);
  text-align: center;
`;

export const NoResultsHeading = styled.p`
  margin-bottom: 8px;
  color: var(--color-gray-80);
  font: var(--font-headings-heading-3);
`;

export const NoResultsSubheading = styled.p`
  color: var(--color-gray-80);
  font: var(--font-regular-14pt-normal);
`;
