/* eslint-disable @typescript-eslint/no-unused-vars */

export abstract class ReceiptPrinterEncoder {
  abstract get endStream(): number[];
  abstract get initialize(): number[];
  abstract get lines(): number[];
  abstract get newline(): number[];
  abstract get partialCut(): number[];
  abstract get popCashDrawer(): number[];
}

export class EscPrinterEncoder extends ReceiptPrinterEncoder {
  get initialize(): number[] {
    return [ ESC, AT_SIGN, ESC, EXCLAMATION_POINT, SOH, ]
  }

  get lines(): number[] {
    return [ESC, d, 0]
  }

  get endStream(): number[] {
    return [NUL, DC4, DC4,];
  }

  get newline(): number[] {
    return [NEW_LINE];
  }

  get partialCut(): number[] {
    return [ ESC, i];
  }
  
  get popCashDrawer(): number[] {
    return [ESC, p, NUL, DC4, DC4]
  }
}

export class StarPrinterEncoder extends EscPrinterEncoder {
  get popCashDrawer(): number[] {
    return [ESC, '!'.charCodeAt(0), 7]
  }
}

// From ASCII
const NUL = 0
const SOH = 1
const BELL = 7
const NEW_LINE = 10
const CARRIAGE_RETURN = 13
const DC4 = 20
const ESC = 27
const EXCLAMATION_POINT = 33
const COMMA = 44
const ZERO = 48
const ONE = 49
const TWO = 50
const THREE = 51
const FIVE = 53
const SIX = 54
const SEVEN = 55
const AT_SIGN = 64
const A = 65
const B = 66
const D = 68
const F = 70
const N = 78
const O = 79
const S = 83
const W = 87
const X = 88
const Z = 90
const UP_CARROT = 94
const d = 100
const i = 105
const p = 112
