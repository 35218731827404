import React from 'react';

import { FormControl } from 'src/app/components/lib/form-control';
import { Label } from 'src/app/components/lib/label';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { SelectCaret, Select } from 'src/app/components/lib/select';
import { StyledPlaceholder, StyledOutlinedInput } from 'src/app/components/lib/select/select-rebrand.styles';

import type { SxProps, SelectProps as MUISelectProps } from '@mui/material';

export type BooleanSelectProps = Omit<MUISelectProps<boolean>, 'onChange'> & {
  automationId?: string;
  className?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  gridColumns?: number;
  id?: string;
  label?: React.ReactNode | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  onChange: (arg: { target: { value: boolean } }) => void;
  placeholder?: string;
  renderValue?: (selected: boolean) => React.ReactNode | boolean;
  required?: boolean;
  sx?: SxProps;
  tooltip?: string;
  value?: boolean;
  width?: string;
};

export const BooleanSelect = React.forwardRef<HTMLDivElement, BooleanSelectProps>((props, ref) => {
  const {
    automationId,
    className = '',
    disabled = false,
    displayEmpty = true,
    gridColumns,
    id,
    input,
    label,
    labelPlacement = 'start',
    MenuProps,
    onChange,
    placeholder,
    renderValue,
    required = false,
    sx,
    tooltip,
    value,
    width,
    ...other
  } = props;

  const idFromLabel: string = typeof label === 'string' ? label : '';

  return (
    <FormControl
      {...other}
      $fullWidth={!label}
      $labelPlacement={labelPlacement}
      $width={width}
      className={`${className} select-form-control`}
      disabled={disabled}
      gridColumns={gridColumns}
      ref={ref}
      sx={sx}
    >
      {label && (
        <Label className='select-label' id={`select-label_${id ?? idFromLabel}`} required={required} tooltip={tooltip}>
          {label}
        </Label>
      )}
      <Select
        className='select-select-input'
        data-testid={automationId}
        displayEmpty={displayEmpty}
        IconComponent={SelectCaret}
        input={input ?? <StyledOutlinedInput />}
        labelId={`select-label_${id ?? idFromLabel}`}
        MenuProps={
          MenuProps ?? {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              marginTop: '8px',
            },
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderValue={
          renderValue ??
          ((selected: boolean) => {
            if (!selected) {
              return 'No';
            }
            if (selected) {
              return 'Yes';
            }
            return <StyledPlaceholder>{placeholder ?? 'Select an option'}</StyledPlaceholder>;
          })
        }
        value={value ?? ''}
        onChange={({ target }) => onChange({ target: { value: target.value === 'true' } })}
      >
        <MenuItem value='true'>Yes</MenuItem>
        <MenuItem value='false'>No</MenuItem>
      </Select>
    </FormControl>
  );
});
