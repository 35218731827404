import React from 'react';

export function ColumnResizeIcon() {
  return (
    <svg fill='none' height='50' viewBox='0 0 17 50' width='17' xmlns='http://www.w3.org/2000/svg'>
      <rect fill='var(--color-gray-60)' height='50' rx='0.5' width='1' x='8' />
      <path
        d='M3.46976 22.0753L0.153591 25.06C0.105902 25.1113 0.0671961 25.1777 0.0405374 25.254C0.0138787 25.3304 -1.56722e-07 25.4146 -1.5299e-07 25.5C-1.49258e-07 25.5854 0.0138788 25.6696 0.0405374 25.7459C0.0671961 25.8223 0.105902 25.8887 0.153591 25.94L3.46976 28.9247C3.52324 28.9732 3.58514 28.9993 3.64843 29C3.71173 29.0007 3.7739 28.976 3.82791 28.9287C3.8807 28.8818 3.92424 28.8164 3.95436 28.7386C3.98449 28.6608 4.0002 28.5732 4 28.4842L4 22.5158C4.00027 22.4269 3.98463 22.3394 3.95456 22.2617C3.92449 22.184 3.881 22.1186 3.82825 22.0718C3.77426 22.0243 3.71203 21.9994 3.64866 22C3.58528 22.0006 3.5233 22.0267 3.46976 22.0753Z'
        fill='var(--color-gray-60)'
      />
      <path
        d='M13.5302 28.9247L16.8464 25.94C16.8941 25.8887 16.9328 25.8223 16.9595 25.746C16.9861 25.6696 17 25.5854 17 25.5C17 25.4146 16.9861 25.3304 16.9595 25.2541C16.9328 25.1777 16.8941 25.1113 16.8464 25.06L13.5302 22.0753C13.4768 22.0268 13.4149 22.0007 13.3516 22C13.2883 21.9993 13.2261 22.024 13.1721 22.0713C13.1193 22.1182 13.0758 22.1836 13.0456 22.2614C13.0155 22.3392 12.9998 22.4268 13 22.5158L13 28.4842C12.9997 28.5731 13.0154 28.6606 13.0454 28.7383C13.0755 28.816 13.119 28.8814 13.1717 28.9282C13.2257 28.9757 13.288 29.0006 13.3513 29C13.4147 28.9994 13.4767 28.9733 13.5302 28.9247Z'
        fill='var(--color-gray-60)'
      />
    </svg>
  );
}
