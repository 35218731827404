import { EventEmitter } from 'events';
import _ from 'lodash';
import AppDispatcher from '../utils/AppDispatcher';
import UserStore from './UserStore';
import AjaxPromises from '../utils/AjaxPromises';
import {
  endPoints as PermissionEndpoints,
  events as PermissionEvents,
  Permissions as PermissionConstants,
} from '../constants/PermissionsConstants';
import { actions as AppEvents } from '../constants/AppConstants';

class PermissionStore extends EventEmitter {
  private _permissions = {};
  private _superUserPermissions = {};

  constructor() {
    super();
    this.refresh = this.refresh.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
    AppDispatcher.register(this.actionHandler);
  }

  public get permissions() {
    return _.values(this._permissions);
  }

  public get superUserPermissions() {
    return _.values(this._superUserPermissions);
  }

  public permissionName(permissionId) {
    return _.keys(PermissionConstants).find((key) => PermissionConstants[key] === permissionId);
  }

  public userCan(permission) {
    return !!this._permissions[permission];
  }

  public superUserCan(permission) {
    return !!this._superUserPermissions[permission];
  }

  public userCanAny(permissions) {
    permissions.forEach((p) => {
      if (this._permissions[p]) {
        return true;
      }
    });

    return false;
  }

  public userCanAll(permissions) {
    permissions.forEach((p) => {
      if (!this._permissions[p]) {
        return false;
      }
    });

    return true;
  }

  public async refresh() {
    try {
      const data = UserStore.getApiData();
      const resp = await AjaxPromises.POST(PermissionEndpoints.GET_PERMISSIONS, data);
      const superUserResp = await AjaxPromises.POST(PermissionEndpoints.GET_SUPERUSER_PERMISSIONS, data);

      if (!resp.Result) {
        throw resp;
      }
      this._permissions = _.keyBy(resp.Data, (x) => x);
      this._superUserPermissions = _.keyBy(superUserResp.Data, (x) => x);
      this.emitChange();
    } catch (ex) {
      console.warn(`Failed to load permissions.`, ex);
    }
  }

  protected emitChange() {
    this.emit(PermissionEvents.CHANGE_EVENT);
  }

  public addChangeListener(callback) {
    this.on(PermissionEvents.CHANGE_EVENT, callback);
  }

  public removeChangeListener(callback) {
    this.removeListener(PermissionEvents.CHANGE_EVENT, callback);
  }

  public actionHandler(action) {
    switch (action.actionType) {
      case AppEvents.APP_BOOTED:
      case AppEvents.LOCATION_LOADED:
        this.refresh();
        break;
    }
  }
}

const permissions = new PermissionStore();
export default permissions;
