import React from 'react';

import { Pagination, PaginationItem } from '@mui/material';
import styled from 'styled-components';

import { PaginationBack, PaginationForward } from 'src/app/components/icons';

import type { PaginationProps } from '@mui/material';

export function RebrandPagination(props: PaginationProps): JSX.Element {
  return (
    <RebrandPaginationContainer
      {...props}
      data-testid='rebrand-pagination'
      renderItem={(item) => (
        <PaginationItem components={{ previous: PaginationBack, next: PaginationForward }} {...item} />
      )}
    />
  );
}

const RebrandPaginationContainer = styled(Pagination)`
  background-color: var(--color-brand-primary-white);

  .MuiPaginationItem-previousNext {
    background: var(--color-brand-primary-white);
    border: var(--button-border-width) solid var(--color-gray-20);
    box-shadow: 0 2px var(--sizes-10) rgba(0, 0, 0, 0.1);
    border-radius: 100%;

    &:hover {
      border-color: var(--color-gray-50);
    }

    &:focus {
      border-color: var(--color-gray-90);
    }

    &.Mui-disabled {
      opacity: unset;
      box-shadow: unset;

      & svg > path {
        fill: var(--color-gray-40);
      }
    }
  }

  .MuiPaginationItem-text:not(.MuiPaginationItem-previousNext) {
    font: var(--font-regular-14pt-normal);
    color: var(--color-gray-80);
    border-radius: var(--button-border-radius-medium);

    &:hover:not(.MuiPaginationItem-ellipsis) {
      background-color: var(--color-gray-10);
    }

    &:focus {
      background-color: var(--color-gray-20);
    }
  }

  .MuiPaginationItem-text.Mui-selected {
    font: var(--font-regular-14pt-semibold);
    color: var(--color-grayscale-black);
    background-color: unset;
  }
`;
