export function estimateReadingTime(message: string) {
  // Assuming the average reading speed of 250 words per minute, lean towards lower
  const averageReadingSpeed = 200;

  // Calculate the total number of words
  const words: string[] = message.trim().split(/\s+/);
  const totalWords: number = words.length;

  // Calculate the estimated reading time in minutes
  const readingTimeMinutes = totalWords / averageReadingSpeed;

  // Convert minutes to milliseconds
  const readingTimeMilliseconds = readingTimeMinutes * 60 * 1000;

  // Add some time for eyes to transition to and from the notification
  const additionalTime = 1000;

  // minimum time to show the notification
  const minimumTime = 3000;

  return Math.max(readingTimeMilliseconds + additionalTime, minimumTime);
}
