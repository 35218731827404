import styled, { css } from 'styled-components';

export type Weight = 'bold' | 'normal' | 'semibold';
export type CellAlignment = 'center' | 'inherit' | 'justify' | 'left' | 'right';

type SummaryTableContainerProps = {
  equalColumns?: boolean;
};

export const SummaryTableContainer = styled.table<SummaryTableContainerProps>`
  width: 100%;
  border-collapse: collapse;
  font: var(--font-regular-14pt-normal);
  background-color: white;
  ${({ equalColumns }) =>
    equalColumns &&
    css`
      table-layout: fixed;
    `}
`;

export const SummaryTableCaption = styled.caption<{ show: boolean }>`
  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}
`;

type SummaryTableHeaderProps = {
  withBorder?: boolean;
};

export const SummaryTableHeader = styled.thead<SummaryTableHeaderProps>`
  display: table-header-group;
  vertical-align: middle;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: 1px solid var(--color-gray-20);
    `}
`;

export const SummaryTableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
`;

export const SummaryTableFooter = styled.tfoot`
  border-top: 1px solid var(--color-grayscale-black);
  display: table-footer-group;
  vertical-align: middle;
`;

export const SummaryTableRow = styled.tr<{ headerRow?: boolean }>`
  display: table-row;
  border-bottom: 1px solid var(--color-gray-20);
  &:last-child {
    border-bottom: unset;
  }
  color: ${({ headerRow }) => (headerRow ? 'var(--color-gray-90)' : 'var(--color-grayscale-black)')};
`;

type SummaryTableCellProps = {
  align?: CellAlignment;
  boldBorderTop?: boolean;
  maxWidth?: string;
  padding?: string;
  weight?: Weight;
  width?: string;
};

export const SummaryTableCell = styled.td<SummaryTableCellProps>`
  font: var(--font-regular-14pt-${({ weight }) => weight ?? 'normal'});
  width: ${({ width }) => width ?? 'auto'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'none'};
  padding: ${({ padding }) => padding ?? 'var(--sizes-30) 0'};
  text-align: ${({ align }) => align ?? 'left'};
  vertical-align: middle;
  border-top: ${({ boldBorderTop }) => (boldBorderTop ? '1px solid black' : 'none')};
`;

type SummaryTableHeaderCellProps = {
  align?: CellAlignment;
  color?: string;
  padding?: string;
  weight?: Weight;
};

export const SummaryTableHeaderCell = styled.th<SummaryTableHeaderCellProps>`
  padding: ${({ padding }) => padding ?? 'var(--sizes-30) 0'};
  text-align: ${({ align }) => align ?? 'left'};
  vertical-align: middle;
  font: var(--font-small-13pt-${({ weight }) => weight ?? 'semibold'});
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;
