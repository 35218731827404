import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { LinkButton } from 'src/app/components/lib/button';
import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { customerTypeQueryKey, useGetAllCustomerTypes } from 'src/app/queries/customers/get-all-customer-types';

import type { SxProps } from '@mui/material';

type CustomerTypeDropdownProps = {
  automationId?: string;
  disabled?: boolean;
  helpText?: string;
  label?: string;
  labelPlacement?: 'start' | 'top';
  onChange: (value: number | string) => void;
  refreshButtonAutomationId?: string;
  required?: boolean;
  resetButtonAutomationId?: string;
  searchAutomationId?: string;
  sx?: SxProps;
  value: number | string;
};

type CustomerTypeProps = {
  CustomerType: string;
  CustomerTypeId: number;
  Visible: boolean;
};

export function CustomerTypeSingleSelectDropdown(props: CustomerTypeDropdownProps) {
  const {
    onChange,
    value,
    disabled = false,
    required,
    automationId,
    label,
    sx,
    labelPlacement,
    helpText,
    searchAutomationId,
    refreshButtonAutomationId,
    resetButtonAutomationId,
  } = props;
  const queryClient = useQueryClient();
  const { data: allCustomerTypes = [], isLoading } = useGetAllCustomerTypes();
  const customerTypes = allCustomerTypes?.filter((customer: CustomerTypeProps) => customer.Visible);

  async function loadCustomerTypes() {
    void queryClient.invalidateQueries({ queryKey: customerTypeQueryKey });
  }

  async function handleRefresh() {
    await loadCustomerTypes();
  }

  return (
    <DropdownSingle
      automationId={automationId}
      disabled={disabled}
      footerContent={
        <LinkButton
          automationId={refreshButtonAutomationId}
          buttonSize='small'
          key='customer-type-refresh'
          label='Refresh'
          loading={isLoading}
          onClick={handleRefresh}
        />
      }
      helpText={helpText}
      inputId='select-customer-type'
      label={label}
      labelPlacement={labelPlacement}
      options={customerTypes?.map((custType: CustomerTypeProps) => ({
        label: custType.CustomerType,
        id: custType.CustomerTypeId,
      }))}
      required={required}
      resetButtonAutomationId={resetButtonAutomationId}
      searchAutomationId={searchAutomationId}
      setValue={(newVal) => onChange(newVal.id)}
      sx={sx}
      value={String(value)}
    />
  );
}
