import styled, { css } from 'styled-components';

export const HEADER_HEIGHT = 64;
export const LARGE_BANNER_HEIGHT = 48;
export const SMALL_BANNER_HEIGHT = 40;

export const generalBannerStyles = css`
  color: #fff;
  width: 100%;
  padding: var(--sizes-40);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--sizes-20);
`;

export const Text = styled.div`
  font: var(--font-extra-small-12pt-semibold);
  display: inline-block;
  display: flex;
  justify-content: center;
`;
