import React, { useEffect, useState } from 'react';

import { parseSelectedValues } from 'src/app/pages/customers/customers/segments/utils';
import { genderOptions } from 'src/app/utils/gender-options';

import { MultiFilterSelect } from '../../lib/multi-filter-select';

import type { DropdownMenuOption } from '../../lib/dropdown';

type GenderOptionDropdownProps = {
  disabled?: boolean;
  onChange: (value: (number | string)[]) => void;
  values: string;
};

type GenderOption = {
  label: string;
  value: string;
};

export function GenderOptionDropdown(props: GenderOptionDropdownProps) {
  const { onChange, values, disabled = false } = props;

  const filteredGenderOptions = genderOptions.filter((genderOption) => genderOption.value !== '');

  const mapToDropdownOptions = (
    selectedValues: string[],
    allGenderOptionOptions: GenderOption[]
  ): DropdownMenuOption[] =>
    selectedValues
      .map((val) => {
        const option = allGenderOptionOptions.find((opt) => opt.value === val);
        if (option) {
          return { label: option.label, id: option.value };
        }
        return null;
      })
      .filter((option) => option !== null);

  const getMultiSelectedOptionsForGenderOptions = (
    values: string,
    allGenderOptions: GenderOption[]
  ): DropdownMenuOption[] => {
    const selectedValues = parseSelectedValues(values);
    return mapToDropdownOptions(selectedValues, allGenderOptions);
  };

  const [label, setLabel] = useState('Select a gender');
  const [genderOptionDropdownSelection, setGenderOptionsDropdownSelection] = useState<DropdownMenuOption[]>(
    getMultiSelectedOptionsForGenderOptions(values, filteredGenderOptions)
  );

  useEffect(() => {
    if (genderOptionDropdownSelection.length !== 0 && genderOptionDropdownSelection.length < 4) {
      const genderOptionsLabelList = genderOptionDropdownSelection.map((item) => item.label).join(', ');
      setLabel(genderOptionsLabelList);
    } else {
      setLabel('Select a gender');
    }
  }, [genderOptionDropdownSelection]);

  const handlegenderOptionsFilterChange = (values: DropdownMenuOption[]) => {
    setGenderOptionsDropdownSelection(values);

    const customerTypeDropdownSelection = values.map((option) => option.id);
    onChange(customerTypeDropdownSelection);
  };

  return (
    <MultiFilterSelect
      activeFilter={genderOptionDropdownSelection}
      automationId='gender-options-dropdown_multi-filter-select'
      disabled={disabled}
      label={label}
      options={filteredGenderOptions.map((genderOptions) => ({
        label: String(genderOptions.label),
        id: String(genderOptions.value),
      }))}
      onChange={handlegenderOptionsFilterChange}
    />
  );
}
