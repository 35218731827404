import React from 'react';

export function SuccessMessageIcon(): JSX.Element {
  return (
    <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_722_2229)'>
        <g clipPath='url(#clip1_722_2229)'>
          <path
            d='M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87707C0.00693258 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5514 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9949 11.7581 30.3076 7.69139 27.3081 4.6919C24.3086 1.69242 20.2419 0.00508114 16 0V0ZM23.8144 13.4144L15.0144 22.2144C14.8287 22.4002 14.6082 22.5475 14.3655 22.6481C14.1228 22.7486 13.8627 22.8004 13.6 22.8004C13.3373 22.8004 13.0772 22.7486 12.8345 22.6481C12.5918 22.5475 12.3713 22.4002 12.1856 22.2144L8.18561 18.2144C7.81049 17.8393 7.59974 17.3305 7.59974 16.8C7.59974 16.2695 7.81049 15.7607 8.18561 15.3856C8.56073 15.0105 9.0695 14.7997 9.60001 14.7997C10.1305 14.7997 10.6393 15.0105 11.0144 15.3856L13.6 17.9712L20.9856 10.5856C21.1714 10.3999 21.3919 10.2525 21.6345 10.152C21.8772 10.0515 22.1373 9.99974 22.4 9.99974C22.6627 9.99974 22.9228 10.0515 23.1655 10.152C23.4082 10.2525 23.6287 10.3999 23.8144 10.5856C24.0002 10.7713 24.1475 10.9918 24.248 11.2345C24.3485 11.4772 24.4003 11.7373 24.4003 12C24.4003 12.2627 24.3485 12.5228 24.248 12.7655C24.1475 13.0082 24.0002 13.2287 23.8144 13.4144Z'
            fill='#2FB594'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_722_2229'>
          <rect fill='white' height='32' width='32' />
        </clipPath>
        <clipPath id='clip1_722_2229'>
          <rect fill='white' height='32' width='32' />
        </clipPath>
      </defs>
    </svg>
  );
}
