import React from 'react';

export function GreenFilledCheckIcon({ size = 'var(--sizes-30)' }: { size?: string }): JSX.Element {
  return (
    <svg fill='none' style={{ height: size, width: size }} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9981 4.40929 11.3653 2.88427 10.2405 1.75946C9.11573 0.634658 7.59072 0.00190543 6 0V0ZM8.9304 5.0304L5.6304 8.3304C5.56076 8.40007 5.47807 8.45533 5.38706 8.49303C5.29605 8.53074 5.19851 8.55014 5.1 8.55014C5.0015 8.55014 4.90395 8.53074 4.81294 8.49303C4.72194 8.45533 4.63925 8.40007 4.5696 8.3304L3.0696 6.8304C2.92893 6.68973 2.8499 6.49894 2.8499 6.3C2.8499 6.10106 2.92893 5.91027 3.0696 5.7696C3.21027 5.62893 3.40106 5.5499 3.6 5.5499C3.79894 5.5499 3.98973 5.62893 4.1304 5.7696L5.1 6.7392L7.8696 3.9696C7.93926 3.89995 8.02195 3.8447 8.11295 3.807C8.20396 3.7693 8.3015 3.7499 8.4 3.7499C8.49851 3.7499 8.59605 3.7693 8.68705 3.807C8.77806 3.8447 8.86075 3.89995 8.9304 3.9696C9.00006 4.03925 9.05531 4.12194 9.093 4.21295C9.1307 4.30396 9.1501 4.4015 9.1501 4.5C9.1501 4.5985 9.1307 4.69604 9.093 4.78705C9.05531 4.87806 9.00006 4.96075 8.9304 5.0304Z'
        fill='var(--color-green-80)'
      />
    </svg>
  );
}
