import { EventEmitter } from 'events';

import _ from 'lodash';
import moment from 'moment';
import { v1 } from 'uuid';

import { removeProductCategoryFromCanonicalName } from 'src/app/pages/reports-v2/utils';

import { actions as ReportActions, events as ReportEvents } from '../constants/ReportConstants';
import AppDispatcher from '../utils/AppDispatcher';
import Formatters from '../utils/Formatters';

const _state = {
  listOfReports: false as any,
  listOfCategories: false as any,

  selectedReport: false as any,
  selectedReportCategory: false,

  fromDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
  toDate: moment(new Date()).format('YYYY-MM-DD'),

  runForAllLocations: false,

  reportPeriodType: null,

  parameters: null,

  currentReportData: false as any,
  currentReportColumns: null,
  orderType: 0,
  ccrsReset: false,
  partialFile: null,
  showDropdown: false,
};

class ReportStoreClass extends EventEmitter {
  constructor() {
    super();
  }

  getDataType(key, data) {
    const dateFormats = ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY', 'YYYY-MM-DDTHH:mm:ss.SSS'];

    for (let i = 0; i < 10; i++) {
      const value = _.sample(data)[key];

      if (value == null || value === undefined) {
        continue;
      }

      if (moment(value, dateFormats, true).isValid()) {
        let render = (x) => Formatters.formatDate(x[key], 'MM/DD/YYYY');

        const validValues = _(data)
          .map((x) => x[key])
          .uniq()
          .map((x) => moment(x))
          .value();

        if (_.filter(validValues, (x) => x.minutes() !== 0).length > validValues.length * 0.1) {
          render = (x) => Formatters.formatDate(x[key], 'MM/DD/YYYY HH:mm:ss a');
        }

        return {
          type: 'date',
          render,
        };
      }
      if (isNaN(value)) {
        return { type: 'str', render: null };
      }

      if (!isNaN(parseFloat(value))) {
        return { type: 'flt', render: null };
      }

      if (!isNaN(parseInt(value))) {
        return { type: 'int', render: null };
      }
    }

    return {
      type: null,
      render: null,
    };
  }

  getColumnDefinitions(data) {
    if (data.length == 0) {
      return null;
    }

    const columns = {};
    _.keys(data[0]).forEach((k) => {
      const dataType = this.getDataType(k, data);

      columns[k] = {
        title: k,
        sort: {
          type: dataType.type,
        },
        render: dataType.render,
      };
    });

    return columns;
  }

  setData(data) {
    _state.currentReportData = { ...data.Data, reportId: data.reportId };
    let columnData = data.Data;
    if (data.Data.details) {
      columnData = data.Data.details;
    }
    _state.currentReportColumns = this.getColumnDefinitions(columnData);
    ReportStore.emitChange();
  }

  getState() {
    return _state;
  }

  emitChange(callback?) {
    this.emit(ReportEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(ReportEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(ReportEvents.CHANGE_EVENT, callback);
  }

  dispatchToken;
}

const ReportStore = new ReportStoreClass();

ReportStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    // case UserActions.SELECT_COMPANY:
    //     _state.listOfReports = false;
    //     _state.listOfCategories = false;
    //     _state.currentReportData = false;
    //     ReportStore.emitChange();
    //     break;

    case ReportActions.LIST_OF_REPORTS_LOADED:
      const adjustedListOfReports = _.map(action.data, (report) => ({
        ...report,
        canonicalName: removeProductCategoryFromCanonicalName(_.kebabCase(report.ReportName)),
        // ReportId is not unique, you can have multiple reports with the same ID but different categories.
        // This just adds a unique ID so we can route to the correct category & report from the overview page.
        uuid: v1(),
      }));
      _state.listOfReports = adjustedListOfReports;
      _state.listOfCategories = _.uniqBy(action.data, 'ReportCategory');
      // _state.selectedReportCategory = _state.selectedReportCategory || (listOfCategories.length > 0 ? listOfCategories[2].name : false);

      ReportStore.emitChange();
      break;

    case ReportActions.SET_REPORT_DATA:
      if (action.data.reportId) {
        for (let i = 0; i < _state.listOfReports.length; i++) {
          if (_state.listOfReports[i].ReportId == action.data.reportId) {
            _state.selectedReport = _state.listOfReports[i];
            _state.selectedReportCategory = _state.listOfReports[i].ReportCategory;
          }
        }
      }

      // var selectedReport = action.data.reportId ? _state.listOfReports.find(x => x.ReportId == action.data.reportId) : _state.selectedReport;
      // _state.selectedReport = selectedReport; //action.data.reportId ? _state.listOfReports.find(x => x.ReportId == action.data.reportId) : _state.selectedReport;

      if (action.data.category && action.data.category !== _state.selectedReportCategory) {
        _state.selectedReport = false;
      }

      _state.selectedReportCategory = action.data.category || _state.selectedReportCategory;

      _state.fromDate = action.data.fromDate || _state.fromDate;
      _state.toDate = action.data.toDate || _state.toDate;

      _state.runForAllLocations = action.data.runForAllLocations;

      _state.reportPeriodType = action.data.reportPeriodType;

      _state.parameters = action.data.parameters ?? _state.parameters;
      _state.showDropdown = action.data.showDropdown ?? _state.showDropdown;

      _state.orderType = action.data.orderType ?? _state.orderType;
      _state.ccrsReset = action.data.ccrsReset ?? _state.ccrsReset;
      _state.partialFile = action.data.partialFile ?? _state.partialFile;

      _state.currentReportData = action.data.currentReportData ?? _state.currentReportData;

      ReportStore.emitChange();
      break;

    case ReportActions.CLEAR_SELECTED_REPORT:
      _state.selectedReport = null;
      _state.selectedReportCategory = null;
      _state.currentReportData = false;
      ReportStore.emitChange();
      break;

    case ReportActions.REPORT_DATA_LOADED:
      ReportStore.setData(action.data);
      break;

    case ReportActions.REPORT_FAILED:
      _state.currentReportData = [{ Error: 'An error occurred running this report.' }];
      ReportStore.emitChange();
      break;
  }
});

export default ReportStore;
