import { createContext, useContext } from 'react';

type DropdownMenuContextData = {
  isFetching?: boolean;
  multiSelect?: boolean;
  triggerNodeRef?: (node: any) => void;
  truncateLabel?: boolean;
  width?: string;
};

export const DropdownMenuContext = createContext<DropdownMenuContextData>({});

export function useDropdownMenuContext() {
  return useContext(DropdownMenuContext);
}
