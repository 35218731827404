import styled, { css } from 'styled-components';

// constants
export const DRAWER_TRANSITION_DURATION = 300;

// styles
export const DrawerOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(16, 48, 77, 0.3);

  &.drawer-overlay-enter {
    opacity: 0;
  }
  &.drawer-overlay-enter-active {
    opacity: 1;
    transition: opacity ${DRAWER_TRANSITION_DURATION}ms ease;
  }
  &.drawer-overlay-exit {
    opacity: 1;
  }
  &.drawer-overlay-exit-active {
    opacity: 0;
    transition: opacity ${DRAWER_TRANSITION_DURATION}ms ease;
  }
`;

const drawerStyles = css`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;

  &.drawer-container-enter {
    transform: translate3D(0, 100%, 0);
  }
  &.drawer-container-enter-active {
    transition: transform ${DRAWER_TRANSITION_DURATION}ms ease;
    transform: translate3D(0, 0, 0);
  }
  &.drawer-container-exit {
    transform: translate3D(0, 0, 0);
  }
  &.drawer-container-exit-active {
    transition: transform ${DRAWER_TRANSITION_DURATION}ms ease;
    transform: translate3D(0, 100%, 0);
  }
`;

export const DrawerContainer = styled.div`
  ${drawerStyles}
  max-height: calc(100% - 50px);
  background: white;
`;

export const FullScreenDrawerContainer = styled.div`
  ${drawerStyles}
  max-height: calc(100% - 36px);
  background: var(--color-gray-10);
  overflow-x: hidden;
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 2.2rem 1.125rem;
  border-bottom: 1px solid var(--color-gray-20);

  // TODO: REMOVE!
  .page-heading {
    margin: 0;
  }
`;

export const DrawerContent = styled.div`
  flex-grow: 1;
`;
