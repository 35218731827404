import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { Checkbox } from 'src/app/components/lib/checkbox';
import { FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { Modal } from 'src/app/components/lib/modal';
import { totalExportRequestCountLimit } from 'src/app/queries/graphql';
import { useNotificationActions } from 'src/app/state/notifications';

import { exportCSV, filterColumnsByList, getComputedColumns, getLayout, filterColumnsWithoutHeaders } from '../utils';

import { ColumnRow } from './table-modal.styles';

import type { columnLayout, GridValues } from '../utils';
import type { UseQueryResult } from '@tanstack/react-query';

type ExportTableModalProps = {
  disabledColumns?: string[];
  excludeExcel?: boolean;
  gridValues: GridValues;
  onClose: () => void;
  open: boolean;
  paginatedQueryItemExtender?: (data: unknown) => unknown;
  paginatedQuerySet?: UseQueryResult[];
  selectiveColumnsList?: string[];
  title: string;
  warningGenerator: (columns: columnLayout[]) => string[];
};

enum LoadingButtons {
  CSV = 1,
  Excel = 2,
}

export function ExportTableModal({
  gridValues,
  onClose,
  open,
  selectiveColumnsList,
  title,
  disabledColumns = [],
  excludeExcel = false,
  warningGenerator,
  paginatedQueryItemExtender,
  paginatedQuerySet,
}: ExportTableModalProps) {
  const [columns, setColumns] = useState<columnLayout[]>([]);
  const [loading, setLoading] = useState<LoadingButtons | false>(false);
  const notifications = useNotificationActions();

  useEffect(() => {
    const {
      sortedFullData,
      visibleColumns: unfilteredVisibleColumns,
      sortedColumns: unfilteredSortedColumns,
    } = gridValues;

    const visibleColumns = filterColumnsWithoutHeaders(unfilteredVisibleColumns);
    const sortedColumns = filterColumnsWithoutHeaders(unfilteredSortedColumns);

    if (sortedFullData.length === 0) {
      return;
    }

    // if we are on a refactored table that already has columns set, don't
    // rebuild the columns again
    if (paginatedQuerySet?.length && columns.length) {
      return;
    }

    const selectiveColumnsListExists = !!selectiveColumnsList?.length;
    const shownColumns = selectiveColumnsListExists
      ? filterColumnsByList(sortedColumns, selectiveColumnsList)
      : visibleColumns ?? sortedColumns;
    const cols = selectiveColumnsListExists ? shownColumns : sortedColumns;
    const computedColumns = getComputedColumns(cols, shownColumns);
    const layout = getLayout(sortedFullData, computedColumns);

    const newLayout = layout.reduce((newLayoutcolumns, currentColumn) => {
      if (!currentColumn.disableExport && currentColumn.exportColumn) {
        const newColumnLayout = {
          align: currentColumn.align,
          exportRender: currentColumn.exportRender,
          order: currentColumn.order,
          name: currentColumn.name.field,
          print: currentColumn.visible,
          title: currentColumn.title,
          type: currentColumn.type,
          valueGetter: currentColumn.valueGetter,
          valueFormatter: currentColumn.valueFormatter,
        };
        newLayoutcolumns.push(newColumnLayout);
      }
      return newLayoutcolumns;
    }, []);

    setColumns(newLayout);
  }, [gridValues]);

  const handleExport = async (columnsToExport = null, excel = false) => {
    const { sortedFullData } = gridValues;
    let paginatedDataSet = [];

    // if this is passed in, we are exporting data from a paginated table.
    // we need to loop through the query set and pass data through instead
    // of using sortedFullData from the table.
    if (paginatedQuerySet) {
      if (paginatedQuerySet.length > totalExportRequestCountLimit) {
        notifications.alert({ message: 'Please export less than 10,000 items at a time' });
        return;
      }
      setLoading(excel ? LoadingButtons.Excel : LoadingButtons.CSV);
      const results = await Promise.all(paginatedQuerySet.map((query) => query.refetch()));
      results.forEach((result) => {
        if (result.isSuccess) {
          // @ts-ignore TODO
          let items = result.data?.items ?? [];
          if (paginatedQueryItemExtender) {
            items = items.map((item) => paginatedQueryItemExtender(item));
          }
          paginatedDataSet = paginatedDataSet.concat(items);
        } else {
          notifications.error({ message: 'There was an error exporting this data' });
        }
      });
      setLoading(false);
    }
    const filename = `${moment().format('YYYY-MM-DD')}-${String(title)}.csv`;
    const fullDataSet = paginatedDataSet.length > 0 ? paginatedDataSet : sortedFullData;
    exportCSV(columnsToExport, fullDataSet, excel, filename);
    onClose();
  };

  const onExport = (excel = false) => {
    handleExport(columns, excel);
  };

  const onCheck = (name: string, checked: boolean) => {
    let columnsCopy = [...columns];

    columnsCopy = columns.map((column) => {
      if (column.name === name) {
        column.print = checked;
      }
      return column;
    });

    setColumns(columnsCopy);
  };

  const onChange = (name: string, newTitle: string) => {
    let columnsCopy = [...columns];

    columnsCopy = columns.map((column) => {
      if (column.name === name) {
        column.title = newTitle;
      }
      return column;
    });

    setColumns(columnsCopy);
  };

  const onSelectAll = (all = true) => {
    let columnsCopy = [...columns];

    columnsCopy = columns.map((column) => {
      column.print = all;
      return column;
    });

    setColumns(columnsCopy);
  };

  const canGenerateWarning = columns?.length && warningGenerator;

  const exportCsv = {
    automationId: 'export-table-modal-export-csv-button',
    label: 'Export CSV',
    onClick: () => onExport(false),
    loading: loading === LoadingButtons.CSV,
  };

  const exportExcel = {
    automationId: 'export-table-modal-export-excel-button',
    label: 'Export Excel',
    onClick: () => onExport(true),
    loading: loading === LoadingButtons.Excel,
  };

  const primaryActions = excludeExcel ? [exportCsv] : [exportCsv, exportExcel];

  return (
    <>
      <Modal
        Content={
          <FormSection>
            {columns.map((col, idx) => {
              const checked = col.print ? 'checked' : '';
              const isDisabled = disabledColumns.includes(col.name);
              return (
                <ColumnRow key={`export_${String(idx)}`}>
                  <Checkbox
                    checked={!!checked}
                    disabled={isDisabled}
                    label=''
                    onChange={({ target }) => onCheck(col.name, target.checked)}
                  />
                  <Input
                    disabled={isDisabled}
                    value={col.title}
                    onChange={({ target }) => onChange(col.name, target.value)}
                  />
                </ColumnRow>
              );
            })}
          </FormSection>
        }
        open={open}
        primaryActions={primaryActions}
        secondaryActions={[
          {
            automationId: 'export-table-modal-select-all-button',
            label: 'Select All',
            variant: 'secondary',
            onClick: () => onSelectAll(),
          },
          {
            automationId: 'export-table-modal-select-none-button',
            label: 'Select None',
            variant: 'secondary',
            onClick: () => onSelectAll(false),
          },
        ]}
        title='Export table'
        Warning={canGenerateWarning ? warningGenerator(columns) : []}
        onClose={onClose}
      />
      <a download='name.csv' href='' id='export-a' />
    </>
  );
}
