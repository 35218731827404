import type { HardwareLogger } from "../../utils/logger";

import type { ScaleMeasurement } from "./types";
import { ScaleMeasurementHeader, ScaleMeasurementUnit, parseScaleMeasurementHeader, parseScaleMeasurementUnit } from "./types";

export class ScaleMeasurementParser {
  private logger: HardwareLogger;
  constructor(logger: HardwareLogger) {
    this.logger = logger;
  }
  commandTerminator = '\r\n';

  // NumpadEnter is sent by A&D scale when using USB-B cable as keypress/keyup/keydown.
  // Escape is sent by 232Key with the suggested configuration as keyup but not as keypress
  keyboardTerminatorCodes = ['NumpadEnter', 'Escape'];
  
  // Pattern matches A&D standard (SiF type 0) and Dump Print (SiF type 1) formats
  get measurementPattern(): RegExp { return /(\?G|\?N|OL|QT|SG|SN|ST|US|WT|G|N)\s*,?([-+]?[.0-9]+)(?:\[[0-9]{1}\])?\s*([A-Za-z]{1,3})/g }
  get modelNamePattern(): RegExp { return /TN,\s*([\w\d\\-]+)$/ }
  get serialNumberPattern(): RegExp { return /SN,\s*([\w\d]+)$/ }

  parseDataBuffer(output: string): string[] {
    const commands = output.split(this.commandTerminator)
    if(!output[output.length - 1].match(this.commandTerminator)) {
      commands.pop()
    }

    return commands.map(it => it.trim())
  }

  parseMeasurement(data: string) : ScaleMeasurement | null {
    try {
      const match = this.measurementPattern.exec(data)
      if(match) {
        const [, header, amount, amountUnit] = match
        return {
            header: parseScaleMeasurementHeader(header),
            unit: parseScaleMeasurementUnit(amountUnit),
            value: parseFloat(amount),
        } as ScaleMeasurement
      }
    }
    catch(e) {
      this.logger.e('error parsing measurement', e)
    }
    return null
  }

  /*
  Convert a list of keyboard events into a scale measurement.
  All measurements are returned with a stable header in grams.

  Valid input requires only numbers with a single comma or decimal point.
  Leading +- chars are ommitted to accept A&D formats.

  Invalid input contains leading, trailing, or non-numeric characters.

  example:
  +0000.00 - A&D format when using USB-B cable.
  0.12 - 232Key format
  2,3 - Support 232Key comma configuration
  */
  parseMeasurementFromKeyboardEvents(events: KeyboardEvent[]) : ScaleMeasurement | null {
    // only use keypress events
    const items = events.filter(it => it.type == 'keypress');

    // Strip any trailing command terminators
    const lastKey = String.fromCharCode(items[items.length - 1].which);
    if(this.keyboardTerminatorCodes.includes(lastKey)) {
      items.slice(0, -1)
    }

    let input = items
      .map((input) => String.fromCharCode(input.which))
      .join('')

    // When parsed from USB-B cable the input contains a +- indicator and pads
    // the left with 0 chars. Trim this string so only the weight remains.
    const trimStartChars = ['+', '-', '0'];
    for(const char of input) {
      if(!trimStartChars.includes(char)) break;
      input = input.substring(1);
    }

    if(input.length === 0) return null;
    
    // Keep a leading 0 if the weight is under 1 unit
    if([',', '.'].includes(input[0])) input = '0' + input;

    // trim whitespace
    input = input.trim();

    // When parsed from 232 key only numbers and a comma/decimal are sent so 
    // we can enforce they're the only characters contained in the string.
    // This regex ensures the string starts and ends with digits and only
    // contains digits and a single decimal point or comma.
    const validChars = new RegExp(/^\d+[\\,\\.]\d+$/g);
    const matches = input.match(validChars);
    if(!matches || matches.length === 0) return null;

    const match = matches[matches.length - 1]
    const weight = parseFloat(match.replace(',', '.'));
    if (isNaN(weight)) return null;

    return {
      value: weight,
      unit: ScaleMeasurementUnit.gram,
      header: ScaleMeasurementHeader.stable,
    }
  }

  parseModelName(data: string) : string | null {
    try {
      const match = this.modelNamePattern.exec(data);
      if(match) {
        const [,modelName] = match;
        return modelName;
      }
    }
    catch(e) {
      this.logger.e('error parsing mode', e);
    }
    return null;
  }

  parseSerialNumber(data: string) : string | null {
    try {
      const match = this.serialNumberPattern.exec(data)
      if(match) {
        const [,serialNumber] = match
        return serialNumber;
      }
    }
    catch(e) {
      this.logger.e('error parsing measurement', e)
    }
    return null
  }
}
