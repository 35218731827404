import React, { useMemo, useState } from 'react';

import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { Button } from 'src/app/components/lib/button';
import { Checkbox } from 'src/app/components/lib/checkbox';
import { ControlBar, ControlGroup, ControlSearchBox, RefreshButton } from 'src/app/components/lib/control-bar';
import { Drawer, DrawerHeader } from 'src/app/components/lib/drawer';
import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { Loader } from 'src/app/components/lib/loader';
import { Page, PageVariant } from 'src/app/components/lib/page';
import { DataGrid, generateRowActions, type GridColDef } from 'src/app/components/lib/table';
import { Type } from 'src/app/components/lib/type';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { CustomerSegmentList } from 'src/app/constants/table-names';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useGetSegmentsQuery } from 'src/app/queries/segment/get-segments';
import { useUpdateSegmentStatusMutation } from 'src/app/queries/segment/update-segment-status';
import { Modal, useModal } from 'src/app/state/modals';
import { useNotificationActions } from 'src/app/state/notifications';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { SegmentQueryBuilder } from './details/segment-query-builder';
import { StyledDrawerContent } from './details/segment-query-builder/query-builder.styles';

export function SegmentsList() {
  const navigate = useNavigate();
  const { data: segments = [], isLoading, refetch, isFetching } = useGetSegmentsQuery();
  const confirmModal = useModal(Modal.confirm);
  const canEditSegments = usePermissionCheck([Permissions.EditSegments]);
  const { mutate: updateSegmentStatusMutator } = useUpdateSegmentStatusMutation();
  const notifications = useNotificationActions();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  // TODO: update this after the rollout
  const [showInactiveSegments, setShowInactiveSegments] = useState<boolean>(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isQueryBuilderEnabled = useLDFlag(LD.CUSTOMERS_QUERY_BUILDER_CHUNK_ROLLOUT);

  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Name',
      renderCell: ({ value }) => (
        <Type px={14} weight='semibold'>
          {value}
        </Type>
      ),
    },
    { field: 'AudienceSize', headerName: 'Audience Size', valueGetter: ({ value }) => value?.toLocaleString() },
    { field: 'CreatedBy', headerName: 'Created By' },
    {
      field: 'CreatedOnUtc',
      headerName: 'Created Date',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'LastEditedUtc',
      headerName: 'Last Edited Date',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'LastCalculatedUtc',
      headerName: 'Audience Last Refreshed',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: '',
      headerName: 'Status',
      renderCell: ({ row }) => {
        const isUpdating = row?.IsUpdating ? 'Updating' : false;
        const isActive = row?.IsDeleted ? 'Inactive' : 'Active';

        return (
          <span>
            {isUpdating || isActive}
            {isUpdating && <InlineLoader size='xs' variant='grey' />}
          </span>
        );
      },
    },
    generateRowActions([
      {
        visible: canEditSegments,
        value: (segment) => (segment?.IsDeleted ? 'Activate' : 'Deactivate'),
        onClick: (segment) => {
          confirmModal.openModal({
            onConfirm: async () => {
              updateSegmentStatusMutator(
                {
                  IsDeleted: !segment.IsDeleted,
                  SegmentId: segment.SegmentId,
                },
                {
                  onSuccess: () => {
                    notifications.success({ message: i18next.t('Segment status updated successfully') });
                  },
                  onError: () => notifications.error({ message: i18next.t('Error updating Segment status') }),
                }
              );
            },
          });
        },
      },
    ]),
  ];

  const filteredSegments = useMemo(
    () =>
      segments.filter((segment) => {
        const matchesSearchTerm = !searchTerm || segment.Name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesInactiveFilter = showInactiveSegments || !segment.IsDeleted;

        return matchesSearchTerm && matchesInactiveFilter;
      }),
    [searchTerm, showInactiveSegments, segments]
  );

  // handlers
  const handleCreateButtonClick = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Page variant={PageVariant.Table}>
        <HeaderContainer>
          <Heading>Segments</Heading>
          <ControlGroup>
            {canEditSegments && isQueryBuilderEnabled && (
              <Button label='Create segment' onClick={handleCreateButtonClick} />
            )}
          </ControlGroup>
        </HeaderContainer>
        <ControlBar>
          <ControlGroup grow>
            <ControlSearchBox placeholder='Search segments...' onChange={(value) => setSearchTerm(value)} />
            <Checkbox
              checked={showInactiveSegments}
              label='Show inactive segments'
              onChange={async ({ target }) => {
                setShowInactiveSegments(target.checked);
              }}
            />
            <RefreshButton isFetching={isFetching} onClick={refetch} />
          </ControlGroup>
        </ControlBar>
        <DataGrid
          columns={columns}
          getRowId={(row) => row.SegmentId}
          height='100%'
          loading={isLoading}
          name={CustomerSegmentList}
          rows={filteredSegments}
          onRowClick={({ row }) => navigate(String(row.SegmentId))}
        />
      </Page>
      <Drawer open={drawerOpen} onClose={handleDrawerClose}>
        <DrawerHeader>
          <Heading>Create segment</Heading>
          <Button label='Close' variant='secondary' onClick={handleDrawerClose} />
        </DrawerHeader>
        <StyledDrawerContent>
          <SegmentQueryBuilder handleDrawerClose={handleDrawerClose} />
        </StyledDrawerContent>
      </Drawer>
    </>
  );
}

const InlineLoader = styled(Loader)`
  display: inline-block;
`;
