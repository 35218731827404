import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { BohExitIcon } from 'src/app/components/icons/boh-exit-icon';
import { ChevronRight } from 'src/app/components/icons/chevron-right';
import { Divider } from 'src/app/components/lib/divider';
import { BackButtonIcon } from 'src/app/components/lib/heading/back-icon';
import { IconButton } from 'src/app/components/lib/icon-button';
import { useBohNav } from 'src/app/hooks/use-boh-nav';

import type { DrawerProps } from '@mui/material';

type SubNavigationProps = {
  onBackAction: () => void;
  onClose: DrawerProps['onClose'];
  onSubNavClick: (event, link) => void;
  parentSection: string;
};

export function SubNavigation({ onBackAction, onClose, onSubNavClick, parentSection }: SubNavigationProps) {
  const links = useBohNav();

  const [activeSection, setActiveSection] = useState(parentSection);
  const subNavItems = links?.find((navItem) => navItem.label === activeSection)?.subNavItems;

  useEffect(() => {
    setActiveSection(parentSection);
  }, [parentSection]);

  return (
    parentSection &&
    subNavItems && (
      <SidebarContainer>
        <MenuHeader>
          <BackAndTitle>
            <BackIconButton onClick={onBackAction}>
              <BackButtonIcon />
            </BackIconButton>
            {activeSection && <SubNavSectionTitle>{activeSection}</SubNavSectionTitle>}
          </BackAndTitle>
          <IconButton onClick={onClose}>
            <BohExitIcon sx={{ width: '16px', height: '16px' }} />
          </IconButton>
        </MenuHeader>
        <NavigationItems>
          {subNavItems?.map((item) => {
            const { visible, label } = item;
            return (
              visible &&
              (label === 'Configure' && subNavItems.length > 1 ? (
                <>
                  <SubNavItemDivider variant='compact' />

                  <SubNavigationMenuItem onClick={(e) => onSubNavClick(e, item)}>
                    <SubNavItem data-testid={`index_sub-nav-item_${label}`} key={label}>
                      {label}
                    </SubNavItem>
                    <StyledChevronIcon />
                  </SubNavigationMenuItem>
                </>
              ) : (
                <SubNavigationMenuItem onClick={(e) => onSubNavClick(e, item)}>
                  <SubNavItem data-testid={`index_sub-nav-item_${label}`} key={label}>
                    {label}
                  </SubNavItem>
                  <StyledChevronIcon />
                </SubNavigationMenuItem>
              ))
            );
          })}
        </NavigationItems>
      </SidebarContainer>
    )
  );
}

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--sizes-110);

  overflow-y: scroll;

  /* hide scrollbar for most browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  li {
    font-size: 16px;
    padding: var(--sizes-40) var(--sizes-70);
  }

  .nested-navigation-icon__svg {
    font-size: 16px;
  }
`;

const SubNavigationMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--sizes-40) var(--sizes-70);

  svg {
    path {
      fill: var(--color-gray-70);
    }
  }

  :hover {
    outline-color: var(--color-gray-70);
    color: var(--color-gray-20);
    cursor: pointer;
  }
`;

const StyledChevronIcon = styled(ChevronRight)`
  display: flex;
  align-self: flex-end;
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 56px 32px 20px;

  button {
    padding: 0;
    width: 20px;
    height: 20px;
    :focus {
      outline: 0;
    }

    :hover {
      svg {
        path {
          fill: var(--color-gray-90);
        }
      }
      padding: 6px;
      background: var(--color-gray-10);
      border-radius: 18px;
    }
  }
`;

const BackAndTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    padding: 0;
    width: 40px;
    height: 40px;
    :focus {
      outline: 0;
    }
  }
`;

const BackIconButton = styled(IconButton)`
  margin-right: var(--sizes-50);
  border: 1px solid var(--color-gray-40);
`;

const SubNavSectionTitle = styled.div`
  font: var(--font-headings-heading-1);
`;

const SubNavItem = styled.div`
  letter-spacing: var(--font-letter-spacing);
  font: var(--font-large-16pt-normal);
  color: var(--color-gray-90);
`;

const SubNavItemDivider = styled(Divider)`
  width: 84%;
  margin: var(--sizes-30) var(--sizes-60);
`;
