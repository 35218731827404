import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v1';

import FormatDate from 'src/app_deprecated/components/form/FormatDate.react';
import Price from 'src/app_deprecated/components/form/Price.react';
import TableLogic from 'src/app_deprecated/components/table/TableLogic';
import ReportStore from 'src/app_deprecated/stores/ReportStore';
import UserStore from 'src/app_deprecated/stores/UserStore';

import { Checkbox } from 'src/app/components/lib/checkbox';
import { FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { Modal } from 'src/app/components/lib/modal';
import { Select } from 'src/app/components/lib/select';
import { totalExportRequestCountLimit } from 'src/app/queries/graphql';
import { useNotificationActions } from 'src/app/state/notifications';
import { formatDate } from 'src/app/utils/formatters';

import { getLayout, getComputedColumns, filterColumnsWithoutHeaders } from '../utils';

import { TwoColumnLayout, ColumnRow } from './table-modal.styles';

import type { GridValues, columnLayout } from '../utils';
import type { UseQueryResult } from '@tanstack/react-query';
import type { GridColDef } from 'src/app/components/lib/table';

export type PrintTableModalProps = {
  gridValues: GridValues;
  isReportTable: boolean;
  onClose: () => void;
  open: boolean;
  paginatedQuerySet?: UseQueryResult[];
};

export function PrintTableModal({ gridValues, isReportTable, onClose, open, paginatedQuerySet }: PrintTableModalProps) {
  const [columns, setColumns] = useState<columnLayout[]>([]);
  const [fontSize, setFontSize] = useState(12);
  const [header, setHeader] = useState('');
  const [print, setPrint] = useState('Page');
  const [printRows, setPrintRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const notifications = useNotificationActions();
  const [uuidVal] = useState(uuid());

  useEffect(() => {
    const {
      currentPageRows,
      visibleColumns: unfilteredVisibleColumns,
      sortedColumns: unfilteredSortedColumns,
      sortedFullData,
    } = gridValues;

    const visibleColumns = filterColumnsWithoutHeaders(unfilteredVisibleColumns);
    const sortedColumns = filterColumnsWithoutHeaders(unfilteredSortedColumns);

    if (sortedFullData.length === 0) {
      return;
    }

    // set print rows before we stop calculating columns
    setPrintRows(currentPageRows);

    // if we are on a refactored table that already has columns set, don't
    // rebuild the columns again
    if (paginatedQuerySet?.length && columns.length) {
      return;
    }

    const shownColumns: GridColDef[] = visibleColumns ?? sortedColumns;
    const computedColumns = getComputedColumns(sortedColumns, shownColumns);
    const layout = getLayout(sortedFullData, computedColumns);

    const newLayout = layout.reduce((newLayoutcolumns, currentColumn) => {
      if (!currentColumn.disableExport && currentColumn.visible) {
        const newColumnLayout = {
          align: currentColumn.align,
          exportRender: currentColumn.exportRender,
          order: currentColumn.order,
          name: currentColumn.name.field,
          print: currentColumn.visible,
          title: currentColumn.title,
          type: currentColumn.type,
          valueGetter: currentColumn.valueGetter,
          valueFormatter: currentColumn.valueFormatter,
        };
        newLayoutcolumns.push(newColumnLayout);
      }
      return newLayoutcolumns;
    }, []);

    setColumns(newLayout);
  }, [gridValues]);

  const onCheck = (name: string, checked: boolean) => {
    let columnsCopy = [...columns];

    columnsCopy = columns.map((column) => {
      if (column.name === name) {
        column.print = checked;
      }
      return column;
    });

    setColumns(columnsCopy);
  };

  const onChange = (name: string, newTitle: string) => {
    let columnsCopy = [...columns];

    columnsCopy = columns.map((column) => {
      if (column.name === name) {
        column.title = newTitle;
      }
      return column;
    });

    setColumns(columnsCopy);
  };

  async function handlePrint() {
    let paginatedDataSet = [];

    // if we were passed in paginatedQuerySet props AND the "print" field is "All", fetch the data and render it, then continue here
    if (print === 'All' && paginatedQuerySet) {
      if (paginatedQuerySet.length > totalExportRequestCountLimit) {
        notifications.alert({ message: 'Please print less than 10,000 items at a time' });
        return;
      }
      setLoading(true);
      const results = await Promise.all(paginatedQuerySet.map((query) => query.refetch()));
      results.forEach((result) => {
        if (result.isSuccess) {
          // @ts-ignore TODO
          paginatedDataSet = paginatedDataSet.concat(result.data?.items);
        } else {
          notifications.error({ message: 'There was an error printing this data' });
        }
      });
      setPrintRows(paginatedDataSet);
      setLoading(false);
    }

    void onPrint();
  }

  const onPrint = async () => {
    const printer = window.open('', 'PRINT');
    let align = '';

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < columns.length; i++) {
      let textAlign = 'left';
      switch (columns[i].align) {
        case 'text-right':
          textAlign = 'right';
          break;
        case 'text-center':
          textAlign = 'center';
          break;
      }

      align += `.${String(columns[i].name)} { text-align: ${String(textAlign)} } `;
    }

    const verticalBordersVal =
      '.tablePrint td { border-left: 1px solid #ddd; } .tablePrint td:last-child { border-right: 1px solid #ddd; }';

    printer?.document.write(
      `<style>table { border-collapse: collapse; } .tablePrint td { border-top: 1px solid #ddd; } .tablePrint td, .tablePrint th { padding: 4px 8px; font-size: ${String(
        fontSize
      )}px; } .tablePrint th { background-color: #dddddd; } ${align} border { border-top: 1px solid #ddd; } ${verticalBordersVal} </style>`
    );

    printer?.document.write('</head><body>');
    printer?.document.write(document.getElementById(uuidVal)?.innerHTML ?? '');
    printer?.document.write('</body></html>');
    printer?.document.close(); // necessary for IE >= 10
    setTimeout(() => {
      printer?.focus(); // necessary for IE >= 10*/
      printer?.print();
      printer?.close();
    }, 100);

    onClose();
  };

  const tryDataRender = (renderCol, data) => {
    const { type, valueFormatter, valueGetter } = renderCol;
    const cellValue = _.get(data, renderCol.name);
    const hasValue = cellValue && cellValue !== '' && cellValue !== 'null' && cellValue !== 'undefined';
    const value = valueGetter ? valueGetter({ row: data, value: cellValue }) : cellValue;
    // if we only have valueGetter, the result of calling that function should be the output
    let output = value ?? '';

    if (hasValue && (type === 'dateTime' || type === 'date')) {
      output = formatDate(new Date(value), type === 'dateTime', UserStore.getState());
    }

    if (hasValue && valueFormatter) {
      output = valueFormatter({ value });
    }

    return output;
  };

  const getPrintOptions = () => {
    const { selectedRows, sortedFullData } = gridValues;
    const options = ['All', 'Page'];

    const selectedPrintRows = Array.from(selectedRows.values());
    const areSelectedOnGrid = selectedPrintRows.every((row) => row !== undefined);

    if (selectedRows.size > 0 && sortedFullData.length > 0 && areSelectedOnGrid) {
      options.push('Selected');
    }

    return options;
  };

  const onPrintChange = (printOption: string) => {
    const { currentPageRows, sortedFullData, selectedRows } = gridValues;

    setPrint(printOption);

    const selectedPrintRows = Array.from(selectedRows.values());

    if (printOption === 'Selected') {
      setPrintRows(selectedPrintRows);
    } else if (printOption === 'Page') {
      setPrintRows(currentPageRows);
    } else {
      setPrintRows(sortedFullData);
    }
  };

  const {
    toDate,
    fromDate,
    selectedReport: { requireFromDate, requireToDate },
  } = ReportStore.getState();
  const {
    selectedLocation: { Name: locationName },
  } = UserStore.getState();

  return (
    <>
      <Modal
        Content={
          <FormSection>
            <TwoColumnLayout>
              <Input
                label='Font size:'
                labelPlacement='top'
                value={fontSize}
                onChange={({ target }) => {
                  setFontSize(target.value);
                }}
              />
              <Select
                label='Print:'
                labelPlacement='top'
                value={print}
                onChange={({ target }) => {
                  onPrintChange(target.value);
                }}
              >
                {getPrintOptions().map((printOption) => (
                  <MenuItem key={printOption} value={printOption}>
                    {printOption}
                  </MenuItem>
                ))}
              </Select>
            </TwoColumnLayout>
            <Input
              label='Page header (leave blank for no header):'
              labelPlacement='top'
              value={header}
              onChange={({ target }) => {
                setHeader(target.value);
              }}
            />
            {columns.map((col) => {
              const checked = col.print ? 'checked' : '';
              return (
                <ColumnRow key={col.name}>
                  <Checkbox
                    checked={!!checked}
                    label=''
                    value={!!checked}
                    onChange={({ target }) => onCheck(col.name, target.checked)}
                  />
                  <Input value={col.title} onChange={({ target }) => onChange(col.name, target.value)} />
                </ColumnRow>
              );
            })}
          </FormSection>
        }
        open={open}
        primaryActions={[
          { automationId: 'print-table-modal-print-button', label: 'Print', onClick: handlePrint, loading },
        ]}
        title='Print table'
        onClose={onClose}
      />
      <div className='print-table__table' id={uuidVal}>
        <table className='tablePrint'>
          <thead>
            {header.length > 0 && (
              <tr>
                <td>Header: </td>
                <td>{header}</td>
              </tr>
            )}
            {isReportTable && (
              <>
                <tr>
                  <td>Export Date: </td>
                  <td>{moment().format('MM/DD/YYYY hh:mm A')}</td>
                </tr>
                {requireFromDate && (
                  <tr>
                    <td>From Date: </td>
                    <td>{moment(fromDate).format('MM/DD/YYYY')}</td>
                  </tr>
                )}
                {requireToDate && (
                  <tr>
                    <td>To Date: </td>
                    <td>{moment(toDate).format('MM/DD/YYYY')}</td>
                  </tr>
                )}
                <tr>
                  <td>Location: </td>
                  <td>{locationName}</td>
                </tr>
              </>
            )}
            <tr className='border'>
              {columns.map((col) => {
                if (!col.print) {
                  return null;
                }

                return <th key={col.title}>{col.title}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {printRows.map((row, index) => (
              <tr className='border' key={`table_row_${String(index)}`}>
                {columns.map((col) => {
                  if (!col.print) {
                    return null;
                  }

                  if (col.valueGetter || col.valueFormatter) {
                    return (
                      <td className={col.name} key={col.name}>
                        {tryDataRender(col, row)}
                      </td>
                    );
                  }

                  const val = row[col.name];

                  if (TableLogic.formatAsPrice(col.name)) {
                    return <Price className={col.name} dollar={false} key={col.name} price={val} type='table' />;
                  }

                  if (TableLogic.formatAsDate(col.name)) {
                    if (moment(val).isValid()) {
                      return (
                        <FormatDate
                          className={col.name}
                          date={val}
                          format={TableLogic.formatAsDate(col.name)}
                          key={col.name}
                          type='table'
                        />
                      );
                    }
                    return <td key={col.name}>&nbsp;</td>;
                  }

                  if (TableLogic.formatAsDateTime(col.name)) {
                    if (moment(val).isValid()) {
                      return (
                        <FormatDate
                          className={col.name}
                          date={val}
                          format={TableLogic.formatAsDateTime(col.name)}
                          key={col.name}
                          type='table'
                        />
                      );
                    }
                    return <td key={col.name}>&nbsp;</td>;
                  }

                  return (
                    <td className={col.name} key={col.name}>
                      {row[col.name]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
