import keyMirror from 'keymirror';

export const endPoints = {
  RUN_REPORT_DASHBOARD: 'api/posv3/reports/run-report-dashboard',
  RUN_REPORT: 'api/posv3/reports/run-report',
  GET_DAILY_SALES_SUMMARY: 'api/bi/v2/DailyTotals',
};

export const actions = keyMirror({
  SET_DATE: null,
  SET_MONTH: null,
  // REPORT_LOADED 			: null,
  // DAILY_SALES_LOADED      : null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
