import type { ReactNode } from 'react';
import React from 'react';

import { SummaryTableContainer, SummaryTableCaption } from './summary-table.styles';

type SummaryTableProps = {
  caption: string;
  children: ReactNode;
  className?: string;
  equalColumns?: boolean;
  id?: string;
  showCaption?: boolean;
};

export function SummaryTable({
  caption,
  className,
  children,
  equalColumns,
  showCaption = false,
  id,
}: SummaryTableProps) {
  return (
    <SummaryTableContainer aria-label={caption} className={className} equalColumns={equalColumns} id={id}>
      <SummaryTableCaption show={showCaption}>{caption}</SummaryTableCaption>
      {children}
    </SummaryTableContainer>
  );
}
