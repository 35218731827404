import React from 'react';

type DutchieLogoProps = {
  color?: string;
  onClick?: () => void;
};

export function DutchieLogo({ color = 'var(--color-brand-primary-white)', onClick }: DutchieLogoProps): JSX.Element {
  return (
    <svg fill='none' height='24' viewBox='0 0 107 24' width='107' xmlns='http://www.w3.org/2000/svg' onClick={onClick}>
      <path
        d='M22.7416 16.8747C21.4717 17.5299 20.0921 18.0213 18.7673 18.3116C17.2545 18.6392 15.7024 18.7509 14.1582 18.6392C11.5636 18.4308 9.04733 17.2991 7.31497 15.4378C7.10333 15.2144 6.93087 14.9389 6.60165 14.8645C6.07645 14.7528 5.70804 15.0953 5.58262 15.5494C4.30491 20.0538 8.64755 24.3497 13.4057 23.9775C17.6856 23.7318 21.6363 21.3567 23.6822 17.7756C23.8076 17.5671 23.7998 17.3289 23.6744 17.1278C23.4863 16.8151 23.0708 16.696 22.7416 16.8747Z'
        fill={color}
      />
      <path
        d='M12.0339 1.2248C13.2646 1.94699 14.409 2.83298 15.334 3.77852C16.3922 4.85808 17.2702 6.0791 17.9443 7.40435C19.0496 9.64537 19.2691 12.2735 18.446 14.6337C18.3441 14.924 18.1795 15.1995 18.2814 15.5048C18.446 15.9887 18.932 16.1227 19.418 16.0036C24.1683 14.7975 25.9085 9.08698 23.1884 5.35691C20.8211 1.95444 16.6823 -0.100448 12.3945 0.00378545C12.1437 0.0112307 11.9242 0.130356 11.8066 0.331378C11.6106 0.636632 11.7125 1.04612 12.0339 1.2248Z'
        fill={color}
      />
      <path
        d='M13.1705 8.55086C12.2063 8.41685 11.2343 8.6402 10.4504 9.19115C10.0428 9.47407 9.70573 9.83144 9.4549 10.2484C8.72589 11.4471 8.81212 12.9435 9.68222 14.0529C10.2623 14.7974 11.1167 15.2888 12.0809 15.4228C13.045 15.5568 14.017 15.3335 14.8009 14.7825C15.2085 14.4996 15.5456 14.1422 15.7964 13.7253C16.5254 12.5266 16.4392 11.0301 15.5691 9.92078C14.9969 9.17626 14.1425 8.68487 13.1705 8.55086Z'
        fill={color}
      />
      <path
        d='M12.1436 5.88549C12.4572 5.82593 12.7942 5.81849 13.0215 5.58768C13.3821 5.20798 13.2645 4.73893 12.904 4.40389C9.43141 1.09821 3.34856 2.52769 1.31049 6.63002C-0.602154 10.2782 -0.414021 14.7081 1.82785 18.185C1.96111 18.3935 2.17276 18.5052 2.42359 18.5052C2.79985 18.5052 3.1134 18.2222 3.1134 17.8649C3.1526 16.495 3.38776 15.1101 3.78753 13.8742C4.24218 12.4671 4.91631 11.1344 5.79424 9.91336C7.29144 7.88081 9.58035 6.38432 12.1436 5.88549Z'
        fill={color}
      />
      <path
        d='M31.3719 12.8847C31.3719 9.43011 33.4727 7.05507 36.9923 7.05507C38.4817 7.05507 39.7515 7.6358 40.4413 8.49201V2.98998C40.4413 2.81129 40.5903 2.66983 40.7784 2.66983H43.0673C43.2555 2.66983 43.4044 2.81129 43.4044 2.98998V18.2155C43.4044 18.3942 43.2555 18.5356 43.0673 18.5356H40.7784C40.5903 18.5356 40.4413 18.3942 40.4413 18.2155V17.2402C39.8534 18.1336 38.5287 18.7218 37.0393 18.7218C33.4727 18.7143 31.3719 16.3393 31.3719 12.8847ZM40.6216 12.8847C40.6216 10.8447 39.3674 9.49711 37.4548 9.49711C35.5657 9.49711 34.3115 10.8447 34.3115 12.8847C34.3115 14.9247 35.5657 16.2723 37.4548 16.2723C39.3674 16.2723 40.6216 14.9247 40.6216 12.8847Z'
        fill={color}
      />
      <path
        d='M55.7661 18.535H53.4771C53.289 18.535 53.1401 18.3936 53.1401 18.2149V16.8375C52.3876 17.9394 51.0393 18.7212 49.3618 18.7212C46.7907 18.7212 45.0192 17.1055 45.0192 14.6412V7.56819C45.0192 7.38951 45.1681 7.24805 45.3562 7.24805H47.6451C47.8333 7.24805 47.9822 7.38951 47.9822 7.56819V13.9934C47.9822 15.2145 49.0248 16.2345 50.3103 16.2121C52.1132 16.1823 53.1322 14.7082 53.1322 12.1991V7.56819C53.1322 7.38951 53.2812 7.24805 53.4693 7.24805H55.7582C55.9463 7.24805 56.0953 7.38951 56.0953 7.56819V18.2223C56.1031 18.3936 55.9542 18.535 55.7661 18.535Z'
        fill={color}
      />
      <path
        d='M63.3069 18.7141C60.9004 18.7141 59.1053 17.4782 59.1053 14.8351V9.61602H57.4435C57.2554 9.61602 57.1064 9.47456 57.1064 9.29588V7.56858C57.1064 7.3899 57.2554 7.24844 57.4435 7.24844H59.1053V4.25545C59.1053 4.07676 59.2543 3.9353 59.4424 3.9353H61.7313C61.9194 3.9353 62.0683 4.07676 62.0683 4.25545V7.24844H64.7257C64.9138 7.24844 65.0627 7.3899 65.0627 7.56858V9.29588C65.0627 9.47456 64.9138 9.61602 64.7257 9.61602H62.0683V14.5745C62.0683 15.6541 62.6798 16.2348 63.6047 16.2348C63.9183 16.2348 64.271 16.2051 64.6003 16.1083C64.8041 16.0487 65.0157 16.1827 65.0314 16.3837L65.1882 18.1706C65.2038 18.3269 65.1019 18.4684 64.9452 18.5056C64.4356 18.6471 63.8869 18.7141 63.3069 18.7141Z'
        fill={color}
      />
      <path
        d='M65.5723 12.884C65.5723 9.42945 68.0493 7.05441 71.616 7.05441C74.5241 7.05441 76.6092 8.42434 77.1423 10.6281C77.1815 10.8068 77.0639 10.9855 76.8758 11.0153L74.626 11.4322C74.4536 11.462 74.289 11.3652 74.2419 11.2014C73.9284 10.174 72.9877 9.49645 71.7179 9.49645C69.8758 9.49645 68.6764 10.8217 68.6764 12.884C68.6764 14.9464 69.8836 16.2716 71.7179 16.2716C72.9956 16.2716 73.8814 15.6686 74.2341 14.6262C74.289 14.4699 74.4536 14.3656 74.626 14.4029L76.8758 14.8347C77.0639 14.8719 77.1893 15.0506 77.1423 15.2293C76.5622 17.4107 74.4771 18.7137 71.6238 18.7137C68.0493 18.7137 65.5723 16.3386 65.5723 12.884Z'
        fill={color}
      />
      <path
        d='M78.71 2.6618H80.9989C81.187 2.6618 81.3359 2.80326 81.3359 2.98195V8.98281C82.112 7.7469 83.4602 7.0545 85.1142 7.0545C87.7794 7.0545 89.551 8.67011 89.551 11.1345V18.2075C89.551 18.3861 89.402 18.5276 89.2139 18.5276H86.925C86.7368 18.5276 86.5879 18.3861 86.5879 18.2075V11.8269C86.5879 10.5984 85.5532 9.57099 84.2598 9.56354C82.402 9.54865 81.3516 11.0526 81.3516 13.6212V18.2075C81.3516 18.3861 81.2027 18.5276 81.0146 18.5276H78.7256C78.5375 18.5276 78.3886 18.3861 78.3886 18.2075V2.9894C78.3651 2.81071 78.5218 2.6618 78.71 2.6618Z'
        fill={color}
      />
      <path
        d='M90.6406 4.01678C90.6406 3.05635 91.4166 2.28949 92.4827 2.28949C93.5723 2.28949 94.3718 3.0489 94.3718 4.01678C94.3718 4.99956 93.5723 5.76642 92.4827 5.76642C91.4245 5.76642 90.6406 5.007 90.6406 4.01678ZM91.3383 7.23313H93.6272C93.8153 7.23313 93.9642 7.37459 93.9642 7.55327V18.2074C93.9642 18.3861 93.8153 18.5276 93.6272 18.5276H91.3383C91.1501 18.5276 91.0012 18.3861 91.0012 18.2074V7.56072C90.9934 7.38203 91.1501 7.23313 91.3383 7.23313Z'
        fill={color}
      />
      <path
        d='M106.639 13.6286H98.1265C98.3852 15.3335 99.5453 16.3386 101.238 16.3386C102.516 16.3386 103.457 15.81 103.841 14.8794C103.904 14.7305 104.076 14.6486 104.241 14.6932L106.365 15.2367C106.561 15.2889 106.671 15.4824 106.6 15.6611C105.832 17.5894 103.833 18.7137 101.191 18.7137C97.2486 18.7137 95.1478 15.8249 95.1478 12.884C95.1478 9.94317 97.084 7.05441 101.027 7.05441C105.04 7.05441 107 9.87616 107 12.5043C107 12.7724 106.992 13.0776 106.976 13.3233C106.969 13.4945 106.82 13.6286 106.639 13.6286ZM103.817 11.5662C103.676 10.2186 102.751 9.21354 101.035 9.21354C99.5689 9.21354 98.5341 10.04 98.2049 11.5662H103.817Z'
        fill={color}
      />
    </svg>
  );
}
