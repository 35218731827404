import React from 'react';

import i18next from 'i18next';

import { SearchIcon } from 'src/app/components/icons/search-icon';
import { omit } from 'src/app/utils/omit';

import { InputAdornment } from '../input';

import { SearchInput } from './search-rebrand.styles';

import type { TextFieldProps } from '@mui/material';

type SearchProps = TextFieldProps & {
  automationId?: string;
  disableClearIcon?: boolean;
  onReset?: () => void;
};

export function Search(props: SearchProps) {
  const { automationId, disableClearIcon = false, InputProps, inputProps, className = '', placeholder = '' } = props;
  const propsWithTranslatedPlaceholder = omit({ ...props, placeholder: i18next.t(placeholder) }, [
    'automationId',
  ]) as TextFieldProps;
  return (
    <SearchInput
      {...propsWithTranslatedPlaceholder}
      className={`${className}`}
      inputProps={{
        ...inputProps,
        'data-testid': automationId,
        type: disableClearIcon ? undefined : 'search',
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='var(--color-gray-70)' />
          </InputAdornment>
        ),
      }}
    />
  );
}
