import React, { useRef } from 'react';

import { startCase } from 'lodash';
import styled from 'styled-components';

import { Checkbox } from 'src/app/components/lib/checkbox';

export type CheckboxRowProps = {
  disabled: boolean;
  field: string;
  handleCheckboxOnChange: (field: string) => void;
  headerName?: string;
  workingColumnVisibilityModel: Record<string, boolean>;
};

export function CheckboxRow({
  disabled,
  field,
  handleCheckboxOnChange,
  workingColumnVisibilityModel,
  headerName,
}: CheckboxRowProps) {
  const ref = useRef(null);
  const isChecked = workingColumnVisibilityModel[field];

  return (
    <CheckboxRowContainer data-testid='checkbox-row' ref={ref}>
      <Checkbox
        checked={isChecked}
        disabled={disabled}
        label={headerName ?? startCase(field)}
        onChange={() => handleCheckboxOnChange(field)}
      />
    </CheckboxRowContainer>
  );
}

const CheckboxRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sizes-20) var(--sizes-40);
  border-radius: 6px;
  border: 1px solid var(--color-gray-20, #e3e7e9);
  background: var(--color-grayscale-white, #fff);

  &:hover {
    border-radius: var(--button-border-radius-small);
    background-color: var(--color-gray-10);
  }
`;
