import { useEffect, useState } from 'react';

import { HardwareService } from '../HardwareService';
import { usePeripheral } from '../shared/usePeripherals';

import type { Scanner } from './Scanner';
import type { BarcodeData } from './types';
import type { DeviceBarcode } from './useScanners';

export type ScannerHook = {
  scanner: Scanner;
  refresh: () => void;
  barcode: BarcodeData | undefined;
}

export type ScannerHookProps = {
  onBarcode?: (barcode: BarcodeData) => void
}

export const useScanner = (device: Scanner, props?: ScannerHookProps): ScannerHook => {
  const [barcode, setBarcode] = useState<BarcodeData | undefined>(undefined);

  const handleBarcode = (evt: Event) => {
    const detail = (evt as CustomEvent<DeviceBarcode>).detail;
    if(detail.device.id !== device.id) return;
    
    setBarcode(detail.barcode);
    props?.onBarcode?.(detail.barcode);
  };
  
  useEffect(() => {
    HardwareService.scanner.addEventListener('barcode', handleBarcode);
    return () => {
      HardwareService.scanner.removeEventListener('barcode', handleBarcode);
    }
  });
  
  const result = usePeripheral<Scanner>(device, HardwareService.scanner);
  return {
    scanner: result.item.device,
    refresh: result.refresh,
    barcode,
  }
}
