import React from 'react';

import AppActions from 'src/app_deprecated/actions/AppActions';
import { events as AppEvents } from 'src/app_deprecated/constants/AppConstants';
import AppStore from 'src/app_deprecated/stores/AppStore';

class VersionChecker extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      buildNumber: AppStore.getBuildNumber(),
      newVersion: false,
      intervalId: null,
    };

    this.newVerionChange = this.newVerionChange.bind(this);
  }

  componentDidMount() {
    AppStore.addChangeListener(AppEvents.BUILD_NUMBER, this.newVerionChange);
    const intervalId = setInterval(this.reloadVersion, 600000);
    this.setState({
      intervalId,
    });
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(AppEvents.BUILD_NUMBER, this.newVerionChange);
    clearInterval(this.state.intervalId);
  }

  reloadVersion() {
    AppActions.getBuildNumber();
  }

  newVerionChange(newVersion) {
    // If we got a different build number and we already had a build number then its new
    // don't set back to not new after we got a new
    const gotNew = (this.state.buildNumber !== newVersion && !!this.state.buildNumber) || this.state.newVersion;
    if (gotNew && AppStore.getForceRefresh()) {
      location.reload();
    }

    this.setState({
      buildNumber: newVersion,
      newVersion: gotNew,
    });
  }

  render() {
    if (!this.state.newVersion) {
      return null;
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 20,
          backgroundColor: '#a9ea9f',
          cursor: 'pointer',
          padding: 10,
          zIndex: 100,
          width: 250,
          borderRadius: '0px 0px 5px 5px',
        }}
      >
        <u
          onClick={() => {
            location.reload();
          }}
        >
          There is a new version of the application, would you like to refresh?
        </u>
      </div>
    );
  }
}

export default VersionChecker;
