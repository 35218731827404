import React from 'react';

import styled from 'styled-components';

import { ControlGroup } from '../control-bar';

export type HeadingProps = {
  children: React.ReactNode;
  marginBottom?: string;
};

export function HeaderContainer(props) {
  const { children, marginBottom, ...other } = props;

  return (
    <StyledHeaderContainer {...other} $marginBottom={marginBottom}>
      {children}
    </StyledHeaderContainer>
  );
}

export function HeaderContainerButtonsWrapper(props) {
  const { children, ...other } = props;

  return <ControlGroup {...other}>{children}</ControlGroup>;
}

const StyledHeaderContainer = styled.header<{ $marginBottom?: string }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 'var(--sizes-50)'};

  .page-heading {
    margin-bottom: 0;
  }
`;
