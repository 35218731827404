import React from 'react';

import i18next from 'i18next';
import UserStore from '../../stores/UserStore';
import PermissionStore from '../../stores/PermissionStore';

import { Permissions } from '../../constants/PermissionsConstants';

class PopupHeader extends React.Component<any, any> {
  render() {
    const { header, filter, style } = this.props;
    const user = UserStore.getState().data;

    return (
      <div className={`popup-header ${filter ? 'popup-header__padding' : ''}`} style={style}>
        {i18next.t(header.title)}
        {header.subTitle && <span style={{ opacity: '.75' }}>{i18next.t(`   ${header.subTitle}`)}</span>}

        {filter && (
          <div className='popup-header__filter'>
            <span>
              <i className='fa fa-search' />
            </span>
            <input
              placeholder={filter.placeholder ? filter.placeholder : 'Search'}
              type='text'
              value={filter.query}
              onChange={(e) => {
                filter.onChange(e.target.value);
              }}
            />
          </div>
        )}

        {header.actions && (
          <div className='popup-header__actions'>
            {header.actions.onSubtract && (
              <i
                className='fa fa-minus'
                onClick={() => {
                  header.actions.onSubtract();
                }}
              />
            )}
            {header.actions.onAdd && !header.actions.disableAdd && (
              <i
                className='fa fa-plus'
                onClick={() => {
                  header.actions.onAdd();
                }}
              />
            )}
            {header.actions.onRefresh && (
              <i
                className='fa fa-refresh'
                onClick={() => {
                  header.actions.onRefresh();
                }}
              />
            )}
            {header.actions.onExport && (
              <i
                className='fa fa-file-excel-o'
                onClick={() => {
                  header.actions.onExport(header.actions.title);
                }}
              />
            )}
            {header.actions.onPrint && (
              <i
                className='fa fa-print'
                onClick={() => {
                  header.actions.onPrint();
                }}
              />
            )}
            {header.actions.onEdit && !header.actions.disableEdit && (
              <i
                className='fa fa-pencil'
                onClick={() => {
                  header.actions.onEdit();
                }}
              />
            )}
            {header.actions.onFaq && (
              <i
                className='fa fa-question'
                onClick={() => {
                  header.actions.onFaq();
                }}
              />
            )}
            {header.actions.onDelete && (
              <i
                className='fa fa-trash'
                onClick={() => {
                  header.actions.onDelete();
                }}
              />
            )}

            {header.actions.onBack && (
              <i
                className='fa fa-arrow-circle-left'
                onClick={() => {
                  header.actions.onBack();
                }}
              />
            )}
            {header.actions.onRotateLeft && (
              <i
                className='fa fa-undo'
                onClick={() => {
                  header.actions.onRotateLeft();
                }}
              />
            )}
            {header.actions.onRotateRight && (
              <i
                className='fa fa-repeat'
                onClick={() => {
                  header.actions.onRotateRight();
                }}
              />
            )}
            {header.actions.onClose && (
              <i
                className='fa fa-times'
                data-testid='popup-header_i_close'
                onClick={() => {
                  header.actions.onClose();
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PopupHeader;
