import { atom } from 'recoil';

import LabelStore from 'src/app_deprecated/stores/LabelStore';

type Label = {
  Fields: any[];
  Height: number;
  LabelDpi: number;
  LabelId: number;
  LabelsPerPage: number;
  Name: string;
  Orientation: string;
  PrinterId: number;
  PrinterName: string;
  Type: string;
  Width: number;
};

type LabelsAtom = {
  labels: Label[];
};

export const labelsAtom = atom<LabelsAtom>({
  key: 'labels',
  default: {
    labels: [],
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListener() {
    const { labels } = LabelStore;

    setSelf({ labels });
  }

  if (trigger === 'get') {
    changeListener(); // initial call
  }

  LabelStore.addChangeListener(changeListener);

  return () => LabelStore.removeChangeListener(changeListener);
}
