/* eslint-disable no-plusplus */
import React from 'react';

import { format, isBefore, isAfter } from 'date-fns';
import uuid from 'uuid/v1';

import {
  RebrandMonthContainer,
  RebrandMonthDate,
  RebrandDaysOfWeekContainer,
} from './date-picker-month-rebrand.styles';

type DataItem = {
  date: string | false;
  dd: string | false;
  ddd: string | false;
};

type DatePickerMonthProps = {
  afterSelectedDate?: Date;
  allowPastDate?: boolean;
  beforeSelectedDate?: Date;
  data?: DataItem[];
  selectDate?: (date: string) => void;
  selected?: Date;
  today?: Date;
};

export function DatePickerMonth(props: DatePickerMonthProps) {
  const { data, today, selected, selectDate, allowPastDate, afterSelectedDate, beforeSelectedDate } = props;

  const daysOfTheMonth = data ?? [];
  const daysOfTheWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const bufferDays = [];
  let i = 0;
  while (daysOfTheWeek[i] !== daysOfTheMonth[0].dd) {
    bufferDays.push(
      <div className='buffer-day' key={`buffer_${i}`}>
        &nbsp;
      </div>
    );
    i++;
  }

  function handleSelectDate(date) {
    if (
      (isBefore(date, today) && !allowPastDate) ||
      (beforeSelectedDate && isBefore(date, beforeSelectedDate)) ||
      (afterSelectedDate && isAfter(date, afterSelectedDate))
    ) {
      return;
    }
    selectDate(date);
  }

  return (
    <RebrandMonthContainer>
      <RebrandDaysOfWeekContainer>
        {daysOfTheWeek.map((day) => (
          <div className='week-day__dd' key={day}>
            {day}
          </div>
        ))}
      </RebrandDaysOfWeekContainer>

      {bufferDays}

      {daysOfTheMonth.map((day) => {
        if (typeof day.date !== 'string') {
          return null;
        }

        const date = new Date(day.date);

        const isToday = format(today, 'MM/dd/yyyy') === day.date;

        const beforeTodayDisabled = isBefore(date, today) && !allowPastDate;

        const beforeSelectedDisabled = beforeSelectedDate instanceof Date && isBefore(date, beforeSelectedDate);
        const afterSelectedDisabled = afterSelectedDate instanceof Date && isAfter(date, afterSelectedDate);
        const dataTestIdToday = isToday ? 'calendar-month_div_today' : '';
        const selectedDate = format(selected, 'MM/dd/yyyy') === day.date;

        return (
          <RebrandMonthDate
            $afterSelectedDate={afterSelectedDisabled}
            $beforeSelectedDate={beforeSelectedDisabled}
            $beforeTodayDisabled={beforeTodayDisabled}
            $selectedDate={selectedDate}
            $todayDate={isToday}
            data-testid={dataTestIdToday}
            key={uuid()}
            role='button'
            title={isToday ? 'Today' : ''}
            onClick={() => handleSelectDate(format(date, 'MM/dd/yyyy'))}
          >
            {format(date, 'd')}
          </RebrandMonthDate>
        );
      })}
    </RebrandMonthContainer>
  );
}
