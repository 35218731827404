import { Vendors } from "../../utils/vendor";
import { Peripheral } from "../shared/Peripheral";
import type { ConnectionType } from "../shared/index";
import { PeripheralType } from "../shared/index";

import { EscPrinterEncoder, StarPrinterEncoder } from "./encoders";

export abstract class ReceiptPrinter extends Peripheral {  
  abstract print(bytes: Uint8Array): Promise<boolean>
  abstract popCashDrawer(): Promise<boolean>;

  constructor(connectionType: ConnectionType) {
    super(PeripheralType.receiptPrinter, connectionType);
  }

  get encoder(): EscPrinterEncoder { 
    if(this.vendorId && Vendors.star.supported[PeripheralType.receiptPrinter].indexOf(this.vendorId) !== -1) {
      return new StarPrinterEncoder();
    }
    return new EscPrinterEncoder();
  }
}
