import React from 'react';

import styled from 'styled-components';

import { Button } from 'src/app/components/lib/button';

export default function PageDisabledEmptyState(): JSX.Element {
  return (
    <PageDisabledStyledEmptyState>
      <HeroImage />
      <EmptyStateHeader>Feature unavailable</EmptyStateHeader>
      <EmptyStateBody>
        <p>
          This feature is temporarily unavailable as we scale to meet increased demand.
          <br />
          We apologize for the inconvenience. See our status page for the latest updates.
        </p>
        <Container>
          <a href='https://status.dutchie.com/' rel='noopener noreferrer' target='_blank'>
            <StyledButton label='Status Page' variant='secondary' />
          </a>
        </Container>
      </EmptyStateBody>
    </PageDisabledStyledEmptyState>
  );
}

const PageDisabledStyledEmptyState = styled.div`
  padding: 56px 8px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
`;

const EmptyStateHeader = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 20px;
`;

const EmptyStateBody = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const Container = styled.div`
  margin-top: 36px;
`;

const StyledButton = styled(Button)`
  margin: 0 8px;
`;

const StyledSVG = styled.svg`
  margin-bottom: 48px;
`;

function HeroImage(): JSX.Element {
  return (
    <StyledSVG fill='none' height='300' viewBox='0 0 301 300' width='301' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='150.5' cy='150' fill='#8DA1B4' opacity='0.05' r='150' />
      <ellipse cx='150.944' cy='150.444' fill='#8DA1B4' opacity='0.08' rx='122.041' ry='122.929' />
      <circle cx='150.944' cy='150.444' fill='#446583' opacity='0.1' r='98.0769' />
      <path
        d='M64.5002 93C64.5002 84.7157 71.216 78 79.5002 78H221.5C229.785 78 236.5 84.7157 236.5 93V207C236.5 215.284 229.785 222 221.5 222H79.5002C71.216 222 64.5002 215.284 64.5002 207V93Z'
        fill='white'
      />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='47' x='99.5002' y='99' />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='130' x='99.5002' y='117' />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='130' x='98.5002' y='133' />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='130' x='98.5002' y='149' />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='130' x='98.5002' y='165' />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='130' x='99.5002' y='181' />
      <rect fill='#8DA1B4' height='8' opacity='0.5' rx='4' width='130' x='99.5002' y='197' />
      <path
        d='M64.5002 91C64.5002 83.8203 70.3205 78 77.5002 78H223.5C230.68 78 236.5 83.8203 236.5 91H64.5002Z'
        fill='#8DA1B4'
      />
      <rect fill='#8DA1B4' height='8' rx='4' width='39' x='189.5' y='99' />
      <circle cx='73.8303' cy='85.3546' fill='white' r='2' />
      <circle cx='79.8303' cy='85.3546' fill='white' r='2' />
      <circle cx='85.8303' cy='85.3546' fill='white' r='2' />
      <path
        d='M64.5002 91H91.5002V222H79.5002C71.216 222 64.5002 215.284 64.5002 207V91Z'
        fill='#8DA1B4'
        opacity='0.5'
      />
      <circle cx='164.5' cy='161' fill='white' r='35' />
      <path
        d='M159.67 141.5C161.595 138.167 166.406 138.167 168.33 141.5L183.486 167.75C185.41 171.083 183.005 175.25 179.156 175.25H148.845C144.996 175.25 142.59 171.083 144.515 167.75L159.67 141.5Z'
        fill='#C6D0D9'
      />
      <rect fill='#8DA1B4' height='4' rx='2' width='4' x='162' y='166' />
      <rect fill='#8DA1B4' height='15' rx='2' width='4' x='162' y='148' />
      <path
        d='M65.0002 91C65.0002 84.0964 70.5967 78.5 77.5002 78.5H223.5C230.404 78.5 236 84.0964 236 91V207C236 215.008 229.508 221.5 221.5 221.5H79.5002C71.4921 221.5 65.0002 215.008 65.0002 207V91Z'
        stroke='#8DA1B4'
      />
    </StyledSVG>
  );
}
