/* eslint-disable arrow-body-style */
import { useMemo } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { getNavStringFromCategoryAndCname } from 'src/app/pages/reports-v2/helpers';
import { useInventoryPackagesGlobalSearchQuery } from 'src/app/queries/graphql/inventory-packages/get-many-global-search';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { searchTermAtom } from './search-term';
import { useFilteredCatalog } from './use-filtered-catalog';
import { useFilteredNav } from './use-filtered-nav';
import { useFilteredReports } from './use-filtered-reports';

export type SearchResultMetadata = {
  brandName?: string | null;
  packageID?: string | null;
  parentNav?: string;
  quantity?: number | string;
  reportCategory?: string;
  room?: string | null;
  SKU?: string;
};
export enum ResultType {
  INVENTORY_ITEM = 'inventory item',
  PAGE = 'page',
  PRODUCT = 'product',
  REPORT = 'report',
}
export type SearchResult = {
  metadata: SearchResultMetadata;
  primaryDisplayName: string;
  resultType: ResultType;
  to: string;
};
type GlobalSearch = {
  allSourcesDoneLoading: boolean;
  searchResults: SearchResult[] | null;
};

export function useGlobalSearch(): GlobalSearch {
  const filter = useRecoilValue(searchTermAtom);

  const filteredNav = useFilteredNav();

  const { data: inventoryData, isLoading: inventoryIsLoading } = useInventoryPackagesGlobalSearchQuery(filter);
  const { isLoading: catalogIsLoading, filteredCatalog } = useFilteredCatalog();
  const { isLoading: reportsIsLoading, filteredReports } = useFilteredReports();
  const allSourcesDoneLoading = !inventoryIsLoading && !catalogIsLoading && !reportsIsLoading;

  const { locProfile } = useRecoilValue(userDispensariesAtom);

  const ldClient = useLDClient();
  const inventoryNextGen = ldClient?.variation('platform.reports.inventory-performance.rollout', false);
  const employeeNextGenFlag = ldClient?.variation('platform.reports.budtender-performance.rollout', false);

  // one memo per filtered dataset
  const pageSearchResults: SearchResult[] = useMemo(() => {
    return filteredNav.reduce<SearchResult[]>((accumResults, currParentNav) => {
      // if parent nav is visible, include a search result item for each visible subnav item
      if (currParentNav.visible) {
        currParentNav.subNavItems?.forEach((subNavItem) => {
          const { visible, label, to } = subNavItem;
          if (visible) {
            accumResults.push({
              primaryDisplayName: label,
              resultType: ResultType.PAGE,
              to,
              metadata: { parentNav: currParentNav.label },
            });
          }
        });
      }

      return accumResults;
    }, []);
  }, [filteredNav]);

  const inventorySearchResults: SearchResult[] = useMemo(() => {
    const filteredInventory = inventoryData?.items ?? [];
    return filteredInventory.reduce<SearchResult[]>((accumResults, currItem) => {
      const itemAsSearchResult = {
        primaryDisplayName: currItem.product?.whseProductsDescription ?? '(no product name)',
        resultType: ResultType.INVENTORY_ITEM,
        to: `/products/inventory/${String(currItem.id)}`,
        metadata: {
          brandName: currItem.product?.brand?.brandName,
          quantity: currItem.quantity ?? 0,
          packageID:
            locProfile.ShowSourcePackage && !!currItem.sourceSerialNumber
              ? currItem.sourceSerialNumber
              : currItem.serialNumber,
          room: currItem.room?.roomNo,
        },
      };

      accumResults.push(itemAsSearchResult);
      return accumResults;
    }, []);
  }, [inventoryData, locProfile.ShowSourcePackage]);

  const catalogSearchResults: SearchResult[] = useMemo(() => {
    return filteredCatalog.reduce<SearchResult[]>((accumResults, currProduct) => {
      const productAsSearchResult = {
        primaryDisplayName: currProduct.Name,
        resultType: ResultType.PRODUCT,
        to: `/products/catalog/${currProduct.ProductId}`,
        metadata: {
          brandName: currProduct.BrandName,
          SKU: currProduct.Sku,
        },
      };

      accumResults.push(productAsSearchResult);
      return accumResults;
    }, []);
  }, [filteredCatalog]);

  const reportSearchResults: SearchResult[] = useMemo(() => {
    return filteredReports.reduce<SearchResult[]>((accumResults, currReport) => {
      const reportAsSearchResult = {
        primaryDisplayName: currReport.ReportName,
        resultType: ResultType.REPORT,
        to: getNavStringFromCategoryAndCname({
          category: currReport.ReportCategory,
          canonicalName: currReport.canonicalName,
          inventoryPerformanceFlag: inventoryNextGen,
          employeePerformanceFlag: employeeNextGenFlag,
        }),
        metadata: {
          reportCategory: currReport.ReportCategory,
        },
      };

      accumResults.push(reportAsSearchResult);
      return accumResults;
    }, []);
  }, [filteredReports, inventoryNextGen]);

  const aggregatedSearchResults = filter
    ? [...pageSearchResults, ...inventorySearchResults, ...catalogSearchResults, ...reportSearchResults]
    : null;

  return { searchResults: aggregatedSearchResults, allSourcesDoneLoading };
}
