import React, { useEffect, useState, useMemo } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { down, up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled, { css } from 'styled-components';

import UserActions from 'src/app_deprecated/actions/UserActions';
import CompanyApi from 'src/app_deprecated/api/CompanyApi';
import UserApi from 'src/app_deprecated/api/UserApi';
import { endPoints as Endpoints } from 'src/app_deprecated/constants/AppConstants';
import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';
import { actions as ActionTypes } from 'src/app_deprecated/constants/UserConstants';
import UserStore from 'src/app_deprecated/stores/UserStore';
import AppDispatcher from 'src/app_deprecated/utils/AppDispatcher';
import HttpClient from 'src/app_deprecated/utils/HttpClient';

import { AccountIcon } from 'assets/icons/account-icon';
import { HelpIcon } from 'assets/icons/help-icon';
import { RebrandCaret } from 'src/app/components/icons';
import { DutchieLogo } from 'src/app/components/icons/dutchie-logo';
import { SidebarMenu } from 'src/app/components/icons/sidebar-menu';
import { Menu } from 'src/app/components/lib/menu';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useLspObject } from 'src/app/hooks/use-lsp-object';
import { ElevateSuperuserModal } from 'src/app/layout/header/elevate-superuser-modal';
import { OrgManagementModal } from 'src/app/layout/header/org-management-modal';
import { useLocsForLspsQuery } from 'src/app/queries/settings/get-locations-for-lsp';
import { useGetOrgQuery } from 'src/app/queries/settings/get-org';
import { useDarkMode } from 'src/app/state/dark-mode';
import { useNotificationActions } from 'src/app/state/notifications';
import { userAtom } from 'src/app/state/user';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { usePermissionCheck, userPermissionsAtom } from 'src/app/state/user-permissions';

import NavigationMenu from '../navigation-menu';
import NestedNavigationMenu from '../navigation-menu/nested-menu';
import { ServerMigrationBanner } from '../server-migration-ui/server-migration-banner';

import { GeneralHeaderBanner } from './banners/general-header-banner';
import { NonSandboxBanner } from './banners/non-sandbox-banner';
import { useDecagon } from './decagon/use-decagon';
import GlobalSearch from './global-search';
import { LDExplorerModal } from './ld-explorer-modal';
import { LspLocDropdown } from './lsp-loc-dropdown';
import { VersionsModal } from './versions-modal';

import type { LabelData } from './lsp-loc-dropdown';

export function HeaderV2() {
  const location = useLocation();
  const darkMode = useDarkMode();
  const [viewVersionsModal, toggleVersionsModal] = useState(false);
  const [viewLDExplorer, toggleLDExplorer] = useState(false);
  const [viewElevateSuperUser, toggleElevateSuperUser] = useState(false);
  const [viewOrgManagementModal, setViewOrgManagementModal] = useState(false);
  const { perms } = useRecoilValue(userPermissionsAtom);
  const [accountMenu, setAccountMenu] = useState<HTMLElement | null>(null);
  const [helpMenu, setHelpMenu] = useState<HTMLElement | null>(null);
  const [helpDeskUrl, setHelpDeskUrl] = useState('');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [ZDLoaded, setZDLoaded] = useState(false);
  const { decagon } = useDecagon();
  const [isOrgManagementRoute, setIsOrgManagementRoute] = useState(false);
  const [filteredSearchTerm, setFilteredSearchTerm] = useState('');
  const [debouncedFilteredSearchTerm, setDebouncedFilteredSearchTerm] = useState('');
  const [isLocDropdownOpen, setIsLocDropdownOpen] = useState(false);
  // react query client -- used for clearing RQ cache upon lsp/loc change and logout
  const queryClient = useQueryClient();
  const notificationActions = useNotificationActions();
  const isSuperUser = usePermissionCheck(Permissions.SuperUser);

  const isAccountMenuOpen = Boolean(accountMenu);
  const isHelpMenuOpen = Boolean(helpMenu);

  const flags = useFlags();
  const showLocationPublish = flags['pos-publish-locations'] && perms[Permissions.SuperUser];
  const hideGlobalSearch = flags['pos.bo.disable-global-search'];
  const showElevateSuperUser = flags['pos.platform.pos-readonly-superuser.rollout'];

  const serverMigrationConfig = flags['pos.platform.database-migration-configs.operational'];
  const nonSandboxBannerRollout = useLDFlag<boolean>(LD.NON_SANDBOX_BANNER_ALERT, false);

  const { selectedLocation, companies, selectedCompany } = useRecoilValue(userDispensariesAtom);
  const { Id, FullName, UserName } = useRecoilValue(userAtom);

  const { data: locsForLsps } = useLocsForLspsQuery({
    enabled: !!companies.length,
    payload: { SelectedLspValues: companies?.map((l) => l.Id) },
  });

  const { data: org } = useGetOrgQuery();

  const lspObject = useLspObject(locsForLsps ?? []);

  const navigate = useNavigate();

  const desktopView = useBreakpoint(up('largeTablet'));

  async function updateSelectedLocation(company, location) {
    const formData = UserStore.getApiData();

    // first get the locations associated with the selected company
    const newLocations = await CompanyApi.getLocations({
      ...formData,
      LspId: company.LspId,
    });
    const locationWithAccess = (newLocations.Data || []).find((loc) => loc.HasPermission);

    // fwiw I _believe_ this check should be done on the server. We shouldn't be sending down lsp's that
    // the user has no locs available under
    if (!locationWithAccess) {
      // notify the user that they can't access this company(lsp)
      notificationActions.alert({
        message: 'Unauthorized',
        subContent: 'It appears you do not have access to view the requested company',
      });
      return;
    }

    let locationToAssign = locationWithAccess;
    if (location && newLocations.Data) {
      const foundLoc = newLocations.Data.find((loc) => loc.LocId === location.LocId);
      locationToAssign = foundLoc;
    }

    // set the location's lsp id to the id  of the lsp selected from the menu
    locationWithAccess.LspId = company.LspId;

    // clear the RQ cache
    queryClient.clear();

    // now grab images and set default lsp
    void UserApi.setDefaultLsp(formData.SessionId, UserStore.getState().data.Id, company.LspId);
    // this could possibly be removed. We show logo on invoices and in settings.
    const images = await CompanyApi.getLogo({ ...formData, LspId: company.LspId });

    // then set the location and notify all listening stores
    UserActions.selectLocation(locationToAssign);
    AppDispatcher.dispatch({
      actionType: ActionTypes.SELECT_COMPANY,
      data: {
        locations: newLocations.Data || [],
        currentCompany: { ...company, Logo: images.Data[0].Logo },
        currentLocation: locationToAssign,
      },
    });
    queryClient.clear();
  }

  function handleLDExplorerClick() {
    toggleLDExplorer(!viewLDExplorer);
    setHelpMenu(null);
  }

  /**
   * Using this to debounce `filteredLspObject` as it's a memoized value
   * and we don't want to recompute it on every keystroke.
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedFilteredSearchTerm(filteredSearchTerm);
    }, 500);

    return () => clearTimeout(timeout);
  }, [filteredSearchTerm]);

  useEffect(() => {
    const firstRouteParam = location.pathname.split('/')[1];
    setIsOrgManagementRoute(firstRouteParam.includes('organization'));
  }, [location]);

  useEffect(() => {
    if (window.zE) {
      setZDLoaded(true);
    }
  }, [window.zE]);

  useEffect(() => {
    void HttpClient.post(Endpoints.GET_HELP_DESK, UserStore.getApiData()).then((res) => {
      setHelpDeskUrl(res.HelpDeskUrl);
    });
  }, [selectedCompany]);

  useEffect(() => {
    setAccountMenu(null);
  }, [viewVersionsModal]);

  function handleNavigationClick(navigateTo: string) {
    if (navigateTo === 'account') {
      navigate('settings/account');
      setAccountMenu(null);
    }

    if (navigateTo === 'logout') {
      queryClient.clear();
      UserStore.logout();
      setAccountMenu(null);
    }
  }

  const generateLocationLabelData = (): LabelData => {
    if (isOrgManagementRoute) {
      // if we are on the org management route, we should show the org name
      return { orgName: org?.Name ?? 'Organization Management' };
    }
    const selectedLocationName = selectedLocation?.Name;
    const isStaging = !selectedLocation?.IsLive && showLocationPublish;
    const locName = isStaging ? `${selectedLocationName} - STAGING` : selectedLocationName;
    const lspName = selectedCompany?.LspName;
    return { lspName, locName };
  };

  const updateCompanySelection = async (lspId: string, locId: string) => {
    const lspIdInt = parseInt(lspId, 10);
    const locIdInt = parseInt(locId, 10);
    const shouldChangeLocation = parseInt(selectedLocation?.LocId, 10) !== locIdInt;
    const shouldChangeCompany = selectedCompany?.LspId !== lspIdInt;
    const targetCompany = companies.find(({ LspId }) => LspId === lspIdInt);
    const targetLocation = lspObject[lspIdInt].locations.find(({ LocId }) => LocId === locIdInt);
    setIsLocDropdownOpen(false);
    if (isOrgManagementRoute) {
      await updateSelectedLocation(targetCompany, targetLocation);
      navigate('/home');
      return;
    }
    if (!isOrgManagementRoute && !shouldChangeCompany && !shouldChangeLocation) {
      return;
    }
    await updateSelectedLocation(targetCompany, targetLocation);
  };

  const navigateToOrgManagement = (password: string) => {
    void HttpClient.post(
      Endpoints.VERIFY_PASSWORD,
      { Password: password, UserName },
      'Please enter the correct password to continue',
      undefined,
      false
    ).then((res) => {
      if (res) {
        setViewOrgManagementModal(false);
        navigate('organization');
      }
    });
  };

  const filteredLspObject = useMemo(
    () =>
      Object.keys(lspObject).reduce((result, lspId) => {
        const lsp = lspObject[lspId];
        const filteredLocations = lsp.locations.filter((location) =>
          location.LocName.toLowerCase().includes(debouncedFilteredSearchTerm.toLowerCase())
        );

        const lspMatches = lsp.LspName.toLowerCase().includes(debouncedFilteredSearchTerm.toLowerCase());

        if (lspMatches || filteredLocations.length > 0) {
          result[lspId] = {
            ...lsp,
            filteredLocations: filteredLocations.length > 0 ? filteredLocations : lsp.locations,
          };
        }

        return result;
      }, {}),
    [debouncedFilteredSearchTerm, lspObject]
  );

  return (
    <>
      {viewOrgManagementModal && (
        <OrgManagementModal
          navigateToOrgManagement={navigateToOrgManagement}
          onClose={() => setViewOrgManagementModal(false)}
        />
      )}
      {viewVersionsModal && <VersionsModal onClose={() => toggleVersionsModal(false)} />}
      {viewLDExplorer && <LDExplorerModal onClose={handleLDExplorerClick} />}
      {viewElevateSuperUser && <ElevateSuperuserModal onClose={() => toggleElevateSuperUser(false)} />}
      {!!serverMigrationConfig?.backofficeURL && <ServerMigrationBanner migrationConfig={serverMigrationConfig} />}
      <GeneralHeaderBanner />
      {nonSandboxBannerRollout && <NonSandboxBanner />}
      <HeaderContainer $darkMode={darkMode} data-testid='headerv2_container'>
        {desktopView ? (
          <NavigationMenu isOpen={openSidebar} onClose={() => setOpenSidebar(false)} />
        ) : (
          <NestedNavigationMenu isOpen={openSidebar} onClose={() => setOpenSidebar(false)} />
        )}

        <HeaderLeftContentContainer>
          <SidebarToggleLogoContainer>
            <ToggleContainer onClick={() => setOpenSidebar(true)}>
              <SidebarMenu />
            </ToggleContainer>
            {desktopView && (
              <>
                <DutchieLogo onClick={() => navigate('home')} />
                {isOrgManagementRoute && (
                  <OrgManagementTag data-testid='org-management-badge'>Org&nbsp;Management</OrgManagementTag>
                )}
              </>
            )}
          </SidebarToggleLogoContainer>
          {!hideGlobalSearch && <GlobalSearch transparentBackground />}
        </HeaderLeftContentContainer>

        <HeaderRightContentContainer>
          {desktopView && (
            <LspLocDropdown
              filteredSearchTerm={filteredSearchTerm}
              isOpen={isLocDropdownOpen}
              isOrgManagementRoute={isOrgManagementRoute}
              labelData={generateLocationLabelData()}
              openOrgManagementModal={() => setViewOrgManagementModal(true)}
              setFilteredSearchTerm={setFilteredSearchTerm}
              setIsOpen={setIsLocDropdownOpen}
            >
              {Object.keys(filteredLspObject).map((lspId) => {
                const lsp = filteredLspObject[lspId];
                const filteredLocations = lsp.locations.filter((location) =>
                  location.LocName.toLowerCase().includes(filteredSearchTerm.toLowerCase())
                );

                const lspMatches = lsp.LspName.toLowerCase().includes(filteredSearchTerm.toLowerCase());
                const locationMatches = filteredLocations.length > 0;
                const locsToLoop = filteredLocations.length > 0 ? filteredLocations : lsp?.locations;

                // Only show LSP if it matches the search term or if any of its locations match
                if (lspMatches || locationMatches) {
                  return (
                    <div key={lspId}>
                      <StyledAccordion>
                        <StyledAccordionSummary
                          expandIcon={<RebrandCaret color='var(--color-grayscale-black)' height='7px' width='14px' />}
                        >
                          <StyledAccordionLabel>{lsp.LspName}</StyledAccordionLabel>
                        </StyledAccordionSummary>
                        <AccordionDetails
                          sx={{
                            paddingTop: '0',
                            paddingBottom: '0',
                          }}
                        >
                          {locsToLoop.map((location) => (
                            <MenuItem
                              automationId={`location-${lspId}-${location.LocId}`}
                              key={location.LocId}
                              sx={{
                                padding: '0.625rem!important',
                                '&:first-child': {
                                  paddingTop: '0!important',
                                },
                                '&:last-child': {
                                  paddingBottom: '0!important',
                                },
                                '&:hover': {
                                  backgroundColor: 'transparent!important',
                                },
                              }}
                              value={`${lspId}-${location.LocId}`}
                              onClick={() => updateCompanySelection(lspId, location.LocId)}
                            >
                              <MenuItemText>{location.LocName}</MenuItemText>
                            </MenuItem>
                          ))}
                        </AccordionDetails>
                      </StyledAccordion>
                    </div>
                  );
                }
                return null;
              })}
            </LspLocDropdown>
          )}

          <IconContainer>
            <AccountIcon onClick={(e) => setAccountMenu(e.currentTarget)} />
          </IconContainer>

          <IconContainer>
            <HelpIcon onClick={(e) => setHelpMenu(e.currentTarget)} />
          </IconContainer>
        </HeaderRightContentContainer>
      </HeaderContainer>

      {/* Account Menu */}
      <StyledMenu anchorEl={accountMenu} open={isAccountMenuOpen} onClose={() => setAccountMenu(null)}>
        <AccountVersionsMenuItem $darkMode={darkMode} value=''>
          <AccountFullName data-testid='rebrand-header_div_user-full-name'>{FullName}</AccountFullName>
          <AccountVersions>
            <div title='User ID'>{Id}</div>
            <div>
              <AccountVersionsButton type='button' onClick={() => toggleVersionsModal(true)}>
                Versions
              </AccountVersionsButton>
            </div>
          </AccountVersions>
        </AccountVersionsMenuItem>
        <AccountMenuItem
          $darkMode={darkMode}
          data-testid='rebrand-header_account-menu-item_my-account'
          value='My account'
          onClick={() => handleNavigationClick('account')}
        >
          My account
        </AccountMenuItem>
        {isSuperUser && showElevateSuperUser && (
          <AccountMenuItem
            $darkMode={darkMode}
            data-testid='rebrand-header_account-menu-item_enable_edit_access'
            value='Settings'
            onClick={() => {
              setAccountMenu(null);
              setHelpMenu(null);
              toggleElevateSuperUser(true);
            }}
          >
            Enable edit access
          </AccountMenuItem>
        )}
        <AccountMenuItem
          $darkMode={darkMode}
          data-testid='rebrand-header_account-menu-item_logout'
          value='Logout'
          onClick={() => handleNavigationClick('logout')}
        >
          Logout
        </AccountMenuItem>
      </StyledMenu>

      {/* Help Menu */}
      <StyledMenu anchorEl={helpMenu} open={isHelpMenuOpen} onClose={() => setHelpMenu(null)}>
        {ZDLoaded && !decagon && (
          <HelpMenuItem
            $darkMode={darkMode}
            value='Live chat'
            onClick={() => {
              window.zE.activate();
              setHelpMenu(null);
            }}
          >
            <span>Live chat</span>
          </HelpMenuItem>
        )}
        {decagon && (
          <HelpMenuItem
            $darkMode={darkMode}
            value='Live chat'
            onClick={() => {
              decagon.open();
              setHelpMenu(null);
            }}
          >
            <span>Live chat</span>
          </HelpMenuItem>
        )}
        <a href='https://support.dutchie.com/hc/en-us' rel='noopener noreferrer' target='_blank'>
          <HelpMenuItem $darkMode={darkMode} value='Help center'>
            Help center
          </HelpMenuItem>
        </a>
        {helpDeskUrl && (
          <a href={`//${helpDeskUrl}`} rel='noopener noreferrer' target='_blank'>
            <HelpMenuItem $darkMode={darkMode} value='Internal help desk'>
              Internal help desk
            </HelpMenuItem>
          </a>
        )}
        {isSuperUser && (
          <HelpMenuItem $darkMode={darkMode} value='LD Explorer' onClick={handleLDExplorerClick}>
            LD Explorer
          </HelpMenuItem>
        )}
      </StyledMenu>
    </>
  );
}

const HeaderContainer = styled.div<{ $darkMode: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--color-opal-90);
  z-index: 10;
  padding: 0 var(--sizes-50) 0 var(--sizes-40);

  @media only screen and (max-width: 768px) {
    svg:nth-child(2) {
      display: none;
    }
  }

  .select-form-control {
    display: block;
    width: fit-content;
    margin-bottom: 0;

    fieldset {
      border: none;
      padding: 0;
    }

    .MuiOutlinedInput-root {
      padding: 0;
    }

    .MuiSelect-select {
      font: var(--font-small-13pt-normal);
    }
  }

  .sub-navigation-panel {
    &-enter {
      opacity: 0;
      transform: translateX(-100%);
    }
    &-enter-done {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 300ms linear, transform 300ms ease-in;
    }
    &-exit {
      opacity: 1;
      transform: translateX(0%);
    }
    &-exit-active {
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 300ms linear, transform 300ms ease-in;
    }
  }

  .global_search_icon-focused {
    display: none;
  }

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      border-bottom: 1px solid #1f3244;
    `}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  border-bottom: 1px solid var(--color-gray-20);
  padding: 0.625rem 1.25rem 0.625rem 1.25rem;

  &.Mui-expanded {
    border-bottom: 1px solid transparent;
    min-height: 48px;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const MenuItemText = styled.div`
  font: var(--font-small-13pt-normal);
`;

const HeaderLeftContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const SidebarToggleLogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 100%;
  border-right: 1px solid var(--color-grayscale-black);
  padding: var(--sizes-30) var(--sizes-70) var(--sizes-30) 0;

  ${down('largeTablet')} {
    padding: 0;
  }

  svg:hover {
    cursor: pointer;
  }
`;

const ToggleContainer = styled.div`
  padding: var(--sizes-20);
  border-radius: 50%;
  display: flex;
  margin-right: var(--sizes-40);

  :hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.16);
  }
`;

const HeaderRightContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--sizes-60);

  .select-select-input {
    font-family: var(--font-family-text);
    background: none;

    .MuiInputBase-input {
      padding-left: 0;
      padding-right: var(--sizes-60);
    }

    > svg {
      width: var(--sizes-30);
      height: 7px;
      top: calc(50% - 0.2em);
      right: -10px;
      margin-right: 10px;

      path {
        fill: var(--color-gray-10);
      }
    }

    &.Mui-focused .MuiSelect-icon.MuiSelect-iconOutlined {
      path {
        fill: var(--color-gray-10);
      }
    }
  }

  .MuiInputBase-input {
    color: var(--color-gray-10);
    line-height: var(--sizes-40);
  }
`;

const IconContainer = styled.div`
  display: flex;

  svg {
    display: block;
    path {
      fill: var(--color-gray-10);
    }
  }

  :hover {
    cursor: pointer;
    background: none;
  }
`;

const StyledMenu = styled(Menu)`
  margin-top: var(--sizes-20);

  .MuiPopover-paper {
    width: fit-content;
    max-width: 400px;
    min-width: 200px;
  }
`;

const StyledMenuItem = styled(MenuItem)<{ $darkMode: boolean }>`
  height: 52px;
  color: #000000;
  padding: var(--sizes-40) var(--sizes-70);
  background: none;

  .Mui-focusVisible {
    background-color: none;
    background: none;
  }

  :hover {
    background: transparent;
    background-color: #f3f3f3;
    font-weight: 600;
  }
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: var(--color-brand-primary-white);
      :hover {
        background: transparent;
        background-color: var(--color-gray-90);
      }
    `}
`;

const AccountMenuItem = styled(StyledMenuItem)`
  :nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: none;
    align-items: flex-start;
    padding: var(--sizes-20) var(--sizes-70) var(--sizes-30) var(--sizes-70);

    .logged-in-as {
      font: var(--font-micro-10pt-bold);
      margin-bottom: 2px;
      color: #a6a6a6;
    }

    :hover {
      cursor: auto;
      font-weight: 400;
      background: transparent;
      border-radius: 9px 9px 0 0;
    }

    :disabled {
      color: #000000;
    }

    .Mui-focusVisible {
      background-color: none;
    }
  }

  :nth-child(3) {
    border-bottom: none;
  }
`;

const AccountVersionsMenuItem = styled(StyledMenuItem)`
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-gray-40);
  justify-content: space-between;
  padding-top: var(--sizes-30);
  padding-bottom: var(--sizes-30);
  margin-bottom: var(--sizes-20);

  :hover,
  :focus {
    background: transparent;
    cursor: auto;
  }
`;

const AccountFullName = styled.div`
  font: var(--font-extra-small-12pt-semibold);
  text-wrap: wrap;
`;

const AccountVersions = styled.div`
  color: var(--color-gray-70);
  font-size: var(--typography-mini-11pt-semibold-font-size);
  font-weight: var(--typography-extra-small-12pt-normal-font-weight);
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const AccountVersionsButton = styled.button`
  background: none;
  color: var(--color-brand-secondary-sapphire);
  padding: 0;

  :hover {
    text-decoration: underline;
  }
`;

const HelpMenuItem = styled(StyledMenuItem)`
  a {
    color: #000000;
  }
`;

const OrgManagementTag = styled.div`
  background-color: var(--color-blue-60);
  color: var(--color-gray-10);
  font: var(--font-extra-small-12pt-bold);
  text-transform: uppercase;
  margin-left: 12px;
  border-radius: 8px 8px 8px 0;
  padding: 4px 8px;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  .Mui-expanded {
    .current-step {
      background-color: var(--color-brand-primary-opal);
      color: white;
    }
  }
  .MuiAccordionDetails-root {
    border-top: none;
  }
`;

const StyledAccordionLabel = styled.div`
  font: var(--font-regular-14pt-semibold);
`;
