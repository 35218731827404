import type { LDClient } from 'launchdarkly-js-client-sdk';

// eslint-disable-next-line import/no-mutable-exports
let ldClient: LDClient | undefined;

/// Please don't call this from the global scope!  It may not be initialized yet
/// only use if `useLDClient` or `useFlags` is unavailable (e.g. you're outside of a react component)
/// Use `withLDConsumer` for class components
export const getLDClient = (): LDClient | undefined => ldClient;
export const setLDClient = (client: LDClient | undefined) => (ldClient = client);
