import styled, { css } from 'styled-components';

import { IconButton } from '../icon-button';

export const BackIconButton = styled(IconButton)<{ $darkMode: boolean }>`
  margin-right: var(--sizes-50);
  height: var(--sizes-80);
  width: var(--sizes-80);
  border: 1px solid var(--color-gray-40);
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      border-color: var(--color-gray-80);
    `}
`;

type HeaderStyleProps = {
  hasSubheader: boolean;
};

const getHeaderBottomMargin = (hasSubheader: boolean) =>
  hasSubheader
    ? css`
        margin-block-end: var(--sizes-10);
      `
    : css`
        margin-bottom: 0;
      `;

export const StyledH1 = styled.h1<HeaderStyleProps>`
  font: var(--font-headings-heading-1);
  margin-top: 0;
  line-height: 26px;
  ${({ hasSubheader }) => getHeaderBottomMargin(hasSubheader)};
`;

export const StyledH2 = styled.h2<HeaderStyleProps>`
  font: var(--font-headings-heading-2);
  margin-top: 0;
  ${({ hasSubheader }) => getHeaderBottomMargin(hasSubheader)};
`;

export const StyledH3 = styled.h3<HeaderStyleProps>`
  font: var(--font-headings-heading-3);
  margin-top: 0;
  ${({ hasSubheader }) => getHeaderBottomMargin(hasSubheader)};
`;

export const StyledH4 = styled.h4<HeaderStyleProps>`
  font: var(--font-large-16pt-semibold);
  margin-top: 0;
  ${({ hasSubheader }) => getHeaderBottomMargin(hasSubheader)};
`;

const getContainerMarginBottom = (level: number) => (level === 1 ? 'var(--sizes-50)' : 'var(--sizes-70)');

export const HeadingContainer = styled.div<{ $level?: number; $marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: ${({ $marginBottom, $level }) => $marginBottom ?? getContainerMarginBottom($level)};
  margin-top: 0;
`;

export const HeadingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0;
`;

export const SubHeaderContainer = styled.span<{ $level?: number }>`
  font: ${({ $level }) => ($level === 4 ? 'var(--font-small-13pt-normal)' : 'var(--font-regular-14pt-normal)')};
  color: var(--color-gray-80);
`;
