import _ from 'lodash';
import AjaxPromises from '../utils/AjaxPromises';
import UserStore from './UserStore';
import { SimpleCachedDataStore } from './SimpleCachedDataStore';
import { endPoints as InventoryEndPoints } from '../constants/InventoryConstants';

class ExternalCategoriesStore extends SimpleCachedDataStore {
  constructor() {
    super();
  }

  sourceUrl = InventoryEndPoints.GET_EXTERNAL_CATEGORIES;
  entity = 'External Categories';
  get categories() {
    return this.data;
  }

  emitChange(change) {
    super.emitChange(change);
  }
}
export default new ExternalCategoriesStore();
