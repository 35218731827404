import type { GridEnrichedColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import type { ServerPaginatedTables } from 'src/app/constants/table-names-paginated';

export type BaseResponse<T extends { data?: unknown }> = {
  data?: T['data'];
};

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum LogicalOperator {
  AND = 'and',
  OR = 'or',
}

export enum FilterOperator {
  CONTAINS = 'contains',
  EQUALS = 'eq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  IN = 'in',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  NOT_EQUAL = 'neq',
  NOT_IN = 'nin',
  VARIABLE = 'variable',
}

export enum OneToManyFilterOperator {
  ALL = 'all',
  ANY = 'any',
  NONE = 'none',
  SOME = 'some',
}

/**
 * Used to filter a resource by a related resource with which it has a one-to-many relationship.
   * The navigationField is the top level field, generally in the syntax {resourceToResource}.
   * The manyField is the nested field for which there are many.
   * The operator is used to determine how to compare - one of any, all, some, or none.
   * Example: 
        packageToItemLabels is a one-to-many relationship (one package has many item labels)
        Requesting the resource in the gql document:
            packageToItemLabels {
              packageId
              itemLabel
            }
        To filter by itemLabel, use "itemLabel" as the manyField and "packageToItemLabels" as the navigationField.
   */
type OneToManyFilter = { manyField: string; navigationField: string; operator: OneToManyFilterOperator };

/**
 * Model for instantiating filters used by server table controls hook.
 */
export type FilterDefinition<T> = {
  /**
   * This function is used to transform the value of the filter before it is passed to the API. This is useful for cases where the value of the filter is not the same as the value of the data field. For example, if the data field is a date, but the filter value is a string, this function can be used to convert the string to a date before passing it to the API.
   */
  customValueToApi?: (val: any) => any;
  /**
   * This is used to pass additional variables to a query that need to be tracked in the url
   */
  field?: string;
  /**
   * One or more data fields to be compared against.
   */
  fields?: Array<string | keyof AbridgedFilterInput<T>>;
  /**
   * The starting value for this filter. Its type also acts as the source of truth for the FilterModel value passed to the API.
   */
  initialValue: number[] | string[] | boolean | number | string | null;
  /**
   * The logical operator that will be used to logically compare values referenced by the `fields` list. (Note that if `fields` only has one item, this operator will function identically regardless of whether AND or OR is used)
   */
  logicalOperator?: LogicalOperator;
  /**
   * One or more data fields that have a one-to-many relationship with the starting resource.
   */
  oneToManyFields?: Array<OneToManyFilter>;
  /**
   * The comparator for a `field` and the `value`. e.g., `field` CONTAINS `value`
   */
  operator?: FilterOperator;
};

export type FilterModel<T> = {
  /**
   * This function is used to transform the value of the filter before it is passed to the API. This is useful for cases where the value of the filter is not the same as the value of the data field. For example, if the data field is a date, but the filter value is a string, this function can be used to convert the string to a date before passing it to the API.
   */
  customValueToApi?: (val: any) => any;
  /**
   * This is used to pass additional variables to a query that need to be tracked in the url
   */
  field?: string;
  /**
   * One or more data fields to be compared against.
   */
  fields?: Array<string | keyof AbridgedFilterInput<T>>;
  /**
   * The logical operator that will apply to the `fields` list.
   */
  logicalOperator?: LogicalOperator;
  /**
   * If or[{ field: multiValues[0] },{ field: multiValues[1] }, ...] is desired, then multiValues should be used instead of value
   * Assumes fields is of length 1
   */
  multiValues?: string[];
  /**
   * One or more data fields that have a one-to-many relationship with the starting resource.
   */
  oneToManyFields?: Array<OneToManyFilter>;
  /**
   * The comparator for a `field` and the `value`. e.g., `field` CONTAINS `value`
   */
  operator: FilterOperator;
  urlParam?: string;
  /**
   * The value to compare against the data field(s).
   */
  value?: number[] | string[] | boolean | number | string;
};

/**
 * Configuration model used in our query hooks to specify pagination, filtering, and sorting.
 */
export type QueryProps<TFilter, TSort> = {
  applyUserId?: boolean;
  // variables used for directives (@skip, @include)
  conditionalFields?: Record<string, boolean>;
  disabled?: boolean;
  filterModel?: FilterModel<TFilter>[];
  page: number;
  pageSize: number;
  sort?: SortItem<TSort>[];
  tableName?: ServerPaginatedTables;
};

export type SortItem<T> = {
  direction: Direction;
  key: keyof T;
};

export type PaginationProps = {
  skip: number;
  take: number;
};

/**
 * Utility type for removing keys that don't represent valid filter fields so an input type can be restricted to only the actual fields on the data model
 */
export type AbridgedFilterInput<T> = Omit<T, 'and' | 'locId' | 'lspId' | 'or'>;

type Primitive = bigint | boolean | number | string | symbol | null | undefined;

export type DeepNonNullable<Type> = Type extends Primitive
  ? NonNullable<Type>
  : { [Key in keyof Type]: DeepNonNullable<Type[Key]> };

type DeepRequired<T extends object> = Required<{
  [P in keyof T]: T[P] extends object | undefined ? DeepRequired<Required<T[P]>> : T[P];
}>;

/**
 * Helpers that generate the exported utility type GridColumn
 */
type NestedClone<Type> = {
  [Property in keyof Type]: Type[Property] extends Primitive
    ? Property
    : `${Property & string}.${string & keyof Type[Property]}`;
};

/**
 * Ideally, this would be done recursively, and it's entirely possible.
 * However, the generated sort types reference one another and create circular references, so it's not practical for our gql sort input types.
 */
type GetNested<Type> = {
  [Property in keyof Type]: Type[Property] extends Primitive
    ? Property
    : `${Property & string}.${NestedClone<Type[Property]>[keyof NestedClone<Type[Property]>] & string}`;
};

export type ValidKeys<Type> = GetNested<Type>[keyof GetNested<Type>];

type EnforceSortability<Type extends object> =
  | { field: string; sortable: false }
  | { field: ValidKeys<DeepRequired<DeepNonNullable<Type>>> };

/**
 * Utility type for enforcing the inclusion of `sortable: false`
 * for any column that is not server-side sortable as defined by the type argument T.
 * @param T: a type whose keys can be used for server-side sorting
 * @param U: the row type for the grid
 */
export type GridColumn<
  SortableType extends object,
  RowType extends GridValidRowModel = GridValidRowModel
> = EnforceSortability<SortableType> & Omit<GridEnrichedColDef<GridValidRowModel & RowType>, 'field' | 'sortable'>;
