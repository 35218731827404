import React, { useState } from 'react';

import NotificationActions from 'src/app_deprecated/actions/NotificationActions';

import { ErrorMessageIcon } from 'src/app/components/icons/error-message';
import { InfoMessageIcon } from 'src/app/components/icons/info-message';
import { SuccessMessageIcon } from 'src/app/components/icons/success-message';
import { WarnMessageIcon } from 'src/app/components/icons/warning-message';
import { FormSection, FormTitle } from 'src/app/components/lib/form';
import { Modal, ModalContentText } from 'src/app/components/lib/modal';
import useTimeout from 'src/app/hooks/use-timeout';

import { estimateReadingTime } from './estimate-reading-time';
import {
  NotificationContainer,
  Message,
  SubContent,
  MessageContainer,
  NotificationIcon,
  MoreInfoButton,
} from './notification.styles';
import { NotificationTypes } from './types';

import type { NotificationProps } from './types';

export function Notification(props: NotificationProps): JSX.Element {
  const {
    variant,
    message,
    subContent,
    automationId = '',
    uuid,
    sticky = false,
    additionalInfo,
    isLegacy = false,
    onRemove,
    className = '',
  } = props;

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const timer = estimateReadingTime(message);

  const handleCloseNotification = () => {
    setTimeout(() => (isLegacy ? NotificationActions.removeNotification(uuid) : onRemove(uuid)), 400);
  };

  const handleClickNotification = ({ target }) => {
    if (additionalInfo && !showConfirmationModal && !showAdditionalInfo) {
      setShowConfirmationModal(true);
      return;
    }

    if (target.id === `more-info_${uuid}`) {
      setShowAdditionalInfo(true);
      return;
    }

    handleCloseNotification();
  };

  const getNotificationIcon = () => {
    switch (variant) {
      case NotificationTypes.Error:
        return <ErrorMessageIcon />;

      case NotificationTypes.Alert:
        return <WarnMessageIcon />;

      case NotificationTypes.Success:
        return <SuccessMessageIcon />;

      case NotificationTypes.Info:
        return <InfoMessageIcon />;

      default:
        return null;
    }
  };

  const notificationIcon = getNotificationIcon();

  useTimeout(() => {
    if (!sticky) {
      handleCloseNotification();
    }
  }, timer);

  return (
    <>
      <NotificationContainer
        className={`${className} ${props.shouldRemove ? 'toast-exit-active' : ''}`}
        onClick={handleClickNotification}
      >
        <NotificationIcon>{notificationIcon}</NotificationIcon>
        <MessageContainer data-testid={`${automationId}content`}>
          <Message px={14} weight='semibold'>
            {message}
          </Message>
          {subContent && <SubContent>{subContent}</SubContent>}
          {additionalInfo && (
            <MoreInfoButton
              id={`more-info_${uuid}`}
              label='More info'
              sx={{ backgroundColor: 'none', width: 'fit-content' }}
              onClick={handleClickNotification}
            />
          )}
        </MessageContainer>
      </NotificationContainer>

      {showAdditionalInfo && (
        <Modal
          Content={
            <FormSection>
              {Object.keys(additionalInfo).map((additionalInfoKey) => (
                <div key={additionalInfoKey}>
                  <FormTitle>{additionalInfoKey}</FormTitle>
                  <ModalContentText>{additionalInfo[additionalInfoKey]}</ModalContentText>
                </div>
              ))}
            </FormSection>
          }
          open
          title='Connection error details'
          onClose={() => setShowAdditionalInfo(false)}
        />
      )}

      {showConfirmationModal && (
        <Modal
          open
          primaryActions={[{ label: 'Show info', onClick: () => setShowAdditionalInfo(true) }]}
          secondaryActions={[{ label: 'Discard', variant: 'secondary', onClick: handleCloseNotification }]}
          title='Discard notification or show more info?'
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
}
