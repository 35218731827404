import { Button as MuiButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledButton = styled(MuiButton)<{
  $buttonSize: string;
  $darkMode: boolean;
  $fontSize?: string;
  $fontWeight?: string;
  $labelColor?: string;
  $variant: string;
}>`
  ${({ $darkMode, $variant, $buttonSize }) => css`
    /* Base */
    width: fit-content;
    outline: 1px solid transparent;
    box-shadow: none;
    letter-spacing: var(--button-letter-spacing);
    text-transform: none;
    flex-shrink: 0;
    white-space: nowrap;

    /* Variants */
    ${$variant === 'primary' && primary}
    ${$variant === 'secondary' && secondary($darkMode)}
    ${$variant === 'white' && white}
    ${$variant === 'danger' && danger}
    ${$variant === 'darkBlue' && darkBlue}
    ${$variant === 'lightBlue' && lightBlue}
    ${$variant === 'outlineBlue' && outlineBlue}
    ${$variant === 'yellow' && yellow}
    ${$variant === 'hidden' && hidden}

    /* Sizes */
    ${$buttonSize === 'large' && largeButtonStyles}
    ${$buttonSize === 'medium' && mediumButtonStyles}
    ${$buttonSize === 'small' && smallButtonStyles}
    ${$buttonSize === 'extra-small' && extraSmallButtonStyles}

    :hover, :focus, :active {
      box-shadow: none;
    }

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .MuiButton-startIcon {
      margin-left: 0;
    }
    .MuiButton-endIcon {
      margin-right: 0;
    }
  `}
`;

// VARIANTS
const primary = css`
  background-color: var(--color-brand-secondary-sapphire);
  color: var(--color-brand-primary-white);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-brand-primary-white);
    }
  }

  :hover {
    background-color: var(--color-blue-70);
  }

  :focus {
    outline: 1px solid rgba(0, 0, 0, 0.5);
  }

  :disabled {
    background-color: var(--color-gray-50);
    border-color: transparent;
    color: var(--color-brand-primary-white);
  }
`;

export const secondary = (darkMode: boolean) => css`
  color: var(--color-gray-100);
  background-color: var(--color-gray-20);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-grayscale-black);
    }
  }

  :hover {
    color: var(--color-grayscale-black);
    background-color: var(--color-gray-40);
  }

  :focus {
    outline: 1px solid rgba(0, 0, 0, 0.5);
  }

  :disabled {
    background: var(--color-gray-20);
    color: var(--color-gray-70);
  }

  ${darkMode &&
  css`
    background-color: var(--color-gray-90);
    color: var(--color-brand-primary-white);
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      svg {
        fill: var(--color-brand-primary-white);
      }
    }
    :hover {
      color: var(--color-brand-primary-white);
      background-color: var(--color-gray-80);
    }

    :focus {
      outline: 1px solid rgba(255, 255, 255, 0.5);
    }
  `}
`;

export const white = css`
  background: var(--color-brand-primary-white);
  outline-color: var(--color-gray-60);
  color: var(--color-gray-90);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-gray-90);
    }
  }

  :hover {
    background: var(--color-gray-20);
    outline-color: var(--color-gray-70);
    color: var(--color-gray-100);
  }

  :focus {
    background: var(--color-brand-primary-white);
    outline: 1px solid var(--color-grayscale-black);
    color: var(--color-grayscale-black);
  }

  :disabled {
    background: var(--color-brand-primary-white);
    outline: 1px solid var(--color-gray-60);
    color: var(--color-gray-60);
  }
`;

const danger = css`
  color: var(--color-brand-primary-white);
  background-color: var(--color-red-50);
  outline: 1px solid var(--color-red-50);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg,
    path {
      fill: var(--color-brand-primary-white);
    }
  }

  :disabled {
    background-color: var(--color-gray-50);
    outline: 1px solid var(--color-gray-50);
    color: var(--color-brand-primary-white);
  }

  :hover {
    background-color: #76000c;
    outline: 1px solid #76000c;
  }

  :focus {
    text-decoration: none;
    outline: 1px solid rgba(0, 0, 0, 0.5);
  }
`;

const darkBlue = css`
  color: var(--color-brand-primary-white);
  background-color: var(--color-blue-70);

  :hover {
    background-color: var(--color-blue-90);
  }
`;

const lightBlue = css`
  color: var(--color-blue-90);
  background-color: var(--color-blue-20);

  :hover {
    background-color: var(--color-blue-30);
  }
`;

const outlineBlue = css`
  background: transparent;
  outline-color: var(--color-blue-80);
  color: var(--color-blue-80);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-blue-80);
    }
  }

  :hover {
    background: var(--color-blue-80);
    outline-color: var(--color-blue-80);
    color: var(--color-brand-primary-white);
  }

  :disabled {
    background: transparent;
    outline: 1px solid var(--color-gray-60);
  }

  :focus {
    outline: 5px auto var(--color-blue-80);
  }
`;

const yellow = css`
  background: var(--color-yellow-10);
  outline-color: var(--color-yellow-100);
  color: var(--color-grayscale-black);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-grayscale-black);
    }
  }

  :hover {
    background: var(--color-gray-80);
    outline-color: var(--color-gray-80);
    color: var(--color-brand-primary-white);
  }

  :disabled {
    background: transparent;
    outline: 1px solid var(--color-gray-60);
  }

  :focus {
    outline: 5px auto var(--color-grayscale-black);
  }
`;

const hidden = css`
  background: transparent;
  outline: none;
  //color: transparent;
  box-shadow: none;
  border: none transparent;

  :hover {
    background: transparent;
    outline: none;
    color: transparent;
    box-shadow: none;
    border: none transparent;
  }

  :focus {
    outline: none;
  }
`;

// SIZES
const largeButtonStyles = css`
  padding: var(--sizes-40) var(--sizes-60);
  border-radius: var(--button-border-radius-large);
  font: var(--font-large-16pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 22px;
    height: 22px;
  }
`;

export const mediumButtonStyles = css`
  padding: var(--sizes-30) var(--sizes-50);
  border-radius: var(--button-border-radius-medium);
  font: var(--font-regular-14pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 16px;
    height: 16px;
  }
`;

const smallButtonStyles = css`
  padding: var(--sizes-20) var(--sizes-30);
  border-radius: var(--button-border-radius-small);
  font: var(--font-extra-small-12pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 14px;
    height: 14px;
  }
`;

const extraSmallButtonStyles = css`
  padding: var(--sizes-10) var(--sizes-20);
  border-radius: var(--button-border-radius-small);
  font: var(--font-mini-11pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 12px;
    height: 12px;
  }
`;

export const ToolTipContainer = styled.div`
  width: fit-content;
`;
