import styled, { css } from 'styled-components';

export const RebrandLabel = styled.label<{ $darkMode: boolean }>`
  grid-column: 1 / span 1;
  align-self: center;
  color: var(--color-gray-90);
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      color: var(--color-gray-50);
    `}
  font: var(--font-small-13pt-normal);
  line-height: var(--sizes-40);
  margin-bottom: 0;

  .required-asterisk {
    margin-left: -2px;
    color: var(--color-red-50);
  }

  .tooltip {
    opacity: 1;
  }
`;
