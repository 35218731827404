import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';

import { createFilterOptions } from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { constant } from 'lodash';
import { areEqual } from 'react-window';

import { LinkButton } from 'src/app/components/lib/button';
import {
  NO_OPTIONS_TEXT_DEFAULT,
  POPPER_MODIFIERS,
  POPPER_PLACEMENT_DEFAULT,
  SEARCH_PLACEHOLDER_DEFAULT,
} from 'src/app/components/lib/dropdown/menu/constants';
import { DropdownMenuContext, useDropdownMenuContext } from 'src/app/components/lib/dropdown/menu/context';
import {
  AutocompleteContainer,
  DropdownMenuFooter,
  DropdownMenuPopper,
  PopperLayout,
  SearchInput,
} from 'src/app/components/lib/dropdown/menu/dropdown-menu.styles';
import { DropdownMenuItem } from 'src/app/components/lib/dropdown/menu/item';
import NoOptions from 'src/app/components/lib/dropdown/menu/no-results';
import { PopperComponent } from 'src/app/components/lib/dropdown/menu/popper-component';
import { VirtualizedListbox } from 'src/app/components/lib/dropdown/menu/virtualization';
import { useDarkMode } from 'src/app/state/dark-mode';

import type { VirtualizedListboxProps } from './virtualization';
import type { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import type { ListChildComponentProps } from 'react-window';
import type { DropdownMenuBaseProps, DropdownMenuOption } from 'src/app/components/lib/dropdown';

const DropdownMenuMultipleRow = memo(({ data, index, style }: ListChildComponentProps) => {
  const [menuItemProps, option, selected] = data[index];
  const { truncateLabel, triggerNodeRef } = useDropdownMenuContext();

  const isSeventhFromEnd = data && index === data.length - 7;

  return (
    <DropdownMenuItem
      ariaSelected={menuItemProps['aria-selected']}
      checked={selected}
      danger={option.danger}
      dataOptionIndex={menuItemProps['data-option-index']}
      disabled={option.disabled}
      footer={option.footer}
      height={Number(style.height)}
      id={option.id}
      key={option.key ?? menuItemProps.id}
      label={option.label}
      labelSecondary={option.labelSecondary}
      ref={isSeventhFromEnd ? triggerNodeRef : undefined}
      tabIndex={menuItemProps.tabIndex}
      title={option.label}
      top={Number(style.top)}
      truncateLabel={truncateLabel}
      variant='checkbox'
      onClick={menuItemProps.onClick}
      onMouseOver={menuItemProps.onMouseOver}
      onTouchStart={menuItemProps.onTouchStart}
    />
  );
}, areEqual);

const MenuMultipleListbox = forwardRef<HTMLDivElement, VirtualizedListboxProps>(({ children, ...props }, ref) => {
  const { truncateLabel } = useDropdownMenuContext();

  return (
    <VirtualizedListbox {...props} ref={ref} RowComponent={DropdownMenuMultipleRow} truncateLabel={truncateLabel}>
      {children}
    </VirtualizedListbox>
  );
});

export type DropdownMenuMultipleProps = DropdownMenuBaseProps & {
  automationId?: string;
  disableFooter?: boolean;
  handleClose: (newValue?: DropdownMenuOption[]) => void;
  infiniteScroll?: boolean;
  onChange?: (event: React.KeyboardEvent | React.KeyboardEvent<HTMLLIElement>, value: DropdownMenuOption[]) => void;
  onSelectAll?: () => Promise<DropdownMenuOption[]>;
  searchAutomationId?: string;
  selectAllButtonAutomationId?: string;
  selectNoneButtonAutomationId?: string;
  value: DropdownMenuOption[];
  withinModal?: boolean;
};

export function DropdownMenuMultiple({
  anchorEl,
  disableFooter = false,
  disableSearchAllFields = false,
  footerContent,
  handleClose,
  id,
  infiniteScroll = false,
  automationId,
  noOptionsText = NO_OPTIONS_TEXT_DEFAULT,
  onChange,
  open,
  options,
  placement = POPPER_PLACEMENT_DEFAULT,
  searchAutomationId,
  selectAllButtonAutomationId,
  selectNoneButtonAutomationId,
  searchPlaceholder = SEARCH_PLACEHOLDER_DEFAULT,
  truncateLabel = true,
  value,
  width = '396px',
  paginationProps,
  onSearchInputChange,
  onSelectAll,
  withinModal,
}: DropdownMenuMultipleProps) {
  const darkMode = useDarkMode();
  const [filterInput, setFilterInput] = useState('');
  const [workingValue, setWorkingValue] = useState<DropdownMenuOption[]>(value);

  const handleSetValue = (event, selections: DropdownMenuOption[]) => {
    const sanitizedValue = selections.filter(({ disabled }) => !disabled);

    if (onChange) {
      onChange(event, sanitizedValue);
    }

    setWorkingValue(sanitizedValue);
  };

  const handleOnChange = (
    event: React.KeyboardEvent<HTMLLIElement> | React.MouseEvent<HTMLLIElement>,
    newValue,
    reason
  ) => {
    // This conditional ignores backspace key presses when removing a selected item
    if (event.type === 'keydown' && 'key' in event && event.key === 'Backspace' && reason === 'removeOption') {
      return;
    }

    handleSetValue(event, newValue);
  };

  const handleAutocompleteOnClose = (event: React.BaseSyntheticEvent, reason: AutocompleteCloseReason) => {
    if (reason === 'escape') {
      handleClose(workingValue);
    }
  };

  const handleOnClickAway = () => {
    handleClose(workingValue);
    setFilterInput('');
    if (onSearchInputChange) {
      onSearchInputChange('');
    }
  };

  const handleOnInputChange = (event: React.SyntheticEvent, changedValue: string, reason: string) => {
    if (reason === 'input') {
      setFilterInput(changedValue);
      onSearchInputChange?.(changedValue);
    }
  };

  const stringify = ({ label, footer, labelSecondary }: DropdownMenuOption) => {
    if (disableSearchAllFields) {
      return label;
    }
    const footerText = typeof footer === 'string' ? footer : '';
    return `${label}${footerText}${labelSecondary ?? ''}`;
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    stringify,
  });

  const selectAll = async (event) => {
    const newOptions = await onSelectAll?.();
    const optionsToSelect = newOptions?.length ? newOptions : options;

    const filteredOptions = optionsToSelect
      // use `stringify` in the same way MUI's Autocomplete does and preserve the id of each option for future lookup
      .map((option) => ({
        id: option.id,
        match: stringify(option).toLowerCase(),
      }))
      // Filter the options down to those that match the current state of the filter input
      .filter((filterableOption) => filterableOption.match.includes(filterInput.toLowerCase()))
      // Get unmodified options from the original `options` array using id lookup
      .map((filteredOption) => optionsToSelect.find((option) => option.id === filteredOption.id));

    handleSetValue(event, filteredOptions);
  };

  const selectNone = (event) => handleSetValue(event, []);

  const zeroOptions = useMemo(() => options.length === 0, [options]);

  // keep workingValue reactive to changes in value
  useEffect(() => {
    setWorkingValue(value);
  }, [value]);

  const { isFetching, triggerNodeRef } = paginationProps ?? {};
  const dropdownMenuContext = useMemo(
    () => ({ truncateLabel, multiSelect: true, width, isFetching, triggerNodeRef }),
    [truncateLabel, width, isFetching, triggerNodeRef]
  );

  const zeroProvidedOptions = zeroOptions && filterInput === '';

  return (
    <DropdownMenuPopper
      $darkMode={darkMode}
      $emptyMultiselect={zeroOptions}
      $width={width}
      $withinModal={withinModal}
      anchorEl={anchorEl}
      data-testid={automationId}
      id={id}
      modifiers={POPPER_MODIFIERS}
      open={open}
      placement={placement}
      role='menu'
    >
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <PopperLayout>
          {zeroProvidedOptions ? (
            <NoOptions />
          ) : (
            <>
              <DropdownMenuContext.Provider value={dropdownMenuContext}>
                <AutocompleteContainer
                  disableCloseOnSelect
                  filterOptions={filterOptions}
                  inputValue={filterInput}
                  isOptionEqualToValue={(option: DropdownMenuOption, selectedValue: DropdownMenuOption) =>
                    option?.id === selectedValue?.id
                  }
                  ListboxComponent={MenuMultipleListbox}
                  ListboxProps={{ role: infiniteScroll ? 'list-box' : 'listbox' }}
                  multiple
                  noOptionsText={noOptionsText}
                  open
                  options={options}
                  PopperComponent={PopperComponent}
                  renderInput={(params) => (
                    <SearchInput
                      autoFocus
                      automationId={searchAutomationId}
                      inputProps={params.inputProps}
                      inputRef={params.InputProps.ref}
                      placeholder={searchPlaceholder}
                    />
                  )}
                  renderOption={(props, option: DropdownMenuOption, { selected }) => [props, option, selected]}
                  renderTags={constant(null)}
                  value={workingValue}
                  onChange={handleOnChange}
                  onClose={handleAutocompleteOnClose}
                  onInputChange={handleOnInputChange}
                />
              </DropdownMenuContext.Provider>
              {!disableFooter && (
                <DropdownMenuFooter>
                  <LinkButton
                    automationId={selectAllButtonAutomationId}
                    buttonSize='small'
                    disabled={zeroOptions}
                    label='Select all'
                    onClick={selectAll}
                  />
                  <LinkButton
                    automationId={selectNoneButtonAutomationId}
                    buttonSize='small'
                    disabled={zeroOptions}
                    label='Select none'
                    onClick={selectNone}
                  />
                  {footerContent}
                </DropdownMenuFooter>
              )}
            </>
          )}
        </PopperLayout>
      </ClickAwayListener>
    </DropdownMenuPopper>
  );
}
