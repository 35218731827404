import type { HTMLAttributes } from 'react';
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { VariableSizeList } from 'react-window';

import { BASE_ROW_HEIGHT, ROW_HEIGHT_PADDING } from 'src/app/components/lib/dropdown/menu/constants';
import { useDropdownMenuContext } from 'src/app/components/lib/dropdown/menu/context';

import type { ListChildComponentProps } from 'react-window';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

function getMenuItemHeight(
  option: DropdownMenuOption,
  truncateLabel: boolean,
  containerWidth: string,
  multiSelect: boolean
) {
  const widthSubtrahend = multiSelect ? '87px' : '64px';

  const temporaryElementContainer = document.getElementById('temp-elements');
  const temporaryMenuItem = document.createElement('div');
  temporaryMenuItem.style.display = 'var(--menu-item-display-value)';
  temporaryMenuItem.style.flexDirection = 'var(--menu-item-flex-direction)';
  temporaryMenuItem.style.padding = multiSelect ? 'var(--sizes-30) var(--sizes-50)' : 'var(--menu-item-padding)';
  temporaryMenuItem.style.boxSizing = 'border-box';
  temporaryMenuItem.style.width = `calc(${containerWidth} - ${widthSubtrahend})`;
  // keeps the parent element hidden so users don't see it when it mounts
  temporaryMenuItem.style.visibility = 'hidden';
  temporaryMenuItem.style.zIndex = '-1';

  const temporaryLabelContainer = document.createElement('div');
  temporaryLabelContainer.style.display = 'var(--menu-item-label-container-display-value)';
  temporaryLabelContainer.style.justifyContent = 'var(--menu-item-label-container-justify-content-value)';
  temporaryLabelContainer.style.gap = 'var(--menu-item-label-container-gap-value)';
  temporaryMenuItem.appendChild(temporaryLabelContainer);

  const temporaryPrimaryLabel = document.createElement('span');
  if (truncateLabel) {
    temporaryPrimaryLabel.style.whiteSpace = 'var(--dropdown-menu-item-label-white-space-value)';
    temporaryPrimaryLabel.style.overflow = 'var(--dropdown-menu-item-label-overflow)';
    temporaryPrimaryLabel.style.textOverflow = 'var(--dropdown-menu-item-label-text-overflow)';
    temporaryPrimaryLabel.style.font = 'var(--menu-item-label-font)';
    temporaryPrimaryLabel.style.display = 'var(--dropdown-menu-item-label-display)';
    temporaryPrimaryLabel.innerText = option.label;
    temporaryLabelContainer.appendChild(temporaryPrimaryLabel);
  } else {
    temporaryPrimaryLabel.style.whiteSpace = 'var(--dropdown-menu-item-label-wrapped-white-space)';
    temporaryPrimaryLabel.style.font = 'var(--menu-item-label-font)';
    temporaryPrimaryLabel.style.display = 'var(--dropdown-menu-item-label-display)';
    temporaryPrimaryLabel.innerText = option.label;
    temporaryLabelContainer.appendChild(temporaryPrimaryLabel);
  }

  if (option?.labelSecondary) {
    const temporaryLabelSecondary = document.createElement('span');
    temporaryLabelSecondary.style.marginTop = 'var(--menu-item-label-secondary-margin-top)';
    temporaryLabelSecondary.style.font = 'var(--menu-item-label-secondary-font)';
    temporaryLabelSecondary.style.textTransform = 'var(--menu-item-label-secondary-text-transform)';
    temporaryLabelSecondary.style.alignSelf = 'var(--menu-item-label-secondary-align-self)';
    temporaryLabelSecondary.innerText = option.labelSecondary;

    temporaryLabelContainer.appendChild(temporaryLabelSecondary);
  }

  if (option?.footer) {
    const temporaryFooterElement = document.createElement('div');
    temporaryFooterElement.style.font = 'var(--menu-item-footer-font)';
    temporaryFooterElement.style.whiteSpace = 'var(--menu-item-footer-white-space)';
    temporaryFooterElement.innerText = option.footer;
    temporaryMenuItem.appendChild(temporaryFooterElement);
  }

  temporaryElementContainer?.appendChild(temporaryMenuItem);
  const { height } = temporaryMenuItem.getBoundingClientRect();
  temporaryElementContainer?.removeChild(temporaryMenuItem);

  return Math.max(height, BASE_ROW_HEIGHT + ROW_HEIGHT_PADDING);
}

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const OuterElementContext = createContext({});

const OuterElement = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export type VirtualizedListboxProps = HTMLAttributes<HTMLElement> & {
  RowComponent: (props: ListChildComponentProps<VirtualizedListboxProps>) => JSX.Element;
  truncateLabel: boolean;
};

type ItemData = [HTMLAttributes<HTMLLIElement>, DropdownMenuOption, boolean];

export const VirtualizedListbox = forwardRef<HTMLDivElement, VirtualizedListboxProps>((props, ref) => {
  const { children, RowComponent, truncateLabel, ...other } = props;
  const itemData: ItemData[] = useMemo(() => {
    const data: ItemData[] = [];
    (children as ItemData[]).forEach((child) => {
      data.push(child);
    });

    return data;
  }, [children]);

  const itemCount = itemData.length;
  const gridRef = useResetCache(itemCount);
  const { multiSelect, width } = useDropdownMenuContext();

  const getItemSize = useCallback(
    (index) => getMenuItemHeight(itemData[index][1], truncateLabel, width, multiSelect),
    [itemData, truncateLabel, multiSelect, width]
  );

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          height={360}
          innerElementType='ul'
          itemCount={itemCount}
          itemData={itemData}
          itemSize={getItemSize}
          outerElementType={OuterElement}
          overscanCount={10}
          ref={gridRef}
          width={width}
        >
          {RowComponent}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
