import React from 'react';

import { Tooltip as MUITooltip } from '@mui/material';

import { StyledTooltip } from './tooltip.styles';

import type { TooltipProps } from '@mui/material';

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps & { disabled?: boolean; textAlign?: string }>(
  (props, ref) => {
    const { children, className = '', disabled = false, textAlign, ...other } = props;
    if (disabled) {
      return children;
    }

    const slotProps = {
      popper: {
        sx: { textAlign: textAlign ?? 'left' },
      },
    };

    return (
      <MUITooltip
        {...other}
        arrow
        className={className}
        PopperComponent={StyledTooltip}
        ref={ref}
        slotProps={slotProps}
      >
        {children}
      </MUITooltip>
    );
  }
);
