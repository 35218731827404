import React from 'react';

export function SessionExpirationIllustration() {
  return (
    <svg fill='none' height='255' viewBox='0 0 268 255' width='268' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='128' cy='127.5' fill='var(--color-opal-40)' opacity='0.05' rx='127.124' ry='127.5' />
      <ellipse cx='128.376' cy='127.5' fill='var(--color-opal-40)' opacity='0.08' rx='103.429' ry='104.181' />
      <circle cx='128.376' cy='127.5' fill='var(--color-opal-60)' opacity='0.1' r='83.1195' />
      <g filter='url(#filter0_d_7740_38512)'>
        <rect fill='white' height='126.987' rx='10' width='166.56' x='43.5874' y='61.0131' />
        <rect height='125.987' rx='9.5' stroke='var(--color-gray-60)' width='165.56' x='44.0874' y='61.5131)' />
      </g>
      <path
        d='M119.72 186.852C156.319 186.623 175.337 122.872 180.27 91.0246H210.097V176.852C210.097 182.375 205.62 186.852 200.097 186.852H119.72Z'
        fill='var(--color-gray-10)'
        opacity='0.5'
      />
      <path
        d='M45.0845 70.1608C45.0845 65.7425 48.6662 62.1608 53.0845 62.1608H200.276C204.694 62.1608 208.276 65.7425 208.276 70.1608V90.6652H45.0845V70.1608Z'
        fill='var(--color-gray-10)'
      />
      <line stroke='#A8AEB2' x1='43.937' x2='207.128' y1='90.9745' y2='90.9745' />
      <rect fill='var(--color-opal-40)' height='5.98868' rx='2.99434' width='5.98868' x='53.9937' y='74.5719' />
      <rect fill='var(--color-opal-40)' height='5.98868' rx='2.99434' width='5.98868' x='64.4736' y='74.5719' />
      <rect fill='var(--color-opal-40)' height='5.98868' rx='2.99434' width='5.98868' x='74.9536' y='74.5719' />
      <g filter='url(#filter1_d_7740_38512)'>
        <path
          clipRule='evenodd'
          d='M213.858 98.5044C232.075 98.5044 246.844 83.7363 246.844 65.5189C246.844 47.3016 232.075 32.5334 213.858 32.5334C195.641 32.5334 180.873 47.3016 180.873 65.5189C180.873 75.6149 185.408 84.6514 192.553 90.702L191.446 100.851C191.356 101.674 192.248 102.243 192.957 101.815L202.043 96.3255C205.711 97.7331 209.695 98.5044 213.858 98.5044Z'
          fill='white'
          fillRule='evenodd'
        />
        <path
          d='M192.553 90.702L193.547 90.8105L193.605 90.2823L193.199 89.9389L192.553 90.702ZM191.446 100.851L192.44 100.959V100.959L191.446 100.851ZM192.957 101.815L193.474 102.671H193.474L192.957 101.815ZM202.043 96.3255L202.402 95.3919L201.945 95.2166L201.526 95.4696L202.043 96.3255ZM245.844 65.5189C245.844 83.184 231.523 97.5044 213.858 97.5044V99.5044C232.628 99.5044 247.844 84.2886 247.844 65.5189H245.844ZM213.858 33.5334C231.523 33.5334 245.844 47.8538 245.844 65.5189H247.844C247.844 46.7493 232.628 31.5334 213.858 31.5334V33.5334ZM181.873 65.5189C181.873 47.8538 196.193 33.5334 213.858 33.5334V31.5334C195.088 31.5334 179.873 46.7493 179.873 65.5189H181.873ZM193.199 89.9389C186.269 84.0702 181.873 75.3085 181.873 65.5189H179.873C179.873 75.9212 184.547 85.2326 191.907 91.4652L193.199 89.9389ZM192.44 100.959L193.547 90.8105L191.559 90.5936L190.452 100.742L192.44 100.959ZM192.44 100.959L192.44 100.959L190.452 100.742C190.272 102.389 192.057 103.527 193.474 102.671L192.44 100.959ZM201.526 95.4696L192.44 100.959L193.474 102.671L202.561 97.1814L201.526 95.4696ZM213.858 97.5044C209.819 97.5044 205.957 96.7563 202.402 95.3919L201.685 97.2591C205.466 98.7099 209.57 99.5044 213.858 99.5044V97.5044Z'
          fill='#C9D3DC'
        />
      </g>
      <path
        d='M213.156 54.987C212.784 54.987 212.426 55.135 212.163 55.3985C211.899 55.662 211.751 56.0193 211.751 56.3919V64.8209C211.751 65.1934 211.899 65.5508 212.163 65.8142C212.426 66.0777 212.784 66.2257 213.156 66.2257H219.478C219.663 66.2269 219.847 66.1915 220.019 66.1215C220.19 66.0515 220.346 65.9482 220.478 65.8176C220.609 65.6871 220.713 65.5318 220.785 65.3607C220.856 65.1896 220.893 65.0061 220.893 64.8208C220.893 64.6355 220.856 64.4521 220.785 64.281C220.713 64.1099 220.609 63.9546 220.478 63.824C220.346 63.6935 220.19 63.5902 220.019 63.5202C219.847 63.4501 219.663 63.4147 219.478 63.416H214.561V56.3919C214.561 56.0193 214.413 55.662 214.15 55.3985C213.886 55.135 213.529 54.987 213.156 54.987V54.987Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M224.786 53.1915C218.761 47.1661 208.957 47.1661 202.931 53.1915C202.669 53.4552 202.522 53.8121 202.522 54.1841C202.523 54.5561 202.671 54.9126 202.934 55.1756C203.197 55.4386 203.553 55.5866 203.925 55.587C204.297 55.5875 204.654 55.4404 204.918 55.178C209.847 50.2481 217.869 50.2481 222.799 55.178C227.729 60.1077 227.729 68.1292 222.799 73.0588C218.452 77.4056 211.659 77.9039 206.722 74.5361L207.976 74.1182C208.329 74.0003 208.622 73.7468 208.788 73.4135C208.955 73.0802 208.982 72.6944 208.864 72.3409C208.746 71.9874 208.493 71.6952 208.16 71.5286C207.826 71.362 207.44 71.3347 207.087 71.4526L203.445 72.6667C203.125 72.7736 202.854 72.9922 202.682 73.2826C202.509 73.573 202.447 73.9158 202.507 74.2482L203.257 78.4154C203.315 78.7394 203.485 79.0327 203.738 79.2441C203.99 79.4554 204.309 79.5715 204.638 79.5719C204.722 79.5718 204.806 79.5642 204.888 79.5493C205.255 79.4833 205.58 79.2744 205.793 78.9685C206.006 78.6626 206.088 78.2848 206.022 77.9181L205.921 77.3565C208.875 79.1249 212.334 79.8578 215.75 79.4392C219.167 79.0205 222.347 77.4743 224.786 75.0451C230.811 69.0203 230.811 59.2166 224.786 53.1915V53.1915Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M201.831 56.7233C201.665 56.6424 201.485 56.595 201.3 56.5837C201.116 56.5725 200.932 56.5977 200.757 56.6579C200.583 56.7181 200.422 56.812 200.284 56.9344C200.146 57.0568 200.033 57.2053 199.953 57.3712C199.839 57.6044 199.732 57.8396 199.631 58.077C199.558 58.247 199.52 58.4298 199.517 58.6148C199.515 58.7998 199.549 58.9835 199.618 59.1553C199.687 59.327 199.789 59.4835 199.919 59.6156C200.048 59.7478 200.203 59.853 200.373 59.9253C200.543 59.9976 200.726 60.0355 200.911 60.0369C201.096 60.0383 201.28 60.0031 201.451 59.9333C201.623 59.8636 201.779 59.7606 201.91 59.6304C202.042 59.5002 202.146 59.3453 202.218 59.1746C202.3 58.9818 202.386 58.7904 202.478 58.6011C202.559 58.4353 202.606 58.2552 202.618 58.0711C202.629 57.887 202.604 57.7025 202.543 57.5281C202.483 57.3537 202.389 57.1929 202.267 57.0548C202.145 56.9167 201.997 56.8041 201.831 56.7233Z'
        fill='var(--color-teal-50)'
      />
      <path
        d='M199.815 65.1775C200.181 65.1774 200.533 65.0343 200.795 64.7786C201.057 64.5229 201.209 64.175 201.218 63.8089C201.225 63.5438 201.24 63.2785 201.263 63.0132C201.279 62.8294 201.258 62.6443 201.203 62.4685C201.147 62.2926 201.057 62.1294 200.939 61.9882C200.82 61.8471 200.675 61.7306 200.511 61.6456C200.347 61.5606 200.169 61.5087 199.985 61.4927C199.801 61.4768 199.616 61.4973 199.44 61.5529C199.264 61.6086 199.101 61.6983 198.96 61.817C198.819 61.9357 198.702 62.081 198.617 62.2447C198.532 62.4084 198.48 62.5872 198.464 62.771C198.436 63.0924 198.418 63.4144 198.41 63.7368C198.401 64.1092 198.539 64.4702 198.796 64.7403C199.052 65.0104 199.406 65.1676 199.778 65.1773C199.79 65.1775 199.803 65.1775 199.815 65.1775V65.1775Z'
        fill='var(--color-teal-50)'
      />
      <path
        d='M201.738 67.7271C201.686 67.5494 201.599 67.3837 201.483 67.2395C201.367 67.0954 201.223 66.9756 201.061 66.8872C200.898 66.7987 200.719 66.7433 200.535 66.7242C200.351 66.705 200.165 66.7224 199.987 66.7755C199.81 66.8285 199.645 66.9161 199.501 67.0332C199.358 67.1503 199.239 67.2946 199.151 67.4579C199.064 67.6211 199.01 67.8 198.992 67.9843C198.973 68.1686 198.992 68.3547 199.046 68.5318C199.12 68.778 199.199 69.0236 199.285 69.2667C199.346 69.4408 199.441 69.601 199.564 69.7384C199.687 69.8758 199.836 69.9875 200.003 70.0673C200.169 70.1471 200.349 70.1933 200.534 70.2034C200.718 70.2134 200.902 70.1871 201.076 70.1259C201.25 70.0647 201.411 69.9698 201.548 69.8466C201.685 69.7235 201.797 69.5745 201.877 69.4081C201.957 69.2418 202.003 69.0614 202.013 68.8772C202.023 68.693 201.997 68.5086 201.935 68.3345C201.865 68.1333 201.799 67.9309 201.738 67.7271Z'
        fill='var(--color-teal-50)'
      />
      <path
        d='M93.5288 139.578C92.2708 140.26 90.9041 140.772 89.5917 141.075C88.093 141.416 86.5554 141.532 85.0256 141.416C82.4553 141.199 79.9625 140.02 78.2464 138.081C78.0367 137.848 77.8659 137.561 77.5397 137.484C77.0194 137.368 76.6545 137.724 76.5302 138.197C75.2644 142.889 79.5665 147.364 84.2801 146.977C88.5201 146.721 92.4339 144.247 94.4607 140.516C94.5849 140.299 94.5771 140.051 94.4529 139.841C94.2665 139.516 93.855 139.392 93.5288 139.578Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M82.9216 123.276C84.1408 124.028 85.2745 124.951 86.1909 125.936C87.2392 127.06 88.1089 128.332 88.7768 129.713C89.8717 132.047 90.0891 134.785 89.2738 137.243C89.1728 137.546 89.0097 137.833 89.1107 138.151C89.2738 138.655 89.7552 138.795 90.2367 138.67C94.9425 137.414 96.6665 131.466 93.9719 127.58C91.6267 124.036 87.5265 121.895 83.2788 122.004C83.0303 122.012 82.8129 122.136 82.6964 122.345C82.5023 122.663 82.6032 123.09 82.9216 123.276Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M84.0474 130.907C83.0922 130.768 82.1293 131 81.3528 131.574C80.949 131.869 80.6151 132.241 80.3666 132.675C79.6444 133.924 79.7298 135.483 80.5918 136.638C81.1664 137.414 82.0128 137.926 82.968 138.065C83.9231 138.205 84.8861 137.972 85.6626 137.398C86.0664 137.104 86.4003 136.731 86.6488 136.297C87.371 135.049 87.2856 133.49 86.4236 132.334C85.8567 131.559 85.0103 131.047 84.0474 130.907Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M83.0301 128.131C83.3407 128.069 83.6747 128.061 83.8999 127.821C84.2571 127.425 84.1406 126.936 83.7834 126.587C80.3433 123.144 74.3173 124.633 72.2982 128.906C70.4035 132.706 70.5898 137.321 72.8108 140.943C72.9428 141.16 73.1524 141.276 73.4009 141.276C73.7737 141.276 74.0843 140.982 74.0843 140.609C74.1231 139.182 74.3561 137.74 74.7521 136.452C75.2025 134.987 75.8704 133.598 76.7401 132.326C78.2233 130.209 80.4908 128.65 83.0301 128.131Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M102.079 135.422C102.079 131.823 104.16 129.349 107.646 129.349C109.122 129.349 110.38 129.954 111.063 130.846V125.115C111.063 124.928 111.211 124.781 111.397 124.781H113.665C113.851 124.781 113.999 124.928 113.999 125.115V140.974C113.999 141.161 113.851 141.308 113.665 141.308H111.397C111.211 141.308 111.063 141.161 111.063 140.974V139.959C110.481 140.889 109.168 141.502 107.693 141.502C104.16 141.494 102.079 139.02 102.079 135.422ZM111.242 135.422C111.242 133.297 109.999 131.893 108.105 131.893C106.233 131.893 104.991 133.297 104.991 135.422C104.991 137.547 106.233 138.95 108.105 138.95C109.999 138.95 111.242 137.547 111.242 135.422Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M126.245 141.307H123.977C123.791 141.307 123.643 141.16 123.643 140.974V139.539C122.898 140.687 121.562 141.501 119.9 141.501C117.353 141.501 115.598 139.818 115.598 137.251V129.884C115.598 129.697 115.746 129.55 115.932 129.55H118.2C118.386 129.55 118.533 129.697 118.533 129.884V136.576C118.533 137.848 119.566 138.911 120.84 138.888C122.626 138.857 123.635 137.321 123.635 134.707V129.884C123.635 129.697 123.783 129.55 123.969 129.55H126.237C126.423 129.55 126.571 129.697 126.571 129.884V140.982C126.579 141.16 126.431 141.307 126.245 141.307Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M133.715 141.494C131.331 141.494 129.553 140.206 129.553 137.453V132.017H127.907C127.72 132.017 127.573 131.869 127.573 131.683V129.884C127.573 129.698 127.72 129.55 127.907 129.55H129.553V126.433C129.553 126.247 129.7 126.099 129.887 126.099H132.154C132.341 126.099 132.488 126.247 132.488 126.433V129.55H135.121C135.307 129.55 135.455 129.698 135.455 129.884V131.683C135.455 131.869 135.307 132.017 135.121 132.017H132.488V137.182C132.488 138.306 133.094 138.911 134.01 138.911C134.321 138.911 134.67 138.88 134.997 138.779C135.198 138.717 135.408 138.857 135.424 139.066L135.579 140.928C135.594 141.091 135.494 141.238 135.338 141.277C134.833 141.424 134.29 141.494 133.715 141.494Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M135.959 135.421C135.959 131.822 138.413 129.348 141.947 129.348C144.828 129.348 146.893 130.775 147.421 133.071C147.46 133.257 147.344 133.443 147.157 133.474L144.929 133.909C144.758 133.94 144.595 133.839 144.548 133.668C144.237 132.598 143.306 131.892 142.048 131.892C140.223 131.892 139.035 133.273 139.035 135.421C139.035 137.569 140.231 138.95 142.048 138.95C143.313 138.95 144.191 138.321 144.54 137.236C144.595 137.073 144.758 136.964 144.929 137.003L147.157 137.453C147.344 137.492 147.468 137.678 147.421 137.864C146.847 140.136 144.781 141.493 141.954 141.493C138.413 141.493 135.959 139.019 135.959 135.421Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M148.974 124.773H151.242C151.428 124.773 151.575 124.92 151.575 125.106V131.357C152.344 130.07 153.68 129.348 155.318 129.348C157.959 129.348 159.714 131.031 159.714 133.598V140.966C159.714 141.152 159.566 141.3 159.38 141.3H157.112C156.926 141.3 156.778 141.152 156.778 140.966V134.32C156.778 133.04 155.753 131.97 154.472 131.962C152.632 131.947 151.591 133.513 151.591 136.189V140.966C151.591 141.152 151.443 141.3 151.257 141.3H148.99C148.803 141.3 148.656 141.152 148.656 140.966V125.114C148.632 124.928 148.788 124.773 148.974 124.773Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M160.793 126.184C160.793 125.184 161.562 124.385 162.618 124.385C163.698 124.385 164.49 125.176 164.49 126.184C164.49 127.208 163.698 128.007 162.618 128.007C161.57 128.007 160.793 127.216 160.793 126.184ZM161.485 129.535H163.752C163.938 129.535 164.086 129.682 164.086 129.868V140.966C164.086 141.152 163.938 141.3 163.752 141.3H161.485C161.298 141.3 161.151 141.152 161.151 140.966V129.876C161.143 129.69 161.298 129.535 161.485 129.535Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M176.642 136.196H168.209C168.465 137.972 169.615 139.019 171.292 139.019C172.558 139.019 173.49 138.469 173.87 137.499C173.932 137.344 174.103 137.259 174.266 137.305L176.371 137.872C176.565 137.926 176.674 138.128 176.604 138.314C175.843 140.322 173.862 141.493 171.245 141.493C167.339 141.493 165.258 138.484 165.258 135.421C165.258 132.357 167.176 129.348 171.082 129.348C175.058 129.348 177 132.288 177 135.025C177 135.305 176.992 135.623 176.976 135.878C176.969 136.057 176.821 136.196 176.642 136.196ZM173.847 134.048C173.707 132.644 172.791 131.597 171.09 131.597C169.638 131.597 168.613 132.458 168.287 134.048H173.847Z'
        fill='var(--color-opal-40)'
      />
      <defs>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='176.987'
          id='filter0_d_7740_38512'
          width='216.56'
          x='18.5874'
          y='44.0131'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='12.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.6 0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0.3 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_7740_38512' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_7740_38512' mode='normal' result='shape' />
        </filter>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='111.43'
          id='filter1_d_7740_38512'
          width='107.971'
          x='159.873'
          y='19.5334'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='10' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.6 0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0.21 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_7740_38512' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_7740_38512' mode='normal' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
