import React from 'react';

export function WarnMessageIcon(): JSX.Element {
  return (
    <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_722_2235)'>
        <g clipPath='url(#clip1_722_2235)'>
          <path
            d='M31.6934 27.426L18.36 2.09268C18.134 1.66239 17.7947 1.30204 17.3788 1.0506C16.9628 0.799152 16.4861 0.666167 16 0.666016C15.514 0.666167 15.0372 0.799152 14.6213 1.0506C14.2053 1.30204 13.866 1.66239 13.64 2.09268L0.306688 27.426C0.0846817 27.8384 -0.0248071 28.302 -0.0107635 28.7701C0.00328021 29.2382 0.140361 29.6944 0.386688 30.0927C0.629666 30.4764 0.966639 30.7918 1.36565 31.0088C1.76467 31.2258 2.2125 31.3373 2.66669 31.3327H29.3334C29.7924 31.3325 30.2435 31.2139 30.6433 30.9883C31.043 30.7627 31.3777 30.4377 31.615 30.0449C31.8524 29.652 31.9843 29.2045 31.998 28.7457C32.0117 28.2869 31.9068 27.8324 31.6934 27.426ZM14.6667 11.3327C14.6667 10.9791 14.8072 10.6399 15.0572 10.3899C15.3073 10.1398 15.6464 9.99935 16 9.99935C16.3536 9.99935 16.6928 10.1398 16.9428 10.3899C17.1929 10.6399 17.3334 10.9791 17.3334 11.3327V19.3327C17.3334 19.6863 17.1929 20.0254 16.9428 20.2755C16.6928 20.5255 16.3536 20.666 16 20.666C15.6464 20.666 15.3073 20.5255 15.0572 20.2755C14.8072 20.0254 14.6667 19.6863 14.6667 19.3327V11.3327ZM16.0667 26.666C15.5415 26.663 15.0378 26.4575 14.6603 26.0925C14.2828 25.7274 14.0606 25.2308 14.04 24.706C14.0311 24.4424 14.0751 24.1797 14.1695 23.9335C14.2638 23.6872 14.4065 23.4623 14.5892 23.2721C14.772 23.082 14.991 22.9303 15.2333 22.8262C15.4756 22.7221 15.7363 22.6676 16 22.666C16.5252 22.669 17.0289 22.8745 17.4064 23.2396C17.7839 23.6047 18.0061 24.1013 18.0267 24.626C18.0356 24.8896 17.9916 25.1523 17.8973 25.3986C17.8029 25.6448 17.6602 25.8697 17.4775 26.0599C17.2947 26.2501 17.0758 26.4017 16.8334 26.5058C16.5911 26.6099 16.3304 26.6644 16.0667 26.666Z'
            fill='#FFD504'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_722_2235'>
          <rect fill='white' height='32' width='32' />
        </clipPath>
        <clipPath id='clip1_722_2235'>
          <rect fill='white' height='32' width='32' />
        </clipPath>
      </defs>
    </svg>
  );
}
