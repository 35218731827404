import { atom } from 'recoil';

import { events as AppEvents } from 'src/app_deprecated/constants/AppConstants';

import AppStore from '../../app_deprecated/stores/AppStore';

export const appAtom = atom({
  key: 'app-versions',
  default: {
    buildNumber: '',
    apiVersionNumber: '',
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    const { buildNumber, apiVersionNumber } = AppStore.getState();
    setSelf({ buildNumber, apiVersionNumber });
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  // Subscribe to remote storage changes and update the atom value
  AppStore.addChangeListener(AppEvents.BUILD_NUMBER, changeListenerForUserState);

  // Cleanup remote storage subscription
  return () => {
    AppStore.removeChangeListener(AppEvents.BUILD_NUMBER, changeListenerForUserState);
  };
}
