import React from 'react';
import i18next from 'i18next';

class Translate extends React.Component<any, any> {
  render() {
    if (typeof this.props.children === 'string') {
      return i18next.t(this.props.children.toString());
    }

    return this.props.children;
  }
}

export default Translate;
