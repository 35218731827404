import React from 'react';

import { InfoIconV2 } from 'src/app/components/icons/info-icon-v2';

import { Tooltip, TooltipIconContainer } from '../../tooltip';

export function TooltipHeader({ header, tooltip }: { header: string; tooltip: string }) {
  return (
    <div className='MuiDataGrid-columnHeaderTitle'>
      {header}
      <Tooltip placement='bottom' title={tooltip}>
        <TooltipIconContainer>
          <InfoIconV2 fill='var(--color-gray-60)' size='var(--sizes-30)' />
        </TooltipIconContainer>
      </Tooltip>
    </div>
  );
}
