import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { actions as NotificationActions } from 'src/app_deprecated/constants/NotificationConstants';
import AppDispatcher from 'src/app_deprecated/utils/AppDispatcher';

import { analyticsAtom } from 'src/app/state/analytics';

export function useTrackErrorNotifications() {
  const analytics = useRecoilValue(analyticsAtom);

  useEffect(() => {
    // subscribe
    const subscriptionToken = AppDispatcher.register((action) => {
      const isAddNotificationAction = action.actionType === NotificationActions.ADD_NOTIFICATION;
      const isErrorNotificationType = action.data?.type === 'error';

      if (analytics && isAddNotificationAction && isErrorNotificationType) {
        analytics.track('Error notification presented', { message: action.data.body });
      }
    });

    // unsubscribe
    return () => AppDispatcher.unregister(subscriptionToken);
  }, [analytics]);
}
