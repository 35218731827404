import React from 'react';

import styled from 'styled-components';

const ChevronContainer = styled.div`
  cursor: pointer;

  svg {
    display: block;
  }
`;

type ChevronLeftProps = {
  onClick?: () => void;
};

export function ChevronLeft({ onClick }: ChevronLeftProps) {
  return (
    <ChevronContainer onClick={onClick}>
      <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M10.2571 3.27135C10.4591 3.49667 10.4586 3.86152 10.2562 4.08626L6.73459 7.99526L10.2467 11.9148C10.4486 12.1402 10.4481 12.505 10.2456 12.7298C10.0432 12.9545 9.71533 12.954 9.51341 12.7286L5.81871 8.60523C5.74674 8.52494 5.68939 8.42936 5.65049 8.32454C5.61153 8.21957 5.59155 8.1071 5.59169 7.99354C5.59183 7.87998 5.61208 7.76756 5.65129 7.66272C5.69045 7.55801 5.74775 7.46291 5.81992 7.38284L9.5249 3.27028C9.72737 3.04555 10.0552 3.04602 10.2571 3.27135Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M10.3221 3.19929C10.5598 3.46451 10.5593 3.89397 10.3209 4.15851L6.86425 7.99545L10.3116 11.8428C10.5493 12.108 10.5487 12.5375 10.3104 12.802C10.072 13.0665 9.68616 13.0659 9.44849 12.8007L5.75379 8.67729C5.67332 8.58752 5.60924 8.4807 5.56574 8.36349C5.52218 8.24613 5.49985 8.12037 5.5 7.9934C5.50016 7.86643 5.5228 7.74074 5.56664 7.62351C5.61043 7.50643 5.67449 7.4001 5.75519 7.31058L9.46015 3.19804C9.69847 2.9335 10.0844 2.93406 10.3221 3.19929ZM10.1914 4.01401C10.358 3.82907 10.3584 3.52883 10.1922 3.34341C10.026 3.15798 9.75626 3.15759 9.58965 3.34253L5.88495 7.45478C5.8213 7.52539 5.77047 7.60959 5.73594 7.70193C5.70136 7.79439 5.6835 7.89353 5.68338 7.99368C5.68326 8.09382 5.70087 8.19301 5.73523 8.28558C5.76954 8.37803 5.81987 8.46205 5.88334 8.53285L9.57833 12.6566C9.7445 12.842 10.0143 12.8424 10.1809 12.6575C10.3475 12.4726 10.3479 12.1723 10.1817 11.9869L6.66966 8.06731C6.63391 8.02741 6.63399 7.96281 6.66984 7.92301L10.1914 4.01401ZM6.61646 7.86338C6.58062 7.90318 6.52258 7.90312 6.48681 7.86325C6.45106 7.82335 6.45082 7.75841 6.48665 7.71861C6.52249 7.67881 6.58053 7.67887 6.6163 7.71874C6.65205 7.75863 6.6523 7.82358 6.61646 7.86338ZM6.61614 8.12678C6.65188 8.16669 6.65179 8.23128 6.61595 8.27107C6.58008 8.31083 6.52172 8.31107 6.48598 8.27116C6.45024 8.23126 6.45033 8.16666 6.48618 8.12688C6.52204 8.08711 6.5804 8.08688 6.61614 8.12678Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M10.5082 3.03244C10.8312 3.39276 10.8305 3.96634 10.5067 4.32584L7.20033 7.99591L10.4978 11.676C10.8207 12.0363 10.82 12.6099 10.4961 12.9694C10.1582 13.3443 9.59909 13.3434 9.2623 12.9675L5.56764 8.84416C5.46553 8.73025 5.3854 8.59608 5.33136 8.45048C5.27729 8.30478 5.24981 8.14942 5.25 7.9931C5.25019 7.83677 5.27805 7.68149 5.33248 7.53594C5.38685 7.39056 5.467 7.25691 5.56949 7.1432L9.27441 3.0307C9.61226 2.65569 10.1714 2.65659 10.5082 3.03244ZM6.41613 7.86735C6.39962 7.84199 6.39327 7.81495 6.39323 7.79158C6.39317 7.76244 6.40292 7.72691 6.43068 7.69609C6.43527 7.69099 6.44026 7.68616 6.4456 7.68164C6.47335 7.65817 6.51067 7.64315 6.55186 7.64319C6.56247 7.6432 6.57282 7.64421 6.58281 7.6461M6.58201 8.34411L6.50299 8.25593L6.48347 8.23414C6.45349 8.20068 6.43095 8.16268 6.41585 8.12241C6.39934 8.14783 6.39292 8.17502 6.39289 8.19863C6.39285 8.22816 6.40278 8.26328 6.42991 8.29357C6.4345 8.2987 6.43947 8.30354 6.44478 8.30806M6.4124 8.1128C6.41496 8.11397 6.41753 8.11511 6.42011 8.11621C6.42317 8.11198 6.42654 8.10781 6.43021 8.10373M6.4124 8.1128C6.40495 8.09103 6.39963 8.06866 6.39645 8.04603C6.39172 8.01234 6.39172 7.97798 6.39647 7.9443C6.39969 7.92155 6.40508 7.89896 6.41261 7.8771C6.41522 7.87591 6.41784 7.87476 6.42046 7.87365M6.52939 8.193L6.54449 8.17947L6.52939 8.193ZM6.56719 7.79113L6.55168 7.80505L6.56719 7.79113ZM6.53592 7.79085L6.55143 7.77694L6.53592 7.79085Z'
          fill='#171A1C'
          fillRule='evenodd'
        />
      </svg>
    </ChevronContainer>
  );
}
