import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useBuildNumber } from 'src/app/queries/admin/get-build-number';

import { Notification } from './notification';
import { NotificationTypes } from './types';

export function VersionChecker() {
  const { data } = useBuildNumber();

  const [buildNumber, setBuildNumber] = useState(data?.BuildNumber);
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    // if buildNumber in state is undefined, it means that `data` from the useBuildNumber query was undefined on the first render
    // when the buildNumber is undefined but we have a build number from the query to update with, we update state
    if (!buildNumber && data?.BuildNumber) {
      setBuildNumber(data?.BuildNumber);
      return;
    }

    // if the buildNumber in state is truthy (not undefined on the first render) AND does not match the number we get from the query,
    // then a number API version is available and the user should be prompted to refresh the page
    if (Boolean(buildNumber) && Boolean(data) && buildNumber !== data?.BuildNumber) {
      setBuildNumber(data?.BuildNumber);
      setIsNewVersionAvailable(true);
    }
  }, [buildNumber, data]);

  useEffect(() => {
    // if a new version is available AND we get a forceRefresh returned from the API, the automatically reload the page
    if (isNewVersionAvailable && data?.ForceRefresh) {
      handleReloadApplication();
    }
  }, [isNewVersionAvailable, data?.ForceRefresh]);

  const handleReloadApplication = () => {
    setIsNewVersionAvailable(false);
    window.location.reload();
  };

  return isNewVersionAvailable ? (
    <StyledNotification
      automationId='new-api-version-available-notification'
      message='New version available'
      subContent={
        <>
          <UnderlineRefresh>Refresh</UnderlineRefresh> for a new version of the application.
        </>
      }
      uuid=''
      variant={NotificationTypes.Info}
      onRemove={handleReloadApplication}
    />
  ) : null;
}

const UnderlineRefresh = styled.span`
  text-decoration: underline;
`;

const StyledNotification = styled(Notification)`
  cursor: pointer;
`;
