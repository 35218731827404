import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { inventoryAtom } from 'src/app/state/inventory';

import { getMenuPlacement } from '../../lib/dropdown/menu/get-menu-placement';

type InventoryAdjustmentReasonDropdownProps = {
  automationId?: string;
  className?: string;
  customLabel?: string;
  disabled?: boolean;
  hasLabel?: boolean;
  onChange: (value: number | string) => void;
  required?: boolean;
  value: number | string;
  width?: string;
};

export function InventoryAdjustmentReasonDropdown(props: InventoryAdjustmentReasonDropdownProps) {
  const {
    className = '',
    onChange,
    value,
    disabled = false,
    required,
    automationId,
    hasLabel = true,
    width,
    customLabel,
  } = props;
  const { adjustmentReasons } = useRecoilValue(inventoryAtom);
  const flags = useFlags();
  const auditV1AdjustmentReasonsMenuPlacement =
    flags['pos.backoffice.audit-v1-adjustment-reasons-menu-cutoff-fix.rollback'];
  const options = [...adjustmentReasons.filter(Boolean)];
  const sortedOptions = options.sort((a, b) => a.localeCompare(b));
  const label = hasLabel ? 'Reason:' : '';

  return (
    <DropdownSingle
      automationId={automationId}
      className={className}
      disabled={disabled}
      helpText='Select a reason'
      inputId='select-reason'
      label={customLabel ?? label}
      menuPlacement={auditV1AdjustmentReasonsMenuPlacement ? getMenuPlacement() : undefined}
      options={sortedOptions.map((reason) => ({
        label: reason,
        id: reason,
      }))}
      required={required}
      setValue={(newVal) => onChange(newVal.id)}
      value={value}
      width={width}
    />
  );
}
