import React from 'react';

import styled from 'styled-components';

import PermissionStore from 'src/app_deprecated/stores/PermissionStore';

import { Modal } from 'src/app/components/lib/modal';
import { useElevateSuperuserQuery } from 'src/app/queries/permissions/elevate-superuser';

type ElevateSuperuserModalProps = {
  onClose: () => void;
};

export function ElevateSuperuserModal({ onClose }: ElevateSuperuserModalProps) {
  const { mutateAsync } = useElevateSuperuserQuery();

  const handleEnable = async () => {
    await mutateAsync();
    await PermissionStore.refresh();
    onClose();
  };

  return (
    <Modal
      Content={
        <Container>
          <Item>Enabling edit access will allow you to make any changes under this LSP.</Item>
          <Item>This puts you at risk for making unwanted changes.</Item>
          <Item>Are you sure you want to enable edit access?</Item>
        </Container>
      }
      height='268px'
      open
      primaryActions={[
        {
          automationId: 'elevate-superuser-modal-confirm-button',
          label: 'Enable',
          loading: false,
          disabled: false,
          variant: 'primary',
          onClick: () => void handleEnable(),
        },
      ]}
      secondaryActions={[
        {
          automationId: 'elevate-superuser-modal-cancel-button',
          label: 'Cancel',
          loading: false,
          disabled: false,
          variant: 'link-secondary',
          onClick: onClose,
        },
      ]}
      title='Enable edit access'
      width='522px'
      onClose={onClose}
    />
  );
}

const Container = styled.ul`
  font-size: 14px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Item = styled.li`
  height: 20%;
  list-style-type: none;
`;
