/**
 * Util function to check and correctly set the lspId, locId at during endpoint
 * calls.
 * @param customerFormData The custom payload to check
 * @param fileName The original form data to check against
 */

export function getUpdatedFormDataPayload(customerFormData, formData) {
  ['LspId', 'LocId', 'OrgId'].forEach((field) => {
    if (customerFormData?.[field] && formData[field] !== customerFormData[field]) {
      formData[field] = customerFormData[field];
    }
  });

  return formData;
}
