import React from 'react';

export function FinancialsIcon(): JSX.Element {
  return (
    <svg fill='none' height='18' viewBox='0 0 24 18' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M2.57143 2.143C2.09804 2.143 1.71429 2.52675 1.71429 3.00014V15.0001C1.71429 15.4735 2.09804 15.8573 2.57143 15.8573H21.4286C21.902 15.8573 22.2857 15.4735 22.2857 15.0001V3.00014C22.2857 2.52675 21.902 2.143 21.4286 2.143H2.57143ZM0 3.00014C0 1.57998 1.15127 0.428711 2.57143 0.428711H21.4286C22.8487 0.428711 24 1.57998 24 3.00014V15.0001C24 16.4203 22.8487 17.5716 21.4286 17.5716H2.57143C1.15127 17.5716 0 16.4203 0 15.0001V3.00014Z'
        fill='var(--color-grayscale-black)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M12 7.28585C11.0532 7.28585 10.2857 8.05337 10.2857 9.00014C10.2857 9.94691 11.0532 10.7144 12 10.7144C12.9468 10.7144 13.7143 9.94691 13.7143 9.00014C13.7143 8.05337 12.9468 7.28585 12 7.28585ZM8.57143 9.00014C8.57143 7.10659 10.1065 5.57157 12 5.57157C13.8935 5.57157 15.4286 7.10659 15.4286 9.00014C15.4286 10.8937 13.8935 12.4287 12 12.4287C10.1065 12.4287 8.57143 10.8937 8.57143 9.00014Z'
        fill='var(--color-grayscale-black)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M4.28571 5.57157C4.28571 5.09818 4.66947 4.71443 5.14286 4.71443H6C6.47339 4.71443 6.85714 5.09818 6.85714 5.57157C6.85714 6.04495 6.47339 6.42871 6 6.42871H5.14286C4.66947 6.42871 4.28571 6.04495 4.28571 5.57157Z'
        fill='var(--color-grayscale-black)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M17.1429 12.4287C17.1429 11.9553 17.5266 11.5716 18 11.5716H18.8571C19.3305 11.5716 19.7143 11.9553 19.7143 12.4287C19.7143 12.9021 19.3305 13.2859 18.8571 13.2859H18C17.5266 13.2859 17.1429 12.9021 17.1429 12.4287Z'
        fill='var(--color-grayscale-black)'
        fillRule='evenodd'
      />
    </svg>
  );
}
