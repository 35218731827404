import React from 'react';

import styled from 'styled-components';

function NoOptions() {
  return <NoOptionsContainer>No results found</NoOptionsContainer>;
}

export default NoOptions;

const NoOptionsContainer = styled.p`
  margin: var(--sizes-40);
  padding: var(--sizes-20) var(--sizes-40);
  border-radius: var(--sizes-20);
  font: var(--font-regular-14pt-bold);
  color: var(--color-gray-80);
`;
