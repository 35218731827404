import React from 'react';

import HttpClient from 'src/app_deprecated/utils/HttpClient';

import { DropdownSingle } from 'src/app/components/lib/dropdown';
import { FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { Modal } from 'src/app/components/lib/modal';
import { STATES } from 'src/app/constants/states';

type CreateTemplatePopupProps = {
  data: any;
  entityName: string; // TODO: The entityName prop isn't actually used - can removed and remove where its passed in any implementation sites
  onClose: () => void;
  title?: string;
  URL: string;
};

type CreateTemplatePopupState = {
  name: string;
  state: string;
  type: string;
};

const stateOptions = STATES.map((state) => ({
  key: state,
  id: state,
  label: state,
}));

export default class CreateTemplatePopup extends React.Component<CreateTemplatePopupProps, CreateTemplatePopupState> {
  constructor(props) {
    super(props);

    this.state = {
      name: undefined,
      state: '',
      type: '',
    };
  }

  saveTemplate = async () => {
    const { name, state, type } = this.state;
    const { onClose, URL, data } = this.props;

    const resp = await HttpClient.post(
      URL,
      {
        Data: data,
        Name: name,
        State: state,
        Type: type,
      },
      `Failed to save template`,
      `Template Saved`
    );
    if (resp) {
      onClose();
    }
  };

  get isValid() {
    const { name } = this.state;
    return !!name;
  }

  render() {
    const { name, state, type } = this.state;
    const { onClose, title = 'Create template' } = this.props;

    return (
      <Modal
        Content={
          <FormSection>
            <Input
              label='Template name:'
              placeholder='Enter name'
              required
              value={name}
              onChange={(event) => this.setState({ name: event.target.value })}
            />
            <DropdownSingle
              helpText='Select a state'
              label='State:'
              options={stateOptions}
              setValue={(val) => {
                this.setState({ state: `${val.id}` });
              }}
              value={state}
            />
            <DropdownSingle
              helpText='Select a type'
              label='Type:'
              options={[
                { label: '', id: '' },
                { label: 'Cultivation', id: 'Cultivation' },
                { label: 'Retail', id: 'Retail' },
                { label: 'Manufacturing', id: 'Manufacturing' },
              ]}
              setValue={(val) => {
                this.setState({ type: `${val.id}` });
              }}
              value={type}
            />
          </FormSection>
        }
        open
        primaryActions={[
          {
            label: 'Create',
            onClick: () => {
              void this.saveTemplate();
            },
            disabled: !this.isValid,
          },
        ]}
        title={title}
        onClose={onClose}
      />
    );
  }
}
