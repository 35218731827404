import type { ReportCategory } from 'src/app/queries/posv3/reports/list-reports';

export function getNavStringFromCategoryAndCname({
  category,
  canonicalName,
  inventoryPerformanceFlag,
  employeePerformanceFlag,
}: {
  canonicalName: string;
  category: ReportCategory;
  employeePerformanceFlag: boolean;
  inventoryPerformanceFlag: boolean;
}) {
  if (
    (category.toLowerCase() === 'inventory' && inventoryPerformanceFlag) ||
    (category.toLowerCase() === 'employee' && employeePerformanceFlag)
  ) {
    return `/reports/${category.toLowerCase()}/reports/${canonicalName}`;
  }
  return `/reports/${category.toLowerCase()}/${canonicalName}`;
}

export enum ReportView {
  ASYNC = 'async',
  EXPORT_ONLY = 'export-only',
  STANDARD = 'standard',
}

type ComputeReportViewParams = {
  isAsync: boolean;
  isRunnable: boolean;
};

export function computeReportView({ isAsync, isRunnable }: ComputeReportViewParams): ReportView {
  if (isAsync) {
    return ReportView.ASYNC;
  }
  if (isRunnable) {
    return ReportView.STANDARD;
  }
  return ReportView.EXPORT_ONLY;
}
