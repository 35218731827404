import styled from 'styled-components';

import { InventoryAdjustmentReasonDropdown } from 'src/app/components/form-elements/dropdowns/inventory-adjustment-reasons';
import { LabelDropdown } from 'src/app/components/form-elements/dropdowns/labels';
import { BooleanSelect } from 'src/app/components/lib/select/boolean-select/index';

import { BaseInput } from '../base-input';
import { SelectAdornment } from '../select-adornment';

export const TableInput = styled(BaseInput)`
  background-color: white;
  height: var(--sizes-70);

  .MuiInputBase-input {
    padding: var(--sizes-20) var(--sizes-40);
    font: var(--font-extra-small-12pt-normal);
  }

  .MuiInputBase-adornedEnd {
    .MuiInputBase-input {
      padding-left: 0;
    }
  }
`;

export const EndAdornment = styled.span`
  font: var(--font-extra-small-12pt-bold);
  text-transform: uppercase;
  color: var(--color-gray-60);
`;

export const TableSelectAdornment = styled(SelectAdornment)`
  display: flex;

  .select-select-input {
    height: var(--sizes-60);

    .MuiInputBase-input {
      font: var(--font-extra-small-12pt-semibold);
    }
  }
`;

export const TableBooleanSelect = styled(BooleanSelect)`
  display: flex;
  height: var(--sizes-70);

  .MuiSelect-select {
    padding: var(--sizes-20) var(--sizes-40) !important;
  }

  .select-select-input {
    font-size: var(--sizes-30);
  }

  .MuiSelect-icon {
    top: 14px;
  }
`;

export const TableLabelDropdown = styled(LabelDropdown)`
  height: var(--sizes-70);

  .MuiInputBase-formControl {
    display: flex;
    height: var(--sizes-70);
  }

  .MuiOutlinedInput-input {
    font-size: var(--sizes-30);
  }
`;

export const TableAdjustmentReason = styled(InventoryAdjustmentReasonDropdown)`
  height: var(--sizes-70);
  background-color: white;

  .MuiInputBase-formControl {
    display: flex;
    height: var(--sizes-70);
  }

  .MuiInputBase-input {
    padding: var(--sizes-20) var(--sizes-40);
  }

  .MuiOutlinedInput-input {
    font-size: var(--sizes-30);
  }
`;
