import React from 'react';

export function DragHandleIcon(): JSX.Element {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M1.4048 3.51032C1.3494 3.52076 1.31786 3.53209 1.25882 3.56276C1.19246 3.59724 1.09453 3.69653 1.05804 3.76635C0.9594 3.95507 0.987228 4.18245 1.12779 4.33625C1.19699 4.41196 1.2792 4.462 1.37752 4.48824L1.4304 4.50235H8H14.5696L14.6225 4.48824C14.7208 4.462 14.803 4.41196 14.8722 4.33625C15.0126 4.18269 15.0405 3.95478 14.942 3.76635C14.9214 3.72703 14.9003 3.70022 14.8512 3.65085C14.7807 3.57999 14.7264 3.54657 14.6366 3.51876L14.5888 3.50395L8.0192 3.50292C2.60456 3.50206 1.44172 3.50337 1.4048 3.51032ZM1.41972 7.50728C1.36647 7.51629 1.31628 7.53336 1.264 7.56024C1.19246 7.59702 1.09619 7.69336 1.05804 7.76635C0.9594 7.95507 0.987228 8.18245 1.12779 8.33625C1.19699 8.41196 1.2792 8.462 1.37752 8.48824L1.4304 8.50235H8H14.5696L14.6225 8.48824C14.7208 8.462 14.803 8.41196 14.8722 8.33625C15.0126 8.18269 15.0405 7.95478 14.942 7.76635C14.9214 7.72703 14.9003 7.70022 14.8512 7.65085C14.7807 7.57999 14.7264 7.54657 14.6366 7.51876L14.5888 7.50395L8.0192 7.50309C4.40592 7.50262 1.43616 7.50451 1.41972 7.50728ZM1.41972 11.5073C1.36647 11.5163 1.31628 11.5334 1.264 11.5602C1.19246 11.597 1.09619 11.6934 1.05804 11.7663C0.9594 11.9551 0.987228 12.1825 1.12779 12.3362C1.19699 12.412 1.2792 12.462 1.37752 12.4882L1.4304 12.5023H8H14.5696L14.6225 12.4882C14.7208 12.462 14.803 12.412 14.8722 12.3362C15.0126 12.1827 15.0405 11.9548 14.942 11.7663C14.9214 11.727 14.9003 11.7002 14.8512 11.6509C14.7807 11.58 14.7264 11.5466 14.6366 11.5188L14.5888 11.5039L8.0192 11.5031C4.40592 11.5026 1.43616 11.5045 1.41972 11.5073Z'
        fill='#B0B7BB'
        fillRule='evenodd'
      />
    </svg>
  );
}
