import styled from 'styled-components';

import { Button } from '../lib/button';

export const BannerContainer = styled.div`
  display: grid;
  grid-row: auto;
  grid-template-columns: max-content auto auto;
  padding: var(--sizes-20) var(--sizes-40);
  align-items: center;
  gap: var(--sizes-20);
  border-radius: var(--sizes-20);
  background: var(--color-blue-10);
  margin-bottom: var(--sizes-50);
  min-height: 50px;
`;

export const BetaPill = styled.div`
  color: var(--color-brand-primary-white);
  font-size: 9px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.045px;
  text-transform: uppercase;
  border-radius: 2px;
  background: var(--color-blue-80);
  padding: 2px var(--sizes-10);
`;

export const BannerCopy = styled.p`
  color: var(--color-blue-80);
  font: var(--font-small-13pt-normal);
`;

export const BannerButton = styled(Button)`
  justify-self: flex-end;
`;
