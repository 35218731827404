import React from 'react';

// recoil

// components
import { useRecoilValue } from 'recoil';

import PermissionDeniedModal from 'src/app/components/modals/modals/permission-denied-modal';
import { SessionExpirationModal } from 'src/app/components/modals/modals/session-expiration-modal';
import CreateTemplatePopup from 'src/app/pages/superuser/licence-types/create-template-popup';
import { useModal, Modal } from 'src/app/state/modals';
import { userIsLoggedInSelector } from 'src/app/state/user';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import AddReasonModal from './modals/add-reason-modal';
import ConfirmModal from './modals/confirm-modal';

export function Modals() {
  const permissionDeniedModal = useModal(Modal.permissionDenied);
  const confirmModal = useModal(Modal.confirm);
  const addReasonModal = useModal(Modal.addReason);
  const createTemplateModal = useModal(Modal.createTemplate);

  const { lspSettings } = useRecoilValue(userDispensariesAtom);
  const sessionTimeoutMinutes = lspSettings?.SessionTimeoutMinutes ?? 0;
  const userIsLoggedIn = useRecoilValue(userIsLoggedInSelector);
  const shouldUseSessionTimeout = userIsLoggedIn && sessionTimeoutMinutes > 0;

  return (
    <>
      {permissionDeniedModal.state && <PermissionDeniedModal open onClose={permissionDeniedModal.closeModal} />}
      {confirmModal.state && <ConfirmModal {...confirmModal.state} open onClose={confirmModal.closeModal} />}
      {addReasonModal.state && <AddReasonModal {...addReasonModal.state} open onClose={addReasonModal.closeModal} />}
      {shouldUseSessionTimeout && (
        <SessionExpirationModal sessionTimeoutMinutes={sessionTimeoutMinutes} userIsLoggedIn={userIsLoggedIn} />
      )}
      {createTemplateModal.state && (
        <CreateTemplatePopup {...createTemplateModal.state} open onClose={createTemplateModal.closeModal} />
      )}
    </>
  );
}
