import { useState } from 'react';

import {
  gridColumnDefinitionsSelector,
  useGridApiContext,
  useGridSelector,
  useGridRootProps,
} from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';

import { actions as ActionTypes } from 'src/app_deprecated/constants/UserConstants';
import AppDispatcher from 'src/app_deprecated/utils/AppDispatcher';

import { ActionsFieldName } from 'src/app/components/lib/table';
import { getDefaultTableSetting } from 'src/app/components/lib/table/utils';
import { useDataGridWrapperContext } from 'src/app/components/lib/table/wrapper-context';
import { useUserTableSettings } from 'src/app/queries/settings/update-user-table-settings';
import { useNotificationActions } from 'src/app/state/notifications';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { hasDifferentColumnSortOrder, initializeColumnOrder, filterColumnsBySearch, CheckboxFieldName } from './utils';

import type { GridStateColDef } from '@mui/x-data-grid-pro';

export const useColumnsModal = ({ onClose }: { onClose: () => void }) => {
  const { mutate: setUserTableSettings } = useUserTableSettings();
  const notification = useNotificationActions();
  // DataGrid state:
  const apiRef = useGridApiContext();
  const { columnVisibilityModel = {}, initialState } = useGridRootProps();
  const { tableName } = useDataGridWrapperContext();
  const { tableSettings } = useRecoilValue(userDispensariesAtom);
  const currentTableSettings = tableSettings.find((setting) => setting.table === tableName);
  const columns: GridStateColDef[] = useGridSelector(apiRef, gridColumnDefinitionsSelector);

  // State hooks:
  const [searchValue, setSearchValue] = useState('');
  const [workingColumnVisibilityModel, setWorkingColumnVisibilityModel] = useState(columnVisibilityModel);
  const initializedColumns = initializeColumnOrder(columns, columnVisibilityModel ?? {});
  const columnsHiddenFromConfig = initializedColumns?.filter(({ hideFromColumnConfig }) => hideFromColumnConfig) ?? [];
  const columnsNotHiddenFromConfig =
    initializedColumns?.filter(({ hideFromColumnConfig }) => !hideFromColumnConfig) ?? [];

  const [workingColumns, setWorkingColumns] = useState(columnsNotHiddenFromConfig);

  // Data:
  const visibleColumns = Object.entries(workingColumnVisibilityModel).filter(
    ([key, value]) => value && key !== 'Actions'
  );

  const filteredColumns = filterColumnsBySearch(searchValue, workingColumns);
  const saveBtnIsDisabled = workingColumns.length < 1 || visibleColumns.length < 1;
  const saveBtnTooltipTxt = saveBtnIsDisabled ? 'At least one column must be selected.' : '';

  // Helpers:
  const mapTableColumnSettings = (column: GridStateColDef, index: number) => {
    const columnLayoutSettings =
      currentTableSettings?.layout.find(({ name }) => name === column.field) ??
      getDefaultTableSetting(column.field, column.headerName ?? column.field);

    if (!currentTableSettings && initialState?.sorting?.sortModel?.length) {
      initialState.sorting.sortModel.forEach((columSortItem) => {
        if (columSortItem.field === columnLayoutSettings.name) {
          columnLayoutSettings.sort = columSortItem;
        }
      });
    }

    return {
      ...columnLayoutSettings,
      order: index,
      visible: workingColumnVisibilityModel[column.field],
      width: columns.find(({ field }) => field === column.field)?.width,
    };
  };

  const filterTableColumnSettings = ({ name }) => name !== ActionsFieldName;

  const getUpdatedTableLayout = (columns: GridStateColDef[]) =>
    columns.map(mapTableColumnSettings).filter(filterTableColumnSettings);

  const handleSetNotification = (result: boolean) => {
    const ERR_MSG = 'Could not save table layout';
    const SUCCESS_MSG = 'New table layout saved';
    const message = result ? SUCCESS_MSG : ERR_MSG;
    notification[result ? 'success' : 'error']({ message });
  };

  const saveTableLayout = () => {
    // on settled update column visibility model
    const onSettled = () => {
      apiRef.current.setColumnVisibilityModel(workingColumnVisibilityModel);
      closeModal(false);
    };

    if (tableName) {
      const columns = [...workingColumns, ...columnsHiddenFromConfig];
      const updatedTableLayout = getUpdatedTableLayout(columns);
      const payload = { Setting: tableName, SettingType: 'table', value: JSON.stringify(updatedTableLayout) };
      setUserTableSettings(payload, {
        onSuccess: ({ Result }: { Result: boolean }) => {
          AppDispatcher.dispatch({
            actionType: ActionTypes.SET_TABLE_SETTINGS,
            data: { table: tableName, layout: updatedTableLayout },
          });
          handleSetNotification(Result);
        },
        onError: () => handleSetNotification(false),
        onSettled,
      });
    } else {
      handleSetNotification(false);
      onSettled();
    }
  };

  const updateColumnOrder = () => {
    if (hasDifferentColumnSortOrder(initializedColumns, workingColumns)) {
      // include columns hidden from column config that were filtered out of workingColumns
      // this assumes the hidden columns will come after any columns that have been sorted in the column config-- not sure of a great way to have any other assumed order for these
      const workingColumnsCopy = [...workingColumns, ...columnsHiddenFromConfig];

      const actionsColumn = columns.find(({ field }) => field === ActionsFieldName);
      const checkboxColumn = columns.find(({ field }) => field === CheckboxFieldName);
      // insert checkbox column at the beginning of the array
      if (checkboxColumn) {
        workingColumnsCopy.unshift(checkboxColumn);
      }

      // insert actions column at the end of the array
      if (actionsColumn) {
        workingColumnsCopy.push(actionsColumn);
      }

      workingColumnsCopy.forEach(({ field }, index) => {
        apiRef.current.setColumnIndex(field, index);
      });
    }
  };

  const resetState = () => {
    setWorkingColumns(initializedColumns);
    setWorkingColumnVisibilityModel(columnVisibilityModel);
  };

  const closeModal = (cancel = true) => {
    if (cancel) {
      resetState();
    }
    onClose();
  };

  // Event handlers:
  const handleSaveOnClick = () => {
    updateColumnOrder();
    void saveTableLayout();
  };

  const handleCheckboxOnChange = (field: string) => {
    setWorkingColumnVisibilityModel({
      ...workingColumnVisibilityModel,
      [field]: !workingColumnVisibilityModel[field],
    });
  };
  const handleMoveColumn = (dragIndex: number, hoverIndex: number) => {
    setWorkingColumns((previousWorkingColumns) => {
      const columnsCopy = [...previousWorkingColumns];
      columnsCopy.splice(dragIndex, 1);
      columnsCopy.splice(hoverIndex, 0, previousWorkingColumns[dragIndex]);

      return columnsCopy;
    });
  };
  return {
    searchValue,
    filteredColumns,
    workingColumnVisibilityModel,
    saveBtnIsDisabled,
    saveBtnTooltipTxt,
    setSearchValue,
    handleCheckboxOnChange,
    handleMoveColumn,
    closeModal,
    handleSaveOnClick,
  };
};
