
// Receive keyboard events without risk they can be cancelled by a previously 
// registered listener. This addresses an issue where the scanner was stopping 

import { HardwareService } from "../models/index";

// event propagation which means the scale would never receive the events.
export class KeyboardEventRepeater {
  private keyDownEvents = new EventTarget();
  private keyPressEvents = new EventTarget();
  private keyUpEvents = new EventTarget();

  private listenerCounter = 0;

  private static _instance: KeyboardEventRepeater | undefined;
  static get instance(): KeyboardEventRepeater { 
    if(!this._instance) {
      this._instance = new this();
    }

    return this._instance as KeyboardEventRepeater;
   }

  constructor() {
    HardwareService.logger.d('Keyboard Event Repeater initialized');
    document.addEventListener('keydown', this.handleKeyDownRef);
    document.addEventListener('keypress', this.handleKeyPressRef);
    document.addEventListener('keyup', this.handleKeyUpRef);
  }

  private dispose() : void {
    HardwareService.logger.d('Keyboard Event Repeater Disposed');
    document.removeEventListener('keydown', this.handleKeyDownRef);
    document.removeEventListener('keypress', this.handleKeyPressRef);
    document.removeEventListener('keyup', this.handleKeyUpRef);
    KeyboardEventRepeater._instance = undefined;
  }

  private handleKeyDownRef = this.handleKeyDown.bind(this);
  private handleKeyDown(event: KeyboardEvent): void {
    this.keyDownEvents.dispatchEvent(new CustomEvent('keydown', { detail: event }));
  }

  private handleKeyPressRef = this.handleKeyPress.bind(this);
  private handleKeyPress(event: KeyboardEvent): void { 
    // Stop event propagation if not coming from an input field. This prevents
    // unwanted browser behavior when responding to the keys.
    if (!(event.target instanceof window.HTMLElement && this.isInput(event.target))) {
      event.stopImmediatePropagation()
      event.preventDefault()
    }

    this.keyPressEvents.dispatchEvent(new CustomEvent('keypress', { detail: event }));
  } 

  private handleKeyUpRef = this.handleKeyUp.bind(this);
  private handleKeyUp(event: KeyboardEvent): void {
    this.keyUpEvents.dispatchEvent(new CustomEvent('keyup', { detail: event }));
  }

  private isContentEditable(element: HTMLElement) : boolean {
    if (typeof element.getAttribute !== 'function') {
      return false
    }
  
    return !!element.getAttribute('contenteditable')
  }

  private isInput(element: HTMLElement): boolean {
    if (!element) {
      return false
    }
  
    const { tagName } = element
    const editable = this.isContentEditable(element)
  
    return tagName === 'INPUT' || tagName === 'TEXTAREA' || editable
  }


  addEventListener(type: 'keydown' | 'keypress' | 'keyup' | string, callback: EventListenerOrEventListenerObject): void {
    switch (type) {
      case 'keydown': this.keyDownEvents.addEventListener(type, callback); break;
      case 'keypress': this.keyPressEvents.addEventListener(type, callback); break;
      case 'keyup': this.keyUpEvents.addEventListener(type, callback); break;
      default: throw new Error(`Invalid event type: ${type}`);
    }
    this.listenerCounter++;
  }

  removeEventListener(type: 'keydown' | 'keypress' | 'keyup' | string, callback: EventListenerOrEventListenerObject): void {
    switch (type) {
      case 'keydown': this.keyDownEvents.removeEventListener(type, callback); break;
      case 'keypress': this.keyPressEvents.removeEventListener(type, callback); break;
      case 'keyup': this.keyUpEvents.removeEventListener(type, callback); break;
      default: throw new Error(`Invalid event type: ${type}`);
    }
    this.listenerCounter--;

    if(this.listenerCounter <= 0) {
      this.dispose();
    }
  }
}
