import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { settingsAtom } from './settings';

export const darkModeContext = createContext<{
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}>({ darkMode: false, setDarkMode: () => false });

const { Provider } = darkModeContext;

export function DarkModeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(false);
  const { PaletteMode } = useRecoilValue(settingsAtom);
  useEffect(() => {
    setDarkMode(PaletteMode === 'dark');
  }, [PaletteMode]);
  const value = useMemo(
    () => ({
      darkMode,
      setDarkMode,
    }),
    [darkMode, setDarkMode]
  );
  return <Provider value={value}>{children}</Provider>;
}

export function useDarkMode() {
  const { darkMode } = useContext(darkModeContext);
  return darkMode;
}
