import React from 'react';

type CaretDownProps = {
  fill?: string;
  rotate?: boolean;
};

export function CaretDown({ fill = 'var(--color-gray-90)', rotate }: CaretDownProps) {
  const styleAttr = rotate ? { transform: 'rotate(180deg)' } : null;

  return (
    <svg fill='none' height='16' style={styleAttr} viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M3.86243 6.4625C4.05959 6.28581 4.37883 6.28618 4.57548 6.46334L7.99585 9.54474L11.4255 6.47166C11.6227 6.29499 11.9419 6.29539 12.1385 6.47256C12.3352 6.64973 12.3347 6.93659 12.1375 7.11327L8.52958 10.3461C8.45933 10.4091 8.37569 10.4593 8.28397 10.4933C8.19213 10.5274 8.09371 10.5449 7.99435 10.5448C7.89498 10.5447 7.79662 10.5269 7.70488 10.4926C7.61326 10.4584 7.53005 10.4082 7.45999 10.3451L3.8615 7.10321C3.66485 6.92605 3.66527 6.6392 3.86243 6.4625Z'
        fill={fill}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M3.79938 6.4057C4.03145 6.19772 4.40723 6.19816 4.63869 6.40669L7.99602 9.43128L11.3624 6.41486C11.5945 6.20689 11.9703 6.20736 12.2018 6.41591C12.4332 6.62446 12.4327 6.96211 12.2006 7.17008L8.59262 10.4029C8.51408 10.4733 8.42061 10.5294 8.31805 10.5675C8.21536 10.6056 8.10532 10.6251 7.99423 10.625C7.88313 10.6249 7.77315 10.6051 7.67057 10.5667C7.56813 10.5284 7.47509 10.4723 7.39675 10.4017L3.79828 7.15987C3.56681 6.95134 3.5673 6.61369 3.79938 6.4057ZM4.51226 6.52C4.35044 6.37421 4.08773 6.3739 3.92548 6.51931C3.76323 6.66471 3.76289 6.90077 3.92471 7.04656L7.52293 10.2882C7.58472 10.3439 7.65839 10.3883 7.73919 10.4186C7.82009 10.4488 7.90684 10.4644 7.99447 10.4645C8.08209 10.4647 8.16889 10.4492 8.24988 10.4192C8.33077 10.3892 8.40429 10.3451 8.46624 10.2896L12.0745 7.05646C12.2368 6.91107 12.2371 6.67501 12.0753 6.52921C11.9135 6.38341 11.6508 6.38308 11.4885 6.52847L8.0589 9.60155C8.02398 9.63283 7.96746 9.63276 7.93263 9.60139L4.51226 6.52ZM7.88045 9.6481C7.91528 9.67945 7.91523 9.73024 7.88034 9.76154C7.84543 9.79282 7.78861 9.79304 7.75378 9.76168C7.71896 9.73032 7.71901 9.67954 7.7539 9.64824C7.7888 9.61696 7.84563 9.61674 7.88045 9.6481ZM8.11094 9.64838C8.14585 9.6171 8.20237 9.61718 8.23719 9.64855C8.27198 9.67993 8.27219 9.73099 8.23727 9.76227C8.20235 9.79354 8.14583 9.79346 8.11102 9.7621C8.07622 9.73071 8.07602 9.67965 8.11094 9.64838Z'
        fill={fill}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M3.65338 6.2428C3.96867 5.96024 4.47055 5.96078 4.78511 6.24416L7.99642 9.13721L11.2165 6.25194C11.5318 5.96941 12.0337 5.96999 12.3482 6.2534C12.6763 6.54904 12.6755 7.0383 12.3466 7.33299L8.73864 10.5658C8.73863 10.5658 8.73866 10.5658 8.73864 10.5658C8.63897 10.6552 8.52157 10.7253 8.39417 10.7726C8.26668 10.8199 8.13075 10.8439 7.99396 10.8437C7.85717 10.8436 7.7213 10.8192 7.59394 10.7716C7.46674 10.724 7.34979 10.6539 7.2503 10.5642L3.65186 7.32239C3.32373 7.02678 3.32452 6.53753 3.65338 6.2428ZM7.88394 9.82339C7.86174 9.83783 7.83808 9.84339 7.81763 9.84343C7.79213 9.84348 7.76105 9.83494 7.73408 9.81066C7.72962 9.80664 7.72539 9.80228 7.72143 9.7976C7.70089 9.77332 7.68775 9.74066 7.68779 9.70462C7.6878 9.69534 7.68868 9.68628 7.69034 9.67754M8.3011 9.67824L8.22394 9.74738L8.20488 9.76446C8.17559 9.7907 8.14235 9.81042 8.10711 9.82363C8.12935 9.83808 8.15315 9.84369 8.1738 9.84372C8.19964 9.84376 8.23037 9.83506 8.25688 9.81133C8.26136 9.80731 8.2656 9.80296 8.26956 9.79832M8.0987 9.82665C8.09972 9.82441 8.10072 9.82216 8.10168 9.81991C8.09798 9.81722 8.09433 9.81428 8.09076 9.81106M8.0987 9.82665C8.07965 9.83317 8.06008 9.83783 8.04028 9.84061C8.04025 9.84061 8.04031 9.8406 8.04028 9.84061C8.0108 9.84474 7.98073 9.84474 7.95126 9.84059C7.95123 9.84058 7.95129 9.84059 7.95126 9.84059C7.93136 9.83777 7.91159 9.83306 7.89246 9.82646C7.89142 9.82418 7.89042 9.82189 7.88944 9.81959M8.16887 9.72428L8.15704 9.71107ZM7.81724 9.69121L7.82942 9.70478ZM7.817 9.71857L7.80482 9.705Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
}
