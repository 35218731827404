import React from 'react';

export function MigrationIcon(): JSX.Element {
  return (
    <svg fill='none' height='129' viewBox='0 0 136 129' width='136' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='63.4945' cy='63.05' fill='#F5F6F8' r='62.8356' />
      <path
        d='M8.51001 23.8197C8.51001 19.4014 12.0917 15.8197 16.51 15.8197H110.673C115.092 15.8197 118.673 19.4014 118.673 23.8197V38.8197H8.51001V23.8197Z'
        fill='#F3F4F5'
      />
      <path
        d='M8.51001 39.8197H118.673V101.82C118.673 106.238 115.092 109.82 110.673 109.82H16.51C12.0917 109.82 8.51001 106.238 8.51001 101.82V39.8197Z'
        fill='white'
      />
      <path
        clipRule='evenodd'
        d='M79.3862 80.1791C79.3875 80.1772 79.3887 80.1753 79.3899 80.1734C79.4049 80.1501 79.4198 80.1267 79.4346 80.1033C79.4434 80.0893 79.4523 80.0754 79.461 80.0614C79.5668 79.8935 79.6694 79.7235 79.7687 79.5514C79.9422 79.266 79.9314 78.9398 79.7579 78.6646C79.4975 78.2365 78.9227 78.0734 78.4671 78.318C78.316 78.3951 78.1638 78.4706 78.0106 78.5444C77.9941 78.5524 77.9776 78.5603 77.961 78.5682C77.9353 78.5806 77.9095 78.5929 77.8836 78.6051C77.8815 78.6061 77.8793 78.6071 77.8772 78.6082C76.2875 79.3609 74.5978 79.9319 72.9681 80.2854C70.8747 80.7339 68.7272 80.8868 66.5904 80.7339C63.0003 80.4484 59.5186 78.899 57.1216 76.3507C57.0621 76.2885 57.0049 76.2235 56.9476 76.1584C56.7229 75.903 56.4976 75.647 56.1346 75.5658C55.4079 75.4129 54.8981 75.8818 54.7246 76.5036C52.9566 82.6706 58.9655 88.5523 65.5492 88.0426C71.2148 87.7208 76.4635 84.7307 79.3862 80.1791ZM56.5379 78.696C56.5351 80.4037 57.2755 82.0418 58.555 83.3659C60.2539 85.1241 62.7845 86.1876 65.3802 85.9866L65.4026 85.9849L65.425 85.9836C69.1665 85.7711 72.6905 84.236 75.2946 81.8146C74.6714 82.0028 74.0482 82.1645 73.4315 82.2982L73.4261 82.2994C71.1333 82.7906 68.7788 82.9586 66.4342 82.7908L66.417 82.7895L66.417 82.7895C62.7978 82.5018 59.2423 81.0846 56.5379 78.696Z'
        fill='#D2D7DA'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M64.9059 55.2173C64.8969 55.2171 64.8878 55.217 64.8788 55.2168C64.8141 55.2157 64.7493 55.215 64.6845 55.2147C64.6665 55.2145 64.6484 55.2145 64.6303 55.2144C64.4704 55.214 64.3103 55.2157 64.15 55.2195C63.8029 55.2297 63.4992 55.3928 63.3365 55.6681C63.0654 56.086 63.2064 56.6466 63.6511 56.8913C63.8359 56.9986 64.0193 57.1086 64.2011 57.221C64.205 57.2234 64.2088 57.2257 64.2126 57.2281C64.2383 57.244 64.2639 57.2599 64.2895 57.2759C64.2914 57.2771 64.2933 57.2783 64.2952 57.2795C65.7522 58.1902 67.1004 59.2579 68.2174 60.3876C69.6816 61.8657 70.8964 63.5374 71.8292 65.3519C73.3585 68.4201 73.6622 72.0184 72.5234 75.2498C72.4955 75.3284 72.4642 75.4062 72.433 75.4838C72.3067 75.7981 72.1825 76.1071 72.2956 76.4424C72.5234 77.105 73.1958 77.2885 73.8683 77.1254C80.4412 75.474 82.8491 67.6556 79.0854 62.5487C75.9491 58.0884 70.5657 55.3195 64.9059 55.2173ZM74.9487 74.5083C78.7248 72.4919 79.9927 67.3249 77.357 63.7486L77.3434 63.7301L77.3302 63.7113C75.2618 60.7697 72.0532 58.6536 68.457 57.7554C68.9107 58.1453 69.3432 58.5476 69.7499 58.9589C71.3621 60.5864 72.7015 62.4292 73.7306 64.431L73.7365 64.4423L73.7421 64.4537C75.2839 67.5469 75.7541 71.1272 74.9487 74.5083Z'
        fill='#D2D7DA'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M64.928 68.964L64.9256 68.9637C64.1134 68.852 63.3223 69.0405 62.6978 69.4748L62.6875 69.4819L62.6875 69.4819C62.3639 69.7041 62.1046 69.9783 61.9127 70.294L61.9076 70.3024L61.9075 70.3024C61.3436 71.2199 61.4109 72.3512 62.0814 73.1969L62.0856 73.2022L62.0855 73.2022C62.5468 73.788 63.228 74.18 64.0143 74.2881C64.8265 74.3998 65.6176 74.2113 66.2421 73.777L66.2523 73.7699L66.2524 73.7699C66.576 73.5477 66.8353 73.2734 67.0272 72.9578L67.0323 72.9494C67.5963 72.0319 67.5289 70.9006 66.8585 70.0549L66.8522 70.0469L66.8459 70.0388C66.401 69.466 65.7277 69.0731 64.928 68.964ZM65.2238 66.9216C63.8897 66.7381 62.5447 67.0439 61.4601 67.7982C60.8961 68.1856 60.4297 68.6749 60.0826 69.2457C59.0739 70.8869 59.1932 72.9358 60.3971 74.4546C61.1998 75.4739 62.382 76.1467 63.7161 76.3302C65.0502 76.5137 66.3952 76.2079 67.4798 75.4536C68.0438 75.0662 68.5102 74.5769 68.8573 74.0061C69.866 72.3649 69.7467 70.316 68.5427 68.7972C67.751 67.7778 66.5687 67.1051 65.2238 66.9216Z'
        fill='#D2D7DA'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M61.8331 61.63C60.2469 60.8059 58.3702 60.6056 56.5282 60.9825C54.0267 61.4944 51.8101 63.0313 50.7236 65.1953L50.7135 65.2152L50.7031 65.235C49.0134 68.424 48.6858 72.1178 49.6953 75.4935C49.8399 74.8416 50.0135 74.2034 50.2151 73.5869M49.1875 79.5633C49.1868 79.5621 49.1862 79.561 49.1855 79.5599C46.4115 74.889 46.2681 69.0954 48.8133 64.2917C51.6334 58.6751 60.0501 56.718 64.855 61.2439C65.3539 61.7026 65.5166 62.3448 65.0177 62.8646C64.7624 63.1211 64.407 63.1762 64.0502 63.2314C63.9675 63.2442 63.8846 63.257 63.8029 63.2724C60.2562 63.9553 57.0891 66.0042 55.0174 68.7871C53.8026 70.4588 52.8698 72.2834 52.2408 74.21C51.6876 75.9021 51.3622 77.7981 51.308 79.6737C51.308 80.163 50.8741 80.5504 50.3535 80.5504C50.0064 80.5504 49.7136 80.3975 49.5292 80.1121C49.4347 79.9671 49.3429 79.821 49.2538 79.6737C49.2423 79.6548 49.2309 79.6358 49.2195 79.6168C49.2088 79.599 49.1981 79.5811 49.1875 79.5633ZM61.8331 61.63C58.3816 62.6576 55.3626 64.8017 53.295 67.5791L53.2879 67.5887L53.2809 67.5983C51.9413 69.4418 50.9107 71.4569 50.2153 73.5862M51.3251 79.0096C51.3252 79.0097 51.3252 79.0098 51.3253 79.0099L51.3251 79.0096Z'
        fill='#D2D7DA'
        fillRule='evenodd'
      />
      <path
        d='M108.9 15.1529H17.8953C12.6048 15.1529 8.31592 19.4417 8.31592 24.7323V101.368C8.31592 106.658 12.6048 110.947 17.8953 110.947H108.9C114.191 110.947 118.479 106.658 118.479 101.368V24.7323C118.479 19.4417 114.191 15.1529 108.9 15.1529Z'
        stroke='#ADB4B7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.2'
      />
      <path
        d='M8.31592 39.1014H118.479'
        stroke='#ADB4B7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.2'
      />
      <path
        d='M21.08 28.3246C20.4187 28.3246 19.8826 27.7885 19.8826 27.1272C19.8826 26.4659 20.4187 25.9297 21.08 25.9297'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M21.0798 28.3246C21.7411 28.3246 22.2773 27.7885 22.2773 27.1272C22.2773 26.4659 21.7411 25.9297 21.0798 25.9297'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M32.4491 28.3246C31.7878 28.3246 31.2517 27.7885 31.2517 27.1272C31.2517 26.4659 31.7878 25.9297 32.4491 25.9297'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M32.4492 28.3246C33.1105 28.3246 33.6466 27.7885 33.6466 27.1272C33.6466 26.4659 33.1105 25.9297 32.4492 25.9297'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M43.818 28.3246C43.1567 28.3246 42.6206 27.7885 42.6206 27.1272C42.6206 26.4659 43.1567 25.9297 43.818 25.9297'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <path
        d='M43.8181 28.3246C44.4795 28.3246 45.0155 27.7885 45.0155 27.1272C45.0155 26.4659 44.4795 25.9297 43.8181 25.9297'
        stroke='#ADB4B7'
        strokeWidth='1.2'
      />
      <g filter='url(#filter0_d_14_20771)'>
        <circle cx='112.495' cy='101.732' fill='white' r='22.8466' />
        <circle cx='112.495' cy='101.732' r='22.2466' stroke='#ADB4B7' strokeWidth='1.2' />
      </g>
      <path
        d='M106.395 95.5322L120.595 109.732'
        stroke='#BE0517'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
      />
      <path
        d='M106.395 109.732L120.595 95.5322'
        stroke='#BE0517'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
      />
      <defs>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='49.6932'
          id='filter0_d_14_20771'
          width='50.6932'
          x='84.6479'
          y='78.8856'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dx='-5' dy='4' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.917639 0 0 0 0 0.92547 0 0 0 0 0.933301 0 0 0 0.7 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_14_20771' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_14_20771' mode='normal' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
