import React from 'react';

import Translate from 'src/app_deprecated/components/form/layout/Translate.react';

import { BohBack } from 'src/app/components/icons/boh-back';
import { useDarkMode } from 'src/app/state/dark-mode';

import { BackButtonIcon } from './back-icon';
import {
  BackIconButton,
  HeadingContainer,
  HeadingTextContainer,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  SubHeaderContainer,
} from './heading-rebrand.styles';
import { BohIconButton } from './heading.styles';

export type HeadingProps = {
  automationId?: string;
  backBtnTestId?: string;
  children: React.ReactNode | string;
  className?: string;
  iconDataTestId?: string;
  level?: 1 | 2 | 3 | 4;
  marginBottom?: string;
  onBackAction?: (...args: any) => void;
  subheader?: string;
  // in some cases we use customer product titles as subheaders and those should never be translated automatically
  translateSubheader?: boolean;
};

const headings = {
  '1': StyledH1,
  '2': StyledH2,
  '3': StyledH3,
  '4': StyledH4,
};

export function BackButton({ onClick, variant, iconDataTestId, backBtnTestId }) {
  const darkMode = useDarkMode();
  const BackButtonContainer = variant === 'boh' ? BohIconButton : BackIconButton;
  const Icon = variant === 'boh' ? BohBack : BackButtonIcon;
  return (
    <BackButtonContainer
      $darkMode={darkMode}
      aria-label='Back'
      data-testid={backBtnTestId}
      role='navigation'
      onClick={onClick}
    >
      <Icon data-testid={iconDataTestId} />
    </BackButtonContainer>
  );
}

export function Heading({
  automationId = '',
  onBackAction,
  children,
  level = 1,
  subheader,
  iconDataTestId,
  backBtnTestId,
  className = '',
  marginBottom,
  translateSubheader = true,
}: HeadingProps): JSX.Element {
  const HeadingElement = headings[String(level)];
  const hasSubheader = subheader?.length > 0;

  return (
    <HeadingContainer
      $level={level}
      $marginBottom={marginBottom}
      className={`${className} page-heading`}
      data-testid={automationId}
    >
      {onBackAction && (
        <BackButton
          backBtnTestId={backBtnTestId}
          iconDataTestId={iconDataTestId}
          variant='rebrand'
          onClick={onBackAction}
        />
      )}
      <HeadingTextContainer>
        <HeadingElement hasSubheader={hasSubheader}>{children}</HeadingElement>
        {hasSubheader && (
          <SubHeaderContainer $level={level}>
            {translateSubheader && <Translate>{subheader}</Translate>}
            {!translateSubheader && subheader}
          </SubHeaderContainer>
        )}
      </HeadingTextContainer>
    </HeadingContainer>
  );
}
