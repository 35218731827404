import React from 'react';

import { Loader } from 'src/app/components/lib/loader';

import { StyledLinkButton } from './link-button.styles';

import type { SxProps } from '@mui/material';
import type { ButtonProps } from 'src/app/components/lib/button';

export type LinkButtonProps = ButtonProps & {
  automationId?: string;
  className?: string;
  id?: string;
  label: React.ReactNode | string;
  loading?: boolean;
  sx?: SxProps;
  variant?: 'danger' | 'primary' | 'secondary';
};

// THIS IS A BUTTON STYLED TO LOOK LIKE A LINK
export function LinkButton({
  label,
  variant = 'primary',
  onClick,
  sx,
  disabled = false,
  loading = false,
  id = '',
  automationId = '',
  className,
}: LinkButtonProps): JSX.Element {
  return (
    <StyledLinkButton
      $variant={variant}
      className={className}
      data-testid={automationId}
      disabled={disabled}
      id={id}
      sx={sx}
      onClick={onClick}
    >
      {!loading && label}
      {loading && <Loader variant={variant === 'primary' ? 'blue' : 'black'} />}
    </StyledLinkButton>
  );
}
