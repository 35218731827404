import { css } from 'styled-components';

export const additionalTokens = css`
  :root {
    --button-border-active-color-default: rgba(0, 0, 0, 0.5);
    --button-border-radius-large: 8px;
    --button-border-radius-medium: 6px;
    --button-border-radius-small: 4px;
    --button-border-width: 1px;
    --button-letter-spacing: 0.00625rem;

    --font-family-text: Matter, -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --font-body-default-size: 16px;
    --font-body-default: 400 var(--font-body-default-size) var(--font-family-text);
    --font-weight-bold: 700;
    --font-weight-normal: 400;
    --font-weight-semibold: 600;
    --font-letter-spacing: 0.00625rem;

    --navigation-drawer-width: 237px;
    --navigation-drawer-width-tablet: 390px;

    --menu-item-display-value: flex;
    --menu-item-flex-direction: column;
    --menu-item-padding: var(--sizes-30) var(--sizes-70);

    --menu-item-label-container-display-value: flex;
    --menu-item-label-container-justify-content-value: space-between;
    --menu-item-label-container-gap-value: var(--sizes-70);

    --menu-item-label-font: var(--font-regular-14pt-semibold);

    --menu-item-label-secondary-margin-top: 1px;
    --menu-item-label-secondary-color: var(--color-gray-60);
    --menu-item-label-secondary-font: var(--font-extra-small-12pt-bold);
    --menu-item-label-secondary-text-transform: uppercase;
    --menu-item-label-secondary-align-self: center;

    --menu-item-footer-font: var(--font-small-13pt-normal);
    --menu-item-footer-white-space: normal;

    --dropdown-menu-item-label-white-space-value: nowrap;
    --dropdown-menu-item-label-overflow: hidden;
    --dropdown-menu-item-label-text-overflow: ellipsis;
    --dropdown-menu-item-label-display: block;
    --dropdown-menu-item-label-wrapped-white-space: normal;
  }
`;
