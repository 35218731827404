import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { fetchMany } from 'src/app/queries/graphql';
import { FilterOperator, LogicalOperator } from 'src/app/queries/types/gql-filtering-and-pagination';
import { isNotNullish } from 'src/app/utils/type-utils';
import { GetPackagesForGlobalSearchDocument } from 'src/gql/graphql';

import { inventoryPackagesGlobalSearchKeys } from './query-key-factory';

import type { AbridgedFilterInput, QueryProps } from 'src/app/queries/types/gql-filtering-and-pagination';
import type { PackageFilterInput, PackageSortInput } from 'src/gql/graphql';

export function useInventoryPackagesGlobalSearchQuery(filter: string) {
  const queryProps: QueryProps<AbridgedFilterInput<PackageFilterInput>, PackageSortInput> = useMemo(
    () => ({
      filterModel: [
        {
          fields: [
            'serialNumber',
            'sourceSerialNumber',
            'product.whseProductsProductNo',
            'product.whseProductsDescription',
          ],
          operator: FilterOperator.CONTAINS,
          value: filter,
          logicalOperator: LogicalOperator.OR,
        },
      ],
      page: 0,
      pageSize: 20,
      sort: [],
    }),
    [filter]
  );

  return useQuery({
    queryKey: [...inventoryPackagesGlobalSearchKeys.many(queryProps)],
    queryFn: async () => {
      const inventoryPackageData = await fetchMany(GetPackagesForGlobalSearchDocument, {
        ...queryProps,
        applyUserId: true,
      });
      return {
        ...inventoryPackageData,
        items: inventoryPackageData?.items?.filter(isNotNullish) ?? [],
      };
    },
    enabled: filter?.length > 0,
    keepPreviousData: true,
  });
}
