import axios from 'axios';
import _ from 'lodash';

import UserStore from 'src/app_deprecated/stores/UserStore';

import { getUpdatedFormDataPayload } from 'src/app/utils/customer-loc-lsp-payload';

import { ReactQueryError } from './utils';

import type { AddNotificationArgs } from '../components/lib/notification/types';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { BasePayload } from 'src/app/queries/types/api';
import type { NotificationActions } from 'src/app/state/notifications';

// Once per hour
export const refetchInterval = 1 * 60 * 60 * 1000;

export const staleTimeFiveMinutes = 5 * 60 * 1000;

export async function fetchData<ReturnType>(
  endpoint: string,
  // eslint-disable-next-line default-param-last
  params: Record<string, any> = {},
  additionalProps?: {
    error?:
      | boolean
      | {
          action?: keyof NotificationActions;
          args?: AddNotificationArgs;
          statusCodeMap?: Record<number, { action?: keyof NotificationActions; args: AddNotificationArgs }>;
        };
  }
): Promise<ReturnType> {
  const { error } = additionalProps ?? {};
  const userApiPayload: BasePayload = {
    ...UserStore.getApiData(),
    ...params,
  };

  try {
    const response = await axios.post(`/${endpoint}`, userApiPayload);

    if (!response.data.Result && !error) {
      throw new Error(response.data.Message ?? '');
    }

    if (!response.data.Result && error) {
      let message = response.data.Message || 'There was an error, please try again later.';
      if (typeof error === 'object') {
        const { action, args } = error;
        message = args?.message || message;
        throw new ReactQueryError({ action, args: { ...args, message } });
      }
      throw new ReactQueryError({ args: { message } });
    }

    return response.data.Data;
  } catch (e) {
    const requestError = e as AxiosError;
    const { response } = requestError;
    if (error) {
      let message = requestError?.message || 'There was an error, please try again later.';
      if (typeof error === 'object' && error.statusCodeMap && response?.status) {
        const { action, args } = error.statusCodeMap?.[response.status] || error;
        message = error.statusCodeMap?.[response.status]?.args?.message || message;
        throw new ReactQueryError({ action, args: { ...args, message } });
      }
      throw new ReactQueryError({ args: { message } });
    }
    throw e;
  }
}

export async function fetchDataWithUserId<ReturnType>(
  endpoint: string,
  params: Record<string, unknown> = {}
): Promise<ReturnType> {
  const { UserId } = UserStore.getApiData();
  const userApiPayload = {
    UserId,
    ...params,
  };

  const response = await axios.post(`/${endpoint}`, userApiPayload);

  if (!response.data.Result) {
    throw new Error(response.data.Message ?? '');
  }

  return response.data.Data;
}

export async function getData<T>(endpoint: string): Promise<T> {
  const userApiPayload = UserStore.getApiData();
  const response = await axios.get(`/${endpoint}`, userApiPayload);

  if (response.status !== 200) {
    throw new Error(response.data.Message ?? '');
  }

  return response.data;
}

/**
 * This is a temporary get data function till the above can be removed and all usages updated.
 * @param endpoint
 * @returns
 */
export async function getDataTemp<T>(endpoint: string): Promise<T> {
  const userApiPayload = UserStore.getApiData();
  const response = await axios.get(`/${endpoint}`, userApiPayload);

  if (response.status !== 200) {
    throw new Error(response.data.Message ?? '');
  }

  return response.data.Data;
}

// T is the type of the request and response payload and can be inferred from the type of the `payload` passed in the argument object
// if the response type is different from the request type, use U to specify the response type
export async function postData<T, U = T>({
  endpoint,
  payload,
  options,
}: {
  endpoint: string;
  options?: AxiosRequestConfig;
  payload: T;
}): Promise<U> {
  const clonedPayload = _.cloneDeep(payload);
  let userApiPayload: BasePayload = UserStore.getApiData(payload);
  userApiPayload = getUpdatedFormDataPayload(clonedPayload, userApiPayload);
  const response = await axios.post(`/${endpoint}`, userApiPayload, options ?? {});

  if (!response.data?.Result) {
    throw new Error(response.data?.Message ?? 'Unknown Error');
  }

  return response.data?.Data ?? response.data;
}

export async function putData<T, U = T>({
  endpoint,
  payload,
  options,
}: {
  endpoint: string;
  options?: AxiosRequestConfig;
  payload: T;
}): Promise<U> {
  const userApiPayload: BasePayload = UserStore.getApiData(payload);
  const response = await axios.put(`/${endpoint}`, userApiPayload, options ?? {});

  if (!response.data?.Result) {
    throw new Error(response.data?.Message ?? 'Unknown Error');
  }

  return response.data?.Data ?? response.data;
}

export async function deleteData<T>({ endpoint, payload }: { endpoint: string; payload: T }): Promise<void> {
  const userApiPayload: BasePayload = UserStore.getApiData(payload);
  const response = await axios.delete(`/${endpoint}`, { data: userApiPayload });

  if (response.status !== 200) {
    throw new Error(response.data.Message ?? 'Unable to delete');
  }

  return response.data;
}
