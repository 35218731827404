import React from 'react';

import { SvgIcon } from '@mui/material';

export function CloseRebrand(props) {
  return (
    <SvgIcon {...props} fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M3.41554 12.3492C3.02816 11.9618 3.02816 11.3338 3.41554 10.9464L11.4316 2.93027C11.819 2.5429 12.4471 2.5429 12.8345 2.93027C13.2218 3.31765 13.2218 3.94572 12.8345 4.33309L4.81836 12.3492C4.43098 12.7366 3.80291 12.7366 3.41554 12.3492Z'
        fill='var(--color-gray-90)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M3.41553 2.93028C3.80291 2.5429 4.43097 2.5429 4.81835 2.93028L12.8345 10.9464C13.2218 11.3338 13.2218 11.9618 12.8345 12.3492C12.4471 12.7366 11.819 12.7366 11.4316 12.3492L3.41553 4.3331C3.02816 3.94572 3.02816 3.31765 3.41553 2.93028Z'
        fill='var(--color-gray-90)'
        fillRule='evenodd'
      />
    </SvgIcon>
  );
}
