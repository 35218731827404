import React, { useEffect, useState } from 'react';

import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { Button } from 'src/app/components/lib/button';
import { Page } from 'src/app/components/lib/page';
import { Tab } from 'src/app/components/lib/tab';
import { Tabs } from 'src/app/components/lib/tabs';
import { REDIRECT_PARAM } from 'src/app/constants/router-constants';
import SpecifiedErrorBoundary from 'src/app/layout/error/specified-error-boundary';
import { userPermissionsAtom } from 'src/app/state/user-permissions';

import type { PageVariant } from 'src/app/components/lib/page';

export type TabLink = {
  badge?: React.ReactElement;
  element: JSX.Element;
  hasNestedRoutes: boolean;
  hasPermission: boolean;
  hide?: boolean;
  label: string;
  to?: string;
};

type TabPageProps = {
  className?: string;
  header: JSX.Element;
  marginBottom?: '32px' | '48px';
  tabs: TabLink[];
  variant?: PageVariant;
};

function TabPage({ header, tabs, marginBottom, variant, className }: TabPageProps) {
  const navigate = useNavigate();
  const { '*': paramsString = '' } = useParams();
  const paramsArray = paramsString.split('/');
  const isListPage = paramsArray.length === 1;
  const initialTab = paramsArray[0];
  const { perms } = useRecoilValue(userPermissionsAtom);
  const permsLoaded = Object.keys(perms).length > 0;
  const filteredTabs = tabs.filter((tab) => !tab.hide);
  const [requestedTab, setRequestedTab] = useState(initialTab || filteredTabs[0].to);

  // sets the active tab based on permissions
  // redirects to the home page if user does not have permissions to any of the tabs
  // returns a default tab if no requested tab is provided
  // redirects to the home page if the requested tab doesn't exist
  // does nothing if the requested tab exists and is enabled

  useEffect(() => {
    if (permsLoaded) {
      const firstDefault = filteredTabs.find((option) => option.hasPermission);
      const visibleTabs = filteredTabs.map((option) => {
        if (option.hasPermission) {
          return option.to;
        }
        return null;
      });
      const firstVisibleTab = firstDefault ? firstDefault.to : '';

      if (!visibleTabs.includes(initialTab)) {
        setRequestedTab(firstVisibleTab);
      } else {
        setRequestedTab(initialTab);
      }

      if (!firstDefault) {
        navigate(`/?${REDIRECT_PARAM}=true`, { replace: true });
      } else if (requestedTab === '' || initialTab === '') {
        firstDefault
          ? navigate(firstDefault.to, { replace: true })
          : navigate(`/?${REDIRECT_PARAM}=true`, { replace: true });
      } else if (!tabs.some((option) => option.to === requestedTab)) {
        navigate('/', { replace: true });
      }
    }
  }, [permsLoaded, initialTab, filteredTabs]);

  // The tab-actions div is a container that we use can use to portal other react elements
  // Some tabbed pages manage the action state down a few layers and this allows us to access it easier
  return (
    <Page className={className} variant={variant}>
      {header}
      <TabbedPageActionsContainer>
        <div id='tab-actions'> </div>
      </TabbedPageActionsContainer>
      <Tabs marginBottom={marginBottom} scrollButtons='auto' value={requestedTab} variant='scrollable'>
        {filteredTabs.map((tab) =>
          tab.hasPermission ? (
            <Tab badge={tab.badge} component={Link} key={tab.to} label={tab.label} to={tab.to} value={tab.to} />
          ) : null
        )}
      </Tabs>
      <SpecifiedErrorBoundary
        additionalContent={
          !isListPage ? (
            <Button label='Go back' variant='link' onClick={() => navigate(`./${initialTab}`)} />
          ) : undefined
        }
      >
        <Routes>
          {filteredTabs.map((tab) =>
            tab.hasPermission ? (
              <Route element={tab.element} key={tab.to} path={`${tab.to}${tab.hasNestedRoutes ? '/*' : ''}`} />
            ) : null
          )}
        </Routes>
      </SpecifiedErrorBoundary>
    </Page>
  );
}
export default TabPage;

const TabbedPageActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
