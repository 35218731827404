import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import NotificationActions from 'src/app_deprecated/actions/NotificationActions';
import { endPoints as EndPoints } from 'src/app_deprecated/constants/AuthConstants';
import AjaxPromises from 'src/app_deprecated/utils/AjaxPromises';
import HttpClient from 'src/app_deprecated/utils/HttpClient';
import PasswordCheck from 'src/app_deprecated/utils/PasswordCheck';

import { DutchieLogoColor } from 'src/app/layout/navigation-menu/dutchie-logo-color';
import { PasswordStrengthIndicator } from 'src/app/pages/settings/users/users/details/password-strength-indicator';
import { getPasswordStrength, getPasswordStrengthLabel } from 'src/app/utils/password-check';

import { Button } from '../lib/button';
import { Divider } from '../lib/divider';
import { FormSection } from '../lib/form';
import { Heading } from '../lib/heading';
import { Input } from '../lib/input';

type IPasswordSettings = {
  PasswordLength: 8;
  PasswordLengthEnabled: boolean;
};

class Reset extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const passwordSettings: IPasswordSettings = {
      PasswordLength: 8,
      PasswordLengthEnabled: false,
    };

    this.state = {
      loading: false,
      password1: '',
      password2: '',
      strength: 0,
      token: this.props.token,
      complexityError: false,
      matchingError: false,
      complexityStatus: '',
      matchingStatus: '',
      passwordSettings,
      passwordStrength: '',
      passwordStrengthValue: 0,
      isPasswordPolicyValid: false,
    };

    this.onFirstPasswordChange = this.onFirstPasswordChange.bind(this);
    this.onSecondPasswordChange = this.onSecondPasswordChange.bind(this);
    this.slowCheck = this.slowCheck.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.getPasswordSettings = this.getPasswordSettings.bind(this);
    this.createPasswordStrengthLabel = this.createPasswordStrengthLabel.bind(this);
  }

  getPasswordSettings() {
    const { token } = this.state;
    void AjaxPromises.GET(`${EndPoints.PASSWORD_SETTINGS}?resetToken=${token}`).then((res) => {
      if (res.Result) {
        this.setState({ passwordSettings: res.Data });
      }
    });
  }

  componentDidMount() {
    this.getPasswordSettings();
  }

  slowCheck(check) {
    _.debounce(() => this.checkPassword(check), 500, { leading: false, trailing: true });
  }

  conponentWillUnmount() {
    this.setState({
      loading: false,
      password1: '',
      password2: '',
      strength: 0,
      token: this.props.token,
      complexityError: false,
      matchingError: false,
      complexityStatus: '',
      matchingStatus: '',
      passwordStrength: '',
      passwordStrengthValue: 0,
    });
  }

  onFirstPasswordChange(e) {
    this.setState({ password1: e.target.value }, () => {
      this.slowCheck(1);
      this.createPasswordStrengthLabel();
    });
  }

  onSecondPasswordChange(e) {
    this.setState({ password2: e.target.value }, () => {
      this.slowCheck(2);
    });
  }

  checkPassword(check) {
    const { password1, password2, passwordSettings } = this.state;

    if (check === 1) {
      const complexityError = PasswordCheck.checkComplexity(password1, passwordSettings);

      if (complexityError) {
        this.setState({ complexityError: true, complexityStatus: complexityError.idStatus });
        NotificationActions.addNotification({
          type: 'error',
          sticky: false,
          body: complexityError.notification,
          timer: 5500,
        });
        return true;
      }
      if (!complexityError) {
        this.setState({ complexityError: '', complexityStatus: '' });
        return false;
      }
    } else {
      const matchingError = PasswordCheck.checkMatching(password1, password2);

      if (matchingError) {
        this.setState({ matchingError: true, matchingStatus: matchingError.idStatus });
        NotificationActions.addNotification({
          type: 'error',
          sticky: false,
          body: matchingError.notification,
          timer: 5000,
        });
        return true;
      }
      if (!matchingError) {
        this.setState({ matchingError: '', matchingStatus: '' });
        return false;
      }
    }
  }

  resetPassword(e) {
    const { loading } = this.state;
    e.preventDefault();
    const complexityFailed = this.checkPassword(1);
    const matchingFailed = this.checkPassword(2);
    if (loading) {
      return;
    }

    if (complexityFailed || matchingFailed) {
      return;
    }

    if (!complexityFailed && !matchingFailed) {
      this.setState({ loading: true }, async () => {
        const resp = await HttpClient.post(EndPoints.RESET, {
          password: this.state.password2,
          passwordToken: this.state.token,
        });

        if (resp) {
          window.location.href = '../';
        }

        this.setState({ loading: false });
      });
    }
  }

  createPasswordStrengthLabel() {
    const { password1 } = this.state;
    const passwordStrengthValue = getPasswordStrength(password1);
    const passwordStrengthLabel = getPasswordStrengthLabel(passwordStrengthValue);

    this.setState({ passwordStrength: passwordStrengthLabel });
  }

  render() {
    const { password1, passwordStrength, complexityError, password2, loading } = this.state;
    return (
      <AuthContainer>
        <AuthFormContainer>
          <TopSection>
            <DutchieLogoColor sizeRatio={1.6} />
            <AuthFormSection>
              <StyledHeading subheader='Enter your new password below.'>Reset your password</StyledHeading>
              <StyledFormSection>
                <Input
                  id='auth-password1'
                  inputProps={{ autocomplete: 'new-password' }}
                  passwordPolicyLength={this.state.passwordSettings.PasswordLength}
                  placeholder='Enter new password'
                  type='password'
                  validatePasswordPolicy
                  value={password1}
                  onBlur={this.onFirstPasswordChange}
                  onChange={this.onFirstPasswordChange}
                  onFocus={() => this.setState({ complexityError: '', complexityStatus: '' })}
                  onPasswordPolicyValidation={(valid) => {
                    this.setState({ isPasswordPolicyValid: valid });
                  }}
                />
                <PasswordStrengthIndicator label={password1 === '' ? '' : passwordStrength} />
              </StyledFormSection>
              <Input
                disabled={complexityError}
                id='auth-password2'
                inputProps={{ autocomplete: 'new-password' }}
                placeholder='Re-enter new password'
                type='password'
                value={password2}
                onChange={this.onSecondPasswordChange}
                onFocus={() => this.setState({ matchingError: '', matchingStatus: '' })}
              />
            </AuthFormSection>
          </TopSection>
          <Divider variant='compact' />
          <ActionsContainer>
            <Button
              disabled={!this.state.isPasswordPolicyValid}
              label='Save'
              loading={loading}
              onClick={this.resetPassword}
            />
          </ActionsContainer>
        </AuthFormContainer>
      </AuthContainer>
    );
  }
}

export default Reset;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--sizes-50) var(--sizes-60);
`;

const AuthFormContainer = styled.div`
  width: 522px;
  background: var(--color-brand-primary-white);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

const StyledFormSection = styled(FormSection)`
  gap: 14px;
`;

const AuthContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-gray-10);
`;
const AuthFormSection = styled(FormSection)`
  gap: var(--sizes-60);
  margin-top: var(--sizes-90);
  margin-bottom: var(--sizes-20);
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
  h1 {
    margin-bottom: 8px;
  }
`;

const TopSection = styled.div`
  padding: var(--sizes-70);
  padding-bottom: var(--sizes-80);
`;
