import styled from 'styled-components';

export const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--sizes-60);
  width: 100%;
`;

export const ColumnRow = styled.div`
  display: flex;
`;
