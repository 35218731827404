import keyMirror from 'keymirror';

export const endPoints = {
  GET_CARS: 'api/v2/delivery/get-cars',
  GET_COUNTIES: 'api/v2/delivery/get-counties',
  GET_DRIVERS: 'api/v2/delivery/get-drivers',
  GET_DRIVER: 'api/v2/delivery/get-driver',
  UPDATE_CAR: 'api/v2/delivery/update-car',
  UPDATE_DRIVER: 'api/v2/delivery/update-driver',
  DELETE_DRIVER: 'api/v2/delivery/delete-driver',
  DELETE_CAR: 'api/v2/delivery/delete-car',
  GET_ALL_ZONES: 'api/v2/delivery/get-all-zones',
  UPDATE_ZONE: 'api/v2/delivery/update-zone',
  DELETE_ZONE: 'api/v2/delivery/delete-zone',
  GET_MSU_DOLLAR_BREAKDOWN: 'api/v2/delivery/get-msu-dollar-breakdown',
  GET_DELIVERY_THRESHOLDS: 'api/v2/delivery/get_delivery_threshold_options',
  SET_DELIVERY_THRESHOLDS: 'api/v2/delivery/set_delivery_threshold_options',
};

export const actions = keyMirror({});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
