import React from 'react';

export function EllipsesIcon(): JSX.Element {
  return (
    <svg fill='none' height='16' viewBox='0 0 20 16' width='20' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='2.44466' cy='7.99999' fill='var(--color-gray-80)' rx='1.77767' ry='1.77667' />
      <ellipse cx='10.0001' cy='7.99999' fill='var(--color-gray-80)' rx='1.77767' ry='1.77667' />
      <ellipse cx='17.5553' cy='7.99999' fill='var(--color-gray-80)' rx='1.77767' ry='1.77667' />
    </svg>
  );
}
